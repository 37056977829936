import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import { RecipientContractReviewFormFields } from 'components/domain/sideModal/RecipientContractReviewSideModal/types';
import { RECIPIENT_CONTRACT_SEND_STATUS_CONFIG } from 'components/domain/table/RecipientContractReviewTable/constant';
import InformationTable from 'components/ui/InformationTable';
import {
	InformationLabelType,
	InformationLabelValueType,
	InformationValueType,
} from 'components/ui/InformationTable/type';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { CRStatusConfig } from 'types/view/base';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';

import { COMPLETION_STATUS_CONFIG, SEND_STATUS_CONFIG, VALIDATION_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: RecepientContractReviewListViewType;
}

function RecipientContractDetailInfo({ data }: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const { control } = useFormContext<RecipientContractReviewFormFields>();
	const [toggle, setToggle] = React.useState(false);
	const { data: commonCodes = { CMN211: [], CMN214: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN211', 'CMN214'] },
		commonCodeAdapter,
	);

	const handleClickPreview = async (edocNo: string) => {
		showDialog(() => <EDocNoDialog edocNo={edocNo} centerId={Number(data.data.centerId)} />);
	};

	const handleClickConfirm = () => {
		// TODO : 확정 기능 추가
	};
	const contractToolTipText = `수급자 표준약관, ERP 계약내역을 서로 비교하여\n아래 4개 규칙에 모두 맞는 적합한 서류라면 ‘적합', \n1개라도 맞지 않다면 ‘부적합'으로 표시됩니다.\n1. [급여종류] 일치\n2. [계약일] 일치\n3. 수급자 표준약관 [계약시작일] = ERP 계약내역 [급여이용시작일]\n4. 수급자 표준약관 [계약종료일] = ERP 계약내역 [인정기간 만료일]`;
	const longtermCareServicePlanTipText = `수급자 표준약관, ERP 계약내역을 서로 비교하여\n아래 4개 규칙에 모두 맞는 적합한 서류라면 ‘적합', \n1개라도 맞지 않다면 ‘부적합'으로 표시됩니다.\n1. [급여종류] 일치\n2. [계약일] 일치\n3. 수급자 표준약관 [계약시작일] = ERP 계약내역 [급여이용시작일]\n4. 수급자 표준약관 [계약종료일] = ERP 계약내역 [인정기간 만료일]`;

	const contractInfoTable = [
		[
			{ type: 'label', label: '발송상태', labelStyle: { width: '9.8rem' } },
			{
				type: 'label',
				label: (
					<S.TableLabelContainer>
						<CRText typography='label' color='gray60'>
							유효성
						</CRText>
						<RDTooltip content={contractToolTipText} side='top'>
							<CRIcon src={Assets.icon.help} width='1.6rem' height='1.6rem' />
						</RDTooltip>
					</S.TableLabelContainer>
				),
				labelStyle: { width: '8.8rem' },
			},
			{ type: 'label', label: '급여종류', labelStyle: { width: '14.4rem' } },
			{ type: 'label', label: '' },
		] as InformationLabelType[],
		[
			{
				type: 'value',
				value: <CRStatus options={SEND_STATUS_CONFIG}>{data.recipientContractSendStatus}</CRStatus>,
			},
			{
				type: 'value',
				value: (
					<CRStatus options={VALIDATION_STATUS_CONFIG}>
						{data.recipientContractDocumentVerification
							? VALIDATION_STATUS_CONFIG[0].label
							: VALIDATION_STATUS_CONFIG[1].label}
					</CRStatus>
				),
			},
			{
				type: 'value',
				value:
					data.lognTermCareBenefitInfo.map((item) => item.longtermCareBenefitType).join(', ') ||
					'-',
			},
			{
				type: 'value',
				value: data.data.edocNo && (
					<CRButton.Default
						type='outlined'
						palette='gray'
						size='xSmall'
						onClick={() => handleClickPreview(data.data.edocNo || '')}>
						미리보기
					</CRButton.Default>
				),
			},
		] as InformationValueType[],
	];

	const renderConfigStatus = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || value === item.value);

		return <CRStatus options={config}>{currentValue?.label}</CRStatus>;
	}, []);

	const longtermCareServicePlanInfoTableList: Array<{
		tableInfo: InformationLabelValueType[][];
		key: string;
	}> = data.lognTermCareBenefitInfo.map((item) => ({
		key: item.longtermCareBenefitPlanDocumentNumber,
		tableInfo: [
			[
				{
					type: 'default',
					label: '급여종류',
					value: item.longtermCareBenefitType,
					labelStyle: { width: '12rem' },
				},
				{
					type: 'default',
					label: (
						<S.TableLabelContainer>
							<CRText typography='label' color='gray60'>
								유효성
							</CRText>
							<RDTooltip content={longtermCareServicePlanTipText} side='top'>
								<CRIcon
									src={Assets.icon.help}
									width='1.6rem'
									height='1.6rem'
									style={{ marginBottom: '0.2rem' }}
								/>
							</RDTooltip>
						</S.TableLabelContainer>
					),
					value: (
						<S.TableCellContianer>
							<CRStatus options={COMPLETION_STATUS_CONFIG}>
								{item.longtermCareBenefitPlanDocumentVerification ? '완료' : '미완료'}
							</CRStatus>
							{item.longtermCareBenefitPlanDocumentNumber && (
								<CRButton.Default
									type='outlined'
									palette='gray'
									size='xSmall'
									onClick={() => handleClickPreview(item.longtermCareBenefitPlanDocumentNumber)}>
									미리보기
								</CRButton.Default>
							)}
						</S.TableCellContianer>
					),
					labelStyle: { width: '12rem' },
					valueStyle: { width: '23.2rem' },
				},
			],
			[
				{
					type: 'default',
					label: '계약일',
					value: item.longtermCareBenefitPlanContractDate,
					labelStyle: { width: '12rem' },
				},
				{
					type: 'default',
					label: '발송상태',
					value: renderConfigStatus(
						RECIPIENT_CONTRACT_SEND_STATUS_CONFIG,
						item.longtermCareBenefitPlanDocumnetSendStatus,
					),
					labelStyle: { width: '10.4rem' },
				},
			],
			[
				{
					type: 'default',
					label: '급여시작일',
					value: item.longtermCareBenefitPlanStartDate,
					labelStyle: { width: '10.4rem' },
				},
				{
					type: 'default',
					label: '급여종료일',
					value: item.longtermCareBenefitPlanEndDate,
					labelStyle: { width: '10.4rem' },
				},
			],
			[
				{
					type: 'default',
					label: '종합의견',
					value: (
						<S.TableColumnCellContainer>
							<CRText
								typography='label'
								text={item.longtermCareBenefitPlanOverallOpinion || '-'}
								style={{
									display: '-webkit-box',
									WebkitLineClamp: toggle ? undefined : 3,
									WebkitBoxOrient: 'vertical',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									width: '100%',
								}}
							/>
							{item.longtermCareBenefitPlanOverallOpinion && (
								<CRButton.IconButton
									palette='gray'
									type='outlined'
									size='xSmall'
									style={{
										marginTop: '0.4rem',
										width: '100%',
										backgroundColor: Colors.gray95,
										marginBottom: '0.4rem',
									}}
									onClick={() => setToggle(!toggle)}
									iconRight={
										toggle ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom
									}>
									{toggle ? '접기' : '전체 보기'}
								</CRButton.IconButton>
							)}
						</S.TableColumnCellContainer>
					),
					valueColSpan: 3,
				},
			],
		] as InformationLabelValueType[][],
	}));

	const renderAssessmentStatusText = (value?: boolean) => (
		<CRText typography='label' color={value ? 'gray00' : 'primary60'}>
			{value ? '정상' : '확인필요'}
		</CRText>
	);

	const fallRiskAssessmentInfoTable = [
		[
			{ type: 'label', label: '급여종류', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '조사사유', labelStyle: { width: '8.8rem' } },
			{ type: 'label', label: '작성일', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '연령점수', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '' },
		] as InformationLabelType[],
		...data.lognTermCareBenefitInfo.map(
			(item) =>
				[
					{ type: 'value', value: item.longtermCareBenefitType },
					{
						type: 'value',
						value: renderAssessmentStatusText(item.fallRiskAssessmentReason),
					},
					{
						type: 'value',
						value: renderAssessmentStatusText(item.fallRiskAssessmentDate),
					},
					{
						type: 'value',
						value: renderAssessmentStatusText(item.fallRiskAssessmentAgeScoreValid),
					},
					{
						type: 'value',
						value: (
							<CRButton.Default
								type='outlined'
								palette='gray'
								size='xSmall'
								onClick={handleClickConfirm}>
								확정
							</CRButton.Default>
						),
					},
				] as InformationValueType[],
		),
	];

	const pressureUlcerRiskAssessmentInfoTable = [
		[
			{ type: 'label', label: '급여종류', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '조사사유', labelStyle: { width: '8.8rem' } },
			{ type: 'label', label: '작성일', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '항목 누락', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '' },
		] as InformationLabelType[],
		...data.lognTermCareBenefitInfo.map(
			(item) =>
				[
					{ type: 'value', value: item.longtermCareBenefitType },
					{
						type: 'value',
						value: renderAssessmentStatusText(item.pressureInjuryRiskAssessmentReason),
					},
					{
						type: 'value',
						value: renderAssessmentStatusText(item.pressureInjuryRiskAssessmentDate),
					},
					{
						type: 'value',
						value: renderAssessmentStatusText(item.pressureInjuryRiskAssessmentItemValidity),
					},
					{
						type: 'value',
						value: (
							<CRButton.Default
								type='outlined'
								palette='gray'
								size='xSmall'
								onClick={handleClickConfirm}>
								확정
							</CRButton.Default>
						),
					},
				] as InformationValueType[],
		),
	];

	const cognitiveAssessmentInfoTable = [
		[
			{ type: 'label', label: '급여종류', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '조사사유', labelStyle: { width: '8.8rem' } },
			{ type: 'label', label: '작성일', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '항목 누락', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '교육년수', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '' },
		] as InformationLabelType[],
		...data.lognTermCareBenefitInfo.map(
			(item) =>
				[
					{ type: 'value', value: item.longtermCareBenefitType },
					{
						type: 'value',
						value: renderAssessmentStatusText(item.cognitiveFunctionAssessmentReason),
					},
					{
						type: 'value',
						value: renderAssessmentStatusText(item.cognitiveFunctionAssessmentDate),
					},
					{
						type: 'value',
						value: renderAssessmentStatusText(item.cognitiveFunctionAssessmentItemValidity),
					},
					{
						type: 'value',
						value: renderAssessmentStatusText(
							item.cognitiveFunctionAssessmentEducationYearsValidity,
						),
					},
					{
						type: 'value',
						value: (
							<CRButton.Default
								type='outlined'
								palette='gray'
								size='xSmall'
								onClick={handleClickConfirm}>
								확정
							</CRButton.Default>
						),
					},
				] as InformationValueType[],
		),
	];

	const needsAssessmentInfoTable = [
		[
			{ type: 'label', label: '급여종류', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '조사사유', labelStyle: { width: '8.8rem' } },
			{ type: 'label', label: '작성일', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '판단근거', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '종합의견', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '' },
		] as InformationLabelType[],
		...data.lognTermCareBenefitInfo.map(
			(item) =>
				[
					{ type: 'value', value: item.longtermCareBenefitType },
					{
						type: 'value',
						value: renderAssessmentStatusText(item.needsAssessmentCompletionStatus),
					},
					{ type: 'value', value: renderAssessmentStatusText(item.needsAssessmentReason) },
					{
						type: 'value',
						value: renderAssessmentStatusText(item.needsAssessmentJudgmentBasisValidity),
					},
					{
						type: 'value',
						value: renderAssessmentStatusText(item.needsAssessmentOverallOpinionValidity),
					},
					{
						type: 'value',
						value: (
							<CRButton.Default
								type='outlined'
								palette='gray'
								size='xSmall'
								onClick={handleClickConfirm}>
								확정
							</CRButton.Default>
						),
					},
				] as InformationValueType[],
		),
	];

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						수급자 계약서
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.recipientContractCompletionStatus ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<InformationTable items={contractInfoTable} />
				{/* <S.EmptyContainer>
					<CRText typography='label' color='gray60' text='발급된 계약서가 없습니다.' />
				</S.EmptyContainer> */}
			</CRCardFormLayout>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						급여제공계획서
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.longtermCareBenefitPlanCompletionStatus ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				{longtermCareServicePlanInfoTableList ? (
					longtermCareServicePlanInfoTableList.map((item, index) => (
						<InformationTable items={item.tableInfo} key={item.key} />
					))
				) : (
					<S.EmptyContainer>
						<CRText typography='label' color='gray60' text='발급된 계약서가 없습니다.' />
					</S.EmptyContainer>
				)}
				<CRInputLabel label='종합의견 검수' type='left' isRequired>
					<Controller
						control={control}
						name='salaryPlanCompleteOption'
						render={({ field: { value, onChange } }) => (
							<CRInput.Selector
								currentValue={value}
								onChangeValue={onChange}
								items={commonCodes.CMN211}
							/>
						)}
					/>
				</CRInputLabel>
				<CRInputLabel label='공단통보' type='left'>
					<Controller
						control={control}
						name='notiToPcorpYn'
						render={({ field: { value, onChange } }) => (
							<CRCheckBox
								checkBoxType='checkbox'
								appearanceType='button'
								checked={value}
								onChange={onChange}>
								<CRText>공단 통보 완료</CRText>
							</CRCheckBox>
						)}
					/>
				</CRInputLabel>
				<CRInputLabel label='변경사유서 작성' type='left' isRequired>
					<Controller
						control={control}
						name='salaryChangeStatOption'
						render={({ field: { value, onChange } }) => (
							<CRInput.Selector
								currentValue={value}
								onChangeValue={onChange}
								items={commonCodes.CMN214}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						통합사정
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.tagCompletionStatus ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<S.TableContainer>
					<CRText typography='label' text='낙상위험도' />
					<InformationTable items={fallRiskAssessmentInfoTable} />
				</S.TableContainer>
				<S.TableContainer>
					<CRText typography='label' text='욕창위험도' />
					<InformationTable items={pressureUlcerRiskAssessmentInfoTable} />
				</S.TableContainer>
				<S.TableContainer>
					<CRText typography='label' text='인지기능' />
					<InformationTable items={cognitiveAssessmentInfoTable} />
				</S.TableContainer>
				<S.TableContainer>
					<CRText typography='label' text='욕구사정' />
					<InformationTable items={needsAssessmentInfoTable} />
				</S.TableContainer>
				<CRInputLabel label='통합사정 검수' type='left'>
					<Controller
						control={control}
						name='integratedEvaluationCompleteYn'
						render={({ field: { value, onChange } }) => (
							<CRCheckBox
								checkBoxType='checkbox'
								appearanceType='button'
								checked={value}
								onChange={onChange}>
								<CRText>검수 완료</CRText>
							</CRCheckBox>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default RecipientContractDetailInfo;
