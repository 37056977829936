export enum ContractStatusType {
	Registration = 'CMN082.00',
	Waiting = 'CMN082.10',
	Progress = 'CMN082.20',
	Pending = 'CMN082.70',
	Cancel = 'CMN082.80',
	Complete = 'CMN082.90',
}

export enum ServiceType {
	FamilyCare = 'CMN083.10',
	VisitCare = 'CMN083.11',
	VisitRecogCare = 'CMN083.12',
	VisitBath = 'CMN083.20',
	FamilyBath = 'CMN083.21',
	VisitCarBath = 'CMN083.22',
	VisitHomeBath = 'CMN083.23',
	VisitNursing = 'CMN083.30',
}

// 본인부담금납부방법코드
export enum OwnExpensePayMethodType {
	// 자동이체
	Automatic = 'CMN041.10',
	// 직접이체
	Self = 'CMN041.20',
	// 임금공제
	WageDeduction = 'CMN041.30',
}

export enum WorkScheduleType {
	WorkSchedule = 'CMN193.10', // 정상 근무 예정
	Vacation = 'CMN193.20', // 휴가
	OverWork = 'CMN193.30', // 초과 근무
	WorkDetail = 'CMN193.40', // 종사자근무 상세 신고
}

export enum HolidayType {
	PublicHoliday = 'CMN021.10', // 공휴일
	CompTimeOffHoliday = 'CMN021.20', // 대체휴일
	TemporaryHoliday = 'CMN021.30', // 임시 휴일
}
