import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const EMPLOYMENT_AUTOMATION_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '8rem',
		label: '결과',
		sortKey: '결과',
	},
	{
		width: '8rem',
		label: '요청 상태',
		sortKey: '요청 상태',
	},
	{
		width: '5.6rem',
		label: '법인',
		sortKey: '법인',
	},
	{
		width: '9.6rem',
		label: '센터',
		sortKey: '센터',
	},
	{
		width: '9.6rem',
		label: '직원명',
		sortKey: '직원명',
	},
	{
		width: '12rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '8rem',
		label: '신고 유형',
		sortKey: '신고 유형',
	},
	{
		width: '32rem',
		label: '결과로그',
		sortKey: '결과로그',
	},
	{
		width: '12rem',
		label: '급여유형',
		sortKey: '급여유형',
	},
	{
		width: '12rem',
		label: '입사일',
		sortKey: '입사일',
	},
	{
		width: '12rem',
		label: '근무시작일',
		sortKey: '근무시작일',
	},
	{
		width: '14.4rem',
		label: '입사서류 파일',
		sortKey: '입사서류 파일',
	},
	{
		width: '14.4rem',
		label: '직원번호',
		sortKey: '직원번호',
	},
	{
		width: '10.4rem',
		label: '자격면허종류',
		sortKey: '자격면허종류',
	},
	{
		width: '14.4rem',
		label: '자격면허번호',
		sortKey: '자격면허번호',
	},
	{
		width: '12rem',
		label: '자격취득일자',
		sortKey: '자격취득일자',
	},
	{
		width: '20.5rem',
		label: '자격증파일',
		sortKey: '자격증파일',
	},
	{
		label: '',
		sortKey: '',
	},
];

export const TAG_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '실패',
		label: '실패',
		color: 'red',
	},
	{
		key: '대기',
		label: '대기',
		color: 'gray',
	},
	{
		key: '진행중',
		label: '진행중',
		color: 'yellow',
	},
];
