import { CRStatusConfig, CRTableMultiHeadConfig } from 'types/view/base';

export const EMPLOYEE_CONTRACT_REVIEW_TABLE_HEADER_CONFIG: CRTableMultiHeadConfig[] = [
	{
		label: '기본정보',
		sortKey: 'basicInfo',
		children: [
			{ label: '상태', sortKey: 'totalWorkCompletionStatus', width: '8.8rem' },
			{ label: '소속센터', sortKey: 'center', width: '8.8rem' },
			{ label: '사회복지사', sortKey: 'socialWorker', width: '8.8rem' },
			{ label: '직원명', sortKey: 'employeeName', width: '8.8rem' },
			{ label: '직원 생년월일', sortKey: 'employeeBirthDate', width: '12rem' },
			{ label: '기존/신규', sortKey: 'isNewEmployee', width: '8rem' },
			{ label: '당일계약', sortKey: 'isSameDayContract', width: '8rem' },
			{ label: '계약유형', sortKey: 'contractType', width: '8rem' },
			{ label: '계약일', sortKey: 'recipientContractDate', width: '14.4rem' },
			{ label: '급여종류', sortKey: 'longtermCareBenefitType', width: '8rem' },
			{ label: '급여시작일', sortKey: 'longtermCareStartDate', width: '12rem' },
			{ label: '급여종료일', sortKey: 'longtermCareEndDate', width: '12rem' },
			{ label: '수급자명', sortKey: 'recipientName', width: '8.8rem' },
			{ label: '장기요양인정번호', sortKey: 'longTermCareNumber', width: '12rem' },
			{ label: '가족 여부', sortKey: 'isFamily', width: '8rem' },
		],
	},
	{
		label: '범죄경력조회',
		sortKey: 'criminalRecordCheck',
		children: [
			{
				label: '완료 여부',
				sortKey: 'isCrimeCheckCompleted',
				width: '12rem',
				textAlign: 'center',
			},
			{
				label: '범죄회보서',
				sortKey: 'hasCrimeReportFile',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '노인학대',
				sortKey: 'hasElderAbuseReportFile',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '장애인학대',
				sortKey: 'hasDisabilityAbuseReportFile',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '등록 유형',
				sortKey: 'isManuallyRegistered',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '근로계약서',
		sortKey: 'employmentContract',
		children: [
			{
				label: '완료 여부',
				sortKey: 'employeeContractCompletionStatus',
				width: '12rem',
				textAlign: 'center',
			},
			{
				label: '발송상태',
				sortKey: 'employeeContractSendingStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '유효성',
				sortKey: 'isEmployeeContractValid',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '시급',
				sortKey: 'employeeContractHourlyWage',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '월소정근로시간',
				sortKey: 'employeeContractMonthlyWorkHours',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '지침 준수 여부',
				sortKey: 'isContractWorkHoursAlignedWithPolicy',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '인력신고',
		sortKey: 'personnelReport',
		children: [
			{
				label: '입사 신고',
				sortKey: 'employeeReportState',
				width: '12rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '4대보험',
		sortKey: 'fourMajorInsurances',
		children: [
			{
				label: '취득 신고',
				sortKey: 'isInsuranceEnrollmentReportCompleted',
				width: '12rem',
				textAlign: 'center',
			},
			{
				label: '주소정근로시간',
				sortKey: 'weeklyContractedHours',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '신고 금액',
				sortKey: 'reportedInsuranceWage',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '취득 일자',
				sortKey: 'insuranceEnrollmentDate',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '국민연금',
				sortKey: 'requiresNationalPensionService',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '건강보험',
				sortKey: 'requiresNationalHealthInsurance',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '고용보험',
				sortKey: 'requiresEmploymentInsurance',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '산재보험',
				sortKey: 'requiresEmployeeCompensationInsurance',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '배상책임보험',
		sortKey: 'liabilityInsurance',
		children: [
			{
				label: '완료 여부',
				sortKey: 'isLiabilityInsuranceRegistrationCompleted',
				width: '12rem',
				textAlign: 'center',
			},
			{
				label: '대상',
				sortKey: 'isLiabilityInsuranceRequired',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '가입 여부',
				sortKey: 'isLiabilityInsuranceEnrolled',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '단말기 등록',
		sortKey: 'deviceRegistration',
		children: [
			{
				label: '등록 여부',
				sortKey: 'deviceRegistrationRegistrationStatus',
				width: '12rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '참고',
		sortKey: 'reference',
		children: [
			{
				label: '계약 특이사항',
				sortKey: 'referenceContractNotes',
				width: '24rem',
				textAlign: 'center',
			},
			{ label: '메모', sortKey: 'adminMemo', width: '24rem', textAlign: 'center' },
		],
	},
];

export const COMPLETION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '미완료',
		label: '미완료',
		color: 'yellow',
	},
];

export const CONTRACT_COMPLETION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미완료',
		label: '미완료',
		value: 'CMN218.10',
		color: 'yellow',
	},
	{
		key: '완료',
		label: '완료',
		value: 'CMN218.30',
		color: 'green',
	},
	{
		key: '수정필요',
		label: '수정필요',
		value: 'CMN218.20',
		color: 'red',
	},
];

export const REPORT_COMPLETION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미신고',
		label: '미신고',
		value: 'CMN216.10',
		color: 'yellow',
	},
	{
		key: '신고완료',
		label: '신고완료',
		value: 'CMN216.20',
		color: 'yellow',
	},
	{
		key: '승인완료',
		label: '승인완료',
		value: 'CMN216.30',
		color: 'green',
	},
];

export const DEVICE_REGISTRATION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미등록',
		label: '미등록',
		value: 'CMN217.10',
		color: 'yellow',
	},
	{
		key: '타명의 등록',
		label: '타명의 등록',
		value: 'CMN217.20',
		color: 'green',
	},
	{
		key: '등록완료',
		label: '등록완료',
		value: 'CMN217.30',
		color: 'green',
	},
];

export const SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '작성 대기',
		label: '작성 대기',
		value: 'CMN171.05',
		color: 'red',
	},
	{
		key: '서명 대기',
		label: '서명 대기',
		value: 'CMN171.10',
		color: 'red',
	},
	{
		key: '검수 대기',
		label: '검수 대기',
		value: 'CMN171.20',
		color: 'green',
	},
	{
		key: '완료',
		label: '완료',
		value: 'CMN171.30',
		color: 'green',
	},
	{
		key: '서명 취소',
		label: '서명 취소',
		value: 'CMN171.40',
		color: 'red',
	},
	{
		key: '기간 만료',
		label: '기간 만료',
		value: 'CMN171.50',
		color: 'red',
	},
	{
		key: '상태 오류',
		label: '상태 오류',
		value: 'CMN171.99',
		color: 'red',
	},
];

export const VALIDATION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '적합',
		label: '적합',
		color: 'green',
	},
	{
		key: '부적합',
		label: '부적합',
		color: 'red',
	},
];

export const COMPLIANCE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '준수',
		label: '준수',
		color: 'green',
	},
	{
		key: '확인필요',
		label: '확인필요',
		color: 'yellow',
	},
];

export const EMPLOYEE_CONTRACT_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미완료',
		label: '미완료',
		value: 'CMN218.10',
		color: 'yellow',
	},
	{
		key: '수정필요',
		label: '수정필요',
		value: 'CMN218.20',
		color: 'red',
	},
	{
		key: '완료',
		label: '완료',
		value: 'CMN218.30',
		color: 'green',
	},
];
