import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { QueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRChips from 'components/base/CRChips';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import EmployeeContractManagementTable from 'components/domain/table/EmployeeContractManagementTable';
import { centerListAdapter, commonCodeAdapter, corporationListAdapter } from 'lib/adapter/common';
import { employeeContractReviewAdapter } from 'lib/adapter/contractReview';
import {
	useCommonCenters,
	useCommonCodes,
	useCommonCorporations,
	useEmployeeContractList,
	useSaveContractReview,
} from 'lib/hook/react-query';
import { useCreateInsuranceList } from 'lib/hook/react-query/mutation/insurance';
import { ResponseCode } from 'types/api';
import { SaveContractReviewRequest } from 'types/api/contractReview';
import {
	CreateAcquirementInsuranceListRequest,
	CreateAcquirementInsuranceRequest,
	CreateInsuranceResponse,
} from 'types/api/insurance';
import { PageInfo } from 'types/view/base';
import { EmployeeContractReviewListViewType } from 'types/view/contractReview';
import { Filter } from 'types/view/filter';

import { EMPLOYEE_CONTRACT_REVIEW_TASK_CONFIG, EmployeeContractReviewTaskType } from './constant';
import * as S from './styles';

function EmployeeNewContractTab(): React.ReactElement {
	const [checkedList, setCheckedList] = useState<EmployeeContractReviewListViewType[]>([]);

	const [date, setDate] = useState(dayjs().toDate());
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [currentStatus, setCurrentStatus] = useState<CheckOption>();
	const [currentStatusValue, setCurrentStatusValue] = useState<CheckOption>();
	const [currentTask, setCurrentTask] = useState<EmployeeContractReviewTaskType>(
		EMPLOYEE_CONTRACT_REVIEW_TASK_CONFIG[0],
	);
	const [currentFilter, setCurrentFilter] = useState<{
		contractTypeFilter?: { label: string; value: string }[];
		sameDayContractFilter?: { label: string; value: boolean }[];
		centerFilter?: { label: string; value: number }[];
		serviceStartDateFilter?: { label: string; value: string }[];
		employeeContractCompleteFilter?: { label: string; value: string }[];
		pcorpTagRegistrationFilter?: { label: string; value: string }[];
		liabilityInsuranceFilter?: { label: string; value: boolean }[];
		criminalRecordFilter?: { label: string; value: boolean }[];
		insuranceFilter?: { label: string; value: boolean }[];
		employeeReportFilter?: { label: string; value: string }[];
		totalCompleteFilter?: { label: string; value: boolean }[];
	}>({
		contractTypeFilter: [{ label: '신규계약', value: 'CMN136.10' }],
	});

	const { data: corporationOptions } = useCommonCorporations(corporationListAdapter);
	const { data: centerOptions } = useCommonCenters(centerListAdapter);
	const { data: commonCodes = { CMN136: [], CMN218: [], CMN217: [], CMN216: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN136', 'CMN218', 'CMN217', 'CMN216'] },
		commonCodeAdapter,
	);

	const {
		data: employeeContractReviewData,
		isLoading,
		refetch,
	} = useEmployeeContractList(
		{
			contractTypeCd: currentFilter?.contractTypeFilter?.[0]?.value || undefined,
			contractYearMonth: dayjs(date).format('YYYYMM'),
			centerIds: currentFilter?.centerFilter?.map((item) => item.value) || undefined,
			serviceStartDt: currentFilter?.serviceStartDateFilter?.[0]?.value || undefined,
			sameDayContractYn: currentFilter?.sameDayContractFilter?.[0]?.value ?? undefined,
			employeeContractCompleteCd:
				currentFilter?.employeeContractCompleteFilter?.[0]?.value || undefined,
			pcorpTagRegistrationCd: currentFilter?.pcorpTagRegistrationFilter?.[0]?.value || undefined,
			liabilityInsuranceCompleteYn:
				currentFilter?.liabilityInsuranceFilter?.[0]?.value ?? undefined,
			criminalCompleteYn: currentFilter?.criminalRecordFilter?.[0]?.value ?? undefined,
			insuranceCompleteYn: currentFilter?.insuranceFilter?.[0]?.value ?? undefined,
			employeeReportStatCd: currentFilter?.employeeReportFilter?.[0]?.value || undefined,
			totCompleteYn: currentFilter?.totalCompleteFilter?.[0]?.value || undefined,
			page: pageInfo.page,
			size: pageInfo.size,
			keyword,
		},
		employeeContractReviewAdapter,
	);

	const { mutate: saveEmployeeContractReview } = useSaveContractReview(
		(client, returnData, variables) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success(
					`총 ${checkedList.length}건 ${currentStatusValue?.label}상태로 변경하였습니다.`,
				);
				refetch();
				setCurrentStatus(undefined);
				setCurrentStatusValue(undefined);
				setCheckedList([]);
			} else {
				Toast.error(`근로계약서 검토 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`);
			}
		},
	);
	const { mutateAsync: createInsuranceList } = useCreateInsuranceList(
		(client: QueryClient, data: CreateInsuranceResponse | null) => {
			if (data?.code === ResponseCode.SUCCESS) {
				Toast.success(`총 ${checkedList.length}건 4대보험 신고 신청에 성공하였습니다.`);
			} else {
				Toast.error('4대보험 신고 신청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const filters = useMemo(
		() =>
			[
				{
					key: 'sameDayContractFilter',
					type: 'toggle',
					placeholder: '당일 계약',
					options: [{ label: '당일 계약', value: true }],
				},
				{
					key: 'contractTypeFilter',
					type: 'single',
					placeholder: '계약 유형',
					options: commonCodes.CMN136,
				},
				{
					key: 'totalCompleteFilter',
					type: 'single',
					placeholder: '상태',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
				{
					key: 'centerFilter',
					type: 'multi',
					placeholder: '소속센터',
					options: centerOptions,
				},
				{
					key: 'serviceStartDateFilter',
					type: 'day_calendar',
					placeholder: '급여 시작일',
					value: [
						{
							label: 'rangeFilter',
							value: {
								start: dayjs(date).startOf('M').toDate(),
								end: dayjs(date).endOf('M').toDate(),
							},
						},
					],
				},
				{
					key: 'criminalRecordFilter',
					type: 'single',
					placeholder: '범죄경력조회',
					options: [
						{ label: '조회 완료', value: true },
						{ label: '미조회', value: false },
					],
				},
				{
					key: 'employeeContractCompleteFilter',
					type: 'single',
					placeholder: '근로계약서',
					options: commonCodes.CMN218,
				},
				{
					key: 'employeeReportFilter',
					type: 'single',
					placeholder: '인력신고',
					options: commonCodes.CMN216,
				},
				{
					key: 'insuranceFilter',
					type: 'single',
					placeholder: '4대보험',
					options: [
						{ label: '가입 완료', value: true },
						{ label: '미가입', value: false },
					],
				},
				{
					key: 'liabilityInsuranceFilter',
					type: 'single',
					placeholder: '배상책임보험',
					options: [
						{ label: '가입 완료', value: true },
						{ label: '미가입', value: false },
					],
				},
				{
					key: 'pcorpTagRegistrationFilter',
					type: 'single',
					placeholder: '단말기 등록',
					options: commonCodes.CMN217,
				},
			] as Filter<CheckOption>[],
		[commonCodes, centerOptions, corporationOptions],
	);

	const filterHandler = useCallback(() => {
		const currentFilter: Filter<CheckOption>[] = currentTask.filterKey
			.map((item) => filters.find((filter) => filter.key === item))
			.filter((filter): filter is Filter<CheckOption> => filter !== undefined);
		return [...filters.slice(0, 4), ...currentFilter];
	}, [currentTask, filters]);

	const handleSearch = () => {
		setKeyword(searchValue);
	};

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleSearchValue = (value: string) => setSearchValue(value);

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleSaveEmployeeContractReviewStatus = async () => {
		if (!currentStatusValue || !currentStatus) return;
		const currentData: SaveContractReviewRequest = checkedList
			.filter((item) => item.employeeId && item.serviceContractId && item.centerId)
			.map((item) => ({
				serviceContractId: item.serviceContractId,
				centerId: item.centerId,
				employeeId: item.employeeId,
				recipientId: undefined,
				contractMngtId: item.contractMngtId,
				employeeReportStatCd: item.employeeReportState,
				pcorpTagRegistrationCd: item.deviceRegistrationRegistrationStatus,
				liabilityInsuranceSubscriptionYn: item.isLiabilityInsuranceRegistrationCompleted,
				criminalRecordRegistrationYn: item.isCrimeCheckCompleted,
				employeeContractCompleteCd: item.employeeContractCompletionStatus,
				insuranceRequestYn: item.isInsuranceEnrollmentReportCompleted,
				remark: item.adminMemo,
				[currentStatus.value]: currentStatusValue.value,
			}));

		await saveEmployeeContractReview(currentData);
	};

	const handleDateToString = (value?: string) => {
		if (!value) return '';
		return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
	};

	const handleBulkInsuranceApplication = async () => {
		const handleInsuranceInfo = (item: EmployeeContractReviewListViewType) => ({
			...(item.requiresEmployeeCompensationInsurance && {
				accidentInsuranceRequestDate: handleDateToString(item.insuranceEnrollmentDate),
				accidentInsuranceRequestAmt: item.reportedInsuranceWage,
				accidentInsuranceApproveYn: item.requiresEmployeeCompensationInsurance,
			}),
			...(item.requiresEmploymentInsurance && {
				employInsuranceRequestDate: handleDateToString(item.insuranceEnrollmentDate),
				employInsuranceRequestAmt: item.reportedInsuranceWage,
				employInsuranceApproveYn: item.requiresEmploymentInsurance,
			}),
			...(item.requiresNationalHealthInsurance && {
				healthInsuranceRequestDate: handleDateToString(item.insuranceEnrollmentDate),
				healthInsuranceRequestAmt: item.reportedInsuranceWage,
				healthInsuranceApproveYn: item.requiresNationalHealthInsurance,
			}),
			...(item.requiresNationalPensionService && {
				pensionInsuranceRequestDate: handleDateToString(item.insuranceEnrollmentDate),
				pensionInsuranceRequestAmt: item.reportedInsuranceWage,
				pensionInsuranceApproveYn: item.requiresNationalPensionService,
			}),
		});

		const createInsuranceListData: CreateAcquirementInsuranceListRequest[] = checkedList.map(
			(item) => ({
				centerId: item.centerId,
				employeeId: item.employeeId,
				insuranceRequestTypeCd: 'CMN073.10',
				weekPerWorkHourCnt: item.weeklyContractedHours,
				dependents: [],
				insuranceRequestDetails: handleInsuranceInfo(item),
				unemploymentBenefitPreconditionFullYn: item.requiresEmploymentInsurance,
				requestDesc: '',
			}),
		);

		await createInsuranceList({
			employeeId: checkedList[0].employeeId,
			params: createInsuranceListData,
		});
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>신규계약 내역이 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					해당 월에 추가된 신규계약이 없습니다.
					<br />
					연월 또는 필터를 다시 설정해 주세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	const renderInsuranceButton = useMemo(
		() =>
			checkedList.length > 0 && currentTask.value === 'insurance' ? (
				<S.SelectionContainer>
					<CRButton.Default
						palette='gray'
						size='small'
						style={{ padding: '0 1.2rem', borderRadius: '0.8rem' }}
						onClick={handleBulkInsuranceApplication}>
						취득신고 신청
					</CRButton.Default>
					<CRText text={`(${checkedList.length}개 선택)`} color='gray60' />
					<S.Divider />
				</S.SelectionContainer>
			) : undefined,
		[checkedList],
	);

	const renderCustomFilter = useMemo(
		() =>
			checkedList.length > 0 && currentTask.statusSelectionItem ? (
				<S.SelectionContainer>
					{currentTask.statusSelectionItem && (
						<CRChips.Selection
							containerStyle={{
								width: '16rem',
								backgroundColor: currentStatus ? Colors.gray100 : undefined,
								borderColor: Colors.gray90,
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
							hasArrowIcon
							options={currentTask.statusSelectionItem}
							placeholder='업무 선택'
							filterKey='single'
							currentValue={currentStatus ? [currentStatus] : undefined}
							labelColor={currentStatus ? 'gray00' : 'gray60'}
							onChangeValue={(value) => {
								setCurrentStatus(value);
								setCurrentStatusValue(undefined);
							}}
						/>
					)}
					{currentTask.statusSelectionItem && (
						<CRChips.Selection
							containerStyle={{
								width: '16rem',
								backgroundColor: currentStatus ? Colors.gray100 : undefined,
								borderColor: Colors.gray90,
							}}
							hasArrowIcon
							disabled={!currentStatus}
							options={currentStatus?.data}
							placeholder='값 선택'
							filterKey='single'
							currentValue={currentStatusValue ? [currentStatusValue] : undefined}
							labelColor={currentStatusValue ? 'gray00' : 'gray60'}
							onChangeValue={setCurrentStatusValue}
						/>
					)}
					<CRButton.Default
						palette='gray'
						size='small'
						style={{ padding: '0 1.2rem', borderRadius: '0.8rem' }}
						disabled={!(currentStatusValue && currentStatus)}
						onClick={handleSaveEmployeeContractReviewStatus}>
						변경
					</CRButton.Default>
					<CRText text={`(${checkedList.length}개 선택)`} color='gray60' />
					<S.Divider />
				</S.SelectionContainer>
			) : undefined,
		[checkedList, currentTask, currentStatus, currentStatusValue],
	);

	useEffect(() => {
		// TODO: 표 목록 데이터 불러올 때 pageInfo state 변경
	}, []);

	useEffect(() => {
		if (checkedList.length === 0) {
			setCurrentStatus(undefined);
			setCurrentStatusValue(undefined);
		}
	}, [checkedList]);

	return (
		<S.Container>
			{/* 컴포넌트화 필요 */}
			<S.TaskCategorySelectorContainer>
				{EMPLOYEE_CONTRACT_REVIEW_TASK_CONFIG.map((item, index) => (
					<React.Fragment key={item.value}>
						<CRButton.Default
							type={item.value === currentTask.value ? 'filled' : 'outlined'}
							palette='gray'
							size='default'
							style={{ padding: '0.6rem 1.6rem' }}
							onClick={() => {
								setCurrentTask(item);
								setCurrentStatus(undefined);
								setCurrentStatusValue(undefined);
							}}>
							<CRText
								typography='body'
								color={item.value === currentTask.value ? 'white' : 'gray00'}>
								{item.label}
							</CRText>
						</CRButton.Default>
						{index === 0 && <S.Divider />}
					</React.Fragment>
				))}
			</S.TaskCategorySelectorContainer>
			<CRTable.BackBlind>
				<S.YearMonthPickerContainer>
					<CRTableMonthSelector value={date} onChange={handleChangeDate} />
				</S.YearMonthPickerContainer>
				<CRTableHeader
					pageInfo={pageInfo}
					onChangePageInfo={handleChangePageInfo}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
					stickyMode
					filters={filterHandler()}
					searchValue={searchValue}
					onChangeSearchValue={handleSearchValue}
					onSearch={handleSearch}
					onRefresh={refetch}
					showRefresh
					placeholder='직원명 검색'
					showViewCount
					renderCustomFilter={
						currentTask.value === 'insurance' ? renderInsuranceButton : renderCustomFilter
					}
				/>

				{isLoading ? (
					<CRSpinner />
				) : employeeContractReviewData &&
				  employeeContractReviewData.employeeContractReviewList.length > 0 ? (
					<EmployeeContractManagementTable
						checkedList={checkedList}
						setCheckedList={setCheckedList}
						employeeContractData={employeeContractReviewData.employeeContractReviewList}
						tableRenderKeys={currentTask.tableRenderKeys}
						sideModalDefaultActiveKey={currentTask.sideModalDefaultActiveKey}
					/>
				) : (
					renderEmpty
				)}
			</CRTable.BackBlind>
		</S.Container>
	);
}

export default EmployeeNewContractTab;
