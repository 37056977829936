import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1.6rem;
	overflow-y: auto;
	padding: 2.4rem 2.4rem;
	margin: 0 auto;
	flex: 1;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};};
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;
