import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { QueryClient } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import DailyRoundingDialog from 'components/domain/dialog/DailyRoundingDialog';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { regex } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useDeleteEmployeeWorkHistory,
	useSaveEmployeeWorkHistory,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeWorkInfoViewType } from 'types/view/employee';
import { EmployeeDailyWorkHistoryViewType } from 'types/view/workSchedule';

import DeleteDialog from '../DeleteDialog';
import WorkLogLocationDialog from '../WorkLogLocationDialog';
import { employeeDailyWorkLogDefaultValue, handleRequestBody } from './config';
import { ActualWorkFormType, EmployeeDailyWorkLogSettingYupResolver } from './inputSchema';
import * as S from './styles';

type Props = {
	targetDate: Dayjs;
	workhistoryOfTargetDate?: EmployeeDailyWorkHistoryViewType;
	currentEmployee?: EmployeeWorkInfoViewType;
};

function EmployeeDailyWorkLogSettingDialog({
	targetDate,
	workhistoryOfTargetDate,
	currentEmployee,
}: Props): React.ReactElement {
	dayjs.extend(isoWeek);

	const { showDialog, hideDialog } = useDialog();
	const defaultValues = employeeDailyWorkLogDefaultValue(workhistoryOfTargetDate, targetDate);

	const targetActualWorkData = workhistoryOfTargetDate?.actualWorkInfo ?? undefined;
	const targetWorkHistoryData = workhistoryOfTargetDate?.workHistoryInfo ?? undefined;
	const isRegistryDialog = !targetWorkHistoryData;
	const hasRoundingInfo =
		(workhistoryOfTargetDate?.managerTagInfo ?? []).length > 0 ||
		(workhistoryOfTargetDate?.workExecuteLogInfo ?? []).length > 0;

	const { data: commonCodes = { CMN200: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN200'] },
		commonCodeAdapter,
	);

	const invalidGetEmployeeWorkHistoryQuery = (client: QueryClient) => {
		client.invalidateQueries([
			endpoint.getEmployeeWorkHistory.key,
			{
				employeeId: currentEmployee?.employeeId,
				yearMonth: targetDate.format('YYYYMM'),
			},
		]);
		client.invalidateQueries([
			endpoint.getEmployeeWorkInfoList.key,
			{
				centerId: currentEmployee?.centerId,
				yearMonth: targetDate.format('YYYYMM'),
			},
		]);
	};

	const { mutate: saveWorkHistory } = useSaveEmployeeWorkHistory((client, returnData) => {
		if (!returnData) {
			Toast.error('근무내용 수정에 실패하였습니다. 잠시 후 다시 시도해주세요.');
		} else {
			Toast.success(`${targetDate.format('YYYY년 MM월 DD일')} 근무내용 수정을 완료하였습니다.`);
			invalidGetEmployeeWorkHistoryQuery(client);
			hideDialog();
		}
	});

	const { mutate: deleteWorkHisory } = useDeleteEmployeeWorkHistory((client, returnData) => {
		if (!returnData) {
			Toast.error('근무내용 삭제에 실패했습니다. 잠시 후 다시 시도해주세요.');
		} else {
			Toast.success(`${targetDate.format('YYYY년 MM월 DD일')} 근무내용 삭제를 완료하였습니다.`);
			invalidGetEmployeeWorkHistoryQuery(client);
			hideDialog();
		}
	});

	const {
		control,
		handleSubmit,
		trigger,
		getValues,
		setValue,
		watch,
		formState: { errors },
	} = useForm<ActualWorkFormType>({
		resolver: yupResolver(EmployeeDailyWorkLogSettingYupResolver),
		mode: 'onChange',
		defaultValues,
	});

	const roundingTime = useMemo(() => {
		if (
			!(
				workhistoryOfTargetDate?.minRoundingStartTime && workhistoryOfTargetDate?.maxRoundingEndTime
			)
		)
			return {
				startTime: undefined,
				endTime: undefined,
				labelText: '방문 없음',
			};

		const startTime = workhistoryOfTargetDate.minRoundingStartTime;
		const endTime = workhistoryOfTargetDate.maxRoundingEndTime;
		const labelText = `${startTime.format('HH:mm')} ~ ${endTime.format('HH:mm')}`;

		return {
			startTime: workhistoryOfTargetDate.minRoundingStartTime,
			endTime: workhistoryOfTargetDate.maxRoundingEndTime,
			labelText,
		};
	}, []);

	const totalWorkTime = useMemo(() => {
		const defaultWorkTimeHour = getValues('defaultWorkTimeHour') || 0;
		const defaultWorkTimeMinute = getValues('defaultWorkTimeMinute') || 0;
		const approvedOverWorkTimeHour = getValues('approvedOverWorkTimeHour') || 0;
		const approvedOverWorkTimeMinute = getValues('approvedOverWorkTimeMinute') || 0;

		return (
			Number(defaultWorkTimeHour) * 60 +
			Number(defaultWorkTimeMinute) +
			Number(approvedOverWorkTimeMinute) +
			Number(approvedOverWorkTimeHour) * 60
		);
	}, [watch()]);

	const actualOverWorkCnt = () => {
		const workhourCnt = workhistoryOfTargetDate?.initialEmployeeActualWorkHourCnt || 0;
		const defaultWorkTimeHour = getValues('defaultWorkTimeHour') || 0;
		const defaultWorkTimeMinute = getValues('defaultWorkTimeMinute') || 0;
		const restHourCnt = getValues('restPeriodTimeCnt') || 0;

		const totalOverWorkTime =
			workhourCnt - (defaultWorkTimeHour * 60 + defaultWorkTimeMinute) - restHourCnt;
		return totalOverWorkTime < 0 ? 0 : totalOverWorkTime;
	};

	const totalWorkTimeErrorState = {
		inputStatus:
			errors.defaultWorkTimeHour ||
			errors.defaultWorkTimeMinute ||
			errors.approvedOverWorkTimeMinute ||
			errors.approvedOverWorkTimeHour,
		errorMessages:
			errors.defaultWorkTimeHour?.message ||
			errors.defaultWorkTimeMinute?.message ||
			errors.approvedOverWorkTimeHour?.message ||
			errors.approvedOverWorkTimeMinute?.message,
	};

	const handleDeleteWorkHistory = () => {
		if (
			!currentEmployee ||
			!targetWorkHistoryData?.data.workScheduleId ||
			!targetActualWorkData?.data.workLeaveId
		) {
			Toast.error('근무내용 삭제에 실패하였습니다. 잠시 후에 다시 시도해주세요.');
			return;
		}
		deleteWorkHisory({
			centerId: currentEmployee?.centerId,
			yearMonth: targetDate.format('YYYYMM'),
			employeeId: currentEmployee?.employeeId,
			workScheduleDt: targetDate.format('YYYYMMDD'),
			workScheduleId: targetWorkHistoryData.data.workScheduleId,
			workLeaveId: targetActualWorkData.data.workLeaveId,
		});
		hideDialog();
	};

	const handleClickDeleteButton = () => {
		showDialog(() => (
			<DeleteDialog
				title='근무내용 삭제'
				content={`${targetDate.format('M월 D일')} 근무내용을 삭제 하시겠습니까?`}
				hideDialog={hideDialog}
				cancelOption={{ text: '취소' }}
				successOption={{
					text: '삭제',
					successCallback: handleDeleteWorkHistory,
				}}
			/>
		));
	};

	const locationInfo = {
		workStartPoint: {
			latitude: targetActualWorkData?.data.workLatitude,
			longitude: targetActualWorkData?.data.workLongitude,
			ip: targetActualWorkData?.data.workIp,
		},
		workEndPoint: {
			latitude: targetActualWorkData?.data.leaveLatitude,
			longitude: targetActualWorkData?.data.leaveLongitude,
			ip: targetActualWorkData?.data.leaveIp,
		},
	};
	const hasLocationInfo =
		Object.values(locationInfo.workStartPoint).some((value) => value) ||
		Object.values(locationInfo.workEndPoint).some((value) => value);

	const handleClickLoacationInfoButton = () => {
		showDialog(() => (
			<WorkLogLocationDialog
				workEndLocationInfo={locationInfo.workEndPoint}
				workStartLocationInfo={locationInfo.workStartPoint}
			/>
		));
	};

	const handleClickRoundingTimeDetailButton = () => {
		showDialog(() => (
			<DailyRoundingDialog
				title={targetDate.format('YYYY년 M월 D일')}
				managerTagInfo={workhistoryOfTargetDate?.managerTagInfo ?? []}
				workExecuteLogInfo={workhistoryOfTargetDate?.workExecuteLogInfo ?? []}
			/>
		));
	};

	const removeLeadingZero = (value: number | string) => {
		if (value === 0 && typeof value === 'string') return 0;
		return Number(value.toString().replace(/^0+/, ''));
	};

	const handleOverworkMinuteChange = (
		currentValue: number,
		changeValue: (...event: any[]) => void,
	) => {
		if (currentValue < 30) return changeValue(0);
		return changeValue(30);
	};

	const triggerValidationOfWorkTime = () => {
		trigger([
			'defaultWorkTimeHour',
			'defaultWorkTimeMinute',
			'approvedOverWorkTimeHour',
			'approvedOverWorkTimeMinute',
			'workStartTime',
			'workEndTime',
		]);
	};

	const handleRestPeriodTime = () => {
		const defaultWorkTimeHour = getValues('defaultWorkTimeHour') || 0;
		const defaultWorkTimeMinute = getValues('defaultWorkTimeMinute') || 0;
		const approvedOverWorkTimeHour = getValues('approvedOverWorkTimeHour') || 0;
		const approvedOverWorkTimeMinute = getValues('approvedOverWorkTimeMinute') || 0;

		const currentTotalWorkTime =
			Number(defaultWorkTimeHour) * 60 +
			Number(defaultWorkTimeMinute) +
			Number(approvedOverWorkTimeMinute) +
			Number(approvedOverWorkTimeHour) * 60;
		const calculatedRestPeriod = Math.floor(currentTotalWorkTime / 240) * 30;

		setValue('restPeriodTimeCnt', calculatedRestPeriod);
	};

	const handleChangeHour = (onChange: (...event: any[]) => void) => (value: number) => {
		const currentValue = removeLeadingZero(value);
		if (currentValue <= 12) onChange(currentValue);
		triggerValidationOfWorkTime();
		handleRestPeriodTime();
	};

	const handleChangeMinute = (onChange: (...event: any[]) => void) => (value: number) => {
		const currentValue = removeLeadingZero(value);
		if (currentValue <= 60) onChange(currentValue);
		triggerValidationOfWorkTime();
		handleRestPeriodTime();
	};

	const hadleTimeCntText = (value: number) => {
		if (!value) return '0분';
		const hour = Math.floor(value / 60);
		const minute = value % 60;

		return hour === 0 ? `${minute}분` : `${hour}시간 ${minute === 0 ? '' : `${minute}분`}`;
	};

	const roundUpToNearestTen = (time: string) => {
		const timeObj = dayjs(`${targetDate.format('YYYY.MM.DD')} ${time}`);
		const roundedMinutes = Math.ceil(timeObj.minute() / 10) * 10;

		if (roundedMinutes === 60) {
			return timeObj.add(1, 'hour').startOf('hour');
		}
		return timeObj.minute(roundedMinutes);
	};

	const calcultateApprovedWorkEndTime = (startTime: string) => {
		const start = dayjs(`${targetDate.format('YYYY.MM.DD')} ${startTime}`);
		const endTime = start
			.add(totalWorkTime, 'minute')
			.add(getValues('restPeriodTimeCnt'), 'minute');

		return endTime;
	};

	const calcultateApprovedWorkTime = (currentStartTime: string) => {
		const validatedWorkStartTime = regex.time.test(currentStartTime)
			? getValues('workStartTime')
			: '09:00';
		const workStartTime = roundUpToNearestTen(validatedWorkStartTime);
		const workEndTime = calcultateApprovedWorkEndTime(workStartTime.format('HH:mm'));

		return {
			startTime: workStartTime,
			endTime: workEndTime,
			labelText: `${workStartTime.format('HH:mm')} ~ ${workEndTime.format('HH:mm')}`,
		};
	};

	const disabled = !!Object.keys(errors).length;

	const onSubmit = (currentData: ActualWorkFormType) => {
		if (!currentEmployee) {
			Toast.error('근무내용 수정에 실패하였습니다. 잠시 후 다시 시도해주세요.');
			return;
		}
		const employeeMonthMaxOverworkTime = currentEmployee.overWorkMaxTimeCnt * 60;
		const totalOverWorkTimeOfMonth =
			(workhistoryOfTargetDate?.totalOverWorkTimeOfMonth || 0) -
			(targetWorkHistoryData?.overWorkTimeCnt || 0) +
			currentData.approvedOverWorkTimeHour * 60 +
			currentData.approvedOverWorkTimeMinute;
		const approvedWorkTime = calcultateApprovedWorkTime(currentData.workStartTime);
		const hasRoundTime = !!roundingTime.startTime && !!roundingTime.endTime;

		const validateWorkTimeWithRounding = hasRoundTime
			? (approvedWorkTime.startTime.isBefore(roundingTime.startTime, 'minute') ||
					approvedWorkTime.startTime.isSame(roundingTime.startTime, 'minute')) &&
				(approvedWorkTime.endTime.isAfter(roundingTime.endTime, 'minute') ||
					approvedWorkTime.endTime.isSame(roundingTime.endTime, 'minute'))
			: true;

		const totalWorkCntOfTargetWeek =
			(workhistoryOfTargetDate?.totalWorkTimeCntOfTargetWeek || 0) -
			(targetWorkHistoryData?.overWorkTimeCnt || 0) -
			(targetWorkHistoryData?.workHourCnt || 0) +
			totalWorkTime;

		if (totalWorkCntOfTargetWeek > 52 * 60) {
			Toast.error('주간 최대 근무시간은 52시간을 초과할 수 없습니다.');
			return;
		}

		if (approvedWorkTime.endTime.hour() >= 22) {
			Toast.error('근무인정시간은 22시를 넘을 수 없습니다.');
			return;
		}

		if (!validateWorkTimeWithRounding) {
			Toast.error('라운딩 방문시간이 근무인정시간 내 포함되어야 합니다.');
			return;
		}

		if (totalOverWorkTimeOfMonth > employeeMonthMaxOverworkTime) {
			Toast.error(
				`월 최대 연장근로 ${employeeMonthMaxOverworkTime / 60}시간을 초과할 수 없습니다.`,
			);
			return;
		}
		const currentRequestBody = {
			...handleRequestBody({
				currentEmployee,
				currentData,
				targetWorkHistoryData: targetWorkHistoryData?.data,
				targetActualWorkData,
				targetDate,
			}),
			startTime: approvedWorkTime.startTime.format('HHmm'),
			endTime: approvedWorkTime.endTime.format('HHmm'),
		};
		saveWorkHistory(currentRequestBody);
	};

	return (
		<CRDialog
			title={`${targetDate.format('YYYY년 M월 D일')} 근무내용`}
			onClickClose={hideDialog}
			type='M'
			body={
				<S.Container>
					<S.BodyContainer>
						<S.BodyLeftContentContainer>
							<S.SectionContainer>
								<CRText typography='bodyB' color='gray10'>
									기본근무
									<CRText text='*' typography='label' color='primary60' />
								</CRText>
								<CRInputLabel label='기본근무시간'>
									<S.InputHorizontalAlignContainer>
										<Controller
											render={({ field: { onChange, value } }) => (
												<CRInput.Default
													value={value}
													onChange={handleChangeHour(onChange)}
													suffix='시간'
													type='number'
													status={totalWorkTimeErrorState.inputStatus ? 'error' : 'default'}
												/>
											)}
											name='defaultWorkTimeHour'
											control={control}
										/>
										<Controller
											render={({ field: { onChange, value } }) => (
												<CRInput.Default
													value={value}
													onChange={handleChangeMinute(onChange)}
													suffix='분'
													type='number'
													status={totalWorkTimeErrorState.inputStatus ? 'error' : 'default'}
													onBlur={() => handleOverworkMinuteChange(value, onChange)}
													onPressEnter={() => handleOverworkMinuteChange(value, onChange)}
												/>
											)}
											name='defaultWorkTimeMinute'
											control={control}
										/>
									</S.InputHorizontalAlignContainer>
									<CRText
										text={totalWorkTimeErrorState.errorMessages || '30분 단위 설정 가능'}
										typography='label'
										color={totalWorkTimeErrorState.errorMessages ? 'primary60' : 'gray60'}
									/>
								</CRInputLabel>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel label='휴게 시간' isRequired>
											<S.CounterContainer>
												<CRInput.Counter
													value={value}
													renderValue={(value) => (
														<CRText text={hadleTimeCntText(value)} typography='body' />
													)}
													onChange={onChange}
													countStepNumber={30}
													plusColor='gray95'
													minusColor='gray95'
													buttonSize='small'
													min={Math.floor(totalWorkTime / 240) * 30}
												/>
											</S.CounterContainer>
										</CRInputLabel>
									)}
									name='restPeriodTimeCnt'
									control={control}
								/>
							</S.SectionContainer>
							<S.SectionContainer>
								<CRText text='연장근무' typography='bodyB' color='gray10' />
								<CRInputLabel
									label={
										<CRText
											typography='label'
											color='gray60'
											style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
											근무자 기록시간
											<RDTooltip content={`근무자 최초 출퇴근 기록의\n연장근무시간`} side='top'>
												<CRIcon
													src={Assets.icon.help}
													width='1.6rem'
													height='1.6rem'
													style={{ marginBottom: '0.2rem' }}
												/>
											</RDTooltip>
										</CRText>
									}>
									<CRInput.Default
										disabled
										value={hadleTimeCntText(actualOverWorkCnt())}
										suffix={
											<S.IconContainer>
												<S.TimeIcon
													src={Assets.icon.accessTimeBlack}
													width='2.4rem'
													height='2.4rem'
												/>
											</S.IconContainer>
										}
									/>
								</CRInputLabel>
								<CRInputLabel label='인정시간'>
									<S.InputHorizontalAlignContainer>
										<Controller
											render={({ field: { onChange, value } }) => (
												<CRInput.Default
													value={value}
													onChange={handleChangeHour(onChange)}
													suffix='시간'
													type='number'
													status={totalWorkTimeErrorState.inputStatus ? 'error' : 'default'}
												/>
											)}
											name='approvedOverWorkTimeHour'
											control={control}
										/>
										<Controller
											render={({ field: { onChange, value } }) => (
												<CRInput.Default
													value={value}
													onChange={handleChangeMinute(onChange)}
													suffix='분'
													type='number'
													status={totalWorkTimeErrorState.inputStatus ? 'error' : 'default'}
													onBlur={() => handleOverworkMinuteChange(value, onChange)}
													onPressEnter={() => handleOverworkMinuteChange(value, onChange)}
												/>
											)}
											name='approvedOverWorkTimeMinute'
											control={control}
										/>
									</S.InputHorizontalAlignContainer>
									<CRText
										text={totalWorkTimeErrorState.errorMessages || '30분 단위 설정 가능'}
										typography='label'
										color={totalWorkTimeErrorState.errorMessages ? 'primary60' : 'gray60'}
									/>
								</CRInputLabel>
							</S.SectionContainer>
						</S.BodyLeftContentContainer>
						<S.BodyRightContentContainer>
							<S.SectionContainer>
								<S.SectionTitleContainer>
									<CRText text='출퇴근기록' typography='bodyB' color='gray10' />
									{hasLocationInfo && (
										<CRButton.Default
											type='outlined'
											palette='gray'
											size='small'
											onClick={handleClickLoacationInfoButton}>
											<CRText text='위치정보' typography='label' color='gray10' />
										</CRButton.Default>
									)}
								</S.SectionTitleContainer>
								<S.InputContainer>
									<S.InputHorizontalAlignContainer>
										<S.InputContainer>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRInputLabel label='출근 시간' isRequired>
														<CRInput.Time
															value={value}
															placeholder='00:00'
															minTime='06:01'
															maxTime='23:59'
															maxLength={5}
															onChange={(value) => {
																onChange(value);
																triggerValidationOfWorkTime();
															}}
															suffix={
																<S.IconContainer>
																	<CRIcon
																		src={Assets.icon.accessTimeBlack}
																		width='2.4rem'
																		height='2.4rem'
																	/>
																</S.IconContainer>
															}
															status={errors.workStartTime?.message ? 'error' : 'default'}
														/>
													</CRInputLabel>
												)}
												name='workStartTime'
												control={control}
											/>
										</S.InputContainer>
										<Controller
											render={({ field: { onChange, value } }) => (
												<S.InputContainer>
													<CRInputLabel label='출근 장소' isRequired betweenGap='0.4rem'>
														<CRInput.Selector
															currentValue={value}
															placeholder='장소 선택'
															items={commonCodes.CMN200}
															onChangeValue={onChange}
															status={errors.workStartPlace ? 'error' : 'default'}
														/>
													</CRInputLabel>
												</S.InputContainer>
											)}
											name='workStartPlace'
											control={control}
										/>
									</S.InputHorizontalAlignContainer>
									<CRText
										typography='label'
										text={
											errors.workStartTime?.message ||
											errors.workStartPlace?.message ||
											'출퇴근 기록은 금일 06시 01분부터 익일 06시까지 설정할 수 있습니다.'
										}
										color={errors.workStartTime || errors.workStartPlace ? 'primary60' : 'gray60'}
									/>
								</S.InputContainer>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='출근 기록 비고'
											renderRightAddon={`${value?.length || 0}/25`}>
											<CRInput.Default
												value={value}
												placeholder='비고 입력'
												maxLength={25}
												onChange={onChange}
												addOnBottom={errors.workStartRemark?.message}
												status={errors.workStartRemark ? 'error' : 'default'}
											/>
										</CRInputLabel>
									)}
									name='workStartRemark'
									control={control}
								/>
								<S.InputContainer>
									<S.InputHorizontalAlignContainer>
										<S.InputContainer>
											<Controller
												render={({ field: { onChange, value } }) => (
													<CRInputLabel label='퇴근 시간' isRequired>
														<CRInput.Time
															value={value}
															placeholder='00:00'
															minTime='00:00'
															maxTime='23:59'
															maxLength={5}
															onChange={(value) => {
																onChange(value);
																triggerValidationOfWorkTime();
															}}
															suffix={
																<S.IconContainer>
																	<CRIcon
																		src={Assets.icon.accessTimeBlack}
																		width='2.4rem'
																		height='2.4rem'
																	/>
																</S.IconContainer>
															}
															status={errors.workEndTime ? 'error' : 'default'}
														/>
													</CRInputLabel>
												)}
												name='workEndTime'
												control={control}
											/>
										</S.InputContainer>
										<Controller
											render={({ field: { onChange, value } }) => (
												<S.InputContainer>
													<CRInputLabel label='퇴근 장소' isRequired betweenGap='0.4rem'>
														<CRInput.Selector
															currentValue={value}
															placeholder='장소 선택'
															items={commonCodes.CMN200}
															onChangeValue={onChange}
															status={errors.workEndPlace ? 'error' : 'default'}
														/>
													</CRInputLabel>
												</S.InputContainer>
											)}
											name='workEndPlace'
											control={control}
										/>
									</S.InputHorizontalAlignContainer>
									<CRText
										typography='label'
										text={errors.workEndTime?.message || errors.workEndPlace?.message || ''}
										color={errors.workEndTime || errors.workEndPlace ? 'primary60' : 'gray60'}
									/>
								</S.InputContainer>
								<Controller
									render={({ field: { onChange, value } }) => (
										<CRInputLabel
											label='퇴근 기록 비고'
											renderRightAddon={`${value?.length || 0}/25`}>
											<CRInput.Default
												value={value}
												placeholder='비고 입력'
												maxLength={25}
												onChange={onChange}
												addOnBottom={errors.workEndRemark?.message}
												status={errors.workEndRemark ? 'error' : 'default'}
											/>
										</CRInputLabel>
									)}
									name='workEndRemark'
									control={control}
								/>
								<CRInputLabel
									label={
										<CRText
											typography='label'
											color='gray60'
											style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
											라운딩 방문시간
											<RDTooltip content='최초 방문시작시간 ~ 마지막 방문종료시간' side='top'>
												<CRIcon
													src={Assets.icon.help}
													width='1.6rem'
													height='1.6rem'
													style={{ marginBottom: '0.2rem' }}
												/>
											</RDTooltip>
										</CRText>
									}
									renderRightAddon={
										hasRoundingInfo && (
											<CRButton.Default
												type='outlined'
												palette='gray'
												size='small'
												onClick={handleClickRoundingTimeDetailButton}>
												<CRText text='상세' typography='label' color='gray10' />
											</CRButton.Default>
										)
									}>
									<CRInput.Default
										disabled
										placeholder='방문 없음'
										value={roundingTime.labelText}
										suffix={
											<S.IconContainer>
												<S.TimeIcon
													src={Assets.icon.accessTimeBlack}
													width='2.4rem'
													height='2.4rem'
												/>
											</S.IconContainer>
										}
									/>
								</CRInputLabel>
							</S.SectionContainer>
							<S.ApprovedWorkLogContainer>
								<CRText
									typography='label'
									color='gray60'
									style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
									근무인정시간
									<RDTooltip
										content={`공단 포털에 입력될 근무시간\n(연장근무, 휴게시간 포함)`}
										side='top'>
										<CRIcon
											src={Assets.icon.help}
											width='1.6rem'
											height='1.6rem'
											style={{ marginBottom: '0.2rem' }}
										/>
									</RDTooltip>
								</CRText>
								<CRText
									text={calcultateApprovedWorkTime(watch('workStartTime')).labelText}
									typography='bodyB'
									color='gray10'
								/>
							</S.ApprovedWorkLogContainer>
						</S.BodyRightContentContainer>
					</S.BodyContainer>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					{!isRegistryDialog && (
						<CRButton.Default
							type='outlined'
							palette='primary'
							size='default'
							onClick={handleClickDeleteButton}>
							삭제
						</CRButton.Default>
					)}
					<S.RightButtonContainer>
						<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
							취소
						</CRButton.Default>
						<CRButton.Default
							palette='primary'
							size='default'
							disabled={disabled}
							onClick={handleSubmit(onSubmit)}>
							{isRegistryDialog ? '등록' : '수정'}
						</CRButton.Default>
					</S.RightButtonContainer>
				</S.ButtonContainer>
			}
		/>
	);
}

export default React.memo(EmployeeDailyWorkLogSettingDialog);
