import styled, { css } from 'styled-components';

export const TableHeader = styled.thead`
	position: sticky;
	z-index: 5;
	height: auto;
	background: ${(props) => props.theme.colors.gray99};
	white-space: nowrap;
	top: 0;
`;

export const TableHeaderRow = styled.tr`
	height: 3.3rem;
	white-space: nowrap;
`;

export const TableHeaderColumn = styled.th`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
	vertical-align: middle;
	overflow: hidden;
	padding: 0.4rem 0.8rem;

	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:first-of-type {
		border-left: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}
`;

export const TableSubHeaderColumn = styled(TableHeaderColumn)`
	text-align: left;
	border-top: none;
`;
