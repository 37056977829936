import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
	position: relative;
`;

export const SectionContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	margin-bottom: 0.8rem;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	flex-direction: column;
	gap: 0.8rem;
`;

export const Label = styled.div`
	${(props) => props.theme.typography.labelB};
	color: ${(props) => props.theme.colors.gray00};
	display: flex;
	justify-content: space-between;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const RightButtonContainer = styled.div`
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
`;

export const OptionContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const CardLabelContainer = styled.div`
	display: flex;
	${(props) => props.theme.typography.bodyB};
	gap: 0.8rem;
	align-items: center;
`;

export const EmptyContainer = styled.div`
	width: 100%;
	height: 9.6rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(p) => p.theme.colors.gray95};
`;

export const TableCellContianer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const TableContentContainer = styled.div`
	padding: 1.6rem;
	width: 100%;
	background-color: ${(p) => p.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const LabelValueContainer = styled.div`
	display: flex;
	min-height: 5.6rem;
`;

export const LabelContainer = styled.div`
	display: flex;
	align-items: center;
	width: 13.6rem;
`;

export const ValueContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const TableLabelContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

export const Header = styled.div`
	padding: 2.4rem 2.4rem 1.6rem;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	height: 7.6rem;
`;

export const Title = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

export const Content = styled.div`
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	margin: 0.1rem;
	flex: 1;
	overflow: auto;

	& > * {
		user-select: auto;
	}
`;

export const Table = styled.table`
	width: 100%;
	/* border: 0.1rem solid ${(props) => props.theme.colors.gray90}; */
`;

export const TableRow = styled.tr`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	padding: 1rem 1.2rem;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
`;

export const TableValueColumn = styled.td`
	${(props) => props.theme.typography.label};
	position: relative;
	box-sizing: border-box;
	padding: 1rem 1.2rem;
	background: ${(props) => props.theme.colors.gray100};
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	&:last-child {
		border-right: 0;
	}
`;

export const LeftButtonContainer = styled.div``;

export const ServiceTypeBadgeContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.8rem;
`;

export const TextBox = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const ServiceTypeBadge = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.primary10};
	background: ${(props) => props.theme.colors.primary90};
	border-radius: 100rem;
	height: 2.4rem;
	padding: 0 0.8rem;
	box-sizing: border-box;
`;

export const InsuranceTableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: scroll;
`;
