import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FieldErrors, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import { DayOfTheWeek } from 'components/base/CRInput/CRDayPicker';
import { ErrorMessage } from 'components/base/CRInputMessage/styles';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { RecipientScheduleAutomation, displayPadTime } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCommonCodes,
	useMyAccountInfo,
	useUpdateRecipientGuardians,
	useUpdateScheduleAutomation,
} from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeDTO, ResponseCode, UpdateScheduleAutomationRequestItem } from 'types/api';
import {
	RecipientGuardianFormViewType,
	RecipientScheduleAutomationFormViewType,
} from 'types/view/recipient';

import * as S from './styles';

enum ServiceType {
	가족요양 = 'CMN083.10',
	일반요양 = 'CMN083.11',
	인지요양 = 'CMN083.12',
	방문목욕 = 'CMN083.20',
	방문간호 = 'CMN083.30',
}

interface Props {
	items?: RecipientScheduleAutomationFormViewType[];
	employees?: EmployeeDTO[];
	onlyEdit?: boolean;
	centerManagers?: CheckOption[];
	onStartChangeSection?: () => void;
	onCancelChangeSection?: () => void;
	isEditSectionChanging?: boolean;
}

function RecipientScheduleAutomationForm({
	items,
	onlyEdit = false,
	employees,
	centerManagers,
	onStartChangeSection,
	onCancelChangeSection,
	isEditSectionChanging,
}: Props): React.ReactElement {
	const { currentRecipient } = useRecipientPage();
	const { data: myAccountInfo } = useMyAccountInfo();
	const [duplicatedArrayIdx, setDuplicatedArrayIdx] = useState<number[]>([]);
	const updateScheduleAutomationMutation = useUpdateScheduleAutomation(async (client, res) => {
		if (res?.code === ResponseCode.SUCCESS) {
			await client.invalidateQueries([
				endpoint.getRecipient.key,
				{ recipientId: res?.data?.recipientId },
			]);
			await client.invalidateQueries([
				endpoint.getRecipientBase.key,
				{ recipientId: res?.data?.recipientId },
			]);

			client.invalidateQueries([
				endpoint.getRecipients.key,
				{ centerIds: myAccountInfo?.centerId },
			]);

			onStartChangeSection?.();
			Toast.success('일정 등록 자동화 설정 정보를 수정했습니다.');
		} else {
			if (res?.message) {
				Toast.error(res.message);
			}

			onCancelChangeSection?.();
		}
	});
	const updateRecipientGuardiansMutation = useUpdateRecipientGuardians(
		async (client, res, variables) => {
			if (res?.recipientId) {
				await client.invalidateQueries([
					endpoint.getRecipient.key,
					{ recipientId: variables.recipientId },
				]);
				await client.invalidateQueries([
					endpoint.getRecipientBase.key,
					{ recipientId: variables.recipientId },
				]);

				client.invalidateQueries([
					endpoint.getRecipients.key,
					{ centerIds: myAccountInfo?.centerId },
				]);

				onStartChangeSection?.();
				Toast.success('일정 등록 자동화 설정 정보를 수정했습니다.');
			} else {
				onCancelChangeSection?.();
			}
		},
	);
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN038', 'CMN180', 'CMN083', 'CMN181'],
		},
		commonCodeAdapter,
	);

	const { showDialog } = useDialog();

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		setError,
		formState: { errors },
	} = useForm<{
		items: RecipientScheduleAutomationFormViewType[];
	}>({
		mode: 'onChange',
		defaultValues: {
			items:
				items?.length === 0
					? [
							{
								serviceKindCd: '',
								employeeId1: undefined,
								days: [],
								monthMethod: undefined,
								monthAmt: '',
								time: {
									serviceStartTime: '',
									serviceEndTime: '',
								},
								holidayExceptYn: true,
								monthAmtOverYn: false,
							},
						]
					: items,
		},
		resolver: yupResolver(RecipientScheduleAutomation),
	});

	const { append, remove, fields } = useFieldArray({
		control,
		name: 'items',
	});
	const itemsList = watch('items');

	const checkDuplicateOtherService = (items: RecipientScheduleAutomationFormViewType[]) => {
		const flatSchedule = items.map((item, itemIndex) => ({
			days: item.days,
			startTime: item.time.serviceStartTime,
			endTime: item.time.serviceEndTime,
			itemIndex,
		}));
		const duplicatedArray: number[] = [];
		for (let i = 0; i < flatSchedule.length; i += 1) {
			for (let j = 0; j < flatSchedule.length; j += 1) {
				// eslint-disable-next-line no-continue
				if (i === j) continue;

				const timeOne = flatSchedule[i];
				const { startTime: _oneStart, endTime: _oneEnd } = timeOne;
				const timeTwo = flatSchedule[j];
				const { startTime: _twoStart, endTime: _twoEnd } = timeTwo;

				const oneStart = displayPadTime(_oneStart);
				const oneEnd = displayPadTime(_oneEnd);
				const twoStart = displayPadTime(_twoStart);
				const twoEnd = displayPadTime(_twoEnd);

				// 중복 시간이 있는지 체크
				const isDuplicated =
					// two의 시작시간이 one의 시간 번위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${twoStart}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// two의 종료시간이 one의 시간 번위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoEnd}:00`) &&
						dayjs(`2000-01-01 ${twoEnd}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// two의 시간 범위가 one의 시간 범위 안에 있을때
					(dayjs(`2000-01-01 ${oneStart}:00`).isBefore(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${twoEnd}:00`).isBefore(`2000-01-01 ${oneEnd}:00`)) ||
					// one의 시간 범위가 two의 시간 범위 안에 있을때
					(dayjs(`2000-01-01 ${twoStart}:00`).isBefore(`2000-01-01 ${oneStart}:00`) &&
						dayjs(`2000-01-01 ${oneEnd}:00`).isBefore(`2000-01-01 ${twoEnd}:00`)) ||
					// one의 시간범위와 two의 시간 범위가 동일할때
					(dayjs(`2000-01-01 ${oneStart}:00`).isSame(`2000-01-01 ${twoStart}:00`) &&
						dayjs(`2000-01-01 ${oneEnd}:00`).isSame(`2000-01-01 ${twoEnd}:00`));
				// 중복된 시간이 있다면 날짜 체크
				if (isDuplicated) {
					const oneDays = timeOne.days;
					const twoDays = timeTwo.days;

					for (let k = 0; k < oneDays.length; k += 1) {
						for (let l = 0; l < twoDays.length; l += 1) {
							if (oneDays[k] === twoDays[l]) {
								if (!duplicatedArray.includes(timeOne.itemIndex)) {
									duplicatedArray.push(timeOne.itemIndex);
								}
								if (!duplicatedArray.includes(timeTwo.itemIndex)) {
									duplicatedArray.push(timeTwo.itemIndex);
								}
							}
						}
					}
				}
			}
		}

		setDuplicatedArrayIdx(duplicatedArray);

		if (duplicatedArray.length) {
			Toast.error('다른 급여 일정과 중복되지 않게 입력해 주십시오.');
		}
		return duplicatedArray.length;
	};

	const onSubmitSuccess = async (form: { items: RecipientScheduleAutomationFormViewType[] }) => {
		if (
			updateRecipientGuardiansMutation.isLoading ||
			!myAccountInfo?.centerId ||
			!currentRecipient?.recipientId
		)
			return;

		// 토스트까지 함수내부에서 처리
		if (checkDuplicateOtherService(form.items)) {
			onCancelChangeSection?.();
			return;
		}

		const params = form.items.map((item) => {
			const param: UpdateScheduleAutomationRequestItem = {
				centerId: myAccountInfo.centerId,
				serviceKindCd: item.serviceKindCd,
				recipientId: currentRecipient.recipientId,
				employeeId1: item.employeeId1?.value?.id,
				serviceStartTime: item.time.serviceStartTime,
				serviceEndTime: item.time.serviceEndTime,
				holidayExceptYn: !!item.holidayExceptYn,
				monthAmtOverYn: !!item.monthAmtOverYn,
				totalDayYn: item.totalDayYn?.[0]?.value,
				scheduleAutomationDays: item.days.map((day) => ({
					scheduleAutomationDayCd: day,
				})),
			};
			if (item.serviceKindCd === ServiceType.방문목욕) {
				param.employeeId2 = item.employeeId2?.value?.id;
				param.automationFeeCd = item.automationFeeCd;
			}
			if (item.serviceKindCd === ServiceType.인지요양) {
				param.managerId = item.managerId;
			}
			if (item.monthMethod === 'count' && !param.totalDayYn) {
				param.monthUseCnt = Number(item.monthUseCnt);
			}

			if (item.monthMethod === 'amount' && !param.totalDayYn) {
				param.monthAmt = Number(item.monthAmt?.replaceAll(',', ''));
			}

			return param;
		});

		updateScheduleAutomationMutation.mutate({
			centerId: myAccountInfo.centerId,
			recipientId: currentRecipient.recipientId,
			params,
		});
	};

	const onSubmitFail = (errors: { items: FieldErrors<RecipientGuardianFormViewType>[] }) => {
		const item = errors?.items?.filter(Boolean)[0];
		onCancelChangeSection?.();
		if (!item) return;
		if (Object.values(item)?.[0]?.message) {
			Toast.error(Object.values(item)[0]?.message);
		} else {
			const twoDepthError = Object.values(Object.values(item)[0]);
			if (twoDepthError.length) {
				Toast.error(Object.values(Object.values(item)[0])?.[0]?.message);
			} else {
				Toast.error('입력폼을 확인해주세요');
			}
		}
	};

	const onSubmitHandler = () => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='변경된 정보 저장'
				content='일정 등록 자동화 설정에서 변경된 정보를 저장합니다.'
				successOption={{
					text: '저장',
					successCallback: () => {
						hideDialog();
						handleSubmit(onSubmitSuccess, onSubmitFail as any)();
					},
				}}
				cancelOption={{
					text: '저장안함',
					callback: () => {
						hideDialog();
						onStartChangeSection?.();
					},
				}}
				hideDialog={() => {
					hideDialog();
					onCancelChangeSection?.();
				}}
			/>
		));
	};

	const transformTimeString = (time = '') => {
		if (time.length <= 2) return time;
		return `${time.slice(0, 2)}:${time.slice(2, time.length)}`;
	};

	const handleChangeTime =
		(index: number) =>
		(dayOfTheWeeks: DayOfTheWeek[], startTime = '', endTime = '') => {
			const newDuplicatedIndexArray = duplicatedArrayIdx.filter((prevIndex) => index !== prevIndex);
			setDuplicatedArrayIdx(newDuplicatedIndexArray);
			setValue(`items.${index}.time.serviceStartTime`, startTime?.replace(':', ''));
			setValue(`items.${index}.time.serviceEndTime`, endTime?.replace(':', ''));
		};

	useEffect(() => {
		if (isEditSectionChanging) {
			onSubmitHandler();
		}
	}, [isEditSectionChanging]);

	const serviceTypeOptions = useMemo(
		() =>
			(commonCodes?.CMN083 || []).filter(
				(item) =>
					item.data?.etcDesc3 !== ServiceType.방문목욕 || item.value === ServiceType.방문목욕,
			),
		[commonCodes],
	);

	const automationFeeOptions = useMemo(() => commonCodes?.CMN181 || [], [commonCodes]);

	const employeesOptions = useMemo(
		() =>
			employees?.map((item) => ({
				label: item.korMemberNm,
				value: {
					name: item.korMemberNm,
					id: item.employeeId,
					birth: item.birthDt,
					duty: item.dutyNms,
				},
			})) || [],
		[employees],
	);

	const handleClickAddGuardian = () => {
		append({
			serviceKindCd: '',
			employeeId1: undefined,
			employeeId2: undefined,
			days: [],
			monthMethod: 'count',
			time: {
				serviceStartTime: '',
				serviceEndTime: '',
			},
			totalDayYn: [],
			holidayExceptYn: true,
		});
	};

	const handleClickRemoveGuardian = (index: number) => () => {
		remove(index);
	};

	const formatIndex = (number: number) => `${number + 1}`.padStart(2, '0');

	return (
		<S.Container>
			<S.Form>
				{!onlyEdit && (
					<S.Label>
						일정 등록 자동화 설정
						<S.ButtonContainer>
							<CRButton.Default
								size='xSmall'
								type='outlined'
								palette='gray'
								onClick={onSubmitHandler}>
								취소
							</CRButton.Default>
							<CRButton.Default
								buttonType='button'
								size='xSmall'
								type='filled'
								palette='gray'
								onClick={handleSubmit(onSubmitSuccess, onSubmitFail as any)}>
								저장
							</CRButton.Default>
						</S.ButtonContainer>
					</S.Label>
				)}
				{fields?.map((field, index) => {
					const totalDayYn = itemsList?.[index]?.totalDayYn?.[0]?.value;
					return (
						<React.Fragment key={field.id}>
							<S.ItemHeader>
								{`급여일정 ${formatIndex(index)}`}
								<S.DeleteButtonContainer>
									<CRButton.Default
										size='xSmall'
										type='outlined'
										onClick={handleClickRemoveGuardian(index)}>
										삭제
									</CRButton.Default>
								</S.DeleteButtonContainer>
							</S.ItemHeader>
							<S.Table>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										급여 종류<S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '0.4rem',
											}}>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInput.Selector
														items={serviceTypeOptions}
														type='small'
														currentValue={serviceTypeOptions.find((item) => item.value === value)}
														onChangeValue={(option: CheckOption) => onChange(option.value)}
														placeholder='급여 종류 입력'
													/>
												)}
												name={`items.${index}.serviceKindCd`}
												control={control}
											/>
											{itemsList?.[index]?.serviceKindCd === ServiceType.방문목욕 && (
												<Controller
													render={({
														field: { onBlur, ref, onChange, value },
														formState: { errors },
													}) => (
														<CRInput.Selector
															status={
																errors.items?.[index]?.automationFeeCd?.message
																	? 'error'
																	: 'default'
															}
															items={automationFeeOptions}
															type='small'
															currentValue={automationFeeOptions.find(
																(item) => item.value === value,
															)}
															onChangeValue={(option: CheckOption) => onChange(option.value)}
															placeholder='수가 선택'
														/>
													)}
													name={`items.${index}.automationFeeCd`}
													control={control}
												/>
											)}
										</div>
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										직원<S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '0.4rem',
											}}>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInput.TableSearchSelector
														placeholder='직원1 선택'
														currentValue={value}
														items={employeesOptions}
														onChange={onChange}
													/>
												)}
												name={`items.${index}.employeeId1`}
												control={control}
											/>
											{itemsList?.[index]?.serviceKindCd === ServiceType.방문목욕 && (
												<Controller
													render={({ field: { ref, onChange, value }, formState: { errors } }) => (
														<CRInput.TableSearchSelector
															placeholder='직원2 선택'
															currentValue={value}
															items={employeesOptions}
															onChange={onChange}
														/>
													)}
													name={`items.${index}.employeeId2`}
													control={control}
												/>
											)}
										</div>
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										이용 요일<S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem', verticalAlign: 'middle' }}>
										<S.DayContainer>
											<Controller
												render={({ field: { onBlur, ref, onChange, value } }) => (
													<CRInput.DayPicker
														value={value}
														disabled={totalDayYn}
														onChange={onChange}
													/>
												)}
												name={`items.${index}.days`}
												control={control}
											/>
											<S.LabelContainer>
												<Controller
													render={({ field: { value, ref, onChange }, formState: { errors } }) => (
														<CRCheckBoxGroup
															ref={ref}
															checkType='multiple'
															onChange={(option) => {
																if (option?.[0]?.value) {
																	setValue(`items.${index}.days`, Object.values(DayOfTheWeek));

																	setValue(`items.${index}.holidayExceptYn`, false);
																	setValue(`items.${index}.monthUseCnt`, undefined);
																	setValue(`items.${index}.monthAmt`, '');
																	setValue(`items.${index}.monthMethod`, '');
																	setError(`items.${index}.days`, {
																		message: '',
																	});
																}
																onChange(option);
															}}
															type='checkbox'
															value={value}
															options={[
																{
																	label: '전체 선택',
																	value: true,
																},
															]}
														/>
													)}
													name={`items.${index}.totalDayYn`}
													control={control}
												/>
												<RDTooltip
													content={
														<>
															요일, 이용횟수, 월금액과 관계 없이
															<br /> 월 전체를 일정으로 등록합니다.
														</>
													}>
													<S.QuestionMark src={Assets.icon.help} alt='이용요일 도움말' />
												</RDTooltip>
											</S.LabelContainer>
										</S.DayContainer>
										<div>
											{errors?.items?.[index]?.days?.message && (
												<ErrorMessage>{errors?.items?.[index]?.days?.message}</ErrorMessage>
											)}
										</div>
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										등록 기준<S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn
										style={{
											width: '24rem',
											verticalAlign: 'middle',
											background: totalDayYn ? Colors.gray99 : Colors.gray100,
										}}>
										<Controller
											render={({
												field: { value, onBlur, ref, onChange },
												formState: { errors },
											}) => {
												const options = [
													{
														label: '월 이용횟수',
														value: 'count',
													},
													{
														label: '월금액',
														value: 'amount',
													},
												];
												console.log('value', value);
												const currentValue = options.find((item) => item.value === value);
												// if (value === undefined) {
												// 	setValue(`items.${index}.monthMethod`, );
												// }
												return (
													<CRCheckBoxGroup
														disabled={totalDayYn}
														ref={ref}
														checkType='single'
														onChange={(e) => onChange(e?.[0]?.value)}
														type='radio'
														gap={0.8}
														value={currentValue && [currentValue]}
														options={options}
													/>
												);
											}}
											name={`items.${index}.monthMethod`}
											control={control}
										/>
									</S.TableValueColumn>
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										이용일정<S.RequiredMark>*</S.RequiredMark>
									</S.TableLabelColumn>
									<S.TableValueColumn style={{ width: '24rem' }}>
										<Controller
											render={({ field: { value }, formState: { errors } }) => (
												<>
													<CRInput.TimePicker
														type='time'
														start={transformTimeString(value?.serviceStartTime)}
														end={transformTimeString(value?.serviceEndTime)}
														onChange={handleChangeTime(index)}
													/>
													{duplicatedArrayIdx.includes(index) ? (
														<ErrorMessage>
															다른 급여 일정과 중복되지 않게 입력해 주십시오.
														</ErrorMessage>
													) : (
														''
													)}
													{errors && errors?.items?.[index]?.time && (
														<ErrorMessage>
															{(
																errors?.items?.[index]?.time?.serviceStartTime ||
																errors?.items?.[index]?.time?.serviceEndTime
															)?.message || ''}
														</ErrorMessage>
													)}
												</>
											)}
											name={`items.${index}.time`}
											control={control}
										/>
									</S.TableValueColumn>
									{itemsList?.[index]?.monthMethod === 'count' && (
										<>
											<S.TableLabelColumn style={{ width: '10.4rem' }}>
												월 최대
												<br />
												<S.LabelContainer>
													이용횟수<S.RequiredMark>*</S.RequiredMark>
													<RDTooltip
														content={
															<>
																선택한 요일에 입력한 횟수까지
																<br />
																일정을 등록합니다.
															</>
														}>
														<S.QuestionMark src={Assets.icon.help} alt='이용요일 도움말' />
													</RDTooltip>
												</S.LabelContainer>
											</S.TableLabelColumn>
											<S.TableValueColumn
												style={{
													width: '24rem',
													background: totalDayYn ? Colors.gray99 : Colors.gray100,
												}}>
												<Controller
													render={({
														field: { onBlur, ref, onChange, value },
														formState: { errors },
													}) => (
														<CRInput.TableInput
															disabled={totalDayYn}
															ref={ref}
															onBlur={onBlur}
															onChange={onChange}
															value={value ? String(value) : ''}
															placeholder='월 최대 이용횟수 입력'
															suffix='회'
														/>
													)}
													name={`items.${index}.monthUseCnt`}
													control={control}
												/>
											</S.TableValueColumn>
										</>
									)}
									{itemsList?.[index]?.monthMethod === 'amount' && (
										<>
											<S.TableLabelColumn style={{ width: '10.4rem' }}>
												<S.LabelContainer>
													최대 월금액<S.RequiredMark>*</S.RequiredMark>
													<RDTooltip
														content={
															<>
																입력한 월금액을 초과하지 않을 때까지
																<br />
																선택한 요일에 일정을 등록합니다.
															</>
														}>
														<S.QuestionMark src={Assets.icon.help} alt='이용요일 도움말' />
													</RDTooltip>
												</S.LabelContainer>
											</S.TableLabelColumn>
											<S.TableValueColumn
												style={{
													width: '24rem',
													background: totalDayYn ? Colors.gray99 : Colors.gray100,
												}}>
												<Controller
													render={({
														field: { onBlur, ref, onChange, value },
														formState: { errors },
													}) => (
														<CRInput.TableInput
															disabled={totalDayYn}
															ref={ref}
															type='comma'
															onBlur={onBlur}
															onChange={onChange}
															value={value ? value.toLocaleString() : ''}
															placeholder='최대 월금액 입력'
															suffix='원'
														/>
													)}
													name={`items.${index}.monthAmt`}
													control={control}
												/>
											</S.TableValueColumn>
										</>
									)}
								</S.TableRow>
								<S.TableRow>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										공휴일
										<br />
										<S.LabelContainer>
											근무일정<S.RequiredMark>*</S.RequiredMark>
											<RDTooltip content={<>일정 등록시 공휴일 포함 여부를 결정합니다.</>}>
												<S.QuestionMark src={Assets.icon.help} alt='공휴일 근무일정' />
											</RDTooltip>
										</S.LabelContainer>
									</S.TableLabelColumn>
									<S.TableValueColumn
										style={{
											width: '24rem',
											verticalAlign: 'middle',
											background: totalDayYn ? Colors.gray99 : Colors.gray100,
										}}>
										<Controller
											render={({
												field: { value, onBlur, ref, onChange },
												formState: { errors },
											}) => {
												const options = [
													{
														label: '미포함',
														value: true,
													},
													{
														label: '포함',
														value: false,
													},
												];
												const currentValue = options.find((item) => item.value === value);

												return (
													<CRCheckBoxGroup
														disabled={totalDayYn}
														ref={ref}
														checkType='single'
														onChange={(e) => {
															onChange(e?.[0]?.value);
														}}
														type='radio'
														gap={0.8}
														value={currentValue && [currentValue]}
														options={options}
													/>
												);
											}}
											name={`items.${index}.holidayExceptYn`}
											control={control}
										/>
									</S.TableValueColumn>
									<S.TableLabelColumn style={{ width: '10.4rem' }}>
										<S.LabelContainer>
											월 한도액<S.RequiredMark>*</S.RequiredMark>
											<RDTooltip
												content={
													<>
														일정 등록시 월 한도액 초과하여
														<br />
														일정 입력을 허용할 수 있습니다.
													</>
												}>
												<S.QuestionMark src={Assets.icon.help} alt='월 한도액 도움말' />
											</RDTooltip>
										</S.LabelContainer>
									</S.TableLabelColumn>
									<S.TableValueColumn
										style={{
											width: '24rem',
											verticalAlign: 'middle',
										}}>
										<Controller
											render={({
												field: { value, onBlur, ref, onChange },
												formState: { errors },
											}) => {
												const options = [
													{
														label: '초과 불가',
														value: false,
													},
													{
														label: '초과 허용',
														value: true,
													},
												];
												const currentValue = options.find((item) => item.value === value);
												if (value === undefined) {
													setValue(`items.${index}.monthAmtOverYn`, true);
												}
												return (
													<CRCheckBoxGroup
														ref={ref}
														checkType='single'
														onChange={(e) => onChange(e?.[0]?.value)}
														type='radio'
														gap={0.8}
														value={currentValue && [currentValue]}
														options={options}
													/>
												);
											}}
											name={`items.${index}.monthAmtOverYn`}
											control={control}
										/>
									</S.TableValueColumn>
								</S.TableRow>
								{itemsList?.[index]?.serviceKindCd === ServiceType.인지요양 && (
									<S.TableRow>
										<S.TableLabelColumn style={{ width: '10.4rem' }}>
											프로그램 <br /> 관리자<S.RequiredMark>*</S.RequiredMark>
										</S.TableLabelColumn>
										<S.TableValueColumn style={{ width: '24rem' }}>
											<Controller
												render={({
													field: { onBlur, ref, onChange, value },
													formState: { errors },
												}) => (
													<CRInput.Selector
														status={errors.items?.[index]?.managerId?.message ? 'error' : 'default'}
														items={centerManagers}
														type='small'
														currentValue={centerManagers?.find((item) => item.value === value)}
														onChangeValue={(option: CheckOption) => onChange(option.value)}
														placeholder='프로그램 관리자 선택'
													/>
												)}
												name={`items.${index}.managerId`}
												control={control}
											/>
										</S.TableValueColumn>
										<S.TableLabelColumn colSpan={2} />
									</S.TableRow>
								)}
							</S.Table>
						</React.Fragment>
					);
				})}
				<S.AddButtonContainer>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						palette='gray'
						type='outlined'
						onClick={handleClickAddGuardian}>
						급여 일정 추가
					</CRButton.IconButton>
				</S.AddButtonContainer>
			</S.Form>
		</S.Container>
	);
}

export default RecipientScheduleAutomationForm;
