import React, { useEffect, useMemo, useState } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import { CheckOption } from 'components/base/Selections/type';
import EmployReportEnrollSideModal from 'components/domain/sideModal/EmployReportEnrollSideModal';
import EmploymentReportAutomationTable from 'components/domain/table/EmploymentReportAutomationTable';
import { centerListAdapter, commonCodeAdapter, corporationListAdapter } from 'lib/adapter/common';
import {
	useCommonCenters,
	useCommonCodes,
	useCommonCorporations,
	useGetEmployReportData,
} from 'lib/hook/react-query';
import useSideModal from 'lib/hook/util/useSideModal';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

function EmploymentReportTab(): React.ReactElement {
	const { showSideModal } = useSideModal();
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [currentFilter, setCurrentFilter] = useState<{
		resultFilter: { label: string; value: string }[];
		requestStatusFilter: { label: string; value: boolean }[];
		corporationFilter: { label: string; value: number }[];
		centerFilter: { label: string; value: number }[];
		requestTypeFilter: { label: string; value: string }[];
	}>({
		resultFilter: [],
		requestStatusFilter: [],
		corporationFilter: [],
		centerFilter: [],
		requestTypeFilter: [],
	});

	const { data: corporationOptions } = useCommonCorporations(corporationListAdapter);
	const { data: centerOptions } = useCommonCenters(centerListAdapter);
	const { data: commonCodes = { CMN153: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN153'] },
		commonCodeAdapter,
	);
	const {
		data: employReportData,
		isLoading: employReportLoading,
		refetch,
	} = useGetEmployReportData({
		page: pageInfo.page,
		size: pageInfo.size,
		autoSsisYn: currentFilter?.requestStatusFilter?.map((item) => item?.value),
		centerIds: currentFilter?.centerFilter?.map((item) => item?.value),
		corpId: currentFilter?.corporationFilter?.map((item) => item?.value),
		requestTypes:
			currentFilter?.requestTypeFilter?.map((item) => item?.value).length === 0
				? ['입사', '급여추가']
				: currentFilter?.requestTypeFilter?.map((item) => item?.value),
		statusCd: currentFilter?.resultFilter?.map((item) => item?.value),
		keyword,
	});

	const handleResultLabel = (value?: string) => {
		switch (value) {
			case 'CMN153.10':
				return '대기';
			case 'CMN153.20':
				return '진행중';
			case 'CMN153.30':
				return '완료';
			case 'CMN153.40':
				return '실패';
			default:
				return '대기';
		}
	};

	const filters = useMemo(
		() =>
			[
				{
					key: 'resultFilter',
					type: 'single',
					placeholder: '결과',
					options: commonCodes.CMN153.map((item) => ({
						label: handleResultLabel(item.value),
						value: item.value,
					})),
				},
				{
					key: 'requestStatusFilter',
					type: 'single',
					placeholder: '요청 상태',
					options: [
						{ label: '미요청', value: false },
						{ label: '요청', value: true },
					],
				},
				{
					key: 'corporationFilter',
					type: 'single',
					placeholder: '법인',
					options: corporationOptions,
				},
				{
					key: 'requestTypeFilter',
					type: 'multi',
					placeholder: '신고 유형',
					options: [
						{ label: '입사', value: '입사' },
						{ label: '급여추가', value: '급여추가' },
					],
				},
				{
					key: 'centerFilter',
					type: 'multi',
					placeholder: '센터',
					options: centerOptions,
				},
			] as Filter<CheckOption>[],
		[commonCodes, centerOptions, corporationOptions],
	);

	const handleClickAddAutomationData = () => {
		showSideModal(<EmployReportEnrollSideModal />);
	};

	const handleSearch = () => {
		setKeyword(searchValue);
	};

	const handleSearchValue = (value: string) => setSearchValue(value);

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	useEffect(() => {
		if (employReportData?.totalPages !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: employReportData?.totalPages || 0,
			}));
		}
	}, [employReportData?.totalPages]);

	return (
		<CRTable.BackBlind>
			<CRTableHeader
				pageInfo={pageInfo}
				onChangePageInfo={handleChangePageInfo}
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				stickyMode
				filters={filters}
				searchValue={searchValue}
				onChangeSearchValue={handleSearchValue}
				onSearch={handleSearch}
				onRefresh={refetch}
				showRefresh
				placeholder='직원명 검색'
				showViewCount
				renderRightButton={
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						size='xSmall'
						onClick={handleClickAddAutomationData}>
						요청 추가
					</CRButton.IconButton>
				}
			/>
			{employReportLoading ? (
				<CRSpinner />
			) : (
				<EmploymentReportAutomationTable employReportData={employReportData?.content ?? []} />
			)}
		</CRTable.BackBlind>
	);
}

export default EmploymentReportTab;
