import React, { useCallback, useState } from 'react';

import Colors from 'common/colors';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import EmployeeContractReviewSideModal from 'components/domain/sideModal/EmployeeContractReviewSideModal';
import useSideModal from 'lib/hook/util/useSideModal';
import { EmployeeContractManagemetTableRenderKey } from 'pages/dashboard/NewContractManagementPage/constant';
import { CRStatusConfig } from 'types/view/base';
import { EmployeeContractReviewListViewType } from 'types/view/contractReview';

import {
	COMPLETION_STATUS_CONFIG,
	COMPLIANCE_STATUS_CONFIG,
	CONTRACT_COMPLETION_STATUS_CONFIG,
	DEVICE_REGISTRATION_STATUS_CONFIG,
	EMPLOYEE_CONTRACT_REVIEW_TABLE_HEADER_CONFIG,
	REPORT_COMPLETION_STATUS_CONFIG,
	SEND_STATUS_CONFIG,
	VALIDATION_STATUS_CONFIG,
} from './constant';
import * as S from './styles';

interface Props {
	employeeContractData: Array<EmployeeContractReviewListViewType>;
	tableRenderKeys: EmployeeContractManagemetTableRenderKey[];
	sideModalDefaultActiveKey?: string;
	checkedList: EmployeeContractReviewListViewType[];
	setCheckedList: React.Dispatch<React.SetStateAction<EmployeeContractReviewListViewType[]>>;
}

function EmployeeContractManagementTable({
	employeeContractData,
	tableRenderKeys,
	sideModalDefaultActiveKey,
	checkedList,
	setCheckedList,
}: Props): React.ReactElement {
	const { showSideModal, hideSideModal } = useSideModal();
	const [currentRow, setCurrentRow] = useState<EmployeeContractReviewListViewType>();

	const renderMultipleCell = (children?: React.ReactElement) => (
		<S.MultipleCellContainer>{children}</S.MultipleCellContainer>
	);

	const renderConfigStatus = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || item.value === value);

		return (
			<CRStatus type='pill' options={config}>
				{currentValue?.label}
			</CRStatus>
		);
	}, []);

	const renderConfigText = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || value === item.value);
		const currentColor =
			currentValue?.color === 'green'
				? 'gray00'
				: currentValue?.color === 'yellow'
					? 'red'
					: (currentValue?.color as keyof typeof Colors);

		return (
			<CRText typography='label' color={currentColor}>
				{currentValue?.label ?? '-'}
			</CRText>
		);
	}, []);

	const renderCellText = useCallback(
		(value?: string) => <CRText typography='label'>{value}</CRText>,
		[],
	);

	const renderBooleanCell = useCallback((label: string, value?: boolean) => {
		const hasValue = value !== undefined && value !== null;
		const labelColor = hasValue ? (value ? 'gray00' : 'primary60') : 'gray00';
		return (
			<CRText typography='label' color={labelColor}>
				{hasValue ? label : '-'}
			</CRText>
		);
	}, []);

	const renderCheckBox = useCallback(
		(value: boolean, item?: EmployeeContractReviewListViewType) => (
			<CRCheckBox
				palette='gray'
				checked={value}
				onChange={item && handleClickCheckBox(item)}
				customStyle={{ justifyContent: 'center' }}
			/>
		),
		[checkedList],
	);

	const renderCheckBoxHeader = useCallback(() => {
		const isAllChecked = employeeContractData.every((item) =>
			checkedList.some(
				(checkedItem) =>
					checkedItem.serviceContractId === item.serviceContractId &&
					checkedItem.employeeId === item.employeeId,
			),
		);

		return (
			<CRCheckBox
				palette='gray'
				checkBoxType='toggle'
				checked={isAllChecked}
				onChange={() => {
					if (isAllChecked) {
						setCheckedList([]);
					} else {
						setCheckedList(employeeContractData);
					}
				}}
				customStyle={{ justifyContent: 'center' }}
			/>
		);
	}, [checkedList]);

	const renderStringMultiCell = (value: string[]) =>
		value.map((item) => renderMultipleCell(renderCellText(item)));
	const renderBoleanMultiCell = (value: boolean[]) =>
		value.map((item) => renderMultipleCell(renderCellText(item ? '완료' : '미완료')));

	const renderCompletionStatus = useCallback(
		(value: boolean) => renderConfigStatus(COMPLETION_STATUS_CONFIG, value ? '완료' : '미완료'),
		[],
	);

	const handleClickRow = (item: EmployeeContractReviewListViewType) => {
		if (
			item.serviceContractId === currentRow?.serviceContractId &&
			item.employeeId === currentRow?.employeeId
		) {
			setCurrentRow(undefined);
			hideSideModal();
		} else {
			setCurrentRow(item);
			showSideModal(
				<EmployeeContractReviewSideModal
					data={item}
					defaultActiveKey={sideModalDefaultActiveKey}
				/>,
			);
		}
	};

	const handleRowStyle = (item: EmployeeContractReviewListViewType) =>
		item.serviceContractId === currentRow?.serviceContractId &&
		item.employeeId === currentRow?.employeeId
			? { background: Colors.primary95 }
			: undefined;

	const handleHeaderConfig = () => {
		const headerConfig = EMPLOYEE_CONTRACT_REVIEW_TABLE_HEADER_CONFIG;
		const currentHeader = headerConfig
			.slice(1)
			.map((head) => ({
				...head,
				children:
					head.children?.filter(
						(subHead) =>
							subHead.sortKey &&
							tableRenderKeys.includes(subHead.sortKey as EmployeeContractManagemetTableRenderKey),
					) || [],
			}))
			.filter((head) => head.children.length > 0);

		return [
			{ label: renderCheckBoxHeader(), sortKey: 'checkbox', width: '5.6rem' },
			...headerConfig.slice(0, 1),
			...currentHeader,
			...headerConfig.slice(-1),
		];
	};

	const handleClickCheckBox =
		(item: EmployeeContractReviewListViewType) => (e: React.ChangeEvent<HTMLInputElement>) => {
			const index = checkedList.findIndex(
				(checkedItem) =>
					checkedItem.serviceContractId === item.serviceContractId &&
					checkedItem.employeeId === item.employeeId,
			);

			if (index === -1) {
				setCheckedList([...checkedList, item]);
			} else {
				const newCheckedList = [...checkedList];
				newCheckedList.splice(index, 1);
				setCheckedList(newCheckedList);
			}
		};

	return (
		<S.Container>
			<S.TableScrollContainer>
				<CRTable.Root style={{ tableLayout: 'auto', overflowX: 'scroll' }}>
					<CRTable.MultiHead heads={handleHeaderConfig()} offset={-47} />
					<CRTable.Body>
						{employeeContractData.map((item) => (
							<CRTable.Row
								style={handleRowStyle(item)}
								key={item.serviceContractId}
								item={{
									...item,
									checked: checkedList.some(
										(checkedItem) =>
											checkedItem.serviceContractId === item.serviceContractId &&
											checkedItem.employeeId === item.employeeId,
									),
									center: item.center || '-',
									socialWorker: item.socialWorker || '-',
									employeeName: item.employeeName || '-',
									employeeBirthDate:
										item.employeeBirthDate?.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3') || '-',
									contractType: item.contractType || '-',
									recipientContractDate:
										item?.recipientContractDate?.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3') ||
										'-',
									recipientName: item.recipientName || '-',
									longTermCareNumber: item.longTermCareNumber || '-',
									longtermCareBenefitType: item.lognTermCareBenefitInfo.map(
										(info) => info.longtermCareBenefitType || '-',
									),
									longtermCareStartDate: item.lognTermCareBenefitInfo.map(
										(info) =>
											info.longtermCareStartDate?.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3') ||
											'-',
									),
									longtermCareEndDate: item.lognTermCareBenefitInfo.map(
										(info) =>
											info.longtermCareEndDate?.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3') ||
											'-',
									),
									employeeContractDate: item.lognTermCareBenefitInfo.map(
										(info) =>
											info.employeeContractDate?.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3') ||
											'-',
									),
									employeeContractCompletionStatus: item.employeeContractCompletionStatus,
									employeeContractSendingStatus: item.lognTermCareBenefitInfo.map(
										(info) => info.employeeContractSendingStatus || '-',
									),
									isEmployeeContractValid: item.lognTermCareBenefitInfo.map(
										(info) => info.isEmployeeContractValid,
									),
									employeeContractHourlyWage: item.lognTermCareBenefitInfo.map(
										(info) => info.employeeContractHourlyWage.toLocaleString('ko-KR') || '-',
									),
									employeeContractMonthlyWorkHours: item.lognTermCareBenefitInfo.map(
										(info) => info.employeeContractMonthlyWorkHours || '-',
									),
									isContractWorkHoursAlignedWithPolicy: item.lognTermCareBenefitInfo.map(
										(info) => info.isContractWorkHoursAlignedWithPolicy,
									),

									employeeReportState: item.employeeReportState || '-',
									insuranceEnrollmentDate:
										item.insuranceEnrollmentDate?.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3') ||
										'-',
									deviceRegistrationRegistrationStatus:
										item.deviceRegistrationRegistrationStatus || '-',
								}}
								renderKeys={[
									'checked',
									'totalWorkCompletionStatus',
									'center',
									'socialWorker',
									'employeeName',
									'employeeBirthDate',
									'isNewEmployee',
									'isSameDayContract',
									'contractType',
									'recipientContractDate',
									'longtermCareBenefitType',
									'longtermCareStartDate',
									'longtermCareEndDate',
									'recipientName',
									'longTermCareNumber',
									'isFamily',
									...tableRenderKeys,
									'referenceContractNotes',
									'adminMemo',
								]}
								customRender={{
									checked: renderCheckBox,
									totalWorkCompletionStatus: renderCompletionStatus,
									isNewEmployee: (value) => renderCellText(value ? '신규' : '기존'),
									isSameDayContract: (value) => renderBooleanCell(value ? '당일계약' : '-', !value),
									isFamily: (value) => renderCellText(value ? '가족' : '가족아님'),

									longtermCareBenefitType: renderStringMultiCell,
									longtermCareStartDate: renderStringMultiCell,
									longtermCareEndDate: renderStringMultiCell,
									// 범죄
									isCrimeCheckCompleted: renderCompletionStatus,
									hasCrimeReportFile: (value) =>
										renderBooleanCell(value ? '등록 완료' : '미등록', value),
									hasElderAbuseReportFile: (value) =>
										renderBooleanCell(value ? '등록 완료' : '미등록', value),
									hasDisabilityAbuseReportFile: (value) =>
										renderBooleanCell(value ? '등록 완료' : '미등록', value),
									isManuallyRegistered: (value) => renderCellText(value ? '수기' : '시스템'),
									// 근로 계약
									employeeContractCompletionStatus: (value) =>
										renderConfigStatus(CONTRACT_COMPLETION_STATUS_CONFIG, value),
									employeeContractSendingStatus: (value) =>
										value.map((item) =>
											renderMultipleCell(renderConfigText(SEND_STATUS_CONFIG, item)),
										),
									isEmployeeContractValid: (value) =>
										value.map((item) =>
											renderMultipleCell(
												renderConfigText(VALIDATION_STATUS_CONFIG, item ? '적합' : '부적합'),
											),
										),
									employeeContractHourlyWage: (value) =>
										value.map((item) =>
											renderMultipleCell(renderCellText(item ? item.toLocaleString() : '-')),
										),
									employeeContractMonthlyWorkHours: (value) =>
										renderStringMultiCell(
											value.map((item) => `${item.toLocaleString('ko-KR')}시간`),
										),
									isContractWorkHoursAlignedWithPolicy: (value) =>
										value.map((item) =>
											renderMultipleCell(
												renderConfigText(COMPLIANCE_STATUS_CONFIG, item ? '준수' : '확인필요'),
											),
										),
									// 인력신고
									employeeReportState: (value) =>
										renderConfigStatus(REPORT_COMPLETION_STATUS_CONFIG, value),
									// 4대보험
									isInsuranceEnrollmentReportCompleted: renderCompletionStatus,
									weeklyContractedHours: (value) => renderCellText(value ? `${value}시간` : '-'),
									reportedInsuranceWage: (value) =>
										renderCellText(value ? value.toLocaleString('ko-kr') : '-'),
									requiresNationalPensionService: (value) =>
										renderBooleanCell(value ? '취득필요' : '해당없음', !value),
									requiresNationalHealthInsurance: (value) =>
										renderBooleanCell(value ? '취득필요' : '해당없음', !value),
									requiresEmploymentInsurance: (value) =>
										renderBooleanCell(value ? '취득필요' : '해당없음', !value),
									requiresEmployeeCompensationInsurance: (value) =>
										renderBooleanCell(value ? '취득필요' : '해당없음', !value),
									// 배상책임보험
									isLiabilityInsuranceRegistrationCompleted: renderCompletionStatus,
									isLiabilityInsuranceRequired: (value) =>
										renderBooleanCell(value ? '가입필요' : '해당없음', !value),
									isLiabilityInsuranceEnrolled: (value) =>
										renderBooleanCell(value ? '가입완료' : '미가입', value),
									deviceRegistrationRegistrationStatus: (value) =>
										renderConfigStatus(DEVICE_REGISTRATION_STATUS_CONFIG, value),
									referenceContractNotes: (value) => value || '-',
									adminMemo: (value) => value || '-',
								}}
								customStyle={{
									checked: { borderLeft: `1px solid ${Colors.gray90}` },
									totalWorkCompletionStatus: { textAlign: 'center' },
									isCrimeCheckCompleted: { textAlign: 'center' },
									hasCrimeReportFile: { textAlign: 'center' },
									hasElderAbuseReportFile: { textAlign: 'center' },
									hasDisabilityAbuseReportFile: { textAlign: 'center' },
									isManuallyRegistered: { textAlign: 'center' },
									employeeContractCompletionStatus: { textAlign: 'center' },
									employeeContractSendingStatus: { padding: 0, textAlign: 'center' },
									isEmployeeContractValid: { padding: 0, textAlign: 'center' },
									employeeContractHourlyWage: { padding: 0, textAlign: 'right' },
									employeeContractMonthlyWorkHours: { padding: 0, textAlign: 'right' },
									isContractWorkHoursAlignedWithPolicy: { padding: 0, textAlign: 'center' },
									employeeReportState: { padding: 0, textAlign: 'center' },
									longtermCareBenefitType: { padding: 0 },
									longtermCareStartDate: { padding: 0 },
									longtermCareEndDate: { padding: 0 },
									isInsuranceEnrollmentReportCompleted: { textAlign: 'center' },
									weeklyContractedHours: { textAlign: 'right' },
									reportedInsuranceWage: { textAlign: 'right' },
									insuranceEnrollmentDate: { textAlign: 'right' },
									requiresNationalPensionService: { textAlign: 'center' },
									requiresNationalHealthInsurance: { textAlign: 'center' },
									requiresEmploymentInsurance: { textAlign: 'center' },
									requiresEmployeeCompensationInsurance: { textAlign: 'center' },
									isLiabilityInsuranceRegistrationCompleted: { textAlign: 'center' },
									isLiabilityInsuranceRequired: { textAlign: 'center' },
									isLiabilityInsuranceEnrolled: { textAlign: 'center' },
									deviceRegistrationRegistrationStatus: { textAlign: 'center' },
									adminMemo: { borderRight: `1px solid ${Colors.gray90}` },
								}}
								onClick={handleClickRow}
								stopPropagationKeys={['checked']}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			</S.TableScrollContainer>
		</S.Container>
	);
}

export default EmployeeContractManagementTable;
