import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	min-width: 92rem;
	overflow: overlay;
	box-sizing: border-box;
`;

export const TableScrollContainer = styled.div`
	width: 100%;
	height: calc(100% - 11rem);
	overflow: auto;
	background: ${(props) => props.theme.colors.gray100};
`;

export const MultipleCellContainer = styled.div`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray00};
	height: 3.3rem;
	padding: 0.4rem 0.8rem;

	& + & {
		border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	}
`;
