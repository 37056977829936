import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRTab from 'components/base/CRTab';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import EmployeeContractDetailBasicInfo from 'components/domain/table/EmployeeContractDetailTable/EmployeeContractDetailBasicInfo';
import EmployeeContractDetailCrimeHistory from 'components/domain/table/EmployeeContractDetailTable/EmployeeContractDetailCrimeHistory';
import EmployeeContractDetailInfo from 'components/domain/table/EmployeeContractDetailTable/EmployeeContractDetailInfo';
import EmployeeInsuranceInfo from 'components/domain/table/EmployeeContractDetailTable/EmployeeInsuranceInfo';
import EmployeeLongtermInfo from 'components/domain/table/EmployeeContractDetailTable/EmployeeLongtermInfo';
import EmployeeReportInfo from 'components/domain/table/EmployeeContractDetailTable/EmployeeReportInfo';
import useCRForm from 'lib/hook/form/useCRForm';
import { useSaveContractReview } from 'lib/hook/react-query';
import useSideModal from 'lib/hook/util/useSideModal';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { SaveContractReviewDTO } from 'types/dto/contractReview';
import { EmployeeContractReviewListViewType } from 'types/view/contractReview';

import * as S from './styles';
import { EmployeeContractReviewFormFields } from './types';
import { employeeContractReviewValidator } from './validator';

interface Props {
	data: EmployeeContractReviewListViewType;
	defaultActiveKey?: string;
}

function EmployeeContractReviewSideModal({ data, defaultActiveKey }: Props): React.ReactElement {
	const { hideSideModal } = useSideModal();

	const handleDefaultValues = (
		data?: EmployeeContractReviewListViewType,
	): EmployeeContractReviewFormFields => {
		if (!data) return {} as EmployeeContractReviewFormFields;

		return {
			serviceContractId: data.serviceContractId,
			centerId: data.centerId,
			recipientId: undefined,
			employeeId: data.employeeId,
			employeeReportStatCd: data.employeeReportState,
			pcorpTagRegistrationCd: data.deviceRegistrationRegistrationStatus,
			liabilityInsuranceSubscriptionYn: data.data?.liabilityInsuranceSubscriptionYn,
			criminalRecordRegistrationYn: data.data?.criminalRecordRegistrationYn,
			employeeContractCompleteCd: data.employeeContractCompletionStatus,
			insuranceRequestYn: data.data?.insuranceRequestYn,
			remark: data.adminMemo,
			contractMngtId: data.contractMngtId,
		};
	};

	const { setValue, watch, CRHandleSubmit, CRFormProvider, reset } =
		useCRForm<EmployeeContractReviewFormFields>({
			yupValidator: employeeContractReviewValidator,
			defaultValues: handleDefaultValues(data),
		});

	const { mutate: saveEmployeeContractReview } = useSaveContractReview(
		(client, returnData, variables) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success(`계약 검수 저장에 성공하였습니다.`);
				client.invalidateQueries([endpoint.getEmployeeContractReviewList.key]);
				hideSideModal();
			} else {
				Toast.error(`계약 검수 저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`);
			}
		},
	);

	const onSubmit = async (inputData: EmployeeContractReviewFormFields) => {
		const saveEmployeeContractReviewData: SaveContractReviewDTO = {
			serviceContractId: inputData.serviceContractId,
			centerId: inputData.centerId,
			employeeId: inputData.employeeId,
			recipientId: undefined,
			contractMngtId: inputData.contractMngtId,
			employeeReportStatCd: inputData.employeeReportStatCd,
			pcorpTagRegistrationCd: inputData.pcorpTagRegistrationCd,
			liabilityInsuranceSubscriptionYn: inputData.liabilityInsuranceSubscriptionYn,
			criminalRecordRegistrationYn: inputData.criminalRecordRegistrationYn,
			employeeContractCompleteCd: inputData.employeeContractCompleteCd,
			insuranceRequestYn: inputData.insuranceRequestYn,
			remark: inputData.remark,
		};

		await saveEmployeeContractReview([saveEmployeeContractReviewData]);
	};

	useEffect(() => {
		reset(handleDefaultValues(data));
	}, [data]);

	return (
		<S.Container>
			<S.Header>
				<CRText typography='h4' text='직원 계약 정보' color='gray00' />
				<S.CloseIcon src={Assets.icon.close} alt='닫기' onClick={hideSideModal} />
			</S.Header>
			<S.Content>
				<CRFormProvider>
					{/* // TODO: INPUT 및 점검 UI + 수급자 탭과 동일한 Tab To Tab */}
					<EmployeeContractDetailBasicInfo data={data} />
					<CRTab.Sub
						menuContainerStyle={{ padding: '0 2.4rem' }}
						contentContainerStyle={{ overflow: 'hidden' }}
						containerStyle={{ overflow: 'visible' }}
						defaultActiveKey={defaultActiveKey || '범죄경력조회'}
						items={[
							{
								label: '범죄경력조회',
								key: '범죄경력조회',
								children: <EmployeeContractDetailCrimeHistory data={data} />,
							},
							{
								label: '근로계약서',
								key: '근로계약서',
								children: <EmployeeContractDetailInfo data={data} />,
							},
							{
								label: '인력신고',
								key: '인력신고',
								children: <EmployeeReportInfo data={data} />,
							},
							{
								label: '4대보험',
								key: '4대보험',
								children: <EmployeeInsuranceInfo data={data} />,
							},
							{
								label: '공단 등록',
								key: '공단 등록',
								children: <EmployeeLongtermInfo data={data} />,
							},
						]}
					/>
				</CRFormProvider>
			</S.Content>
			<S.ButtonContainer>
				<CRButton.Default type='text' palette='gray' onClick={() => hideSideModal()}>
					취소
				</CRButton.Default>
				<CRButton.Default buttonType='submit' onClick={CRHandleSubmit(onSubmit)}>
					저장
				</CRButton.Default>
			</S.ButtonContainer>
		</S.Container>
	);
}

export default EmployeeContractReviewSideModal;
