import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { CreateEmployReportForm } from 'types/view/employReport';

interface Props {
	control: Control<CreateEmployReportForm, any>;
}

function EmploymentReportJoinInfoForm({ control }: Props): React.ReactElement {
	const { data: commonCodes = { CMN006: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN006'] },
		commonCodeAdapter,
	);
	const serviceOptions = useMemo(
		() => (commonCodes.CMN006 || [])?.filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes],
	);
	const renderErrorMessage = (error: boolean, message: string) =>
		error ? (
			<CRText color='primary60' typography='label'>
				{message}
			</CRText>
		) : null;

	return (
		<CRCardFormLayout label='입사 정보'>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						label='서비스 유형'
						isRequired
						message={renderErrorMessage(!!errors.serviceType, errors.serviceType?.message ?? '')}>
						<CRInput.Selector
							items={serviceOptions}
							placeholder='서비스 유형 입력'
							currentValue={value}
							onChangeValue={onChange}
							status={errors.serviceType ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='serviceType'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='입사일'
						message={renderErrorMessage(!!errors.joinDate, errors.joinDate?.message ?? '')}>
						<CRInput.DatePicker
							value={value}
							onChangeValue={onChange}
							placeholder='입사일 선택'
							status={errors.joinDate ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='joinDate'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='근무시작일'
						message={renderErrorMessage(
							!!errors.workStartDate,
							errors.workStartDate?.message ?? '',
						)}>
						<CRInput.DatePicker
							value={value}
							onChangeValue={onChange}
							placeholder='근무시작일 선택'
							status={errors.workStartDate ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='workStartDate'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='입사서류 파일'
						message={renderErrorMessage(!!errors.joinFile, errors.joinFile?.message ?? '')}>
						<CRInput.FileUploader
							onChange={(files) => onChange({ ...value, fileDetails: files })}
							files={value?.fileDetails}
							placeholder='파일을 끌어오거나 업로드'
							status={errors.joinFile ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='joinFile'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						label='직원번호'
						isRequired
						message={renderErrorMessage(!!errors.employeeNo, errors.employeeNo?.message ?? '')}>
						<CRInput.Default
							placeholder='직원번호 입력'
							onChange={onChange}
							value={value}
							status={errors.employeeNo ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='employeeNo'
				control={control}
			/>
		</CRCardFormLayout>
	);
}

export default EmploymentReportJoinInfoForm;
