import * as yup from 'yup';

import { CheckOption } from 'components/base/Selections/type';

export const EmploymentAutomationDataValidator = yup.object().shape({
	requestType: yup.object().required('요청 유형을 선택해주세요'),
	center: yup.object().required('소속 센터를 선택해주세요'),
	corporation: yup.object().required('소속 법인을 선택해주세요'),
	employeeNm: yup.string().required('직원명을 입력해주세요'),
	employeeRsdnNo: yup.string().required('직원 주민등록번호를 입력해주세요'),
	autoSsisYn: yup.boolean(),

	employeeNo: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('직원번호를 입력해주세요'),
		}),

	// 입사정보
	serviceType: yup
		.object()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('급여유형을 선택해주세요'),
		}),
	joinDate: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('입사일을 선택해주세요'),
		}),
	workStartDate: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('근무시작일을 선택해주세요'),
		}),
	joinFile: yup.mixed().when('requestType', {
		is: (val: CheckOption) => val?.value !== '퇴사',
		then: (schema) => schema.required('입사서류 파일을 업로드해주세요'),
	}),

	// 자격정보
	licenseType: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('자격면허를 선택해주세요'),
		}),
	licenseNo: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('자격번호를 입력해주세요'),
		}),
	licenseAcqDate: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('자격취득일을 선택해주세요'),
		}),
	licenseFile: yup.mixed().when('requestType', {
		is: (val: CheckOption) => val?.value !== '퇴사',
		then: (schema) => schema.required('자격증 파일을 업로드해주세요'),
	}),

	// 퇴사정보
	resignDate: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value === '퇴사',
			then: (schema) => schema.required('퇴사일을 선택해주세요'),
		}),
	resignReason: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value === '퇴사',
			then: (schema) => schema.required('퇴사사유를 입력해주세요'),
		}),
	workEndDate: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value === '퇴사',
			then: (schema) => schema.required('근무종료일을 선택해주세요'),
		}),
	resignFile: yup.mixed().when('requestType', {
		is: (val: CheckOption) => val?.value === '퇴사',
		then: (schema) => schema.required('퇴사서류 파일을 업로드해주세요'),
	}),

	// 기타정보
	recruitmentType: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('채용구분을 선택해주세요'),
		}),
	employeeStatus: yup.string().required('직원상태를 선택해주세요'),
	regionJobCategory: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('지역직종을 선택해주세요'),
		}),
	jobCategory: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('직종을 선택해주세요'),
		}),
	salaryType: yup.string().nullable(),
	dementiaEduYn: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('치매교육여부를 선택해주세요'),
		}),
	workType: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('근무형태를 선택해주세요'),
		}),
	position: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('직급을 선택해주세요'),
		}),
	employmentType: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value !== '퇴사',
			then: (schema) => schema.required('고용형태를 선택해주세요'),
		}),
	deptNm: yup.string().required('부서명을 선택해주세요'),
	updateContent: yup.string().required('변경내용을 입력해주세요'),
	updateReason: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value === '퇴사',
			then: (schema) => schema.required('인력변경사유를 입력해주세요'),
		}),
	updateReasonDetail: yup
		.string()
		.nullable()
		.when('requestType', {
			is: (val: CheckOption) => val?.value === '퇴사',
			then: (schema) => schema.required('인력변경사유 상세를 입력해주세요'),
		}),
});
