import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import { Toast } from 'components/base/CRToast';
import { InsuranceCancelDialog } from 'components/domain/dialog/InsuranceCancelDialog';
import { InsuranceDetailAttachResponse, InsuranceDetailBase } from 'lib';
import { useEmployeePageBaseInfo, useMyAccountInfo } from 'lib/hook/react-query';
import { useUpdateInsuranceDetailInfo } from 'lib/hook/react-query/mutation/insurance';
import { useInsuranceDetailInfo } from 'lib/hook/react-query/query/insurance';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import {
	UpdateInsuranceAcquirement,
	UpdateInsuranceChangeJob,
	UpdateInsuranceLoss,
} from 'types/api/insurance';
import { InsuranceEditDetailFormType } from 'types/view/insurance';

import { InsuranceReqType } from '../InsuranceRequestPage/RequestTypeForm';
import BasicInfo from './BasicInfoForm';
import InsuranceAcqDateChangeView from './InsuranceAcqDateChangeView';
import InsuranceAcquirementCancelView from './InsuranceAcquirementCancelView';
import InsuranceAcquirementView from './InsuranceAcquirementView';
import InsuranceBaseView from './InsuranceBaseView';
import InsuranceChangeJobView from './InsuranceChangeJobView';
import InsuranceChangeSalaryView from './InsuranceChangeSalaryView';
import InsuranceLossCancelView from './InsuranceLossCancelView';
import InsuranceLossDateChangeView from './InsuranceLossDateChangeView';
import InsuranceLossView from './InsuranceLossView';
import RequestTypeView from './RequestTypeForm';
import * as S from './styles';

export enum InsuranceState {
	대기 = 'CMN161.10',
	진행중 = 'CMN161.20',
	반려됨 = 'CMN161.30',
	완료 = 'CMN161.40',
	부분완료 = 'CMN161.41',
	철회 = 'CMN161.50',
}

export default function InsuranceDetailPage(): React.ReactElement {
	const { showDialog } = useDialog();
	const param = useParams<{ id: string }>();
	const acquirementContext = useForm<InsuranceEditDetailFormType>({
		resolver: yupResolver(InsuranceDetailBase),
		defaultValues: {
			isEachSelect: false,
		},
	});

	const changeSalaryContext = useForm<InsuranceEditDetailFormType>({
		resolver: yupResolver(InsuranceDetailBase),
		defaultValues: {
			isEachSelect: false,
		},
	});

	const changeJobContext = useForm<InsuranceEditDetailFormType>({
		resolver: yupResolver(InsuranceDetailAttachResponse),
		defaultValues: {
			isEachSelect: false,
		},
	});

	const lossContext = useForm<InsuranceEditDetailFormType>({
		resolver: yupResolver(InsuranceDetailAttachResponse),
		defaultValues: {
			isEachSelect: false,
		},
	});

	const acquirementCancelContext = useForm<InsuranceEditDetailFormType>({
		resolver: yupResolver(InsuranceDetailBase),
		defaultValues: {
			isEachSelect: false,
		},
	});

	const lossCancelContext = useForm<InsuranceEditDetailFormType>({
		resolver: yupResolver(InsuranceDetailBase),
		defaultValues: {
			isEachSelect: false,
		},
	});

	const baseContext = useForm<InsuranceEditDetailFormType>({
		resolver: yupResolver(InsuranceDetailBase),
		defaultValues: {
			isEachSelect: false,
		},
	});

	const { data: myAccountInfo } = useMyAccountInfo();
	const params = useParams<{ id: string; detailId: string }>();
	const navigate = useNavigate();

	const { data: insuranceDetailInfo, refetch } = useInsuranceDetailInfo({
		employeeId: params.id,
		insuranceRequestId: params.detailId,
	});

	const { data: currentEmployeeBase } = useEmployeePageBaseInfo({
		centerId: myAccountInfo?.centerId,
		employeeId: Number(param.id),
	});

	const handleRefetch = () => {
		refetch();
	};

	const { mutate: updateInsuranceDetailInfo } = useUpdateInsuranceDetailInfo(
		(client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 저장하였습니다.');
				client.invalidateQueries([
					endpoint.getEmployeeBaseInfo.key,
					{
						centerId: Number(myAccountInfo?.centerId),
						employeeId: Number(params.id),
					},
				]);
				handleRefetch();
			} else {
				Toast.error('저장에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const onSubmitEditDetailForm = (data: InsuranceEditDetailFormType) => {
		const param: UpdateInsuranceAcquirement = {
			employeeId: params.id,
			insuranceRequestId: params.detailId,
			insuranceRequestStateCd: data.insuranceRequestStateCd?.value,
		};

		const isExistDenyInsurance = [
			data?.employInsuranceApproveYn?.[0]?.value,
			data?.accidentInsuranceApproveYn?.[0]?.value,
			data?.pensionInsuranceApproveYn?.[0]?.value,
			data?.healthInsuranceApproveYn?.[0]?.value,
		]
			.filter((item) => item !== undefined)
			.some((item) => !item);

		if (data.responseDesc) {
			param.responseDesc = data.responseDesc;
		}

		if (
			data.cancelDesc &&
			([InsuranceState.반려됨, InsuranceState.철회].includes(data.insuranceRequestStateCd?.value) ||
				(data.isEachSelect && isExistDenyInsurance))
		) {
			param.cancelDesc = data.cancelDesc;
		}

		if (data.isEachSelect) {
			const isEmployInsuranceExist =
				data?.insuranceRequestDetails?.employInsuranceRequestAmt ||
				data?.insuranceRequestDetails?.employInsuranceRequestDate;
			const isAccidentInsuranceExist =
				data?.insuranceRequestDetails?.accidentInsuranceRequestAmt ||
				data?.insuranceRequestDetails?.accidentInsuranceRequestDate;
			const isPensionInsuranceExist =
				data?.insuranceRequestDetails?.pensionInsuranceRequestAmt ||
				data?.insuranceRequestDetails?.pensionInsuranceRequestDate;
			const isHealthInsuranceExist =
				data?.insuranceRequestDetails?.healthInsuranceRequestAmt ||
				data?.insuranceRequestDetails?.healthInsuranceRequestDate;

			if (isEmployInsuranceExist) {
				param.employInsuranceApproveYn = data.employInsuranceApproveYn?.[0].value;
			}
			if (isAccidentInsuranceExist) {
				param.accidentInsuranceApproveYn = data.accidentInsuranceApproveYn?.[0].value;
			}
			if (isPensionInsuranceExist) {
				param.pensionInsuranceApproveYn = data.pensionInsuranceApproveYn?.[0].value;
			}
			if (isHealthInsuranceExist) {
				param.healthInsuranceApproveYn = data.healthInsuranceApproveYn?.[0].value;
			}
			if (isExistDenyInsurance) {
				param.insuranceRequestStateCd = InsuranceState.부분완료;
			} else {
				param.insuranceRequestStateCd = InsuranceState.완료;
			}
		}

		updateInsuranceDetailInfo(param);
	};

	const onSubmitChangeAttachResponseForm = (data: InsuranceEditDetailFormType) => {
		const param: UpdateInsuranceChangeJob = {
			employeeId: params.id,
			insuranceRequestId: params.detailId,
			insuranceRequestStateCd: data.insuranceRequestStateCd?.value,
		};

		if (data.attachFileResponseDesc) {
			param.attachFileResponseDesc = data.attachFileResponseDesc;
		}

		if (
			data.cancelDesc &&
			[InsuranceState.반려됨, InsuranceState.철회].includes(data.insuranceRequestStateCd?.value)
		) {
			param.cancelDesc = data.cancelDesc;
		}

		updateInsuranceDetailInfo(param);
	};

	const onSubmitLossForm = (data: InsuranceEditDetailFormType) => {
		const param: UpdateInsuranceLoss = {
			employeeId: params.id,
			insuranceRequestId: params.detailId,
			insuranceRequestStateCd: data.insuranceRequestStateCd?.value,
		};

		const targetInsurance = [
			data?.employInsuranceApproveYn?.[0]?.value,
			data?.accidentInsuranceApproveYn?.[0]?.value,
			data?.pensionInsuranceApproveYn?.[0]?.value,
			data?.healthInsuranceApproveYn?.[0]?.value,
		];

		const isExistDenyInsurance = targetInsurance
			.filter((item) => item !== undefined)
			.some((item) => !item);

		if (data.responseDesc) {
			param.responseDesc = data.responseDesc;
		}

		if (data.attachFileResponseDesc) {
			param.attachFileResponseDesc = data.attachFileResponseDesc;
		}

		if (
			data.cancelDesc &&
			([InsuranceState.반려됨, InsuranceState.철회].includes(data.insuranceRequestStateCd?.value) ||
				(data.isEachSelect && isExistDenyInsurance))
		) {
			param.cancelDesc = data.cancelDesc;
		}

		if (data.isEachSelect) {
			const isEmployInsuranceExist =
				data?.insuranceRequestDetails?.employInsuranceRequestAmt ||
				data?.insuranceRequestDetails?.employInsuranceRequestDate;
			const isAccidentInsuranceExist =
				data?.insuranceRequestDetails?.accidentInsuranceRequestAmt ||
				data?.insuranceRequestDetails?.accidentInsuranceRequestDate;
			const isPensionInsuranceExist =
				data?.insuranceRequestDetails?.pensionInsuranceRequestAmt ||
				data?.insuranceRequestDetails?.pensionInsuranceRequestDate;
			const isHealthInsuranceExist =
				data?.insuranceRequestDetails?.healthInsuranceRequestAmt ||
				data?.insuranceRequestDetails?.healthInsuranceRequestDate;

			if (isEmployInsuranceExist) {
				param.employInsuranceApproveYn = data.employInsuranceApproveYn?.[0].value;
			}
			if (isAccidentInsuranceExist) {
				param.accidentInsuranceApproveYn = data.accidentInsuranceApproveYn?.[0].value;
			}
			if (isPensionInsuranceExist) {
				param.pensionInsuranceApproveYn = data.pensionInsuranceApproveYn?.[0].value;
			}
			if (isHealthInsuranceExist) {
				param.healthInsuranceApproveYn = data.healthInsuranceApproveYn?.[0].value;
			}

			if (isExistDenyInsurance) {
				param.insuranceRequestStateCd = InsuranceState.부분완료;
			} else {
				param.insuranceRequestStateCd = InsuranceState.완료;
			}
		}

		updateInsuranceDetailInfo(param);
	};

	const totalContext = useMemo(
		() => [
			{
				requestType: InsuranceReqType.취득,
				formContext: acquirementContext,
				onSubmit: onSubmitEditDetailForm,
			},
			{
				requestType: InsuranceReqType.신고금액변경,
				formContext: changeSalaryContext,
				onSubmit: onSubmitEditDetailForm,
			},
			{
				requestType: InsuranceReqType.이직확인서,
				formContext: changeJobContext,
				onSubmit: onSubmitChangeAttachResponseForm,
			},
			{
				requestType: InsuranceReqType.상실,
				formContext: lossContext,
				onSubmit: onSubmitLossForm,
			},
			{
				requestType: InsuranceReqType.취득취소,
				formContext: acquirementCancelContext,
				onSubmit: onSubmitEditDetailForm,
			},
			{
				requestType: InsuranceReqType.상실취소,
				formContext: lossCancelContext,
				onSubmit: onSubmitEditDetailForm,
			},
			{
				requestType: InsuranceReqType.취득일자정정,
				formContext: baseContext,
				onSubmit: onSubmitEditDetailForm,
			},
			{
				requestType: InsuranceReqType.상실일자정정,
				formContext: baseContext,
				onSubmit: onSubmitEditDetailForm,
			},
			{
				requestType: InsuranceReqType.세무문서요청,
				formContext: baseContext,
				onSubmit: onSubmitEditDetailForm,
			},
			{
				requestType: InsuranceReqType.기타,
				formContext: baseContext,
				onSubmit: onSubmitEditDetailForm,
			},
		],

		[
			acquirementContext,
			changeSalaryContext,
			changeJobContext,
			lossContext,
			acquirementCancelContext,
			lossCancelContext,
			baseContext,
			onSubmitEditDetailForm,
			onSubmitChangeAttachResponseForm,
			onSubmitLossForm,
		],
	);

	const handleClickConfirm = () => {
		if (window.location.pathname.includes('/history/detail')) {
			window.close();
		} else {
			navigate(-1);
		}
	};

	const requestType = {
		label: insuranceDetailInfo?.insuranceRequestType.text || '',
		value: insuranceDetailInfo?.insuranceRequestType.id || '',
	};

	const hasUpdateSocialInsuranceFunc = useHasFunc(['employee:update_social_insurance']);
	const hasDeleteSocialInsuranceFunc = useHasFunc(['employee:delete_social_insurance']);

	const disabled =
		!hasUpdateSocialInsuranceFunc ||
		[
			InsuranceState.완료,
			InsuranceState.반려됨,
			InsuranceState.철회,
			InsuranceState.부분완료,
		].includes(insuranceDetailInfo?.insuranceRequestState.id as InsuranceState);

	const isShowCancelButton =
		InsuranceState.대기 === (insuranceDetailInfo?.insuranceRequestState.id as InsuranceState);
	const matchedForm: any = totalContext.find(
		(context) => context.requestType === requestType?.value,
	);

	const submitForm = () => {
		if (matchedForm) {
			matchedForm.formContext.trigger().then((isValid: boolean) => {
				if (isValid) {
					matchedForm.formContext.handleSubmit(matchedForm.onSubmit as (data: any) => void)();
				}
			});
		}
	};

	const handleCLickCancel = () => {
		if (insuranceDetailInfo && params.id && params.detailId) {
			showDialog(() => (
				<InsuranceCancelDialog
					onRefetch={handleRefetch}
					item={insuranceDetailInfo}
					employeeId={params.id as string}
					insuranceRequestId={params.detailId as string}
				/>
			));
		}
	};
	const renderSubForm = () => {
		if (!insuranceDetailInfo) return null;
		switch (requestType.value) {
			case InsuranceReqType.취득: {
				return (
					<InsuranceAcquirementView
						item={insuranceDetailInfo}
						context={acquirementContext}
						disabled={disabled}
						rsdnNumber={currentEmployeeBase?.rsdnNo}
					/>
				);
			}
			case InsuranceReqType.신고금액변경: {
				return (
					<InsuranceChangeSalaryView
						item={insuranceDetailInfo}
						context={changeSalaryContext}
						disabled={disabled}
					/>
				);
			}
			case InsuranceReqType.이직확인서: {
				return (
					<InsuranceChangeJobView
						item={insuranceDetailInfo}
						context={changeJobContext}
						disabled={disabled}
					/>
				);
			}
			case InsuranceReqType.상실: {
				return (
					<InsuranceLossView item={insuranceDetailInfo} context={lossContext} disabled={disabled} />
				);
			}
			case InsuranceReqType.취득취소: {
				return (
					<InsuranceAcquirementCancelView
						employeeData={currentEmployeeBase || undefined}
						item={insuranceDetailInfo}
						context={acquirementCancelContext}
						disabled={disabled}
					/>
				);
			}
			case InsuranceReqType.상실취소: {
				return (
					<InsuranceLossCancelView
						employeeData={currentEmployeeBase || undefined}
						item={insuranceDetailInfo}
						context={lossCancelContext}
						disabled={disabled}
					/>
				);
			}
			case InsuranceReqType.취득일자정정: {
				return (
					<InsuranceAcqDateChangeView
						employeeData={currentEmployeeBase || undefined}
						item={insuranceDetailInfo}
						context={baseContext}
						disabled={disabled}
						rsdnNumber={currentEmployeeBase?.rsdnNo}
					/>
				);
			}
			case InsuranceReqType.상실일자정정: {
				return (
					<InsuranceLossDateChangeView
						employeeData={currentEmployeeBase || undefined}
						item={insuranceDetailInfo}
						context={baseContext}
						disabled={disabled}
					/>
				);
			}
			case InsuranceReqType.세무문서요청:
			case InsuranceReqType.기타: {
				return (
					<InsuranceBaseView item={insuranceDetailInfo} context={baseContext} disabled={disabled} />
				);
			}
			default: {
				return null;
			}
		}
	};

	return (
		<S.Container>
			{[InsuranceState.반려됨, InsuranceState.철회].includes(
				insuranceDetailInfo?.insuranceRequestState.id as InsuranceState,
			) && (
				<S.BannerContainer>
					<CRBanner
						type='error'
						title={`${insuranceDetailInfo?.insuranceRequestState.text || '취소'} 사유: ${
							insuranceDetailInfo?.cancelDesc
						} (${dayjs(insuranceDetailInfo?.approvalDate).format('YYYY.MM.DD HH:mm')} ${
							insuranceDetailInfo?.insuranceRequestState.text
						})`}
					/>
				</S.BannerContainer>
			)}
			<S.Title>4대보험 상세</S.Title>
			<S.ContentsContainer>
				<Accordion.Root
					type='multiple'
					style={{
						width: '100%',
					}}
					defaultValue={[
						'기본 정보',
						'신고 유형',
						'신고 내용',
						'피부양자',
						'이직확인서 요청',
						'파일 첨부',
						'4대보험 신고 결과',
					]}>
					<BasicInfo />
					<RequestTypeView requestType={requestType} />
					{renderSubForm()}
				</Accordion.Root>
			</S.ContentsContainer>
			<S.BottomSection>
				<S.BottomMenuContainer $showCancelButton={isShowCancelButton}>
					{isShowCancelButton && (
						<S.BottomLeftMenuContainer>
							<CRButton.Default
								disabled={!hasDeleteSocialInsuranceFunc}
								onClick={handleCLickCancel}
								type='outlined'>
								신청 철회
							</CRButton.Default>
						</S.BottomLeftMenuContainer>
					)}

					<S.BottomRightMenuContainer>
						{!disabled ? (
							<>
								<CRButton.Default
									type='text'
									palette='gray'
									onClick={() => {
										if (window.history.length === 1) {
											window.close();
										} else {
											navigate(-1);
										}
									}}>
									취소
								</CRButton.Default>
								<CRButton.Default
									disabled={
										!matchedForm?.formContext?.formState?.isValid || !hasUpdateSocialInsuranceFunc
									}
									onClick={submitForm}
									type='outlined'>
									저장
								</CRButton.Default>
							</>
						) : (
							<CRButton.Default onClick={handleClickConfirm}>확인</CRButton.Default>
						)}
					</S.BottomRightMenuContainer>
				</S.BottomMenuContainer>
			</S.BottomSection>
		</S.Container>
	);
}
