import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRSelector from 'components/base/CRInput/CRSelector';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import {
	UpdateSalaryServiceScheduleValidator,
	caculateDateTime,
	displayDateTime,
	parseDateTime,
} from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { SalaryServiceSchedule, UpdateSalaryServiceScheduleForm } from 'types/api/salary';
import { ServiceType } from 'types/view/common';

import * as S from './styles';

interface Props {
	scheduleData: SalaryServiceSchedule;
	onSave: (schedule: UpdateSalaryServiceScheduleForm) => void;
}

export function UpdateSalaryScheduleDialog({ scheduleData, onSave }: Props): React.ReactElement {
	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { isValid },
	} = useForm<UpdateSalaryServiceScheduleForm>({
		defaultValues: {
			...scheduleData,
			pcorpServiceFeeCd: scheduleData.serviceFeeCd,
			original: { ...scheduleData },
		},
		resolver: yupResolver(UpdateSalaryServiceScheduleValidator),
		mode: 'onChange',
	});

	const { hideDialog } = useDialog();

	const { data: commonCodes } = useCommonCodes(
		{ comCdGroupNms: ['CMN083', 'CMN209'] },
		commonCodeAdapter,
	);

	const serviceTypeCodes = (commonCodes?.CMN083 ?? []).filter(
		(code) => code.data?.etcDesc4 === 'Y',
	);

	const familyTypeCodes = (commonCodes?.CMN209 ?? []).filter(
		(code) => code.data?.etcDesc1 === 'CMN006.20',
	);

	const localSetValue = (name: keyof UpdateSalaryServiceScheduleForm, value: any) => {
		setValue(name, value, { shouldValidate: true });
	};

	const adjustServiceEndDateTime = (date: string, startTime: string) => {
		const endDateTime = dayjs(caculateDateTime(date, startTime)).add(
			watch('serviceOfferHourCnt'),
			'minutes',
		);

		if (!endDateTime.isValid()) {
			return;
		}

		localSetValue('serviceEndDt', endDateTime.format('YYYYMMDD'));
		localSetValue('serviceEndTime', endDateTime.format('HHmm00'));
	};

	const adjustVisitBathService = () => {
		localSetValue('serviceOfferHourCnt', 60);
		adjustServiceEndDateTime(watch('serviceStartDt'), watch('serviceStartTime'));
	};

	const adjustFamilyCareService = () => {
		localSetValue('serviceOfferHourCnt', 60);
		localSetValue('familyYn', true);
		adjustServiceEndDateTime(watch('serviceStartDt'), watch('serviceStartTime'));
	};

	const adjustVisitCareService = () => {
		localSetValue('familyYn', false);
	};

	const handleChangeServiceType =
		(onChange: (value: string) => void) => (option: { value: string }) => {
			if (option.value === ServiceType.VisitBath) adjustVisitBathService();
			if (option.value === ServiceType.FamilyCare) adjustFamilyCareService();
			if (option.value === ServiceType.VisitCare) adjustVisitCareService();
			onChange(option.value);
			localSetValue('pcorpServiceFeeCd', undefined);
		};

	const handleChangeServiceDateTime = (date: string, startTime: string) => {
		const dateTime =
			parseDateTime(startTime).length === 4
				? `${parseDateTime(startTime)}00`
				: parseDateTime(startTime);

		localSetValue('serviceStartDt', date);
		localSetValue('serviceStartTime', dateTime);

		adjustServiceEndDateTime(date, startTime);
	};

	const handleChangeBathServiceTime = (option: { value: string }) => {
		localSetValue('serviceOfferHourCnt', option.value);
		adjustServiceEndDateTime(watch('serviceStartDt'), watch('serviceStartTime'));
	};

	const handleChangeFamilyCareServiceTime = (option: { value: string }) => {
		localSetValue('serviceOfferHourCnt', option.value);
		adjustServiceEndDateTime(watch('serviceStartDt'), watch('serviceStartTime'));
	};

	const handleChangeServiceTime = (onChange: (value: string) => void) => (time: string) => {
		onChange(time);
		adjustServiceEndDateTime(watch('serviceStartDt'), watch('serviceStartTime'));
	};

	const getRoundedMinutes = (minutes: number) => Math.round(minutes / 30) * 30;

	const handleBlurServiceTime = () => {
		if (
			[ServiceType.VisitCare, ServiceType.FamilyCare].includes(
				watch('serviceKindCd') as ServiceType,
			)
		) {
			const roundedMinutes = getRoundedMinutes(watch('serviceOfferHourCnt') ?? 0);
			localSetValue('serviceOfferHourCnt', roundedMinutes);
			return;
		}
		if ([ServiceType.VisitNursing].includes(watch('serviceKindCd') as ServiceType)) {
			const serviceOfferHourCnt = watch('serviceOfferHourCnt');
			if (serviceOfferHourCnt < 15) localSetValue('serviceOfferHourCnt', 15);
			if (serviceOfferHourCnt > 90) localSetValue('serviceOfferHourCnt', 90);
		}

		adjustServiceEndDateTime(watch('serviceStartDt'), watch('serviceStartTime'));
	};

	return (
		<CRDialog
			title='근로 상세 내역'
			showCloseButton={false}
			body={
				<S.Container>
					<Controller
						control={control}
						name='serviceKindCd'
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='급여 종류' isRequired>
								<CRSelector
									items={serviceTypeCodes}
									placeholder='급여 종류 선택'
									onChangeValue={handleChangeServiceType(onChange)}
									currentValue={
										value
											? {
													value,
													label: '',
												}
											: undefined
									}
								/>
							</CRInputLabel>
						)}
					/>
					<CRInputLabel label='근무 시간' isRequired>
						<CRInput.DateTimePicker
							type='column'
							disable={{ end: true }}
							hide={{ end: false, start: false }}
							date={watch('serviceStartDt')}
							startTime={displayDateTime(watch('serviceStartTime'), 4)}
							endTime={displayDateTime(watch('serviceEndTime'), 4)}
							onChange={handleChangeServiceDateTime}
							maxLength={4}
						/>
					</CRInputLabel>
					<Controller
						control={control}
						name='serviceOfferHourCnt'
						render={({ field: { value, onChange } }) => (
							<CRInputLabel
								label='총 근무 시간'
								isRequired
								message={
									<CRInputMessage>
										{[ServiceType.VisitNursing].includes(watch('serviceKindCd') as ServiceType) &&
											'총 근무 시간은 15분 이상 90분 이하로 입력해주세요.'}
										{[ServiceType.VisitCare].includes(watch('serviceKindCd') as ServiceType) &&
											'총 근무 시간을 최소 30분 부터 최대 480분까지 30분 단위로 입력해주세요.'}
									</CRInputMessage>
								}>
								{[ServiceType.VisitBath].includes(watch('serviceKindCd') as ServiceType) && (
									<CRInput.Selector
										items={[
											{ label: '40분', value: 40 },
											{ label: '60분', value: 60 },
										]}
										currentValue={value !== undefined ? { value, label: '' } : undefined}
										onChangeValue={handleChangeBathServiceTime}
									/>
								)}
								{[ServiceType.FamilyCare].includes(watch('serviceKindCd') as ServiceType) && (
									<CRInput.Selector
										items={[
											{ label: '60분', value: 60 },
											{ label: '90분', value: 90 },
										]}
										currentValue={value !== undefined ? { value, label: '' } : undefined}
										onChangeValue={handleChangeFamilyCareServiceTime}
									/>
								)}
								{![ServiceType.VisitBath, ServiceType.FamilyCare].includes(
									watch('serviceKindCd') as ServiceType,
								) && (
									<CRInput.Default
										type='number'
										value={value}
										onBlur={handleBlurServiceTime}
										onChange={handleChangeServiceTime(onChange)}
										placeholder='총 근무 시간 입력'
									/>
								)}
							</CRInputLabel>
						)}
					/>
					<Controller
						control={control}
						name='familyYn'
						render={({ field: { onChange, value } }) => (
							<CRInputLabel label='가족 여부' isRequired>
								<CRCheckBoxGroup
									type='radio'
									checkType='single'
									disabled={[ServiceType.FamilyCare, ServiceType.VisitCare].includes(
										watch('serviceKindCd') as ServiceType,
									)}
									options={[
										{ label: '가족', value: true },
										{ label: '가족아님', value: false },
									]}
									appearanceType='button'
									gap={0.8}
									onChange={(option) => onChange(option[0].value)}
									value={
										value !== undefined
											? [{ value, label: value ? '가족' : '가족아님' }]
											: undefined
									}
								/>
							</CRInputLabel>
						)}
					/>
					{[ServiceType.VisitBath].includes(watch('serviceKindCd') as ServiceType) && (
						<Controller
							control={control}
							name='pcorpServiceFeeCd'
							render={({ field: { value, onChange } }) => (
								<CRInputLabel label='수가명' isRequired>
									<CRSelector
										items={familyTypeCodes}
										placeholder='수가명을 선택해주세요.'
										onChangeValue={(option) => onChange(option.value)}
										currentValue={
											watch('pcorpServiceFeeCd')
												? { value: watch('pcorpServiceFeeCd'), label: '' }
												: undefined
										}
									/>
								</CRInputLabel>
							)}
						/>
					)}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						palette='primary'
						type='filled'
						size='default'
						disabled={!isValid}
						onClick={handleSubmit(onSave)}>
						<S.LoadingContainer>저장</S.LoadingContainer>
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
