import { CRTableHeadConfig } from 'types/view/base';

export const OWN_EXPENSE_CORRECTION_TABLE_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '12rem',
		label: '연월',
		sortKey: '연월',
		textAlign: 'left',
	},
	{
		width: '12rem',
		label: '급여 유형',
		sortKey: '급여 유형',
	},
	{
		width: '12rem',
		label: '유형',
		sortKey: '유형',
	},
	{
		width: '12rem',
		label: '수가 총액',
		sortKey: '수가 총액',
	},
	{
		width: '12rem',
		label: '본인부담금',
		sortKey: '본인부담금',
	},
	{
		width: '12rem',
		label: '공단청구금',
		sortKey: '공단청구금',
	},
	{
		width: '12rem',
		label: '가산금',
		sortKey: '가산금',
	},
	{
		width: '100%',
		label: '메모',
		sortKey: '메모',
	},
	{
		width: '24rem',
		label: '',
		sortKey: '',
	},
];
