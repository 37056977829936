import styled, { RuleSet, css } from 'styled-components';

import { InputStatus } from 'components/base/CRInput/type';

export const Container = styled.div``;

export const Label = styled.label`
	${(props) => props.theme.typography.label};
`;

type InputAttrObj = {
	[key in InputStatus]: RuleSet<object>;
};

const inputAttr: InputAttrObj = {
	default: css`
		box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
		background: ${(props) => props.theme.colors.gray100};
	`,
	focus: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.gray10} inset;
		background: ${(props) => props.theme.colors.gray99};
	`,
	error: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.primary60} inset;
		background: ${(props) => props.theme.colors.primary99};
	`,
	disabled: css`
		background: ${(props) => props.theme.colors.gray95};
	`,
};

export const InputContainer = styled.div<{
	$isSingleUploaded: boolean;
	$isDragOver: boolean;
	$error: boolean;
	$disabled: boolean;
}>`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	margin-top: 0.4rem;
	padding: 0 1.6rem;
	height: 5.6rem;
	background: ${(props) => props.theme.colors.gray100};
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.8rem;
	overflow: hidden;
	box-sizing: border-box;

	${(props) =>
		(props.$isSingleUploaded || props.$isDragOver) &&
		css`
			background: ${(props) => props.theme.colors.gray99};
		`};

	${(props) =>
		props.$disabled &&
		css`
			background: ${(props) => props.theme.colors.gray95};
			cursor: no-drop;
		`};

	${(props) => props.$error && inputAttr.error};
`;

export const FileInput = styled.input`
	position: absolute;
	width: 0;
	height: 0;
	padding: 0;
	overflow: hidden;
	border: 0;
`;

export const CustomInputArea = styled.div<{ $isSingleUploaded: boolean; $disabled: boolean }>`
	${(props) => props.theme.typography.body};
	flex: 1;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	color: ${(props) => props.theme.colors.gray60};

	${(props) =>
		props.$isSingleUploaded &&
		css`
			${props.theme.typography.body};
			color: ${props.theme.colors.gray10};
		`};
	${(props) =>
		props.$disabled &&
		css`
			color: ${props.theme.colors.gray60};
		`};
`;

export const Icon = styled.img<{ $isSingleUploaded?: boolean }>`
	width: 2.4rem;
	height: 2.4rem;

	${(props) =>
		props.$isSingleUploaded &&
		css`
			filter: brightness(0) saturate(100%) invert(64%) sepia(3%) saturate(241%) hue-rotate(149deg)
				brightness(89%) contrast(86%);
		`}
`;

export const FileList = styled.ul`
	margin-top: 1.6rem;
	padding: 1.6rem;
	border-radius: 0.8rem;
	background: ${(props) => props.theme.colors.gray99};
`;

export const FileListItem = styled.li`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0.8rem;
	& + & {
		margin-top: 0.8rem;
	}
`;

export const FileInfo = styled.div`
	display: flex;
	gap: 0.4rem;
	white-space: nowrap;
	justify-content: space-between;
	align-items: center;
	flex: 1;
`;

export const FileName = styled.span<{ $disabled: boolean }>`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray10};
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	text-decoration: underline;
	cursor: pointer;
	max-width: 30rem;

	${(props) =>
		props.$disabled &&
		css`
			max-width: 40rem;
		`};
`;

export const FileSize = styled.span`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
`;

export const DeleteIcon = styled(Icon)`
	cursor: pointer;
`;
