import dayjs from 'dayjs';
import { v4 } from 'uuid';

import { GetRecipientHoldingHistoriesData, MyAccountInfoDTO } from 'types/api';
import {
	GetRecipientBaseData,
	GetRecipientHistoryOfLongTermGradeData,
	GetRecipientHistoryOfManagerData,
	GetRecipientHistoryOfOwnExpenseData,
} from 'types/api/common';
import { Schedule, ScheduleDTO } from 'types/api/schedule';
import { EmployeeService } from 'types/view/employee';
import {
	RecipientHoldingHistoryType,
	RecipientLongTermGradeHistoryType,
	RecipientManagerHistoryType,
	RecipientOwnExpenseHistoryType,
	RecipientScheduleAutomationFormViewType,
	RecipientService,
} from 'types/view/recipient';
import {
	ProcessedSchedule,
	ScheduleCategory,
	ScheduleItemType,
	ScheduleType,
} from 'types/view/schedule';

import { GetEmployeeContractData, GetRecipientContractData } from '../../types/api/contract';

export const recipientHistoryOfManagerAdapter = (items: GetRecipientHistoryOfManagerData | null) =>
	(items ?? []).map(
		(item) =>
			({
				id: (item.recipientManagerHistId ?? 0).toString(),
				status: item.currentYn ? '현재' : '이전',
				date: item.managerChangeDate,
				center: {
					id: item.centerId.toString(),
					name: item.centerNm,
				},
				manager: {
					id: item.managerId,
					name: item.managerNm,
				},
				note: item.managerChangeDesc,
				consultantOpinion: item.consultOpinion,
				changeReason: item.managerChangeReason,
			}) as RecipientManagerHistoryType,
	);

export const recipientScheduleAdapter =
	(myAccountInfo?: MyAccountInfoDTO | null) => (data: ScheduleDTO | null) => {
		if (!data) return null;
		const processedData: ScheduleType[] = [];

		const formatTime = (time: string) => {
			if (time.length === 4) {
				return `${time.slice(0, 2)}:${time.slice(2)}`;
			}
			if (time.length === 6) {
				return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
			}
			return time;
		};

		const uniqueDates =
			Array.from(
				new Set([
					...data.holidays.map((item) => item.holidayDt),
					...data.otherServiceUses.map((item) => item.otherServiceUseDt),
					...data.schedules.map((item) => item.serviceStartDt),
					...data.errorSchedules.map((item) => item.errorDt),
					...data.recipientPendings
						.map((item) => {
							const diff =
								dayjs(item.recipientPendingEndDate).diff(
									dayjs(item.recipientPendingStartDate),
									'day',
								) + 1;
							return new Array(diff)
								.fill(0)
								.map((_, index) =>
									dayjs(item.recipientPendingStartDate).add(index, 'day').format('YYYYMMDD'),
								);
						})
						.flat(),
				]),
			).sort() ?? [];

		uniqueDates?.forEach((date) => {
			const items: ScheduleItemType[] = [];

			data.holidays.forEach((holiday) => {
				if (holiday.holidayDt === date) {
					items.push({
						kind: ScheduleCategory.HOLIDAYS,
						title: holiday.holidayNm,
					});
				}
			});

			data?.otherServiceUses?.forEach((otherService) => {
				if (otherService.otherServiceUseDt === date) {
					items.push({
						kind: ScheduleCategory.OTHER,
						title: '타급여',
						startTime: formatTime(otherService.otherServiceUseStartTime),
						endTime: formatTime(otherService.otherServiceUseEndTime),
					});
				}
			});

			data?.recipientPendings?.forEach((recipientPending) => {
				const isSameOrAfter =
					dayjs(date).isAfter(recipientPending.recipientPendingStartDate, 'day') ||
					dayjs(date).isSame(recipientPending.recipientPendingStartDate, 'day');
				const isSameOrBefore =
					dayjs(date).isBefore(recipientPending.recipientPendingEndDate, 'day') ||
					dayjs(date).isSame(recipientPending.recipientPendingEndDate, 'day');

				if (isSameOrAfter && isSameOrBefore) {
					items.push({
						kind: ScheduleCategory.HOLDING,
						title: '입원',
						startTime: formatTime(recipientPending.recipientPendingStartDate),
						endTime: formatTime(recipientPending.recipientPendingEndDate),
						data: recipientPending,
					});
				}
			});

			const otherSchedules = data.schedules.filter((item) => item.serviceKindNm !== '방문목욕');

			const bathSchedules = data.schedules.filter((item) => item.serviceKindNm === '방문목욕');

			const uniqueBathSchedules = bathSchedules
				?.filter((service, index, self) => {
					// Remove duplicate services
					const isUnique =
						index ===
						self.findIndex(
							(s) =>
								s.serviceStartTime === service.serviceStartTime &&
								s.serviceEndTime === service.serviceEndTime &&
								s.serviceStartDt === service.serviceStartDt,
						);
					return isUnique;
				})
				?.map((currentService: Schedule) => {
					const matchingServices = data?.schedules?.filter(
						(otherService) =>
							currentService.serviceStartTime === otherService.serviceStartTime &&
							currentService.serviceEndTime === otherService.serviceEndTime &&
							currentService.serviceStartDt === otherService.serviceStartDt,
					);

					return matchingServices && matchingServices.length > 0
						? {
								...currentService,
								employeeNm: matchingServices.map((item) => item.employeeNm).join(', '),
							}
						: currentService;
				});

			otherSchedules.concat(uniqueBathSchedules)?.forEach((schedule) => {
				if (schedule.serviceStartDt === date) {
					items.push({
						kind:
							schedule.centerId === myAccountInfo?.centerId
								? ScheduleCategory.MY_CENTER
								: ScheduleCategory.CARING_CENTER,
						title: schedule.serviceKindNm,
						startTime: formatTime(schedule.serviceStartTime),
						endTime: formatTime(schedule.serviceEndTime),
						name: schedule.employeeNm,
						centerId: schedule.centerId,
					});
				}
			});

			const checkOverlapTime = () => {
				const caringCenterItems =
					items
						?.filter((item) =>
							[ScheduleCategory.CARING_CENTER, ScheduleCategory.MY_CENTER].includes(item.kind),
						)
						?.map((item) => ({
							scheduleId: v4(),
							time: {
								startTime: `${item?.startTime?.substring(0, 2)}:${item?.startTime?.substring(
									2,
									4,
								)}`,
								endTime: `${item?.endTime?.substring(0, 2)}:${item?.endTime?.substring(2, 4)}`,
							},
						})) ?? [];

				const overlapIds: (string | number)[] = [];

				for (let i = 0; i < items.length; i += 1) {
					for (let j = i + 1; j < caringCenterItems.length; j += 1) {
						const schedule1 = caringCenterItems[i].time;
						const schedule2 = caringCenterItems[j].time;

						if (
							schedule1.startTime < schedule2.endTime &&
							schedule1.endTime > schedule2.startTime
						) {
							// 시간이 겹치는 경우
							overlapIds.push(caringCenterItems[i].scheduleId);
							overlapIds.push(caringCenterItems[j].scheduleId);
						}
					}
				}
				return overlapIds;
			};

			const hasDuplicatedSchedule = checkOverlapTime();

			const hasError =
				!!data.errorSchedules.find((errorSchedule) => errorSchedule.errorDt === date) ||
				hasDuplicatedSchedule?.length > 0;

			const formattedDate = `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6)}`;
			const processedDate = {
				date: formattedDate,
				hasError,
				items,
			};

			processedData.push(processedDate);
		});

		return {
			schedules: processedData,
			lastSyncUpdate: data?.syncUpdateAt,
		} as ProcessedSchedule;
	};

export const recipientHistoryOfOwnExpenseAdapter = (
	items: GetRecipientHistoryOfOwnExpenseData | null,
) =>
	(items ?? []).map(
		(item) =>
			({
				id: item.burdenRateHistId.toString(),
				status: item.currentYn ? '현재' : '이전',
				date: item.burdenRateStartDate,
				ownExpenseRate: item.burdenRate,
				ownExpenseRateCd: item.burdenRateCd,
				note: item.burdenRateChangeDesc,
				center: {
					name: item.centerNm,
				},
				manager: {
					name: item.managerNm,
				},
			}) as RecipientOwnExpenseHistoryType,
	);

export const recipientHistoryOfLongTermGradeAdapter = (
	items: GetRecipientHistoryOfLongTermGradeData | null,
) =>
	(items ?? []).map(
		(item) =>
			({
				id: item.longTermGradeHistId.toString(),
				status: item.currentYn ? '현재' : '이전 이력',
				date: item.longTermChangeDate,
				grade: item.longTermGradeNm,
				acquisitionDate: item.longTermStartDate,
				expiredDate: item.longTermEndDate,
				number: `${item.longTermNo}${
					item.longTermMajorChangeNo ? `-${item.longTermMajorChangeNo}` : ''
				}${item.longTermMinorChangeNo ? `-${item.longTermMinorChangeNo}` : ''}`,
				note: item.longTermChangeDesc,
				center: item.centerNm,
				manager: item.managerNm,
			}) as RecipientLongTermGradeHistoryType,
	);

export const recipientServicesAdapter = (items: GetRecipientContractData | null) =>
	(items ?? []).map(
		(item) =>
			({
				id: item.serviceContractDetailId,
				status: item.serviceStateCd,
				serviceType: item.contractTypeCd,
				service: item.serviceTypeNm,
				contractedDate: item.serviceStartDate,
				periodOfUse: item.servicePeriod,
				employee: item.employeesNm,
				center: item.affiliationCenterAliasNm,
				manager: item.contractManagerNm,
			}) as RecipientService,
	);

export const employeeServicesAdapter = (items: GetEmployeeContractData | null) =>
	(items ?? []).map(
		(item) =>
			({
				id: item.contractId,
				contractServiceId: item.contractServiceId,
				status: item.serviceStateCd,
				serviceType: item.serviceTypeCd,
				service: item.serviceTypeCdNm,
				contractedDate: item.contractStartDate,
				contractTypeCdNm: item.contractTypeCdNm,
				periodOfUse: item.contractPeriod,
				employee: item.employeeNm,
				center: item.centerAliasNm,
				manager: item.contractManagerNm,
				recipient: item.recipientNm,
			}) as EmployeeService,
	);

export const recipientHistoryOfHoldingAdapter = (items: GetRecipientHoldingHistoriesData | null) =>
	(items ?? [])
		.sort((a, b) => (b.recipientPendingStartDate > a.recipientPendingStartDate ? 1 : -1))
		.map(
			(item) =>
				({
					id: item.recipientPendingHistId,
					period: `${dayjs(item.recipientPendingStartDate).format('YYYY.MM.DD')}~${dayjs(
						item.recipientPendingEndDate,
					).format('YYYY.MM.DD')}`,
					description: item.recipientPendingDesc,
				}) as RecipientHoldingHistoryType,
		);

export const recipientScheduleAutomationInfoAdapter = (
	data: GetRecipientBaseData | null,
): RecipientScheduleAutomationFormViewType[] | null =>
	data?.scheduleAutomations.map((automation) => ({
		serviceKindCd: automation.serviceKindCd,
		employeeId1: automation.employeeId1
			? {
					label: automation.employeeId1.employeeNm,
					value: {
						id: automation.employeeId1.employeeId,
						name: automation.employeeId1.employeeNm,
						birth: automation.employeeId1.birthDt,
					},
				}
			: undefined,
		employeeId2: automation.employeeId2
			? {
					label: automation.employeeId2.employeeNm,
					value: {
						id: automation.employeeId2.employeeId,
						name: automation.employeeId2.employeeNm,
						birth: automation.employeeId2.birthDt,
					},
				}
			: undefined,
		days: automation.scheduleAutomationDays.map((item) => item.scheduleAutomationDayCd),
		monthMethod: automation.monthUseCnt ? 'count' : automation.monthAmt ? 'amount' : '',
		monthAmt: automation.monthAmt,
		monthUseCnt: automation.monthUseCnt,
		time: {
			serviceStartTime: automation.serviceStartTime,
			serviceEndTime: automation.serviceEndTime,
		},
		holidayExceptYn: automation.holidayExceptYn,
		monthAmtOverYn: automation.monthAmtOverYn,
		automationFeeCd: automation.automationFeeCd,
		managerId: automation.managerId,
		totalDayYn: automation.totalDayYn
			? [
					{
						label: '전체 선택',
						value: true,
					},
				]
			: [],
	})) as RecipientScheduleAutomationFormViewType[];
