import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
	position: relative;
`;

export const CardLabelContainer = styled.div`
	display: flex;
	${(props) => props.theme.typography.bodyB};
	gap: 0.8rem;
	align-items: center;
`;

export const EmptyContainer = styled.div<{ $height?: string }>`
	width: 100%;
	height: ${(props) => props.$height ?? '9.6rem'};
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(p) => p.theme.colors.gray95};
`;

export const TableCellContianer = styled.div`
	display: flex;
	align-items: center;
	overflow: hidden;
	white-space: nowrap;
	gap: 0.8rem;
`;

export const TableColumnCellContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const TableContentContainer = styled.div`
	padding: 1.6rem;
	width: 100%;
	background-color: ${(p) => p.theme.colors.gray99};
	border-radius: 0.8rem;
`;

export const LabelValueContainer = styled.div`
	display: flex;
	align-items: center;
	height: 5.6rem;
`;

export const LabelContainer = styled.div`
	display: flex;
	align-items: center;
	width: 13.6rem;
`;

export const TableLabelContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;
