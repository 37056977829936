import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from '../base/useCRMutation';

export const useSalarysAutoBilling = useCRMutation(
	endpoint.requestSalarysAutoBilling.key,
	'requestSalarysAutoBilling',
);

export const useSendCashReceive = useCRMutation(endpoint.sendCashReceive.key, 'sendCashReceive');

export const useSendEmployeeSalary = useCRMutation(
	endpoint.sendEmployeeSalary.key,
	'sendEmployeeSalary',
);

export const useCalculateSalary = useCRMutation(
	endpoint.updateSalarysCalculate.key,
	'updateSalarysCalculate',
);

export const useUpdateSalarysDetail = useCRMutation(
	endpoint.updateSalarysDetail.key,
	'updateSalarysDetail',
);

export const useDownloadEmployeeSalarysPayroll = useCRMutation(
	endpoint.getEmployeeSalarysPayroll.key,
	'getEmployeeSalarysPayroll',
);

export const useDownloadEmployeeSalarysTransfer = useCRMutation(
	endpoint.getEmployeeSalarysTransfer.key,
	'getEmployeeSalarysTransfer',
);

export const useCompleteEmployeeSalarys = useCRMutation(
	endpoint.completeEmployeeSalary.key,
	'completeEmployeeSalary',
);

export const useDeleteEmployeeSalary = useCRMutation(
	endpoint.deleteEmployeeSalary.key,
	'deleteEmployeeSalary',
);

export const useDeleteEmployeeSalarySchedule = useCRMutation(
	endpoint.deleteEmployeeSalarySchedule.key,
	'deleteEmployeeSalarySchedule',
);

export const useUpdateSalaryWorkSchedules = useCRMutation(
	endpoint.updateSalaryWorkSchedules.key,
	'updateSalaryWorkSchedules',
);

export const useUpdateEmployeeSalaryAfterSync = useCRMutation(
	endpoint.updateEmployeeSalaryAfterSync.key,
	'updateEmployeeSalaryAfterSync',
);

export const useConfirmAllEmployeeSalary = useCRMutation(
	endpoint.confirmAllEmployeeSalary.key,
	'confirmAllEmployeeSalary',
);

export const useRecalculateAllEmployeeSalary = useCRMutation(
	endpoint.recalculateAllEmployeeSalary.key,
	'recalculateAllEmployeeSalary',
);
