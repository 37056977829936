import React from 'react';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { Toast } from 'components/base/CRToast';
import CRToolTip from 'components/base/CRToolTip';
import OwnExpenseCorrectionDialog from 'components/domain/dialog/OwnExpenseCorrectionDialog';
import InformationTable from 'components/ui/InformationTable';
import { useMonthlyBurdenChargeDetail, useSendBurdenCharge } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { ResponseCode } from 'types/api';
import { BurdenChargeDTO } from 'types/dto';
import { OwnExpenseMonthlyPay } from 'types/view/ownExpense';

import { displayBirthDay } from '../../../../lib';
import { endpoint } from '../../../../lib/service/Api/endpoint';
import CRStatus from '../../../base/CRStatus';
import { InformationTableItemType } from '../../../ui/InformationTable/type';
import DefaultDialog from '../DefaultDialog';
import EDocNoDialog from '../EdocNoDialog';
import * as S from './styles';

interface Props {
	item: OwnExpenseMonthlyPay;
	onClickClose?: () => void;
	refetch?: () => Promise<any>;
}

function OwnExpenseMonthlyDetailDialog({ item, onClickClose, refetch }: Props): React.ReactElement {
	const hasCorrectionOwnExpenseFunc = useHasFunc(['own_expense:correct_own_expense']);
	const {
		data,
		isLoading,
		refetch: refetchMonthlyBurdenChargeDetail,
	} = useMonthlyBurdenChargeDetail({
		burdenAmtChargeId: item.id,
	});

	const refetchData = async () => {
		await refetchMonthlyBurdenChargeDetail?.();
		await refetch?.();
	};

	const { mutateAsync: sendBurdenCharge } = useSendBurdenCharge((client) => {
		client.invalidateQueries([
			endpoint.getMonthlyBurdenChargeDetail.key,
			{ burdenAmtChargeId: item.id },
		]);
	});
	const { showDialog } = useDialog();

	const handleClickSendBurdenCharge = (data: BurdenChargeDTO) => {
		const successCallback = async (hideDialog: () => void) => {
			const res = await sendBurdenCharge({
				burdenAmtChargeId: data.burdenAmtChargeId,
			});
			if (res.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 발송 요청을 하였습니다.');
			} else {
				Toast.error('발송 요청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
			hideDialog();
		};

		const date = dayjs(data.burdenAmtChargeYm).format('YYYY년 M월');
		const content = `${data.recipientNm}(${data.mobilePhoneNo})님에게 ${date} 본인부담금 납부 고지서를 발송할 수 있도록 요청합니다.`;

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='납부 고지서를 발송 요청 하시겠습니까?'
				content={content}
				hideDialog={hideDialog}
				successOption={{
					text: '발송 요청',
					successCallback: () => successCallback(hideDialog),
				}}
				cancelOption={{
					text: '취소',
				}}
			/>
		));
	};

	const handleClickPreviewBurdenCharge = (data: BurdenChargeDTO) => {
		showDialog(() => <EDocNoDialog dialogType='M' edocNo={data.edocNo} />);
	};

	const handleClickCorrection = () => {
		if (!data || !data.recipientId || !data.centerId) return;

		showDialog(({ hideDialog }) => (
			<OwnExpenseCorrectionDialog
				refetch={refetchData}
				recipientId={data.recipientId}
				centerId={data.centerId}
				onClickClose={hideDialog}
			/>
		));
	};

	if (isLoading) {
		return <div />;
	}

	if (!data) {
		onClickClose?.();
		return <div />;
	}

	return (
		<CRDialog
			type='M'
			title='월별 본인부담금 상세'
			body={
				<S.Container>
					<S.SectionContainer>
						<S.SectionTitle>수급자 기본 정보</S.SectionTitle>
						<InformationTable
							items={[
								[
									{
										label: '이름',
										value: data.recipientNm,
									},
									{
										label: '생년월일',
										value: displayBirthDay(data.birthDt),
									},
									{
										label: '현금영수증',
										value: data.cashReceiptIssueYn ? '대상자' : '비대상자',
									},
								],
								...(data.sendTitle
									? [
											[
												{
													label: '납부 고지서',
													value: (
														<S.PaymentBillContainer>
															<S.PaymentBillText>{`${data.sendTitle} (${dayjs(data.sendDate).format(
																'YY.MM.DD HH:mm',
															)} ${data.sendStateNm})`}</S.PaymentBillText>
															<CRButton.Default
																palette='gray'
																size='xSmall'
																type='outlined'
																onClick={() => handleClickPreviewBurdenCharge(data)}>
																미리보기
															</CRButton.Default>
															<CRButton.Default
																palette='gray'
																size='xSmall'
																type='outlined'
																onClick={() => handleClickSendBurdenCharge(data)}>
																발송
															</CRButton.Default>
														</S.PaymentBillContainer>
													),
													valueColSpan: 5,
												},
											] as InformationTableItemType[],
										]
									: ([] as InformationTableItemType[][])),
								[
									{
										label: '본인부담금 총액',
										value: data.burdenAmt.toLocaleString(),
									},
									{
										label: '납부 총액',
										value: data.burdenAmtPaySumAmt.toLocaleString(),
									},
									{
										label: '납부 상태',
										value: (
											<CRStatus
												options={[
													{
														key: 'CMN135.30',
														label: '완납',
														color: 'green',
													},
													{
														key: 'CMN135.40',
														label: '과납',
														color: 'blue',
													},
													{
														key: 'CMN135.10',
														label: '미납',
														color: 'red',
													},
												]}>
												{data.burdenAmtPayStateCd}
											</CRStatus>
										),
									},
								],
								[
									{
										label: '납부 방식',
										value: data.burdenAmtPayMethodNm,
									},
									{
										label: '사회복지사',
										value: data.managerNm,
									},
									{
										type: 'labelValueNull',
									},
								],
							]}
						/>
					</S.SectionContainer>
					<S.SectionContainer>
						<S.SectionTitleContainer>
							<S.SectionTitle>본인부담금 정보</S.SectionTitle>
							{hasCorrectionOwnExpenseFunc && (
								<CRToolTip title='본인부담금 보정을 통해 보완청구/추가청구 및 기타 보정 사항을 반영해보세요'>
									<CRButton.Default
										disabled={!hasCorrectionOwnExpenseFunc}
										palette='gray'
										type='outlined'
										size='xSmall'
										onClick={handleClickCorrection}>
										보정
									</CRButton.Default>
								</CRToolTip>
							)}
						</S.SectionTitleContainer>
						<InformationTable
							items={[
								[
									{
										type: 'label',
										label: '',
									},
									{
										type: 'label',
										label: '수가 총액',
										labelStyle: {
											textAlign: 'right',
										},
									},
									{
										type: 'label',
										label: '본인부담금',
										labelStyle: {
											textAlign: 'right',
										},
									},
									{
										type: 'label',
										label: '공단청구금',
										labelStyle: {
											textAlign: 'right',
										},
									},
									{
										type: 'label',
										label: '가산금',
										labelStyle: {
											textAlign: 'right',
										},
									},
									{
										type: 'label',
										label: '가산후 총액',
										labelStyle: {
											textAlign: 'right',
										},
									},
								],
								...data.burdenAmtChargeDetails.map(
									(item) =>
										[
											{
												type: 'label',
												label: item.serviceTypeNm,
											},
											{
												type: 'value',
												value: item.feeSumAmt.toLocaleString(),
												valueStyle: {
													textAlign: 'right',
												},
											},
											{
												type: 'value',
												value: item.burdenAmt.toLocaleString(),
												valueStyle: {
													textAlign: 'right',
												},
											},
											{
												type: 'value',
												value: item.pcorpChargeAmt.toLocaleString(),
												valueStyle: {
													textAlign: 'right',
												},
											},
											{
												type: 'value',
												value: item.additionAmt.toLocaleString(),
												valueStyle: {
													textAlign: 'right',
												},
											},
											{
												type: 'value',
												value: item.additionAfterSumAmt.toLocaleString(),
												valueStyle: {
													textAlign: 'right',
												},
											},
										] as InformationTableItemType[],
								),
								[
									{
										type: 'label',
										label: '총합',
									},
									{
										type: 'value',
										value: data.burdenAmtChargeDetails
											.reduce((sum, item) => sum + item.feeSumAmt, 0)
											.toLocaleString(),
										valueStyle: {
											fontWeight: '700',
											textAlign: 'right',
										},
									},
									{
										type: 'value',
										value: data.burdenAmtChargeDetails
											.reduce((sum, item) => sum + item.burdenAmt, 0)
											.toLocaleString(),
										valueStyle: {
											fontWeight: '700',
											textAlign: 'right',
										},
									},
									{
										type: 'value',
										value: data.burdenAmtChargeDetails
											.reduce((sum, item) => sum + item.pcorpChargeAmt, 0)
											.toLocaleString(),
										valueStyle: {
											fontWeight: '700',
											textAlign: 'right',
										},
									},
									{
										type: 'value',
										value: data.burdenAmtChargeDetails
											.reduce((sum, item) => sum + item.additionAmt, 0)
											.toLocaleString(),
										valueStyle: {
											fontWeight: '700',
											textAlign: 'right',
										},
									},
									{
										type: 'value',
										value: data.burdenAmtChargeDetails
											.reduce((sum, item) => sum + item.additionAfterSumAmt, 0)
											.toLocaleString(),
										valueStyle: {
											fontWeight: '700',
											textAlign: 'right',
										},
									},
								],
							]}
						/>
					</S.SectionContainer>
				</S.Container>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default OwnExpenseMonthlyDetailDialog;
