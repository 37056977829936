import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	max-height: calc(100vh - 4.8rem);
	overflow: hidden;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
`;

export const Table = styled.table`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableRow = styled.tr<{ $type?: string }>`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}

	${(props) =>
		props.$type === 'DELETE' &&
		css`
			background: ${props.theme.colors.redLighten};
		`}

	${(props) =>
		props.$type === 'UPDATE' &&
		css`
			background: ${props.theme.colors.yellowLighten};
		`}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	height: 3.4rem;
	padding: 0.4rem 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
	vertical-align: middle;
	word-break: keep-all;
`;

export const SectionContainer = styled.div`
	gap: 0.8rem;
`;

export const TableValueColumnBase = styled.td<{ $textAlign?: 'left' | 'right' }>`
	${(props) => props.theme.typography.label};
	text-align: ${(p) => p.$textAlign || 'right'};
	position: relative;
	box-sizing: border-box;
	height: 3.4rem;
	padding: 0.4rem 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
`;

export const TableValueColumn = styled(TableValueColumnBase)<{ $textAlign?: 'left' | 'right' }>`
	* {
		${(props) => props.theme.typography.label};
	}
`;
