import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRFormInput from 'components/base/CRFormInput';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import CRToolTip from 'components/base/CRToolTip';
import SalaryCalculateConfirmDialog from 'components/domain/dialog/SalaryCalculateConfirmDialog';
import { SalaryDetailDialogFormFields } from 'components/domain/dialog/SalaryDetailDialog/types';
import SendCashReceiveDialog from 'components/domain/dialog/SendCashReceiveDialog';
import SendEmployeeSalaryDialog from 'components/domain/dialog/SendEmployeeSalaryDialog';
import InsuranceInfoColumn from 'components/ui/InsuranceInfoColumn/Index';
import { displayComma } from 'lib';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { SalaryPayType } from 'types/api/salary';

import * as S from './styles';

interface Props {
	disabled?: boolean;
}

function SalaryDetailBasicInfo({ disabled }: Props) {
	const hasCalculateSalaryFunc = useHasFunc(['salary:calculate_salary']);
	const { watch, control } = useFormContext<SalaryDetailDialogFormFields>();
	const salary = watch('salaryDetail');
	const { showDialog } = useDialog();

	const onClickSendCashReceive = () => {
		showDialog(({ hideDialog }) => (
			<SendCashReceiveDialog employeeSalaryId={salary.employeeSalaryId} onClose={hideDialog} />
		));
	};

	const onClickSendEmployeeSalary = () => {
		showDialog(({ hideDialog }) => (
			<SendEmployeeSalaryDialog employeeSalaryId={salary.employeeSalaryId} onClose={hideDialog} />
		));
	};

	const onClickReCalculate = async () => {
		showDialog(({ hideDialog }) => (
			<SalaryCalculateConfirmDialog
				employeeSalaryId={salary.employeeSalaryId}
				onClose={hideDialog}
			/>
		));
	};

	const isCashReceive = salary.salaryPayTypeCd === SalaryPayType.현금수령;

	const isRecalculateDisable = salary.salaryCompleteYn || !hasCalculateSalaryFunc || disabled;

	const lastSalarySendDate = salary.salarySendDate
		? `최근 발송 요청 일시 ${dayjs(salary.salarySendDate).format('YYYY.MM.DD HH:mm')}`
		: '';

	const lastCashSendDate = salary.cashSendDate
		? ` (${dayjs(salary.cashSendDate).format('YYYY.MM.DD HH:mm')})`
		: '';

	const isDocumentSendable = salary.salaryCompleteYn;

	return (
		<S.Container>
			<FlexContainer justify='space-between'>
				<CRText typography='label' text='직원기본정보' margin='0 0 0.4rem 0' />

				<FlexContainer gap='0.8rem' align='center' margin='0 0 0.3rem 0'>
					{lastSalarySendDate && (
						<>
							<CRText typography='label' color='gray50' text={lastSalarySendDate} />
							<CRButton.Default
								onClick={onClickSendEmployeeSalary}
								size='xSmall'
								palette='gray'
								type='outlined'>
								발송
							</CRButton.Default>
						</>
					)}
					<CRToolTip
						title={
							<div>
								마지막으로 다운로드한 공단 청구내역으로 임금을 재계산 및 저장합니다. (식대 및
								본인부담금 수정 이력은 초기화됩니다.)
							</div>
						}
						placement='up'>
						<CRButton.Default
							disabled={isRecalculateDisable}
							onClick={onClickReCalculate}
							size='xSmall'
							palette='gray'
							type='outlined'>
							청구 내역 동기화
						</CRButton.Default>
					</CRToolTip>
				</FlexContainer>
			</FlexContainer>
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '25%' }}>이름</S.TableLabelColumn>
					<S.TableValueColumn $textAlign='left' align='left' style={{ width: '25%' }}>
						{salary.employeeNm}
					</S.TableValueColumn>
					<S.TableLabelColumn style={{ width: '25%' }}>생년월일</S.TableLabelColumn>
					<S.TableValueColumn $textAlign='left' style={{ width: '25%' }}>
						{dayjs(salary.birthDt).format('YYYY.MM.DD')}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>실 수령액</S.TableLabelColumn>
					<S.TableValueColumn $textAlign='left'>
						{displayComma(salary.realPayAmt)}
					</S.TableValueColumn>
					<S.TableLabelColumn>공제액</S.TableLabelColumn>
					<S.TableValueColumn $textAlign='left'>
						{displayComma(salary.deductSumAmt)}
					</S.TableValueColumn>
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>총 임금</S.TableLabelColumn>
					<S.TableValueColumn $textAlign='left'>
						<FlexContainer gap='0.6rem'>{displayComma(salary.paySumAmt)}</FlexContainer>
					</S.TableValueColumn>
					<S.TableLabelColumn>4대보험</S.TableLabelColumn>
					<InsuranceInfoColumn {...salary} />
				</S.TableRow>
				<S.TableRow>
					<S.TableLabelColumn>사회복지사</S.TableLabelColumn>
					<S.TableValueColumn $textAlign='left'>{salary?.managerNm || '-'}</S.TableValueColumn>
					<S.TableLabelColumn colSpan={2} />
				</S.TableRow>
			</S.Table>
			<CRText typography='label' text='임금 수령 정보' margin='0 0 0.4rem 0' />
			{isCashReceive ? (
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '25%' }}>수령 방식</S.TableLabelColumn>
						<S.TableValueColumn $textAlign='left' style={{ width: '75%' }}>
							<FlexContainer gap='0.6rem'>
								{salary.salaryPayTypeNm}
								{isDocumentSendable && (
									<>
										{lastCashSendDate}
										<CRButton.Default
											onClick={onClickSendCashReceive}
											size='xSmall'
											palette='gray'
											type='outlined'>
											발송
										</CRButton.Default>
									</>
								)}
							</FlexContainer>
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>월별 임금 메모</S.TableLabelColumn>
						<S.TableValueColumn $textAlign='left' colSpan={3}>
							<FlexContainer direction='column' align='flex-start'>
								<Controller
									control={control}
									name='salaryDetail.salaryMemo'
									render={({ field: { ...rest } }) => (
										<CRInput.TextArea
											{...rest}
											disabled={disabled}
											placeholder='월별 임금 메모 입력'
											typography='label'
										/>
									)}
								/>
								<CRText
									typography='label'
									color='gray60'
									text='해당 메모는 임금명세서에 같이 출력 되어 발송되므로 참고 바랍니다.'
								/>
							</FlexContainer>
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			) : (
				<S.Table>
					<S.TableRow>
						<S.TableLabelColumn style={{ width: '25%' }}>수령 방식</S.TableLabelColumn>
						<S.TableValueColumn $textAlign='left' style={{ width: '25%' }}>
							{salary.salaryPayTypeNm}
						</S.TableValueColumn>
						<S.TableLabelColumn style={{ width: '25%' }}>은행명</S.TableLabelColumn>
						<S.TableValueColumn $textAlign='left' style={{ width: '25%' }}>
							{salary.salaryAccountBankNm}
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>계좌번호</S.TableLabelColumn>
						<S.TableValueColumn $textAlign='left'>{salary.salaryAccountNo}</S.TableValueColumn>
						<S.TableLabelColumn>계좌주</S.TableLabelColumn>
						<S.TableValueColumn $textAlign='left'>
							{salary.salaryAccountDepositorNm}
						</S.TableValueColumn>
					</S.TableRow>
					<S.TableRow>
						<S.TableLabelColumn>월별 임금 메모</S.TableLabelColumn>
						<S.TableValueColumn $textAlign='left' colSpan={3}>
							<FlexContainer direction='column' align='flex-start'>
								<CRFormInput.TextArea
									name='salaryDetail.salaryMemo'
									fixedHeight
									disabled={disabled}
									placeholder='월별 임금 메모 입력'
									typography='label'
								/>

								<CRText
									color='gray60'
									text='해당 메모는 임금명세서에 같이 출력 되어 발송되므로 참고 바랍니다.'
								/>
							</FlexContainer>
						</S.TableValueColumn>
					</S.TableRow>
				</S.Table>
			)}
		</S.Container>
	);
}

export default SalaryDetailBasicInfo;
