import { CheckOption } from 'components/base/Selections/type';
import { CenterManager } from 'types/api/common';
import {
	EDocBeforeIssueInfoDTO,
	EDocDeviceUnusedApplicationIssueDTO,
	RecipientEdocAdmissionUseDTO,
} from 'types/api/eDoc';
import { FileDetailDTO } from 'types/dto';

export enum EDocTemplateType {
	재직증명서 = 'CMN119.0045',
	경력증명서 = 'CMN119.0046',
	방문간호기록지 = 'CMN119.0052',
	방문목욕기록지 = 'CMN119.0053',
	방문요양기록지 = 'CMN119.0054',
	사직서및퇴직급여지급연장동의서 = 'CMN119.0056',
	사직서 = 'CMN119.0093',
	퇴직급여지급연장동의서 = 'CMN119.0094',
	서약서 = 'CMN119.0057',
	입소이용신청서 = 'CMN119.0061',
	CMS자동이체신청서 = 'CMN119.0092',
	타명의통장확인서 = 'CMN119.0064',
	방문목욕소독및관찰일지 = 'CMN119.0066',
	업무수행일지 = 'CMN119.0073',
	외국인고용보험확인서 = 'CMN119.0085',
	외국인고용보험신청서및확인서 = 'CMN119.0086',
	건강보험취득취소확인서 = 'CMN119.0087',
	건강보험상실취소확인서 = 'CMN119.0088',
	단말기미사용신청서 = 'CMN119.0042',
}

type EDocStyleClassName = 'save_button' | 'info_button' | 'close_button' | 'print_button';
export type EDoc = {
	setStyle: (option: EDocStyleClassName, value: string) => void;
	getEFormData: () => {
		[key: string]: {
			data: string[];
		};
	};
	callSaveFileDownLoad: (
		filename: string,
		fileType: number,
		range1: number,
		range2?: number,
	) => void;
	setVisibleMenu: (arg: boolean) => void;
	setDefaultRatio: (arg: string) => void;
	setScrollView: (arg: boolean) => void;
	setRemoveTotalCountSlash: (arg: boolean) => void;
	setNecessaryEnabled: (enabled: boolean) => void;
	setEndSaveButtonEvent: (callbackFunction: () => void) => void;
	view: () => void;
	setStartPrintButtonEvent: (callback: () => void) => void;
	callHTML5Print: () => void;
	callPDFPrint: () => void;
};

export type EDocIssueFormTypes = {
	eDocType: CheckOption;
	recipientNm: string;
	recipientPhoneNo: string;
};

// 재직 증명서
export type EmploymentCertificationFormTypes = {
	issueReason: CheckOption[];
	issueReasonEtcDescription: string;
	workStartDate: Date;
	data?: EDocBeforeIssueInfoDTO;
};

// 경력 증명서
export type CareerFormTypes = {
	issueReason: CheckOption[];
	issueReasonEtcDescription: string;
	data?: EDocBeforeIssueInfoDTO;
};

// 서약서
export type PledgeFormTypes = {
	recipient: CheckOption;
	pledgeContent: string;
	data?: EDocBeforeIssueInfoDTO;
};

// 타통장 신청서
export type OtherBankBookFormTypes = {
	bank: CheckOption; // 은행명
	accountNumber: string; // 계좌번호
	// 희망계좌 명의인
	name: string; // 희망계좌 명의인 - 성명
	birthDt: string; // 희망계좌 명의인 - 생년월일
	phoneNumber: string; // 희망계좌 명의인 - 성명
	relation: CheckOption;
	relationEtc: string;
	reason: string;
	data?: EDocBeforeIssueInfoDTO;
};

// 사직서 발급 신청서 폼
export type ResignFormTypes = {
	resignDate: Date; // 퇴사일
	resignType: CheckOption;
	resignDetailType: CheckOption;
	workStartDate: Date;
	resignTypeEtc: string;
	reason: string;
	data?: EDocBeforeIssueInfoDTO;
};

// 외국인고용보험 확인서 발급
export type ForeignConfirmFormTypes = {
	korNm?: string;
	engNm?: string;
	joinType?: CheckOption[];
	gender?: CheckOption[];
	rsdnNo?: string;
	birthDt?: string;
	countryNm?: string;
	stayQualificationNm?: string;
	date?: Date;
	data?: EDocBeforeIssueInfoDTO;
};

// 외국인고용보험 신청서 및 확인서 발급
export type ForeignRequestConfirmFormTypes = {
	korNm?: string;
	engNm?: string;
	joinType?: CheckOption[];
	gender?: CheckOption[];
	rsdnNo?: string;
	birthDt?: string;
	countryNm?: string;
	stayQualificationNm?: string;
	joinDate?: Date;
	dutyNm?: CheckOption;
	weekPerWorkTime?: string;
	monthlyAvgSalaryAmt?: string;
	paySumAmtCurrentYear?: string;
	paySumAmtLastYear?: string;
	date?: Date;
	data?: EDocBeforeIssueInfoDTO;
};

// 건강보험취득취소 확인서 발급
export type HealthInsuranceAcqCancelFormTypes = {
	healthInsuranceAcqDate?: Date;
	requestDate: Date;
	insCancelCd: CheckOption[];
	data?: EDocBeforeIssueInfoDTO;
};

// 건강보험상실취소 확인서 발급
export type HealthInsuranceLossCancelFormTypes = {
	healthInsuranceLossDate?: Date;
	requestDate: Date;
	data?: EDocBeforeIssueInfoDTO;
};

// 단말기미사용신청서
export type DeviceUnusedApplicationFormTypes = {
	beforePhoneNo: string;
	afterPhoneNo: string;
	requestReason: string;
	requestDate: Date;
	phoneNo: string;
	data?: EDocBeforeIssueInfoDTO;
};

// 서류 관리 - 서류 등록 폼
export type DocumentEnrollFormTypes = {
	paperType: CheckOption;
	paperTypeEtc: string;
	enrollDate: Date;
	remark: string;
	paperFile: FileDetailDTO[];
	vniMedicalInstitutionNm: string; // 방문간호 지시서 - 의료기관 명칭
	vniIssueDt: Date; // 발급일자
	vniValidPeriodDayCnt: string; // 유효기간 일수
	vniDoctorLicenseNo: string; // 의사면허번호
	vniVisitCntStandardCd: CheckOption; // 횟수기준코드
	vniVisitCnt: string; // 방문횟수
};

export interface AdmissionUseService {
	serviceType?: CheckOption;
	serviceDesc?: string;
	serviceFee?: string;
	serviceNumberOfMonth?: string;
}

// 서류 관리 - 입소이용신청서
export type AdmissionUseRequestFormTypes = {
	type: CheckOption;
	requestDate: Date;
	startEndDate: {
		start: Date | null;
		end: Date | null;
	};
	consenter?: CheckOption;
	birthDt: string;
	recipientNm: string;
	recipientRsdnNo: string;
	longTermGradeCd: CheckOption;
	longTermNo: string;
	recipientBaseAddr: string;
	recipientDetailAddr: string;
	recipientAddr: string;
	service: AdmissionUseService[];
	data?: EDocBeforeIssueInfoDTO;
	admissionUseEdocData?: RecipientEdocAdmissionUseDTO;
	confirmMode: boolean;
};

// 서류 관리 - CMS 자동이체신청서
export type CMSAutoTransferFormTypes = {
	applicantName?: string;
	applicantPhoneNo?: string;
	applicantDate?: string;
	accountName?: string;
	accountOwnerBirthday?: string;
	accountOwnerPhoneNo?: string;
	account?: string;
	bank?: CheckOption;
	data?: EDocBeforeIssueInfoDTO;
};

// 서명 필요없는 전자문서 발급 형식
export type EDocWithOutSignIssueItem = {
	nm?: string;
	birthDt?: string;
	workStartDate?: string;
	companyNm?: string;
	assignTaskNm: string;
	workStartEndDate: string;
	issueDescNm: string;
	year?: string;
	month?: string;
	day?: string;
	corpNm?: string;
	corpStampImageData?: string;
};

// 서약서 전자문서 발급 형식
export type EDocPledgeIssueItem = {
	nm?: string;
	signNm?: string;
	birthDt?: string;
	workStartDate?: string;
	centerNm?: string;
	recipientNm?: string;
	content?: string;
	year?: string;
	month?: string;
	day?: string;
};

// 타명의 통장 신청서 발급 형식
export type EDocOtherBankBookIssueItem = {
	nm?: string;
	signNm?: string;
	birthDt?: string;
	workStartDate?: string;
	centerNm?: string;
	bankNm: string;
	accountNumber: string;
	accountNm: string;
	accountBirth: string;
	accountPhone: string;
	relationshipType: string;
	reason: string;
	year?: string;
	month?: string;
	day?: string;
};

// 사직서 + 퇴직급여 지급 연장 동의서 발급 형식
export type EDocResignIssueItem = {
	nm: string;
	signNm: string;
	centerNm: string;
	birthDt: string;
	workStartDate: string;
	leaveHopeYear: string;
	leaveHopeMonth: string;
	leaveHopeDay: string;
	assignTaskNm: string;
	leaveChk1: string;
	leaveChk2: string;
	leaveComCdId: string;
	leaveDetailComCdId: string;
	year: string;
	month: string;
	day: string;
	leaveYear: string;
	leaveMonth: string;
	leaveDay: string;
	leaveReason: string;
	centerStampImageData: string;
};

// 외국인 고용보험 확인서 전자문서 발급 형식
export type EDocForeignConfirmIssueItem = {
	korNm: string;
	signKorNm: string;
	engNm: string;
	rsdnNo: string;
	birthDt: string;
	genderNm: string;
	countryNm: string;
	stayQualificationNm: string;
	representativeNm: string;
	caringStampImageData?: string;
	date: string;
	subscribeChk: '1' | '0';
	unsubscribeChk: '1' | '0';
};

// 외국인 고용보험 신청서 및 확인서 전자문서 발급 형식
export type EDocForeignRequestConfirmIssueItem = {
	korNm: string;
	signKorNm: string;
	engNm: string;
	rsdnNo: string;
	birthDt: string;
	genderNm: string;
	countryNm: string;
	stayQualificationNm: string;
	representativeNm: string;
	bizNo: string;
	maleChk: string;
	femaleChk: string;
	dutySign: string;
	joinDate: string;
	weekPerWorkTime: string;
	dailyWorkerYChk: string;
	dailyWorkerNChk: string;
	monthlyAvgSalaryAmt: string;
	paySumAmtLastYear: string;
	paySumAmtCurrentYear: string;
	year: string;
	month: string;
	day: string;
	centerNm: string;
	centerAddr: string;
	centerPhoneNo: string;
	caringStampImageData?: string;
	date: string;
	subscribeChk: '1' | '0';
	unsubscribeChk: '1' | '0';
};

// 건강보험취득취소확인서 발급 형식
export type EDocHealthInsuranceAcqCancelIssueItem = {
	centerNm: string;
	centerPhoneNo: string;
	bizNo: string;
	healthInsuranceAcqDate: string;
	year: string;
	month: string;
	day: string;
	korNm: string;
	signKorNm: string;
	rsdnNo: string;
	phoneNo: string;
	insCancelCd: string;
	insCancelText: string;
};

// 건강보험상실취소확인서 발급 형식
export type EDocHealthInsuranceLossCancelIssueItem = {
	centerNm: string;
	centerPhoneNo: string;
	bizNo: string;
	healthInsuranceLossDate: string;
	year: string;
	month: string;
	day: string;
	korNm: string;
	signKorNm: string;
	rsdnNo: string;
	phoneNo: string;
};

// 단말기미사용신청서 발급 형식
export type EDocDeviceUnusedApplicationIssueItem = {
	centerNm: string;
	centerMark: string;
	employeeNm: string;
	signEmployeeNm: string;
	birthDt: string;
	requestPhoneInfo: string;
	phoneNo: string;
	requestReason: string;
	requestDt: string;
};

export type IssueAdmissionUseRequestService = {
	recipientNm?: string;
	recipientBirthDt?: string;
	recipientType?: string;
	recipientStartEndDate?: string;
	longTermNo?: string;
	longTermGradeNm?: string;
	serviceType: string;
	serviceDesc: string;
	serviceFee: string;
	serviceNumberOfMonth: string;
	serviceAmtOfMonth: string;
	serviceCenterNm: string;
	serviceCenterMark: string;
};

export type IssueAdmissionUseRequestWelfare = {
	welfareItemNm: string;
	welfareItemCd: string;
	welfareStartEndDate: string;
	welfareAmt: string;
	welfareCenterNm: string;
	welfareCenterMark: string;
	welfareTotalAmt?: string;
	welfarePurchase: string;
	welfareRental: string;
};

// 입소이용신청서 발급 양식
export type IssueAdmissionUseRequestIssueItem = {
	newChk: string;
	renewalChk: string;
	changeChk: string;
	terminationChk: string;
	consenterNm: string;
	signConsenterNm: string;
	consenterPhoneNo: string;
	consenterRelNm: string;
	consenterBirthDt: string;
	consenterAddr: string;
	recipientNm: string;
	signRecipientNm: string;
	recipientBirthDt: string;
	recipientAddr: string;
	recipientPhoneNo: string;
	rsdnNo: string;
	longTermNo: string;
	longTermGradeNm: string;
	centerNm: string;
	gubunChk1: string;
	gubunChk2: string;
	year: string;
	month: string;
	day: string;
	youNm: string;
	service: IssueAdmissionUseRequestService[];
	welfare: IssueAdmissionUseRequestWelfare[];
};

// CMS 자동이체신청서 발급 양식
export type IssueCMSAutoTransferIssueItem = {
	receiptCompanyNm: string; // 수납업체
	receiptPurposeDesc: string; // 수납목적
	representativeNm: string; // 대표자
	bizNo: string; // 사업자등록번호
	bizAddr: string; // 주소
	requesterNm: string; // 신청인
	signRequesterNm: string; // 신청인사인
	depositorRelationshipDesc: string; // 예금주와의관계
	phoneNo: string; // 연락처
	paymentFixChk: string; // 납부금액 - 고정금액체크
	paymentChangeChk: string; // 납부금액 - 변동체크
	paymentFixAmount: string; // 납부금액 - 고정금액
	paymentEveryMonthDate: string; // 납부일 매월 _일
	nonPaymentWithdrawalDate1: string; // 미납시 _일 첫번째
	nonPaymentWithdrawalDate2: string; // 미납시 _일 두번째
	bankNm: string; // 은행명
	depositorNm: string; // 예금주
	signDepositorNm: string; // 예금주사인
	bankAccountNo1: string; // 계좌번호 1
	bankAccountNo2: string;
	bankAccountNo3: string;
	bankAccountNo4: string;
	bankAccountNo5: string;
	bankAccountNo6: string;
	bankAccountNo7: string;
	bankAccountNo8: string;
	bankAccountNo9: string;
	bankAccountNo10: string;
	bankAccountNo11: string;
	bankAccountNo12: string;
	bankAccountNo13: string;
	bankAccountNo14: string;
	bankAccountNo15: string;
	bankAccountNo16: string;
	depositorBirthDate1: string; // 예금주생년월일 1
	depositorBirthDate2: string;
	depositorBirthDate3: string;
	depositorBirthDate4: string;
	depositorBirthDate5: string;
	depositorBirthDate6: string;
	depositorBirthDate7: string;
	depositorBirthDate8: string;
	depositorBirthDate9: string;
	depositorBirthDate10: string;
	depositorPhoneNo1: string; // 예금주 휴대전화번호 1
	depositorPhoneNo2: string;
	depositorPhoneNo3: string;
	depositorPhoneNo4: string;
	depositorPhoneNo5: string;
	depositorPhoneNo6: string;
	depositorPhoneNo7: string;
	depositorPhoneNo8: string;
	depositorPhoneNo9: string;
	depositorPhoneNo10: string;
	depositorPhoneNo11: string;
	collectPersonalInfoAgreeChk: string; // 개인정보이용수집 동의 체크
	collectPersonalInfoDisAgreeChk: string; // 개인정보이용수집 비동의 체크
	provisionPersonalInfoThirdPartyAgreeChk: string; // 개인정보 제3자 제공 동의 체크
	provisionPersonalInfoThirdPartyDisAgreeChk: string; // 개인정보 제3자 제공 비동의 체크
	year: string; // 년
	month: string; // 월
	day: string; // 일
};

export type ESignRequestForm = {
	eSignWayCd: CheckOption[];
	receiver: CheckOption<CenterManager>;
	facingManagerPhoneUsageDivCd: CheckOption[];
};

export enum LastESignStateCd {
	작성대기 = 'CMN171.05',
	서명대기 = 'CMN171.10',
	검수대기 = 'CMN171.20',
	완료 = 'CMN171.30',
	서명취소 = 'CMN171.40',
	기간만료 = 'CMN171.50',
	상태오류 = 'CMN171.99',
}
