import React, { ReactNode, useMemo } from 'react';
import {
	Navigate,
	Outlet,
	Route,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';

import RouterPath from 'common/router';
import CRLayout from 'components/base/CRLayout';
import ClientMobileLayout from 'components/domain/layout/ClientMobileLayout';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import { EnvConfig } from 'lib/config/env';
import EmployeeProvider from 'lib/provider/employee';
import FullTimeEmployeeProvider from 'lib/provider/fullTimeEmployee';
import GlobalLayoutProvider from 'lib/provider/ui/Global';
import NavigationProvider from 'lib/provider/ui/Navigation';
import WorkExecuteInspectionLogProvider from 'lib/provider/workExecuteInspectionLog';
import AddCenterFinanceInfoPage from 'pages/dashboard/AddCenterFinanceInfoPage';
import AlarmPage from 'pages/dashboard/AlarmPage';
import AnnualTOPage from 'pages/dashboard/AnnualTOPage';
import DocumentDetailPage from 'pages/dashboard/ApprovalPage/DocumentDetailPage';
import CenterAccountManagementPage from 'pages/dashboard/CenterAccountManagementPage';
import CenterAccountPage from 'pages/dashboard/CenterAccountPage';
import CenterDocumentPage from 'pages/dashboard/CenterDocumentPage';
import CenterFinanceInformationPage from 'pages/dashboard/CenterFinanceInformationPage';
import CenterInformationPage from 'pages/dashboard/CenterInformationPage';
import CenterUserPage from 'pages/dashboard/CenterUserPage';
import RecipientContractSendViewPage from 'pages/dashboard/ContractTaskPage/ContractSendViewPage';
import RenewContractSendViewPage from 'pages/dashboard/ContractTaskPage/RenewContractSendViewPage';
import EmployReportAutomationPage from 'pages/dashboard/EmployReportAutomationPage';
import EmploymentReportTab from 'pages/dashboard/EmployReportAutomationPage/EmploymentReportTab';
import ResignReportTab from 'pages/dashboard/EmployReportAutomationPage/ResignReportTab';
import EmployeeBasicTab from 'pages/dashboard/EmployeePage/tab/EmployeeBasicTab/EmployeeBasicTab';
import EmployeeCareerTab from 'pages/dashboard/EmployeePage/tab/EmployeeCareerTab';
import EmployeeConsultingTab from 'pages/dashboard/EmployeePage/tab/EmployeeConsultingTab';
import EmployeeContractTab from 'pages/dashboard/EmployeePage/tab/EmployeeContractTab';
import EmployeeDocumentTab from 'pages/dashboard/EmployeePage/tab/EmployeeDocumentTab';
import EmployeeEducationTab from 'pages/dashboard/EmployeePage/tab/EmployeeEducationTab';
import EmployeeLicenseTab from 'pages/dashboard/EmployeePage/tab/EmployeeLicenseTab';
import EmployeeMessageTab from 'pages/dashboard/EmployeePage/tab/EmployeeMessageTab';
import EmployeeSalaryTab from 'pages/dashboard/EmployeePage/tab/EmployeeSalaryTab';
import EmployeeScheduleTab from 'pages/dashboard/EmployeePage/tab/EmployeeScheduleTab';
import EmployeeResignPage from 'pages/dashboard/EmployeeResignPage';
import ExcelUploadPage from 'pages/dashboard/ExcelUploadPage';
import FullTimeEmployeePage from 'pages/dashboard/FullTimeEmployeePage';
import FullTimeEmployeeBasicTab from 'pages/dashboard/FullTimeEmployeePage/tab/FullTimeEmployeeBasicTab';
import FullTimeEmployeeDetailReportTab from 'pages/dashboard/FullTimeEmployeePage/tab/FullTimeEmployeeDetailReportTab';
import FullTimeEmployeeEducationTab from 'pages/dashboard/FullTimeEmployeePage/tab/FullTimeEmployeeEducationTab';
import FullTimeEmployeeLicenseTab from 'pages/dashboard/FullTimeEmployeePage/tab/FullTimeEmployeeLicenseTab';
import FullTimeEmployeeScheduleTab from 'pages/dashboard/FullTimeEmployeePage/tab/FullTimeEmployeeScheduleTab';
import FullTimeEmployeeResignPage from 'pages/dashboard/FullTimeEmployeeResignPage';
import InsuranceDetailPage from 'pages/dashboard/InsuranceDetailPage';
import InsuranceRequestPage from 'pages/dashboard/InsuranceRequestPage';
import MyCenterPage from 'pages/dashboard/MyCenterPage';
import NewContractManagementPage from 'pages/dashboard/NewContractManagementPage';
import EmployeeNewContractTab from 'pages/dashboard/NewContractManagementPage/EmployeeNewContractTab';
import RecipientNewContractTab from 'pages/dashboard/NewContractManagementPage/RecipientNewContractTab';
import RecipientWorkExecuteLogTab from 'pages/dashboard/RecipientPage/tab/RecipientWorkExecuteLogTab';
import RecipientResignPage from 'pages/dashboard/RecipientResignPage';
import RoundingHistoryTOPage from 'pages/dashboard/RoundingHistoryTOPage';
import RoundingRatePage from 'pages/dashboard/RoundingRatePage';
import {
	ScheduleClientEmployeePage,
	ScheduleClientRecipientPage,
} from 'pages/dashboard/SchedulePage/ClientMobilePage';
import InsurancePage from 'pages/dashboard/SchedulePage/InsurancePage';
import MonitoringDetailPage from 'pages/dashboard/SchedulePage/MonitoringDetailPage';
import MonitoringPage from 'pages/dashboard/SchedulePage/MonitoringPage';
import MonthSchedulePage from 'pages/dashboard/SchedulePage/MonthSchedulePage';
import EmployeeMonthSchedulePage from 'pages/dashboard/SchedulePage/MonthSchedulePage/EmployeeMonthSchedulePage';
import GasanSocialWorkerMonthSchedulePage from 'pages/dashboard/SchedulePage/MonthSchedulePage/GasanSocialWorkerMonthSchedulePage';
import RecipientMonthSchedulePage from 'pages/dashboard/SchedulePage/MonthSchedulePage/RecipientMonthSchedulePage';
import RecordingSheetPage from 'pages/dashboard/SchedulePage/RecordingSheetPage';
import TagAutomationPage from 'pages/dashboard/SchedulePage/TagAutomationPage';
import TagErrorPage from 'pages/dashboard/SchedulePage/TagErrorPage';
import SendDetailPage from 'pages/dashboard/SendDetailPage';
import SendHistoryPage from 'pages/dashboard/SendHistoryPage';
import ESignHistoryTab from 'pages/dashboard/SendHistoryPage/ESignHistoryTab';
import ReservedMessageHistoryTab from 'pages/dashboard/SendHistoryPage/ReservedMessageHistoryTab';
import SendHistoryTab from 'pages/dashboard/SendHistoryPage/SendHistoryTab';
import UpdateCenterFinanceInfoPage from 'pages/dashboard/UpdateCenterFinanceInfoPage';
import WorkExecuteInspectionLogBasicPage from 'pages/dashboard/WorkExecuteInspectionLogBasicPage';
import WorkExecuteInspectionLogDetailPage from 'pages/dashboard/WorkExecuteInspectionLogDetailPage';
import WorkExecuteLogBasicPage from 'pages/dashboard/WorkExecuteLogBasicPage';
import WorkExecuteLogDetailPage from 'pages/dashboard/WorkExecuteLogDetailPage';
import WorkExecuteLogNewPage from 'pages/dashboard/WorkExecuteLogNewPage';
import WorkScheduleManagementPage from 'pages/dashboard/WorkScheduleManagementPage';
import EDocDownloadPage from 'pages/electronicDocument/eDocPage/EDocDownloadPage';
import ClientMobileErrorPage from 'pages/exception/ErrorPage/ClientMobileErrorPage';
import DashBoardErrorPage from 'pages/exception/ErrorPage/DashBoardErrorPage';
import DashBoardNotFoundPage from 'pages/exception/NotFoundPage/DashBoardNotFoundPage';

import CenterInfoProvider from '../../lib/provider/centerInformation';
import ContractTaskProvider from '../../lib/provider/contract';
import ContractChangeEmployeeTaskProvider from '../../lib/provider/contractChangeEmployee';
import ContractRenewalTaskProvider from '../../lib/provider/contractRenewal';
import RecipientProvider from '../../lib/provider/recipient';
import WorkExecuteLogProvider from '../../lib/provider/workExecuteLog';
import {
	ApprovalPage,
	BillingPage,
	ConsultingDetailPage,
	ConsultingEditPage,
	ConsultingNewPage,
	ContractInspectionPage,
	ContractManagementPage,
	ContractTaskPage,
	EmployeeBasicPage,
	EmployeePage,
	MyTaskPage,
	NewDocumentPage,
	OwnExpensePage,
	RecipientBasicPage,
	RecipientBasicTab,
	RecipientConsultingTab,
	RecipientDocumentsTab,
	RecipientIntegratedAssessmentTab,
	RecipientMessageTab,
	RecipientOwnExpenseTab,
	RecipientPage,
	RecipientScheduleTab,
	RecipientServiceTab,
	SalaryPage,
	SendPage,
	ServiceInspectionPage,
} from '../dashboard';
import BillingTaskPage from '../dashboard/BillingTaskPage';
import ContractChangeEmployeeTaskPage from '../dashboard/ContractChangeEmployeeTaskPage';
import ChangeEmployeeContractInspectionPage from '../dashboard/ContractChangeEmployeeTaskPage/ChangeEmployeeContractInspectionPage';
import ChangeEmployeeEmployeeBasicPage from '../dashboard/ContractChangeEmployeeTaskPage/ChangeEmployeeEmployeeBasicPage';
import ChangeEmployeeRecipientBasicPage from '../dashboard/ContractChangeEmployeeTaskPage/ChangeEmployeeRecipientBasicPage';
import ChangeEmployeeServiceInspectionPage from '../dashboard/ContractChangeEmployeeTaskPage/ChangeEmployeeServiceInspectionPage';
import ContractRenewalTaskPage from '../dashboard/ContractRenewalTaskPage';
import RenewalContractInspectionPage from '../dashboard/ContractRenewalTaskPage/RenewalContractInspectionPage';
import RenewalRecipientBasicPage from '../dashboard/ContractRenewalTaskPage/RenewalRecipientBasicPage';
import RenewalServiceInspectionPage from '../dashboard/ContractRenewalTaskPage/RenewalServiceInspectionPage';
import IntegratedAssessmentBedsoreInfoPage from '../dashboard/IntegratedAssessmentBedsoreInfoPage';
import IntegratedAssessmentDesireInfoPage from '../dashboard/IntegratedAssessmentDesireInfoPage';
import IntegratedAssessmentFallInfoPage from '../dashboard/IntegratedAssessmentFallInfoPage';
import IntegratedAssessmentKmmseInfoPage from '../dashboard/IntegratedAssessmentKmmseInfoPage';
import IntegratedAssessmentSalaryOfferPlanInfoPage from '../dashboard/IntegratedAssessmentSalaryOfferPlanInfoPage';
import OwnExpenseMonthlyPage from '../dashboard/OwnExpenseMonthlyPage';
import OwnExpensePayHistoryPage from '../dashboard/OwnExpensePayHistoryPage';
import { EDoc404, EDocInfoPage, EDocPage, ESignInfoPage, ESignPage } from '../electronicDocument';
import { NewPasswordPage, SignInPage, SignUpPage } from '../sign';
import Helper from './Helper';
import ProtectedRouter from './ProtectedRouter';
import PublicRouter from './PublicRouter';

function RootRouter(): React.ReactElement {
	const renderDashboardLayoutWithErrorFallback = (children: ReactNode) => {
		if (EnvConfig.BUILD_ENV !== 'production') {
			return children;
		}
		return (
			<ErrorBoundary fallback={<DashBoardErrorPage />} level='global'>
				{children}
			</ErrorBoundary>
		);
	};

	const renderMoblileLayoutWithErrorFallback = (children: ReactNode) => {
		if (EnvConfig.BUILD_ENV !== 'production') {
			return children;
		}
		return (
			<ErrorBoundary fallback={<ClientMobileErrorPage />} level='global'>
				{children}
			</ErrorBoundary>
		);
	};

	const SignLayoutComponent = useMemo(
		() =>
			renderDashboardLayoutWithErrorFallback(
				<PublicRouter>
					<Outlet />
				</PublicRouter>,
			),
		[],
	);

	const ProtectedLayoutComponent = useMemo(
		() =>
			renderDashboardLayoutWithErrorFallback(
				<ProtectedRouter>
					<Outlet />
				</ProtectedRouter>,
			),
		[],
	);

	const DashboardLayoutComponent = useMemo(
		() =>
			renderDashboardLayoutWithErrorFallback(
				<ProtectedRouter>
					<NavigationProvider>
						<GlobalLayoutProvider>
							<CRLayout HelperComponent={<Helper />}>
								<Outlet />
							</CRLayout>
						</GlobalLayoutProvider>
					</NavigationProvider>
				</ProtectedRouter>,
			),
		[],
	);

	const AdminViewLayoutComponent = useMemo(
		() =>
			renderMoblileLayoutWithErrorFallback(
				<ProtectedRouter>
					<NavigationProvider>
						<GlobalLayoutProvider>
							<Outlet />
						</GlobalLayoutProvider>
					</NavigationProvider>
				</ProtectedRouter>,
			),
		[],
	);

	const ClientMobileLayoutComponent = useMemo(
		() =>
			renderMoblileLayoutWithErrorFallback(
				<ClientMobileLayout>
					<Outlet />
				</ClientMobileLayout>,
			),
		[],
	);

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route element={SignLayoutComponent}>
					<Route path={RouterPath.signIn()} element={<SignInPage />} />
					<Route path={RouterPath.signUp()} element={<SignUpPage />} />
				</Route>
				<Route element={ProtectedLayoutComponent}>
					<Route path={RouterPath.newPassword()} element={<NewPasswordPage />} />
				</Route>
				<Route path={RouterPath.root()} element={DashboardLayoutComponent}>
					<Route path='/' element={<Navigate to={RouterPath.recipient().tab.key} />} />
					<Route path='/excel-upload' element={<ExcelUploadPage />} />
					<Route path={RouterPath.alarm()} element={<AlarmPage />} />
					<Route path={RouterPath.approvalOperate().approval.key}>
						<Route index element={<ApprovalPage />} />
						<Route path={RouterPath.approvalOperate().document.key}>
							<Route
								index
								path={RouterPath.approvalOperate().new.uniqueKey}
								element={<NewDocumentPage />}
							/>
							<Route
								path={RouterPath.approvalOperate().detail.uniqueKey}
								element={<DocumentDetailPage />}
							/>
						</Route>
					</Route>
					<Route
						path={RouterPath.recipient().tab.key}
						element={
							<RecipientProvider>
								<RecipientPage />
							</RecipientProvider>
						}>
						<Route
							path={RouterPath.recipient().basicInformation.key}
							element={<RecipientBasicTab />}
						/>
						<Route path={RouterPath.recipient().service.key}>
							<Route index element={<RecipientServiceTab />} />
							<Route
								path={RouterPath.myTaskOperate().contractRenewal.key}
								element={
									<ContractRenewalTaskProvider>
										<ContractRenewalTaskPage />
									</ContractRenewalTaskProvider>
								}>
								<Route
									path={RouterPath.myTaskOperate().renewalRecipientBasic.uniqueKey}
									element={<RenewalRecipientBasicPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().renewalContractService.uniqueKey}
									element={<RenewalServiceInspectionPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().renewalContractInspection.uniqueKey}
									element={<RenewalContractInspectionPage />}
								/>
							</Route>
							<Route
								path={RouterPath.myTaskOperate().contractChangeEmployee.key}
								element={
									<ContractChangeEmployeeTaskProvider>
										<ContractChangeEmployeeTaskPage />
									</ContractChangeEmployeeTaskProvider>
								}>
								<Route
									path={RouterPath.myTaskOperate().changeEmployeeRecipientBasic.uniqueKey}
									element={<ChangeEmployeeRecipientBasicPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().changeEmployeeContractService.uniqueKey}
									element={<ChangeEmployeeServiceInspectionPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().changeEmployeeManager.uniqueKey}
									element={<ChangeEmployeeEmployeeBasicPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().changeEmployeeContractInspection.uniqueKey}
									element={<ChangeEmployeeContractInspectionPage />}
								/>
							</Route>
							<Route
								path={RouterPath.myTaskOperate().contract.key}
								element={
									<ContractTaskProvider>
										<ContractTaskPage />
									</ContractTaskProvider>
								}>
								<Route
									path={RouterPath.myTaskOperate().recipientBasic.uniqueKey}
									element={<RecipientBasicPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().contractService.uniqueKey}
									element={<ServiceInspectionPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().manager.uniqueKey}
									element={<EmployeeBasicPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().contractInspection.uniqueKey}
									element={<ContractInspectionPage />}
								/>
							</Route>
						</Route>
						<Route path={RouterPath.recipient().integratedAssessment.key}>
							<Route index element={<RecipientIntegratedAssessmentTab />} />
							<Route
								element={<IntegratedAssessmentFallInfoPage />}
								path={RouterPath.recipient().integratedAssessmentFallInfo.key}
							/>
							<Route
								element={<IntegratedAssessmentBedsoreInfoPage />}
								path={RouterPath.recipient().integratedAssessmentBedsoreInfo.key}
							/>
							<Route
								element={<IntegratedAssessmentKmmseInfoPage />}
								path={RouterPath.recipient().integratedAssessmentKmmseInfo.key}
							/>
							<Route
								element={<IntegratedAssessmentDesireInfoPage />}
								path={RouterPath.recipient().integratedAssessmentDesireInfo.key}
							/>
							<Route
								element={<IntegratedAssessmentSalaryOfferPlanInfoPage />}
								path={RouterPath.recipient().integratedAssessmentSalaryOfferPlanInfo.key}
							/>
						</Route>
						<Route path={RouterPath.recipient().workExecuteLogTab.key}>
							<Route index element={<RecipientWorkExecuteLogTab />} />
							<Route
								path={RouterPath.recipient().workExecuteLogNew.key}
								element={
									<WorkExecuteLogProvider>
										<WorkExecuteLogNewPage />
									</WorkExecuteLogProvider>
								}>
								<Route
									path={RouterPath.recipient().workExecuteLog.key}
									element={<WorkExecuteLogBasicPage />}
								/>
							</Route>
							<Route
								path={RouterPath.recipient().workExecuteLogDetail.key}
								element={
									<WorkExecuteLogProvider>
										<WorkExecuteLogDetailPage />
									</WorkExecuteLogProvider>
								}>
								<Route
									path={RouterPath.recipient().workExecuteLog.key}
									element={<WorkExecuteLogBasicPage />}
								/>
							</Route>
						</Route>
						<Route path={RouterPath.recipient().schedule.key} element={<RecipientScheduleTab />} />

						<Route
							path={RouterPath.recipient().ownExpense.key}
							element={<RecipientOwnExpenseTab />}
						/>
						<Route path={RouterPath.recipient().consulting.key}>
							<Route index element={<RecipientConsultingTab />} />
							<Route
								element={<ConsultingDetailPage />}
								path={RouterPath.recipient().consultingDetail.key}
							/>
							<Route
								element={<ConsultingEditPage />}
								path={RouterPath.recipient().consultingEdit.key}
							/>
							<Route
								element={<ConsultingNewPage />}
								path={RouterPath.recipient().consultingNew.key}
							/>
						</Route>
						<Route path={RouterPath.recipient().message.key}>
							<Route index element={<RecipientMessageTab />} />
							<Route
								path={RouterPath.recipient().workExecuteLogInspectionDetail.key}
								element={
									<WorkExecuteInspectionLogProvider>
										<WorkExecuteInspectionLogDetailPage />
									</WorkExecuteInspectionLogProvider>
								}>
								<Route index element={<WorkExecuteInspectionLogBasicPage />} />
							</Route>
						</Route>
						<Route
							path={RouterPath.recipient().documents.key}
							element={<RecipientDocumentsTab />}
						/>
						<Route path={RouterPath.recipient().resign.key} element={<RecipientResignPage />} />
					</Route>
					<Route
						path={RouterPath.employee().tab.key}
						element={
							<EmployeeProvider>
								<EmployeePage />
							</EmployeeProvider>
						}>
						<Route path={RouterPath.employee().basic.key} element={<EmployeeBasicTab />} />
						<Route path={RouterPath.employee().career.key} element={<EmployeeCareerTab />} />
						<Route path={RouterPath.employee().license.key} element={<EmployeeLicenseTab />} />
						<Route path={RouterPath.employee().education.key} element={<EmployeeEducationTab />} />
						<Route path={RouterPath.employee().contract.key}>
							<Route index element={<EmployeeContractTab />} />
							<Route
								path={RouterPath.myTaskOperate().contract.key}
								element={
									<ContractTaskProvider>
										<ContractTaskPage />
									</ContractTaskProvider>
								}>
								<Route
									path={RouterPath.myTaskOperate().recipientBasic.uniqueKey}
									element={<RecipientBasicPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().contractService.uniqueKey}
									element={<ServiceInspectionPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().manager.uniqueKey}
									element={<EmployeeBasicPage />}
								/>
								<Route
									path={RouterPath.myTaskOperate().contractInspection.uniqueKey}
									element={<ContractInspectionPage />}
								/>
							</Route>
						</Route>
						<Route path={RouterPath.employee().schedule.key} element={<EmployeeScheduleTab />} />
						<Route path={RouterPath.employee().wage.key} element={<EmployeeSalaryTab />} />
						<Route path={RouterPath.employee().consulting.key}>
							<Route index element={<EmployeeConsultingTab />} />
							<Route
								element={<ConsultingDetailPage />}
								path={RouterPath.employee().consultingDetail.key}
							/>
							<Route
								element={<ConsultingEditPage />}
								path={RouterPath.employee().consultingEdit.key}
							/>
							<Route
								element={<ConsultingNewPage />}
								path={RouterPath.employee().consultingNew.key}
							/>
						</Route>
						<Route path={RouterPath.employee().message.key} element={<EmployeeMessageTab />} />
						<Route path={RouterPath.employee().message.key} element={<EmployeeMessageTab />} />
						<Route path={RouterPath.employee().documents.key} element={<EmployeeDocumentTab />} />
						<Route path={RouterPath.employee().resign.key} element={<EmployeeResignPage />} />
					</Route>
					<Route
						path={RouterPath.fullTimeEmployee().tab.key}
						element={
							<FullTimeEmployeeProvider>
								<FullTimeEmployeePage />
							</FullTimeEmployeeProvider>
						}>
						<Route
							path={RouterPath.fullTimeEmployee().basic.key}
							element={<FullTimeEmployeeBasicTab />}
						/>
						<Route
							path={RouterPath.fullTimeEmployee().license.key}
							element={<FullTimeEmployeeLicenseTab />}
						/>
						<Route
							path={RouterPath.fullTimeEmployee().education.key}
							element={<FullTimeEmployeeEducationTab />}
						/>
						<Route
							path={RouterPath.fullTimeEmployee().detailReport.key}
							element={<FullTimeEmployeeDetailReportTab />}
						/>
						<Route
							path={RouterPath.fullTimeEmployee().schedule.key}
							element={<FullTimeEmployeeScheduleTab />}
						/>
						<Route
							path={RouterPath.fullTimeEmployee().resign.key}
							element={<FullTimeEmployeeResignPage />}
						/>
					</Route>
					<Route
						path={RouterPath.ownExpenseTab().ownExpenseMonthly.key}
						element={<OwnExpenseMonthlyPage />}
					/>
					<Route
						path={RouterPath.ownExpenseTab().ownExpensePayHistory.key}
						element={<OwnExpensePayHistoryPage />}
					/>
					<Route path={RouterPath.myTask()} element={<MyTaskPage />} />
					<Route path={RouterPath.workSchedule()}>
						<Route
							path={RouterPath.workScheduleTab().management.key}
							element={
								<EmployeeProvider>
									<WorkScheduleManagementPage />
								</EmployeeProvider>
							}
						/>
						<Route
							path={RouterPath.workScheduleTab().annualManagement.key}
							element={<AnnualTOPage />}
						/>
					</Route>
					<Route path={RouterPath.contract()}>
						<Route
							path={RouterPath.contractTab().contract.key}
							element={<ContractManagementPage />}
						/>
						<Route
							path={RouterPath.contractTab().employReportAutomation.key}
							element={<EmployReportAutomationPage />}>
							<Route
								path={RouterPath.employReportAutomationToTab().employment.key}
								element={<EmploymentReportTab />}
							/>
							<Route
								path={RouterPath.employReportAutomationToTab().resign.key}
								element={<ResignReportTab />}
							/>
						</Route>
						<Route
							path={RouterPath.contractTab().newContractManagement.key}
							element={<NewContractManagementPage />}>
							<Route
								path={RouterPath.newContractManagementToTab().recipient.key}
								element={<RecipientNewContractTab />}
							/>
							<Route
								path={RouterPath.newContractManagementToTab().employee.key}
								element={<EmployeeNewContractTab />}
							/>
						</Route>
					</Route>
					<Route>
						<Route
							path={RouterPath.scheduleOperate().monthSchedule.key}
							element={<MonthSchedulePage />}>
							<Route
								path={RouterPath.monthScheduleTab().recipient.key}
								element={
									<RecipientProvider>
										<RecipientMonthSchedulePage />
									</RecipientProvider>
								}
							/>
							<Route
								path={RouterPath.monthScheduleTab().employee.key}
								element={
									<EmployeeProvider>
										<EmployeeMonthSchedulePage />
									</EmployeeProvider>
								}
							/>
							<Route
								path={RouterPath.monthScheduleTab().gasan.key}
								element={<GasanSocialWorkerMonthSchedulePage />}
							/>
						</Route>
						<Route
							index
							path={RouterPath.scheduleOperate().tagError.key}
							element={<TagErrorPage />}
						/>
						<Route
							index
							path={RouterPath.scheduleOperate().recordingSheet.key}
							element={<RecordingSheetPage />}
						/>
						<Route
							index
							path={RouterPath.scheduleOperate().tagAutomation.key}
							element={<TagAutomationPage />}
						/>
						<Route path={RouterPath.scheduleOperate().monitoring.key}>
							<Route index element={<MonitoringPage />} />
							<Route
								path={RouterPath.scheduleOperate().monitoringDetail.key}
								element={<MonitoringDetailPage />}
							/>
						</Route>
					</Route>
					<Route path={RouterPath.roundingManagement()}>
						<Route
							path={RouterPath.roundingManagementTab().roundingHistory.key}
							element={<RoundingHistoryTOPage />}
						/>
						<Route
							path={RouterPath.roundingManagementTab().roundingRate.key}
							element={<RoundingRatePage />}
						/>
					</Route>
					<Route path={RouterPath.ownExpense()} element={<OwnExpensePage />} />
					<Route path={RouterPath.salary()} element={<SalaryPage />} />
					<Route path={RouterPath.billing()} element={<BillingPage />} />
					<Route path={RouterPath.myCenter()} element={<MyCenterPage />}>
						<Route
							path={RouterPath.myCenterOperate().user.uniqueKey}
							element={<CenterUserPage />}
						/>
						<Route
							path={RouterPath.myCenterOperate().document.uniqueKey}
							element={<CenterDocumentPage />}
						/>
						<Route
							path={RouterPath.myCenterOperate().information.uniqueKey}
							element={
								<CenterInfoProvider>
									<CenterInformationPage />
								</CenterInfoProvider>
							}
						/>
						<Route
							path={RouterPath.myCenterOperate().accountManagement.uniqueKey}
							element={<CenterAccountManagementPage />}
						/>
						<Route path={RouterPath.myCenterOperate().financeInformation.uniqueKey}>
							<Route index element={<CenterFinanceInformationPage />} />
							<Route
								path={RouterPath.myCenterOperate().addFinanceInformation.uniqueKey}
								element={<AddCenterFinanceInfoPage />}
							/>
							<Route
								path={RouterPath.myCenterOperate().updateFinanceInformation.uniqueKey}
								element={<UpdateCenterFinanceInfoPage />}
							/>
						</Route>
					</Route>
					<Route
						path={RouterPath.myTaskOperate().contract.key}
						element={
							<ContractTaskProvider>
								<ContractTaskPage />
							</ContractTaskProvider>
						}>
						<Route
							path={RouterPath.myTaskOperate().recipientBasic.uniqueKey}
							element={<RecipientBasicPage />}
						/>
						<Route
							path={RouterPath.myTaskOperate().contractService.uniqueKey}
							element={<ServiceInspectionPage />}
						/>
						<Route
							path={RouterPath.myTaskOperate().manager.uniqueKey}
							element={<EmployeeBasicPage />}
						/>
						<Route
							path={RouterPath.myTaskOperate().contractInspection.uniqueKey}
							element={<ContractInspectionPage />}
						/>
					</Route>
					<Route
						path={RouterPath.myTaskOperate().contractRenewal.key}
						element={
							<ContractRenewalTaskProvider>
								<ContractRenewalTaskPage />
							</ContractRenewalTaskProvider>
						}>
						<Route
							path={RouterPath.myTaskOperate().recipientBasic.uniqueKey}
							element={<RenewalRecipientBasicPage />}
						/>
						<Route
							path={RouterPath.myTaskOperate().contractService.uniqueKey}
							element={<RenewalServiceInspectionPage />}
						/>
						<Route
							path={RouterPath.myTaskOperate().contractInspection.uniqueKey}
							element={<RenewalContractInspectionPage />}
						/>
					</Route>
					<Route
						path={RouterPath.myTaskOperate().contractChangeEmployee.key}
						element={
							<ContractChangeEmployeeTaskProvider>
								<ContractChangeEmployeeTaskPage />
							</ContractChangeEmployeeTaskProvider>
						}>
						<Route
							path={RouterPath.myTaskOperate().recipientBasic.uniqueKey}
							element={<ChangeEmployeeRecipientBasicPage />}
						/>
						<Route
							path={RouterPath.myTaskOperate().contractService.uniqueKey}
							element={<ChangeEmployeeServiceInspectionPage />}
						/>
						<Route
							path={RouterPath.myTaskOperate().manager.uniqueKey}
							element={<ChangeEmployeeEmployeeBasicPage />}
						/>
						<Route
							path={RouterPath.myTaskOperate().contractInspection.uniqueKey}
							element={<ChangeEmployeeContractInspectionPage />}
						/>
					</Route>
					<Route path={RouterPath.insurance().tab.key}>
						<Route path={RouterPath.insurance().tab.key} element={<InsurancePage />} index />
						<Route
							path={RouterPath.insurance().request.key}
							element={<InsuranceRequestPage />}
							index
						/>
						<Route path={RouterPath.insurance().detail.key} element={<InsuranceDetailPage />} />
						<Route
							path={RouterPath.insurance().historyDetail.key}
							element={<InsuranceDetailPage />}
						/>
					</Route>
					<Route path={RouterPath.centerAccount().tab.key}>
						<Route
							path={RouterPath.centerAccount().tab.key}
							element={<CenterAccountPage />}
							index
						/>
					</Route>
					<Route path={RouterPath.send().tab.key}>
						<Route path={RouterPath.send().do.key} element={<SendPage />} />
						<Route path={RouterPath.send().history.key} element={<SendHistoryPage />}>
							<Route path={RouterPath.sendHistoryTab().message.key} element={<SendHistoryTab />} />
							<Route path={RouterPath.sendHistoryTab().esign.key} element={<ESignHistoryTab />} />
							<Route
								path={RouterPath.sendHistoryTab().reserve.key}
								element={<ReservedMessageHistoryTab />}
							/>
						</Route>
						<Route path={RouterPath.send().sendDetail.key} element={<SendDetailPage />} />
					</Route>
					<Route
						path={RouterPath.send().workExecuteLogInspectionDetail.key}
						element={
							<WorkExecuteInspectionLogProvider>
								<WorkExecuteInspectionLogDetailPage />
							</WorkExecuteInspectionLogProvider>
						}>
						<Route index element={<WorkExecuteInspectionLogBasicPage />} />
					</Route>
				</Route>
				<Route path={RouterPath.signOut()} action={undefined} />
				<Route path={RouterPath.eDoc()} element={ClientMobileLayoutComponent}>
					<Route path={RouterPath.eDoc()} element={<EDocPage />} />
					<Route path={RouterPath.eDocDownload()} element={<EDocDownloadPage />} />
					<Route path={RouterPath.eDocInfo().info.key} element={<EDocInfoPage />} />
					<Route path={RouterPath.eDocInfo().notFound.key} element={<EDoc404 />} />
				</Route>
				<Route path={RouterPath.eSign()} element={ClientMobileLayoutComponent}>
					<Route path={RouterPath.eSign()} element={<ESignPage />} />
					<Route path={RouterPath.eSignInfo().info.key} element={<ESignInfoPage />} />
					<Route path={RouterPath.eSignInfo().notFound.key} element={<EDoc404 />} />
				</Route>
				<Route element={AdminViewLayoutComponent}>
					<Route
						path={RouterPath.viewPage().contractSendInfo.key}
						element={<RecipientContractSendViewPage />}
					/>
					<Route
						path={RouterPath.viewPage().renewContractSendInfo.key}
						element={<RenewContractSendViewPage />}
					/>
				</Route>
				<Route path={RouterPath.schedule()} element={ClientMobileLayoutComponent}>
					<Route
						path={RouterPath.scheduleOperate().clientMobileEmployee.key}
						element={<ScheduleClientEmployeePage />}
					/>
					<Route
						path={RouterPath.scheduleOperate().clientMobileRecipient.key}
						element={<ScheduleClientRecipientPage />}
					/>
				</Route>
				<Route path='/*' element={<DashBoardNotFoundPage />} />
			</>,
		),
	);

	return <RouterProvider router={router} />;
}

export default RootRouter;
