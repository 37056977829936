import React, { useLayoutEffect, useRef, useState } from 'react';

import { CRTableHeadConfig, CRTableMultiHeadConfig } from 'types/view/base';

import * as S from './styles';

interface Props {
	heads: CRTableMultiHeadConfig[];
	offset?: number;
	disableBackBlind?: boolean;
}

function CRTableMultiHead({
	heads,
	offset = 0,
	disableBackBlind = false,
}: Props): React.ReactElement {
	const [top, setTop] = useState(0);

	const ref = useRef<HTMLTableSectionElement>(null);

	useLayoutEffect(() => {
		const parentTop = !disableBackBlind
			? ref.current?.parentElement?.parentElement?.parentElement?.getBoundingClientRect().top
			: ref.current?.parentElement?.parentElement?.getBoundingClientRect().top;
		const newTop = (ref.current?.getBoundingClientRect().top ?? 0) - (parentTop ?? 0);
		setTop(newTop + offset - 1);
	}, [offset, disableBackBlind]);

	const renderHeaderCell = ({
		label,
		sortKey,
		width,
		colSpan,
		rowSpan,
		children,
		...rest
	}: CRTableMultiHeadConfig) => {
		const calculatedWidth = children?.length
			? `${children.reduce((total, child) => {
					const childWidth = child.width ? parseFloat(String(child.width).replace('rem', '')) : 0;
					return total + childWidth;
				}, 0)}rem`
			: width || 'auto';

		return (
			<S.TableHeaderColumn
				key={sortKey}
				colSpan={colSpan}
				rowSpan={rowSpan}
				style={{
					width: calculatedWidth,
					maxWidth: calculatedWidth,
					minWidth: calculatedWidth,
					top,
					...rest,
				}}>
				{label}
			</S.TableHeaderColumn>
		);
	};

	const renderSubHeader = ({ label, sortKey, width, ...rest }: CRTableHeadConfig) => (
		<S.TableSubHeaderColumn
			key={sortKey}
			style={{
				width,
				maxWidth: width,
				minWidth: width,
				top,
				...rest,
			}}>
			{label}
		</S.TableSubHeaderColumn>
	);

	const childHeaders = heads.flatMap(
		(header) => header.children || [{ label: '', sortKey: '', display: 'none' }],
	);

	return (
		<S.TableHeader ref={ref}>
			<S.TableHeaderRow>
				{heads.map((header) =>
					renderHeaderCell({
						...header,
						colSpan: header.children?.length || 1,
						rowSpan: header.children?.length ? 1 : 2,
					}),
				)}
			</S.TableHeaderRow>
			<S.TableHeaderRow>{childHeaders.map(renderSubHeader)}</S.TableHeaderRow>
		</S.TableHeader>
	);
}

export default React.memo(CRTableMultiHead);
