import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { CRText } from 'components/base/CRText';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import { EmployeeContractReviewFormFields } from 'components/domain/sideModal/EmployeeContractReviewSideModal/types';
import {
	EMPLOYEE_CONTRACT_STATUS_CONFIG,
	SEND_STATUS_CONFIG,
	VALIDATION_STATUS_CONFIG,
} from 'components/domain/table/EmployeeContractManagementTable/constant';
import InformationTable from 'components/ui/InformationTable';
import { InformationLabelValueType } from 'components/ui/InformationTable/type';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { CRStatusConfig } from 'types/view/base';
import { EmployeeContractReviewListViewType } from 'types/view/contractReview';

import * as S from './styles';

interface Props {
	data: EmployeeContractReviewListViewType;
}

function EmployeeContractDetailInfo({ data }: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const { control } = useFormContext<EmployeeContractReviewFormFields>();

	const { data: commonCodes = { CMN218: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN218'] },
		commonCodeAdapter,
	);

	const renderConfigStatus = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || value === item.value);

		return <CRStatus options={config}>{currentValue?.label}</CRStatus>;
	}, []);

	const handleClickPreview = async (edocNo: string) => {
		showDialog(() => <EDocNoDialog edocNo={edocNo} centerId={Number(data.data.centerId)} />);
	};

	const contractToolTipText = `계약서 전자문서, ERP 계약 간\n아래 항목을 준수하는지 확인합니다.\n1. [급여종류] 일치\n2. [계약일] 일치\n3. [계약시작일] = [급여이용시작일]\n4. [계약종료일] = [급여이용종료일]`;

	const contractInfoTableItems = data.lognTermCareBenefitInfo.map((item) => ({
		key: `${data.employeeId}-${item.employeeContractDate}-${item.longtermCareBenefitType}`,
		tableInfo: [
			[
				{
					type: 'default',
					label: '급여종류',
					value: item.longtermCareBenefitType,
					labelStyle: { width: '10.4rem' },
					valueStyle: { minWidth: '12.4rem' },
				},
				{
					type: 'default',
					label: (
						<S.TableLabelContainer>
							<CRText typography='label' color='gray60'>
								유효성
							</CRText>
							<RDTooltip content={contractToolTipText} side='top'>
								<CRIcon
									src={Assets.icon.help}
									width='1.6rem'
									height='1.6rem'
									style={{ marginBottom: '0.2rem' }}
								/>
							</RDTooltip>
						</S.TableLabelContainer>
					),
					value: (
						<S.TableLabelContainer>
							<CRStatus options={VALIDATION_STATUS_CONFIG}>
								{item.isEmployeeContractValid
									? VALIDATION_STATUS_CONFIG[0].label
									: VALIDATION_STATUS_CONFIG[1].label}
							</CRStatus>
							{item.contractEdocNumber && (
								<CRButton.Default
									type='outlined'
									palette='gray'
									size='xSmall'
									onClick={() => handleClickPreview(item.contractEdocNumber)}>
									미리보기
								</CRButton.Default>
							)}
						</S.TableLabelContainer>
					),
					labelStyle: { width: '10.4rem' },
					valueStyle: { minWidth: '12.4rem' },
				},
			],
			[
				{
					type: 'default',
					label: '시급',
					value: item.employeeContractHourlyWage.toLocaleString('ko-KR'),
					labelStyle: { width: '10.4rem' },
				},
				{
					type: 'default',
					label: '발송상태',
					value: renderConfigStatus(SEND_STATUS_CONFIG, item.employeeContractSendingStatus),
					labelStyle: { width: '10.4rem' },
				},
			],
			[
				{
					type: 'default',
					label: '월 소정 근로시간',
					value: item.employeeContractMonthlyWorkHours ? (
						<CRText typography='label'>
							{`${item.employeeContractMonthlyWorkHours}시간`}
							<CRText
								typography='label'
								color={item.isContractWorkHoursAlignedWithPolicy ? 'gray60' : 'primary60'}
								text={item.isContractWorkHoursAlignedWithPolicy ? ' (지침 준수)' : ' (지침 미준수)'}
							/>
						</CRText>
					) : (
						'-'
					),
					labelStyle: { width: '10.4rem' },
				},
				{
					type: 'default',
					label: '계약일',
					value: item.employeeContractDate || '-',
					labelStyle: { width: '10.4rem' },
				},
			],
			[
				{
					type: 'default',
					label: '급여이용일시',
					value: item.longtermCareBenefitUseDay || '-',
					valueColSpan: 3,
				},
			],
		] as InformationLabelValueType[][],
	}));

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						근로계약서
						<CRStatus type='pill' options={EMPLOYEE_CONTRACT_STATUS_CONFIG}>
							{EMPLOYEE_CONTRACT_STATUS_CONFIG.find(
								(item) => item.value === data.employeeContractCompletionStatus,
							)?.label || ''}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				{contractInfoTableItems ? (
					contractInfoTableItems.map((item) => (
						<InformationTable key={item.key} items={item.tableInfo} />
					))
				) : (
					<S.EmptyContainer>
						<CRText typography='label' color='gray60' text='발급된 계약서가 없습니다.' />
					</S.EmptyContainer>
				)}
				<CRInputLabel label='계약서 검수' type='left'>
					<Controller
						control={control}
						name='employeeContractCompleteCd'
						render={({ field: { ...rest } }) => (
							<CRInput.Selector
								currentValue={commonCodes.CMN218.find((item) => item.value === rest.value)}
								onChangeValue={(value) => rest.onChange(value.value)}
								items={commonCodes.CMN218}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default EmployeeContractDetailInfo;
