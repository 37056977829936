import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import OwnExpenseMonthlyDetailDialog from 'components/domain/dialog/OwnExpenseMonthlyDetailDialog';
import OwnExpenseMonthlyTable from 'components/domain/table/OwnExpenseMonthlyTable';
import EmptyDescription from 'components/ui/EmptyDescription';
import InformationSheet from 'components/ui/InformationSheet';
import { centerMangersOptionsAdapter, commonCodeAdapter } from 'lib/adapter/common';
import { ownExpenseMonthlyPayAdapter } from 'lib/adapter/own-expense';
import {
	useCenterManagers,
	useCommonCodes,
	useMonthlyBurdenChargeSummary,
	useMonthlyBurdenCharges,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';
import { OwnExpenseMonthlyPay } from 'types/view/ownExpense';

import * as S from './styles';

interface Props {
	monthDate: Date;
	onChangeMonthDate: (date: Date) => void;
}

function OwnExpenseMonthlyTab({ monthDate, onChangeMonthDate }: Props): React.ReactElement {
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const { showDialog } = useGlobalLayout();
	const [keyword, setKeyword] = useState<string>('');
	const [searchValue, setSearchValue] = useState<string>('');
	const { filters: currentFilter, setFilters: setCurrentFilter } = usePageFilter({
		serviceFilter: [],
		managerFilter: [],
	});

	const { data: myAccountInfo } = useMyAccountInfo();

	const { data: monthlyBurdenChargeSummary } = useMonthlyBurdenChargeSummary({
		burdenAmtChargeYm: dayjs(monthDate).format('YYYYMM'),
		centerId: myAccountInfo?.centerId,
	});

	const {
		data: monthlyBurdenCharges,
		isLoading: isMonthlyBurdenChargesLoading,
		refetch: refetchMonthlyBurdenCharges,
	} = useMonthlyBurdenCharges(
		{
			burdenAmtChargeYm: dayjs(monthDate).format('YYYYMM'),
			centerId: myAccountInfo?.centerId,
			page: pageInfo.page,
			size: pageInfo.size,
			serviceTypeCds: currentFilter?.serviceFilter?.map((filter) => filter.value),
			managerIds: currentFilter?.managerFilter?.map((filter) => filter.value),
			keyword,
		},
		ownExpenseMonthlyPayAdapter,
	);

	const { data: commonCodes = {} } = useCommonCodes(
		{
			comCdGroupNms: ['CMN006'],
		},
		commonCodeAdapter,
	);

	const { data: centerManagerFilter } = useCenterManagers(
		{
			centerId: myAccountInfo?.centerId,
		},
		centerMangersOptionsAdapter,
	);

	const filters = useMemo(
		() =>
			[
				{
					key: 'serviceFilter',
					type: 'multi',
					placeholder: '급여 종류',
					options: commonCodes?.CMN006?.filter((code) => code?.data?.etcDesc1 === 'Y'),
				},
				{
					key: 'managerFilter',
					type: 'multi',
					placeholder: '사회복지사',
					options: centerManagerFilter,
				},
			] as Filter<{
				label: string;
				value: any;
			}>[],
		[commonCodes.CMN006, centerManagerFilter],
	);

	const handleClickOwnExpenseMonthlyItem = (item: OwnExpenseMonthlyPay) => {
		showDialog(({ hideDialog }) => (
			<OwnExpenseMonthlyDetailDialog
				item={item}
				onClickClose={hideDialog}
				refetch={refetchMonthlyBurdenCharges}
			/>
		));
	};

	const handleChangeSearchValue = (value: string) => {
		setSearchValue(value);
	};

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	useEffect(() => {
		if (monthlyBurdenCharges?.totalPages !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: monthlyBurdenCharges?.totalPages || 0,
			}));
		}
	}, [monthlyBurdenCharges?.totalPages]);

	return (
		<S.Container>
			<S.ContentContainer>
				<S.CalendarHeaderContainer>
					<CRTableMonthSelector value={monthDate} onChange={onChangeMonthDate} />
					<S.LastUpdatedDateText>
						{monthlyBurdenChargeSummary?.uploadHist?.uploadEndDate &&
							dayjs(monthlyBurdenChargeSummary?.uploadHist?.uploadEndDate).format(
								'YYYY.MM.DD HH:mm 업데이트',
							)}
					</S.LastUpdatedDateText>
				</S.CalendarHeaderContainer>
				{monthlyBurdenChargeSummary?.burdenAmtChargeSummarys.length ? (
					<>
						<S.SummaryContainer>
							{monthlyBurdenChargeSummary.burdenAmtChargeSummarys.map((summary) => (
								<InformationSheet
									title={`${
										summary.serviceTypeNm
									} 총 합계 (수급자 ${summary.count.toLocaleString()}명)`}
									items={[
										[
											{
												label: '수가총액',
												value: `${summary.feeSumAmt.toLocaleString()}원`,
												type: 'highlight',
											},
										],
										[{ type: 'divider' }],
										[
											{
												label: '공단청구금',
												value: `${summary.pcorpChargeAmt.toLocaleString()}원`,
											},
										],
										[
											{
												label: '본인부담금',
												value: `${summary.burdenAmt.toLocaleString()}원`,
											},
										],
										[
											{
												label: '가산후총액 (수급자)',
												value: `${summary.additionAfterSumAmt.toLocaleString()}원`,
												type: 'highlight',
											},
										],
										[{ type: 'divider' }],
										[
											{
												label: '가산금 (수급자)',
												value: `${summary.additionAmt.toLocaleString()}원`,
											},
										],
									]}
									type='gray'
								/>
							))}
						</S.SummaryContainer>
						<S.OwnExpenseTableContainer>
							<OwnExpenseMonthlyTable
								filters={filters}
								pageInfo={pageInfo}
								currentValue={currentFilter}
								searchValue={searchValue}
								onChangeSearchValue={handleChangeSearchValue}
								onChangePageInfo={handleChangePageInfo}
								setCurrentFilter={setCurrentFilter}
								onSearch={handleSearch}
								onRefresh={refetchMonthlyBurdenCharges}
								items={monthlyBurdenCharges?.content}
								isLoading={isMonthlyBurdenChargesLoading}
								onClickItem={handleClickOwnExpenseMonthlyItem}
							/>
						</S.OwnExpenseTableContainer>
					</>
				) : (
					<EmptyDescription
						title='본인부담금 정보가 없습니다.'
						description={
							'해당 월 본인부담금이 아직 생성되지 않았습니다.\n센터장에게 요청해 주세요.'
						}
					/>
				)}
			</S.ContentContainer>
		</S.Container>
	);
}

export default OwnExpenseMonthlyTab;
