import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import { endpoint } from 'lib/service/Api/endpoint';

export const useRecipients = useCRQuery(endpoint.getRecipients.key, 'getRecipients', {
	enabledKey: 'centerIds',
});

export const useRecipient = useCRQuery(endpoint.getRecipient.key, 'getRecipient', {
	enabledKey: 'recipientId',
});

export const useRecipientBase = useCRQuery(endpoint.getRecipientBase.key, 'getRecipientBase', {
	enabledKey: 'recipientId',
});

export const useCenterRecipientBase = useCRQuery(
	endpoint.getCenterRecipientBase.key,
	'getCenterRecipientBase',
	{
		enabledKey: ['recipientId', 'centerId'],
	},
);

export const useRecipientHistoryOfOwnExpense = useCRQuery(
	endpoint.getRecipientHistoryOfOwnExpense.key,
	'getRecipientHistoryOfOwnExpense',
	{
		enabledKey: 'recipientId',
	},
);

export const useRecipientHistoryOfManager = useCRQuery(
	endpoint.getRecipientHistoryOfManager.key,
	'getRecipientHistoryOfManager',
	{
		enabledKey: 'recipientId',
	},
);

export const useRecipientHistoryOfLongTermGrade = useCRQuery(
	endpoint.getRecipientHistoryOfLongTermGrade.key,
	'getRecipientHistoryOfLongTermGrade',
	{
		enabledKey: 'recipientId',
	},
);

export const useRecipientBurdens = useCRQuery(
	endpoint.getRecipientBurdens.key,
	'getRecipientBurdens',
	{
		enabledKey: ['centerId', 'recipientId'],
	},
);

export const useRecipientServices = useCRQuery(
	endpoint.getRecipientServices.key,
	'getRecipientServices',
	{
		enabledKey: ['centerId', 'recipientId'],
	},
);

export const useRecipientHistoryOfHolding = useCRQuery(
	endpoint.getRecipientHoldingHistories.key,
	'getRecipientHoldingHistories',
	{
		enabledKey: ['recipientId'],
	},
);

export const useWorkExecuteLogSalaryOfferPlan = useCRQuery(
	endpoint.getWorkExecuteLogSalaryOfferPlan.key,
	'getWorkExecuteLogSalaryOfferPlan',
	{
		enabledKey: ['centerId', 'recipientId', 'serviceTypeCd'],
	},
);
