import React from 'react';

import { Dayjs } from 'dayjs';

import CRDialog from 'components/base/CRDialog';
import { CRText } from 'components/base/CRText';
import useDialog from 'lib/hook/util/useDialog';

import * as S from './styles';

interface Props {
	title?: string;
	managerTagInfo: {
		roundingDt: Dayjs;
		startTime: string;
		endTime: string;
	}[];
	workExecuteLogInfo: {
		roundingDt: Dayjs;
		startTime: string;
		endTime: string;
		recipientName: string;
		longtermNumber: string;
	}[];
}

function DailyRoundingDialog({
	title,
	managerTagInfo,
	workExecuteLogInfo,
}: Props): React.ReactElement {
	const { hideDialog } = useDialog();

	return (
		<CRDialog
			title={title ?? '기본 근무시간 추가'}
			onClickClose={hideDialog}
			type='S'
			body={
				<S.Container>
					<S.BodyContainer>
						{managerTagInfo.length > 0 && (
							<S.SectionContainer>
								<CRText typography='label' text='공단 사회복지사 RFID' />
								<S.Table>
									<S.TableRow>
										<S.TableLabelColumn style={{ width: '5.6rem' }}>구분</S.TableLabelColumn>
										<S.TableLabelColumn>방문시간</S.TableLabelColumn>
									</S.TableRow>
									{managerTagInfo.map((item, index) => (
										<S.TableRow>
											<S.TableValueColumn $textAlign='right'>{index + 1}</S.TableValueColumn>
											<S.TableValueColumn $textAlign='left'>{`${item.startTime} ~ ${item.endTime}`}</S.TableValueColumn>
										</S.TableRow>
									))}
								</S.Table>
							</S.SectionContainer>
						)}
						{workExecuteLogInfo.length > 0 && (
							<S.SectionContainer>
								<CRText typography='label' text='업무수행일지' />
								<S.Table>
									<S.TableRow>
										<S.TableLabelColumn style={{ width: '5.6rem' }}>구분</S.TableLabelColumn>
										<S.TableLabelColumn style={{ width: '16.8rem' }}>방문시간</S.TableLabelColumn>
										<S.TableLabelColumn style={{ width: '8.8rem' }}>수급자명</S.TableLabelColumn>
										<S.TableLabelColumn style={{ width: '12rem' }}>
											장기요양인정번호
										</S.TableLabelColumn>
									</S.TableRow>
									{workExecuteLogInfo.map((item, index) => (
										<S.TableRow>
											<S.TableValueColumn $textAlign='right'>{index + 1}</S.TableValueColumn>
											<S.TableValueColumn $textAlign='left'>{`${item.startTime} ~ ${item.endTime}`}</S.TableValueColumn>
											<S.TableValueColumn $textAlign='left'>
												{item.recipientName}
											</S.TableValueColumn>
											<S.TableValueColumn $textAlign='left'>
												{item.longtermNumber}
											</S.TableValueColumn>
										</S.TableRow>
									))}
								</S.Table>
							</S.SectionContainer>
						)}
					</S.BodyContainer>
				</S.Container>
			}
		/>
	);
}

export default DailyRoundingDialog;
