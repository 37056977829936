import React, { useCallback, useState } from 'react';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import EmployReportUpdateSideModal from 'components/domain/sideModal/EmployReportUpdateSideModal';
import { useSaveEmployReportData } from 'lib/hook/react-query';
import useSideModal from 'lib/hook/util/useSideModal';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { EmployReportDataDTO } from 'types/dto/employReport';

import { RESIGN_AUTOMATION_TABLE_HEADER_CONFIG, TAG_STATUS_CONFIG } from './constant';
import * as S from './styles';

export enum SignStatus {
	Pending,
	Done,
}

export enum SendStatus {
	InProgress,
	Done,
	Failure,
}

interface Props {
	employReportData: EmployReportDataDTO[];
}

function ResignReportAutomationTable({ employReportData }: Props): React.ReactElement {
	const { showSideModal, hideSideModal } = useSideModal();
	const [currentRow, setCurrentRow] = useState<EmployReportDataDTO>();
	const { mutate: saveEmployReportData } = useSaveEmployReportData((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success(`정상적으로 자동화 요청 등록을 완료하였습니다.`);
			client.invalidateQueries([endpoint.getEmployReportData.key]);
		} else {
			Toast.error(`자동화 요청 등록에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`);
		}
	});

	const renderReportStatus = useCallback((value?: string, item?: EmployReportDataDTO) => {
		const renderText = (value?: string) => {
			switch (value) {
				case 'CMN153.10':
					return '대기';
				case 'CMN153.20':
					return '진행중';
				case 'CMN153.30':
					return '완료';
				case 'CMN153.40':
					return '실패';
				default:
					return '대기';
			}
		};

		return <CRStatus options={TAG_STATUS_CONFIG}>{renderText(item?.statusCd)}</CRStatus>;
	}, []);

	const renderOption = (value?: number, item?: EmployReportDataDTO) => (
		<S.ButtonContainer>
			<CRButton.Default
				size='xSmall'
				type='outlined'
				palette='gray'
				onClick={handleClickUpdateButton(item)}>
				수정
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderRequestStatus = useCallback(
		(value?: boolean) => (
			<CRText typography='label' color={value ? 'gray00' : 'gray60'}>
				{value ? '요청' : '미요청'}
			</CRText>
		),
		[],
	);

	const renderDate = useCallback(
		(value?: string) => (value ? dayjs(value).format('YYYY.MM.DD') : '-'),
		[],
	);

	const renderBirthDate = useCallback((value?: string) => {
		if (!value) return '-';
		const yearPrefix = ['1', '2', '5', '6'].includes(value[6]) ? '19' : '20';
		return dayjs(`${yearPrefix}${value.slice(0, 6)}`).format('YYYY.MM.DD');
	}, []);

	const handleClickUpdateButton = (item?: EmployReportDataDTO) => () => {
		if (!item) return;
		saveEmployReportData({ ...item, autoSsisYn: false });
	};

	const handleClickRow = (item: EmployReportDataDTO) => {
		if (item.id === currentRow?.id) {
			setCurrentRow(undefined);
			hideSideModal();
		} else {
			setCurrentRow(item);
			showSideModal(<EmployReportUpdateSideModal data={item} />);
		}
	};

	const handleRowStyle = (item: EmployReportDataDTO) =>
		item.id === currentRow?.id
			? {
					background: Colors.primary95,
				}
			: undefined;

	return (
		<S.Container>
			<S.TableScrollContainer>
				<CRTable.Root>
					<CRTable.Head heads={RESIGN_AUTOMATION_TABLE_HEADER_CONFIG} offset={-47} />
					<CRTable.Body>
						{employReportData.map((item) => (
							<CRTable.Row
								key={item.id}
								style={handleRowStyle(item)}
								item={item}
								renderKeys={[
									'statusNm',
									'autoSsisYn',
									'corpNm',
									'ssisCenterNm',
									'employeeNm',
									'employeeRsdnNo',
									'msg',
									'leaveDt',
									'workEndDt',
									'leaveFile',
									'id',
								]}
								customRender={{
									autoSsisYn: renderRequestStatus,
									employeeRsdnNo: renderBirthDate,
									statusNm: renderReportStatus,
									leaveFile: (value) => value?.fileDetails[0]?.originFileNm,
									msg: (value) => value || '-',
									leaveDt: renderDate,
									workEndDt: renderDate,
									id: renderOption,
								}}
								onClick={handleClickRow}
							/>
						))}
					</CRTable.Body>
				</CRTable.Root>
			</S.TableScrollContainer>
		</S.Container>
	);
}

export default ResignReportAutomationTable;
