import React, { useLayoutEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import RouterPath from 'common/router';
import CRTab from 'components/base/CRTab';
import { TabProps } from 'components/base/CRTab/DetailTab/type';

export default function NewContractManagementPage() {
	const { pathname } = useLocation();
	const newContractManagementToTab = RouterPath.newContractManagementToTab() as {
		recipient: { key: string; label: string };
		employee: { key: string; label: string };
	};

	const [currentTab, setCurrentTab] = useState<TabProps>();
	const navigate = useNavigate();
	const handleChangeTab = (item: TabProps) => {
		setCurrentTab(item);
		navigate(item.key);
	};

	useLayoutEffect(() => {
		const currentTab = Object.values(newContractManagementToTab).find(
			(item) => item.key === pathname,
		);
		setCurrentTab(currentTab);
	}, [pathname]);

	return (
		<CRTab.Default
			breadCrumb='신규계약 관리'
			defaultActiveKey={currentTab?.key}
			onChange={handleChangeTab}
			items={[
				{
					label: newContractManagementToTab.recipient.label,
					key: newContractManagementToTab.recipient.key,
					children: <Outlet />,
				},
				{
					label: newContractManagementToTab.employee.label,
					key: newContractManagementToTab.employee.key,
					children: <Outlet />,
				},
			]}
		/>
	);
}
