import React, { useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { useCenterAccountRecordTargets } from 'lib/hook/react-query/query/centerAccount';
import { useHasFunc } from 'lib/hook/util/useHasFunc';

import * as S from './styles';

export interface CenterAccountRecordFormDetail {
	tempId?: string;
	centerAccountRecordDetailId?: number;
	centerAccountRecordId?: number;
	centerId?: number;
	recordTargetDivValue?: CheckOption<any>;
	recordTargetDivCd?: string;
	recordTargetId?: number;
	recordTargetNm?: string;
	recordTypeValue?: CheckOption<any>;
	recordTypeCd?: string;
	recordTypeNm?: string;
	recordDetailValue?: CheckOption<any>;
	recordDetailCd?: string;
	recordDetailNm?: string;
	recordDesc?: string;
	depositAmt?: string;
	withdrawAmt?: string;
	centerAccountAliasNm?: string;
	recordDt?: string;
	recordTime?: string;
	recordKindNm?: string;
	balanceAmt?: number;
	record?: string;
	rowType?: string;
	recordDate?: string;
	recordTargetYm?: string;
}

export interface CenterAccountRecordFormData {
	centerAccountRecordId: number;
	centerId: number;
	centerNm: string;
	centerAccountId: number;
	centerAccountBankCd: string;
	centerAccountBankNm: string;
	centerAccountNo: string;
	centerAccountBizNo: string;
	centerAccountDepositorNm: string;
	verifyStateValue: string;
	recordKindNm: string;
	record: string;
	depositAmt: number;
	withdrawAmt: number;
	balanceAmt: number;
	centerAccountAliasNm: string;
	recordDt: string;
	recordTime: string;
	recordDate: string;
	recordTypeNm: string;
	recordDesc: string;
	centerAccountRecordDetails: CenterAccountRecordFormDetail[];
}
interface Props {
	disabledType?: string[];
	disabledInputs?: ['금액' | '유형' | '메모'];
}

function CenterAccountRecordDetailForm({
	disabledType,
	disabledInputs,
}: Props): React.ReactElement {
	const hasUpdateBankAccountDetailFunc = useHasFunc(['center:update_bank_account_detail']);

	const { data: accountInfo } = useMyAccountInfo();
	const { data: commonCodes = {} } = useCommonCodes(
		{
			comCdGroupNms: ['CMN035', 'CMN177'],
		},
		commonCodeAdapter,
	);
	const { control, getValues, watch } = useFormContext<CenterAccountRecordFormData>();
	const { remove, update } = useFieldArray({
		control,
		name: 'centerAccountRecordDetails',
	});

	const { data: centerAccountRecordTargets } = useCenterAccountRecordTargets({
		centerId: accountInfo?.centerId,
		recordTargetDivCds: 'CMN118.20',
	});
	const centerAccountRecordDetails = watch('centerAccountRecordDetails');

	const recordKindNm = watch('recordKindNm');

	const isDeposit = recordKindNm === '입금';

	const recordTypeOptions = useMemo(
		() => commonCodes.CMN035?.filter((val) => val.data?.etcDesc1 === recordKindNm) || [],
		[commonCodes.CMN035],
	);
	const secondRecordTypeOptions = useMemo(() => commonCodes.CMN177 || [], [commonCodes.CMN177]);

	const recipientOptions = useMemo(
		() =>
			centerAccountRecordTargets?.map((target) => ({
				value: {
					name: target.recordTargetNm,
					duty: '수급자',
					birth: target.recordTargetBirthDt,
					id: target.recordTargetId,
					cd: 'CMN118.20',
					guardian: target.recordTargetGuardianNm,
					manager: target.recordTargetManagerNm,
				},
				label: target.recordTargetNm,
				name: target.recordTargetNm,
			})) || [],
		[centerAccountRecordTargets],
	);

	const deleteRecordDetail = (targetIdx: number) => {
		const isSavedData = !!centerAccountRecordDetails[targetIdx]?.centerAccountRecordDetailId;
		if (!isSavedData) {
			remove(targetIdx);
		} else {
			update(targetIdx, {
				...getValues(`centerAccountRecordDetails.${targetIdx}`),
				rowType: 'DELETE',
			});
		}
	};

	const renderRecordTypeSelector = (index: number) => (
		<Controller
			name={`centerAccountRecordDetails.${index}.recordTypeValue`}
			control={control}
			render={({ field: { value, onChange, ...rest } }) => (
				<CRInputLabel label='유형' isRequired>
					<CRInput.Selector
						{...rest}
						currentValue={value}
						onChangeValue={(e) => {
							onChange(e);
							update(index, {
								...getValues(`centerAccountRecordDetails.${index}`),
								recordTypeValue: e,
								recordDetailValue: null as unknown as undefined,
								recordTargetDivValue: null as unknown as undefined,
							});
						}}
						items={recordTypeOptions.filter((code) => !disabledType?.includes(code.value))}
						placeholder='유형 선택'
						disabled={!hasUpdateBankAccountDetailFunc}
					/>
				</CRInputLabel>
			)}
		/>
	);

	const renderSecondSelector = (index: number) => {
		// 본인부담금 과납금 환불, 본인부담금수입, 장기요양급여수입(인건비비율 반영), 장기요양급여수입(인건비비율 비반영)
		const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010', 'CMN035.2150', 'CMN035.2160'];
		const recordTypeValue = getValues(`centerAccountRecordDetails.${index}.recordTypeValue`)?.value;
		const isRenderAble = renderAbleRecordType.includes(recordTypeValue);

		if (!isRenderAble) {
			return null;
		}

		return (
			<Controller
				name={`centerAccountRecordDetails.${index}.recordDetailValue`}
				control={control}
				render={({ field: { value, onChange, ...rest } }) => (
					<CRInput.Selector
						{...rest}
						currentValue={value}
						onChangeValue={(e) => {
							onChange(e);
							update(index, {
								...getValues(`centerAccountRecordDetails.${index}`),
							});
						}}
						items={secondRecordTypeOptions.filter((code) =>
							code.data?.etcDesc1.includes(recordTypeValue),
						)}
						placeholder='2차 선택'
						disabled={!hasUpdateBankAccountDetailFunc}
					/>
				)}
			/>
		);
	};

	const renderRecipientSelector = (index: number) => {
		const isRenderAble = getValues(`centerAccountRecordDetails.${index}.recordDetailValue`)?.value;
		if (!isRenderAble) return null;

		return (
			<Controller
				name={`centerAccountRecordDetails.${index}.recordTargetDivValue`}
				control={control}
				render={({ field: { value, onChange, ...rest } }) => (
					<CRInput.SearchSelector
						{...rest}
						currentValue={value}
						onChange={(e) => {
							onChange(e);
							update(index, {
								...getValues(`centerAccountRecordDetails.${index}`),
							});
						}}
						items={recipientOptions}
						searchKey={['guardian']}
						visibleKey={['duty', 'birth', 'manager']}
						placeholder='수급자 선택'
						disabled={!hasUpdateBankAccountDetailFunc}
					/>
				)}
			/>
		);
	};

	const renderTargetYmSelector = (index: number) => {
		const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010'];
		const isRenderAbleType = renderAbleRecordType.includes(
			getValues(`centerAccountRecordDetails.${index}.recordTypeValue`)?.value,
		);
		if (!isRenderAbleType) return null;

		return (
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.YearMonthPicker
						disabled={!hasUpdateBankAccountDetailFunc}
						placeholder='월 선택'
						currentValue={value ? dayjs(value).toDate() : undefined}
						onChangeValue={(e) => {
							onChange(e);
							update(index, {
								...getValues(`centerAccountRecordDetails.${index}`),
							});
						}}
					/>
				)}
				name={`centerAccountRecordDetails.${index}.recordTargetYm`}
				control={control}
			/>
		);
	};

	return (
		<S.HistoryListContainer>
			{centerAccountRecordDetails.map((_, index) => {
				if (_.rowType === 'DELETE') return null;
				return (
					<S.HistoryContainer key={_.tempId}>
						<FlexContainer justify='space-between'>
							<CRText text='내역 상세' typography='bodyB' />
							{index > 0 && (
								<CRButton.Default
									disabled={!hasUpdateBankAccountDetailFunc}
									type='outlined'
									size='xSmall'
									style={{ borderRadius: '1.6rem' }}
									onClick={() => deleteRecordDetail(index)}>
									삭제
								</CRButton.Default>
							)}
						</FlexContainer>
						{renderRecordTypeSelector(index)}
						{renderSecondSelector(index)}
						{renderRecipientSelector(index)}
						{renderTargetYmSelector(index)}
						<Controller
							name={
								isDeposit
									? `centerAccountRecordDetails.${index}.depositAmt`
									: `centerAccountRecordDetails.${index}.withdrawAmt`
							}
							control={control}
							render={({ field: { value, onChange, ...rest } }) => (
								<CRInputLabel label='금액' isRequired>
									<CRInput.Default
										{...rest}
										type='comma'
										suffix='원'
										placeholder='0'
										value={value}
										onChange={onChange}
										disabled={disabledInputs?.includes('금액') || !hasUpdateBankAccountDetailFunc}
									/>
								</CRInputLabel>
							)}
						/>

						<Controller
							name={`centerAccountRecordDetails.${index}.recordDesc`}
							control={control}
							render={({ field: { value, onChange, ...rest } }) => (
								<CRInputLabel label='메모'>
									<CRInput.Default
										{...rest}
										placeholder='메모 입력'
										value={value}
										onChange={onChange}
										maxLength={100}
										disabled={!hasUpdateBankAccountDetailFunc}
										addOnBottom='100자까지 입력이 가능합니다.'
									/>
								</CRInputLabel>
							)}
						/>
					</S.HistoryContainer>
				);
			})}
		</S.HistoryListContainer>
	);
}

export default CenterAccountRecordDetailForm;
