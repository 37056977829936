import React, { CSSProperties, ChangeEvent, ForwardedRef, forwardRef } from 'react';

import CRCheckBox from '../CRCheckBox';
import {
	CheckAppearance,
	CheckBoxType,
	CheckGroupAlign,
	CheckOption,
	CheckSelectType,
} from '../type';
import * as S from './styles';

interface IProps {
	style?: CSSProperties;
	options: CheckOption[];
	value?: CheckOption[];
	checkType?: CheckSelectType;
	type: CheckBoxType;
	gap?: number;
	disabled?: boolean;
	direction?: CheckGroupAlign;
	appearanceType?: CheckAppearance;
	onChange?: (checkedList: CheckOption[]) => void;
}

function CRCheckBoxGroup(
	{
		style = {},
		options,
		value = [],
		checkType = 'multiple',
		type = 'checkbox',
		onChange,
		direction = 'row',
		gap = 0,
		disabled = false,
		appearanceType = 'default',
	}: IProps,
	ref?: ForwardedRef<HTMLDivElement>,
): React.ReactElement {
	const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target;
		let newValue = [...value];
		const matchedItem = options.find((option) => String(option.value) === name);
		const index = value.findIndex((e) => e.value === matchedItem?.value);
		console.log('index', index, matchedItem);
		if (checkType === 'multiple') {
			if (checked && matchedItem) {
				newValue.push(matchedItem);
			} else {
				newValue.splice(index, 1);
			}
		} else if (matchedItem) {
			newValue = [matchedItem];
		}
		onChange?.(newValue);
	};

	return (
		<S.Container direction={direction} gap={gap} ref={ref} style={style}>
			{options.map((e) => (
				<CRCheckBox
					checkBoxType={type}
					key={e.value}
					appearanceType={appearanceType}
					name={String(e.value)}
					disabled={disabled || e.disabled}
					checked={!!value?.map((item) => item?.value).includes(e?.value)}
					onChange={handleCheckboxChange}>
					{e.label}
				</CRCheckBox>
			))}
		</S.Container>
	);
}

export default forwardRef(CRCheckBoxGroup);
