import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import * as Accordion from '@radix-ui/react-accordion';
import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import TaskAccordion from 'components/ui/radix/accordion/TaskAccordion';
import { displayMinuteToHourMinute } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import {
	useInsurancePriorInfo,
	useInsuranceSalaryFeeInfo,
} from 'lib/hook/react-query/query/insurance';
import { InsurancePriorInfoDTO, SalaryFee } from 'types/api/insurance';
import { InsuranceLossFormType } from 'types/view/insurance';

import { InsuranceType, insuranceTypeMap } from '..';
import * as S from './styles';

interface Props {
	context: UseFormReturn<InsuranceLossFormType, any, undefined>;
	requestTypeCd?: string;
}
export default function InsuranceLossForm({ context, requestTypeCd }: Props) {
	const [targetDt, setTargetDt] = useState<string>('');
	const isValidTargetDt = targetDt && dayjs(targetDt).isAfter('20240101');
	// const targetYear = useMemo(() => (targetDt ? Number(targetDt.slice(0, 4)) : 0), [targetDt]);
	const { data: myAccountInfo } = useMyAccountInfo();
	const params = useParams<{ id: string }>();
	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN114', 'CMN115', 'CMN007'],
		},
		commonCodeAdapter,
	);

	const { data: insurancePriorInfo } = useInsurancePriorInfo({
		employeeId: Number(params.id),
		centerId: myAccountInfo?.centerId,
		insuranceTypeCd: requestTypeCd,
	});

	const { data: insuranceSalaryFeeInfo, remove: removeSalaryInfo } = useInsuranceSalaryFeeInfo({
		targetDt,
		employeeId: Number(params.id),
		centerId: myAccountInfo?.centerId,
	});

	const formData = context.watch();
	const {
		employInsuranceRequestDate,
		healthInsuranceRequestDate,
		pensionInsuranceRequestDate,
		accidentInsuranceRequestDate,
		insuranceType,
		issueCareerChangeCert,
	} = formData;

	const isStartOfMonth = useMemo(() => dayjs(targetDt).date() === 1, [targetDt]);

	const {
		fields: currentMonth,
		append: appendCurrentMonth,
		remove: removeCurrentMonth,
	} = useFieldArray({
		control: context.control,
		name: 'currentMonth',
	});

	const insuranceDates = useMemo(
		() =>
			[
				employInsuranceRequestDate,
				healthInsuranceRequestDate,
				pensionInsuranceRequestDate,
				accidentInsuranceRequestDate,
			]
				.filter(Boolean)
				.map((item) => dayjs(item).format('YYYYMMDD')),
		[
			employInsuranceRequestDate,
			healthInsuranceRequestDate,
			pensionInsuranceRequestDate,
			accidentInsuranceRequestDate,
		],
	);

	const lossDetailOptions = useMemo(() => {
		const lossCd = context.watch('lossDescCd')?.value;
		return (
			commonCodes?.CMN115.filter(
				(item) => item.data?.etcDesc1 === lossCd && item.data?.comCdId !== 'CMN115.26',
			) || []
		);
	}, [context.watch('lossDescCd'), commonCodes?.CMN115]);

	const currentSalaryOption = useMemo(
		() =>
			(insuranceSalaryFeeInfo?.targetSalaryFees || [])?.map((item) => ({
				label: item.serviceType,
				value: item.serviceType,
				data: item.salaryFees,
			})),
		[insuranceSalaryFeeInfo?.targetSalaryFees],
	);

	const currentSalaryDetailOption = (index: number) =>
		formData.currentMonth[index]?.serviceType?.data?.map((item: SalaryFee) => ({
			label: `${item.salaryFeeNm}(${item.salaryFeeValue.toLocaleString()}원)`,
			value: item.salaryFeeNm,
			data: item,
		})) || [];

	const handleRemoveItem = (index: number) => () => {
		if (context.getValues('currentMonth').length > 1) {
			removeCurrentMonth(index);
		}
	};

	const handleAddItem = () => {
		appendCurrentMonth({
			serviceType: undefined,
			salaryFee: undefined,
			count: '',
			hour: '',
		});
	};

	const handleChangeDate =
		(
			keys: ('accidentInsurance' | 'pensionInsurance' | 'healthInsurance' | 'employInsurance')[],
			onChange: (value: string) => void,
		) =>
		(value: Date) => {
			console.log(keys);
			keys.forEach((key) => {
				const dateString = dayjs(value).format('YYYY-MM-DD hh:mm:ss');
				if (
					!context.getValues(`${key}RequestDate` as keyof InsuranceLossFormType) &&
					insurancePriorInfo?.[`${key}PosbYn` as keyof typeof insurancePriorInfo] &&
					context
						.getValues('insuranceType')
						.find((item) => item.value === insuranceTypeMap[key as keyof typeof insuranceTypeMap])
				) {
					context.setValue(`${key}RequestDate` as keyof InsuranceLossFormType, dateString);
				}

				onChange(dateString);
			});
		};

	const currentTotalSalary = useCallback(() => {
		let totalFee = 0;
		const currentMonth = context.getValues('currentMonth') || [];

		totalFee = currentMonth.reduce((acc, item) => {
			const salaryFee = item?.salaryFee as unknown as CheckOption<SalaryFee>;
			const salaryFeeValue = salaryFee?.data?.salaryFeeValue || 0;
			const count = Number(item.count);

			if (!item.count || !salaryFeeValue) return acc;

			if (salaryFee?.data?.generalCareYn) {
				return acc + count * salaryFeeValue * Number(item.hour);
			}

			return acc + count * salaryFeeValue;
		}, 0);
		return totalFee;
	}, [context.getValues('currentMonth')]);

	const informationTableItems = useMemo(() => {
		const items: InformationTableItemType[][] = [
			[
				{
					type: 'label',
					label: '구분',
					labelStyle: {
						width: '24rem',
						height: '4.1rem',
					},
				},
				{
					type: 'label',
					label: '상실 일자',
					labelStyle: {
						width: '24rem',
						height: '4.1rem',
					},
				},
			],
		];

		if (insurancePriorInfo?.employInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.EMPLOY,
											value: InsuranceType.EMPLOY,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.DatePicker
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY)}
									placeholder='상실 일자 선택'
									type='S'
									value={value ? dayjs(value).toDate() : undefined}
									onChangeValue={handleChangeDate(
										['accidentInsurance', 'healthInsurance', 'pensionInsurance'],
										onChange,
									)}
								/>
							)}
							name='employInsuranceRequestDate'
							control={context?.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		if (insurancePriorInfo?.accidentInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.ACCIDENT,
											value: InsuranceType.ACCIDENT,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.DatePicker
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT)}
									placeholder='상실 일자 선택'
									type='S'
									value={value ? dayjs(value).toDate() : undefined}
									onChangeValue={handleChangeDate(
										['employInsurance', 'healthInsurance', 'pensionInsurance'],
										onChange,
									)}
								/>
							)}
							name='accidentInsuranceRequestDate'
							control={context?.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}
		if (insurancePriorInfo?.pensionInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.PENSION,
											value: InsuranceType.PENSION,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.DatePicker
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.PENSION)}
									placeholder='상실 일자 선택'
									type='S'
									value={value ? dayjs(value).toDate() : undefined}
									onChangeValue={handleChangeDate(
										['employInsurance', 'healthInsurance', 'accidentInsurance'],
										onChange,
									)}
								/>
							)}
							name='pensionInsuranceRequestDate'
							control={context?.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		if (insurancePriorInfo?.healthInsurancePosbYn) {
			items.push([
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRCheckBoxGroup
									type='checkbox'
									value={value}
									options={[
										{
											label: InsuranceType.HEALTH,
											value: InsuranceType.HEALTH,
										},
									]}
									onChange={onChange}
								/>
							)}
							name='insuranceType'
							control={context.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
				{
					type: 'value',
					value: (
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.DatePicker
									disabled={!insuranceType?.find((item) => item.value === InsuranceType.HEALTH)}
									placeholder='상실 일자 선택'
									type='S'
									value={value ? dayjs(value).toDate() : undefined}
									onChangeValue={handleChangeDate(
										['employInsurance', 'pensionInsurance', 'accidentInsurance'],
										onChange,
									)}
								/>
							)}
							name='healthInsuranceRequestDate'
							control={context?.control}
						/>
					),
					valueStyle: {
						verticalAlign: 'middle',
						height: '4.9rem',
					},
				},
			]);
		}

		return items;
	}, [insurancePriorInfo, insuranceType, context]);

	const isInsuranceInfoEmpty = insurancePriorInfo
		? (
				[
					'accidentInsurancePosbYn',
					'employInsurancePosbYn',
					'healthInsurancePosbYn',
					'pensionInsurancePosbYn',
				] as (keyof InsurancePriorInfoDTO)[]
			).every((key) => insurancePriorInfo[key] === false)
		: false;

	useEffect(() => {
		if (insuranceSalaryFeeInfo) {
			if (insuranceSalaryFeeInfo?.targetSalaryInfo) {
				context.setValue('thisSalaryInfo', insuranceSalaryFeeInfo.targetSalaryInfo);
				if (context.getValues('currentMonth')?.length !== 0) {
					context.setValue('currentMonth', []);
				}
			} else {
				context.setValue('thisSalaryInfo', {
					totalWorkHourCnt: 0,
					paySumAmt: 0,
				});

				if (insuranceSalaryFeeInfo?.targetSalaryFees) {
					// 상실월 재무 데이터가 존재하고 2024년 1월 1일 이후 라면 입력폼 기본적으로 하나 추가
					if (context.getValues('currentMonth')?.length === 0 && isValidTargetDt) {
						handleAddItem();
					}
				}
			}
		}
	}, [insuranceSalaryFeeInfo, context.getValues('currentMonth'), isValidTargetDt]);

	useEffect(() => {
		// 모든 상실일 기준 데이터 요청에 필요한 targetDate 처리
		if (insurancePriorInfo) {
			const insuranceCount = Object.values(insurancePriorInfo).filter(Boolean).length;
			if (insuranceCount) {
				const firstItem = insuranceDates?.[0];
				const isAllRequestMonthEqual = insuranceDates.every((item) => item === firstItem);
				if (isAllRequestMonthEqual) {
					setTargetDt(firstItem);
				} else {
					Toast.error('모든 상실일이 일치해야합니다.');
					setTargetDt('');
					context.setValue('currentMonth', []);
				}
			}
		}
	}, [insurancePriorInfo, insuranceDates]);

	useEffect(() => {
		// 체크값 바뀔때 관련 일자 초기화
		const isCheckAccident = !!insuranceType?.find((item) => item.value === InsuranceType.ACCIDENT);
		const isCheckEmploy = !!insuranceType?.find((item) => item.value === InsuranceType.EMPLOY);
		const isCheckHealth = !!insuranceType?.find((item) => item.value === InsuranceType.HEALTH);
		const isCheckPension = !!insuranceType?.find((item) => item.value === InsuranceType.PENSION);

		if (!isCheckAccident) {
			context.setValue('accidentInsuranceRequestDate', '');
		}
		if (!isCheckEmploy) {
			context.setValue('employInsuranceRequestDate', '');
		}
		if (!isCheckHealth) {
			context.setValue('healthInsuranceRequestDate', '');
		}
		if (!isCheckPension) {
			context.setValue('pensionInsuranceRequestDate', '');
		}

		if (!insuranceType?.length) {
			// 모든 보험 종류 체크 해제시 상실월 급여 데이터 제거
			context.setValue('currentMonth', []);
			removeSalaryInfo();
		}
	}, [insuranceType]);

	useEffect(() => {
		// 요청 여부에 따른 요청 사할 및 파일첨부 초기화
		if (issueCareerChangeCert?.length) {
			if (!issueCareerChangeCert?.[0].value) {
				context.setValue('attachFileRequestDesc', '');
				context.setValue('careerChangeAttachFile', []);
			}
		} else {
			context.setValue('issueCareerChangeCert', [
				{
					label: '요청하지 않음',
					value: false,
				},
			]);
		}
	}, [issueCareerChangeCert]);

	// 2024년 이전 데이터는 상실월급여 입력필요없게 해줌
	useEffect(() => {
		if (!isValidTargetDt) {
			context.setValue('currentMonth', []);
		}
	}, [isValidTargetDt]);

	return (
		<>
			<Accordion.Item value='신고 내용' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>신고 내용</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel
									label='신고 선택'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									{isInsuranceInfoEmpty ? (
										<S.EmptyContainer>
											<CRText
												typography='labelB'
												color='gray60'
												text='신고 가능한 보험이 없습니다.'
											/>
										</S.EmptyContainer>
									) : (
										<InformationTable roundBorder items={informationTableItems} />
									)}
								</CRInputLabel>
								<CRInputLabel
									label='상실 사유'
									type='left-sub'
									isRequired
									showOverflow
									betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<CRInput.Selector
												disabled={isInsuranceInfoEmpty}
												onChangeValue={(e) => {
													context.setValue('lossDetailDescCd', undefined);
													onChange(e);
												}}
												currentValue={value}
												items={commonCodes?.CMN114}
												placeholder='상실 사유 선택'
											/>
										)}
										name='lossDescCd'
										control={context.control}
									/>
									{lossDetailOptions?.length > 0 && (
										<Controller
											render={({ field: { onChange, value } }) => (
												<CRInput.Selector
													onChangeValue={onChange}
													currentValue={value}
													items={lossDetailOptions}
													placeholder='구체적 상실 사유 선택'
												/>
											)}
											name='lossDetailDescCd'
											control={context.control}
										/>
									)}
								</CRInputLabel>
								{insuranceSalaryFeeInfo?.targetSalaryInfo && (
									<CRInputLabel
										label='상실월 급여'
										type='left-sub'
										isRequired
										showOverflow
										betweenGap='2.4rem'>
										<CRInput.Default
											value={String(insuranceSalaryFeeInfo?.targetSalaryInfo.paySumAmt)}
											disabled
											suffix='원'
										/>
									</CRInputLabel>
								)}
								{!insuranceSalaryFeeInfo?.targetSalaryInfo && targetDt && isValidTargetDt && (
									<CRInputLabel
										label='상실월 급여'
										type='left-sub'
										isRequired
										showOverflow
										betweenGap='2.4rem'>
										<S.ServiceContainer>
											{currentMonth?.map((item, index) => (
												<S.Container key={item.id}>
													<S.Title>
														급여 정보 {index + 1 < 10 ? `0${index + 1}` : index + 1}
														<CRButton.Default
															disabled={!index}
															type='outlined'
															size='xSmall'
															onClick={handleRemoveItem(index)}>
															삭제
														</CRButton.Default>
													</S.Title>
													<S.Content>
														<CRInputLabel label='급여 종류' isRequired betweenGap='0.8rem'>
															<Controller
																render={({ field: { onChange, value }, formState: { errors } }) => (
																	<CRInput.Selector
																		disabled={isStartOfMonth}
																		items={currentSalaryOption}
																		currentValue={value}
																		placeholder='급여 선택'
																		onChangeValue={(value) => {
																			context.setValue(`currentMonth.${index}.hour`, '');
																			context.setValue(`currentMonth.${index}.count`, '');
																			context.setValue(
																				`currentMonth.${index}.salaryFee`,
																				undefined,
																			);
																			onChange(value);
																		}}
																	/>
																)}
																name={`currentMonth.${index}.serviceType`}
																control={context.control}
															/>
															<Controller
																render={({ field: { onChange, value }, formState: { errors } }) => (
																	<CRInput.Selector
																		disabled={isStartOfMonth}
																		onChangeValue={onChange}
																		currentValue={value}
																		items={currentSalaryDetailOption(index)}
																		placeholder='급여 종류 상세 선택'
																	/>
																)}
																name={`currentMonth.${index}.salaryFee`}
																control={context.control}
															/>
														</CRInputLabel>
														{formData.currentMonth?.[index]?.salaryFee?.data.generalCareYn && (
															<CRInputLabel label='1회 근무시간' isRequired>
																<Controller
																	render={({
																		field: { onChange, value },
																		formState: { errors },
																	}) => (
																		<CRInput.Default
																			disabled={isStartOfMonth}
																			type='number'
																			value={value}
																			placeholder='1회 근무시간'
																			suffix='시간'
																			onChange={onChange}
																		/>
																	)}
																	name={`currentMonth.${index}.hour`}
																	control={context.control}
																/>
															</CRInputLabel>
														)}
														<CRInputLabel label='일정 갯수' isRequired betweenGap='0.8rem'>
															<Controller
																render={({ field: { onChange, value }, formState: { errors } }) => (
																	<CRInput.Default
																		disabled={isStartOfMonth}
																		type='number'
																		value={value}
																		placeholder='일정 갯수'
																		suffix='개'
																		onChange={onChange}
																	/>
																)}
																name={`currentMonth.${index}.count`}
																control={context.control}
															/>
														</CRInputLabel>
													</S.Content>
												</S.Container>
											))}
											<CRButton.IconButton
												disabled={isStartOfMonth}
												style={{
													margin: '1.6rem 0',
												}}
												onClick={handleAddItem}
												iconLeft={Assets.icon.add}
												palette='gray'
												type='outlined'>
												급여정보 추가
											</CRButton.IconButton>
											<CRInputLabel label='임금'>
												<CRInput.Default
													disabled
													value={currentTotalSalary()?.toLocaleString()}
													suffix='원'
												/>
											</CRInputLabel>
										</S.ServiceContainer>
									</CRInputLabel>
								)}

								{insuranceSalaryFeeInfo?.targetSalaryInfo?.totalWorkHourCnt !== undefined && (
									<CRInputLabel
										label='상실월 근로시간'
										type='left-sub'
										isRequired
										showOverflow
										betweenGap='2.4rem'>
										<CRInput.Default
											value={displayMinuteToHourMinute(
												insuranceSalaryFeeInfo?.targetSalaryInfo?.totalWorkHourCnt,
											)}
											disabled
										/>
									</CRInputLabel>
								)}
								<CRInputLabel label='요청 사항' type='left-sub' showOverflow betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<CRInput.TextArea
												disabled={isInsuranceInfoEmpty}
												numberOfLines={4}
												onChange={onChange}
												value={value}
												placeholder='요청 사항 입력'
												fixedHeight
											/>
										)}
										name='requestDesc'
										control={context.control}
									/>
								</CRInputLabel>
								<CRInputLabel label='파일 첨부' type='left-sub' showOverflow betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<CRInput.FileUploader
												disabled={isInsuranceInfoEmpty}
												type='multiple'
												onChange={onChange}
												files={value}
												placeholder='파일을 끌어오거나 업로드'
											/>
										)}
										name='attachFile'
										control={context.control}
									/>
								</CRInputLabel>
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
			<Accordion.Item value='이직확인서 요청' asChild>
				<TaskAccordion.Item>
					<Accordion.Header asChild>
						<Accordion.Trigger asChild>
							<TaskAccordion.Trigger>이직확인서 요청</TaskAccordion.Trigger>
						</Accordion.Trigger>
					</Accordion.Header>
					<Accordion.Content asChild>
						<TaskAccordion.Content>
							<S.FormContainer>
								<CRInputLabel label='요청 여부' isRequired type='left-sub' betweenGap='2.4rem'>
									<Controller
										render={({
											field: { onChange, onBlur, value, ref },
											formState: { errors },
										}) => (
											<CRCheckBoxGroup
												disabled={isInsuranceInfoEmpty}
												style={{
													width: 'auto',
												}}
												checkType='single'
												type='radio'
												onChange={onChange}
												appearanceType='button'
												gap={0.8}
												value={value}
												options={[
													{
														label: '요청하지 않음',
														value: false,
													},
													{
														label: '요청함',
														value: true,
													},
												]}
											/>
										)}
										name='issueCareerChangeCert'
										control={context.control}
									/>
								</CRInputLabel>

								<CRInputLabel
									isRequired
									label='요청 사항'
									type='left-sub'
									showOverflow
									betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<CRInput.TextArea
												disabled={!issueCareerChangeCert?.[0]?.value}
												numberOfLines={4}
												onChange={onChange}
												value={value}
												placeholder='요청 사항 입력'
												fixedHeight
											/>
										)}
										name='attachFileRequestDesc'
										control={context.control}
									/>
								</CRInputLabel>
								<CRInputLabel label='파일 첨부' type='left-sub' showOverflow betweenGap='2.4rem'>
									<Controller
										render={({ field: { onChange, value } }) => (
											<CRInput.FileUploader
												disabled={!issueCareerChangeCert?.[0]?.value}
												type='multiple'
												onChange={onChange}
												files={value}
												placeholder='파일을 끌어오거나 업로드'
											/>
										)}
										name='careerChangeAttachFile'
										control={context.control}
									/>
								</CRInputLabel>
							</S.FormContainer>
						</TaskAccordion.Content>
					</Accordion.Content>
				</TaskAccordion.Item>
			</Accordion.Item>
		</>
	);
}
