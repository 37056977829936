import React, { useLayoutEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRSyncMenu from 'components/base/CRSyncMenu';
import CRTab from 'components/base/CRTab';
import { TabProps } from 'components/base/CRTab/DetailTab/type';
import EmployReportAutomatonRequestDialog from 'components/domain/dialog/EmployReportAutomatonRequestDialog';
import useDialog from 'lib/hook/util/useDialog';

import * as S from './styles';

export default function EmployReportAutomationPage() {
	const { pathname } = useLocation();
	const [isProgress, setIsProgress] = useState(false);
	const employReportAutomationTab = RouterPath.employReportAutomationToTab() as {
		employment: { key: string; label: string };
		resign: { key: string; label: string };
	};
	const { showDialog } = useDialog();

	const [currentTab, setCurrentTab] = useState<TabProps>();
	const navigate = useNavigate();
	const handleChangeTab = (item: TabProps) => {
		setCurrentTab(item);
		navigate(item.key);
	};

	const handleChageProgress = () => {
		setIsProgress(true);
	};

	const handleClickRequestButton = () => {
		showDialog(() => (
			<EmployReportAutomatonRequestDialog handleChageProgress={handleChageProgress} />
		));
	};

	const handleClickSyncHistory = () => {
		// dialog.showDialog(() => );
	};

	const tabRightButtonRenderer = () => (
		<S.TabRightButtonContainer>
			<CRSyncMenu
				title={isProgress ? '자동화 진행중' : '인력신고 자동화'}
				isProgress={isProgress}
				onClickSync={handleClickRequestButton}
				onClickSyncHistory={handleClickSyncHistory}
				leftIcon={Assets.icon.automation}
				showHistoryButton={false}
			/>
		</S.TabRightButtonContainer>
	);

	useLayoutEffect(() => {
		const currentTab = Object.values(employReportAutomationTab).find(
			(item) => item.key === pathname,
		);

		if (!currentTab) navigate(employReportAutomationTab.employment.key);
		setCurrentTab(currentTab);
	}, [pathname]);

	return (
		<CRTab.Default
			breadCrumb='인력신고 자동화'
			defaultActiveKey={currentTab?.key}
			onChange={handleChangeTab}
			renderRightButton={tabRightButtonRenderer()}
			items={[
				{
					label: employReportAutomationTab.employment.label,
					key: employReportAutomationTab.employment.key,
					children: <Outlet />,
				},
				{
					label: employReportAutomationTab.resign.label,
					key: employReportAutomationTab.resign.key,
					children: <Outlet />,
				},
			]}
		/>
	);
}
