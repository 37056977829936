import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import CRSyncMenu from 'components/base/CRSyncMenu';
import CRTab from 'components/base/CRTab';
import { TabProps } from 'components/base/CRTab/DetailTab/type';
import { Toast } from 'components/base/CRToast';
import ScheduleSyncHistoryDialog from 'components/domain/dialog/ScheduleSyncHistoryDialog';
import { SyncDialog } from 'components/domain/dialog/SyncDialog';
import { displayDateToYYYYMM } from 'lib';
import {
	useAutomationLastSync,
	useMyAccountInfo,
	useScheduleAutomation,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { ResponseCode } from 'types/api';

export default function MonthSchedulePage() {
	const hasScheduleSyncFunc = useHasFunc(['schedule:sync_schedule']);
	const monthScheduleTab = RouterPath.monthScheduleTab() as {
		recipient: { key: string; label: string };
		employee: { key: string; label: string };
		gasan: { key: string; label: string };
		[key: string]: { key: string; label: string };
	};

	const [currentTab, setCurrentTab] = useState<TabProps>();
	const navigate = useNavigate();
	const handleChangeTab = (item: TabProps) => {
		setCurrentTab(item);
		navigate(item.key);
	};

	const tt = useLocation();
	useLayoutEffect(() => {
		const paths = tt.pathname.split('/');
		const currentPath = paths[paths.length - 1];
		if (currentPath === 'month-schedule') {
			navigate(monthScheduleTab.recipient.key, {
				replace: true,
			});
		} else {
			const tabInfo = monthScheduleTab[paths[3]];
			setCurrentTab(tabInfo);
		}
	}, []);

	const dialog = useDialog();
	const [isTempSync, setIsTempSync] = useState(false);
	const { data: myAccountInfo } = useMyAccountInfo();

	const centerId = myAccountInfo?.centerId;

	const { data: lastSyncData } = useAutomationLastSync({
		centerId,
		uploadTypeCds: ['CMN107.PLAN'],
	});

	const { mutate: scheduleAutomation, isLoading: scheduleAutomationLoading } =
		useScheduleAutomation((client, returnData) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				setIsTempSync(true);
				Toast.success(
					'일정 계획 동기화 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
				);
			} else {
				Toast.error('일정 계획 동기화 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const handleScheduleSync = async (data: { yearMonth: Date }) => {
		if (!centerId) {
			return;
		}

		const YYYYMM = displayDateToYYYYMM(data.yearMonth);
		scheduleAutomation({ centerId: `${centerId}`, yyyymm: YYYYMM });
		dialog.hideDialog();
	};

	const handleClickSyncButton = () => {
		dialog.showDialog(() => <SyncDialog title='일정 내역' onSync={handleScheduleSync} />);
	};

	const handleClickSyncHistory = () => {
		dialog.showDialog(() => <ScheduleSyncHistoryDialog />);
	};

	const isProgress = useMemo(() => isTempSync || scheduleAutomationLoading, [isTempSync]);
	const isGasanCenter = true;

	useEffect(() => {
		if (
			lastSyncData?.uploadStateCd === 'CMN108.REQUEST' ||
			lastSyncData?.uploadStateCd === 'CMN108.PROGRESS'
		) {
			setIsTempSync(true);
		} else {
			setIsTempSync(false);
		}
	}, [lastSyncData]);

	return (
		<CRTab.Default
			breadCrumb='월별 일정 관리'
			defaultActiveKey={currentTab?.key}
			renderRightButton={
				!(currentTab?.key === monthScheduleTab.gasan.key) ? (
					<CRSyncMenu
						syncDisabled={!hasScheduleSyncFunc}
						title={isProgress ? '일정 동기화 중' : '일정 동기화'}
						isProgress={isProgress}
						onClickSync={handleClickSyncButton}
						onClickSyncHistory={handleClickSyncHistory}
					/>
				) : undefined
			}
			onChange={handleChangeTab}
			items={[
				{
					label: monthScheduleTab.recipient.label,
					key: monthScheduleTab.recipient.key,
					children: <Outlet />,
				},
				{
					label: monthScheduleTab.employee.label,
					key: monthScheduleTab.employee.key,
					children: <Outlet />,
				},
				isGasanCenter && {
					label: monthScheduleTab.gasan.label,
					key: monthScheduleTab.gasan.key,
					children: <Outlet />,
				},
			]}
		/>
	);
}
