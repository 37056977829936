import { RecipientContractManagemetTableRenderKey } from 'pages/dashboard/NewContractManagementPage/constant';
import { CRStatusConfig, CRTableHeadConfig, CRTableMultiHeadConfig } from 'types/view/base';

export const RECIPIENT_CONTRACT_TABLE_HEADER_CONFIG: Array<
	CRTableMultiHeadConfig & {
		children?: Array<CRTableHeadConfig & { sortKey: RecipientContractManagemetTableRenderKey }>;
	}
> = [
	{
		label: '기본정보',
		sortKey: 'basicInfo',
		children: [
			{ label: '상태', sortKey: 'totalWorkCompletionStatus', width: '8.8rem' },
			{ label: '소속센터', sortKey: 'center', width: '8.8rem' },
			{ label: '사회복지사', sortKey: 'socialWorker', width: '8.8rem' },
			{ label: '수급자명', sortKey: 'recipientName', width: '8.8rem' },
			{ label: '장기요양인정번호', sortKey: 'longTermCareNumber', width: '12rem' },
			{ label: '계약유형', sortKey: 'contractType', width: '8rem' },
			{ label: '계약일', sortKey: 'contractDate', width: '14.4rem' },
			{ label: '급여종류', sortKey: 'longtermCareBenefitType', width: '8rem' },
			{ label: '급여시작일', sortKey: 'longtermCareStartDate', width: '10.4rem' },
			{ label: '급여종료일', sortKey: 'longtermCareEndDate', width: '10.4rem' },
			{ label: '직원명', sortKey: 'employeeName', width: '10.4rem' },
		],
	},
	{
		label: '수급자 계약서',
		sortKey: 'contract',
		children: [
			{
				label: '완료 여부',
				sortKey: 'recipientContractCompletionStatus',
				width: '12rem',
				textAlign: 'center',
			},
			{
				label: '발송상태',
				sortKey: 'recipientContractSendStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '유효성',
				sortKey: 'recipientContractDocumentVerification',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '급여제공계획서',
		sortKey: 'benefitPlan',
		children: [
			{
				label: '완료 여부',
				sortKey: 'longtermCareBenefitPlanCompletionStatus',
				width: '12rem',
				textAlign: 'center',
			},
			{
				label: '유효성',
				sortKey: 'longtermCareBenefitPlanDocumentVerification',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '발송상태',
				sortKey: 'longtermCareBenefitPlanDocumnetSendStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '종합의견 검수',
				sortKey: 'longtermCareBenefitPlanOverallOpinionReview',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '공단 통보',
				sortKey: 'longtermCareBenefitPlanNotificationToCorporation',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '변경사유서',
				sortKey: 'longtermCareBenefitPlanReasonForChange',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '통합사정',
		sortKey: 'integratedAssessment',
		children: [
			{
				label: '완료 여부',
				sortKey: 'integratedAssessmentCompletionStatus',
				width: '12rem',
				textAlign: 'center',
			},
			{
				label: '작성 여부',
				sortKey: 'integratedAssessmentWritingStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '낙상위험도',
				sortKey: 'fallRiskAssessmentCompletionStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '욕창위험도',
				sortKey: 'pressureInjuryRiskAssessmentCompletionStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '인지기능',
				sortKey: 'cognitiveFunctionAssessmentCompletionStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '욕구사정',
				sortKey: 'needsAssessmentCompletionStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '직원교체상담일지',
		sortKey: 'employeeChangeConsultation',
		children: [
			{
				label: '작성 여부',
				sortKey: 'employeeChangeConsultationWritingStatus',
				width: '12rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '입소이용신청',
		sortKey: 'admissionApplication',
		children: [
			{
				label: '완료 여부',
				sortKey: 'admissionApplicationFormCompletionStatus',
				width: '12rem',
				textAlign: 'center',
			},
			{
				label: '대상',
				sortKey: 'isTargetOfadmissionApplication',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '서류 작성',
				sortKey: 'isCompletedAdmissionApplicationForm',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '발송상태',
				sortKey: 'admissionApplicationFormSendStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '발송/승인',
				sortKey: 'admissionApplicationSendApprovalStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '공단일정등록',
		sortKey: 'corporationScheduleRegistration',
		children: [
			{
				label: '등록 여부',
				sortKey: 'registeredLongtermCareBenefitScheduleToCorporation',
				width: '12rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '개인별 이용계획서',
		sortKey: 'individualLongTermCarePlan',
		children: [
			{
				label: '열람신청',
				sortKey: 'appliedIndividualLongtermCarePlan',
				width: '12rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '태그',
		sortKey: 'tag',
		children: [
			{ label: '완료 여부', sortKey: 'tagCompletionStatus', width: '12rem', textAlign: 'center' },
			{
				label: '사회복지사요청',
				sortKey: 'tagSocialWorkerRequest',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '부착 구분',
				sortKey: 'tagAttachmentClassification',
				width: '10.4rem',
				textAlign: 'center',
			},
			{
				label: '신청/부착',
				sortKey: 'tagAttachmentStatus',
				width: '10.4rem',
				textAlign: 'center',
			},
		],
	},
	{
		label: '참고',
		sortKey: 'reference',
		children: [
			{
				label: '계약 특이사항',
				sortKey: 'referenceContractNotes',
				width: '24rem',
				textAlign: 'center',
			},
			{ label: '메모', sortKey: 'adminMemo', width: '24rem', textAlign: 'center' },
		],
	},
];

export const COMPLETION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '미완료',
		label: '미완료',
		color: 'yellow',
	},
];

export const CONTRACT_COMPLETION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '수정필요',
		label: '수정필요',
		color: 'red',
	},
	{
		key: '검수대기',
		label: '검수대기',
		color: 'yellow',
	},
];

export const EMPLOYEE_CHANGE_CONSULTATION_WRITING_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '확인필요',
		label: '확인필요',
		value: 'CMN214.10',
		color: 'yellow',
	},
	{
		key: '해당없음',
		label: '해당없음',
		value: 'CMN214.20',
		color: 'green',
	},
	{
		key: '작성필요',
		label: '작성필요',
		value: 'CMN214.30',
		color: 'yellow',
	},
	{
		key: '작성완료',
		label: '작성완료',
		value: 'CMN214.40',
		color: 'green',
	},
];

export const RECIPIENT_SCHEDULE_REGISTRATION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미등록',
		label: '미등록',
		value: 'CMN213.10',
		color: 'yellow',
	},
	{
		key: '미흡',
		label: '미흡',
		value: 'CMN213.20',
		color: 'red',
	},
	{
		key: '등록완료',
		label: '등록완료',
		value: 'CMN213.30',
		color: 'green',
	},
];

export const LONGTERM_CARE_BENEFIT_PLAN_OVERALL_OPINION_REVIEW_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '검수대기',
		label: '검수대기',
		value: 'CMN211.10',
		color: 'red',
	},
	{
		key: '수정필요',
		label: '수정필요',
		value: 'CMN211.20',
		color: 'red',
	},
	{
		key: '검수완료',
		label: '검수완료',
		value: 'CMN211.30',
		color: 'green',
	},
];

export const LONGTERM_CARE_BENEFIT_PLAN_CHANGE_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '확인필요',
		label: '확인필요',
		value: 'CMN214.10',
		color: 'red',
	},
	{
		key: '해당없음',
		label: '해당없음',
		value: 'CMN214.20',
		color: 'green',
	},
	{
		key: '작성필요',
		label: '작성필요',
		value: 'CMN214.30',
		color: 'red',
	},
	{
		key: '작성완료',
		label: '작성완료',
		value: 'CMN214.40',
		color: 'green',
	},
];

export const RECIPIENT_CONTRACT_SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '작성 대기',
		label: '작성 대기',
		value: 'CMN171.05',
		color: 'red',
	},
	{
		key: '서명 대기',
		label: '서명 대기',
		value: 'CMN171.10',
		color: 'red',
	},
	{
		key: '검수 대기',
		label: '검수 대기',
		value: 'CMN171.20',
		color: 'green',
	},
	{
		key: '완료',
		label: '완료',
		value: 'CMN171.30',
		color: 'green',
	},
	{
		key: '서명 취소',
		label: '서명 취소',
		value: 'CMN171.40',
		color: 'red',
	},
	{
		key: '기간 만료',
		label: '기간 만료',
		value: 'CMN171.50',
		color: 'red',
	},
	{
		key: '상태 오류',
		label: '상태 오류',
		value: 'CMN171.99',
		color: 'red',
	},
];

export const RECIPIENT_VALIDATION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '적합',
		label: '적합',
		color: 'green',
	},
	{
		key: '부적합',
		label: '부적합',
		color: 'red',
	},
];

export const WRITING_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '작성',
		label: '작성',
		color: 'green',
	},
	{
		key: '미작성',
		label: '미작성',
		color: 'red',
	},
];

export const EVALUATION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '정상',
		label: '정상',
		color: 'green',
	},
	{
		key: '확인필요',
		label: '확인필요',
		color: 'red',
	},
];

export const NOTI_TO_CORP_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '미통보',
		label: '미통보',
		color: 'red',
	},
];

export const APPLICATION_REQUIRED_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '신청필요',
		label: '신청필요',
		color: 'red',
	},
	{
		key: '해당없음',
		label: '해당없음',
		color: 'green',
	},
];

export const ADMISSION_APPLICATION_SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미발송',
		label: '미발송',
		value: 'CMN212.10',
		color: 'red',
	},
	{
		key: '승인대기',
		label: '승인대기',
		value: 'CMN212.20',
		color: 'green',
	},
	{
		key: '승인거부',
		label: '승인거부',
		value: 'CMN212.30',
		color: 'red',
	},
	{
		key: '승인완료',
		label: '승인완료',
		value: 'CMN212.40',
		color: 'green',
	},
];

export const TAG_ATTACHMENT_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '미신청',
		label: '미신청',
		value: 'CMN215.10',
		color: 'red',
	},
	{
		key: '신청완료',
		label: '신청완료',
		value: 'CMN215.20',
		color: 'yellow',
	},
	{
		key: '수령가능',
		label: '수령가능',
		value: 'CMN215.30',
		color: 'blue',
	},
	{
		key: '부착완료',
		label: '부착완료',
		value: 'CMN215.40',
		color: 'green',
	},
];

export const TAG_APPLICATION_REQUEST_CONFIG: CRStatusConfig[] = [
	{
		key: '기존태그 있음',
		label: '기존태그 있음',
		value: 'CMN219.10',
		color: 'green',
	},
	{
		key: '신청필요',
		label: '신청필요',
		value: 'CMN219.20',
		color: 'red',
	},
	{
		key: '신청필요',
		label: '신청필요',
		value: 'CMN219.30',
		color: 'red',
	},
	{
		key: '신청완료',
		label: '신청완료',
		value: 'CMN219.40',
		color: 'green',
	},
	{
		key: '신청완료',
		label: '신청완료',
		value: 'CMN219.50',
		color: 'green',
	},
];

export const TAG_ATTACHMENT_CLASSIFICATION_CONFIG: CRStatusConfig[] = [
	{
		key: '-',
		label: '-',
		value: 'CMN219.10',
		color: 'green',
	},
	{
		key: '기관부착',
		label: '기관부착',
		value: 'CMN219.20',
		color: 'green',
	},
	{
		key: '공단부착',
		label: '공단부착',
		value: 'CMN219.30',
		color: 'green',
	},
	{
		key: '기관부착',
		label: '기관부착',
		value: 'CMN219.40',
		color: 'green',
	},
	{
		key: '공단부착',
		label: '공단부착',
		value: 'CMN219.50',
		color: 'green',
	},
];
