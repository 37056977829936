import React, { useMemo, useState } from 'react';
import { Controller, UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';

import dayjs from 'dayjs';
import { v4 } from 'uuid';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInput from 'components/base/CRInput';
import CRSpinner from 'components/base/CRSpinner';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { CheckOption } from 'components/base/Selections/type';
import CashReceiptDialog from 'components/domain/dialog/CashReceiptDialog';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import {
	CenterAccountRecordFormData,
	CenterAccountRecordFormDetail,
} from 'components/domain/form/CenterAccountRecordDetailFormNew';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useBurdenPayHistoryDetail,
	useCancelCashReceipt,
	useCommonCodes,
	useIssueCashReceipt,
	useMonthlyBurdenChargeDetailMutation,
	useMonthlyBurdenChargesMutation,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { endpoint } from 'lib/service/Api/endpoint';
import { displayCommaToNumber, displayShortDate } from 'lib/util/display';
import { GetCenterAccountRecordTargetData } from 'types/api/centerAccount';
import { BurdenChargeDTO } from 'types/dto/ownExpense';
import { CashReceiptForm } from 'types/view/ownExpense';

import * as S from './styles';

interface Props {
	item: CenterAccountRecordFormDetail;
	index: number;
	recipients?: GetCenterAccountRecordTargetData[];
	selectedRecommendedRecipient?: GetCenterAccountRecordTargetData;
	formReturn: UseFormReturn<CenterAccountRecordFormData>;
	fieldArray: UseFieldArrayReturn<CenterAccountRecordFormData, 'centerAccountRecordDetails'>;
	disabledInputs?: ['금액' | '유형' | '메모'];
	lastAmt?: number;
}

function CenterAccountRecordDetailFormRow({
	item,
	index,
	formReturn,
	recipients,
	selectedRecommendedRecipient,
	disabledInputs,
	lastAmt,
	fieldArray,
}: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const hasCreateCashReceiptFunc = useHasFunc(['own_expense:create_cash_receipt']);
	const hasDeleteCashReceiptFunc = useHasFunc(['own_expense:delete_cash_receipt']);
	const hasUpdateBankAccountDetailFunc = useHasFunc(['center:update_bank_account_detail']);
	const { control, watch } = formReturn;
	const [selectedDate, setSelectedDate] = useState(dayjs());

	const {
		mutateAsync: monthlyBurdenChargesMutation,
		isLoading: monthlyBurdenChargesMutationLoading,
	} = useMonthlyBurdenChargesMutation();

	const {
		mutateAsync: monthlyBurdenChargeDetailMutation,
		isLoading: monthlyBurdenChargeDetailMutationLoading,
	} = useMonthlyBurdenChargeDetailMutation();

	const issueCashReceipt = useIssueCashReceipt((client) => {
		client.invalidateQueries([
			endpoint.getBurdenPayHistoryDetail.key,
			{
				centerId: item.centerId,
				recipientId: fieldArray.fields[index].recordTargetDivValue?.value.id,
				targetYear: Number(dayjs(selectedDate).format('YYYY')),
			},
		]);

		client.invalidateQueries([
			endpoint.getBurdenPayHistoryDetail.key,
			{
				centerId: item.centerId,
				recipientId: fieldArray.fields[index].recordTargetDivValue?.value.id,
				targetYear: Number(dayjs(fieldArray.fields[index].recordTargetYm).format('YYYY')),
			},
		]);
	});

	const cancelCashReceipt = useCancelCashReceipt((client) => {
		client.invalidateQueries([
			endpoint.getBurdenPayHistoryDetail.key,
			{
				centerId: item.centerId,
				recipientId: fieldArray.fields[index].recordTargetDivValue?.value.id,
				targetYear: Number(dayjs(selectedDate).format('YYYY')),
			},
		]);
	});

	const { data: burdnePayHistoryDetail } = useBurdenPayHistoryDetail({
		centerId: item.centerId,
		recipientId: fieldArray.fields[index].recordTargetDivValue?.value.id,
		targetYear: Number(dayjs(selectedDate).format('YYYY')),
	});

	const { data: commonCodes = {} } = useCommonCodes(
		{
			comCdGroupNms: ['CMN035', 'CMN177'],
		},
		commonCodeAdapter,
	);

	const { remove, update, append } = fieldArray;

	const recordKindNm = watch('recordKindNm');

	const isDeposit = recordKindNm === '입금';

	const recordTypeOptions = useMemo(
		() => commonCodes.CMN035?.filter((val) => val.data?.etcDesc1 === recordKindNm) || [],
		[commonCodes.CMN035],
	);
	const secondRecordTypeOptions = useMemo(() => commonCodes.CMN177 || [], [commonCodes.CMN177]);

	const recipientOptions = useMemo(
		() =>
			recipients?.map((target) => ({
				value: {
					name: target.recordTargetNm,
					duty: '수급자',
					birth: target.recordTargetBirthDt,
					id: target.recordTargetId,
					cd: 'CMN118.20',
					guardian: target.recordTargetGuardianNm,
					manager: target.recordTargetManagerNm,
				},
				label: target.recordTargetNm,
				name: target.recordTargetNm,
			})) || [],
		[recipients],
	);

	const handleClickPrevMonth = () => {
		setSelectedDate(selectedDate.subtract(1, 'year'));
	};

	const handleClickNextMonth = () => {
		setSelectedDate(selectedDate.add(1, 'year'));
	};

	const deleteRecordDetail = (targetIdx: number) => {
		const isSavedData = !!fieldArray.fields[index]?.centerAccountRecordDetailId;
		if (!isSavedData) {
			remove(targetIdx);
		} else {
			update(targetIdx, {
				...fieldArray.fields[index],
				rowType: 'DELETE',
			});
		}
	};

	const handleConfirmIssueCashReceipt =
		(data: BurdenChargeDTO) => async (form: CashReceiptForm) => {
			if (!item.centerId) return;

			await issueCashReceipt.mutateAsync({
				centerId: item.centerId,
				bizNo: data.bizNo,
				targetDivCd: 'CMN118.20',
				targetId: data.recipientId,
				targetYm: data.burdenAmtChargeYm,
				cashReceiptRequestEntityIdValue: data.burdenAmtChargeId,
				cashReceiptIssuerNm: form.name,
				cashReceiptIssueMethodCd: form.issuerNo.type.value,
				cashReceiptIssuerNo: form.issuerNo.number,
				cashReceiptTargetDt: data.burdenAmtChargeDt,
				cashReceiptRequestAmt: Number(data.burdenAmt),
				cashReceiptRequestKindCd: 'CMN166.10',
				remark: `${data.burdenAmtChargeYm.substring(0, 4)}-${data.burdenAmtChargeYm.substring(
					4,
					6,
				)} ${data.centerNm} ${data.recipientNm} ${(data.burdenAmt ?? 0).toLocaleString()}`,
			});
		};

	const handleClickIssueCashReceipt = async (data: BurdenChargeDTO) => {
		showDialog(({ hideDialog }) => (
			<CashReceiptDialog
				title={`${data.burdenAmtChargeYm.substring(0, 4)}년 ${data.burdenAmtChargeYm.substring(
					4,
					6,
				)}월 현금영수증 발행`}
				item={data}
				onSubmit={handleConfirmIssueCashReceipt(data)}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleConfirmCancelCashReceipt = async (data: BurdenChargeDTO) => {
		await cancelCashReceipt.mutateAsync({
			cashReceiptId: data.cashReceipt.cashReceiptId,
			bizNo: data.bizNo,
			targetYm: data.cashReceipt.targetYm,
		});
	};

	const handleClickCancelCashReceipt = async (data: BurdenChargeDTO) => {
		showDialog(({ hideDialog }) => (
			<CashReceiptDialog
				title={`${data.burdenAmtChargeYm.substring(0, 4)}년 ${data.burdenAmtChargeYm.substring(
					4,
					6,
				)}월 현금영수증 발행`}
				item={data}
				hideDialog={hideDialog}
				onSubmit={() => handleConfirmCancelCashReceipt(data)}
				isCancel
			/>
		));
	};

	const renderRecordTypeSelector = (index: number) => (
		<Controller
			name={`centerAccountRecordDetails.${index}.recordTypeValue`}
			control={control}
			render={({ field: { value, onChange, ...rest } }) => (
				<CRInput.Selector
					{...rest}
					type='small'
					currentValue={fieldArray.fields[index].recordTypeValue}
					onChangeValue={(e) => {
						const renderAbleRecordType = [
							'CMN035.1000',
							'CMN035.2010',
							'CMN035.2150',
							'CMN035.2160',
						];
						const shouldSelectRecipient = renderAbleRecordType.includes(e.value);
						if (shouldSelectRecipient) {
							update(index, {
								...fieldArray.fields[index],
								recordTypeValue: e,
								recordDetailValue: undefined as unknown as undefined,
								recordTargetDivValue: recipientOptions.find(
									(item) => item.value.id === selectedRecommendedRecipient?.recordTargetId,
								),
							});
						} else {
							update(index, {
								...fieldArray.fields[index],
								recordTypeValue: e,
								recordDetailValue: undefined as unknown as undefined,
								recordTargetDivValue: undefined as unknown as undefined,
								recordTargetYm: undefined as unknown as undefined,
								depositAmt: '0',
								withdrawAmt: '0',
							});
						}
					}}
					items={recordTypeOptions}
					placeholder='유형 선택'
					disabled={!hasUpdateBankAccountDetailFunc}
				/>
			)}
		/>
	);

	const renderSecondSelector = (index: number) => {
		const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010', 'CMN035.2150', 'CMN035.2160'];
		const recordTypeValue = fieldArray.fields[index].recordTypeValue?.value;
		const isRenderable = renderAbleRecordType.includes(recordTypeValue);

		return (
			<Controller
				name={`centerAccountRecordDetails.${index}.recordDetailValue`}
				control={control}
				render={({ field: { value, onChange, ...rest } }) => (
					<CRInput.Selector
						{...rest}
						type='small'
						currentValue={fieldArray.fields[index].recordDetailValue}
						onChangeValue={(e) => {
							update(index, {
								...fieldArray.fields[index],
								recordDetailValue: e,
							});
						}}
						items={secondRecordTypeOptions.filter((code) =>
							code.data?.etcDesc1.includes(recordTypeValue),
						)}
						placeholder='급여'
						disabled={!hasUpdateBankAccountDetailFunc || !isRenderable}
					/>
				)}
			/>
		);
	};

	const renderCashReceiptSelector = (index: number) => (
		<CRCheckBox checked={burdnePayHistoryDetail?.cashReceiptIssueYn} />
	);

	const renderRecipientSelector = (index: number) => {
		const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010', 'CMN035.2150', 'CMN035.2160'];
		const recordTypeValue = fieldArray.fields[index].recordTypeValue?.value;
		const isRenderable = renderAbleRecordType.includes(recordTypeValue);

		return (
			<Controller
				name={`centerAccountRecordDetails.${index}.recordTargetDivValue`}
				control={control}
				render={({ field: { value, onChange, ...rest } }) => (
					<CRInput.TableSearchSelector
						{...rest}
						currentValue={
							fieldArray.fields[index].recordTargetDivValue as CheckOption<{
								name: string;
								duty: string;
								birth: string;
								id: number;
								cd: string;
								guardian: string;
								manager: string;
							}>
						}
						onChange={(e) => {
							update(index, {
								...fieldArray.fields[index],
								recordTargetDivValue: e,
							});
						}}
						items={recipientOptions}
						searchKey={['guardian']}
						placeholder='수급자 선택'
						disabled={!hasUpdateBankAccountDetailFunc || !isRenderable}
					/>
				)}
			/>
		);
	};

	const renderTargetYmSelector = (index: number) => {
		const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010', 'CMN035.2150', 'CMN035.2160'];
		const recordTypeValue = fieldArray.fields[index].recordTypeValue?.value;
		const isRenderable = renderAbleRecordType.includes(recordTypeValue);
		const filter = secondRecordTypeOptions.filter((code) =>
			code.data?.etcDesc1.includes(recordTypeValue),
		);
		const serviceMap = {
			'CMN006.10': 'CMN177.10',
			'CMN006.20': 'CMN177.11',
			'CMN006.30': 'CMN177.12',
		};

		return (
			<Controller
				render={({ field: { onChange, value } }) => (
					<CRInput.YearMonthPicker
						type='samll'
						disabled={!hasUpdateBankAccountDetailFunc || !isRenderable}
						placeholder='해당월'
						currentValue={
							fieldArray.fields[index].recordTargetYm
								? dayjs(fieldArray.fields[index].recordTargetYm).toDate()
								: undefined
						}
						onChangeValue={async (e) => {
							onChange(e);
							const monthlyBurdenCharges = await monthlyBurdenChargesMutation({
								burdenAmtChargeYm: dayjs(e).format('YYYYMM'),
								centerId: item.centerId,
								page: 1,
								size: 50,
								serviceTypeCds: [],
								managerIds: [],
								keyword: fieldArray.fields[index].recordTargetDivValue?.label,
							});

							const burdenAmtChargeId = monthlyBurdenCharges?.content?.find(
								(item) =>
									item.recipientId === fieldArray.fields[index].recordTargetDivValue?.value.id,
							)?.burdenAmtChargeId;

							if (!burdenAmtChargeId) {
								update(index, {
									...fieldArray.fields[index],
									recordDetailValue: undefined,
									recordTargetYm: dayjs(e).format('YYYYMM'),
									depositAmt: '0',
									withdrawAmt: '0',
								});
								return;
							}

							const monthlyBurdenChargeDetail = await monthlyBurdenChargeDetailMutation({
								burdenAmtChargeId,
							});

							if (monthlyBurdenChargeDetail) {
								(monthlyBurdenChargeDetail?.burdenAmtChargeDetails ?? []).forEach(
									(item, targetIndex) => {
										const newData = {
											...fieldArray.fields[index],
											recordDetailValue: filter.find(
												(code) =>
													code.data?.comCdId ===
													serviceMap[item.serviceTypeCd as keyof typeof serviceMap],
											),
											recordTargetYm: dayjs(e).format('YYYYMM'),
											depositAmt: (isDeposit ? item.burdenAmt : 0).toLocaleString(),
											withdrawAmt: (isDeposit ? 0 : item.burdenAmt).toLocaleString(),
										};
										if (targetIndex) {
											append({
												...newData,
												tempId: v4(),
												centerAccountRecordId: undefined,
												centerAccountRecordDetailId: undefined,
											});
										} else {
											update(index, newData);
										}
									},
								);
							}
						}}
					/>
				)}
				name={`centerAccountRecordDetails.${index}.recordTargetYm`}
				control={control}
			/>
		);
	};

	const renderDescription = (index: number) => (
		<Controller
			name={`centerAccountRecordDetails.${index}.recordDesc`}
			control={control}
			render={({ field: { value, onChange, ...rest } }) => (
				<CRInput.TableInput
					{...rest}
					placeholder='메모 입력'
					value={value}
					onChange={onChange}
					maxLength={100}
					disabled={!hasUpdateBankAccountDetailFunc}
				/>
			)}
		/>
	);

	const renderButton = (index: number) => (
		<S.ButtonContainer>
			<CRButton.Default
				disabled={!hasUpdateBankAccountDetailFunc}
				type='outlined'
				size='xSmall'
				style={{ borderRadius: '1.6rem' }}
				onClick={() => deleteRecordDetail(index)}>
				삭제
			</CRButton.Default>
		</S.ButtonContainer>
	);

	const renderAmountSelector = (index: number) => (
		<Controller
			name={
				isDeposit
					? `centerAccountRecordDetails.${index}.depositAmt`
					: `centerAccountRecordDetails.${index}.withdrawAmt`
			}
			control={control}
			render={({ field: { value, onChange, ...rest } }) => (
				<CRInput.TableInput
					{...rest}
					type='comma'
					prefix={
						<CRButton.Default
							type='outlined'
							size='xSmall'
							palette='gray'
							onClick={() => {
								update(index, {
									...fieldArray.fields[index],
									depositAmt: isDeposit
										? (
												(lastAmt ?? 0) + displayCommaToNumber(fieldArray.fields[index].depositAmt)
											)?.toLocaleString()
										: '0',
									withdrawAmt: isDeposit
										? '0'
										: (
												(lastAmt ?? 0) + displayCommaToNumber(fieldArray.fields[index].withdrawAmt)
											)?.toLocaleString(),
								});
							}}>
							남은 금액
						</CRButton.Default>
					}
					suffix={
						<S.SuffixContainer>
							<CRText text='원' typography='label' color='gray60' />
						</S.SuffixContainer>
					}
					placeholder='0'
					value={
						isDeposit ? fieldArray.fields[index].depositAmt : fieldArray.fields[index].withdrawAmt
					}
					onChange={(e) => {
						update(index, {
							...fieldArray.fields[index],
							depositAmt: isDeposit ? e : '0',
							withdrawAmt: isDeposit ? '0' : e,
						});
					}}
					disabled={disabledInputs?.includes('금액') || !hasUpdateBankAccountDetailFunc}
				/>
			)}
		/>
	);

	const handleClickPreview = (data: BurdenChargeDTO) => {
		showDialog(() => <EDocNoDialog dialogType='M' edocNo={data.edocNo} />);
	};

	const renderRecipientOwnExpenseInfo = (index: number) => (
		<S.OwnExpensePayDetailContainer>
			<S.HeaderButtonContainer>
				<S.Icon
					onClick={handleClickPrevMonth}
					src={Assets.icon.keyboardArrowLeft}
					alt='keyboardArrowLeft'
				/>
				<S.MonthDate>
					{dayjs(selectedDate).format('YYYY년')}
					<S.Icon src={Assets.icon.calendarToday} />{' '}
				</S.MonthDate>
				<S.Icon
					onClick={handleClickNextMonth}
					src={Assets.icon.keyboardArrowRight}
					alt='keyboardArrowRight'
				/>
			</S.HeaderButtonContainer>
			<S.HorizontalScrollContainer>
				<S.HorizontalScrollContentContainer>
					<InformationTable
						items={[
							[
								{
									type: 'label',
									label: '청구 일자',
									labelStyle: {
										width: '10rem',
									},
								},
								{
									type: 'label',
									label: '연월',
									labelStyle: {
										width: '10rem',
									},
								},
								{
									type: 'label',
									label: '본인부담금',
									labelStyle: {
										width: '10rem',
										textAlign: 'right',
									},
								},
								{
									type: 'label',
									label: '발송 요청 일시',
									labelStyle: {
										width: '15rem',
									},
								},
								{
									type: 'label',
									label: '현금영수증',
									labelStyle: {
										width: '22rem',
									},
								},
								{
									type: 'label',
									label: '',
									labelStyle: {
										width: '25rem',
									},
								},
							],
							...(burdnePayHistoryDetail?.burdenAmtCharges ?? []).map(
								(charges) =>
									[
										{
											type: 'value',
											value: displayShortDate(charges.burdenAmtChargeDt),
										},
										{
											type: 'value',
											value: dayjs(displayShortDate(charges.burdenAmtChargeYm)).format(
												'YYYY년 MM월',
											),
										},
										{
											type: 'value',
											value: (charges.burdenAmt ?? 0).toLocaleString(),
											valueStyle: {
												textAlign: 'right',
											},
										},
										{
											type: 'value',
											value: charges.sendDate
												? dayjs(charges.sendDate).format('YYYY.MM.DD HH:mm')
												: '',
										},
										{
											type: 'value',
											value: (
												<CRStatus
													options={[
														{ key: 'CMN167.10', label: '대기', color: 'yellow' },
														{ key: 'CMN167.20', label: '발행중', color: 'yellow' },
														{ key: 'CMN167.90', label: '완료', color: 'green' },
														{ key: 'CMN167.99', label: '취소완료', color: 'red' },
														{ key: 'CMN167.30', label: '취소중', color: 'red' },
													]}>
													{charges.cashReceiptIssueStateCd}
												</CRStatus>
											),
										},
										{
											type: 'value',
											value: charges.sendDate ? (
												<S.ButtonContainer>
													<CRButton.Default
														size='xSmall'
														type='outlined'
														palette='gray'
														onClick={() => handleClickPreview(charges)}>
														미리보기
													</CRButton.Default>
													{['CMN167.30', 'CMN167.90'].includes(charges.cashReceiptIssueStateCd) ? (
														<CRButton.Default
															size='xSmall'
															type='outlined'
															disabled={
																charges.cashReceiptIssueStateCd === 'CMN167.30' ||
																!hasDeleteCashReceiptFunc
															}
															onClick={() => handleClickCancelCashReceipt(charges)}>
															영수증 취소
														</CRButton.Default>
													) : (
														<CRButton.Default
															size='xSmall'
															type='outlined'
															palette='gray'
															disabled={
																charges.cashReceiptIssueStateCd === 'CMN167.20' ||
																!hasCreateCashReceiptFunc
															}
															onClick={() => handleClickIssueCashReceipt(charges)}>
															영수증 발행
														</CRButton.Default>
													)}
												</S.ButtonContainer>
											) : (
												<div />
											),
										},
									] as InformationTableItemType[],
							),
							[
								{
									type: 'value',
									value: '총액',
									colSpan: 2,
									valueStyle: {
										borderRight: 'none',
									},
								},
								{
									type: 'value',
									value: burdnePayHistoryDetail?.burdenAmtCharges
										.reduce((sum, item) => sum + item.burdenAmt, 0)
										.toLocaleString(),
									valueStyle: {
										fontWeight: '700',
										textAlign: 'right',
									},
								},
								{ type: 'labelValueNull', colSpan: 3 },
							],
						]}
					/>
				</S.HorizontalScrollContentContainer>
			</S.HorizontalScrollContainer>
		</S.OwnExpensePayDetailContainer>
	);

	return (
		<React.Fragment key={item.tempId}>
			<CRTable.Row
				item={item}
				renderKeys={[
					'recordTypeValue',
					'recordDetailValue',
					'recordTargetYm',
					'recordTargetDivValue',
					'id',
					'depositAmt',
					'recordDesc',
					'tempId',
				]}
				customRender={{
					recordTypeValue: (value) => renderRecordTypeSelector(index),
					recordDetailValue: (value) => renderRecipientSelector(index),
					recordTargetDivValue: (value) => renderSecondSelector(index),
					id: (value) => renderCashReceiptSelector(index),
					recordTargetYm: (value) => renderTargetYmSelector(index),
					depositAmt: (value) => renderAmountSelector(index),
					recordDesc: (value) => renderDescription(index),
					tempId: (value) => renderButton(index),
				}}
			/>
			{fieldArray.fields[index].recordTargetDivValue?.value &&
				!fieldArray.fields[index].recordTargetYm && (
					<CRTable.Row
						item={item}
						renderKeys={['recordDetailValue']}
						mergeConfig={{
							recordDetailValue: {
								colSpan: 8,
							},
						}}
						customRender={{
							recordDetailValue: (value) => renderRecipientOwnExpenseInfo(index),
						}}
					/>
				)}
			{monthlyBurdenChargesMutationLoading ||
				(monthlyBurdenChargeDetailMutationLoading && <CRSpinner />)}
		</React.Fragment>
	);
}

export default React.memo(CenterAccountRecordDetailFormRow);
