import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

import { DayOfTheWeek } from 'components/base/CRInput/CRTimePicker';
import { CheckOption } from 'components/base/Selections/type';
import { convertStringTimeToDayjs, displayPadTime, displayPhoneNumber } from 'lib/util';
import {
	EmployWorkInfoDTO,
	GetEmployeeDefaultWorkTimeListData,
	GetEmployeeWorkHistoryResponseData,
	GetEmployeeWorkScheduleData,
} from 'types/api';
import {
	BaseWorkDayDTO,
	CenterDefaultWorkTimeDTO,
	FtimeEmployeeAnnualStateResponseDTO,
} from 'types/dto';
import { EmployeeAnnualStateDTO, HolidayDTO } from 'types/dto/employee';
import { WorkScheduleType } from 'types/view/common';
import { EmployeeWorkInfoViewType } from 'types/view/employee';
import {
	ActualWorkLabelType,
	CenterDefaultWorkTimeViewType,
	DefaultWorkTimeViewType,
	EmployeeActualWorkDataViewType,
	EmployeeBasicWorkInfoViewType,
	EmployeeDailyWorkHistoryViewType,
	EmployeeWorkHistoryDataViewType,
	WorkScheduleListViewType,
} from 'types/view/workSchedule';

export const defaultWorkTimeListAdapter = (
	item: GetEmployeeDefaultWorkTimeListData | null,
): DefaultWorkTimeViewType[] | null => {
	if (!item) return null;

	const displayDayOfWeek = (dayOfWeek: DayOfTheWeek) => {
		switch (dayOfWeek) {
			case DayOfTheWeek.Mon:
				return '월';
			case DayOfTheWeek.Tue:
				return '화';
			case DayOfTheWeek.Wed:
				return '수';
			case DayOfTheWeek.Thu:
				return '목';
			case DayOfTheWeek.Fri:
				return '금';
			case DayOfTheWeek.Sat:
				return '토';
			case DayOfTheWeek.Sun:
				return '일';
			default:
				return '';
		}
	};

	const findDayValueByCode = (day: BaseWorkDayDTO) =>
		displayDayOfWeek(Object.values(DayOfTheWeek).filter((item) => item === day.workDayCd)[0]);

	const generateWorkHour = (startTime: string, endTime: string) =>
		`${startTime.slice(0, 2)}:${startTime.slice(2)} ~ ${endTime.slice(0, 2)}:${endTime.slice(2)}`;

	return item.map((defaultWorkTime) => ({
		days: defaultWorkTime.basWorkDays.map(findDayValueByCode).join(', '),
		workHour: generateWorkHour(defaultWorkTime.workStartTime, defaultWorkTime.workEndTime),
		data: defaultWorkTime,
	}));
};

export const employeeWorkScheduleDataAdapter = (
	data: GetEmployeeWorkScheduleData | null,
): WorkScheduleListViewType | null => {
	if (!data) return null;

	const workScheduleTypeMap: { [key: string]: WorkScheduleType } = {
		[WorkScheduleType.WorkSchedule]: WorkScheduleType.WorkSchedule,
		[WorkScheduleType.Vacation]: WorkScheduleType.Vacation,
		[WorkScheduleType.OverWork]: WorkScheduleType.OverWork,
		[WorkScheduleType.WorkDetail]: WorkScheduleType.WorkDetail,
	};

	const { joinDate } = data.ftimeEmployeeBasicInfo;

	const calculateEmployState = () => {
		const { resignDate } = data.ftimeEmployeeBasicInfo;

		if (!joinDate) return '-';
		if (resignDate && dayjs().diff(dayjs(resignDate)) >= 0) return '퇴사';
		if (dayjs(joinDate).diff(dayjs()) <= 0) return '근무중';
		if (dayjs(joinDate).diff(dayjs()) > 0) return '대기중';
		return '-';
	};

	const employeeBasicInfo = {
		korMemberNm: data.ftimeEmployeeBasicInfo.korMemberNm ?? '-',
		dutyNm: data.ftimeEmployeeBasicInfo.dutyNm ?? '-',
		mobilePhoneNo: displayPhoneNumber(data.ftimeEmployeeBasicInfo.mobilePhoneNo),
		workExecuteActingEmployeeNm: data.ftimeEmployeeBasicInfo.workExecuteActingEmployeeNm ?? '-',
		joinDate: joinDate ? dayjs(joinDate).format('YYYY.MM.DD') : '-',
		employState: calculateEmployState(),
		data: data.ftimeEmployeeBasicInfo,
	};

	const actualWorkList = data.workLeaves.map((item, index) => ({
		workDt: item.workDt ?? '',
		label: item.workRfidStartTime ?? '',
		type: ActualWorkLabelType.WorkActual ?? '',
		overworkTimeCnt: item.workHourCnt ?? '',
		data: item,
	}));

	return {
		employeeBasicInfoData: employeeBasicInfo ?? ({} as EmployeeBasicWorkInfoViewType),
		actualWorkData: actualWorkList ?? ([] as EmployeeActualWorkDataViewType[]),
		holidayData: data.holidays ?? ([] as HolidayDTO[]),
		annualStateData: data.annualStates ?? ([] as EmployeeAnnualStateDTO[]),
		data,
	};
};

export const employeeWorkHistoryDataAdapter =
	(targetMonth: Dayjs) =>
	(data: GetEmployeeWorkHistoryResponseData | null): EmployeeWorkHistoryDataViewType | null => {
		if (!data) return null;
		dayjs.extend(isoWeek);

		const totalOverWorkTimeOfMonth = data.workHists
			.filter((item) => dayjs(item.workScheduleDt).isSame(targetMonth, 'M'))
			.reduce((sum, item) => sum + (item.overWorkTimeCnt || 0), 0);

		const daysInMonth = Array.from({ length: targetMonth.daysInMonth() }, (_, i) =>
			targetMonth.startOf('month').add(i, 'days'),
		);

		const dailyWorkHistory: EmployeeDailyWorkHistoryViewType[] = daysInMonth.map((date) => {
			const startOfWeek = date.startOf('week');
			const endOfWeek = date.endOf('week');
			const isBeforeToday = date.isBefore(dayjs(), 'D');

			// 일일 업무 이력
			const managerTagOfTargetDate = data?.managerTagHists
				?.filter((item) => dayjs(item.serviceYmd).isSame(date, 'day'))
				.sort((prev, cur) => {
					const prevStartTime = prev.rfidStartTime
						? convertStringTimeToDayjs(dayjs(prev.serviceYmd), prev.rfidStartTime)
						: null;
					const currentStartTime = cur.rfidStartTime
						? convertStringTimeToDayjs(dayjs(cur.serviceYmd), cur.rfidStartTime)
						: null;
					if (!prevStartTime) return -1;
					if (!currentStartTime) return 1;
					return prevStartTime.isBefore(currentStartTime) ? -1 : 1;
				});

			const workExecuteLogsOfTargetDate = data.workExecuteLogs
				?.filter(
					(item) =>
						dayjs(item.visitDt).isSame(date, 'day') && item.workExecuteLogStateCd === 'CMN184.20',
				)
				.sort((prev, cur) => {
					const prevStartTime = prev.startTime
						? convertStringTimeToDayjs(dayjs(prev.visitDt), prev.startTime)
						: null;
					const currentStartTime = cur.startTime
						? convertStringTimeToDayjs(dayjs(cur.visitDt), cur.startTime)
						: null;
					if (!prevStartTime) return -1;
					if (!currentStartTime) return 1;
					return prevStartTime.isBefore(currentStartTime) ? -1 : 1;
				});

			const actualWorkOfTargetDate = data.workLeaves
				.filter((item) => dayjs(item.workDt).isSame(date, 'day'))
				.map((item) => {
					const workStartTime = item.workTime
						? convertStringTimeToDayjs(date, item.workTime)
						: undefined;
					const workEndTime = item.leaveTime
						? convertStringTimeToDayjs(date, item.leaveTime)
						: undefined;
					const currentWorkEndTime =
						workEndTime && workStartTime
							? workEndTime.isBefore(workStartTime, 'minute')
								? workEndTime.add(1, 'day')
								: workEndTime
							: undefined;

					return {
						workDt: workStartTime,
						workStartTime,
						workStartplace:
							item?.workPlaceCd && item?.workPlaceNm
								? ({
										label: item.workPlaceNm,
										value: item.workPlaceCd,
									} as CheckOption)
								: undefined,
						workStartRemark: item.workRemark,
						leaveDt: currentWorkEndTime,
						workEndTime: currentWorkEndTime,
						workEndplace:
							item?.leavePlaceCd && item?.leavePlaceNm
								? ({
										label: item.leavePlaceNm,
										value: item.leavePlaceCd,
									} as CheckOption)
								: undefined,
						workEndRemark: item.leaveRemark,
						data: item,
					};
				})[0];

			const workHistoryOfTargetDate = data.workHists
				.filter((item) => dayjs(item.workScheduleDt).isSame(date, 'day'))
				.map((item) => {
					const startTime = convertStringTimeToDayjs(date, item.startTime);
					const endTime = convertStringTimeToDayjs(date, item.endTime);
					const currentWorkEndTime = endTime.isBefore(startTime, 'minute')
						? endTime.add(1, 'day')
						: endTime;

					return {
						labelText:
							!isBeforeToday && !actualWorkOfTargetDate?.workEndTime
								? `${displayPadTime(item.startTime)} ~ `
								: `${displayPadTime(item.startTime)} ~ ${displayPadTime(item.endTime)}`,
						tagText: item.overWorkTimeCnt ? `연장(${item.overWorkTimeCnt / 60})` : '',
						workHourCnt: item.workHourCnt ?? 0,
						overWorkTimeCnt: item.overWorkTimeCnt ?? 0,
						startTime,
						endTime: currentWorkEndTime,
						data: item,
					};
				})[0];

			const holidayOfTargetDate = data.holidays
				.filter((item) => dayjs(item.holidayDt).isSame(date, 'day'))
				.map((item) => ({
					data: item,
				}));

			const vacationOfTargetDate = data.vacas
				.filter((item) => dayjs(item.workScheduleDt).isSame(date, 'day'))
				.map((item) => ({
					vacationDate: item.workScheduleDt,
					categoryOfVacation:
						item?.vacaKindCd && item?.vacaKindNm
							? ({
									label: item.vacaKindNm,
									value: item.vacaKindCd,
								} as CheckOption)
							: undefined,
					tagText: item.vacaKindNm
						? `${item.vacaKindNm}${
								(item.vacaUseDayCnt * 8) % 8 ? `(${(item.vacaUseDayCnt * 8) % 8})` : ''
							}`
						: '',
					data: item,
				}));

			const employeeActualWorkLogChageHistoryOfTargetDate = data.workLeaveChangeHists.filter(
				(item) => item.workLeaveId === actualWorkOfTargetDate?.data.workLeaveId,
			);
			const initialEmployeeActualWorkLog = employeeActualWorkLogChageHistoryOfTargetDate.find(
				(item) =>
					item.createdToEmployeeInfo?.employeeId === data.ftimeEmployeeBasicInfo.employeeId &&
					item.workTime &&
					item.leaveTime,
			);

			const roundingScheduleOfTargetDate: {
				roundingDt: Dayjs;
				startTime: Dayjs;
				endTime: Dayjs;
			}[] = [
				...((managerTagOfTargetDate ?? [])
					.map((item) => {
						if (!item?.serviceYmd || !item?.rfidStartTime || !item?.rfidEndTime) return null;

						const serviceDt = dayjs(item.serviceYmd);
						const rfidStartTime = convertStringTimeToDayjs(serviceDt, item.rfidStartTime);
						const rfidEndTime = convertStringTimeToDayjs(serviceDt, item.rfidEndTime);

						return { roundingDt: serviceDt, startTime: rfidStartTime, endTime: rfidEndTime };
					})
					.filter((item) => !!item) ?? []),
				...((workExecuteLogsOfTargetDate ?? [])
					.map((item) => {
						if (!item?.startTime || !item?.endTime || !item?.visitDt) return null;
						const visitDt = dayjs(item.visitDt);
						const startTime = convertStringTimeToDayjs(visitDt, item.startTime);
						const endTime = convertStringTimeToDayjs(visitDt, item.endTime);

						return { roundingDt: visitDt, startTime, endTime };
					})
					.filter((item) => !!item) ?? []),
			];

			const minRoundingStartTime =
				roundingScheduleOfTargetDate.length === 0
					? undefined
					: roundingScheduleOfTargetDate.reduce(
							(min, curr) =>
								curr.startTime
									? curr.startTime.isBefore(min, 'minute')
										? curr.startTime
										: min
									: min,
							date.add(1, 'day'),
						);

			const maxRoundingEndTime =
				roundingScheduleOfTargetDate.length === 0
					? undefined
					: roundingScheduleOfTargetDate.reduce(
							(max, curr) =>
								curr.endTime ? (curr.endTime.isAfter(max, 'minute') ? curr.endTime : max) : max,
							date.add(-1, 'day'),
						);

			const managerTagInfo = managerTagOfTargetDate?.map((item) => ({
				roundingDt: dayjs(item.serviceYmd),
				startTime: item.rfidStartTime
					? convertStringTimeToDayjs(dayjs(item.serviceYmd), item.rfidStartTime).format('HH:mm')
					: '-',
				endTime: item.rfidEndTime
					? convertStringTimeToDayjs(dayjs(item.serviceYmd), item.rfidEndTime).format('HH:mm')
					: '-',
			}));

			const workExecuteLogInfo = workExecuteLogsOfTargetDate?.map((item) => ({
				roundingDt: dayjs(item.visitDt),
				startTime: convertStringTimeToDayjs(dayjs(item.visitDt), item.startTime).format('HH:mm'),
				endTime: convertStringTimeToDayjs(dayjs(item.visitDt), item.endTime).format('HH:mm'),
				recipientName: item.recipientNm ?? '-',
				longtermNumber: item.longTermNo ?? '-',
			}));

			const totalWorkTimeCntOfTargetWeek = data.workHists
				.filter(
					(item) =>
						!dayjs(item.workScheduleDt).isBefore(startOfWeek, 'D') &&
						!dayjs(item.workScheduleDt).isAfter(endOfWeek, 'D'),
				)
				.reduce((sum, item) => sum + (item.workHourCnt ?? 0) + (item.overWorkTimeCnt ?? 0), 0);

			const vacationCnt = vacationOfTargetDate.reduce(
				(sum, item) => sum + item.data.workHourCnt,
				0,
			);

			const hasWorkLog = workHistoryOfTargetDate !== undefined;
			const isAllDayOff = vacationCnt >= 8 * 60;
			const isWorkDay = date.day() !== 0 && date.day() !== 6 && holidayOfTargetDate.length === 0;

			const shouldRenderNoHistoryLabel = isWorkDay && !hasWorkLog && !isAllDayOff && isBeforeToday;

			const isConfirmedDay =
				(!!workHistoryOfTargetDate && workHistoryOfTargetDate.data.workHistCompleteYn) ||
				(vacationOfTargetDate.length > 0 &&
					vacationOfTargetDate.filter((item) => !item.data.workHistCompleteYn).length === 0);

			return {
				date,
				workHistoryInfo: workHistoryOfTargetDate,
				actualWorkInfo: actualWorkOfTargetDate,
				vacationInfo: vacationOfTargetDate,
				holidayInfo: holidayOfTargetDate,
				confirmed: isConfirmedDay,
				shouldRenderNoHistoryLabel,
				totalOverWorkTimeOfMonth,
				totalWorkTimeCntOfTargetWeek,
				maxRoundingEndTime,
				minRoundingStartTime,
				managerTagInfo,
				workExecuteLogInfo,
				initialEmployeeActualWorkHourCnt: initialEmployeeActualWorkLog?.workHourCnt ?? 0,
			};
		});

		const annualStateInfo =
			data.annualStates.find(
				(item) =>
					(dayjs(item.annualStartDate).isSame(targetMonth.endOf('month'), 'D') ||
						dayjs(item.annualStartDate).isBefore(targetMonth.endOf('month'), 'D')) &&
					(dayjs(item.annualEndDate).isSame(targetMonth.endOf('month'), 'D') ||
						dayjs(item.annualEndDate).isAfter(targetMonth.endOf('month'), 'D')),
			) || ({} as FtimeEmployeeAnnualStateResponseDTO);

		const { joinDate } = data.ftimeEmployeeBasicInfo;
		const calculateEmployState = () => {
			const { resignDate } = data.ftimeEmployeeBasicInfo;

			if (!joinDate) return '-';
			if (resignDate && dayjs().diff(dayjs(resignDate), 'D') >= 0) return '퇴사';
			if (dayjs(joinDate).diff(dayjs()) <= 0) return '근무중';
			if (dayjs(joinDate).diff(dayjs()) > 0) return '대기중';
			return '-';
		};

		const employeeBasicInfo = {
			korMemberNm: data.ftimeEmployeeBasicInfo.korMemberNm ?? '-',
			dutyNm: data.ftimeEmployeeBasicInfo.dutyNm ?? '-',
			mobilePhoneNo: data.ftimeEmployeeBasicInfo.mobilePhoneNo
				? displayPhoneNumber(data.ftimeEmployeeBasicInfo.mobilePhoneNo)
				: '-',
			workExecuteActingEmployeeNm: data.ftimeEmployeeBasicInfo.workExecuteActingEmployeeNm ?? '-',
			joinDate: joinDate ? dayjs(joinDate).format('YYYY.MM.DD') : '-',
			employState: calculateEmployState(),
			data: data.ftimeEmployeeBasicInfo,
		};

		const responseData = {
			employeeBasicInfo,
			dailyWorkHistory,
			annualStateInfo,
			data,
		};

		return responseData;
	};

export const employeeWorkInfoDataAdapter = (
	data: EmployWorkInfoDTO[] | null,
): EmployeeWorkInfoViewType[] => {
	if (!data) return [];

	return data.map((item) => {
		const {
			pcorpTimeCnt = 0,
			workTimeCnt = 0,
			overWorkTimeCnt = 0,
			overWorkMaxTimeCnt = 0,
			...rest
		} = item;

		return {
			...rest,
			pcorpTimeCnt,
			workTimeCnt,
			overWorkTimeCnt,
			overWorkMaxTimeCnt,
		};
	});
};

export const centerDefaultWorkTimeAdapter = (
	item: CenterDefaultWorkTimeDTO[] | null,
): CenterDefaultWorkTimeViewType | null => {
	if (!item) return null;

	const displayDayOfWeek = (dayOfWeek: DayOfTheWeek) => {
		switch (dayOfWeek) {
			case DayOfTheWeek.Mon:
				return '월';
			case DayOfTheWeek.Tue:
				return '화';
			case DayOfTheWeek.Wed:
				return '수';
			case DayOfTheWeek.Thu:
				return '목';
			case DayOfTheWeek.Fri:
				return '금';
			case DayOfTheWeek.Sat:
				return '토';
			case DayOfTheWeek.Sun:
				return '일';
			default:
				return '';
		}
	};

	const findDayValueByCode = (day: BaseWorkDayDTO) =>
		displayDayOfWeek(Object.values(DayOfTheWeek).filter((item) => item === day.workDayCd)[0]);

	const generateWorkHour = (startTime: string, endTime: string) =>
		`${displayPadTime(startTime)} ~ ${displayPadTime(endTime)}`;

	return item.map((defaultWorkTime) => ({
		days: defaultWorkTime.centerBasWorkDays.map(findDayValueByCode).join(', '),
		workHour: generateWorkHour(defaultWorkTime.workStartTime, defaultWorkTime.workEndTime),
		data: defaultWorkTime,
	}))[0];
};
