import styled from 'styled-components';

export const HistoryListContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;
export const HistoryContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	gap: 1.6rem;
	padding: 1.6rem;
	width: 100%;
	border: 0.1rem solid ${(p) => p.theme.colors.gray90};
`;

export const SectionTitle = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray10}
`;

export const OwnExpensePayDetailContainer = styled.div`
	width: 100%;
	padding: 0.8rem 0rem;
`;

export const HorizontalScrollContainer = styled.div`
	width: 100%;
`;

export const HorizontalScrollContentContainer = styled.div``;

export const HeaderButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	& > div {
		display: flex;
		gap: 0.8rem;
	}
	margin-top: 0.6rem;
	margin-bottom: 0.2rem;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const MonthDate = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	${(props) => props.theme.typography.bodyB};
	color: ${(props) => props.theme.colors.gray00};
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

export const SuffixContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;
