import React, {
	CSSProperties,
	PropsWithChildren,
	ReactNode,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import Assets from 'assets';
import CRPortal from 'components/base/CRPortal';

import * as S from './styles';
import { ToolTipBoxBgColor, ToolTipPlacement } from './type';

interface IProps {
	title?: ReactNode;
	arrow?: boolean;
	placement?: ToolTipPlacement;
	containerStyle?: CSSProperties;
	innerStyle?: CSSProperties;
	offset?: number;
	tooltipBgColor?: ToolTipBoxBgColor;
}

function CRToolTip({
	title,
	arrow = true,
	placement = 'up',
	tooltipBgColor = 'black',
	containerStyle = {},
	children,
	offset = 0,
	innerStyle = {},
}: PropsWithChildren<IProps>): React.ReactElement {
	const ref = useRef<HTMLDivElement>(null);
	const tooltipRef = useRef<HTMLDivElement>(null);
	const [visible, setVisible] = useState(false);
	const [position, setPosition] = useState<DOMRect | undefined>(undefined);
	const [tooltipPosition, setTooltipPosition] = useState<DOMRect | undefined>(undefined);

	const positionStyle = useMemo(() => {
		if (placement === 'left') {
			return {
				top: `calc(${(position?.top ?? 0) - (tooltipPosition?.height ?? 0) / 2 + (position?.height ?? 0) / 2}px + ${offset}rem)`,
				left: `calc(${(position?.left ?? 0) + (position?.width ?? 0)}px + ${offset}rem + 0.8rem)`,
			};
		}
		if (placement === 'right') {
			return {
				top: `calc(${(position?.top ?? 0) - (tooltipPosition?.height ?? 0) / 2 + (position?.height ?? 0) / 2}px + ${offset}rem)`,
				left: `calc(${(position?.left ?? 0) - (tooltipPosition?.width ?? 0)}px + ${offset}rem - 0.8rem)`,
			};
		}
		if (placement === 'up') {
			return {
				top: `calc(${(position?.top ?? 0) + (position?.height ?? 0)}px + ${offset}rem + 0.8rem)`,
				left: `calc(${(position?.left ?? 0) - (tooltipPosition?.width ?? 0) / 2 + (position?.width ?? 0) / 2}px + ${offset}rem)`,
			};
		}
		if (placement === 'down') {
			return {
				top: `calc(${(position?.top ?? 0) - (tooltipPosition?.height ?? 0)}px + ${offset}rem - ${arrow ? '0.8rem' : '0rem'})`,
				left: `calc(${(position?.left ?? 0) - (tooltipPosition?.width ?? 0) / 2 + (position?.width ?? 0) / 2}px + ${offset}rem)`,
			};
		}
		return {
			top: 0,
			left: 0,
		};
	}, [position, tooltipPosition, placement, offset]);

	useEffect(() => {
		const containerPosition = ref.current?.getBoundingClientRect();
		if (containerPosition) setPosition(containerPosition);
	}, [title]);

	useEffect(() => {
		const tooltipContainerPosition = tooltipRef.current?.getBoundingClientRect();
		if (tooltipContainerPosition) setTooltipPosition(tooltipContainerPosition);
	}, [title]);

	useEffect(() => {
		const handleResize = (event: UIEvent) => {
			const containerPosition = ref.current?.getBoundingClientRect();
			if (containerPosition) setPosition(containerPosition);
			const tooltipContainerPosition = tooltipRef.current?.getBoundingClientRect();
			if (tooltipContainerPosition) setTooltipPosition(tooltipContainerPosition);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<S.Container
			ref={ref}
			style={containerStyle}
			onMouseOver={() => setVisible(true)}
			onMouseLeave={() => setVisible(false)}
			placement={placement}>
			{children}
			{title && (
				<CRPortal>
					<S.TooltipBody
						ref={tooltipRef}
						placement={placement}
						visible={visible}
						style={{
							...innerStyle,
							...positionStyle,
							background: tooltipBgColor,
						}}>
						{arrow && (
							<S.ArrowContainer placement={placement} tooltipBgColor={tooltipBgColor}>
								<img src={Assets.icon.tooltipArrow} alt='arrow' />
							</S.ArrowContainer>
						)}
						{title}
					</S.TooltipBody>
				</CRPortal>
			)}
		</S.Container>
	);
}

export default React.memo(CRToolTip);
