import React from 'react';

import CRButton from 'components/base/CRButton';

import * as S from './styles';

interface Props {
	label?: string | React.ReactElement;
	deletable?: boolean;
	isRequired?: boolean;
	renderRightButton?: React.ReactElement;
}

function CRCardFormLayout({
	label = '',
	deletable,
	children,
	isRequired,
	renderRightButton,
}: React.PropsWithChildren<Props>): React.ReactElement {
	return (
		<S.Container>
			<S.HeaderContainer>
				<S.HeaderTitle>
					{label}
					{isRequired && <S.RequiredMark>*</S.RequiredMark>}
				</S.HeaderTitle>
				{deletable && (
					<CRButton.Default size='xSmall' type='outlined' palette='primary' disabled>
						삭제
					</CRButton.Default>
				)}
				{renderRightButton && renderRightButton}
			</S.HeaderContainer>
			<S.FormContainer>{children}</S.FormContainer>
		</S.Container>
	);
}

export default CRCardFormLayout;
