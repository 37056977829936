import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import ContractReviewRecipientScheduleAutomationFormView from 'components/domain/form/ContractReviewRecipientScheduleAutomationFormView';
import { RecipientContractReviewFormFields } from 'components/domain/sideModal/RecipientContractReviewSideModal/types';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';

import { COMPLETION_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: RecepientContractReviewListViewType;
}

function RecipientScheduleInfo({ data }: Props): React.ReactElement {
	const { control } = useFormContext<RecipientContractReviewFormFields>();
	const { data: commonCodes = { CMN213: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN213'] },
		commonCodeAdapter,
	);

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						공단 일정 등록
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.tagCompletionStatus ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<CRInputLabel label='등록 여부' type='left'>
					<Controller
						control={control}
						name='registeredLongtermCareBenefitScheduleToCorporationOption'
						render={({ field: { value, onChange } }) => (
							<CRInput.Selector
								currentValue={value}
								onChangeValue={onChange}
								items={commonCodes.CMN213}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
			<CRCardFormLayout label={<S.CardLabelContainer>자동화 설정</S.CardLabelContainer>}>
				<ContractReviewRecipientScheduleAutomationFormView
					centerId={data.data.centerId}
					recipientId={data.data.recipientId}
				/>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default RecipientScheduleInfo;
