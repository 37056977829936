import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import InformationSheet from 'components/ui/InformationSheet';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import useDialog from 'lib/hook/util/useDialog';
import { EDocResignDTO } from 'types/api/eDoc';
import { EmployeeDTO } from 'types/api/employee';
import { ResignFormTypes } from 'types/view/eDoc';

import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<ResignFormTypes, any, undefined>;
}

// 퇴직급여지급연장동의서
export default function EDocSeverancePaymentAgreementForm({
	templateCode,
	currentEmployee,
	formContext,
}: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});

	const workStartDate = formContext.watch('workStartDate');
	const resignDate = formContext.watch('resignDate');

	const workStartEndDate = workStartDate
		? `${dayjs(workStartDate).format('YYYY-MM-DD')} ~ ${dayjs(
				formContext.watch('resignDate'),
			).format('YYYY-MM-DD')}`
		: '-';
	const totalWorkDate: string = useMemo(() => {
		const diffTotalMonth = dayjs(resignDate)?.diff(dayjs(workStartDate), 'month');

		if (diffTotalMonth > 11) {
			return `총 ${Math.floor(diffTotalMonth / 12)}년 ${Math.floor(diffTotalMonth % 12)}개월 근무`;
		}
		return `총 ${Math.floor(diffTotalMonth % 12)}개월 근무`;
	}, [workStartDate, resignDate]);

	const handleClickResignDate = async (): Promise<boolean> =>
		new Promise((resolve) => {
			showDialog(({ hideDialog }) => (
				<CRDialog
					title='퇴사 희망일 작성 기준'
					body={
						<S.DialogBodyContainer>
							{`1. '퇴직 의사를 밝힌 날', '마지막 근무일 다음날' 중 더 나중 일자를 입력해주세요. (장기 보류 후 퇴사하는 근로자의 경우, 반드시 '퇴직 의사를 밝힌 날'을 입력해주세요!)\n2. 조건부 계약종료(수급자 사망, 수급자 계약종료 등)의 경우, 사유 발생일 다음날을 입력해주세요.`}
						</S.DialogBodyContainer>
					}
					onClickClose={() => {
						resolve(true);
						hideDialog();
					}}
					footer={
						<S.ButtonContainer>
							<CRButton.Default
								palette='primary'
								onClick={() => {
									resolve(true);
									hideDialog();
								}}>
								확인
							</CRButton.Default>
						</S.ButtonContainer>
					}
				/>
			));
		});

	useEffect(() => {
		if (eDocBeforeIssueInfo) {
			formContext.setValue('data', eDocBeforeIssueInfo);
			const accidentDate = (eDocBeforeIssueInfo as unknown as EDocResignDTO).data.employee
				.accidentInsuranceAcqDate;
			if (accidentDate) {
				formContext.setValue('workStartDate', dayjs(accidentDate).toDate(), {
					shouldValidate: true,
				});
			}
		}
	}, [eDocBeforeIssueInfo]);

	useEffect(() => {
		formContext.setValue('resignDate', new Date(), { shouldValidate: true });
	}, []);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='신청인' isRequired>
					<CRInput.Selector
						autoComplete
						disabled
						currentValue={{
							label: currentEmployee?.korMemberNm || '',
							value: currentEmployee?.employeeId,
						}}
						items={[
							{
								label: currentEmployee?.korMemberNm || '',
								value: currentEmployee?.employeeId,
							},
						]}
					/>
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='입사일' isRequired>
							<CRInput.DatePicker
								value={value}
								onChangeValue={onChange}
								placeholder='입사일 선택'
							/>
						</CRInputLabel>
					)}
					name='workStartDate'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='퇴사일' isRequired>
							<S.ResignDateContainer>
								<CRInput.DatePicker
									value={value}
									onChangeValue={onChange}
									placeholder='퇴사일 선택'
									onClick={handleClickResignDate}
								/>
								<InformationSheet
									items={[
										[
											{
												label: '근무기간',
												value: workStartEndDate,
												labelWidth: '12rem',
											},
										],
										[
											{
												label: '근무 개월수',
												value: totalWorkDate,
												labelWidth: '12rem',
											},
										],
									]}
									type='gray'
								/>
							</S.ResignDateContainer>
						</CRInputLabel>
					)}
					name='resignDate'
					control={formContext.control}
				/>
			</S.SubFormContainer>
		</S.Container>
	);
}
