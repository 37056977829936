import { CRStatusConfig, CRTableMultiHeadConfig } from 'types/view/base';

export const COMPLETION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '미완료',
		label: '미완료',
		color: 'yellow',
	},
];

export const CHECK_CRIME_REPORT_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '해당없음',
		label: '해당없음',
		color: 'gray60',
	},
	{
		key: '등록완료',
		label: '등록완료',
		color: 'green',
	},
	{
		key: '미등록',
		label: '미등록',
		color: 'yellow',
	},
];

export const INSURANCE_TABLE_HEADER_CONFIG: CRTableMultiHeadConfig[] = [
	{
		label: '상태',
		sortKey: 'insuranceStatus',
		width: '8.8rem',
		textAlign: 'left',
	},
	{
		label: '득실타입',
		sortKey: 'insuranceType',
		width: '8.8rem',
		textAlign: 'left',
	},
	{
		label: '국민연금',
		children: [
			{
				label: '신고금액',
				sortKey: 'nationalPensionAmount',
				width: '10.4rem',
			},
			{
				label: '신고일자',
				sortKey: 'nationalPensionDate',
				width: '10.4rem',
			},
		],
		textAlign: 'left',
	},
	{
		label: '건강보험',
		children: [
			{
				label: '신고금액',
				sortKey: 'healthInsuranceAmount',
				width: '10.4rem',
			},
			{
				label: '신고일자',
				sortKey: 'healthInsuranceDate',
				width: '10.4rem',
			},
		],
		textAlign: 'left',
	},
	{
		label: '고용보험',
		children: [
			{
				label: '신고금액',
				sortKey: 'employmentInsuranceAmount',
				width: '10.4rem',
			},
			{
				label: '신고일자',
				sortKey: 'employmentInsuranceDate',
				width: '10.4rem',
			},
		],
		textAlign: 'left',
	},
	{
		label: '산재보험',
		children: [
			{
				label: '신고금액',
				sortKey: 'compensationInsuranceAmount',
				width: '10.4rem',
			},
			{
				label: '신고일자',
				sortKey: 'compensationInsuranceDate',
				width: '10.4rem',
			},
		],
		textAlign: 'left',
	},
	{
		label: '생성일시',
		sortKey: 'creationDate',
		width: '16rem',
		textAlign: 'left',
	},
];

export const TAG_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '실패',
		label: '실패',
		color: 'red',
	},
	{
		key: '대기',
		label: '대기',
		color: 'gray',
	},
	{
		key: '진행중',
		label: '진행중',
		color: 'yellow',
	},
];
