import styled from 'styled-components';

export const TabRightButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.8rem;
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	width: 100%;

	tr:has(&) td:last-child > div {
		display: none;
	}

	tr:has(&):hover td:last-child > div {
		display: flex;
	}
`;
