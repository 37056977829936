import { endpoint } from 'lib/service/Api/endpoint';

import useCRMutation from '../base/useCRMutation';

export const useUpdateInsuranceRequestState = useCRMutation(
	endpoint.updateInsuranceRequestState.key,
	'updateInsuranceRequestState',
);

export const useInsuranceEtcExcelDownload = useCRMutation(
	endpoint.getInsuranceEtcExcelDownload.key,
	'getInsuranceEtcExcelDownload',
);

export const useCreateInsurance = useCRMutation(endpoint.createInsurance.key, 'createInsurance');
export const useCreateInsuranceList = useCRMutation(
	endpoint.createInsuranceList.key,
	'createInsuranceList',
);

export const useUpdateInsuranceDetailInfo = useCRMutation(
	endpoint.updateInsuranceDetail.key,
	'updateInsuranceDetailInfo',
);

export const useInsuranceAcqExcelDownload = useCRMutation(
	endpoint.getInsuranceAcqExcelDownload.key,
	'getInsuranceAcqExcelDownload',
);

export const useInsuranceLossExcelDownload = useCRMutation(
	endpoint.getInsuranceLossExcelDownload.key,
	'getInsuranceLossExcelDownload',
);

export const useCheckInsuranceRequiredEDoc = useCRMutation(
	endpoint.checkInsuranceRequiredEDoc.key,
	'checkInsuranceRequiredEDoc',
);
