import { CRStatusConfig } from 'types/view/base';

export const COMPLETION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '완료',
		label: '완료',
		color: 'green',
	},
	{
		key: '미완료',
		label: '미완료',
		color: 'yellow',
	},
];
export const VALIDATION_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '적합',
		label: '적합',
		color: 'green',
	},
	{
		key: '부적합',
		label: '부적합',
		color: 'red',
	},
];

export const SEND_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: '작성 대기',
		label: '작성 대기',
		value: 'CMN171.05',
		color: 'red',
	},
	{
		key: '서명 대기',
		label: '서명 대기',
		value: 'CMN171.10',
		color: 'red',
	},
	{
		key: '검수 대기',
		label: '검수 대기',
		value: 'CMN171.20',
		color: 'green',
	},
	{
		key: '완료',
		label: '완료',
		value: 'CMN171.30',
		color: 'green',
	},
	{
		key: '서명 취소',
		label: '서명 취소',
		value: 'CMN171.40',
		color: 'red',
	},
	{
		key: '기간 만료',
		label: '기간 만료',
		value: 'CMN171.50',
		color: 'red',
	},
	{
		key: '상태 오류',
		label: '상태 오류',
		value: 'CMN171.99',
		color: 'red',
	},
];
