import React from 'react';

import * as S from './styles';

interface IProps extends React.PropsWithChildren {
	type?: 'default' | 'error';
	children: React.ReactNode;
}

function CRInputMessage({ type = 'default', children }: IProps): React.ReactElement {
	if (type === 'error') {
		return <S.ErrorMessage>{children}</S.ErrorMessage>;
	}
	return <S.DefaultMessage>{children}</S.DefaultMessage>;
}

export default React.memo(CRInputMessage);
