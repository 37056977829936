import React from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { QueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { insuranceAcquirementEnrollValidator } from 'components/domain/dialog/InsuranceEnrollDialog/validator';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { useCreateInsurance } from 'lib/hook/react-query/mutation/insurance';
import useDialog from 'lib/hook/util/useDialog';
import { ResponseCode } from 'types/api';
import { CreateAcquirementInsuranceRequest, CreateInsuranceResponse } from 'types/api/insurance';
import { InsuranceAcquirementEnrollFormType } from 'types/view/insurance';

import * as S from './styles';

interface InsurancePropsType {
	weekPerWorkHourCnt: number; // 주 소정근로시간
	accidentInsuranceRequestAmt?: number; // 산재보험
	accidentInsuranceApproveYn: boolean; // 산재보험
	accidentInsuranceRequestDate: string;
	pensionInsuranceRequestAmt?: number; // 국민연금
	pensionInsuranceApproveYn: boolean; // 국민연금
	pensionInsuranceRequestDate: string;
	employInsuranceRequestAmt?: number; // 고용보험
	employInsuranceApproveYn: boolean; // 고용보험
	employInsuranceRequestDate: string;
	healthInsuranceRequestAmt?: number; // 건강보험
	healthInsuranceApproveYn: boolean; // 건강보험
	healthInsuranceRequestDate: string;
}

interface Props {
	item: InsurancePropsType;
	employeeId: number;
	centerId: number;
	onRefetch: () => void;
}

export function InsuranceEnrollDialog({ item, employeeId, centerId, onRefetch }: Props) {
	const { hideDialog } = useDialog();
	const { mutateAsync: createInsurance, isLoading } = useCreateInsurance(
		(client: QueryClient, data: CreateInsuranceResponse | null) => {
			if (data?.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 4대보험 신고 신청하였습니다.');
				onRefetch();
				hideDialog();
			} else {
				Toast.error('4대보험 신고 신청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		},
	);

	const {
		control,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm<InsuranceAcquirementEnrollFormType>({
		resolver: yupResolver(insuranceAcquirementEnrollValidator),
		defaultValues: {
			weekPerWorkHourCnt: item.weekPerWorkHourCnt === 0 ? undefined : item.weekPerWorkHourCnt, // 주 소정근로시간
			accidentInsuranceRequestAmt: item.accidentInsuranceRequestAmt
				? item.accidentInsuranceRequestAmt.toLocaleString()
				: undefined, // 산재보험
			accidentInsuranceApproveYn: item.accidentInsuranceApproveYn, // 산재보험
			accidentInsuranceRequestDate: dayjs(item.accidentInsuranceRequestDate).toDate(),
			pensionInsuranceRequestAmt: item.pensionInsuranceRequestAmt
				? item.pensionInsuranceRequestAmt.toLocaleString()
				: undefined, // 국민연금
			pensionInsuranceApproveYn: item.pensionInsuranceApproveYn, // 국민연금
			pensionInsuranceRequestDate: dayjs(item.pensionInsuranceRequestDate).toDate(),
			employInsuranceRequestAmt: item.employInsuranceRequestAmt
				? item.employInsuranceRequestAmt.toLocaleString()
				: undefined, // 고용보험
			employInsuranceApproveYn: item.employInsuranceApproveYn, // 고용보험
			employInsuranceRequestDate: dayjs(item.employInsuranceRequestDate).toDate(),
			healthInsuranceRequestAmt: item.healthInsuranceRequestAmt
				? item.healthInsuranceRequestAmt.toLocaleString()
				: undefined, // 건강보험
			healthInsuranceApproveYn: item.healthInsuranceApproveYn, // 건강보험
			healthInsuranceRequestDate: dayjs(item.healthInsuranceRequestDate).toDate(),
		},
	});

	const handleLocaleStringToNumber = (value?: string) => {
		if (!value || value === '') return undefined;
		return Number(value.replace(/,/g, ''));
	};
	const handleDateToString = (value?: Date) => {
		if (!value) return '';
		return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
	};

	const onSubmit = async (data: InsuranceAcquirementEnrollFormType) => {
		if (!centerId || !employeeId) return;

		const insuranceInfo = {
			...(data.accidentInsuranceApproveYn && {
				accidentInsuranceRequestDate: handleDateToString(data.accidentInsuranceRequestDate),
				accidentInsuranceRequestAmt: handleLocaleStringToNumber(data.accidentInsuranceRequestAmt),
				accidentInsuranceApproveYn: data.accidentInsuranceApproveYn,
			}),
			...(data.employInsuranceApproveYn && {
				employInsuranceRequestDate:
					data.employInsuranceRequestDate && handleDateToString(data.employInsuranceRequestDate),
				employInsuranceRequestAmt: handleLocaleStringToNumber(data.employInsuranceRequestAmt),
				employInsuranceApproveYn: data.employInsuranceApproveYn,
			}),
			...(data.healthInsuranceApproveYn && {
				healthInsuranceRequestDate:
					data.healthInsuranceRequestDate && handleDateToString(data.healthInsuranceRequestDate),
				healthInsuranceRequestAmt: handleLocaleStringToNumber(data.healthInsuranceRequestAmt),
				healthInsuranceApproveYn: data.healthInsuranceApproveYn,
			}),
			...(data.pensionInsuranceApproveYn && {
				pensionInsuranceRequestDate:
					data.pensionInsuranceRequestDate && handleDateToString(data.pensionInsuranceRequestDate),
				pensionInsuranceRequestAmt: handleLocaleStringToNumber(data.pensionInsuranceRequestAmt),
				pensionInsuranceApproveYn: data.pensionInsuranceApproveYn,
			}),
		};

		const param: {
			employeeId: number;
			params: CreateAcquirementInsuranceRequest;
		} = {
			employeeId,
			params: {
				centerId,
				insuranceRequestTypeCd: 'CMN073.10',
				weekPerWorkHourCnt: data.weekPerWorkHourCnt,
				dependents: data.dependents,
				insuranceRequestDetails: insuranceInfo,
				unemploymentBenefitPreconditionFullYn: data.employInsuranceApproveYn,
				requestDesc: '',
			},
		};

		createInsurance(param);
	};

	const insuranceTableInfoItem: InformationTableItemType[][] = [
		[
			{
				type: 'label',
				label: '구분',
				labelStyle: {
					width: '20rem',
					height: '4.1rem',
					verticalAlign: 'middle',
				},
			},
			{
				type: 'label',
				label: '신고 금액',
				labelStyle: {
					textAlign: 'right',
					height: '4.1rem',
					verticalAlign: 'middle',
				},
			},
			{
				type: 'label',
				label: '취득 일자',
				labelStyle: {
					height: '4.1rem',
					verticalAlign: 'middle',
				},
			},
		],
		[
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRCheckBox onChange={onChange} checked={value}>
								국민연금
							</CRCheckBox>
						)}
						name='pensionInsuranceApproveYn'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TableInput
								prefix=' '
								disabled={!watch('pensionInsuranceApproveYn')}
								type='comma'
								onChange={onChange}
								value={watch('pensionInsuranceApproveYn') ? value : undefined}
								placeholder='신고 금액 입력'
							/>
						)}
						name='pensionInsuranceRequestAmt'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.DatePicker
								type='S'
								onChangeValue={onChange}
								disabled={!watch('pensionInsuranceApproveYn')}
								value={watch('pensionInsuranceApproveYn') ? value : undefined}
								placeholder='취득 일자 입력'
							/>
						)}
						name='pensionInsuranceRequestDate'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
		],
		[
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRCheckBox onChange={onChange} checked={value}>
								건강보험
							</CRCheckBox>
						)}
						name='healthInsuranceApproveYn'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TableInput
								prefix=' '
								disabled={!watch('healthInsuranceApproveYn')}
								type='comma'
								onChange={onChange}
								value={watch('healthInsuranceApproveYn') ? value : undefined}
								placeholder='신고 금액 입력'
							/>
						)}
						name='healthInsuranceRequestAmt'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.DatePicker
								type='S'
								onChangeValue={onChange}
								disabled={!watch('healthInsuranceApproveYn')}
								value={watch('healthInsuranceApproveYn') ? value : undefined}
								placeholder='취득 일자 입력'
							/>
						)}
						name='healthInsuranceRequestDate'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
		],
		[
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRCheckBox onChange={onChange} checked={value}>
								고용보험
							</CRCheckBox>
						)}
						name='employInsuranceApproveYn'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TableInput
								prefix=' '
								disabled={!watch('employInsuranceApproveYn')}
								type='comma'
								onChange={onChange}
								value={watch('employInsuranceApproveYn') ? value : undefined}
								placeholder='신고 금액 입력'
							/>
						)}
						name='employInsuranceRequestAmt'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.DatePicker
								type='S'
								onChangeValue={onChange}
								disabled={!watch('employInsuranceApproveYn')}
								value={watch('employInsuranceApproveYn') ? value : undefined}
								placeholder='취득 일자 입력'
							/>
						)}
						name='employInsuranceRequestDate'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
		],
		[
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRCheckBox onChange={onChange} checked={value}>
								산재보험
							</CRCheckBox>
						)}
						name='accidentInsuranceApproveYn'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.TableInput
								prefix=' '
								disabled={!watch('accidentInsuranceApproveYn')}
								type='comma'
								onChange={onChange}
								value={watch('accidentInsuranceApproveYn') ? value : undefined}
								placeholder='신고 금액 입력'
							/>
						)}
						name='accidentInsuranceRequestAmt'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
			{
				type: 'value',
				value: (
					<Controller
						render={({ field: { onChange, value } }) => (
							<CRInput.DatePicker
								type='S'
								onChangeValue={onChange}
								disabled={!watch('accidentInsuranceApproveYn')}
								value={watch('accidentInsuranceApproveYn') ? value : undefined}
								placeholder='취득 일자 입력'
							/>
						)}
						name='accidentInsuranceRequestDate'
						control={control}
					/>
				),
				valueStyle: {
					verticalAlign: 'middle',
				},
			},
		],
	];

	return (
		<CRDialog
			title='4대보험 신고'
			onClickClose={hideDialog}
			type='M'
			body={
				<S.Container>
					<CRText typography='bodyB' text='신고 내용' />
					<CRInputLabel label='신고 선택' type='left-sub' isRequired betweenGap='2.4rem'>
						<InformationTable roundBorder items={insuranceTableInfoItem} />
					</CRInputLabel>
					<CRInputLabel label='주 소정 근로시간' type='left-sub' isRequired betweenGap='2.4rem'>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.Default
									type='number'
									value={value}
									onChange={onChange}
									placeholder='주 소정 근로시간 입력'
									suffix='시간'
								/>
							)}
							name='weekPerWorkHourCnt'
							control={control}
						/>
					</CRInputLabel>
					<CRInputLabel label='파일 첨부' type='left-sub' isRequired betweenGap='2.4rem'>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInput.FileUploader
									onChange={onChange}
									files={value}
									placeholder='파일 업로드'
									type='multiple'
								/>
							)}
							name='attatchFileDetails'
							control={control}
						/>
					</CRInputLabel>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={handleSubmit(onSubmit)}>
						신청
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}
