import 'react-toastify/dist/ReactToastify.css';

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import RouterPath from 'common/router';
import { Toast } from 'components/base/CRToast';
import { Sentry } from 'lib/third-party/sentry';
import { getValidParam, objectToQueryString } from 'lib/util';
import {
	CenterAccountDeleteRequest,
	CenterAccountDeleteResponse,
	CenterAccountManagementRequest,
	CenterAccountManagementResponse,
	CenterAccountSaveRequest,
	CenterAccountSaveResponse,
} from 'types/api/accountManagement';
import { AlarmListResponse, AlarmRequestDTO } from 'types/api/alarm';
import { MyAccountInfoResponse } from 'types/api/auth';
import { BaseResponse, GetMyFuncsData, GetMyFuncsResponse, ResponseCode } from 'types/api/base';
import {
	BaseCorporationListResponse,
	CenterListResponse,
	GuaranteedCenterListResponse,
} from 'types/api/center';
import {
	GetCenterAccountRecordSummaryRequestDTO,
	GetCenterAccountRecordTargetRequestDTO,
	GetCenterAccountRecordsDownloadRequest,
	GetCenterAccountRecordsDownloadResponse,
	GetCenterAccountRecordsResponse,
	GetCenterAccountRecordsSummaryResponse,
	GetCenterAccountResponse,
	UpdateCenterAccountChildRecordDetailRequestDTO,
	UpdateCenterAccountChildRecordDetailResponse,
	UpdateCenterAccountRecordDetailRequestDTO,
	UpdateCenterAccountRecordDetailResponse,
	UpdateCenterAccountRecordsResponse,
	getCenterAccountRecordsRequestDTO,
	updateCenterAccountRecordsRequestDTO,
} from 'types/api/centerAccount';
import {
	CheckSalaryDuplicatedPeriodRequest,
	CheckSalaryDuplicatedPeriodResponse,
	CheckSalaryDuplicatedRangeRequest,
	CheckSalaryDuplicatedRangeResponse,
	CreateCenterSalaryFeeInfoRequest,
	CreateCenterSalaryFeeInfoResponse,
	GetCenterSalaryFeeDetailRequest,
	GetCenterSalaryFeeDetailResponse,
	GetCenterSalaryFeeInfoRequest,
	GetCenterSalaryFeeInfoResponse,
	GetCurrentApplySalaryFeeInfoRequest,
	GetCurrentApplySalaryFeeInfoResponse,
	UpdateCenterSalaryFeeInfoRequest,
	UpdateCenterSalaryFeeInfoResponse,
} from 'types/api/centerFinanceInfo';
import {
	GetCenterChiefsResponse,
	GetCenterInfoRequest,
	GetCenterInfoResponse,
	GetCenterOfferServicesRequest,
	GetCenterOfferServicesResponse,
	SaveCenterInfoRequest,
	SaveCenterInfoResponse,
	UpdateCenterAccountInfoData,
	UpdateCenterAccountInfoRequest,
	UpdateCenterAccountInfoResponse,
} from 'types/api/centerInfo';
import {
	AutomationLastSyncRequest,
	AutomationLastSyncResponse,
	AutomationUploadRequestDTO,
	AutomationUploadResponse,
	CenterMemberSearchDTO,
	CenterMemberSearchRequest,
	CheckAccountOwnerRequest,
	CheckAccountOwnerResponse,
	CheckRealNameRequest,
	CheckRealNameResponse,
	CheckRecipientRsdnNData,
	CheckRecipientRsdnNResponse,
	CheckRecipientRsdnNoRequestDTO,
	CreateDownloadUrlRequestDTO,
	CreateRecipientData,
	CreateRecipientHistoryOfManagerRequestDTO,
	CreateRecipientHistoryOfManagerResponse,
	CreateRecipientHistoryOfOwnExpensResponse,
	CreateRecipientHistoryOfOwnExpenseRequestDTO,
	CreateRecipientRequestDTO,
	CreateRecipientResponse,
	CreateUploadUrlRequestDTO,
	CreateUploadUrlResponse,
	DeleteFileDetailRequestDTO,
	DeleteFileDetailResponse,
	DeleteRecipientHistoryOfOwnExpenseRequestDTO,
	DeleteRecipientHistoryOfOwnExpenseResponse,
	GetAllCenterMemberResponse,
	GetCenterManagersData,
	GetCenterManagersRequestDTO,
	GetCenterManagersResponse,
	GetCenterRecipientBaseRequest,
	GetCommonCentersResponse,
	GetCommonCodesRequestDTO,
	GetCommonCodesResponse,
	GetExternalCenterResponse,
	GetRecipientBaseData,
	GetRecipientBaseRequest,
	GetRecipientBaseResponse,
	GetRecipientHistoryOfLongTermGradeRequestDTO,
	GetRecipientHistoryOfLongTermGradeResponse,
	GetRecipientHistoryOfManagerRequestDTO,
	GetRecipientHistoryOfManagerResponse,
	GetRecipientHistoryOfOwnExpenseRequestDTO,
	GetRecipientHistoryOfOwnExpenseResponse,
	GetRecipientRequestDTO,
	GetRecipientResponse,
	GetRecipientsRequestDTO,
	GetRecipientsResponse,
	InquireIndividualInfoRequest,
	InquireIndividualInfoResponse,
	MyMenuInfoResponse,
	SendSlackRequest,
	UpdateAutomationUploadRequestDTO,
	UpdateAutomationUploadResponse,
	UpdateRecipientBaseData,
	UpdateRecipientBaseRequest,
	UpdateRecipientBaseResponse,
	UpdateRecipientBurdenAmtData,
	UpdateRecipientBurdenAmtRequest,
	UpdateRecipientBurdenAmtResponse,
	UpdateRecipientGuardiansData,
	UpdateRecipientGuardiansRequest,
	UpdateRecipientGuardiansResponse,
	UpdateRecipientHistoryOfOwnExpensResponse,
	UpdateRecipientHistoryOfOwnExpenseRequestDTO,
	UpdateRecipientInUseData,
	UpdateRecipientInUseRequest,
	UpdateRecipientInUseResponse,
	UpdateRecipientOtherServicesData,
	UpdateRecipientOtherServicesRequest,
	UpdateRecipientOtherServicesResponse,
	UpdateRecipientWaitingData,
	UpdateRecipientWaitingRequest,
	UpdateRecipientWaitingResponse,
	UploadFileRequestDTO,
} from 'types/api/common';
import {
	GetContractReviewListData,
	GetContractReviewListResponse,
	GetEmployeeContractReviewData,
	GetEmployeeContractReviewListRequest,
	GetEmployeeContractReviewResponse,
	GetRecipientContractReviewListRequest,
	SaveContractReviewRequest,
	SaveContractReviewResponse,
} from 'types/api/contractReview';
import {
	DeleteMyCenterDocsRequest,
	DeleteMyCenterDocsResponse,
	MyCenterDocsRequest,
	MyCenterDocsResponse,
	UpdateMyCenterDocsRequest,
	UpdateMyCenterDocsResponse,
} from 'types/api/document';
import {
	ConfirmEDocRequest,
	ConfirmEDocResponse,
	ConfirmRecipientEdocAdmissionUseRequest,
	ConfirmRecipientEdocAdmissionUseResponse,
	CreateEDocCreateResponse,
	CreateEDocPaperRequest,
	CreateHomeCarePaperRequest,
	DeletePaperRequestDTO,
	DeletePaperResponse,
	EDocBeforeRequest,
	EDocClientConfirmRequestDTO,
	EDocClientConfirmResponse,
	EDocClientOpenRequestDTO,
	EDocClientOpenResponse,
	EDocManagerConfirmRequestDTO,
	EDocManagerConfirmResponse,
	EDocManagerOpenRequestDTO,
	EDocManagerOpenResponse,
	EDocPaperTypeResponse,
	EDocPaperTypesRequest,
	EDocPreviewRequest,
	EDocPreviewResponse,
	EDocRequest,
	EmployeeEDocListRequest,
	EmployeeEDocListResponse,
	EmploymentCertificationIssueInfoResponse,
	ExportEformToPdfRequest,
	GetEFormFileNameRequest,
	GetEFormFileNameResponse,
	GetRecipientEdocAdmissionUseRequest,
	GetRecipientEdocAdmissionUseResponse,
	IssueEDocumentRequest,
	IssueEDocumentResponse,
	OpenEDocRequest,
	OpenEDocResponse,
	RecipientEDocListRequest,
	RecipientEDocListResponse,
	ResendEDocRequest,
	ResendEDocResponse,
	SaveRecordSheetClientRequestParams,
	SaveRecordSheetClientResponse,
} from 'types/api/eDoc';
import {
	EmployReportRequest,
	GetEmployReportData,
	GetEmployReportRequest,
	GetEmployReportResponse,
} from 'types/api/employReport';
import {
	CreateEmployeeRequestDTO,
	CreateEmployeeResponse,
	DeleteEmployeeCareerRequestDTO,
	DeleteEmployeeCareerResponse,
	DeleteEmployeeEducationRequestDTO,
	DeleteEmployeeEducationResponse,
	DeleteEmployeeHealthCheckRequestDTO,
	DeleteEmployeeHealthCheckResponse,
	DeleteEmployeeLicenseRequestDTO,
	DeleteEmployeeLicenseResponse,
	EmployeeCareerInfoRequestDTO,
	EmployeeCareerInfoResponse,
	EmployeeCheckDuplicateRequest,
	EmployeeCheckDuplicateResponse,
	EmployeeEduInfoRequestDTO,
	EmployeeEduInfoResponse,
	EmployeeHealthCheckRequestDTO,
	EmployeeHealthCheckResponse,
	EmployeeHistoryOfManagerRequestDTO,
	EmployeeHistoryOfManagerResponse,
	EmployeeLicenseInfoRequestDTO,
	EmployeeLicenseInfoResponse,
	EmployeeResignInfoRequest,
	EmployeeResignInfoResponse,
	EmployeeSalarysRequest,
	EmployeeSalarysResponse,
	GetEmployeeBaseInfoRequestDTO,
	GetEmployeeBaseInfoResponse,
	GetEmployeeDetailInfoRequestDTO,
	GetEmployeeDetailInfoResponse,
	GetEmployeeWorkInfoListData,
	GetEmployeeWorkInfoListRequest,
	GetEmployeeWorkInfoListResponse,
	GetEmployeeWorkScheduleData,
	GetEmployeeWorkScheduleRequest,
	GetEmployeeWorkScheduleResponse,
	GetEmployeesExcelRequest,
	GetEmployeesExcelResponse,
	GetEmployeesRequestDTO,
	GetEmployeesResponse,
	ResignEmployeeRequest,
	ResignEmployeeResponse,
	SaveEmployeeBaseInfoRequestDTO,
	SaveEmployeeBaseInfoResponse,
	SaveEmployeeCareerRequestDTO,
	SaveEmployeeCareerResponse,
	SaveEmployeeEducationRequestDTO,
	SaveEmployeeEducationResponse,
	SaveEmployeeHealthCheckRequestDTO,
	SaveEmployeeHealthCheckResponse,
	SaveEmployeeHistoryOfManagerRequestDTO,
	SaveEmployeeHistoryOfManagerResponse,
	SaveEmployeeLicenseRequestDTO,
	SaveEmployeeLicenseResponse,
	SaveEmployeeOtherCorpWorkRequestDTO,
	SaveEmployeeOtherCorpWorkResponse,
	SaveEmployeePayInfoRequestDTO,
	SaveEmployeePayInfoResponse,
	SaveEmployeeWorkStateRequestDTO,
	SaveEmployeeWorkStateResponse,
	UpdateEmployeeAffiliationRequest,
	UpdateEmployeeAffiliationResponse,
	UpdateEmployeeTagCallRequest,
	UpdateEmployeeTagCallResponse,
} from 'types/api/employee';
import { UploadBurdenRequestDTO, UploadChargeRequestDTO } from 'types/api/excelUpload';
import {
	CancelMovingFullTimeEmployeeCenterRequest,
	CancelMovingFullTimeEmployeeCenterResponse,
	CheckFullTimeEmployeeDeletableRequest,
	CheckFullTimeEmployeeDeletableResponse,
	CheckFullTimeEmployeeDuplicationRequest,
	CheckFullTimeEmployeeDuplicationResponse,
	CreateFullTimeEmployeeRequest,
	CreateFullTimeEmployeeResponse,
	DeleteFullTimeEmployeeDutyRequest,
	DeleteFullTimeEmployeeDutyResponse,
	DeleteFullTimeEmployeeEducationRequest,
	DeleteFullTimeEmployeeEducationResponse,
	DeleteFullTimeEmployeeHealthCheckRequest,
	DeleteFullTimeEmployeeHealthCheckResponse,
	DeleteFullTimeEmployeeLicenseRequest,
	DeleteFullTimeEmployeeLicenseResponse,
	DeleteFullTimeEmployeeRequest,
	DeleteFullTimeEmployeeResponse,
	DeleteFullTimeEmployeeWorkStateRequest,
	DeleteFullTimeEmployeeWorkStateResponse,
	GetFullTimeEmployeeAnnualTOGenerateAdjustRequest,
	GetFullTimeEmployeeAnnualTOGenerateAdjustResponse,
	GetFullTimeEmployeeAnnualTOHistoriesRequest,
	GetFullTimeEmployeeAnnualTOHistoriesResponse,
	GetFullTimeEmployeeAnnualTORemainAdjustRequest,
	GetFullTimeEmployeeAnnualTORemainAdjustResponse,
	GetFullTimeEmployeeAnnualTOWorkYearCntAdjustRequest,
	GetFullTimeEmployeeAnnualTOWorkYearCntAdjustResponse,
	GetFullTimeEmployeeBaseInfoRequest,
	GetFullTimeEmployeeBaseInfoResponse,
	GetFullTimeEmployeeCrimeHistoriesRequest,
	GetFullTimeEmployeeCrimeHistoriesResponse,
	GetFullTimeEmployeeDementiaEducationsRequest,
	GetFullTimeEmployeeDementiaEducationsResponse,
	GetFullTimeEmployeeDetailInfoRequest,
	GetFullTimeEmployeeDetailInfoResponse,
	GetFullTimeEmployeeDutiesRequest,
	GetFullTimeEmployeeDutiesResponse,
	GetFullTimeEmployeeEducationsRequest,
	GetFullTimeEmployeeEducationsResponse,
	GetFullTimeEmployeeFinalWorkLeaveRequest,
	GetFullTimeEmployeeFinalWorkLeaveResponse,
	GetFullTimeEmployeeHealthCheckRequest,
	GetFullTimeEmployeeHealthCheckResponse,
	GetFullTimeEmployeeLegalEducationsRequest,
	GetFullTimeEmployeeLegalEducationsResponse,
	GetFullTimeEmployeeLicensesRequest,
	GetFullTimeEmployeeLicensesResponse,
	GetFullTimeEmployeeResignRequest,
	GetFullTimeEmployeeResignResponse,
	GetFullTimeEmployeeWorkDetailsRequest,
	GetFullTimeEmployeeWorkDetailsResponse,
	GetFullTimeEmployeeWorkStatesRequest,
	GetFullTimeEmployeeWorkStatesResponse,
	GetFullTimeEmployeesRequest,
	GetFullTimeEmployeesResponse,
	MoveFullTimeEmployeeCenterRequest,
	MoveFullTimeEmployeeCenterResponse,
	ResignFullTimeEmployeeRequest,
	ResignFullTimeEmployeeResponse,
	SaveFullTimeEmployeeAnnualTOGenerateAdjustRequest,
	SaveFullTimeEmployeeAnnualTOGenerateAdjustResponse,
	SaveFullTimeEmployeeAnnualTORemainAdjustRequest,
	SaveFullTimeEmployeeAnnualTORemainAdjustResponse,
	SaveFullTimeEmployeeAnnualTOWorkYearCntAdjustRequest,
	SaveFullTimeEmployeeAnnualTOWorkYearCntAdjustResponse,
	SaveFullTimeEmployeeCrimeHistoryRequest,
	SaveFullTimeEmployeeCrimeHistoryResponse,
	SaveFullTimeEmployeeDementiaEducationRequest,
	SaveFullTimeEmployeeDementiaEducationResponse,
	SaveFullTimeEmployeeDutyRequest,
	SaveFullTimeEmployeeDutyResponse,
	SaveFullTimeEmployeeEducationRequest,
	SaveFullTimeEmployeeEducationResponse,
	SaveFullTimeEmployeeHealthCheckRequest,
	SaveFullTimeEmployeeHealthCheckResponse,
	SaveFullTimeEmployeeLicenseRequest,
	SaveFullTimeEmployeeLicenseResponse,
	SaveFullTimeEmployeeWorkStateRequest,
	SaveFullTimeEmployeeWorkStateResponse,
	UpdateFullTimeEmployeeDetailInfoRequest,
	UpdateFullTimeEmployeeDetailInfoResponse,
	updateFullTimeEmployeeAnnualTOHistoriesRequest,
	updateFullTimeEmployeeAnnualTOHistoriesResponse,
} from 'types/api/full-time-employee';
import {
	CheckInsuranceRequiredEDocRequest,
	CheckInsuranceRequiredEDocResponse,
	CreateAcquirementInsuranceListRequest,
	CreateInsuranceRequest,
	CreateInsuranceResponse,
	EmployeeInsuranceRequest,
	EmployeeInsuranceResponse,
	InsuranceAcqExcelDownloadRequest,
	InsuranceAcqExcelDownloadResponse,
	InsuranceDetailRequest,
	InsuranceDetailResponse,
	InsuranceEtcExcelDownloadRequest,
	InsuranceEtcExcelDownloadResponse,
	InsuranceLossExcelDownloadRequest,
	InsuranceLossExcelDownloadResponse,
	InsurancePriorInfoRequest,
	InsurancePriorInfoResponse,
	InsuranceRequest,
	InsuranceResponse,
	InsuranceSalaryFeeInfoRequest,
	InsuranceSalaryFeeInfoResponse,
	UpdateInsuranceDetailRequest,
	UpdateInsuranceDetailResponse,
	UpdateInsuranceRequestStateRequest,
	UpdateInsuranceRequestStateResponse,
} from 'types/api/insurance';
import {
	IDValidatorRequest,
	IDValidatorResponse,
	NewPasswordRequest,
	NewPasswordResponse,
	SignInRequest,
	SignInResponse,
	SignUpRequest,
	SignUpResponse,
} from 'types/api/memberAcount';
import {
	CreateTaskRequestDTO,
	CreateTaskResponse,
	MySubTaskRequestDTO,
	MySubTaskResponse,
	MyTaskCountResponse,
	MyTaskRequestDTO,
	MyTaskResponse,
	MyTaskTypesResponse,
	NewSubTaskRequestDTO,
	NewSubTaskResponse,
	NewTaskTypesResponse,
} from 'types/api/myTask';
import {
	GetCustomerCenterManagersData,
	GetCustomerCenterManagersRequest,
	GetCustomerCenterManagersResponse,
	GetCustomerChiefsData,
	GetCustomerChiefsResponse,
	GetCustomerClientsData,
	GetCustomerClientsRequest,
	GetCustomerClientsResponse,
	GetCustomerEmployeesData,
	GetCustomerEmployeesRequest,
	GetCustomerEmployeesResponse,
	GetCustomerSelectBoxCommonCodesData,
	GetCustomerSelectBoxCommonCodesRequest,
	GetCustomerSelectBoxCommonCodesResponse,
} from 'types/api/openAPi';
import {
	CheckLongTermDuplicationRequest,
	CheckLongTermDuplicationResponse,
	CreateRecipientHoldingRequest,
	CreateRecipientHoldingResponse,
	DeleteRecipientHoldingRequest,
	DeleteRecipientHoldingResponse,
	GetGenerateIntegratedAssessmentOpinionParams,
	GetRecipientBurdensRequest,
	GetRecipientBurdensResponse,
	GetRecipientConsultClientDTO,
	GetRecipientConsultClientRequest,
	GetRecipientConsultClientResponse,
	GetRecipientHoldingHistoriesRequest,
	GetRecipientHoldingHistoriesResponse,
	GetRecipientsExcelRequest,
	GetRecipientsExcelResponse,
	GetWorkExecuteLogSalaryOfferPlanRequest,
	GetWorkExecuteLogSalaryOfferPlanResponse,
	RecipientCheckDuplicateDTO,
	RecipientCheckDuplicateRequest,
	RecipientCheckDuplicateResponse,
	RecipientServiceRequest,
	RecipientServiceResponse,
	ResignRecipientRequest,
	ResignRecipientResponse,
	UpdateRecipientAffiliationRequest,
	UpdateRecipientAffiliationResponse,
	UpdateRecipientHoldingRequest,
	UpdateRecipientHoldingResponse,
	UpdateRecipientStateRequest,
	UpdateRecipientStateResponse,
	UpdateScheduleAutomationRequest,
	UpdateScheduleAutomationResponse,
} from 'types/api/recipient';
import {
	CompleteEmployeeSalaryRequestDTO,
	CompleteEmployeeSalaryResponse,
	ConfirmAllEmployeeSalaryRequest,
	ConfirmAllEmployeeSalaryResponse,
	DeleteEmployeeSalaryRequest,
	DeleteEmployeeSalaryResponse,
	DeleteEmployeeSalaryScheduleRequest,
	DeleteEmployeeSalaryScheduleResponse,
	EmployeeSalarysPayrollDTO,
	EmployeeSalarysPayrollResponse,
	EmployeeSalarysTransferDTO,
	EmployeeSalarysTransferResponse,
	RecalculateAllEmployeeSalaryRequest,
	RecalculateAllEmployeeSalaryResponse,
	SalarysAutoBillingRequestDTO,
	SalarysAutoBillingResponse,
	SalarysEmployeeSalaryDetailRequestDTO,
	SalarysEmployeeSalaryDetailResponse,
	SalarysEmployeeSalaryListRequestDTO,
	SalarysEmployeeSalaryListResponse,
	SalarysEmployeeSalarysContent,
	SendCashReceiveRequestDTO,
	SendCashReceiveResponse,
	SendEmployeeSalaryRequestDTO,
	SendEmployeeSalaryResponse,
	UpdateEmployeeSalaryAfterSyncRequest,
	UpdateEmployeeSalaryAfterSyncResponse,
	UpdateSalaryWorkSchedulesRequest,
	UpdateSalaryWorkSchedulesResponse,
	UpdateSalarysCalculateRequestDTO,
	UpdateSalarysCalculateResponse,
	UpdateSalarysDetailResponse,
} from 'types/api/salary';
import {
	ClientScheduleRequestDTO,
	DownloadExcelManagerTagHistoryRequest,
	EmployeeScheduleDetailRequestDTO,
	EmployeeScheduleDetailResponse,
	GetAnnualTOHistoriesDownloadRequest,
	GetAnnualTOHistoriesDownloadResponse,
	GetAnnualTOHistoriesRequest,
	GetAnnualTOHistoriesResponse,
	GetAnnualTOUsageHistoriesDownloadRequest,
	GetAnnualTOUsageHistoriesDownloadResponse,
	GetAnnualTOUsageHistoriesRequest,
	GetAnnualTOUsageHistoriesResponse,
	GetFtimeEmployeeMonthScheduleResponse,
	GetFtimeEmployeeWorkScheduleRequest,
	GetFtimeEmployeeWorkScheduleResponse,
	GetManagerTagHistoryRequest,
	GetManagerTagHistoryResponse,
	RecipientScheduleDetailRequestDTO,
	RecipientScheduleDetailResponse,
	RecordingSheetRequest,
	RecordingSheetResponse,
	SaveEmployeeScheduleDetailResponse,
	SaveRecipientScheduleDetailResponse,
	SaveScheduleDetailRequestDTO,
	ScheduleAutomationRequest,
	ScheduleAutomationResponse,
	ScheduleRequestDTO,
	ScheduleResponse,
	ScheduleTagAutomationHistoryRequest,
	ScheduleTagAutomationHistoryResponse,
	ScheduleTagErrorHistoryRequest,
	ScheduleTagErrorHistoryResponse,
	SendRecordingSheetRequestDTO,
	SendRecordingSheetResponse,
	SendScheduleDetailRequest,
	SendScheduleDetailResponse,
	TagErrorAutomationRequest,
	TagErrorAutomationResponse,
	UpdateManagerTagHistoryRequest,
	UpdateManagerTagHistoryResponse,
	UpdateScheduleTagErrorHistoryDetailRequest,
	UpdateScheduleTagErrorHistoryDetailResponse,
} from 'types/api/schedule';
import {
	CancelEDocRequest,
	CancelEDocResponse,
	CancelReservationMessageRequest,
	CancelReservationMessageResponse,
	ESignHistoryDetailRequest,
	ESignHistoryDetailResponse,
	ESignHistoryFilterResponse,
	ESignHistoryRequest,
	ESignHistoryResponse,
	EmployeeSendHistoryDetailRequest,
	EmployeeSendHistoryDetailResponse,
	GetReservationSendDetailRequest,
	GetSendHistoryDetailRequest,
	GetSendHistoryRequest,
	GetSendReservationHistoryRequest,
	MessageTabESignHistoryRequest,
	MessageTabESignHistoryResponse,
	MessageTabReservationHistoryRequest,
	MessageTabReservationHistoryResponse,
	MessageTabSendHistoryRequest,
	MessageTabSendHistoryResponse,
	RecipientSendHistoryDetailResponse,
	RecipientSendHistoryTabDetailRequest,
	ResendFailMessageRequest,
	ResendFailMessageResponse,
	ResendMessageRequest,
	ResendMessageResponse,
	ReservationSendDetailResponse,
	SendHistoryDetailResponse,
	SendHistoryResponse,
	SendMessageRequest,
	SendMessageResponse,
	SendReservationHistoryResponse,
	SendTargetListRequestDTO,
	SendTargetListResponse,
	SendTemplateListResponse,
	UpdateReservationMessageResponse,
	UpdateSignDueDateRequest,
	UpdateSignDueDateResponse,
} from 'types/api/send';
import {
	AccountRolesDTO,
	AccountRolesResponse,
	GetLinkAccountsRequest,
	GetLinkAccountsResponse,
	LinkAccountsRequest,
	LinkAccountsResponse,
	MyCenterAccountsRequest,
	MyCenterAccountsResponse,
	UserAccountActivateRequest,
	UserAccountActivateResponse,
} from 'types/api/userAccount';
import {
	DeleteWorkExecuteLogRequest,
	DeleteWorkExecuteLogResponse,
	GetRecipientWorkExecuteLogNewRequest,
	GetRecipientWorkExecuteLogNewResponse,
	GetWorkExecuteInspectionLogData,
	GetWorkExecuteInspectionLogRequest,
	GetWorkExecuteInspectionLogResponse,
	GetWorkExecuteLogDetailRequest,
	GetWorkExecuteLogDetailResponse,
	GetWorkExecuteLogRequest,
	GetWorkExecuteLogResponse,
	GetWorkExecuteLogServiceSchedulePlanRequest,
	GetWorkExecuteLogServiceSchedulePlanResponse,
	UpdateRecipientWorkExecuteLogRequest,
	UpdateRecipientWorkExecuteLogResponse,
	UpdateWorkExecuteInspectionLogRequest,
	UpdateWorkExecuteInspectionLogResponse,
	WorkExecuteLogIssueEdocRequest,
	WorkExecuteLogIssueEdocResponse,
} from 'types/api/workExecuteLog';

import {
	CancelRecipientIntegratedAssessmentData,
	CancelRecipientIntegratedAssessmentRequest,
	CancelRecipientIntegratedAssessmentResponse,
	ConfirmRecipientIntegratedAssessmentData,
	ConfirmRecipientIntegratedAssessmentRequest,
	ConfirmRecipientIntegratedAssessmentResponse,
	CreateRecipientIntegratedAssessmentData,
	CreateRecipientIntegratedAssessmentRequest,
	CreateRecipientIntegratedAssessmentResponse,
	CreateRecipientIntegratedAssessmentSalaryOfferChangePlanDetailRequest,
	CreateRecipientIntegratedAssessmentSalaryOfferChangePlanDetailResponse,
	DeleteEmployeeActualWorkLogRequest,
	DeleteEmployeeActualWorkLogResponse,
	DeleteEmployeeDefaultWorkTimeData,
	DeleteEmployeeDefaultWorkTimeRequest,
	DeleteEmployeeDefaultWorkTimeResponse,
	DeleteEmployeeVacationRequest,
	DeleteEmployeeVacationResponse,
	DeleteEmployeeWorkHistoryRequest,
	DeleteEmployeeWorkHistoryResponse,
	DeleteRecipientIntegratedAssessmentRequest,
	DeleteRecipientIntegratedAssessmentResponse,
	DeleteRecipientIntegratedAssessmentSalaryOfferChangePlanDetailRequest,
	DeleteRecipientIntegratedAssessmentSalaryOfferChangePlanDetailResponse,
	DeleteRecipientIntegratedAssessmentSalaryOfferRequest,
	DeleteRecipientIntegratedAssessmentSalaryOfferResponse,
	FamilyCareRoundingHistoryResponseData,
	GetCenterDefaultWorkTimeRequest,
	GetCenterDefaultWorkTimeResponse,
	GetCenterWorkScheduleStatusRequest,
	GetCenterWorkScheduleStatusResponse,
	GetEmployeeActualWorkLogDownloadListRequest,
	GetEmployeeActualWorkLogDownloadListResponse,
	GetEmployeeDefaultWorkTimeListRequest,
	GetEmployeeDefaultWorkTimeListResponse,
	GetEmployeeWorkHistoryRequest,
	GetEmployeeWorkHistoryResponse,
	GetPcorpWorkScheduleRequest,
	GetPcorpWorkScheduleResponse,
	GetRecipientIntegratedAssessmentPreviousDataRequest,
	GetRecipientIntegratedAssessmentPreviousDataResponse,
	GetRecipientIntegratedAssessmentPreviousListRequest,
	GetRecipientIntegratedAssessmentPreviousListResponse,
	GetRecipientIntegratedAssessmentRequest,
	GetRecipientIntegratedAssessmentResponse,
	GetRecipientIntegratedAssessmentSalaryOfferChangePlanDetailRequest,
	GetRecipientIntegratedAssessmentSalaryOfferChangePlanDetailResponse,
	GetRecipientIntegratedAssessmentSalaryOfferChangePlanListRequest,
	GetRecipientIntegratedAssessmentSalaryOfferChangePlanListResponse,
	GetRecipientIntegratedAssessmentSalaryOfferSendPreviewRequest,
	GetRecipientIntegratedAssessmentSalaryOfferSendPreviewResponse,
	GetRecipientIntegratedAssessmentSalaryOfferSendRequest,
	GetRecipientIntegratedAssessmentSalaryOfferSendResponse,
	GetRecipientIntegratedAssessmentScoreRequest,
	GetRecipientIntegratedAssessmentScoreResponse,
	GetRecipientIntegratedAssessmentUpdateFormRequest,
	GetRecipientIntegratedAssessmentUpdateFormResponse,
	GetRoundingCompletionStatusRequest,
	GetRoundingCompletionStatusResponse,
	MonitoringDetailsRequest,
	MonitoringDetailsResponse,
	MonitoringResponse,
	MonitoringSchedulesRequest,
	MonitoringSyncDateRequest,
	MonitoringSyncDateResponse,
	MonitoringSyncHistoryRequest,
	MonitoringSyncHistoryResponse,
	MonotoringDetailItemRequest,
	MonotoringDetailItemResponse,
	RegistryDefaultWorkTimeRequest,
	RoundingHistoryRequest,
	SaveCenterDefualtWorkTimeRequest,
	SaveCenterDefualtWorkTimeResponse,
	SaveEmployeeActualWorkLogRequestBody,
	SaveEmployeeActualWorkLogResponse,
	SaveEmployeeDefaultWorkTimeRequest,
	SaveEmployeeDefaultWorkTimeResponse,
	SaveEmployeeMonthMaxOverworkTimeRequest,
	SaveEmployeeMonthMaxOverworkTimeResponse,
	SaveEmployeeVacationRequest,
	SaveEmployeeVacationResponse,
	SaveEmployeeWorkHistoryRequest,
	SaveEmployeeWorkHistoryResponse,
	SaveEmployeeWorkScheduleRequestBody,
	SaveEmployeeWorkScheduleResponse,
	SaveScheduleMonitoringDetailRequest,
	SaveScheduleMonitoringDetailResponse,
	SyncMonitoringDataRequest,
	SyncMonitoringDataResponse,
	SyncMonitoringIndividualRequest,
	SyncMonitoringIndividualResponse,
	SyncPCorpRequest,
	SyncPCorpResponse,
	SyncRoundingManagerRfidTagRequest,
	SyncRoundingManagerRfidTagResponse,
	UpdateMonitoringCompleteStatusRequest,
	UpdateMonitoringCompleteStatusResponse,
	UpdateMonitoringDetailItemCompleteStatusRequest,
	UpdateMonitoringDetailItemCompleteStatusResponse,
	UpdateMonitoringDetailsCompleteStatusRequest,
	UpdateMonitoringDetailsCompleteStatusResponse,
	UpdateRecipientIntegratedAssessmentRequest,
	UpdateRecipientIntegratedAssessmentResponse,
	UpdateRecipientIntegratedAssessmentSalaryOfferChangePlanDetailRequest,
	UpdateRecipientIntegratedAssessmentSalaryOfferChangePlanDetailResponse,
	UpdateRecipientIntegratedSalaryOfferRequest,
	UpdateRecipientIntegratedSalaryOfferResponse,
	UpdateWorkHistoryConfirmRequest,
	UpdateWorkHistoryConfirmResponse,
	UpdateWorkStatusRequest,
	UpdateWorkStatusResponse,
	VisitBathRoundingHistoryResponse,
	VisitBathRoundingHistoryResponseData,
	VisitCareRoundingHistoryResponse,
	VisitCareRoundingHistoryResponseData,
} from '../../../types/api';
import {
	CreateAdviceRequest,
	CreateAdviceResponse,
	DeleteAdviceRequest,
	DeleteAdviceResponse,
	GetAdviceClientOptionsData,
	GetAdviceClientOptionsRequest,
	GetAdviceClientOptionsResponse,
	GetAdviceData,
	GetAdviceMethodsData,
	GetAdviceMethodsResponse,
	GetAdviceRequest,
	GetAdviceResponse,
	GetAdviceTagsData,
	GetAdviceTagsResponse,
	GetEmployeeAdviceData,
	GetEmployeeAdviceRequest,
	GetEmployeeAdviceResponse,
	GetEmployeeAdvicesData,
	GetEmployeeAdvicesRequest,
	GetEmployeeAdvicesResponse,
	GetRecipientAdviceData,
	GetRecipientAdviceRequest,
	GetRecipientAdviceResponse,
	GetRecipientAdvicesData,
	GetRecipientAdvicesRequest,
	GetRecipientAdvicesResponse,
	GetRecipientGuardianAdviceData,
	GetRecipientGuardianAdviceRequest,
	GetRecipientGuardianAdviceResponse,
	UpdateAdviceData,
	UpdateAdviceRequest,
	UpdateAdviceResponse,
} from '../../../types/api/advice';
import {
	ChangeContractStatusRequest,
	ChangeContractStatusResponse,
	CreateChangeEmployeeContractRequest,
	CreateChangeEmployeeContractResponse,
	CreateContractRequest,
	CreateContractResponse,
	CreateEmployeeDocumentRequest,
	CreateEmployeeDocumentResponse,
	CreateRecipientDocumentRequest,
	CreateRecipientDocumentResponse,
	CreateRecipientRenewalDocumentRequest,
	CreateRecipientRenewalDocumentResponse,
	CreateRenewalRecipientContractRequest,
	CreateRenewalRecipientContractResponse,
	DeleteContractRequest,
	DeleteContractResponse,
	GetContractChangeEmployeeDetailRequest,
	GetContractChangeEmployeeDetailResponse,
	GetContractDetailRequest,
	GetContractDetailResponse,
	GetContractDocumentRequest,
	GetContractDocumentResponse,
	GetContractRenewalDetailRequest,
	GetContractRenewalDetailResponse,
	GetContractRequest,
	GetContractResponse,
	GetContractSendStatusRequest,
	GetContractSendStatusResponse,
	GetDocumentTemplateResponse,
	GetEmployeeContractRequest,
	GetEmployeeContractResponse,
	GetEmployeeDocumentRequest,
	GetEmployeeDocumentResponse,
	GetEmployeeWageRequest,
	GetEmployeeWageResponse,
	GetRecipientContractRequest,
	GetRecipientContractResponse,
	GetRecipientDocumentRequest,
	GetRecipientDocumentResponse,
	GetRecipientRenewalDocumentRequest,
	GetRecipientRenewalDocumentResponse,
	GetServiceEmployeeRequest,
	GetServiceEmployeeResponse,
	GetServiceTerminationInfoRequest,
	GetServiceTerminationInfoResponse,
	GetWageDeductionEmployeesRequest,
	GetWageDeductionEmployeesResponse,
	RenewalRecipientContractRequest,
	RenewalRecipientContractResponse,
	SaveChangeEmployeeContractRequest,
	SaveChangeEmployeeContractResponse,
	SaveCompleteContractRequest,
	SaveCompleteContractResponse,
	SaveTempContractRequest,
	SaveTempContractResponse,
	SendEmployeeContractRequest,
	SendEmployeeContractResponse,
	SendRecipientContractRenewalRequest,
	SendRecipientContractRenewalResponse,
	SendRecipientContractRequest,
	SendRecipientContractResponse,
	ServiceTerminationRequest,
	ServiceTerminationResponse,
	UpdateContractStateData,
	UpdateContractStateRequest,
	UpdateContractStateResponse,
	UpdateRenewalContractStateData,
	UpdateRenewalContractStateRequest,
	UploadEmployeeDocumentRequest,
	UploadEmployeeDocumentResponse,
	UploadRecipientDocumentRequest,
	UploadRecipientDocumentResponse,
	UploadRecipientRenewalDocumentRequest,
	UploadRecipientRenewalDocumentResponse,
	UploadVisitNursingRequest,
	UploadVisitNursingResponse,
} from '../../../types/api/contract';
import {
	CancelCashReceiptDetailData,
	CancelCashReceiptDetailRequest,
	CancelCashReceiptDetailResponse,
	DeleteBurdenChargesCorrectionsRequest,
	DeleteBurdenChargesCorrectionsResponse,
	GetBurdenChargesCorrectionsRequest,
	GetBurdenChargesCorrectionsResponse,
	GetBurdenChargesDetailRequest,
	GetBurdenChargesDetailResponse,
	GetBurdenPayHistoriesData,
	GetBurdenPayHistoriesRequest,
	GetBurdenPayHistoriesResponse,
	GetBurdenPayHistoryDetailData,
	GetBurdenPayHistoryDetailRequest,
	GetBurdenPayHistoryDetailResponse,
	GetMonthlyBurdenChargesData,
	GetMonthlyBurdenChargesDetailData,
	GetMonthlyBurdenChargesDetailRequest,
	GetMonthlyBurdenChargesDetailResponse,
	GetMonthlyBurdenChargesRequest,
	GetMonthlyBurdenChargesResponse,
	GetMonthlyBurdenChargesSummaryData,
	GetMonthlyBurdenChargesSummaryRequest,
	GetMonthlyBurdenChargesSummaryResponse,
	IssueCashReceiptDetailData,
	IssueCashReceiptDetailRequest,
	IssueCashReceiptDetailResponse,
	SendBurdenChargeRequestDTO,
	SendBurdenChargeResponse,
	UploadMonthlyBurdenChargesRequest,
	UploadMonthlyBurdenChargesResponse,
	UpsertBurdenChargesCorrectionsRequest,
	UpsertBurdenChargesCorrectionsResponse,
} from '../../../types/api/ownExpense';
import {
	CorporationDTO,
	IntegratedAssessmentDTO,
	IntegratedAssessmentFormDTO,
	IntegratedAssessmentPreviousDataDTO,
	IntegratedAssessmentPreviousListDataDTO,
	IntegratedAssessmentScoreDTO,
} from '../../../types/dto';
import { checkEmptyValues } from '../../util/form';
import IApi from './IApi';
import { endpoint } from './endpoint';

export default class DevApi implements IApi {
	client: AxiosInstance;

	eDocClient?: AxiosInstance;

	holidayClient?: AxiosInstance;

	constructor(config?: AxiosRequestConfig<any>) {
		this.client = axios.create({
			baseURL: process.env.REACT_APP_BASE_URL,
			timeout: 60 * 1000, // 타임아웃임시로 수정
			...config,
			headers: {
				'Content-Type': 'application/json',
				'ngrok-skip-browser-warning': 'true',
				...config?.headers,
			},
		});

		this.client.defaults.paramsSerializer = (paramObj) => {
			const params = new URLSearchParams();

			Object.keys(paramObj).forEach((key) => {
				params.append(key, paramObj[key]);
			});

			return params.toString();
		};

		this.holidayClient = axios.create({
			baseURL: process.env.REACT_APP_PUBLIC_HOLIDAY_URL,
			timeout: 10 * 1000,
			...config,
		});

		this.client.interceptors.request.use(
			(config) => {
				const autoSignIn = localStorage.getItem('autoSignIn');
				const localToken = localStorage.getItem('access_token');

				if (autoSignIn === 'true' && localToken) sessionStorage.setItem('access_token', localToken);

				const token = sessionStorage.getItem('access_token');

				if (token) {
					config.headers.set('Authorization', `Bearer ${token}`);
				}
				return config;
			},
			(error) => Promise.reject(error),
		);

		this.client.interceptors.response.use(
			(res) => {
				if (res?.data.code !== ResponseCode.SUCCESS) {
					// 응답값이 1로만 오는 케이스가 있음
					if (res?.data === 1) {
						return res;
					}
					if (res.data.code === ResponseCode.ERROR) {
						Sentry.serverError({ response: res, ...res } as any);
					} else {
						Sentry.apiError(res);
					}
				}
				return res;
			},
			(error) => {
				// axios 타임아웃에러
				if (error?.code === 'ECONNABORTED') {
					Toast.error('인터넷 연결이 원할하지 않습니다.', {
						toastId: '인터넷 연결이 원할하지 않습니다.',
					});
					return Promise.reject(error);
				}

				// 미인증 상태 처리.
				if (error?.response?.data?.code === 401) {
					localStorage.removeItem('access_token');
					sessionStorage.removeItem('access_token');
					window.location.href = `${window.location.origin}${RouterPath.signIn()}`;
					return Promise.reject(error);
				}

				// 권한없음은 별다른 처리 하지않음.
				if (error?.response?.data?.code === 403) {
					return Promise.reject(error);
				}

				// 기타 이외의 케이스는 로깅하고 리턴
				Sentry.serverError(error);
				return Promise.reject(error);
			},
		);
	}

	async getExample(): Promise<void> {
		return;
	}

	checkResponse<T>(response: AxiosResponse<BaseResponse<T>>) {
		if (response.data?.code === ResponseCode.SUCCESS) {
			return response.data.data ?? null;
		}
		Toast.error(response.data.message);
		return null;
	}

	checkMutationResponse<T>(response: AxiosResponse<BaseResponse<T>>) {
		if (response.data?.code === ResponseCode.SUCCESS) {
			return response.data.data ?? null;
		}
		throw new Error(response.data.message);
	}

	initEDocClient(config?: AxiosRequestConfig<any>) {
		this.eDocClient = axios.create({
			baseURL: process.env.REACT_APP_EFORM_URL,
			timeout: 10 * 1000,
			...config,
		});

		this.eDocClient.interceptors.request.use(
			(config) => {
				const autoSignIn = localStorage.getItem('autoSignIn');
				const localToken = localStorage.getItem('access_token');

				if (autoSignIn === 'true' && localToken) sessionStorage.setItem('access_token', localToken);

				const token = sessionStorage.getItem('access_token');

				if (token) {
					config.headers.set('Authorization', `Bearer ${token}`);
				}
				return config;
			},
			(error) => {
				// axios 타임아웃에러
				if (error?.code === 'ECONNABORTED') {
					Toast.error('인터넷 연결이 원할하지 않습니다.', {
						toastId: '인터넷 연결이 원할하지 않습니다.',
					});
				}
				return Promise.reject(error);
			},
		);
	}

	removeEDocClient() {
		this.eDocClient = undefined;
	}

	removeHolidayClient() {
		this.holidayClient = undefined;
	}

	async updateExampleItem(): Promise<void> {
		return;
	}

	async deleteExampleItem(): Promise<void> {
		return;
	}

	async recipientDuplicateCheck(
		param: RecipientCheckDuplicateRequest,
	): Promise<RecipientCheckDuplicateDTO | null> {
		return this.client
			.get<RecipientCheckDuplicateRequest, AxiosResponse<RecipientCheckDuplicateResponse>>(
				endpoint.recipientCheckDuplicate.path(),
				{
					params: param,
				},
			)
			.then(this.checkResponse);
	}

	async signIn(param: SignInRequest) {
		return this.client
			.post<SignInRequest, AxiosResponse<SignInResponse>>(endpoint.signIn.path(), param)
			.then(this.checkResponse);
	}

	async signUp(param: SignUpRequest) {
		return this.client
			.post<SignUpRequest, AxiosResponse<SignUpResponse>>(endpoint.signUp.path(), param)
			.then((res) => res.data.data ?? null);
	}

	async validateId(param: IDValidatorRequest): Promise<boolean | null> {
		return (
			this.client
				.get<IDValidatorRequest, AxiosResponse<IDValidatorResponse>>(endpoint.validateId.path(), {
					params: param,
				})
				// .then(this.checkResponse);
				.then((res) => res.data.data ?? null)
		);
	}

	async setNewPassword(param: NewPasswordRequest) {
		return this.client
			.post<
				NewPasswordRequest,
				AxiosResponse<NewPasswordResponse>
			>(endpoint.newPassword.path(), param)
			.then(this.checkResponse);
	}

	async getMyTaskCount() {
		return this.client.get<MyTaskCountResponse>(endpoint.myTaskCount.path()).then((e) => e.data);
	}

	async getMyTaskTypes() {
		return this.client.get<MyTaskTypesResponse>(endpoint.myTaskTypes.path()).then((e) => e.data);
	}

	async getMyTaskProcess(params: MyTaskRequestDTO) {
		return this.client
			.get<MyTaskResponse>(endpoint.myTaskProcess.path(), {
				params,
			})
			.then((e) => e.data);
	}

	async getMyTaskComplete(params: MyTaskRequestDTO) {
		return this.client
			.get<MyTaskResponse>(endpoint.myTaskComplete.path(), {
				params,
			})
			.then((e) => e.data);
	}

	async getMyTaskWait(params: MyTaskRequestDTO) {
		return this.client
			.get<MyTaskResponse>(endpoint.myTaskWait.path(), {
				params,
			})
			.then((e) => e.data);
	}

	async getNewTaskTypes() {
		return this.client.get<NewTaskTypesResponse>(endpoint.newTaskTypes.path()).then((e) => e.data);
	}

	async getNewSubTaskTypes(params: NewSubTaskRequestDTO) {
		return this.client
			.get<NewSubTaskResponse>(endpoint.newSubTaskTypes.path(), {
				params,
			})
			.then((e) => e.data);
	}

	async getSubMyTask(params: MySubTaskRequestDTO) {
		return this.client
			.get<MySubTaskResponse>(endpoint.subMyTask.path(params.myTaskId))
			.then((e) => e.data);
	}

	async createTask(params: CreateTaskRequestDTO) {
		return this.client
			.post<CreateTaskResponse>(endpoint.createTask.path(), params)
			.then((e) => e.data);
	}

	async getRecipients(params: GetRecipientsRequestDTO) {
		return this.client
			.get<GetRecipientsResponse>(endpoint.getRecipients.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getRecipient(params: GetRecipientRequestDTO) {
		return this.client
			.get<GetRecipientResponse>(`${endpoint.getRecipient.path()}/${params.recipientId}`)
			.then(this.checkResponse);
	}

	async createRecipient(params: CreateRecipientRequestDTO): Promise<CreateRecipientData | null> {
		return this.client
			.post<CreateRecipientResponse>(endpoint.createRecipient.path(), params)
			.then(this.checkResponse);
	}

	async checkRecipientRsdnNo(
		params: CheckRecipientRsdnNoRequestDTO,
	): Promise<CheckRecipientRsdnNData | null> {
		return this.client
			.get<CheckRecipientRsdnNResponse>(endpoint.checkRecipientRsdnNo.path(), { params })
			.then(this.checkResponse);
	}

	async getRecipientHistoryOfOwnExpense(params: GetRecipientHistoryOfOwnExpenseRequestDTO) {
		return this.client
			.get<GetRecipientHistoryOfOwnExpenseResponse>(
				endpoint.getRecipientHistoryOfOwnExpense.path(params.recipientId),
			)
			.then(this.checkResponse);
	}

	async createRecipientHistoryOfOwnExpense({
		recipientId,
		...params
	}: CreateRecipientHistoryOfOwnExpenseRequestDTO) {
		return this.client
			.post<CreateRecipientHistoryOfOwnExpensResponse>(
				endpoint.createRecipientHistoryOfOwnExpenseRate.path(recipientId),
				params,
			)
			.then(this.checkResponse);
	}

	async updateRecipientHistoryOfOwnExpense({
		recipientId,
		...params
	}: UpdateRecipientHistoryOfOwnExpenseRequestDTO) {
		return this.client
			.post<UpdateRecipientHistoryOfOwnExpensResponse>(
				endpoint.createRecipientHistoryOfOwnExpenseRate.path(recipientId),
				params,
			)
			.then(this.checkResponse);
	}

	async deleteRecipientHistoryOfOwnExpense(params: DeleteRecipientHistoryOfOwnExpenseRequestDTO) {
		return this.client
			.delete<DeleteRecipientHistoryOfOwnExpenseResponse>(
				endpoint.deleteRecipientHistoryOfOwnExpenseRate.path(params.recipientId),
			)
			.then(this.checkResponse);
	}

	async getRecipientHistoryOfManager(params: GetRecipientHistoryOfManagerRequestDTO) {
		return this.client
			.get<GetRecipientHistoryOfManagerResponse>(
				endpoint.getRecipientHistoryOfManager.path(params.recipientId),
			)
			.then(this.checkResponse);
	}

	async createRecipientHistoryOfManager({
		recipientId,
		...params
	}: CreateRecipientHistoryOfManagerRequestDTO) {
		return this.client
			.post<CreateRecipientHistoryOfManagerResponse>(
				endpoint.createRecipientHistoryOfManager.path(recipientId),
				params,
			)
			.then(this.checkResponse);
	}

	async getRecipientHistoryOfLongTermGrade(params: GetRecipientHistoryOfLongTermGradeRequestDTO) {
		return this.client
			.get<GetRecipientHistoryOfLongTermGradeResponse>(
				endpoint.getRecipientHistoryOfLongTermGrade.path(params.recipientId),
			)
			.then(this.checkResponse);
	}

	async getEDoc({ path, param = {} }: EDocRequest) {
		return this.eDocClient?.post<string>(path, param).then((e) => e.data);
	}

	async getAlarms(params: AlarmRequestDTO) {
		return this.client
			?.get<AlarmListResponse>(
				endpoint.getAlarms.path(params.memberAccountId),
				params?.checked
					? {
							params: {
								checked: true,
							},
						}
					: {},
			)
			.then((e) => e.data);
	}

	async createUploadUrl(params: CreateUploadUrlRequestDTO) {
		return this.client
			.get<CreateUploadUrlResponse>(endpoint.createUploadUrl.path(), {
				params,
			})
			.then((e) => e.data);
	}

	async createDownloadUrl(params: CreateDownloadUrlRequestDTO) {
		return this.client
			?.get<CreateUploadUrlResponse>(endpoint.createDownloadUrl.path(), {
				params: {
					objectKey: params.objectKey,
				},
			})
			.then((e) => e.data);
	}

	async uploadFile(param: UploadFileRequestDTO) {
		return axios
			.put(param.presignedUrl, param.file, {
				headers: {
					'Content-Type': param.type,
				},
			})
			.then(
				(e) =>
					// if (e.status === 200) {
					// }
					e.data,
			)
			.catch((err) => console.error(err));
	}

	async uploadBurden(param: UploadBurdenRequestDTO) {
		return axios
			.post(endpoint.uploadBurden.path(), param.burdenUploads, {
				params: { centerId: param.centerId },
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((e) => e.data);
	}

	async uploadCharge(param: UploadChargeRequestDTO) {
		return axios
			.post(endpoint.uploadCharge.path(), param.chargeUploads, {
				params: { centerId: param.centerId },
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((e) => e.data);
	}

	async getEmployees(params: GetEmployeesRequestDTO) {
		if (!params.centerIds?.length) return null;
		return this.client
			.get<GetEmployeesResponse>(endpoint.getEmployees.path(), {
				params: {
					...params,
					centerIds: String(params.centerIds ?? []),
				},
			})
			.then(this.checkResponse);
	}

	async getEmployeeBaseInfo(params: GetEmployeeBaseInfoRequestDTO) {
		if (!params?.centerId || !params?.employeeId) return null;
		return this.client
			.get<GetEmployeeBaseInfoResponse>(endpoint.getEmployeeBaseInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getEmployeeDetailInfo(params: GetEmployeeDetailInfoRequestDTO) {
		if (!params?.centerId || !params?.employeeId || !params?.memberId) return null;
		return this.client
			.get<GetEmployeeDetailInfoResponse>(endpoint.getEmployeeDetailInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getCommonCodes(params: GetCommonCodesRequestDTO) {
		return this.client
			.get<GetCommonCodesResponse>(endpoint.geCommonCodes.path(), {
				params: {
					comCdGroupNms: String(params.comCdGroupNms),
				},
			})
			.then(this.checkResponse);
	}

	async getCommonCenters() {
		return this.client
			.get<GetCommonCentersResponse>(endpoint.getCommonCenters.path())
			.then(this.checkResponse);
	}

	async getExternalCenters() {
		return this.client
			.get<GetExternalCenterResponse>(endpoint.getExternalCenters.path())
			.then(this.checkResponse);
	}

	async saveEmployeeBaseInfo(params: SaveEmployeeBaseInfoRequestDTO) {
		return this.client
			.post<SaveEmployeeBaseInfoResponse>(endpoint.saveEmployeeBaseInfo.path(), params)
			.then(this.checkResponse);
	}

	async saveEmployeeWorkState(params: SaveEmployeeWorkStateRequestDTO) {
		return this.client
			.post<SaveEmployeeWorkStateResponse>(endpoint.saveEmployeeWorkState.path(), params)
			.then(this.checkResponse);
	}

	async employeeDuplicateCheck(param: EmployeeCheckDuplicateRequest) {
		if (!param.name || !param.rsdnNo) return null;
		return this.client
			.get<EmployeeCheckDuplicateResponse>(endpoint.employeeCheckDuplicate.path(), {
				params: param,
			})
			.then((res) => res.data);
	}

	async getMyAccountInfo() {
		return this.client
			.get<MyAccountInfoResponse>(endpoint.getMyAccountInfo.path())
			.then(this.checkResponse);
	}

	async createEmployee(params: CreateEmployeeRequestDTO) {
		return this.client
			.post<CreateEmployeeResponse>(endpoint.createEmployee.path(), params)
			.then((res) => res.data);
	}

	async saveEmployeeOtherCorpWork(params: SaveEmployeeOtherCorpWorkRequestDTO) {
		return this.client
			.post<SaveEmployeeOtherCorpWorkResponse>(endpoint.saveEmployeeOtherCorpWork.path(), params)
			.then(this.checkResponse);
	}

	async saveEmployeePayInfo(params: SaveEmployeePayInfoRequestDTO) {
		return this.client
			.post<SaveEmployeePayInfoResponse>(endpoint.saveEmployeePayInfo.path(), params)
			.then(this.checkResponse);
	}

	async getEmployeeHealthCheck(params: EmployeeHealthCheckRequestDTO) {
		if (!params?.employeeId) return null;
		return this.client
			.get<EmployeeHealthCheckResponse>(endpoint.getEmployeeHealthCheck.path(), {
				params: {
					employeeId: params.employeeId,
				},
			})
			.then(this.checkResponse);
	}

	async saveEmployeeHealthCheck(params: SaveEmployeeHealthCheckRequestDTO) {
		return this.client
			.post<SaveEmployeeHealthCheckResponse>(endpoint.saveEmployeeHealthCheck.path(), params)
			.then(this.checkResponse);
	}

	async deleteEmployeeHealthCheck(params: DeleteEmployeeHealthCheckRequestDTO) {
		return this.client
			.delete<DeleteEmployeeHealthCheckResponse>(endpoint.deleteEmployeeHealthCheck.path(), {
				data: params,
			})
			.then(this.checkResponse);
	}

	async getCenterManagers(
		params: GetCenterManagersRequestDTO,
	): Promise<GetCenterManagersData | null> {
		if (!params?.centerId) return null;
		return this.client
			.get<GetCenterManagersResponse>(endpoint.getCenterManager.path(params.centerId), {
				data: params,
			})
			.then(this.checkResponse);
	}

	async getEmployeeCareerInfo(params: EmployeeCareerInfoRequestDTO) {
		if (!params?.employeeId) return null;
		return this.client
			.get<EmployeeCareerInfoResponse>(endpoint.getEmployeeCareerInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async saveEmployeeCareer(params: SaveEmployeeCareerRequestDTO) {
		return this.client
			.post<SaveEmployeeCareerResponse>(endpoint.saveEmployeeCareer.path(), params)
			.then(this.checkResponse);
	}

	async deleteEmployeeCareer(params: DeleteEmployeeCareerRequestDTO) {
		return this.client
			.delete<DeleteEmployeeCareerResponse>(endpoint.deleteEmployeeCareer.path(), {
				data: params,
			})
			.then(this.checkResponse);
	}

	async getRecipientBase(params: GetRecipientBaseRequest): Promise<GetRecipientBaseData | null> {
		return this.client
			.get<GetRecipientBaseResponse>(endpoint.getRecipientBase.path(params.recipientId))
			.then(this.checkResponse);
	}

	async getCenterRecipientBase(
		params: GetCenterRecipientBaseRequest,
	): Promise<GetRecipientBaseData | null> {
		return this.client
			.get<GetRecipientBaseResponse>(
				endpoint.getCenterRecipientBase.path(params.recipientId, params.centerId),
			)
			.then(this.checkResponse);
	}

	async updateRecipientBase({
		recipientId,
		...params
	}: UpdateRecipientBaseRequest): Promise<UpdateRecipientBaseData | null> {
		return this.client
			.put<UpdateRecipientBaseResponse>(endpoint.updateRecipientBase.path(recipientId), params)
			.then(this.checkResponse);
	}

	async updateRecipientBurdenAmt({
		recipientId,
		...params
	}: UpdateRecipientBurdenAmtRequest): Promise<UpdateRecipientBurdenAmtData | null> {
		return this.client
			.put<UpdateRecipientBurdenAmtResponse>(
				endpoint.updateRecipientBurdenAmt.path(recipientId),
				params,
			)
			.then(this.checkResponse);
	}

	async updateRecipientGuardians({
		recipientId,
		body,
	}: UpdateRecipientGuardiansRequest): Promise<UpdateRecipientGuardiansData | null> {
		return this.client
			.post<UpdateRecipientGuardiansResponse>(
				endpoint.updateRecipientGuardians.path(recipientId),
				body,
			)
			.then(this.checkResponse);
	}

	async updateRecipientInUse(
		params: UpdateRecipientInUseRequest,
	): Promise<UpdateRecipientInUseData | null> {
		return this.client
			.patch<UpdateRecipientInUseResponse>(
				endpoint.updateRecipientInUse.path(params.recipientId),
				{},
			)
			.then(this.checkResponse);
	}

	async updateRecipientWaiting(
		params: UpdateRecipientWaitingRequest,
	): Promise<UpdateRecipientWaitingData | null> {
		return this.client
			.patch<UpdateRecipientWaitingResponse>(
				endpoint.updateRecipientWaiting.path(params.recipientId),
				{},
			)
			.then(this.checkResponse);
	}

	async getEmployeeLicenseInfo(params: EmployeeLicenseInfoRequestDTO) {
		if (!params?.employeeId) return null;
		return this.client
			.get<EmployeeLicenseInfoResponse>(endpoint.getEmployeeLicenseInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async saveEmployeeLicense(params: SaveEmployeeLicenseRequestDTO) {
		return this.client
			.post<SaveEmployeeLicenseResponse>(endpoint.saveEmployeeLicense.path(), params)
			.then(this.checkResponse);
	}

	async deleteEmployeeLicense(params: DeleteEmployeeLicenseRequestDTO) {
		return this.client
			.delete<DeleteEmployeeLicenseResponse>(endpoint.deleteEmployeeLicense.path(), {
				data: params,
			})
			.then(this.checkResponse);
	}

	async deleteFileDetail(params: DeleteFileDetailRequestDTO) {
		return this.client
			.delete<DeleteFileDetailResponse>(endpoint.deleteFileDetail.path(params.fileDetailId))
			.then(this.checkResponse);
	}

	async getEmployeeEduInfo(params: EmployeeEduInfoRequestDTO) {
		if (!params?.employeeId || !params.year) return null;
		return this.client
			.get<EmployeeEduInfoResponse>(endpoint.getEmployeeEduInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async saveEmployeeEducation(params: SaveEmployeeEducationRequestDTO) {
		return this.client
			.post<SaveEmployeeEducationResponse>(endpoint.saveEmployeeEducation.path(), params)
			.then((e) => e.data);
	}

	async deleteEmployeeEducation(params: DeleteEmployeeEducationRequestDTO) {
		return this.client
			.delete<DeleteEmployeeEducationResponse>(endpoint.deleteEmployeeEducation.path(), {
				data: params,
			})
			.then(this.checkResponse);
	}

	async getEmployeeHistoryOfManager(params: EmployeeHistoryOfManagerRequestDTO) {
		if (!params?.employeeId) return null;
		return this.client
			.get<EmployeeHistoryOfManagerResponse>(
				endpoint.getEmployeeHistoryOfManager.path(params.employeeId),
			)
			.then(this.checkResponse);
	}

	async updateRecipientOtherServices({
		recipientId,
		body,
	}: UpdateRecipientOtherServicesRequest): Promise<UpdateRecipientOtherServicesData | null> {
		return this.client
			.post<UpdateRecipientOtherServicesResponse>(
				endpoint.updateRecipientOtherServices.path(recipientId),
				body,
			)
			.then(this.checkResponse);
	}

	async saveEmployeeHistoryOfManager(params: SaveEmployeeHistoryOfManagerRequestDTO) {
		return this.client
			.post<SaveEmployeeHistoryOfManagerResponse>(
				endpoint.saveEmployeeHistoryOfManager.path(String(params.employeeId)),
				params,
			)
			.then(this.checkResponse);
	}

	async getSchedule(params: ScheduleRequestDTO) {
		const { type, employeeId, recipientId, yearMonth, centerId } = params;
		if ((!employeeId && !recipientId) || !centerId) return null;

		const queryParams: Record<string, number | string | undefined> = { yearMonth, centerId };

		if (type === 'employee' && employeeId) {
			queryParams.employeeId = employeeId;
		}

		if (type === 'recipient' && recipientId) {
			queryParams.recipientId = recipientId;
		}

		return this.client
			.get<ScheduleResponse>(endpoint.getSchedule.path(type), {
				params: queryParams,
			})
			.then(this.checkResponse);
	}

	async getMyMenuInfo() {
		return this.client
			.get<MyMenuInfoResponse>(endpoint.getMyMenuInfo.path())
			.then(this.checkResponse);
	}

	async getAdviceClientOptions(
		params: GetAdviceClientOptionsRequest,
	): Promise<GetAdviceClientOptionsData | null> {
		return this.client
			.get<GetAdviceClientOptionsResponse>(endpoint.getAdviceClientOptions.path(), { params })
			.then(this.checkResponse);
	}

	async getAdviceMethods(): Promise<GetAdviceMethodsData | null> {
		return this.client
			.get<GetAdviceMethodsResponse>(endpoint.getAdviceMethods.path())
			.then(this.checkResponse);
	}

	async getAdviceTags(): Promise<GetAdviceTagsData | null> {
		return this.client
			.get<GetAdviceTagsResponse>(endpoint.getAdviceTags.path())
			.then(this.checkResponse);
	}

	async updateAdvice({
		consultId,
		...params
	}: UpdateAdviceRequest): Promise<UpdateAdviceData | null> {
		return this.client
			.patch<UpdateAdviceResponse>(endpoint.updateAdvice.path(consultId), params)
			.then(this.checkResponse);
	}

	async createAdvice(params: CreateAdviceRequest): Promise<CreateAdviceResponse> {
		return this.client
			.post<CreateAdviceResponse>(endpoint.createAdvice.path(), params)
			.then((res) => res.data);
	}

	async getAdvice(params: GetAdviceRequest): Promise<GetAdviceData | null> {
		return this.client
			.get<GetAdviceResponse>(endpoint.getAdvice.path(params.adviceId))
			.then(this.checkResponse);
	}

	async getEmployeeAdvices({
		employeeId,
		...params
	}: GetEmployeeAdvicesRequest): Promise<GetEmployeeAdvicesData | null> {
		if (!employeeId) return null;
		const newParam: { [key: string]: string | number[] } = {};
		Object.entries(params).forEach(([key, value]) => {
			if (value) {
				newParam[key] = value;
			}
		});
		return this.client
			.get<GetEmployeeAdvicesResponse>(endpoint.getEmployeeAdvices.path(employeeId), {
				params: newParam,
			})
			.then(this.checkResponse);
	}

	async getEmployeeScheduleDetail(params: EmployeeScheduleDetailRequestDTO) {
		if (!params.employeeId || !params.centerId) return null;

		return this.client
			.get<EmployeeScheduleDetailResponse>(endpoint.getScheduleDetail.path('employee'), {
				params,
			})
			.then(this.checkResponse);
	}

	async getEmployeeAdvice(params: GetEmployeeAdviceRequest): Promise<GetEmployeeAdviceData | null> {
		return this.client
			.get<GetEmployeeAdviceResponse>(
				endpoint.getEmployeeAdvice.path(params.employeeId, params.adviceId),
			)
			.then(this.checkResponse);
	}

	async getRecipientAdvices({
		recipientId,
		...params
	}: GetRecipientAdvicesRequest): Promise<GetRecipientAdvicesData | null> {
		if (!recipientId) return null;
		const newParam: { [key: string]: string | number[] } = {};
		Object.entries(params).forEach(([key, value]) => {
			if (value) {
				newParam[key] = value;
			}
		});
		return this.client
			.get<GetRecipientAdvicesResponse>(endpoint.getRecipientAdvices.path(recipientId), {
				params: newParam,
			})
			.then(this.checkResponse);
	}

	async getRecipientScheduleDetail(params: RecipientScheduleDetailRequestDTO) {
		if (!params.recipientId || !params.centerId) return null;

		return this.client
			.get<RecipientScheduleDetailResponse>(endpoint.getScheduleDetail.path('recipient'), {
				params,
			})
			.then(this.checkResponse);
	}

	async getRecipientAdvice(
		params: GetRecipientAdviceRequest,
	): Promise<GetRecipientAdviceData | null> {
		return this.client
			.get<GetRecipientAdviceResponse>(
				endpoint.getRecipientAdvice.path(params.recipientId, params.adviceId),
				{
					params: { guardianFlag: params.guardianFlag },
				},
			)
			.then(this.checkResponse);
	}

	async getRecipientGuardianAdvice(
		params: GetRecipientGuardianAdviceRequest,
	): Promise<GetRecipientGuardianAdviceData | null> {
		return this.client
			.get<GetRecipientGuardianAdviceResponse>(
				endpoint.getRecipientGuardianAdvice.path(params.recipientId, params.adviceId),
			)
			.then(this.checkResponse);
	}

	async deleteAdvice(params: DeleteAdviceRequest) {
		return this.client
			.delete<DeleteAdviceResponse>(endpoint.deleteAdvice.path(params.adviceId))
			.then(this.checkResponse);
	}

	async getCenterList() {
		return this.client
			.get<CenterListResponse>(endpoint.getCenterList.path())
			.then(this.checkResponse);
	}

	async getGuaranteedCenters() {
		return this.client
			.get<GuaranteedCenterListResponse>(endpoint.getGuaranteedCenters.path())
			.then(this.checkResponse);
	}

	async updateEmployeeScheduleDetail(params: SaveScheduleDetailRequestDTO[]) {
		return this.client
			.post<SaveEmployeeScheduleDetailResponse>(endpoint.saveEmployeeScheduleDetail.path(), params)
			.then(this.checkResponse);
	}

	async updateRecipientScheduleDetail(params: SaveScheduleDetailRequestDTO[]) {
		return this.client
			.post<SaveRecipientScheduleDetailResponse>(
				endpoint.saveRecipientScheduleDetail.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async getAutomationUploadList(params: AutomationUploadRequestDTO) {
		if (!params?.centerId || !params.uploadTypeCds) return null;
		return this.client
			.get<AutomationUploadResponse>(endpoint.getAutomationUploadList.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async updateAutomationUploadList(params: UpdateAutomationUploadRequestDTO) {
		if (!params?.uploadHistId) return null;
		return this.client
			.put<UpdateAutomationUploadResponse>(
				endpoint.updateAutomationUploadList.path(params.uploadHistId),
			)
			.then(this.checkResponse);
	}

	async getSendTemplateList() {
		return this.client
			.get<SendTemplateListResponse>(endpoint.getSendTemplateList.path(), {})
			.then(this.checkResponse);
	}

	async getSendTargetList(params: SendTargetListRequestDTO) {
		if (!params.templateId) return null;
		return this.client
			.get<SendTargetListResponse>(endpoint.getSendTargetList.path(params.templateId), {
				params,
			})
			.then(this.checkResponse);
	}

	async getMonthlyBurdenCharges(
		params: GetMonthlyBurdenChargesRequest,
	): Promise<GetMonthlyBurdenChargesData | null> {
		if (params.page === 0) return null;
		return this.client
			.get<GetMonthlyBurdenChargesResponse>(endpoint.getMonthlyBurdenCharges.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getMonthlyBurdenChargeDetail(
		params: GetMonthlyBurdenChargesDetailRequest,
	): Promise<GetMonthlyBurdenChargesDetailData | null> {
		return this.client
			.get<GetMonthlyBurdenChargesDetailResponse>(
				endpoint.getMonthlyBurdenChargeDetail.path(params.burdenAmtChargeId),
			)
			.then(this.checkResponse);
	}

	async getMonthlyBurdenChargesSummary(
		params: GetMonthlyBurdenChargesSummaryRequest,
	): Promise<GetMonthlyBurdenChargesSummaryData | null> {
		return this.client
			.get<GetMonthlyBurdenChargesSummaryResponse>(endpoint.getMonthlyBurdenChargesSummary.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async uploadMonthlyBurdenCharges(
		params: UploadMonthlyBurdenChargesRequest,
	): Promise<UploadMonthlyBurdenChargesResponse | null> {
		const formData = new FormData();
		formData.append('file', params.file);

		return this.client
			.post<UploadMonthlyBurdenChargesResponse>(
				endpoint.uploadMonthlyBurdenCharges.path(),
				formData,
				{
					params: {
						centerId: params.centerId,
						burdenAmtChargeYm: params.burdenAmtChargeYm,
					},
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			)
			.then((result) => result.data);
	}

	async getBurdenPayHistories(
		params: GetBurdenPayHistoriesRequest,
	): Promise<GetBurdenPayHistoriesData | null> {
		if (params.page === 0) return null;
		return this.client
			.get<GetBurdenPayHistoriesResponse>(endpoint.getBurdenPayHistories.path(), {
				params: getValidParam(params),
			})
			.then(this.checkResponse);
	}

	async getBurdenPayHistoryDetail(
		params: GetBurdenPayHistoryDetailRequest,
	): Promise<GetBurdenPayHistoryDetailData | null> {
		return this.client
			.get<GetBurdenPayHistoryDetailResponse>(endpoint.getBurdenPayHistoryDetail.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async cancelCashReceipt(
		params: CancelCashReceiptDetailRequest,
	): Promise<CancelCashReceiptDetailData | null> {
		return this.client
			.post<CancelCashReceiptDetailResponse>(endpoint.cancelCashReceipt.path(), params)
			.then(this.checkResponse);
	}

	async issueCashReceipt(
		params: IssueCashReceiptDetailRequest,
	): Promise<IssueCashReceiptDetailData | null> {
		return this.client
			.post<IssueCashReceiptDetailResponse>(endpoint.issueCashReceipt.path(), params)
			.then(this.checkResponse);
	}

	async getRecordingSheets(params: RecordingSheetRequest) {
		if (!params.page || !params.size || !params.centerId) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<RecordingSheetResponse>(endpoint.getRecordingSheets.path(), {
				params: newParams,
				timeout: 30 * 1000,
			})
			.then(this.checkResponse);
	}

	async getEmployeeSalarys(params: EmployeeSalarysRequest) {
		if (!params.centerId || !params.employeeId) return null;
		return this.client
			.get<EmployeeSalarysResponse>(endpoint.getEmployeeSalarys.path(), {
				params: {
					...params,
					managerIds: params.managerIds?.join(', '),
				},
			})
			.then(this.checkResponse);
	}

	async openEDocClient(params: EDocClientOpenRequestDTO) {
		return this.client
			.post<EDocClientOpenResponse>(endpoint.openEDocClient.path(), params)
			.then((res) => res.data);
	}

	async confirmEDocClient(params: EDocClientConfirmRequestDTO) {
		return this.client
			.post<EDocClientConfirmResponse>(endpoint.confirmEDocClient.path(), params)
			.then((res) => res.data);
	}

	async openEDocManager(params: EDocManagerOpenRequestDTO) {
		return this.client
			.post<EDocManagerOpenResponse>(endpoint.openEDocManager.path(), params)
			.then((res) => res.data);
	}

	async confirmEDocManager(params: EDocManagerConfirmRequestDTO) {
		return this.client
			.post<EDocManagerConfirmResponse>(endpoint.confirmEDocManager.path(), params)
			.then((res) => res.data);
	}

	async getEmployeeEDocList(params: EmployeeEDocListRequest) {
		if (!params.centerId || !params.employeeId) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<EmployeeEDocListResponse>(endpoint.getEmployeeEDocList.path(), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	async getEDocPaperTypes(params: EDocPaperTypesRequest) {
		if (!params?.paperMenu) return null;
		return this.client
			.get<EDocPaperTypeResponse>(endpoint.getEDocPaperTypes.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getIssuableEDocPaperTypes(params: EDocPaperTypesRequest) {
		if (!params?.paperMenu) return null;
		return this.client
			.get<EDocPaperTypeResponse>(endpoint.getIssuableEDocPaperTypes.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getEDocIssueInfoBefore(params: EDocBeforeRequest) {
		if (!params?.centerId || (!params.employeeId && !params.recipientId) || !params.templateCode)
			return null;
		return this.client
			.get<EmploymentCertificationIssueInfoResponse>(
				endpoint.getEDocIssueInfoBefore.path(params.templateCode),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	// 전자문서 발급
	async issueEDocument(params: IssueEDocumentRequest): Promise<IssueEDocumentResponse | null> {
		return this.client
			.post<IssueEDocumentResponse>(endpoint.issueEDocument.path(), params)
			.then((res) => res.data);
	}

	// 전자문서 미리보기 데이터
	async getEDocPreviewInfo(params: EDocPreviewRequest) {
		if (!params?.centerId || !params.edocNo) return null;
		return this.client
			.get<EDocPreviewResponse>(endpoint.getEDocPreviewInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getContracts(params: GetContractRequest) {
		if (params.page === 0) return null;
		return this.client
			.get<GetContractResponse>(endpoint.getContracts.path(), { params })
			.then(this.checkResponse);
	}

	async getRecipientContractServices(params: GetRecipientContractRequest) {
		return this.client
			.get<GetRecipientContractResponse>(endpoint.getRecipientContractServices.path(), { params })
			.then(this.checkResponse);
	}

	async getEmployeeContractServices(params: GetEmployeeContractRequest) {
		return this.client
			.get<GetEmployeeContractResponse>(endpoint.getEmployeeContractServices.path(), { params })
			.then(this.checkResponse);
	}

	// 전자 문서 서류 등록
	async createEDocPaper(params: CreateEDocPaperRequest) {
		return this.client
			.post<CreateEDocCreateResponse>(endpoint.createEDocPaper.path(), params)
			.then(this.checkResponse);
	}

	// 방문간호지시서 등록
	async createHomeCarePaper(params: CreateHomeCarePaperRequest) {
		return this.client
			.post<CreateEDocCreateResponse>(endpoint.createHomeCarePaper.path(), params)
			.then(this.checkResponse);
	}

	// 개인정보 열람이력 등록
	async inquireIndividualInfo(params: InquireIndividualInfoRequest) {
		return this.client
			.put<InquireIndividualInfoResponse>(endpoint.inquireIndividualInfo.path(), params)
			.then(this.checkResponse);
	}

	// 수급자 서류 리스트
	async getRecipientEDocList(params: RecipientEDocListRequest) {
		if (!params.centerId || !params.recipientId) return null;
		const newParams = getValidParam(params);

		return this.client
			.get<RecipientEDocListResponse>(endpoint.getRecipientEDocList.path(), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	async getRegisterEDocPaperTypes(params: EDocPaperTypesRequest) {
		if (!params?.paperMenu) return null;
		return this.client
			.get<EDocPaperTypeResponse>(endpoint.getRegisterEDocPaperTypes.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getRecipientBurdens(params: GetRecipientBurdensRequest) {
		return this.client
			.get<GetRecipientBurdensResponse>(endpoint.getRecipientBurdens.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async sendBurdenCharge(params: SendBurdenChargeRequestDTO) {
		return this.client
			.post<SendBurdenChargeResponse>(endpoint.sendBurdenCharge.path(params.burdenAmtChargeId))
			.then((res) => res.data);
	}

	// 메시지 발송 이력
	async getSendHistory(params: GetSendHistoryRequest) {
		if (params.page === 0) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<SendHistoryResponse>(endpoint.getSendHistory.path(), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	// 메시지 발송 이력 상세
	async getSendHistoryDetail(params: GetSendHistoryDetailRequest) {
		if (!params.historyId) return null;
		return this.client
			.get<SendHistoryDetailResponse>(endpoint.getSendHistoryDetail.path(params.historyId))
			.then(this.checkResponse);
	}

	// 메시지 발송
	// 서버쪽에서 동기적으로 발송하기때문에 처리시간이 길어질경우 4분이 넘어갈수도 있음.
	// 그래서 타임아웃을 해제함.
	async sendMessage(params: SendMessageRequest) {
		return this.client
			.post<SendMessageResponse>(endpoint.sendMessage.path(), params, { timeout: 0 })
			.then(this.checkResponse);
	}

	// 메시지 발송 예약 목록 조회
	async getReservationSendHistory(params: GetSendReservationHistoryRequest) {
		if (params.page === 0) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<SendReservationHistoryResponse>(endpoint.getReservationSendHistory.path(), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	// 메시지 발송 예약 목록 조회 상세
	async getReservationSendDetail(params: GetReservationSendDetailRequest) {
		if (!params.historyId) return null;
		return this.client
			.get<ReservationSendDetailResponse>(
				endpoint.getReservationSendHistoryDetail.path(params.historyId),
			)
			.then(this.checkResponse);
	}

	// 전자서명 발송 이력 조회 탭 필터
	async getESignHistoryFilter() {
		return this.client
			.get<ESignHistoryFilterResponse>(endpoint.getESignHistoryFilter.path(), {})
			.then(this.checkResponse);
	}

	// 전자서명 발송 이력 조회 탭 목록
	async getESignHistory(params: ESignHistoryRequest) {
		if (!params.centerId || params.page === 0) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<ESignHistoryResponse>(endpoint.getESignHistory.path(), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	// 전자서명 발송이력 조회 상세
	async getESignHistoryDetail(params: ESignHistoryDetailRequest) {
		if (!params.centerId || !params.edocSendId) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<ESignHistoryDetailResponse>(endpoint.getESignHistoryDetail.path(), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	//	전자 서명 발송이력 - 발송이력 상세 - 발송 기본정보 수정
	async updateSignDueDate(params: UpdateSignDueDateRequest) {
		return this.client
			.put<UpdateSignDueDateResponse>(endpoint.updateSignDueDate.path(), params)
			.then(this.checkResponse);
	}

	// 발송 취소
	async cancelEDoc(params: CancelEDocRequest) {
		return this.client
			.post<CancelEDocResponse>(endpoint.cancelEDoc.path(), params)
			.then(this.checkResponse);
	}

	// 메세지 실패선 재발송
	async resendFailMessage(params: ResendFailMessageRequest) {
		return this.client
			.post<ResendFailMessageResponse>(endpoint.resendFailMessage.path(params.sendId))
			.then(this.checkResponse);
	}

	// 메세지 개별 재발송
	async resendMessage(params: ResendMessageRequest) {
		return this.client
			.post<ResendMessageResponse>(endpoint.resendMessage.path(params.sendId, params.sendDetailId))
			.then(this.checkResponse);
	}

	// 예약 메세지 발송 취소
	async cancelReservationMessage(params: CancelReservationMessageRequest) {
		return this.client
			.post<CancelReservationMessageResponse>(
				endpoint.cancelReservationMessage.path(params.sendId),
				{
					cancelDesc: params.cancelDesc,
				},
			)
			.then(this.checkResponse);
	}

	async confirmEDoc(params: ConfirmEDocRequest) {
		return this.client
			.post<ConfirmEDocResponse>(endpoint.confirmEDoc.path(), params)
			.then((res) => res.data);
	}

	async openEDoc(params: OpenEDocRequest) {
		return this.client
			.post<OpenEDocResponse>(endpoint.openEDoc.path(), params)
			.then((res) => res.data);
	}

	async updateReservationMessage({
		sendId,
		params,
	}: {
		sendId: number;
		params: SendMessageRequest;
	}) {
		return this.client
			.post<UpdateReservationMessageResponse>(
				endpoint.updateReservationMessage.path(sendId),
				params,
			)
			.then((res) => res.data);
	}

	// 임금관리 목록 호출
	async getSalarysEmployeeSalaryList(params: SalarysEmployeeSalaryListRequestDTO) {
		if (params.page === 0) return null;
		return this.client
			.get<SalarysEmployeeSalaryListResponse>(endpoint.getSalarysEmployeeSalaryList.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 임금관리 상세 호출
	async getSalarysEmployeeSalaryDetail(params: SalarysEmployeeSalaryDetailRequestDTO) {
		return this.client
			.get<SalarysEmployeeSalaryDetailResponse>(
				endpoint.getSalarysEmployeeSalaryDetail.path(params.employeeSalaryId),
			)
			.then(this.checkResponse);
	}

	// 임금관리 청구엑셀 자동화 import 요청
	async requestSalarysAutoBilling(params: SalarysAutoBillingRequestDTO) {
		return this.client
			.post<SalarysAutoBillingResponse>(endpoint.requestSalarysAutoBilling.path(), params)
			.then((res) => res.data);
	}

	// 임금 대장 다운로드
	async getEmployeeSalarysPayroll(params: EmployeeSalarysPayrollDTO) {
		return this.client
			.get<EmployeeSalarysPayrollResponse>(endpoint.getEmployeeSalarysPayroll.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 대량 이제 다운로드
	async getEmployeeSalarysTransfer(params: EmployeeSalarysTransferDTO) {
		return this.client
			.get<EmployeeSalarysTransferResponse>(endpoint.getEmployeeSalarysTransfer.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 임금 상세 수정저장 요청
	async updateSalarysDetail(params: SalarysEmployeeSalarysContent) {
		return this.client
			.put<UpdateSalarysDetailResponse>(
				endpoint.updateSalarysDetail.path(params.employeeSalaryId),
				params,
			)
			.then((res) => res.data);
	}

	// 임금 재계산 요청
	async updateSalarysCalculate(params: UpdateSalarysCalculateRequestDTO) {
		return this.client
			.post<UpdateSalarysCalculateResponse>(
				endpoint.updateSalarysCalculate.path(params.employeeSalaryId),
				null,
				{
					params: {
						inputValueKeepYn: params.inputValueKeepYn || false,
					},
				},
			)
			.then((res) => res.data);
	}

	// 임금 현금 수령증 발송
	async sendCashReceive(params: SendCashReceiveRequestDTO) {
		return this.client
			.post<SendCashReceiveResponse>(endpoint.sendCashReceive.path(params.employeeSalaryId))
			.then((res) => res.data);
	}

	// 임금 명세서 발송
	async sendEmployeeSalary(params: SendEmployeeSalaryRequestDTO) {
		return this.client
			.post<SendEmployeeSalaryResponse>(endpoint.sendEmployeeSalary.path(params.employeeSalaryId))
			.then((res) => res.data);
	}

	// 임금 명세서 발송
	async completeEmployeeSalary(params: CompleteEmployeeSalaryRequestDTO) {
		return this.client
			.put<CompleteEmployeeSalaryResponse>(
				endpoint.completeEmployeeSalary.path(params.employeeSalaryId, params.salaryCompleteYn),
			)
			.then((res) => res.data);
	}

	// 알림톡 발송 일정표 조회 수급자용
	async getClientScheduleRecipient(params: ClientScheduleRequestDTO) {
		return this.client
			.get<ScheduleResponse>(endpoint.getClientScheduleRecipient.path(params.sendUuid))
			.then(this.checkResponse);
	}

	// 알림톡 발송 일정표 조회 직원용
	async getClientScheduleEmployee(params: ClientScheduleRequestDTO) {
		return this.client
			.get<ScheduleResponse>(endpoint.getClientScheduleEmployee.path(params.sendUuid))
			.then(this.checkResponse);
	}

	async getMessageTabSendHistory({
		type,
		id,
		params,
	}: {
		type: 'employees' | 'recipients';
		id?: number;
		params: MessageTabSendHistoryRequest;
	}) {
		if (!id) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<MessageTabSendHistoryResponse>(endpoint.messageTabSendHistory.path(type, id), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	async getMessageTabReservationHistory({
		type,
		id,
		params,
	}: {
		type: 'employees' | 'recipients';
		id?: number;
		params: MessageTabReservationHistoryRequest;
	}) {
		if (!id) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<MessageTabReservationHistoryResponse>(
				endpoint.messageTabReservationHistory.path(type, id),
				{
					params: newParams,
				},
			)
			.then(this.checkResponse);
	}

	async getMessageTabESignHistory({
		type,
		id,
		params,
	}: {
		type: 'employees' | 'recipients';
		id?: number;
		params: MessageTabESignHistoryRequest;
	}) {
		if (!id) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<MessageTabESignHistoryResponse>(endpoint.messageTabESignHistory.path(type, id), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	// 기록지 발송
	async sendRecordingSheet({
		params,
		signal,
	}: {
		params: SendRecordingSheetRequestDTO;
		signal?: AbortSignal;
	}) {
		return this.client
			.post<SendRecordingSheetResponse>(
				endpoint.sendRecordingSheets.path(),
				params,
				signal
					? {
							signal,
						}
					: {},
			)
			.then((res) => res.data);
	}

	async getSendScheduleDetail({
		type,
		id,
		params,
	}: {
		type: 'employees' | 'recipients';
		id?: number;
		params: SendScheduleDetailRequest;
	}) {
		if (!id || !params.targetYm || (type === 'recipients' && !params.centerId)) return null;
		// if (!id || !params.targetYm) return null;
		const newParams = getValidParam(params);
		return this.client
			.get<SendScheduleDetailResponse>(endpoint.getSendScheduleDetail.path(type, id), {
				params: newParams,
			})
			.then(this.checkResponse);
	}

	async saveRecordingSheetsClient(
		params: SaveRecordSheetClientRequestParams,
	): Promise<SaveRecordSheetClientResponse> {
		return this.client
			.post<SaveRecordSheetClientResponse>(endpoint.saveRecordingSheetsClient.path(params.type), {
				...params.input,
			})
			.then((res) => res.data);
	}

	async getRecipientIntegratedAssessment({
		recipientId = '',
		...params
	}: GetRecipientIntegratedAssessmentRequest): Promise<IntegratedAssessmentDTO[] | null> {
		return this.client
			.get<GetRecipientIntegratedAssessmentResponse>(
				endpoint.getRecipientIntegratedAssessment.path(recipientId),
				{ params },
			)
			.then(this.checkResponse);
	}

	async getRecipientIntegratedAssessmentUpdateForm({
		recipientId,
		recipientIaId,
	}: GetRecipientIntegratedAssessmentUpdateFormRequest): Promise<IntegratedAssessmentFormDTO | null> {
		return this.client
			.get<GetRecipientIntegratedAssessmentUpdateFormResponse>(
				endpoint.getRecipientIntegratedAssessmentUpdateForm.path(recipientId, recipientIaId),
			)
			.then(this.checkResponse);
	}

	async getRecipientIntegratedAssessmentPreviousData({
		recipientId,
		recipientIaId,
	}: GetRecipientIntegratedAssessmentPreviousDataRequest): Promise<IntegratedAssessmentPreviousDataDTO | null> {
		return this.client
			.get<GetRecipientIntegratedAssessmentPreviousDataResponse>(
				endpoint.getRecipientIntegratedAssessmentPreviousData.path(recipientId, recipientIaId),
			)
			.then(this.checkResponse);
	}

	async getRecipientIntegratedAssessmentPreviousList({
		recipientId,
		...params
	}: GetRecipientIntegratedAssessmentPreviousListRequest): Promise<
		IntegratedAssessmentPreviousListDataDTO[] | null
	> {
		return this.client
			.get<GetRecipientIntegratedAssessmentPreviousListResponse>(
				endpoint.getRecipientIntegratedAssessmentPreviousList.path(recipientId),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async getRecipientIntegratedAssessmentScore({
		recipientId,
		...params
	}: GetRecipientIntegratedAssessmentScoreRequest): Promise<IntegratedAssessmentScoreDTO | null> {
		return this.client
			.get<GetRecipientIntegratedAssessmentScoreResponse>(
				endpoint.getRecipientIntegratedAssessmentScore.path(recipientId),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async createRecipientIntegratedAssessment(
		params: CreateRecipientIntegratedAssessmentRequest,
	): Promise<CreateRecipientIntegratedAssessmentData | null> {
		return this.client
			.post<CreateRecipientIntegratedAssessmentResponse>(
				endpoint.createRecipientIntegratedAssessment.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async updateRecipientIntegratedAssessment({
		recipientIaId,
		...params
	}: UpdateRecipientIntegratedAssessmentRequest): Promise<UpdateRecipientIntegratedAssessmentResponse | null> {
		return this.client
			.patch<UpdateRecipientIntegratedAssessmentResponse>(
				endpoint.updateRecipientIntegratedAssessment.path(recipientIaId),
				params,
			)
			.then((res) => res.data);
	}

	async confirmRecipientIntegratedAssessment({
		recipientIaId,
		...params
	}: ConfirmRecipientIntegratedAssessmentRequest): Promise<ConfirmRecipientIntegratedAssessmentResponse | null> {
		return this.client
			.patch<ConfirmRecipientIntegratedAssessmentResponse>(
				endpoint.confirmRecipientIntegratedAssessment.path(recipientIaId),
				Object.keys(params).length > 0 ? params : null,
			)
			.then((res) => res.data);
	}

	async cancelRecipientIntegratedAssessment({
		recipientIaId,
		...params
	}: CancelRecipientIntegratedAssessmentRequest): Promise<CancelRecipientIntegratedAssessmentResponse | null> {
		return this.client
			.patch<CancelRecipientIntegratedAssessmentResponse>(
				endpoint.cancelRecipientIntegratedAssessment.path(recipientIaId),
				params,
			)
			.then((res) => res.data);
	}

	async deleteRecipientIntegratedAssessment({
		recipientIaId,
	}: DeleteRecipientIntegratedAssessmentRequest): Promise<DeleteRecipientIntegratedAssessmentResponse | null> {
		return this.client
			.delete<DeleteRecipientIntegratedAssessmentResponse>(
				endpoint.deleteRecipientIntegratedAssessment.path(recipientIaId),
			)
			.then((res) => res.data);
	}

	async createContract(params: CreateContractRequest) {
		return this.client
			.post<CreateContractResponse>(endpoint.createContract.path(), params)
			.then((res) => res.data);
	}

	async getScheduleTagErrorHistory(params: ScheduleTagErrorHistoryRequest) {
		if (params.page === 0) return null;
		const validParam = getValidParam(params);
		return this.client
			.get<ScheduleTagErrorHistoryResponse>(endpoint.getScheduleTagErrorHistory.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async updateScheduleTagErrorHistoryDetail(params: UpdateScheduleTagErrorHistoryDetailRequest) {
		const validParam = getValidParam(params);
		return this.client
			.put<UpdateScheduleTagErrorHistoryDetailResponse>(
				endpoint.updateScheduleTagErrorHistoryDetail.path(),
				validParam,
			)
			.then((res) => res.data);
	}

	async getScheduleTagAutomationHistory(params: ScheduleTagAutomationHistoryRequest) {
		if (params.page === 0) return null;
		const validParam = getValidParam(params);
		return this.client
			.get<ScheduleTagAutomationHistoryResponse>(endpoint.getScheduleTagAutomationHistory.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	// 자동화 마지막 동기화이력
	async getAutomationLastSync(params: AutomationLastSyncRequest) {
		return this.client
			.get<AutomationLastSyncResponse>(endpoint.getAutomationLastSync.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 태그 오류 동기화
	async tagErrorAutomation(params: TagErrorAutomationRequest) {
		return this.client
			.post<TagErrorAutomationResponse>(endpoint.tagErrorAutomation.path(), params)
			.then((res) => res.data);
	}

	// 일정 동기화
	async scheduleAutomation(params: ScheduleAutomationRequest) {
		return this.client
			.post<ScheduleAutomationResponse>(endpoint.scheduleAutomation.path(), params)
			.then((res) => res.data);
	}

	// 직원 퇴사 정보
	async getEmployeeResignInfo(params: EmployeeResignInfoRequest) {
		return this.client
			.get<EmployeeResignInfoResponse>(endpoint.getEmployeeResignInfo.path(), {
				params,
			})
			.then((res) => res.data);
	}

	// 직원 퇴사
	async resignEmployee(params: ResignEmployeeRequest) {
		return this.client
			.put<ResignEmployeeResponse>(endpoint.resignEmployee.path(), params)
			.then((res) => res.data);
	}

	// 예금주 조회
	async checkAccountOwner(params: CheckAccountOwnerRequest) {
		return this.client
			.get<CheckAccountOwnerResponse>(endpoint.checkAccountOwner.path(), {
				params,
				timeout: 0,
			})
			.then((res) => res.data);
	}

	// 수급자 퇴소
	async resignRecipient({
		recipientId,
		params,
	}: {
		recipientId: number;
		params: ResignRecipientRequest;
	}) {
		return this.client
			.post<ResignRecipientResponse>(endpoint.resignRecipient.path(recipientId), params)
			.then((res) => res.data);
	}

	// 수급자 퇴소 급여 내역
	async getRecipientServices(params: RecipientServiceRequest) {
		return this.client
			.get<RecipientServiceResponse>(endpoint.getRecipientServices.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getRecipientIntegratedAssessmentSalaryOfferSend(
		params: GetRecipientIntegratedAssessmentSalaryOfferSendRequest,
	) {
		return this.client
			.get<GetRecipientIntegratedAssessmentSalaryOfferSendResponse>(
				endpoint.getRecipientIntegratedAssessmentSalaryOfferSend.path(params.salaryOfferPlanId),
			)
			.then(this.checkResponse);
	}

	async getRecipientIntegratedAssessmentSalaryOfferSendPreview(
		params: GetRecipientIntegratedAssessmentSalaryOfferSendPreviewRequest,
	) {
		return this.client
			.get<GetRecipientIntegratedAssessmentSalaryOfferSendPreviewResponse>(
				endpoint.getRecipientIntegratedAssessmentSalaryOfferSendPreview.path(
					params.salaryOfferPlanId,
				),
			)
			.then(this.checkResponse);
	}

	async deleteRecipientIntegratedAssessmentSalaryOffer(
		params: DeleteRecipientIntegratedAssessmentSalaryOfferRequest,
	) {
		return this.client
			.delete<DeleteRecipientIntegratedAssessmentSalaryOfferResponse>(
				endpoint.deleteRecipientIntegratedAssessmentSalaryOffer.path(params.salaryOfferPlanId),
			)
			.then(this.checkResponse);
	}

	async uploadRecipientIntegratedAssessmentSalaryOffer(
		params: UpdateRecipientIntegratedSalaryOfferRequest,
	) {
		const formData = new FormData();
		formData.append('file', params.file);
		formData.append('targetSeq', params.targetSeq.toString());
		formData.append('targetYear', params.targetYear);
		formData.append('recipientId', params.recipientId.toString());

		return this.client
			.post<UpdateRecipientIntegratedSalaryOfferResponse>(
				endpoint.uploadRecipientIntegratedAssessmentSalaryOffer.path(),
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			)
			.then(this.checkResponse);
	}

	async getRecipientIntegratedAssessmentSalaryOfferChangePlans(
		params: GetRecipientIntegratedAssessmentSalaryOfferChangePlanListRequest,
	) {
		return this.client
			.get<GetRecipientIntegratedAssessmentSalaryOfferChangePlanListResponse>(
				endpoint.getRecipientIntegratedAssessmentSalaryOfferChangePlans.path(
					params.salaryOfferPlanId,
				),
			)
			.then(this.checkResponse);
	}

	async getRecipientIntegratedAssessmentSalaryOfferChangePlan(
		params: GetRecipientIntegratedAssessmentSalaryOfferChangePlanDetailRequest,
	) {
		return this.client
			.get<GetRecipientIntegratedAssessmentSalaryOfferChangePlanDetailResponse>(
				endpoint.getRecipientIntegratedAssessmentSalaryOfferChangePlan.path(
					params.salaryOfferPlanId,
					params.salaryOfferChangePlanId,
				),
			)
			.then(this.checkResponse);
	}

	async deleteRecipientIntegratedAssessmentSalaryOfferChangePlan(
		params: DeleteRecipientIntegratedAssessmentSalaryOfferChangePlanDetailRequest,
	) {
		return this.client
			.delete<DeleteRecipientIntegratedAssessmentSalaryOfferChangePlanDetailResponse>(
				endpoint.deleteRecipientIntegratedAssessmentSalaryOfferChangePlan.path(
					params.salaryOfferPlanId,
					params.salaryOfferChangePlanId,
				),
			)
			.then((res) => res.data);
	}

	async createRecipientIntegratedAssessmentSalaryOfferChangePlan({
		salaryOfferPlanId,
		...rest
	}: CreateRecipientIntegratedAssessmentSalaryOfferChangePlanDetailRequest) {
		return this.client
			.post<CreateRecipientIntegratedAssessmentSalaryOfferChangePlanDetailResponse>(
				endpoint.createRecipientIntegratedAssessmentSalaryOfferChangePlan.path(salaryOfferPlanId),
				rest,
			)
			.then((res) => res.data);
	}

	async updateRecipientIntegratedAssessmentSalaryOfferChangePlan({
		salaryOfferPlanId,
		salaryOfferChangePlanId,
		...rest
	}: UpdateRecipientIntegratedAssessmentSalaryOfferChangePlanDetailRequest) {
		return this.client
			.patch<UpdateRecipientIntegratedAssessmentSalaryOfferChangePlanDetailResponse>(
				endpoint.updateRecipientIntegratedAssessmentSalaryOfferChangePlan.path(
					salaryOfferPlanId,
					salaryOfferChangePlanId,
				),
				rest,
			)
			.then((res) => res.data);
	}

	// 전자서명 재발송
	async resendEDoc(params: ResendEDocRequest) {
		return this.client
			.post<ResendEDocResponse>(endpoint.resendEDoc.path(), params)
			.then((res) => res.data);
	}

	// 자동화 슬랙 알림 발송
	async sendSlack(params: SendSlackRequest) {
		return this.client
			.post(endpoint.sendSlack.path(), {
				message:
					window.location.origin === 'https://erp2.caring.co.kr/'
						? `[PRODUCTION] <@U03LMEJ59U0> <@U02G797NH16> ${params.message}`
						: window.location.origin === 'https://dev-erp.caring.co.kr/'
							? `[DEV] <@U03LMEJ59U0> <@U02G797NH16> ${params.message}`
							: `[LOCAL] <@U03LMEJ59U0> <@U02G797NH16> ${params.message}`,
			})
			.then((res) => res.data);
	}

	// 4대보험 페이지 목록조회
	async getInsurance({ centerId, params }: { centerId?: number; params: InsuranceRequest }) {
		if (params.page === 0) return null;
		return this.client
			.get<InsuranceResponse>(endpoint.getInsurance.path(centerId), {
				params: getValidParam(params),
			})
			.then(this.checkResponse);
	}

	// 4대보험 일괄 진행중으로 상태 변경
	async updateInsuranceRequestState(params: UpdateInsuranceRequestStateRequest) {
		return this.client
			.patch<UpdateInsuranceRequestStateResponse>(
				endpoint.updateInsuranceRequestState.path(),
				params,
			)
			.then((res) => res.data);
	}

	async getContractDetail(params: GetContractDetailRequest) {
		return this.client
			.get<GetContractDetailResponse>(endpoint.getContractDetail.path(params.serviceContractId))
			.then(this.checkResponse);
	}

	async getContractRenewalDetail(params: GetContractRenewalDetailRequest) {
		return this.client
			.get<GetContractRenewalDetailResponse>(
				endpoint.getContractRenewalDetail.path(params.serviceContractGroupUuid),
			)
			.then(this.checkResponse);
	}

	async getContractChangeEmployeeDetail(params: GetContractChangeEmployeeDetailRequest) {
		return this.client
			.get<GetContractChangeEmployeeDetailResponse>(
				endpoint.getContractChangeEmployeeDetail.path(params.serviceContractId),
			)
			.then(this.checkResponse);
	}

	async saveCompleteContract({ serviceContractId, ...rest }: SaveCompleteContractRequest) {
		return this.client
			.post<SaveCompleteContractResponse>(
				endpoint.saveCompleteContract.path(serviceContractId),
				checkEmptyValues(rest),
			)
			.then(this.checkMutationResponse);
	}

	async saveTempContract({ serviceContractId, ...rest }: SaveTempContractRequest) {
		return this.client
			.post<SaveTempContractResponse>(
				endpoint.saveTempContract.path(serviceContractId),
				checkEmptyValues(rest),
			)
			.then(this.checkMutationResponse);
	}

	async getCenterAccounts() {
		return this.client
			.get<GetCenterAccountResponse>(endpoint.getCenterAccounts.path())
			.then(this.checkResponse);
	}

	async getCenterAccountRecords(params: getCenterAccountRecordsRequestDTO) {
		if (params.page === 0) return null;
		return this.client
			.get<GetCenterAccountRecordsResponse>(endpoint.getCenterAccountRecords.path(), { params })
			.then(this.checkResponse);
	}

	async updateCenterAccountRecords(params: updateCenterAccountRecordsRequestDTO) {
		const queryString = objectToQueryString(params);
		return this.client
			.post<UpdateCenterAccountRecordsResponse>(
				endpoint.updateCenterAccountRecords.path(queryString),
				{},
				// 백엔드 타임아웃 300s + 여유시간 10s
				{ timeout: 1000 * 600 + 1000 * 10 },
			)
			.then((res) => res.data);
	}

	async updateCenterAccountRecordDetail(params: UpdateCenterAccountRecordDetailRequestDTO) {
		return this.client
			.post<UpdateCenterAccountRecordDetailResponse>(
				endpoint.updateCenterAccountRecordDetail.path(),
				params,
			)
			.then((res) => res.data);
	}

	async updateCenterAccountChildRecordDetail({
		centerAccountRecordDetailId,
		...prams
	}: UpdateCenterAccountChildRecordDetailRequestDTO) {
		return this.client
			.post<UpdateCenterAccountChildRecordDetailResponse>(
				endpoint.updateCenterAccountChildRecordDetail.path(centerAccountRecordDetailId),
				prams,
			)
			.then((res) => res.data);
	}

	async getCenterAccountRecordTargets(params: GetCenterAccountRecordTargetRequestDTO) {
		return this.client
			.get<SaveTempContractResponse>(endpoint.getCenterAccountRecordTargets.path(), { params })
			.then(this.checkResponse);
	}

	async getCenterAccountRecordSummary(params: GetCenterAccountRecordSummaryRequestDTO) {
		return this.client
			.get<GetCenterAccountRecordsSummaryResponse>(endpoint.getCenterAccountRecordSummarys.path(), {
				params,
			})
			.then((res) => res.data);
	}

	async getInsuranceEtcExcelDownload(params: InsuranceEtcExcelDownloadRequest) {
		return this.client
			.get<InsuranceEtcExcelDownloadResponse>(endpoint.getInsuranceEtcExcelDownload.path(params))
			.then((res) => res.data);
	}

	async sendRecipientContract(params: SendRecipientContractRequest) {
		return this.client
			.post<SendRecipientContractResponse>(endpoint.sendRecipientContract.path(), params)
			.then(this.checkMutationResponse);
	}

	async sendRecipientRenewalContract(params: SendRecipientContractRenewalRequest) {
		return this.client
			.post<SendRecipientContractRenewalResponse>(
				endpoint.sendRecipientRenewalContract.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async sendEmployeeContract(params: SendEmployeeContractRequest) {
		return this.client
			.post<SendEmployeeContractResponse>(endpoint.sendEmployeeContract.path(), params)
			.then(this.checkMutationResponse);
	}

	async getContractSendStatus(params: GetContractSendStatusRequest) {
		return this.client
			.get<GetContractSendStatusResponse>(endpoint.getContractSendStatus.path(), { params })
			.then(this.checkMutationResponse);
	}

	async createRenewalRecipientContract(params: CreateRenewalRecipientContractRequest) {
		return this.client
			.post<CreateRenewalRecipientContractResponse>(
				endpoint.createRenewalRecipientContract.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async renewalRecipientContract(params: RenewalRecipientContractRequest) {
		return this.client
			.post<RenewalRecipientContractResponse>(
				endpoint.renewalRecipientContract.path(),
				checkEmptyValues(params),
			)
			.then(this.checkMutationResponse);
	}

	async saveRenewalRecipientContract(params: RenewalRecipientContractRequest) {
		return this.client
			.post<RenewalRecipientContractResponse>(
				endpoint.saveRenewalRecipientContract.path(),
				checkEmptyValues(params),
			)
			.then(this.checkMutationResponse);
	}

	async changeEmployeeContract(params: SaveChangeEmployeeContractRequest) {
		return this.client
			.post<SaveChangeEmployeeContractResponse>(
				endpoint.changeEmployeeContract.path(),
				checkEmptyValues(params),
			)
			.then(this.checkMutationResponse);
	}

	async changeTempEmployeeContract(params: SaveChangeEmployeeContractRequest) {
		return this.client
			.post<SaveChangeEmployeeContractResponse>(
				endpoint.changeTempEmployeeContract.path(),
				checkEmptyValues(params),
			)
			.then(this.checkMutationResponse);
	}

	async createChangeEmployeeContract(params: CreateChangeEmployeeContractRequest) {
		return this.client
			.post<CreateChangeEmployeeContractResponse>(
				endpoint.createChangeEmployeeContract.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async getEmployeeInsurance(params: EmployeeInsuranceRequest) {
		return this.client
			.get<EmployeeInsuranceResponse>(endpoint.getEmployeeInsurance.path(params.employeeId), {
				params: getValidParam(params),
			})
			.then(this.checkResponse);
	}

	async getServiceEmployees(params: GetServiceEmployeeRequest) {
		return this.client
			.get<GetServiceEmployeeResponse>(
				endpoint.getServiceEmployees.path(params.serviceContractDetailId),
			)
			.then(this.checkResponse);
	}

	async changeContractStatus({ serviceContractId, ...rest }: ChangeContractStatusRequest) {
		return this.client
			.patch<ChangeContractStatusResponse>(
				endpoint.changeContractStatus.path(serviceContractId),
				rest,
			)
			.then(this.checkResponse);
	}

	async createInsurance({
		employeeId,
		params,
	}: {
		employeeId: number;
		params: CreateInsuranceRequest;
	}) {
		return this.client
			.post<CreateInsuranceResponse>(endpoint.createInsurance.path(employeeId), params)
			.then((res) => res.data);
	}

	async createInsuranceList({
		employeeId,
		params,
	}: {
		employeeId: number;
		params: CreateAcquirementInsuranceListRequest[];
	}) {
		return this.client
			.post<CreateInsuranceResponse>(endpoint.createInsuranceList.path(employeeId), params)
			.then((res) => res.data);
	}

	async getWageDeductionEmployees(params: GetWageDeductionEmployeesRequest) {
		return this.client
			.get<GetWageDeductionEmployeesResponse>(endpoint.getWageDeductionEmployees.path(), { params })
			.then(this.checkResponse);
	}

	async getInsurancePriorInfo({ employeeId, insuranceTypeCd, ...res }: InsurancePriorInfoRequest) {
		return this.client
			.get<InsurancePriorInfoResponse>(
				endpoint.getInsurancePriorInfo.path(employeeId, insuranceTypeCd),
				{ params: res },
			)
			.then(this.checkResponse);
	}

	async getInsuranceSalaryFeeInfo({
		centerId,
		targetDt,
		employeeId,
	}: InsuranceSalaryFeeInfoRequest) {
		return this.client
			.get<InsuranceSalaryFeeInfoResponse>(
				endpoint.getInsuranceSalaryFeeInfo.path(targetDt, employeeId),
				{
					params: {
						centerId,
					},
				},
			)
			.then((data) => data.data.data ?? null);
	}

	async getInsuranceDetailInfo({ employeeId, insuranceRequestId }: InsuranceDetailRequest) {
		return this.client
			.get<InsuranceDetailResponse>(
				endpoint.updateInsuranceDetail.path(employeeId, insuranceRequestId),
			)
			.then(this.checkResponse);
	}

	async updateInsuranceDetailInfo({
		employeeId,
		insuranceRequestId,
		...rest
	}: UpdateInsuranceDetailRequest) {
		return this.client
			.put<UpdateInsuranceDetailResponse>(
				endpoint.updateInsuranceDetail.path(employeeId, insuranceRequestId),
				rest,
			)
			.then((res) => res.data);
	}

	async uploadVisitNursing({ serviceContractId, ...rest }: UploadVisitNursingRequest) {
		return this.client
			.post<UploadVisitNursingResponse>(endpoint.uploadVisitNursing.path(serviceContractId), rest)
			.then((res) => res.data);
	}

	async checkLongTermDuplication(params: CheckLongTermDuplicationRequest) {
		return this.client
			.get<CheckLongTermDuplicationResponse>(endpoint.checkLongTermDuplication.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async updateEmployeeTagCall(params: UpdateEmployeeTagCallRequest) {
		return this.client
			.post<UpdateEmployeeTagCallResponse>(endpoint.updateEmployeeTagCall.path(), params)
			.then(this.checkResponse);
	}

	async getServiceTerminationInfo(params: GetServiceTerminationInfoRequest) {
		return this.client
			.get<GetServiceTerminationInfoResponse>(
				endpoint.getServiceTerminationInfo.path(params.serviceContractDetailId),
			)
			.then(this.checkResponse);
	}

	async serviceTermination(params: ServiceTerminationRequest) {
		return this.client
			.post<ServiceTerminationResponse>(endpoint.serviceTermination.path(), params)
			.then((res) => res.data);
	}

	async getInsuranceAcqExcelDownload(params: InsuranceAcqExcelDownloadRequest) {
		return this.client
			.get<InsuranceAcqExcelDownloadResponse>(endpoint.getInsuranceAcqExcelDownload.path(params))
			.then((res) => res.data);
	}

	async getInsuranceLossExcelDownload(params: InsuranceLossExcelDownloadRequest) {
		return this.client
			.get<InsuranceLossExcelDownloadResponse>(endpoint.getInsuranceLossExcelDownload.path(params))
			.then((res) => res.data);
	}

	async createRecipientDocument(params: CreateRecipientDocumentRequest) {
		return this.client
			.post<CreateRecipientDocumentResponse>(endpoint.createRecipientDocument.path(), params)
			.then(this.checkMutationResponse);
	}

	async createRecipientRenewalDocument(params: CreateRecipientRenewalDocumentRequest) {
		return this.client
			.post<CreateRecipientRenewalDocumentResponse>(
				endpoint.createRecipientRenewalDocument.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async getEmployeeWage(params: GetEmployeeWageRequest) {
		return this.client
			.get<GetEmployeeWageResponse>(endpoint.getEmployeeWage.path(), { params })
			.then(this.checkResponse);
	}

	async createEmployeeDocument(params: CreateEmployeeDocumentRequest) {
		return this.client
			.post<CreateEmployeeDocumentResponse>(endpoint.createEmployeeDocument.path(), params)
			.then(this.checkMutationResponse);
	}

	async uploadRecipientDocument(params: UploadRecipientDocumentRequest) {
		return this.client
			.patch<UploadRecipientDocumentResponse>(endpoint.uploadRecipientDocument.path(), params)
			.then((res) => res.data);
	}

	async uploadRecipientRenewalDocument(params: UploadRecipientRenewalDocumentRequest) {
		return this.client
			.patch<UploadRecipientRenewalDocumentResponse>(
				endpoint.uploadRecipientRenewalDocument.path(),
				params,
			)
			.then((res) => res.data);
	}

	async uploadEmployeeDocument(params: UploadEmployeeDocumentRequest) {
		return this.client
			.patch<UploadEmployeeDocumentResponse>(endpoint.uploadEmployeeDocument.path(), params)
			.then((res) => res.data);
	}

	async getDocument(params: GetContractDocumentRequest) {
		if (!params.serviceContractIds.length) return null;
		return this.client
			.get<GetContractDocumentResponse>(endpoint.getDocument.path(), { params })
			.then(this.checkResponse);
	}

	async getRecipientDocument(params: GetRecipientDocumentRequest) {
		return this.client
			.get<GetRecipientDocumentResponse>(
				endpoint.getRecipientDocument.path(params.recipientContractId),
			)
			.then(this.checkResponse);
	}

	async getRecipientRenewalDocument(params: GetRecipientRenewalDocumentRequest) {
		return this.client
			.get<GetRecipientRenewalDocumentResponse>(endpoint.getRecipientRenewalDocument.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getEmployeeDocument(params: GetEmployeeDocumentRequest) {
		return this.client
			.get<GetEmployeeDocumentResponse>(
				endpoint.getEmployeeDocument.path(params.employeeContractId),
			)
			.then(this.checkResponse);
	}

	async getDocumentTemplate() {
		return this.client
			.get<GetDocumentTemplateResponse>(endpoint.getDocumentTemplate.path())
			.then(this.checkResponse);
	}

	async deletePaper(params: DeletePaperRequestDTO) {
		return this.client
			.delete<DeletePaperResponse>(endpoint.deletePaper.path(), {
				data: params,
			})
			.then((res) => res.data);
	}

	// 실명인증
	async checkRealName(params: CheckRealNameRequest): Promise<CheckRealNameResponse> {
		return this.client
			.get<CheckRealNameResponse>(endpoint.checkRealName.path(), {
				params,
			})
			.then((res) => res.data);
	}

	// 수급자 일정자동화 정보 저장
	async updateRecipientScheduleAutomation({
		centerId,
		recipientId,
		params,
	}: UpdateScheduleAutomationRequest) {
		return this.client
			.post<UpdateScheduleAutomationResponse>(
				endpoint.recipientUpdateScheduleAutomation.path(centerId, recipientId),
				params,
			)
			.then((res) => res.data);
	}

	async getCenterAccountRecordsDownload(
		params: GetCenterAccountRecordsDownloadRequest,
	): Promise<GetCenterAccountRecordsDownloadResponse> {
		return this.client
			.get<GetCenterAccountRecordsDownloadResponse>(endpoint.getCenterAccountExcelDownload.path(), {
				params,
			})
			.then((res) => res.data);
	}

	// 수급자 삭제
	async deleteRecipient(params: UpdateRecipientAffiliationRequest) {
		return this.client
			.post<UpdateRecipientAffiliationResponse>(endpoint.updateRecipientAffiliation.path(), params)
			.then((res) => res.data);
	}

	// 직원 삭제
	async deleteEmployee(params: UpdateEmployeeAffiliationRequest) {
		return this.client
			.post<UpdateEmployeeAffiliationResponse>(endpoint.updateEmployeeAffiliation.path(), params)
			.then((res) => res.data);
	}

	// 갱신 계약 삭제
	async deleteRenewalContract({
		serviceContractGroupUuid,
		serviceContractId,
	}: DeleteContractRequest) {
		if (!serviceContractGroupUuid) return null;
		return this.client
			.post<DeleteContractResponse>(
				endpoint.deleteRenewalContract.path(serviceContractGroupUuid),
				null,
				{
					params: {
						serviceContractId,
					},
				},
			)
			.then((res) => res.data);
	}

	// 일반 계약 삭제
	async deleteServiceContract({ serviceContractId }: DeleteContractRequest) {
		return this.client
			.post<DeleteContractResponse>(endpoint.deleteServiceContract.path(serviceContractId))
			.then((res) => res.data);
	}

	// 업무수행일지 이력
	async getWorkExecuteLog(params: GetWorkExecuteLogRequest) {
		return this.client
			.get<GetWorkExecuteLogResponse>(endpoint.getWorkExecuteLog.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 직원 발송 이력 - 메시지 상세
	async getRecipientSendHistoryDetail(params: RecipientSendHistoryTabDetailRequest) {
		if (!params.recipientId || !params.sendDetailId) return null;
		return this.client
			.get<RecipientSendHistoryDetailResponse>(
				endpoint.getRecipientSendHistoryDetail.path(params.recipientId, params.sendDetailId),
			)
			.then(this.checkResponse);
	}

	// 직원 발송 이력 - 메시지 상세
	async getEmployeeSendHistoryDetail(params: EmployeeSendHistoryDetailRequest) {
		if (!params.employeeId || !params.sendDetailId) return null;
		return this.client
			.get<EmployeeSendHistoryDetailResponse>(
				endpoint.getEmployeeSendHistoryDetail.path(params.employeeId, params.sendDetailId),
			)
			.then(this.checkResponse);
	}

	// 업무수행일지 신규 조회
	async getRecipientWorkExecuteLogNew(params: GetRecipientWorkExecuteLogNewRequest) {
		return this.client
			.get<GetRecipientWorkExecuteLogNewResponse>(endpoint.getRecipientWorkExecuteLogNew.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 업무수행일지 일자별 일정조회
	async getWorkExecuteLogServiceSchedulePlan(params: GetWorkExecuteLogServiceSchedulePlanRequest) {
		return this.client
			.get<GetWorkExecuteLogServiceSchedulePlanResponse>(
				endpoint.getWorkExecuteLogServiceSchedulePlan.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async getRecipientHoldingHistories(params: GetRecipientHoldingHistoriesRequest) {
		return this.client
			.get<GetRecipientHoldingHistoriesResponse>(
				endpoint.getRecipientHoldingHistories.path(params.recipientId),
			)
			.then(this.checkResponse);
	}

	// 업무수행일지 저장
	async updateRecipientWorkExecuteLogNew(params: UpdateRecipientWorkExecuteLogRequest) {
		return this.client
			.post<UpdateRecipientWorkExecuteLogResponse>(endpoint.updateWorkExecuteLog.path(), params)
			.then((res) => res.data);
	}

	// 업무수행일지 상세
	async getWorkExecuteLogDetail(params: GetWorkExecuteLogDetailRequest) {
		return this.client
			.get<GetWorkExecuteLogDetailResponse>(
				endpoint.getRecipientWorkExecuteLogDetail.path(params.workExecuteLogId),
			)
			.then(this.checkResponse);
	}

	async getWorkExecuteInspectionLog(
		params: GetWorkExecuteInspectionLogRequest,
	): Promise<GetWorkExecuteInspectionLogData | null> {
		return this.client
			.get<GetWorkExecuteInspectionLogResponse>(
				endpoint.getWorkExecuteInspectionLog.path(params.edocUuid),
			)
			.then(this.checkResponse);
	}

	async updateWorkExecuteInspectionLog(
		params: UpdateWorkExecuteInspectionLogRequest,
	): Promise<UpdateWorkExecuteInspectionLogResponse> {
		return this.client
			.post<UpdateWorkExecuteInspectionLogResponse>(
				endpoint.updateWorkExecuteInspectionLog.path(),
				params,
			)
			.then((res) => res.data);
	}

	async deleteWorkExecuteLog(params: DeleteWorkExecuteLogRequest) {
		return this.client
			.delete<DeleteWorkExecuteLogResponse>(
				endpoint.deleteWorkExecuteLog.path(params.workExecuteLogId),
			)
			.then((res) => res.data);
	}

	// 수급자 목록 엑셀다운로드
	async getRecipientsExcelInfo(params: GetRecipientsExcelRequest) {
		return this.client
			.get<GetRecipientsExcelResponse>(endpoint.getRecipientsExcelInfo.path(), {
				params,
				timeout: 60 * 1000 * 10,
			})
			.then(this.checkResponse);
	}

	// 직원 목록 엑셀다운로드
	async getEmployeesExcelInfo(params: GetEmployeesExcelRequest) {
		return this.client
			.get<GetEmployeesExcelResponse>(endpoint.getEmployeesExcelInfo.path(), {
				params,
				timeout: 60 * 1000 * 10,
			})
			.then(this.checkResponse);
	}

	// 업무수행일지 상담자 목록
	async getRecipientConsultClient({
		recipientId,
		centerId,
	}: GetRecipientConsultClientRequest): Promise<GetRecipientConsultClientDTO[] | null> {
		return this.client
			.get<GetRecipientConsultClientResponse>(
				endpoint.getRecipientConsultClient.path(recipientId),
				{
					params: {
						centerId,
					},
				},
			)
			.then(this.checkResponse);
	}

	async createRecipientHoldingHistory({ recipientId, ...params }: CreateRecipientHoldingRequest) {
		return this.client
			.post<CreateRecipientHoldingResponse>(
				endpoint.createRecipientHoldingHistory.path(recipientId),
				params,
			)
			.then(this.checkResponse);
	}

	async updateRecipientHoldingHistory({ recipientId, ...params }: UpdateRecipientHoldingRequest) {
		return this.client
			.post<UpdateRecipientHoldingResponse>(
				endpoint.updateRecipientHoldingHistory.path(recipientId),
				params,
			)
			.then(this.checkResponse);
	}

	async deleteRecipientHoldingHistory(params: DeleteRecipientHoldingRequest) {
		return this.client
			.delete<DeleteRecipientHoldingResponse>(
				endpoint.deleteRecipientHoldingHistory.path(
					params.recipientId,
					params.recipientPendingHistId,
				),
			)
			.then(this.checkResponse);
	}

	async getMyCenterAccountRoles(): Promise<AccountRolesDTO[] | null> {
		return this.client
			.get<AccountRolesResponse>(endpoint.getMyCenterAccountRoles.path())
			.then(this.checkResponse);
	}

	async getMyCenterAccounts(params: MyCenterAccountsRequest) {
		const validParams = getValidParam(params);
		return this.client
			.get<MyCenterAccountsResponse>(endpoint.getMyCenterAccounts.path(), {
				params: validParams,
			})
			.then(this.checkResponse);
	}

	async activateAccount(params: UserAccountActivateRequest) {
		return this.client
			.patch<UserAccountActivateResponse>(endpoint.activateAccount.path(params.userId))
			.then((res) => res.data);
	}

	async deactivateAccount(params: UserAccountActivateRequest) {
		return this.client
			.patch<UserAccountActivateResponse>(endpoint.deactivateAccount.path(params.userId))
			.then((res) => res.data);
	}

	async getMyCenterDocs(params: MyCenterDocsRequest) {
		return this.client
			.get<MyCenterDocsResponse>(endpoint.getMyCenterDocs.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 내센터 문서 수정
	async updateMyCenterDocument(params: UpdateMyCenterDocsRequest) {
		return this.client
			.post<UpdateMyCenterDocsResponse>(endpoint.updateMyCenterDocs.path(), params)
			.then((res) => res.data);
	}

	// 내 센터 문서 삭제
	async deleteMyCenterDocument(params: DeleteMyCenterDocsRequest) {
		return this.client
			.delete<DeleteMyCenterDocsResponse>(endpoint.deleteMyCenterDocs.path(), {
				data: params,
			})
			.then((res) => res.data);
	}

	// 내 센터 - 센터 정보
	async getMyCenterInfo(params: GetCenterInfoRequest) {
		return this.client
			.get<GetCenterInfoResponse>(endpoint.getMyCenterInfo.path(params.centerId))
			.then(this.checkResponse);
	}

	// 내 센터 - 센터 정보 저장
	async saveMyCenterInfo(params: SaveCenterInfoRequest) {
		return this.client
			.post<SaveCenterInfoResponse>(endpoint.saveMyCenterInfo.path(), params)
			.then((res) => res.data);
	}

	// 센터장 목록 조회
	async getCenterChiefs() {
		return this.client
			.get<GetCenterChiefsResponse>(endpoint.getCenterChiefs.path())
			.then(this.checkResponse);
	}

	// 센터제공서비스 목록 조회
	async getCenterOfferServices(params: GetCenterOfferServicesRequest) {
		return this.client
			.get<GetCenterOfferServicesResponse>(endpoint.getCenterOfferServices.path(params.centerId))
			.then(this.checkResponse);
	}

	async getEmployeeWorkInfoList(
		params: GetEmployeeWorkInfoListRequest,
	): Promise<GetEmployeeWorkInfoListData | null> {
		const validParam = getValidParam(params);
		return this.client
			.get<GetEmployeeWorkInfoListResponse>(endpoint.getEmployeeWorkInfoList.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async getEmployeeWorkSchedule(
		params: GetEmployeeWorkScheduleRequest,
	): Promise<GetEmployeeWorkScheduleData | null> {
		return this.client
			.get<GetEmployeeWorkScheduleResponse>(endpoint.getEmployeeWorkSchedule.path(), { params })
			.then(this.checkResponse);
	}

	// 내 센터 - 센터 계좌 조회
	async getCenterAccountManagement(params: CenterAccountManagementRequest) {
		return this.client
			.get<CenterAccountManagementResponse>(endpoint.getCenterAccountManagement.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 내 센터 - 센터 계좌 추가
	async saveCenterAccount(params: CenterAccountSaveRequest) {
		return this.client
			.post<CenterAccountSaveResponse>(endpoint.saveCenterAccount.path(), params)
			.then((res) => res.data);
	}

	// 내 센터 - 센터 계좌 수정
	async updateCenterAccount(params: CenterAccountSaveRequest) {
		return this.client
			.put<CenterAccountSaveResponse>(endpoint.updateCenterAccount.path(), params)
			.then((res) => res.data);
	}

	// 내 센터 - 센터 계좌 삭제
	async deleteCenterAccount(params: CenterAccountDeleteRequest) {
		return this.client
			.delete<CenterAccountDeleteResponse>(
				endpoint.deleteCenterAccount.path(params.centerAccountId),
			)
			.then((res) => res.data);
	}

	// 내 센터 - 재무 정보 조회
	async getCenterSalaryFeeInfoDTO(params: GetCenterSalaryFeeInfoRequest) {
		return this.client
			.get<GetCenterSalaryFeeInfoResponse>(endpoint.getCenterSalaryFeeInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 내 센터 - 현재 적용된 재무정보
	async getCurrentApplySalaryFeeInfo(params: GetCurrentApplySalaryFeeInfoRequest) {
		return this.client
			.get<GetCurrentApplySalaryFeeInfoResponse>(
				endpoint.getCurrentApplySalaryFeeInfo.path(params.centerId),
			)
			.then((res) => res.data);
	}

	// 내 센터 - 재무기준시작연월 중복 조회
	async checkSalaryDuplicatedPeriod(params: CheckSalaryDuplicatedPeriodRequest) {
		return this.client
			.get<CheckSalaryDuplicatedPeriodResponse>(endpoint.checkSalaryDuplicatedPeriod.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 내 센터 - 센터 재무 정보 추가
	async createCenterSalaryFeeInfo({ centerId, ...res }: CreateCenterSalaryFeeInfoRequest) {
		return this.client
			.put<CreateCenterSalaryFeeInfoResponse>(
				endpoint.createCenterSalaryFeeInfo.path(centerId),
				res,
			)
			.then((res) => res.data);
	}

	// 내 센터 - 센터 재무 상세
	async getCenterSalaryFeeDetail({ centerId, ...res }: GetCenterSalaryFeeDetailRequest) {
		return this.client
			.get<GetCenterSalaryFeeDetailResponse>(endpoint.getCenterSalaryFeeDetail.path(centerId), {
				params: res,
			})
			.then(this.checkResponse);
	}

	// 내 센터 - 센터 재무 정보 수정
	async updateCenterSalaryFeeInfo({ centerId, ...res }: UpdateCenterSalaryFeeInfoRequest) {
		return this.client
			.patch<UpdateCenterSalaryFeeInfoResponse>(
				endpoint.updateCenterSalaryFeeInfo.path(centerId),
				res,
			)
			.then((res) => res.data);
	}

	// 내 센터 - 재무기준시작연월 기간 중복 조회
	async checkSalaryDuplicatedRange(params: CheckSalaryDuplicatedRangeRequest) {
		return this.client
			.get<CheckSalaryDuplicatedRangeResponse>(endpoint.checkSalaryDuplicatedRange.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getFullTimeEmployees(params: GetFullTimeEmployeesRequest) {
		return this.client
			.get<GetFullTimeEmployeesResponse>(endpoint.getFullTimeEmployees.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeBaseInfo(params: GetFullTimeEmployeeBaseInfoRequest) {
		return this.client
			.get<GetFullTimeEmployeeBaseInfoResponse>(endpoint.getFullTimeEmployeeBaseInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async checkFullTimeEmployeeDuplication(params: CheckFullTimeEmployeeDuplicationRequest) {
		return this.client
			.get<CheckFullTimeEmployeeDuplicationResponse>(
				endpoint.checkFullTimeEmployeeDuplication.path(),
				{
					params,
				},
			)
			.then((res) => res.data);
	}

	async createFullTimeEmployee(params: CreateFullTimeEmployeeRequest) {
		return this.client
			.post<CreateFullTimeEmployeeResponse>(endpoint.createFullTimeEmployee.path(), params)
			.then((res) => res.data);
	}

	async deleteFullTimeEmployee(params: DeleteFullTimeEmployeeRequest) {
		return this.client
			.delete<DeleteFullTimeEmployeeResponse>(endpoint.deleteFullTimeEmployee.path(), {
				params,
			})
			.then((res) => res.data);
	}

	async checkFullTimeEmployeeDeletable(params: CheckFullTimeEmployeeDeletableRequest) {
		return this.client
			.get<CheckFullTimeEmployeeDeletableResponse>(endpoint.checkFullTimeEmployeeDeletable.path(), {
				params,
			})
			.then(this.checkMutationResponse);
	}

	async getFullTimeEmployeeDetailInfo(params: GetFullTimeEmployeeDetailInfoRequest) {
		return this.client
			.get<GetFullTimeEmployeeDetailInfoResponse>(endpoint.getFullTimeEmployeeDetailInfo.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async updateFullTimeEmployeeDetailInfo(params: UpdateFullTimeEmployeeDetailInfoRequest) {
		return this.client
			.post<UpdateFullTimeEmployeeDetailInfoResponse>(
				endpoint.updateFullTimeEmployeeDetailInfo.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeAnnualTOHistories(params: GetFullTimeEmployeeAnnualTOHistoriesRequest) {
		return this.client
			.get<GetFullTimeEmployeeAnnualTOHistoriesResponse>(
				endpoint.getFullTimeEmployeeAnnualTOHistories.path(),
				{ params },
			)
			.then(this.checkResponse);
	}

	async updateFullTimeEmployeeAnnualTOHistories(
		params: updateFullTimeEmployeeAnnualTOHistoriesRequest,
	) {
		return this.client
			.post<updateFullTimeEmployeeAnnualTOHistoriesResponse>(
				endpoint.updateFullTimeEmployeeAnnualTOHistories.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeWorkSchedule(params: GetEmployeeWorkScheduleRequest) {
		return this.client
			.get<GetEmployeeWorkScheduleResponse>(endpoint.getFullTimeEmployeeWorkSchedule.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeLicenses(params: GetFullTimeEmployeeLicensesRequest) {
		return this.client
			.get<GetFullTimeEmployeeLicensesResponse>(endpoint.getFullTimeEmployeeLicenses.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async deleteFullTimeEmployeeLicense(params: DeleteFullTimeEmployeeLicenseRequest) {
		return this.client
			.delete<DeleteFullTimeEmployeeLicenseResponse>(
				endpoint.deleteFullTimeEmployeeLicense.path(),
				{ data: params },
			)
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeLicense(params: SaveFullTimeEmployeeLicenseRequest) {
		return this.client
			.post<SaveFullTimeEmployeeLicenseResponse>(
				endpoint.saveFullTimeEmployeeLicense.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeEducations(params: GetFullTimeEmployeeEducationsRequest) {
		return this.client
			.get<GetFullTimeEmployeeEducationsResponse>(endpoint.getFullTimeEmployeeEducations.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async deleteFullTimeEmployeeEducation(params: DeleteFullTimeEmployeeEducationRequest) {
		return this.client
			.delete<DeleteFullTimeEmployeeEducationResponse>(
				endpoint.deleteFullTimeEmployeeEducation.path(),
				{ data: params },
			)
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeEducation(params: SaveFullTimeEmployeeEducationRequest) {
		return this.client
			.post<SaveFullTimeEmployeeEducationResponse>(
				endpoint.saveFullTimeEmployeeEducation.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeWorkDetails(params: GetFullTimeEmployeeWorkDetailsRequest) {
		return this.client
			.get<GetFullTimeEmployeeWorkDetailsResponse>(endpoint.getFullTimeEmployeeWorkDetails.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeWorkStates(params: GetFullTimeEmployeeWorkStatesRequest) {
		return this.client
			.get<GetFullTimeEmployeeWorkStatesResponse>(endpoint.getFullTimeEmployeeWorkStates.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeWorkState(params: SaveFullTimeEmployeeWorkStateRequest) {
		return this.client
			.post<SaveFullTimeEmployeeWorkStateResponse>(
				endpoint.saveFullTimeEmployeeWorkState.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async deleteFullTimeEmployeeWorkState(params: DeleteFullTimeEmployeeWorkStateRequest) {
		return this.client
			.delete<DeleteFullTimeEmployeeWorkStateResponse>(
				endpoint.deleteFullTimeEmployeeWorkState.path(params.ftimeEmployeeWorkStateHistId),
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeDuties(params: GetFullTimeEmployeeDutiesRequest) {
		return this.client
			.get<GetFullTimeEmployeeDutiesResponse>(endpoint.getFullTimeEmployeeDuties.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeDuty(params: SaveFullTimeEmployeeDutyRequest) {
		return this.client
			.post<SaveFullTimeEmployeeDutyResponse>(endpoint.saveFullTimeEmployeeDuty.path(), params)
			.then(this.checkMutationResponse);
	}

	async deleteFullTimeEmployeeDuty(params: DeleteFullTimeEmployeeDutyRequest) {
		return this.client
			.delete<DeleteFullTimeEmployeeDutyResponse>(
				endpoint.deleteFullTimeEmployeeDuty.path(params.ftimeEmployeeDutyHistId),
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeCrimeHistories(params: GetFullTimeEmployeeCrimeHistoriesRequest) {
		return this.client
			.get<GetFullTimeEmployeeCrimeHistoriesResponse>(
				endpoint.getFullTimeEmployeeCrimeHistories.path(),
				{ params },
			)
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeCrimeHistory(params: SaveFullTimeEmployeeCrimeHistoryRequest) {
		return this.client
			.post<SaveFullTimeEmployeeCrimeHistoryResponse>(
				endpoint.saveFullTimeEmployeeCrimeHistory.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeLegalEducations(params: GetFullTimeEmployeeLegalEducationsRequest) {
		return this.client
			.get<GetFullTimeEmployeeLegalEducationsResponse>(
				endpoint.getFullTimeEmployeeLegalEducations.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeDementiaEducations(
		params: GetFullTimeEmployeeDementiaEducationsRequest,
	) {
		return this.client
			.get<GetFullTimeEmployeeDementiaEducationsResponse>(
				endpoint.getFullTimeEmployeeDementiaEducations.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeDementiaEducation(
		params: SaveFullTimeEmployeeDementiaEducationRequest,
	) {
		return this.client
			.post<SaveFullTimeEmployeeDementiaEducationResponse>(
				endpoint.saveFullTimeEmployeeDementiaEducation.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async getFullTimeEmployeeAnnualTOGenerateAdjust(
		params: GetFullTimeEmployeeAnnualTOGenerateAdjustRequest,
	) {
		return this.client
			.get<GetFullTimeEmployeeAnnualTOGenerateAdjustResponse>(
				endpoint.getFullTimeEmployeeAnnualTOGenerateAdjust.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeAnnualTOGenerateAdjust(
		params: SaveFullTimeEmployeeAnnualTOGenerateAdjustRequest,
	) {
		return this.client
			.post<SaveFullTimeEmployeeAnnualTOGenerateAdjustResponse>(
				endpoint.saveFullTimeEmployeeAnnualTOGenerateAdjust.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async getFullTimeEmployeeAnnualTORemainAdjust(
		params: GetFullTimeEmployeeAnnualTORemainAdjustRequest,
	) {
		return this.client
			.get<GetFullTimeEmployeeAnnualTORemainAdjustResponse>(
				endpoint.getFullTimeEmployeeAnnualTORemainAdjust.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeAnnualTORemainAdjust(
		params: SaveFullTimeEmployeeAnnualTORemainAdjustRequest,
	) {
		return this.client
			.post<SaveFullTimeEmployeeAnnualTORemainAdjustResponse>(
				endpoint.saveFullTimeEmployeeAnnualTORemainAdjust.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async getFullTimeEmployeeAnnualTOWorkYearCntAdjust(
		params: GetFullTimeEmployeeAnnualTOWorkYearCntAdjustRequest,
	) {
		return this.client
			.get<GetFullTimeEmployeeAnnualTOWorkYearCntAdjustResponse>(
				endpoint.getFullTimeEmployeeAnnualTOWorkYearCntAdjust.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeAnnualTOWorkYearCntAdjust(
		params: SaveFullTimeEmployeeAnnualTOWorkYearCntAdjustRequest,
	) {
		return this.client
			.post<SaveFullTimeEmployeeAnnualTOWorkYearCntAdjustResponse>(
				endpoint.saveFullTimeEmployeeAnnualTOWorkYearCntAdjust.path(),
				params,
			)
			.then(this.checkMutationResponse);
	}

	async moveFullTimeEmployeeCenter(params: MoveFullTimeEmployeeCenterRequest) {
		return this.client
			.post<MoveFullTimeEmployeeCenterResponse>(endpoint.moveFullTimeEmployeeCenter.path(), params)
			.then(this.checkMutationResponse);
	}

	async cancelMovingFullTimeEmployeeCenter(params: CancelMovingFullTimeEmployeeCenterRequest) {
		return this.client
			.delete<CancelMovingFullTimeEmployeeCenterResponse>(
				endpoint.cancelMovingFullTimeEmployeeCenter.path(params.ftimeEmployeeCenterMoveHistId),
			)
			.then(this.checkMutationResponse);
	}

	async getFullTimeEmployeeFinalWorkLeave(params: GetFullTimeEmployeeFinalWorkLeaveRequest) {
		return this.client
			.get<GetFullTimeEmployeeFinalWorkLeaveResponse>(
				endpoint.getFullTimeEmployeeFinalWorkLeave.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeResign(params: GetFullTimeEmployeeResignRequest) {
		return this.client
			.get<GetFullTimeEmployeeResignResponse>(endpoint.getFullTimeEmployeeResign.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async resignFullTimeEmployee(params: ResignFullTimeEmployeeRequest) {
		return this.client
			.post<ResignFullTimeEmployeeResponse>(endpoint.resignFullTimeEmployee.path(), params)
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeHealthCheck(params: GetFullTimeEmployeeHealthCheckRequest) {
		return this.client
			.get<GetFullTimeEmployeeHealthCheckResponse>(endpoint.getFullTimeEmployeeHealthCheck.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async saveFullTimeEmployeeHealthCheck(params: SaveFullTimeEmployeeHealthCheckRequest) {
		return this.client
			.post<SaveFullTimeEmployeeHealthCheckResponse>(
				endpoint.saveFullTimeEmployeeHealthCheck.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async deleteFullTimeEmployeeHealthCheck(params: DeleteFullTimeEmployeeHealthCheckRequest) {
		return this.client
			.delete<DeleteFullTimeEmployeeHealthCheckResponse>(
				endpoint.deleteFullTimeEmployeeHealthCheck.path(),
				{
					data: params,
				},
			)
			.then(this.checkResponse);
	}

	// 근무일정 > 연차, 유급 휴일 대장
	async getAnnualTOHistories(params: GetAnnualTOHistoriesRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<GetAnnualTOHistoriesResponse>(endpoint.getAnnualTOHistories.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async getAnnualTOHistoriesDownload(params: GetAnnualTOHistoriesDownloadRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<GetAnnualTOHistoriesDownloadResponse>(endpoint.getAnnualTOHistoriesDownload.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async getAnnualTOUsageHistories(params: GetAnnualTOUsageHistoriesRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<GetAnnualTOUsageHistoriesResponse>(endpoint.getAnnualTOUsageHistories.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async getAnnualTOUsageHistoriesDownload(params: GetAnnualTOUsageHistoriesDownloadRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<GetAnnualTOUsageHistoriesDownloadResponse>(
				endpoint.getAnnualTOUsageHistoriesDownload.path(),
				{
					params: validParam,
				},
			)
			.then(this.checkResponse);
	}

	async getEmployeeDefaultWorkTimeList(params: GetEmployeeDefaultWorkTimeListRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<GetEmployeeDefaultWorkTimeListResponse>(
				endpoint.getEmployeeDefaultWorkTimeList.path(validParam.employeeId),
			)
			.then(this.checkResponse);
	}

	async saveEmployeeDefaultWorkTime(params: SaveEmployeeDefaultWorkTimeRequest) {
		const validParam = getValidParam(params);
		return this.client
			.post<SaveEmployeeDefaultWorkTimeResponse>(
				endpoint.saveEmployeeDefaultWorkTime.path(validParam.employeeId),
				params,
			)
			.then(this.checkResponse);
	}

	async deleteEmployeeDefaultWorkTime(
		params: DeleteEmployeeDefaultWorkTimeRequest,
	): Promise<DeleteEmployeeDefaultWorkTimeData | null> {
		return this.client
			.delete<DeleteEmployeeDefaultWorkTimeResponse>(
				endpoint.deleteEmployeeDefaultWorkTime.path(params.employeeId, params.basWorkTimeId),
			)
			.then(this.checkResponse);
	}

	async saveEmployeeMaxOverWorkTime(params: SaveEmployeeMonthMaxOverworkTimeRequest) {
		return this.client
			.post<SaveEmployeeMonthMaxOverworkTimeResponse>(
				endpoint.saveEmployeeMaxOverWorkTime.path(),
				params,
			)
			.then(this.checkResponse);
	}

	async saveEmployeeVacation(params: SaveEmployeeVacationRequest) {
		return this.client
			.post<SaveEmployeeVacationResponse>(endpoint.saveEmployeeVacation.path(), params.body)
			.then(this.checkResponse);
	}

	async deleteEmployeeVacation(params: DeleteEmployeeVacationRequest) {
		return this.client
			.delete<DeleteEmployeeVacationResponse>(endpoint.deleteEmployeeVacation.path(), {
				data: params,
			})
			.then(this.checkResponse);
	}

	async saveEmployeeWorkSchedule(params: SaveEmployeeWorkScheduleRequestBody) {
		return this.client
			.post<SaveEmployeeWorkScheduleResponse>(endpoint.saveEmployeeWorkSchedule.path(), params)
			.then(this.checkResponse);
	}

	async getEmployeeWorkHistory(params: GetEmployeeWorkHistoryRequest) {
		return this.client
			.get<GetEmployeeWorkHistoryResponse>(endpoint.getEmployeeWorkHistory.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getFullTimeEmployeeWorkHistory(params: GetEmployeeWorkHistoryRequest) {
		return this.client
			.get<GetEmployeeWorkHistoryResponse>(endpoint.getFullTimeEmployeeWorkHistory.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async saveEmployeeWorkHistory(params: SaveEmployeeWorkHistoryRequest) {
		return this.client
			.post<SaveEmployeeWorkHistoryResponse>(endpoint.saveEmployeeWorkHistory.path(), params)
			.then(this.checkResponse);
	}

	async deleteEmployeeWorkHistory(params: DeleteEmployeeWorkHistoryRequest) {
		return this.client
			.delete<DeleteEmployeeWorkHistoryResponse>(endpoint.deleteEmployeeWorkHistory.path(), {
				data: params,
			})
			.then(this.checkResponse);
	}

	async updateWorkHistoryConfirm(params: UpdateWorkHistoryConfirmRequest) {
		const validParam = getValidParam(params);
		return this.client
			.post<UpdateWorkHistoryConfirmResponse>(endpoint.updateWorkHistoryConfirm.path(), null, {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async getEmployeeActualWorkLogDownloadList(params: GetEmployeeActualWorkLogDownloadListRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<GetEmployeeActualWorkLogDownloadListResponse>(
				endpoint.getEmployeeActualWorkLogDownloadList.path(),
				{
					params: {
						...validParam,
						employeeIds: `${(params.employeeIds ?? []).join(',')}`,
					},
				},
			)
			.then(this.checkResponse);
	}

	async saveEmployeeActualWorkLog({ params, body }: SaveEmployeeActualWorkLogRequestBody) {
		const validParam = getValidParam(body);
		return this.client
			.post<SaveEmployeeActualWorkLogResponse>(
				endpoint.saveEmployeeActualWorkLog.path(params.workLeaveDt),
				validParam,
			)
			.then(this.checkResponse);
	}

	async deleteEmployeeActualWorkLog(params: DeleteEmployeeActualWorkLogRequest) {
		return this.client
			.delete<DeleteEmployeeActualWorkLogResponse>(
				endpoint.deleteEmployeeActualWorkLog.path(params.workLeaveId),
			)
			.then(this.checkResponse);
	}

	async getPcorpWorkSchedule(params: GetPcorpWorkScheduleRequest) {
		return this.client
			.get<GetPcorpWorkScheduleResponse>(endpoint.getPcorpWorkSchedule.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getCenterWorkSchedule(params: GetCenterWorkScheduleStatusRequest) {
		return this.client
			.get<GetCenterWorkScheduleStatusResponse>(endpoint.getCenterWorkSchedule.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getManagerTagHistory(params: GetManagerTagHistoryRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<GetManagerTagHistoryResponse>(endpoint.getManagerTagHistory.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async updateManagerTagHistory(params: UpdateManagerTagHistoryRequest) {
		const validParam = getValidParam(params);
		return this.client
			.post<UpdateManagerTagHistoryResponse>(endpoint.updateManagerTagHistory.path(), validParam)
			.then(this.checkResponse);
	}

	async downloadExcelManagerTagHistory(params: DownloadExcelManagerTagHistoryRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get(endpoint.downloadExcelManagerTagHistory.path(), {
				params: validParam,
				headers: {
					'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				},
				responseType: 'blob',
			})
			.then((res) => {
				const contentDisposition = res.headers['content-disposition'];
				const filename = contentDisposition?.split('filename=')[1].replace(/['"]/g, '');
				return res.data ? { title: filename, content: res.data } : null;
			});
	}

	async getFtimeEmployeeWorkSchedule(params: GetFtimeEmployeeWorkScheduleRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<GetFtimeEmployeeWorkScheduleResponse>(endpoint.getFtimeEmployeeWorkSchedule.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async getFtimeEmployeeMonthSchedule(
		params: Omit<GetFtimeEmployeeWorkScheduleRequest, 'recipientStateCds'>,
	) {
		return this.client
			.get<GetFtimeEmployeeMonthScheduleResponse>(endpoint.getFtimeEmployeeMonthSchedule.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 내 센터 - 재무기준시작연월 기간 중복 조회
	async checkInsuranceRequiredEDoc({ employeeId, ...res }: CheckInsuranceRequiredEDocRequest) {
		return this.client
			.get<CheckInsuranceRequiredEDocResponse>(
				endpoint.checkInsuranceRequiredEDoc.path(employeeId),
				{
					params: res,
				},
			)
			.then(this.checkResponse);
	}

	// 내 센터 - 계정 - 유저별 계정 연동 목록 조회
	async getLinkAccounts(params: GetLinkAccountsRequest) {
		return this.client
			.get<GetLinkAccountsResponse>(endpoint.getLinkAccounts.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 내 센터 - 계정 - 유저별 계정 연동
	async linkAccounts(params: LinkAccountsRequest) {
		return this.client
			.patch<LinkAccountsResponse>(
				endpoint.linkAccounts.path(params.memberAccountId, params.memberId),
			)
			.then((res) => res.data);
	}

	async getCenterDefaultWorkTime(params: GetCenterDefaultWorkTimeRequest) {
		return this.client
			.get<GetCenterDefaultWorkTimeResponse>(
				endpoint.getCenterDefaultWorkTime.path(params.centerId),
			)
			.then(this.checkResponse);
	}

	async saveCenterDefualtWorkTime({ params, body }: SaveCenterDefualtWorkTimeRequest) {
		const validBody = getValidParam(body);
		return this.client
			.post<SaveCenterDefualtWorkTimeResponse>(
				endpoint.saveCenterDefualtWorkTime.path(params.ceterId),
				validBody,
			)
			.then(this.checkResponse);
	}

	async registryDefaultWorkTime(params: RegistryDefaultWorkTimeRequest) {
		return this.client
			.post(endpoint.registryDefaultWorkTime.path(params.centerId, params.yearMonth))
			.then((res) => res.data);
	}

	// 일정 모니터링 목록 조회
	async getMonitoringSchedules(params: MonitoringSchedulesRequest) {
		const validParam = getValidParam(params);
		return this.client
			.get<MonitoringResponse>(endpoint.getMonitoringSchedules.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	// 일정모니터링 - 공단 데이터 불러오기
	async syncPCorp(params: SyncPCorpRequest) {
		return this.client
			.post<SyncPCorpResponse>(endpoint.syncPCorp.path(), params)
			.then((res) => res.data);
	}

	// 일정 모니터링 목록 조회
	async getCompleteMonitoringSyncDate(params: MonitoringSyncDateRequest) {
		return this.client
			.get<MonitoringSyncDateResponse>(endpoint.getMonitoringCompleteSynyDate.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 일정모니터링 - 전체 데이터 갱신
	async syncMonitoringData(params: SyncMonitoringDataRequest) {
		return this.client
			.post<SyncMonitoringDataResponse>(endpoint.syncMonitoringData.path(), params)
			.then((res) => res.data);
	}

	// 일정 모니터링 목록 조회
	async getRecentMonitoringSyncDate(params: MonitoringSyncDateRequest) {
		return this.client
			.get<MonitoringSyncDateResponse>(endpoint.getRecentMonitoringSynyDate.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 일정모니터링 - 개별 데이터 갱신
	async syncMonitoringIndividualData(params: SyncMonitoringIndividualRequest) {
		return this.client
			.post<SyncMonitoringIndividualResponse>(endpoint.syncMonitoringIndividual.path(), params)
			.then((res) => res.data);
	}

	// 일정 모니터링 데이터 동기화 실행이력 조회
	async getMonitoringSyncHistory(params: MonitoringSyncHistoryRequest) {
		return this.client
			.get<MonitoringSyncHistoryResponse>(endpoint.getMonitoringSyncHistory.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 일정 모니터링 상세 목록 조회
	async getMonitoringDetails(params: MonitoringDetailsRequest) {
		const validParams = getValidParam(params);
		return this.client
			.get<MonitoringDetailsResponse>(endpoint.getMonitoringDetails.path(), {
				params: validParams,
			})
			.then(this.checkResponse);
	}

	// 일정 모니터링 상세 사이드모달 조회
	async getMonitoringDetailItem(params: MonotoringDetailItemRequest) {
		const validParams = getValidParam(params);
		return this.client
			.get<MonotoringDetailItemResponse>(endpoint.getMonitoringDetailItem.path(), {
				params: validParams,
			})
			.then(this.checkResponse);
	}

	// 일정 모니터링 상세 사이드모달 저장
	async saveScheduleMonitoringDetail(params: SaveScheduleMonitoringDetailRequest) {
		return this.client
			.post<SaveScheduleMonitoringDetailResponse>(
				endpoint.saveScheduleMonitoringDetail.path(),
				params,
			)
			.then((res) => res.data);
	}

	// 임금관리 - 특정 임금 데이터 삭제
	async deleteEmployeeSalary(params: DeleteEmployeeSalaryRequest) {
		return this.client
			.delete<DeleteEmployeeSalaryResponse>(
				endpoint.deleteEmployeeSalary.path(params.employeeSalaryId),
			)
			.then((res) => res.data);
	}

	// 임금관리 - 특정 급여서비스일정 삭제
	async deleteEmployeeSalarySchedule(params: DeleteEmployeeSalaryScheduleRequest) {
		return this.client
			.delete<DeleteEmployeeSalaryScheduleResponse>(
				endpoint.deleteEmployeeSalarySchedule.path(params.employeeSalaryId, params.scheduleId),
			)
			.then((res) => res.data);
	}

	// 일정 모니터링 근무확인변경
	async updateWorkConfirmStatus(params: UpdateWorkStatusRequest[]) {
		return this.client
			.post<UpdateWorkStatusResponse>(endpoint.updateWorkConfirmStatus.path(), params)
			.then((res) => res.data);
	}

	// 일정 모니터링 근무중복확인변경
	async updateWorkDuplicationStatus(params: UpdateWorkStatusRequest[]) {
		return this.client
			.post<UpdateWorkStatusResponse>(endpoint.updateWorkDuplicationStatus.path(), params)
			.then((res) => res.data);
	}

	// 욕구사정 종합 생성
	async getGenerateIntegratedAssessmentOpinion(
		params: GetGenerateIntegratedAssessmentOpinionParams,
	) {
		return this.client
			.post<BaseResponse<string>>(endpoint.getGenerateIntegratedAssessmentOpinion.path(), params)
			.then((res) => res.data);
	}

	// 모니터링 상세 확정
	async updateMonitoringDetailItemCompleteStatus(
		params: UpdateMonitoringDetailItemCompleteStatusRequest,
	) {
		return this.client
			.patch<UpdateMonitoringDetailItemCompleteStatusResponse>(
				endpoint.updateMonitoringDetailItemCompleteStatus.path(),
				params,
			)
			.then((res) => res.data);
	}

	// 모니터링 상세 목록 확정
	async updateMonitoringDetailsCompleteStatus(
		params: UpdateMonitoringDetailsCompleteStatusRequest,
	) {
		return this.client
			.patch<UpdateMonitoringDetailsCompleteStatusResponse>(
				endpoint.updateMonitoringDetailsCompleteStatus.path(),
				params,
			)
			.then((res) => res.data);
	}

	// 모니터링 센터 전체 일정 확정
	async updateMonitoringCompleteStatus(params: UpdateMonitoringCompleteStatusRequest) {
		return this.client
			.patch<UpdateMonitoringCompleteStatusResponse>(
				endpoint.updateMonitoringCompleteStatus.path(),
				params,
			)
			.then((res) => res.data);
	}

	// 전자문서 현재 템플릿 파일명 얻어오기
	async getEFormFileName(params: GetEFormFileNameRequest) {
		return this.client
			.get<GetEFormFileNameResponse>(endpoint.getEFormFileName.path(), {
				params,
			})
			.then((res) => res.data);
	}

	async exportEformToPdf(params: ExportEformToPdfRequest) {
		return this.eDocClient
			?.get<Blob>(endpoint.exportEformToPdf.path(), {
				params,
				responseType: 'blob',
			})
			.then((res) => res.data);
	}

	// 업무수행일지 발송
	async issueWorkExecuteLog(params: WorkExecuteLogIssueEdocRequest) {
		return this.client
			.post<WorkExecuteLogIssueEdocResponse>(endpoint.issueWorkExecuteLog.path(), params)
			.then((res) => res.data);
	}

	// 입소이용신청서 조회
	async getRecipientAdmissionUseEDoc({ centerId, edocNo }: GetRecipientEdocAdmissionUseRequest) {
		if (!centerId || !edocNo) return null;
		return this.client
			.get<GetRecipientEdocAdmissionUseResponse>(
				endpoint.getRecipientEdocAdmissionUse.path(centerId, edocNo),
			)
			.then(this.checkResponse);
	}

	async confirmRecipientEdocAdmissionUse(params: ConfirmRecipientEdocAdmissionUseRequest) {
		return this.client
			.patch<ConfirmRecipientEdocAdmissionUseResponse>(
				endpoint.confirmRecipientEdocAdmissionUse.path(),
				params,
			)
			.then((res) => res.data);
	}

	getCustomerCenterManagers(
		params: GetCustomerCenterManagersRequest,
	): Promise<GetCustomerCenterManagersData | null> {
		return this.client
			.get<GetCustomerCenterManagersResponse>(
				endpoint.getCustomerCenterManagers.path(params.centerId),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	// confirmRecipientEdocAdmissionUse
	getCustomerChiefs(): Promise<GetCustomerChiefsData | null> {
		return this.client
			.get<GetCustomerChiefsResponse>(endpoint.getCustomerChiefs.path())
			.then(this.checkResponse);
	}

	getCustomerClients(params: GetCustomerClientsRequest): Promise<GetCustomerClientsData | null> {
		return this.client
			.get<GetCustomerClientsResponse>(endpoint.getCustomerClients.path(params.recipientId), {
				params,
			})
			.then(this.checkResponse);
	}

	getCustomerEmployees(
		params: GetCustomerEmployeesRequest,
	): Promise<GetCustomerEmployeesData | null> {
		return this.client
			.get<GetCustomerEmployeesResponse>(endpoint.getCustomerEmployees.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	getCustomerSelectBoxCommonCodes(
		params: GetCustomerSelectBoxCommonCodesRequest,
	): Promise<GetCustomerSelectBoxCommonCodesData | null> {
		return this.client
			.get<GetCustomerSelectBoxCommonCodesResponse>(
				endpoint.getCustomerSelectBoxCommonCodes.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async getVisitBathRoundingHistory(
		params: RoundingHistoryRequest,
	): Promise<VisitBathRoundingHistoryResponseData | null> {
		return this.client
			.get<VisitBathRoundingHistoryResponse>(endpoint.getVisitBathRoundingHistory.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 모든 센터 수급자 조회
	async getCenterRecipients(
		params: CenterMemberSearchRequest,
	): Promise<CenterMemberSearchDTO[] | null> {
		return this.client
			.get<GetAllCenterMemberResponse>(endpoint.getCenterRecipients.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getVisitCareRoundingHistory(
		params: RoundingHistoryRequest,
	): Promise<VisitCareRoundingHistoryResponseData | null> {
		return this.client
			.get<VisitCareRoundingHistoryResponse>(endpoint.getVisitCareRoundingHistory.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	// 모든 센터 직원 조회
	async getCenterEmployees(
		params: CenterMemberSearchRequest,
	): Promise<CenterMemberSearchDTO[] | null> {
		return this.client
			.get<GetAllCenterMemberResponse>(endpoint.getCenterEmployees.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getFamilyCareRoundingHistory(
		params: RoundingHistoryRequest,
	): Promise<FamilyCareRoundingHistoryResponseData | null> {
		return this.client
			.get<VisitCareRoundingHistoryResponse>(endpoint.getFamilyCareRoundingHistory.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async syncRoundingManagerRFIDTag(
		params: SyncRoundingManagerRfidTagRequest,
	): Promise<SyncRoundingManagerRfidTagResponse | null> {
		return this.client
			.post<SyncRoundingManagerRfidTagResponse>(endpoint.syncRoundingManagerRFIDTag.path(), params)
			.then((res) => res.data);
	}

	async getRoundingCompletionStatus(params: GetRoundingCompletionStatusRequest) {
		return this.client
			.get<GetRoundingCompletionStatusResponse>(
				endpoint.getRoundingCompletionStatus.path(params.centerId),
			)
			.then(this.checkResponse);
	}

	async updateContractState(
		params: UpdateContractStateRequest,
	): Promise<UpdateContractStateData | null> {
		return this.client
			.post<UpdateContractStateResponse>(
				endpoint.updateContractState.path(params.serviceContractId),
				{},
			)
			.then(this.checkResponse);
	}

	async updateRenewalContractState(
		params: UpdateRenewalContractStateRequest,
	): Promise<UpdateRenewalContractStateData | null> {
		return this.client
			.post<UpdateContractStateResponse>(
				endpoint.updateRenewalContractState.path(params.serviceContractUuId),
				{},
			)
			.then(this.checkResponse);
	}

	async updateCenterAccountInfo(
		params: UpdateCenterAccountInfoRequest,
	): Promise<UpdateCenterAccountInfoResponse | null> {
		return this.client
			.post<UpdateCenterAccountInfoResponse>(endpoint.updateCenterAccountInfo.path(), params)
			.then((res) => res.data);
	}

	async getMyFuncs(): Promise<GetMyFuncsData | null> {
		return this.client.get<GetMyFuncsResponse>(endpoint.getMyFuncs.path()).then(this.checkResponse);
	}

	async updateRecipientState(params: UpdateRecipientStateRequest) {
		return this.client
			.patch<UpdateRecipientStateResponse>(endpoint.updateRecipientState.path(), params)
			.then(this.checkResponse);
	}

	async getWorkExecuteLogSalaryOfferPlan(params: GetWorkExecuteLogSalaryOfferPlanRequest) {
		return this.client
			.get<GetWorkExecuteLogSalaryOfferPlanResponse>(
				endpoint.getWorkExecuteLogSalaryOfferPlan.path(),
				{
					params,
				},
			)
			.then(this.checkResponse);
	}

	async updateSalaryWorkSchedules(params: UpdateSalaryWorkSchedulesRequest) {
		return this.client
			.post<UpdateSalaryWorkSchedulesResponse>(
				endpoint.updateSalaryWorkSchedules.path(params.employeeSalaryId),
				params.scheduels,
			)
			.then((res) => res.data);
	}

	async updateEmployeeSalaryAfterSync(params: UpdateEmployeeSalaryAfterSyncRequest) {
		return this.client
			.post<UpdateEmployeeSalaryAfterSyncResponse>(
				endpoint.updateEmployeeSalaryAfterSync.path(params.employeeSalaryId),
				params,
				{
					params: {
						inputValueKeepYn: params.inputValueKeepYn,
						syncYn: params.syncYn,
					},
				},
			)
			.then((res) => res.data);
	}

	async getBurdenChargesCorrections(params: GetBurdenChargesCorrectionsRequest) {
		return this.client
			.get<GetBurdenChargesCorrectionsResponse>(endpoint.getBurdenChargesCorrections.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async upsertBurdenChargesCorrections(params: UpsertBurdenChargesCorrectionsRequest) {
		return this.client
			.post<UpsertBurdenChargesCorrectionsResponse>(
				endpoint.upsertBurdenChargesCorrections.path(),
				params,
			)
			.then((res) => res.data);
	}

	async deleteBurdenChargesCorrections(params: DeleteBurdenChargesCorrectionsRequest) {
		return this.client
			.delete<DeleteBurdenChargesCorrectionsResponse>(
				endpoint.deleteBurdenChargesCorrections.path(params.burdenAmtChargeCorrectionId),
			)
			.then((res) => res.data);
	}

	async getBurdenChargesDetail(params: GetBurdenChargesDetailRequest) {
		return this.client
			.get<GetBurdenChargesDetailResponse>(endpoint.getBurdenChargesDetail.path(), {
				params,
			})
			.then(this.checkResponse);
	}

	async getBaseCorporationList(): Promise<CorporationDTO[] | null> {
		return this.client
			.get<BaseCorporationListResponse>(endpoint.getBaseCorporationList.path())
			.then(this.checkResponse);
	}

	async getEmployReportData(params: GetEmployReportRequest): Promise<GetEmployReportData | null> {
		if (params.page === 0) return null;
		const validParam = getValidParam(params);
		return this.client
			.get<GetEmployReportResponse>(endpoint.getEmployReportData.path(), { params: validParam })
			.then(this.checkResponse);
	}

	async saveEmployReportData(params: EmployReportRequest) {
		return this.client.post(endpoint.saveEmployReportData.path(), params).then((res) => res.data);
	}

	async confirmAllEmployeeSalary(
		params: ConfirmAllEmployeeSalaryRequest,
	): Promise<ConfirmAllEmployeeSalaryResponse | null> {
		return this.client
			.put(endpoint.confirmAllEmployeeSalary.path(), {}, { params })
			.then((res) => res.data);
	}

	async recalculateAllEmployeeSalary(
		params: RecalculateAllEmployeeSalaryRequest,
	): Promise<RecalculateAllEmployeeSalaryResponse | null> {
		return this.client
			.post(endpoint.recalculateAllEmployeeSalary.path(), {}, { params })
			.then((res) => res.data);
	}

	async getRecipientContractReviewList(
		params: GetRecipientContractReviewListRequest,
	): Promise<GetContractReviewListData | null> {
		const validParam = getValidParam(params);
		return this.client
			.get<GetContractReviewListResponse>(endpoint.getRecipientContractReviewList.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async getEmployeeContractReviewList(
		params: GetEmployeeContractReviewListRequest,
	): Promise<GetEmployeeContractReviewData | null> {
		const validParam = getValidParam(params);
		return this.client
			.get<GetEmployeeContractReviewResponse>(endpoint.getEmployeeContractReviewList.path(), {
				params: validParam,
			})
			.then(this.checkResponse);
	}

	async saveContractReview(
		params: SaveContractReviewRequest,
	): Promise<SaveContractReviewResponse | null> {
		return this.client.post(endpoint.saveContractReview.path(), params).then((res) => res.data);
	}
}
