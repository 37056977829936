import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import Theme from 'common/theme';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRTable from 'components/base/CRTable';
import { Toast } from 'components/base/CRToast';
import AddOwnExpenseCorrectionDialog from 'components/domain/dialog/AddOwnExpenseCorrectionDialog';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import { displayComma } from 'lib';
import {
	useBurdenChargesCorrections,
	useDeleteBurdenChargesCorrections,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { ResponseCode } from 'types/api';
import { BurdenChargeCorrectionDTO } from 'types/dto';

import { OWN_EXPENSE_CORRECTION_TABLE_HEADER_CONFIG } from './constants';
import * as S from './styles';

interface Props {
	recipientId: number;
	centerId: number;
	onClickClose?: () => void;
	refetch?: () => Promise<any>;
}

function OwnExpenseCorrectionDialog({
	recipientId,
	centerId,
	onClickClose,
	refetch,
}: Props): React.ReactElement {
	const hasDeleteHospitalizationHistoryFunc = useHasFunc([
		'recipient:delete_hospitalization_history',
	]);
	const { showDialog } = useDialog();
	const { data: burdenChargesCorrections = [], refetch: refetchBurdenChargesCorrections } =
		useBurdenChargesCorrections({
			recipientId,
			centerId,
		});

	const refetchData = async () => {
		await refetchBurdenChargesCorrections();
		await refetch?.();
	};

	const { mutateAsync: deleteBurdenChargeCorrectionMutateAsync } =
		useDeleteBurdenChargesCorrections(async (client, data) => {
			onClickClose?.();
			await refetchData();

			if (data?.code === ResponseCode.SUCCESS) {
				Toast.success('본인부담금 보정 이력을 삭제하였습니다.');
			} else {
				Toast.error('본인부담금 보정이 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const handleAddCorrection = () => {
		showDialog(({ hideDialog }) => (
			<AddOwnExpenseCorrectionDialog
				recipientId={recipientId}
				centerId={centerId}
				hideDialog={hideDialog}
				refetch={refetchData}
			/>
		));
	};

	const handleEditCorrection = (item: BurdenChargeCorrectionDTO) => {
		showDialog(({ hideDialog }) => (
			<AddOwnExpenseCorrectionDialog
				recipientId={recipientId}
				centerId={centerId}
				item={item}
				hideDialog={hideDialog}
				refetch={refetchData}
			/>
		));
	};

	const deleteCorrection = (item: BurdenChargeCorrectionDTO) => async () => {
		await deleteBurdenChargeCorrectionMutateAsync({
			burdenAmtChargeCorrectionId: item.burdenAmtChargeCorrectionId,
		});
	};

	const handleDeleteCorrection = (item: BurdenChargeCorrectionDTO) => {
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='본인부담금 보정 이력 삭제'
				content={`${dayjs(item.burdenAmtChargeYm).format('YYYY년 MM월')} 본인부담금 보정 이력을 삭제하시겠습니까?`}
				hideDialog={hideDialog}
				successOption={{
					text: '삭제',
					successCallback: deleteCorrection(item),
				}}
				cancelOption={{
					text: '취소',
				}}
			/>
		));
	};

	const getTotalColor = (value: number) => {
		if (value === 0) return undefined;
		if (value > 0) return Theme.colors.blue;
		return Theme.colors.red;
	};

	const ButtonContainer = useCallback(
		(id: number, item?: BurdenChargeCorrectionDTO) => (
			<S.ButtonContainer>
				<CRButton.Default
					type='outlined'
					palette='gray'
					size='xSmall'
					onClick={() => item && handleEditCorrection(item)}>
					수정
				</CRButton.Default>
				<CRButton.Default
					disabled={!hasDeleteHospitalizationHistoryFunc}
					type='outlined'
					palette='primary'
					size='xSmall'
					onClick={() => item && handleDeleteCorrection(item)}>
					삭제
				</CRButton.Default>
			</S.ButtonContainer>
		),
		[],
	);

	const BurdenAmtChargeYmColumn = useCallback(
		(value: string) => dayjs(value).format('YYYY년 MM월'),
		[],
	);

	const AmountColumn = useCallback(
		(value: number) => (
			<S.AmountColumn color={getTotalColor(value)}>{displayComma(value)}</S.AmountColumn>
		),
		[],
	);

	return (
		<CRDialog
			type='L'
			title='본인부담금 보정 이력'
			body={
				<S.Container>
					<CRTable.Root>
						<CRTable.Head heads={OWN_EXPENSE_CORRECTION_TABLE_HEADER_CONFIG} disableBackBlind />
						<CRTable.Body>
							{(burdenChargesCorrections ?? []).map((item, index) => (
								<CRTable.Row
									key={item.burdenAmtChargeCorrectionId}
									item={{ ...item, index: index + 1 }}
									renderKeys={[
										'burdenAmtChargeYm',
										'serviceTypeNm',
										'burdenAmtChargeTypeNm',
										'feeSumAmt',
										'burdenAmt',
										'pcorpChargeAmt',
										'additionAmt',
										'correctionSpecialDesc',
										'burdenAmtChargeCorrectionId',
									]}
									customRender={{
										burdenAmtChargeYm: BurdenAmtChargeYmColumn,
										burdenAmtChargeCorrectionId: ButtonContainer,
										feeSumAmt: AmountColumn,
										burdenAmt: AmountColumn,
										pcorpChargeAmt: AmountColumn,
										additionAmt: AmountColumn,
									}}
								/>
							))}
						</CRTable.Body>
					</CRTable.Root>
				</S.Container>
			}
			footer={
				<S.FooterRightSideContainer>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						type='outlined'
						palette='gray'
						onClick={handleAddCorrection}>
						본인부담금 보정 추가
					</CRButton.IconButton>
				</S.FooterRightSideContainer>
			}
			bottomContainerStyle={{
				borderTop: `0.1rem solid ${Theme.colors.gray90}`,
			}}
			onClickClose={onClickClose}
		/>
	);
}

export default React.memo(OwnExpenseCorrectionDialog);
