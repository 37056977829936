import { ExternalCenterDTO } from 'types/dto';

import { BaseResponse } from './base';

export interface GetRecipientsRequestDTO {
	centerIds?: number;
}

export interface RecipientDTO {
	memberId: string;
	recipientId: number;
	korMemberNm: string;
	birthDt: string;
	mobilePhoneNo: string;
	centerId: number;
	centerNm: string;
	stateNm: string;
	serviceNm: string;
	managerId: number;
	managerNm: string;
	mainGuardianNm?: string;
	longTermStartDate?: string;
	longTermEndDate?: string;
}

export type GetRecipientsData = {
	count: number;
	recipients: RecipientDTO[];
};

export type GetRecipientsResponse = BaseResponse<GetRecipientsData>;

export interface GetRecipientRequestDTO {
	recipientId?: number;
}

export interface RecipientDetailDTO {
	recipientId: number;
	recipientNm: string;
	genderCd: string;
	genderNm: string;
	rsdnNo: string;
	birthDt: string;
	baseAddr: string;
	detailAddr: string;
	zipCode: string;
	longTermGradeCd: string;
	longTermGradeNm: string;
	longTermPeriod: string;
	longTermNo: string;
	burdenRateCd: string;
	burdenRateNm: string;
	managerId: number;
	managerNm: string;
	recipientStateCd: string;
	mobilePhoneNo: string;
}

export type GetRecipientData = {
	recipient: RecipientDetailDTO;
	mainGuardianInfo: string;
	otherServiceUseSummary: string;
	services: string;
};

export type GetRecipientResponse = BaseResponse<GetRecipientData>;

export interface PublicHolidayRequestDTO {
	year: string;
	countryCode: string;
}

export interface PublicHolidayDTO {
	date: string;
	localName: string;
	name: string;
	countryCode: string;
	fixed: boolean;
	global: boolean;
	counties: string[];
	launchYear: 0;
	types: ['Public'];
}

export interface CreateUploadUrlRequestDTO {
	size: number;
	objectKey: string;
	autoCertificationYn: boolean;
}

export interface CreateDownloadUrlRequestDTO {
	objectKey: string;
}

export interface UploadFileRequestDTO {
	presignedUrl: string;
	type: string;
	file: File;
}

export interface DownloadFileRequestDTO {
	name: string;
	presignedUrl: string;
}

export interface CreateUploadUrlDTO {
	url: string;
	objectKey: string;
}

export type CreateUploadUrlResponse = BaseResponse<CreateUploadUrlDTO>;

export interface GetCommonCodesRequestDTO {
	comCdGroupNms: string[];
}

export interface CommonCodeDTO {
	comCd: string;
	korComCdNm: string;
	engComCdNm: string;
	korComCdAliasNm: string;
	engComCdAliasNm: string;
	comCdGroupNm: string;
	orderSeq: number;
	comCdDesc?: string;
	etcDesc1: string;
	etcDesc2: string;
	etcDesc3: string;
	etcDesc4: string;
	etcDesc5: string;
	comCdId: string;
}

export type GetCommonCodesResponse = BaseResponse<CommonCodeDTO[]>;

export interface CommonCenterDTO {
	id: string;
	value: string;
}

export type GetCommonCentersData = CommonCenterDTO[];

export type GetCommonCentersResponse = BaseResponse<GetCommonCentersData>;

export type GetExternalCenterData = ExternalCenterDTO[];

export type GetExternalCenterResponse = BaseResponse<GetExternalCenterData>;

export interface GetRecipientHistoryOfOwnExpenseRequestDTO {
	recipientId?: number;
}

export type RecipientHistoryOfOwnExpense = {
	burdenRateHistId: number;
	currentYn: boolean;
	burdenRateStartDate: string;
	burdenRate: string;
	burdenRateCd: string;
	burdenRateChangeDesc: string;
	centerNm: string;
	managerNm: string;
};

export type GetRecipientHistoryOfOwnExpenseData = RecipientHistoryOfOwnExpense[];

export type GetRecipientHistoryOfOwnExpenseResponse =
	BaseResponse<GetRecipientHistoryOfOwnExpenseData>;
export interface CreateRecipientHistoryOfOwnExpenseRequestDTO {
	recipientId?: number;
	burdenRateStartDate: string;
	burdenRateCd: string;
	burdenRateChangeDesc: string;
}
export type CreateRecipientHistoryOfOwnExpenseData = {
	recipientId: number;
};

export type CreateRecipientHistoryOfOwnExpensResponse =
	BaseResponse<CreateRecipientHistoryOfOwnExpenseData>;

export interface UpdateRecipientHistoryOfOwnExpenseRequestDTO {
	recipientId?: number;
	burdenRateStartDate: string;
	burdenRateCd: string;
	burdenRateChangeDesc: string;
	burdenRateHistId: number;
}

export type UpdateRecipientHistoryOfOwnExpenseData = {
	recipientId: number;
};

export type UpdateRecipientHistoryOfOwnExpensResponse =
	BaseResponse<UpdateRecipientHistoryOfOwnExpenseData>;

export interface DeleteRecipientHistoryOfOwnExpenseRequestDTO {
	recipientId: number;
}

export type DeleteRecipientHistoryOfOwnExpenseData = {
	recipientId: number;
};

export type DeleteRecipientHistoryOfOwnExpenseResponse =
	BaseResponse<DeleteRecipientHistoryOfOwnExpenseData>;

export interface GetRecipientHistoryOfManagerRequestDTO {
	recipientId?: string;
}

export type RecipientHistoryOfManager = {
	recipientManagerHistId: number;
	currentYn: boolean;
	managerChangeDate: string;
	centerId: number;
	centerNm: string;
	managerId: string;
	managerNm: string;
	consultOpinion: string;
	managerChangeDesc: string;
	managerChangeReason: string;
};

export type GetRecipientHistoryOfManagerData = RecipientHistoryOfManager[];

export type GetRecipientHistoryOfManagerResponse = BaseResponse<GetRecipientHistoryOfManagerData>;

export interface CreateRecipientHistoryOfManagerRequestDTO {
	recipientManagerHistId?: number;
	managerChangeDate: string;
	centerId: number;
	managerId: number;
	consultOpinion?: string;
	managerChangeReason: string;
	recipientId?: string;
}

export type CreateRecipientHistoryOfManagerData = {
	recipientId: number;
};

export type CreateRecipientHistoryOfManagerResponse =
	BaseResponse<CreateRecipientHistoryOfManagerData>;

export interface GetRecipientHistoryOfLongTermGradeRequestDTO {
	recipientId?: number;
}

export type RecipientHistoryOfLongTermGrade = {
	longTermGradeHistId: number;
	currentYn: boolean;
	longTermGrade: string;
	longTermGradeCd: string;
	longTermGradeNm: string;
	longTermChangeDate: string;
	longTermStartDate: string;
	longTermEndDate: string;
	longTermNo: string;
	longTermMajorChangeNo: string;
	longTermMinorChangeNo: string;
	longTermChangeDesc: string;
	centerNm: string;
	managerNm: string;
};

export type GetRecipientHistoryOfLongTermGradeData = RecipientHistoryOfLongTermGrade[];

export type GetRecipientHistoryOfLongTermGradeResponse =
	BaseResponse<GetRecipientHistoryOfLongTermGradeData>;

export interface CreateRecipientRequestDTO {
	name: string;
	rsdnNo: string;
	centerId: string;
}

export type CreateRecipientData = {
	recipientId: number;
};

export type CreateRecipientResponse = BaseResponse<CreateRecipientData>;

export interface CheckRecipientRsdnNoRequestDTO {
	name: string;
	rsdnNo: string;
	centerId: string;
}

export type CheckRecipientRsdnNData = {
	centerNm?: string;
	isEmployeeDuplicated?: boolean;
	isRecipientDuplicated: boolean;
	isMemberDuplicated?: boolean;
	isCenterDuplicated: boolean;
	recipientId: number;
};

export type CheckRecipientRsdnNResponse = BaseResponse<CheckRecipientRsdnNData>;

export interface GetCenterManagersRequestDTO {
	centerId?: number;
}

export type CenterManagerRole = {
	roleNm: string;
	memberAccountRoleStartDate: string;
	memberAccountRoleEndDate: string;
};

export type PositionCenter = {
	positionCd: string;
	centerId: number;
	centerNm: string;
};

export type CenterManager = {
	memberAccountId: number;
	userId: string;
	eno: string;
	email: string;
	userNm: string;
	passwordChangeNeedYn: boolean;
	profileFileId: number;
	centerId: number;
	centerNm: string;
	positionCode: string;
	positionNm: string;
	lastLoginDate: string;
	accountDisabledYn: boolean;
	personalMobilePhoneNo: string;
	workMobilePhoneNo: string;
	memberAccountRoles: CenterManagerRole[];
	positionCenterMappings: PositionCenter[];
	birthDt: string;
};

export type GetCenterManagersData = CenterManager[];

export type GetCenterManagersResponse = BaseResponse<GetCenterManagersData>;

export interface GetCenterRecipientBaseRequest {
	recipientId: number;
	centerId: number;
}

export interface GetRecipientBaseRequest {
	recipientId?: number;
}

export interface RecipientBaseInfoData {
	recipientId: number;
	recipientNm: string;
	genderCd: string;
	genderNm: string;
	mobilePhoneNo: string;
	rsdnNo: string;
	birthDt: string;
	baseAddr: string;
	detailAddr: string;
	zipCode: string;
	longTermGradeCd: string;
	longTermGradeNm: string;
	longTermPeriod: string;
	longTermNo: string;
	longTermMajorChangeNo: string;
	longTermMinorChangeNo: string;
	burdenRateCd: string;
	burdenRateNm: string;
	signMethodCd: string;
	recipientSpecialDesc: string;
	managerId: number;
	managerNm: string;
	recipientStateCd: string;
	regionNm1: string;
	regionNm2: string;
	regionNm3: string;
	roadNm: string;
	addrLatitude: number;
	addrLongitude: number;
	pending: string;
}

export interface RecipientGuardianData {
	recipientGuardianId: number;
	recipientGuardianNm: string;
	guardianRelCd: string;
	guardianRelNm: string;
	mainGuardianYn: boolean;
	mobilePhoneNo: string;
	birthDt: string;
	baseAddr: string;
	detailAddr: string;
	zipCode: string;
	regionNm1: string;
	regionNm2: string;
	regionNm3: string;
	roadNm: string;
	addrLatitude: number;
	addrLongitude: number;
	scheduleReceiveYn: boolean;
	longTermPayFeeCertiReceiveYn?: boolean;
	docReceiveMethodCd?: string;
}

export interface ServiceTime {
	id?: string;
	otherServiceUseStartTime: string;
	otherServiceUseEndTime: string;
	days: {
		otherServiceUseDayCd: string;
	}[];
}

export interface RecipientOtherServiceData {
	otherCenterNm: string;
	otherCenterPhoneNo: string;
	serviceTypeCd: string;
	remark: string;
	times: ServiceTime[];
}

export interface RecipientBurdenAmtPayData {
	recipientAffiliationId: number;
	burdenAmtPayMethodCd: string;
	burdenAmtAutotransBankCd: string;
	burdenAmtAutotransAccountNo: string;
	burdenAmtAutotransAccountDepositorNm: string;
	burdenAmtAutotransAccountValidYn: boolean;
	cashReceiptIssueYn: boolean;
	cashReceiptIssuerNm: string;
	cashReceiptIssueMethodCd: string;
	cashReceiptIssuerNo: string;
	burdenAmtPayDeductEmployeeId: number;
}

export interface LongTermUserPlanWelfares {
	longTermUsePlanId: number;
	longTermUsePlanWelfareId: number;
	welfareItemCd: string;
}

export interface ScheduleAutomation {
	centerId: number;
	employeeId1: {
		employeeId: number;
		employeeNm: string;
		genderCd: string;
		genderNm: string;
		rsdnNo: string;
		birthDt: string;
		addr: string;
		zipCode: string;
		mobilePhoneNo: string;
		dementiaEduCompleteYn: boolean;
	};
	employeeId2?: {
		employeeId: number;
		employeeNm: string;
		genderCd: string;
		genderNm: string;
		rsdnNo: string;
		birthDt: string;
		addr: string;
		zipCode: string;
		mobilePhoneNo: string;
		dementiaEduCompleteYn: boolean;
	};
	holidayExceptYn: boolean;
	monthAmtOverYn: boolean;
	monthUseCnt?: number;
	monthAmt?: number;
	scheduleAutomationDays: {
		scheduleAutomationDayCd: string;
	}[];
	scheduleAutomationId: number;
	serviceStartTime: string;
	serviceEndTime: string;
	serviceKindCd: string;
	totalDayYn: boolean;
	managerId?: number;
	automationFeeCd?: string;
}

export interface GetRecipientBaseData {
	recipient: RecipientBaseInfoData;
	guardians: RecipientGuardianData[];
	otherServices: RecipientOtherServiceData[];
	burdenAmtPays: RecipientBurdenAmtPayData;
	longTermUsePlanWelfares?: LongTermUserPlanWelfares[];
	scheduleAutomations: ScheduleAutomation[];
}

export type GetRecipientBaseResponse = BaseResponse<GetRecipientBaseData>;

export interface UpdateRecipientBaseRequest {
	recipientId: number;
	recipientNm: string;
	rsdnNo: string;
	birthDt: string;
	basAddr: string;
	detailAddr: string;
	regionNm1: string;
	regionNm2: string;
	regionNm3: string;
	roadNm: string;
	addrLatitude?: number;
	addrLongitude?: number;
	zipCode: string;
	signMethodCd: string;
	recipientSpecialDesc: string;
}

export interface UpdateRecipientBaseData {
	recipientId: number;
}

export type UpdateRecipientBaseResponse = BaseResponse<UpdateRecipientBaseData>;

export interface UpdateRecipientBurdenAmtRequest {
	recipientId: number;
	recipientAffiliationId: number;
	burdenAmtPayMethodCd: string;
	burdenAmtPayDeductEmployeeId?: number;
	burdenAmtAutotransBankCd?: string;
	burdenAmtAutotransAccountNo?: string;
	burdenAmtAutotransAccountDepositorNm?: string;
	cashReceiptIssueYn: boolean;
	cashReceiptIssuerNm?: string;
	cashReceiptIssueMethodCd?: string;
	cashReceiptIssuerNo?: string;
}

export interface UpdateRecipientBurdenAmtData {
	recipientId: number;
}

export type UpdateRecipientBurdenAmtResponse = BaseResponse<UpdateRecipientBurdenAmtData>;

export interface UpdateRecipientGuardiansRequest {
	recipientId: number;
	body: {
		recipientGuardianId?: number;
		recipientGuardianNm: string;
		mobilePhoneNo: string;
		birthDt: string;
		basAddr: string;
		detailAddr: string;
		zipCode: string;
		guardianRelCd: string;
		mainGuardianYn: boolean;
		deleteYn?: boolean;
	}[];
}
export interface UpdateRecipientGuardiansData {
	recipientId: number;
}

export type UpdateRecipientGuardiansResponse = BaseResponse<UpdateRecipientGuardiansData>;

export interface UpdateRecipientInUseRequest {
	recipientId: number;
}
export interface UpdateRecipientInUseData {
	recipientId: number;
}

export type UpdateRecipientInUseResponse = BaseResponse<UpdateRecipientInUseData>;

export interface UpdateRecipientOtherServicesRequest {
	recipientId: number;
	body: {
		otherCenterNm: string;
		otherCenterPhoneNo: string;
		serviceTypeCd: string;
		remark: string;
		times: ServiceTime[];
	}[];
}
export interface UpdateRecipientOtherServicesData {
	recipientId: number;
}

export type UpdateRecipientOtherServicesResponse = BaseResponse<UpdateRecipientOtherServicesData>;

export interface UpdateRecipientWaitingRequest {
	recipientId: number;
}
export interface UpdateRecipientWaitingData {
	recipientId: number;
}

export type UpdateRecipientWaitingResponse = BaseResponse<UpdateRecipientWaitingData>;

export interface DeleteFileDetailRequestDTO {
	fileDetailId: number;
}

export type DeleteFileDetailResponse = BaseResponse<string>;

export interface MyMenuInfoDTO {
	menuId: number;
	korMenuNm: string;
	engMenuNm: string;
	menuLevel: number;
	menuStateCd: string;
	menuStateNm: string;
	menuTypeCd: string;
	menuTypeNm: string;
	orderSeq: number;
	menuUrl: string;
	menuGroupCd?: string;
	menuGroupNm?: string;
	permitCenterTypeCd: string;
	permitCenterTypeNm: string;
	searchPosbYn: boolean;
	updatePosbYn: boolean;
	deletePosbYn: boolean;
	routerUrl: string;
	children: MyMenuInfoDTO[];
}

export type MyMenuInfoResponse = BaseResponse<MyMenuInfoDTO[]>;

export interface AutomationUploadRequestDTO {
	centerId?: number;
	uploadTargetYm?: string;
	uploadTypeCds?: string[];
}

export interface AutomationUploadDTO {
	centerId: number;
	centerNm: string;
	uploadTargetYm: string;
	uploadTypeCd: string;
	uploadTypeNm: string;
	uploadTotalCnt: number;
	uploadCompleteCnt: number;
	uploadRemainCnt: number;
	uploadStartDate: string;
	uploadEndDate: string;
	uploadStateCd: string;
	uploadStateNm: string;
	uploadResultDesc: string;
	createdId: number;
	createdNm: string;
	uploadHistId: number;
}

export type AutomationUploadResponse = BaseResponse<AutomationUploadDTO[]>;

export interface UpdateAutomationUploadRequestDTO {
	uploadHistId: number;
}

export type UpdateAutomationUploadResponse = BaseResponse<AutomationUploadDTO>;

// 개인정보 열람이력 등록

export enum IndividualInfoCode {
	'CMN175.10' = 'CMN175.10', // 주민등록번호
}

export interface InquireIndividualInfoRequest {
	memberId?: number;
	menuId?: number;
	personalInfoBrowseDesc: string;
	personalInfoBrowseItems: {
		personalInfoBrowseDivCd: IndividualInfoCode;
	}[];
}

export type InquireIndividualInfoResponse = BaseResponse<undefined>;

// 자동화 마지막 동기화 시점
export interface AutomationLastSyncRequest {
	centerId?: number;
	uploadTargetYm?: string;
	uploadTypeCds: string[];
}

export type AutomationLastSyncResponse = BaseResponse<AutomationUploadDTO>;

// 페이지있는 데이터
export interface Sort {
	empty: boolean;
	sorted: boolean;
	unsorted: boolean;
}
export interface Pageable {
	sort: Sort;
	offset: number;
	pageNumber: number;
	pageSize: number;
	unpaged: boolean;
	paged: boolean;
}

export type PageResponse<T> = {
	content: T;
	pageable: Pageable;
	totalPages: number;
	totalElements: number;
	last: boolean;
	size: number;
	number: number;
	sort: Sort;
	numberOfElements: number;
	first: boolean;
	empty: boolean;
};

export enum UploadTypeCd {
	'임금계산' = 'CMN107.SALARY',
	'청구내역/청구내역상세' = 'CMN107.CHARGE',
}

// 예금주 조회

export interface CheckAccountOwnerRequest {
	acctNo?: string;
	bankCode?: string;
	custNm?: string;
}

export type CheckAccountOwnerResponse = BaseResponse<string>;

export interface CheckAccountOwnerData {
	errCode: string;
	errMsg: string;
	result: string;
	data: {
		TRDNO: string;
		MCHTTRDNO: string;
		OUTRSLTMSG: string;
		OUTRSLTCD: string;
		RESULT: string;
		OUTSTATCD: string;
	};
}

// 자동화 슬랙 알림
export interface SendSlackRequest {
	message: string;
}

export interface CheckRealNameRequest {
	name: string;
	rsdnNo: string;
	foreignerYn: boolean;
}

export interface CheckRealNameDTO {
	result: boolean;
	resultDesc: string;
}

export type CheckRealNameResponse = BaseResponse<CheckRealNameDTO>;

export enum CommonCodeETCFlag {
	Y = 'Y',
	N = 'N',
}

export interface CenterMemberSearchRequest {
	name?: string;
	birthDt?: string;
}

export interface CenterMemberSearchDTO {
	memberId: number;
	targetId: number;
	korMemberNm: string;
	birthDt: string;
	rsdnNo: string;
	longTermNo?: string;
	centerId: number;
	centerNm: string;
	centerAliasNm: string;
	stateCd?: string;
	stateNm?: string;
	managerId?: number;
	managerNm?: string;
}

export type GetAllCenterMemberResponse = BaseResponse<CenterMemberSearchDTO[]>;
