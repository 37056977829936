import React, { useLayoutEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Buffer } from 'buffer';
import * as yup from 'yup';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRInputLabel from 'components/base/CRInputLabel';
import { Toast } from 'components/base/CRToast';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { corporationListAdapter } from 'lib/adapter/common';
import { useCommonCorporations } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { CorporationDTO } from 'types/dto';

import * as S from './styles';

const EmployReportAutomatonRequestValidator = yup.object({
	corps: yup.array().min(1, '법인을 선택해주세요').required('법인을 선택해주세요'),
});

interface Props {
	handleChageProgress: () => void;
}

function EmployReportAutomatonRequestDialog({ handleChageProgress }: Props): React.ReactElement {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { hideDialog } = useDialog();
	const { data: corporationOptions } = useCommonCorporations(corporationListAdapter);
	const {
		control,
		handleSubmit,
		formState: { isValid },
		reset,
	} = useForm<{ corps: CheckOption<CorporationDTO>[] }>({
		mode: 'onSubmit',
		resolver: yupResolver(EmployReportAutomatonRequestValidator),
		defaultValues: { corps: corporationOptions },
	});

	const requestEmployReport = async (corpIds: number[]) => {
		try {
			const queryParams = new URLSearchParams({ corpIds: corpIds.join(',') });
			const response = await fetch(
				`https://gongza-worker.caring.co.kr/ssis/update?${queryParams}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Basic ${Buffer.from('admin:zpdjfld!@').toString('base64')}`,
					},
				},
			);
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			Toast.error('인력신고 자동화 요펑에 성공하였습니다.');
		} catch (error) {
			Toast.error('인력신고 자동화 요청에 실패하였습니다. 잠시 후 다시 시도해주세요.');
		}
	};

	const onSubmit = async (inputData: { corps: CheckOption<CorporationDTO>[] }) => {
		if (isSubmitting) return;
		setIsSubmitting(true);
		await requestEmployReport(inputData.corps.map((item) => item.value));
		hideDialog();
		handleChageProgress();
		setIsSubmitting(false);
	};

	useLayoutEffect(() => {
		if (!corporationOptions) return;
		reset({ corps: corporationOptions });
	}, [corporationOptions]);

	return (
		<S.Container>
			<S.Form onSubmit={handleSubmit(onSubmit)}>
				<S.HeaderContainer>
					<S.HeaderTitle>자동화 요청</S.HeaderTitle>
					<S.CloseIcon src={Assets.icon.close} alt='close' onClick={hideDialog} />
				</S.HeaderContainer>
				<S.BodyContainer>
					<S.BodyContent>
						<Controller
							render={({ field: { onChange, value } }) => (
								<CRInputLabel label='법인' isRequired>
									<CRCheckBoxGroup
										checkType='multiple'
										type='checkbox'
										direction='col'
										appearanceType='button'
										gap={0.4}
										options={
											corporationOptions?.map((item) => ({
												...item,
												label: `${item.label}(${item.data?.corpNm})`,
											})) ?? []
										}
										onChange={onChange}
										value={value}
									/>
								</CRInputLabel>
							)}
							name='corps'
							control={control}
						/>
					</S.BodyContent>
				</S.BodyContainer>
				<S.FooterContainer>
					<CRButton.Default palette='gray' type='text' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default onClick={handleSubmit(onSubmit)} disabled={!isValid}>
						요청
					</CRButton.Default>
				</S.FooterContainer>
			</S.Form>
		</S.Container>
	);
}

export default React.memo(EmployReportAutomatonRequestDialog);
