import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 48rem;
	max-height: calc(100vh - 4.8rem);
	overflow: hidden;
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const Spacer = styled.div`
	height: 1.6rem;
`;

export const BodyContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	padding: 2.4rem;
	gap: 1.6rem;
`;

export const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
`;

export const TableRowValueContainer = styled.div`
	${(props) => props.theme.typography.label};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 0.8rem;
`;
