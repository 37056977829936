import React, { useCallback, useState } from 'react';

import Colors from 'common/colors';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import RecipientContractReviewSideModal from 'components/domain/sideModal/RecipientContractReviewSideModal';
import useSideModal from 'lib/hook/util/useSideModal';
import { RecipientContractManagemetTableRenderKey } from 'pages/dashboard/NewContractManagementPage/constant';
import { CRStatusConfig } from 'types/view/base';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';

import {
	ADMISSION_APPLICATION_SEND_STATUS_CONFIG,
	APPLICATION_REQUIRED_STATUS_CONFIG,
	COMPLETION_STATUS_CONFIG,
	EMPLOYEE_CHANGE_CONSULTATION_WRITING_STATUS_CONFIG,
	EVALUATION_STATUS_CONFIG,
	LONGTERM_CARE_BENEFIT_PLAN_CHANGE_STATUS_CONFIG,
	LONGTERM_CARE_BENEFIT_PLAN_OVERALL_OPINION_REVIEW_STATUS_CONFIG,
	NOTI_TO_CORP_STATUS_CONFIG,
	RECIPIENT_CONTRACT_SEND_STATUS_CONFIG,
	RECIPIENT_CONTRACT_TABLE_HEADER_CONFIG,
	RECIPIENT_SCHEDULE_REGISTRATION_STATUS_CONFIG,
	RECIPIENT_VALIDATION_STATUS_CONFIG,
	TAG_APPLICATION_REQUEST_CONFIG,
	TAG_ATTACHMENT_CLASSIFICATION_CONFIG,
	TAG_ATTACHMENT_STATUS_CONFIG,
	WRITING_STATUS_CONFIG,
} from './constant';
import * as S from './styles';

interface Props {
	recipientContractData: Array<RecepientContractReviewListViewType>;
	sideModalDefaultActiveKey?: string;
	tableRenderKeys: RecipientContractManagemetTableRenderKey[];
	checkedList: Array<RecepientContractReviewListViewType>;
	setCheckedList: React.Dispatch<React.SetStateAction<RecepientContractReviewListViewType[]>>;
}

function RecipientContractReviewTable({
	recipientContractData,
	tableRenderKeys,
	sideModalDefaultActiveKey,
	checkedList,
	setCheckedList,
}: Props): React.ReactElement {
	const { showSideModal, hideSideModal } = useSideModal();
	const [currentRow, setCurrentRow] = useState<RecepientContractReviewListViewType>();

	const renderMultipleCell = (children?: React.ReactElement) => (
		<S.MultipleCellContainer>{children}</S.MultipleCellContainer>
	);

	const renderConfigStatus = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || value === item.value);

		return (
			<CRStatus type='pill' options={config}>
				{currentValue?.label}
			</CRStatus>
		);
	}, []);

	const renderConfigText = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || value === item.value);
		const currentColor =
			currentValue?.color === 'green'
				? 'gray00'
				: currentValue?.color === 'yellow'
					? 'red'
					: (currentValue?.color as keyof typeof Colors);

		return (
			<CRText typography='label' color={currentColor}>
				{currentValue?.label ?? '-'}
			</CRText>
		);
	}, []);

	const renderCellText = useCallback(
		(value?: string) => <CRText typography='label'>{value}</CRText>,
		[],
	);

	const renderCheckBox = useCallback(
		(value: boolean, item?: RecepientContractReviewListViewType) => (
			<CRCheckBox
				palette='gray'
				checked={value}
				onChange={item && handleClickCheckBox(item)}
				customStyle={{ justifyContent: 'center' }}
			/>
		),
		[checkedList],
	);

	const renderCheckBoxHeader = useCallback(() => {
		const isAllChecked = recipientContractData.every((item) =>
			checkedList.some((checkedItem) => checkedItem.serviceContractId === item.serviceContractId),
		);

		return (
			<CRCheckBox
				palette='gray'
				checkBoxType='toggle'
				checked={isAllChecked}
				onChange={() => {
					if (isAllChecked) {
						setCheckedList([]);
					} else {
						setCheckedList(recipientContractData);
					}
				}}
				customStyle={{ justifyContent: 'center' }}
			/>
		);
	}, [checkedList]);

	const renderStringMultiCell = useCallback(
		(value: string[]) => value.map((item) => renderMultipleCell(renderCellText(item))),
		[],
	);

	const renderEvlauationStatus = useCallback(
		(value: boolean[]) =>
			value.map((item) =>
				renderMultipleCell(renderConfigText(EVALUATION_STATUS_CONFIG, item ? '정상' : '확인필요')),
			),
		[],
	);

	const renderCompletionStatus = useCallback(
		(value: boolean) => renderConfigStatus(COMPLETION_STATUS_CONFIG, value ? '완료' : '미완료'),
		[],
	);

	const handleClickRow = (item: RecepientContractReviewListViewType) => {
		if (item.serviceContractId === currentRow?.serviceContractId) {
			setCurrentRow(undefined);
			hideSideModal();
		} else {
			setCurrentRow(item);
			showSideModal(
				<RecipientContractReviewSideModal
					data={item}
					defaultActiveKey={sideModalDefaultActiveKey}
				/>,
			);
		}
	};

	const handleRowStyle = (item: RecepientContractReviewListViewType) =>
		item.serviceContractId === currentRow?.serviceContractId
			? { background: Colors.primary95 }
			: undefined;

	const handleHeaderConfig = () => {
		const headerConfig = RECIPIENT_CONTRACT_TABLE_HEADER_CONFIG;
		const currentHeader = headerConfig
			.slice(1)
			.map((head) => ({
				...head,
				children:
					head.children?.filter(
						(subHead) =>
							subHead.sortKey &&
							tableRenderKeys.includes(subHead.sortKey as RecipientContractManagemetTableRenderKey),
					) || [],
			}))
			.filter((head) => head.children.length > 0);

		return [
			{ label: renderCheckBoxHeader(), sortKey: 'checkbox', width: '5.6rem' },
			...headerConfig.slice(0, 1),
			...currentHeader,
			...headerConfig.slice(-1),
		];
	};

	const handleClickCheckBox =
		(item: RecepientContractReviewListViewType) => (e: React.ChangeEvent<HTMLInputElement>) => {
			e.stopPropagation();
			const index = checkedList.findIndex(
				(checkedItem) => checkedItem.serviceContractId === item.serviceContractId,
			);
			if (index === -1) {
				setCheckedList([...checkedList, item]);
			} else {
				const newCheckedList = [...checkedList];
				newCheckedList.splice(index);
				setCheckedList(newCheckedList);
			}
		};

	return (
		<S.TableScrollContainer>
			<CRTable.Root style={{ tableLayout: 'auto', overflow: 'scroll' }}>
				<CRTable.MultiHead heads={handleHeaderConfig()} />
				<CRTable.Body>
					{recipientContractData.map((item) => (
						<CRTable.Row
							style={handleRowStyle(item)}
							key={item.serviceContractId}
							item={{
								checked: checkedList.some(
									(checkedItem) => checkedItem.serviceContractId === item.serviceContractId,
								),
								...item,
								longtermCareBenefitType: item.lognTermCareBenefitInfo.map(
									(item) => item.longtermCareBenefitType,
								),
								longtermCareStartDate: item.lognTermCareBenefitInfo.map((item) =>
									item.longtermCareStartDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3'),
								),
								longtermCareEndDate: item.lognTermCareBenefitInfo.map((item) =>
									item.longtermCareEndDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3'),
								),
								employeeName: item.lognTermCareBenefitInfo.map((item) => item.employeeName),
								longtermCareBenefitPlanDocumentVerification: item.lognTermCareBenefitInfo.map(
									(item) => item.longtermCareBenefitPlanDocumentVerification,
								),
								longtermCareBenefitPlanDocumnetSendStatus: item.lognTermCareBenefitInfo.map(
									(item) => item.longtermCareBenefitPlanDocumnetSendStatus,
								),
								integratedAssessmentWritingStatus: item.lognTermCareBenefitInfo.map(
									(item) => item.integratedAssessmentWritingStatus,
								),
								fallRiskAssessmentCompletionStatus: item.lognTermCareBenefitInfo.map(
									(item) => item.fallRiskAssessmentCompletionStatus,
								),
								pressureInjuryRiskAssessmentCompletionStatus: item.lognTermCareBenefitInfo.map(
									(item) => item.pressureInjuryRiskAssessmentCompletionStatus,
								),
								cognitiveFunctionAssessmentCompletionStatus: item.lognTermCareBenefitInfo.map(
									(item) => item.cognitiveFunctionAssessmentCompletionStatus,
								),
								needsAssessmentCompletionStatus: item.lognTermCareBenefitInfo.map(
									(item) => item.needsAssessmentCompletionStatus,
								),
							}}
							renderKeys={[
								'checked',
								'totalWorkCompletionStatus',
								'center',
								'socialWorker',
								'recipientName',
								'longTermCareNumber',
								'contractType',
								'contractDate',
								'longtermCareBenefitType',
								'longtermCareStartDate',
								'longtermCareEndDate',
								'employeeName',
								...tableRenderKeys,
								'referenceContractNotes',
								'adminMemo',
							]}
							customRender={{
								checked: renderCheckBox,
								totalWorkCompletionStatus: renderCompletionStatus,
								contractDate: (value) => value.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1.$2.$3'),
								// 수급자 계약
								recipientContractCompletionStatus: renderCompletionStatus,
								recipientContractSendStatus: (value) =>
									renderConfigText(RECIPIENT_CONTRACT_SEND_STATUS_CONFIG, value),
								recipientContractDocumentVerification: (value) =>
									renderConfigText(RECIPIENT_VALIDATION_STATUS_CONFIG, value ? '적합' : '부적합'),
								// 급여유형별 정보
								longtermCareBenefitType: renderStringMultiCell,
								longtermCareStartDate: renderStringMultiCell,
								longtermCareEndDate: renderStringMultiCell,
								employeeName: renderStringMultiCell,

								// 직원 교체 상담일지
								employeeChangeConsultationWritingStatus: (value) =>
									renderConfigStatus(EMPLOYEE_CHANGE_CONSULTATION_WRITING_STATUS_CONFIG, value),
								// 급여제공계획서
								longtermCareBenefitPlanCompletionStatus: renderCompletionStatus,
								longtermCareBenefitPlanDocumnetSendStatus: (value) =>
									value.map((item) =>
										renderMultipleCell(
											renderConfigText(RECIPIENT_CONTRACT_SEND_STATUS_CONFIG, item),
										),
									),
								longtermCareBenefitPlanDocumentVerification: (value) =>
									value.map((item) =>
										renderMultipleCell(
											renderConfigText(
												RECIPIENT_VALIDATION_STATUS_CONFIG,
												item ? '적합' : '부적합',
											),
										),
									),
								longtermCareBenefitPlanOverallOpinionReview: (value) =>
									renderConfigText(
										LONGTERM_CARE_BENEFIT_PLAN_OVERALL_OPINION_REVIEW_STATUS_CONFIG,
										value,
									),
								longtermCareBenefitPlanNotificationToCorporation: (value) =>
									renderConfigText(NOTI_TO_CORP_STATUS_CONFIG, value ? '완료' : '미통보'),
								longtermCareBenefitPlanReasonForChange: (value) =>
									renderConfigText(LONGTERM_CARE_BENEFIT_PLAN_CHANGE_STATUS_CONFIG, value),
								// 통합 사정
								integratedAssessmentCompletionStatus: renderCompletionStatus,
								integratedAssessmentWritingStatus: (value) =>
									value.map((item) =>
										renderMultipleCell(
											renderConfigText(WRITING_STATUS_CONFIG, item ? '작성' : '미작성'),
										),
									),
								fallRiskAssessmentCompletionStatus: renderEvlauationStatus,
								pressureInjuryRiskAssessmentCompletionStatus: renderEvlauationStatus,
								cognitiveFunctionAssessmentCompletionStatus: renderEvlauationStatus,
								needsAssessmentCompletionStatus: renderEvlauationStatus,

								// 입소이용신청
								admissionApplicationFormCompletionStatus: renderCompletionStatus,
								isTargetOfadmissionApplication: (value) =>
									renderConfigText(
										APPLICATION_REQUIRED_STATUS_CONFIG,
										value ? '신청필요' : '해당없음',
									),
								isCompletedAdmissionApplicationForm: (value) =>
									renderConfigText(COMPLETION_STATUS_CONFIG, value ? '완료' : '미완료'),
								admissionApplicationFormSendStatus: (value) =>
									renderConfigText(RECIPIENT_CONTRACT_SEND_STATUS_CONFIG, value),
								admissionApplicationSendApprovalStatus: (value) =>
									renderConfigText(ADMISSION_APPLICATION_SEND_STATUS_CONFIG, value),

								// 공단 일정 등록
								registeredLongtermCareBenefitScheduleToCorporation: (value) =>
									renderConfigStatus(RECIPIENT_SCHEDULE_REGISTRATION_STATUS_CONFIG, value),
								// 개인별 장기요양이용계획서 열람신청
								appliedIndividualLongtermCarePlan: renderCompletionStatus,
								// 태그
								tagCompletionStatus: renderCompletionStatus,
								tagSocialWorkerRequest: (value) =>
									renderConfigText(TAG_APPLICATION_REQUEST_CONFIG, value),
								tagAttachmentClassification: (value) =>
									renderConfigText(TAG_ATTACHMENT_CLASSIFICATION_CONFIG, value),
								tagAttachmentStatus: (value) =>
									renderConfigText(TAG_ATTACHMENT_STATUS_CONFIG, value),
								referenceContractNotes: (value) => value || '-',
								adminMemo: (value) => value || '-',
							}}
							customStyle={{
								totalWorkCompletionStatus: { textAlign: 'center' },
								longtermCareBenefitType: { padding: 0 },
								longtermCareStartDate: { padding: 0 },
								longtermCareEndDate: { padding: 0 },
								employeeName: { padding: 0 },

								// 수급자 계약서
								recipientContractCompletionStatus: { textAlign: 'center' },
								recipientContractSendStatus: { textAlign: 'center' },
								recipientContractDocumentVerification: { textAlign: 'center' },
								// 급여제공계획서
								longtermCareBenefitPlanCompletionStatus: { textAlign: 'center' },
								longtermCareBenefitPlanOverallOpinionReview: { textAlign: 'center' },
								longtermCareBenefitPlanNotificationToCorporation: { textAlign: 'center' },
								// 급여유형별 급여제공계획서 정보
								longtermCareBenefitPlanDocumentVerification: { padding: 0, textAlign: 'center' },
								longtermCareBenefitPlanDocumnetSendStatus: { padding: 0, textAlign: 'center' },
								// 급여제공계획 변경 사유서 작성 여부
								longtermCareBenefitPlanReasonForChange: { textAlign: 'center' },
								// 통합사정
								integratedAssessmentCompletionStatus: { textAlign: 'center' },
								// 급여유형별 통합사정
								integratedAssessmentWritingStatus: { padding: 0, textAlign: 'center' },
								fallRiskAssessmentCompletionStatus: { padding: 0, textAlign: 'center' },
								pressureInjuryRiskAssessmentCompletionStatus: { padding: 0, textAlign: 'center' },
								cognitiveFunctionAssessmentCompletionStatus: { padding: 0, textAlign: 'center' },
								needsAssessmentCompletionStatus: { padding: 0, textAlign: 'center' },
								// 요양보호사 교체 상담일지
								employeeChangeConsultationWritingStatus: { textAlign: 'center' },
								// 입소 이용 신청서
								admissionApplicationFormCompletionStatus: { textAlign: 'center' },
								isTargetOfadmissionApplication: { textAlign: 'center' },
								isCompletedAdmissionApplicationForm: { textAlign: 'center' },
								admissionApplicationFormSendStatus: { textAlign: 'center' },
								admissionApplicationSendApprovalStatus: { textAlign: 'center' },
								// 공단 일정 등록
								registeredLongtermCareBenefitScheduleToCorporation: { textAlign: 'center' },
								// 개인별 장기요양이용계획서 열람신청
								appliedIndividualLongtermCarePlan: { textAlign: 'center' },
								// 태그
								tagCompletionStatus: { textAlign: 'center' },
								tagSocialWorkerRequest: { textAlign: 'center' },
								tagAttachmentClassification: { textAlign: 'center' },
								tagAttachmentStatus: { textAlign: 'center' },
							}}
							onClick={handleClickRow}
							stopPropagationKeys={['checked']}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.TableScrollContainer>
	);
}

export default RecipientContractReviewTable;
