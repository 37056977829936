import React, { useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { SalaryDetailDialogFormFields } from 'components/domain/dialog/SalaryDetailDialog/types';
import { UpdateSalaryScheduleDialog } from 'components/domain/dialog/UpdateSalaryScheduleDialog';
import { displayComma } from 'lib';
import useDialog from 'lib/hook/util/useDialog';
import { UpdateSalaryServiceScheduleForm } from 'types/api/salary';
import { ServiceType } from 'types/view/common';

import * as S from './styles';

interface Props {
	isDeletableSalaryHistory: boolean;
}

function SalaryDetailServiceSummary({ isDeletableSalaryHistory }: Props): React.ReactElement {
	const [didShowToast, setDidShowToast] = useState(false);
	const { watch, control, getValues } = useFormContext<SalaryDetailDialogFormFields>();
	const { showDialog, hideDialog } = useDialog();
	const salaryServiceScheduleSummarys = watch('salaryDetail.salaryServiceScheduleSummarys');
	const salaryServiceSchedules = useMemo(
		() =>
			(watch('salaryDetail.salaryServiceSchedules') as UpdateSalaryServiceScheduleForm[]).filter(
				(schedule) => schedule.automationDivNm === 'UPDATE_BILLING',
			),
		[watch('salaryDetail.salaryServiceSchedules')],
	);

	const { update } = useFieldArray({
		control,
		name: 'salaryDetail.salaryServiceSchedules',
	});

	const formatTime = (time?: string) => {
		if (!time) return '';
		const hours = time.substring(0, 2);
		const minutes = time.slice(2, 4);
		return `${hours}:${minutes}`;
	};

	const getServiceName = (schedule: UpdateSalaryServiceScheduleForm) => {
		if (schedule.serviceKindCd === ServiceType.VisitCare && !schedule.familyYn) {
			return `일반요양 ${schedule.serviceOfferHourCnt}분`;
		}
		if (schedule.serviceKindCd === ServiceType.FamilyCare && schedule.familyYn) {
			return `가정요양 ${schedule.serviceOfferHourCnt}분`;
		}
		if (
			schedule.serviceKindCd === ServiceType.VisitBath &&
			schedule.pcorpServiceFeeCd === 'CMN209.203'
		) {
			return `차량내목욕 ${schedule.serviceOfferHourCnt}분`;
		}
		if (
			schedule.serviceKindCd === ServiceType.VisitBath &&
			schedule.pcorpServiceFeeCd &&
			['CMN209.203', 'CMN209.204'].includes(schedule.pcorpServiceFeeCd)
		) {
			return `차량내목욕 ${schedule.serviceOfferHourCnt}분`;
		}
		if (
			schedule.serviceKindCd === ServiceType.VisitBath &&
			schedule.pcorpServiceFeeCd &&
			['CMN209.205', 'CMN209.206'].includes(schedule.pcorpServiceFeeCd)
		) {
			return `가정내목욕 ${schedule.serviceOfferHourCnt}분`;
		}
		if (schedule.serviceKindCd === ServiceType.VisitBath && !schedule.familyYn) {
			return `일반목욕 ${schedule.serviceOfferHourCnt}분`;
		}
		if (schedule.serviceKindCd === ServiceType.VisitBath && schedule.familyYn) {
			return `가족목욕 ${schedule.serviceOfferHourCnt}분`;
		}
		if (schedule.serviceKindCd === ServiceType.VisitNursing && !schedule.familyYn) {
			return `일반간호 ${schedule.serviceOfferHourCnt}분`;
		}
		if (schedule.serviceKindCd === ServiceType.VisitNursing && schedule.familyYn) {
			return `가족간호 ${schedule.serviceOfferHourCnt}분`;
		}

		return '';
	};

	const handleSaveSalaryWorkSchedule = (schedule: UpdateSalaryServiceScheduleForm) => {
		const salaryServiceSchedules = getValues('salaryDetail.salaryServiceSchedules');
		const index = salaryServiceSchedules.findIndex(
			(s) => s.salaryServiceScheduleId === schedule.salaryServiceScheduleId,
		);

		update(index, { ...schedule, serviceNm: getServiceName(schedule), transactionType: 'UPDATE' });

		if (!didShowToast) {
			Toast.success('근무 상세 내역이 수정되었습니다. 저장(재계산)을 해야 반영됩니다.');
			setDidShowToast(true);
		}
		hideDialog();
	};

	const handleClickUpdateSchedule = (schedule: UpdateSalaryServiceScheduleForm) => () => {
		if (schedule?.transactionType === 'UPDATE' && schedule.original) {
			const index = salaryServiceSchedules.findIndex(
				(s) => s.salaryServiceScheduleId === schedule.salaryServiceScheduleId,
			);
			update(index, schedule.original);
			Toast.success('근무 상세 내역 수정이 취소되었습니다.');

			return;
		}

		const originalSchedule = schedule.original ? schedule.original : { ...schedule };

		showDialog(() => (
			<UpdateSalaryScheduleDialog
				scheduleData={originalSchedule}
				onSave={handleSaveSalaryWorkSchedule}
			/>
		));
	};

	const handleDeleteSalaryWorkSchedule = (schedule: UpdateSalaryServiceScheduleForm) => () => {
		const salaryServiceSchedules = getValues('salaryDetail.salaryServiceSchedules');
		const index = salaryServiceSchedules.findIndex(
			(s) => s.salaryServiceScheduleId === schedule.salaryServiceScheduleId,
		);

		if (schedule?.transactionType === 'DELETE' && schedule.original) {
			const index = salaryServiceSchedules.findIndex(
				(s) => s.salaryServiceScheduleId === schedule.salaryServiceScheduleId,
			);
			update(index, schedule.original);
			Toast.success('근무 상세 내역 삭제가 취소되었습니다.');
			return;
		}

		const originalSchedule = schedule.original ? schedule.original : { ...schedule };

		update(index, { ...schedule, original: originalSchedule, transactionType: 'DELETE' });
		if (!didShowToast) {
			Toast.success('근무 상세 내역이 삭제되었습니다. 저장(재계산)을 해야 반영됩니다.');
			setDidShowToast(true);
		}
	};

	return (
		<S.Container>
			<CRText typography='label' text='근로 정보' margin='0 0 0.4rem 0' />
			<S.Table style={{ marginBottom: '2rem' }}>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '18rem' }}>급여 종류</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '18rem' }}>수급자</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '18rem' }}>총 근무 시간</S.TableLabelColumn>
					<S.TableLabelColumn>임금</S.TableLabelColumn>
				</S.TableRow>
				{salaryServiceScheduleSummarys.map((summary, index) => {
					const isLastRow = salaryServiceScheduleSummarys.length - 1 === index;
					if (!isLastRow) {
						return (
							<S.TableRow>
								<S.TableValueColumn $textAlign='left'>{summary.serviceNm}</S.TableValueColumn>
								<S.TableValueColumn $textAlign='left'>{summary.recipientNm}</S.TableValueColumn>
								<S.TableValueColumn $textAlign='left'>
									{summary.totalWorkHourInfo}
								</S.TableValueColumn>
								<S.TableValueColumn $textAlign='left'>
									{displayComma(summary.totalSalaryAmt)}
								</S.TableValueColumn>
							</S.TableRow>
						);
					}

					return (
						<S.TableRow>
							<S.TableValueColumnBase $textAlign='left'>
								<CRText typography='labelB' text={summary.serviceNm} />
							</S.TableValueColumnBase>
							<S.TableValueColumnBase $textAlign='left'>
								<CRText typography='labelB' text={`${summary.recipientNm}명`} />
							</S.TableValueColumnBase>
							<S.TableValueColumnBase $textAlign='left'>
								<CRText typography='labelB' text={summary.totalWorkHourInfo} />
							</S.TableValueColumnBase>
							<S.TableValueColumnBase $textAlign='left'>
								<CRText typography='labelB' text={displayComma(summary.totalSalaryAmt)} />
							</S.TableValueColumnBase>
						</S.TableRow>
					);
				})}
			</S.Table>
			<S.SectionContainer>
				<S.SectionTitleContainer>
					<CRText typography='label' text='근로 상세 내역' />
				</S.SectionTitleContainer>
			</S.SectionContainer>
			<S.Table>
				<S.TableRow>
					<S.TableLabelColumn style={{ width: '18rem' }}>급여 종류</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '8rem' }}>수급자</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '10rem' }}>근무 일자</S.TableLabelColumn>
					<S.TableLabelColumn style={{ width: '18rem' }}>근무 시간</S.TableLabelColumn>
					<S.TableLabelColumn />
				</S.TableRow>
				{salaryServiceSchedules.map((schedule) => {
					const serviceOfferDisplay =
						schedule.restHourCnt > 0
							? `(${schedule.workHourCnt}분 / 휴게 ${schedule.restHourCnt}분)`
							: `(${schedule.serviceOfferHourCnt}분)`;
					return (
						<S.TableRow $type={schedule.transactionType}>
							<S.TableValueColumn $textAlign='left'>{schedule.serviceNm}</S.TableValueColumn>
							<S.TableValueColumn $textAlign='left'>{schedule.recipientNm}</S.TableValueColumn>
							<S.TableValueColumn $textAlign='left'>
								{dayjs(schedule.serviceStartDt).format('YYYY.MM.DD')}
							</S.TableValueColumn>
							<S.TableValueColumn $textAlign='left'>{`${formatTime(
								schedule.serviceStartTime,
							)}~${formatTime(
								schedule.serviceEndTime,
							)} ${serviceOfferDisplay}`}</S.TableValueColumn>
							<S.TableValueColumn $textAlign='left'>
								<S.OptionContainer>
									<CRButton.Default
										disabled={!isDeletableSalaryHistory}
										size='xSmall'
										type='outlined'
										palette='gray'
										onClick={handleClickUpdateSchedule(schedule)}>
										{schedule.transactionType === 'UPDATE' ? '수정 취소' : '수정'}
									</CRButton.Default>
									<CRButton.Default
										disabled={!isDeletableSalaryHistory}
										size='xSmall'
										type='outlined'
										onClick={handleDeleteSalaryWorkSchedule(schedule)}>
										{schedule.transactionType === 'DELETE' ? '삭제 취소' : '삭제'}
									</CRButton.Default>
								</S.OptionContainer>
							</S.TableValueColumn>
						</S.TableRow>
					);
				})}
			</S.Table>
		</S.Container>
	);
}

export default SalaryDetailServiceSummary;
