import Assets from 'assets';
import { CheckOption } from 'components/base/Selections/type';
import {
	CenterManager,
	CommonCenterDTO,
	CommonCodeDTO,
	GetCenterManagersData,
	GetCommonCentersData,
	GetExternalCenterData,
	MyMenuInfoDTO,
} from 'types/api/common';
import { CorporationDTO, ExternalCenterDTO } from 'types/dto';
import { SideNavigationSectionItem } from 'types/view/navigation';

export const myTabMenuAdapter = (items: MyMenuInfoDTO[] | null) => {
	const iconMap: { [key: string]: string } = {
		Recipient: Assets.icon.face,
		Employee: Assets.icon.accountCircle,
		Contract: Assets.icon.borderColor,
		Schedule: Assets.icon.today,
		Burden: Assets.icon.contactPage,
		Salary: Assets.icon.requestPage,
		Sends: Assets.icon.send,
		Visit: Assets.icon.door,
		'Social Insurance': Assets.icon.accountBalance,
		'Center Account': Assets.icon.money,
		'Work Schedule': Assets.icon.assignmentTurnedIn,
	};
	if (!items) return { finalResult: [], origin: [] };
	const result: { [key: string]: any } = {};
	const erp3env = [
		'https://erp2.caring.co.kr',
		'https://dev-erp2.caring.co.kr',
		'http://localhost:3000',
	].includes(window.location.origin);

	const tabMenuList = items
		.filter((child) => erp3env || child.menuStateCd !== 'CMN003.99')
		.filter(
			(item) => (erp3env || item.menuStateCd === 'CMN003.10') && item.menuTypeCd === 'CMN004.10',
		);

	tabMenuList.forEach((item) => {
		const groupCode = item.menuGroupCd as string;
		if (groupCode) {
			if (!result[groupCode]) {
				result[groupCode] = {
					label: item.menuGroupNm,
					menus: [],
				};
			}

			const { korMenuNm, engMenuNm, routerUrl } = item;
			const subMenu = item.children
				.filter((child) => erp3env || child.menuStateCd !== 'CMN003.99')
				.map((subMenu) => ({
					label: subMenu.korMenuNm,
					path: subMenu.routerUrl,
				}));

			result[groupCode].menus.push({
				label: korMenuNm,
				path: routerUrl,
				children: subMenu,
				icon: iconMap[engMenuNm],
			});
		}
	});

	const finalResult = Object.values(result) as SideNavigationSectionItem[];
	return { finalResult, origin: items };
};

export const commonCodeAdapter = (items: CommonCodeDTO[] | null) => {
	if (!items) return undefined;
	const codeGroupNames: string[] = [];
	const comeCodes: { [key: string]: CheckOption<CommonCodeDTO>[] } = {};
	items.forEach((item) => {
		if (!codeGroupNames.includes(item.comCdGroupNm)) {
			codeGroupNames.push(item.comCdGroupNm);
		}
	});
	codeGroupNames.forEach((groupName) => {
		comeCodes[groupName] = items
			.filter((item) => item.comCdGroupNm === groupName)
			?.map((item) => ({
				label: item.korComCdAliasNm,
				value: item.comCdId,
				data: item,
			}));
	});

	return comeCodes;
};

export const centerMangersOptionsAdapter = (
	data: GetCenterManagersData | null,
): CheckOption<CenterManager>[] =>
	(data ?? []).map((item) => ({
		label: item.userNm,
		value: item.memberAccountId,
		data: item,
	}));

export const centerListAdapter = (data: GetCommonCentersData | null) =>
	(data ?? []).map(
		(center) =>
			({
				label: center.value,
				value: center.id,
				data: center,
			}) as CheckOption<CommonCenterDTO>,
	);

export const externalCenterListAdapter = (data: GetExternalCenterData | null) =>
	(data ?? []).map(
		(center) =>
			({
				label: center.centerNm,
				value: center.centerId,
				data: center,
			}) as CheckOption<ExternalCenterDTO>,
	);

export const corporationListAdapter = (data: CorporationDTO[] | null) =>
	(data ?? []).map(
		(corp) =>
			({
				label: corp.corpAliasNm,
				value: corp.corpId,
				data: corp,
			}) as CheckOption<CorporationDTO>,
	);
