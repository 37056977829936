import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRIcon from 'components/base/CRIcon';
import CRInput from 'components/base/CRInput';
import { CRText } from 'components/base/CRText';
import { RecipientContractReviewFormFields } from 'components/domain/sideModal/RecipientContractReviewSideModal/types';
import InformationTable from 'components/ui/InformationTable';
import {
	InformationLabelType,
	InformationLabelValueType,
	InformationValueType,
} from 'components/ui/InformationTable/type';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';

import * as S from './styles';

interface Props {
	data: RecepientContractReviewListViewType;
}

function RecipientContractDetailBasicInfo({ data }: Props): React.ReactElement {
	const { control } = useFormContext<RecipientContractReviewFormFields>();

	const sameDayContractTooltip = `ERP 계약 추가 일시와\n급여 정보 [급여시작일]이 일치하는 경우,\n당일계약으로 표시됩니다.`;

	const basicInfoTableItem: InformationLabelValueType[][] = [
		[
			{
				type: 'default',
				label: '수급자명',
				value: data.recipientName,
				labelStyle: { width: '10.4rem' },
				valueStyle: { minWidth: '14.6rem' },
			},
			{
				type: 'default',
				label: (
					<S.TableLabelContainer>
						<CRText typography='label' color='gray60'>
							당일 계약
						</CRText>
						<RDTooltip content={sameDayContractTooltip} side='top'>
							<CRIcon src={Assets.icon.help} width='1.6rem' height='1.6rem' />
						</RDTooltip>
					</S.TableLabelContainer>
				),
				value: (
					<CRText typography='label' color={data.isSameDayContract ? 'primary60' : 'gray00'}>
						{data.isSameDayContract ? '당일계약' : '-'}
					</CRText>
				),
				labelStyle: { width: '10.4rem' },
				valueStyle: { minWidth: '14.6rem' },
			},
			{
				type: 'default',
				label: '소속센터',
				value: data.center,
				labelStyle: { width: '10.4rem' },
				valueStyle: { minWidth: '14.6rem' },
			},
		],
		[
			{ type: 'default', label: '인정번호', value: data.longTermCareNumber },
			{ type: 'default', label: '계약유형', value: data.contractType },
			{ type: 'default', label: '사회복지사', value: data.socialWorker },
		],
		[
			{
				type: 'default',
				label: '계약 특이사항',
				value: data.referenceContractNotes || '-',
				valueColSpan: 5,
			},
		],
		[
			{
				type: 'default',
				label: '메모',
				value: (
					<Controller
						control={control}
						name='remark'
						render={({ field: { ...rest } }) => (
							<CRInput.TextArea
								{...rest}
								placeholder='계약 검수 업무 간 메모를 입력해주세요'
								typography='label'
								numberOfLines={0}
							/>
						)}
					/>
				),
				valueColSpan: 5,
				valueStyle: { minWidth: '3.3rem' },
			},
		],
	];

	const salaryInfoTableItem = [
		[
			{ type: 'label', label: '급여 종류', labelStyle: { width: '10.4rem' } },
			{ type: 'label', label: '급여시작일', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '급여종료일', labelStyle: { width: '12rem' } },
			{ type: 'label', label: '직원명', labelStyle: { width: '20.4rem' } },
			{ type: 'label', label: '' },
		] as InformationLabelType[],
		...data.lognTermCareBenefitInfo.map(
			(item) =>
				[
					{ type: 'value', value: item.longtermCareBenefitType },
					{ type: 'value', value: dayjs(item.longtermCareStartDate).format('YYYY.MM.DD') },
					{ type: 'value', value: dayjs(item.longtermCareEndDate).format('YYYY.MM.DD') },
					{ type: 'value', value: item.employeeName },
					{ type: 'value', value: '' },
				] as InformationValueType[],
		),
	];

	return (
		<S.Container style={{ paddingTop: 0, paddingBottom: 0 }}>
			<S.TableContainer>
				<CRText typography='label' text='기본 정보' margin='0 0 0.4rem 0' />
				<InformationTable items={basicInfoTableItem} />
			</S.TableContainer>
			<S.TableContainer>
				<CRText typography='label' text='급여 정보' margin='0 0 0.4rem 0' />
				<InformationTable items={salaryInfoTableItem} />
			</S.TableContainer>
		</S.Container>
	);
}

export default RecipientContractDetailBasicInfo;
