import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { InsuranceEnrollDialog } from 'components/domain/dialog/InsuranceEnrollDialog';
import { EmployeeContractReviewFormFields } from 'components/domain/sideModal/EmployeeContractReviewSideModal/types';
import InformationTable from 'components/ui/InformationTable';
import { InformationLabelValueType } from 'components/ui/InformationTable/type';
import { useEmployeeInsurance } from 'lib/hook/react-query/query/insurance';
import useDialog from 'lib/hook/util/useDialog';
import { InsuranceState } from 'pages/dashboard/InsuranceDetailPage';
import { EmployeeInsuranceDTO } from 'types/api/insurance';
import { StatusColor } from 'types/view/base';
import { EmployeeContractReviewListViewType } from 'types/view/contractReview';

import { COMPLETION_STATUS_CONFIG, INSURANCE_TABLE_HEADER_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: EmployeeContractReviewListViewType;
}

function EmployeeInsuranceInfo({ data }: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const { control } = useFormContext<EmployeeContractReviewFormFields>();

	const { data: employeeInsuranceData, refetch: refetchInsurance } = useEmployeeInsurance({
		centerId: data?.centerId,
		employeeId: data?.employeeId,
	});

	const employeeInsuranceTableItemStyle = {
		border: 'none',
		backgroundColor: 'transparent',
		padding: 0,
		lineHeight: '2.5rem',
	};

	const renderInsuranceRequestState = useCallback(
		(value: { id: string; text: string; color: StatusColor }) => {
			const options = [{ key: value?.id, label: value?.text, color: value?.color }];
			return <CRStatus options={options}>{value?.id}</CRStatus>;
		},
		[],
	);

	const renderInsuranceRequestType = useCallback(
		(insuranceRequestType?: string) => (
			<S.ServiceTypeBadgeContainer>
				<S.TextBox>{insuranceRequestType || '-'}</S.TextBox>
			</S.ServiceTypeBadgeContainer>
		),
		[],
	);

	const renderPriceNumber = useCallback(
		(
			approvedKey?: keyof Pick<
				EmployeeInsuranceDTO,
				| 'accidentInsuranceApproveYn'
				| 'employInsuranceApproveYn'
				| 'healthInsuranceApproveYn'
				| 'pensionInsuranceApproveYn'
			>,
		) =>
			function renderPrice(value: number, item?: EmployeeInsuranceDTO) {
				let isDenied = false;
				if (
					approvedKey &&
					item?.insuranceRequestState?.id === InsuranceState.부분완료 &&
					item?.[approvedKey] === false
				) {
					isDenied = true;
				}
				return (
					<S.TextBox
						style={{
							color: isDenied ? Colors.gray60 : Colors.gray10,
							textDecoration: isDenied ? 'line-through' : 'auto',
						}}>
						{value && typeof value === 'number' ? value?.toLocaleString() : '-'}
					</S.TextBox>
				);
			},
		[],
	);

	const renderDate = useCallback(
		(
			approvedKey?: keyof Pick<
				EmployeeInsuranceDTO,
				| 'accidentInsuranceApproveYn'
				| 'employInsuranceApproveYn'
				| 'healthInsuranceApproveYn'
				| 'pensionInsuranceApproveYn'
			>,
		) =>
			function renderPrice(date: string, item?: EmployeeInsuranceDTO) {
				let isDenied = false;
				if (
					approvedKey &&
					item?.insuranceRequestState?.id === InsuranceState.부분완료 &&
					item?.[approvedKey] === false
				) {
					isDenied = true;
				}
				return (
					<S.TextBox
						style={{
							color: isDenied ? Colors.gray60 : Colors.gray10,
							textDecoration: isDenied ? 'line-through' : 'auto',
						}}>
						{date && dayjs(date).isValid() ? dayjs(date).format('YYYY.MM.DD') : '-'}
					</S.TextBox>
				);
			},
		[],
	);

	const renderFullDate = useCallback(
		(date: string) => (
			<S.TextBox>
				{date && dayjs(date).isValid() ? dayjs(date).format('YYYY.MM.DD HH:mm') : '-'}
			</S.TextBox>
		),
		[],
	);

	const employeeInsuranceInfo: InformationLabelValueType[][] = [
		[
			{
				label: '필요보험',
				value:
					[
						data.requiresNationalPensionService && '국민연금',
						data.requiresNationalHealthInsurance && '건강보험',
						data.requiresEmploymentInsurance && '고용보험',
						data.requiresEmployeeCompensationInsurance && '산재보험',
					]
						.filter(Boolean)
						.join(', ') || '-',
				labelStyle: { width: '20rem', ...employeeInsuranceTableItemStyle },
				valueStyle: employeeInsuranceTableItemStyle,
			},
		],
		[
			{
				label: '주 소정 근로시간',
				value: `${data.weeklyContractedHours}시간`,
				labelStyle: employeeInsuranceTableItemStyle,
				valueStyle: employeeInsuranceTableItemStyle,
			},
		],
		[
			{
				label: '신고금액',
				value: `${data.reportedInsuranceWage.toLocaleString()}원`,
				labelStyle: employeeInsuranceTableItemStyle,
				valueStyle: employeeInsuranceTableItemStyle,
			},
		],
		[
			{
				label: '신고일자',
				value: data.insuranceEnrollmentDate,
				labelStyle: employeeInsuranceTableItemStyle,
				valueStyle: employeeInsuranceTableItemStyle,
			},
		],
	];

	const handleClickAddEmployeeInsurance = () => {
		showDialog(() => (
			<InsuranceEnrollDialog
				item={{
					weekPerWorkHourCnt: data.weeklyContractedHours, // 주 소정근로시간
					accidentInsuranceRequestAmt: data.reportedInsuranceWage, // 산재보험
					accidentInsuranceRequestDate: data.insuranceEnrollmentDate,
					accidentInsuranceApproveYn: data.requiresEmployeeCompensationInsurance,
					pensionInsuranceRequestAmt: data.reportedInsuranceWage, // 국민연금
					pensionInsuranceRequestDate: data.insuranceEnrollmentDate,
					pensionInsuranceApproveYn: data.requiresNationalPensionService,
					employInsuranceRequestAmt: data.reportedInsuranceWage, // 고용보험
					employInsuranceRequestDate: data.insuranceEnrollmentDate,
					employInsuranceApproveYn: data.requiresEmploymentInsurance,
					healthInsuranceRequestAmt: data.reportedInsuranceWage, // 건강보험
					healthInsuranceRequestDate: data.insuranceEnrollmentDate,
					healthInsuranceApproveYn: data.requiresNationalHealthInsurance,
				}}
				employeeId={data.employeeId}
				centerId={data.centerId}
				onRefetch={refetchInsurance}
			/>
		));
		// TODO: 인력신고 추가 Dialog
	};

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						4대보험 취득 신고
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.isInsuranceEnrollmentReportCompleted ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<S.LabelValueContainer>
					<CRText
						typography='body'
						text='신고 도움 정보'
						style={{ width: '13.6rem', marginTop: '1.6rem' }}
					/>
					<S.TableContentContainer>
						<InformationTable
							items={employeeInsuranceInfo}
							rowStyle={{ border: 'none' }}
							tableStyle={{ border: 'none' }}
						/>
					</S.TableContentContainer>
				</S.LabelValueContainer>
				<CRInputLabel label={<CRText typography='body' text='취득 신고' />} type='left'>
					<Controller
						control={control}
						name='insuranceRequestYn'
						render={({ field: { ...rest } }) => (
							<CRCheckBox
								checkBoxType='checkbox'
								appearanceType='button'
								checked={rest.value}
								onChange={rest.onChange}>
								<CRText>신고 완료</CRText>
							</CRCheckBox>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>

			<CRCardFormLayout label='4대보험 이력'>
				<S.InsuranceTableContainer>
					{employeeInsuranceData && employeeInsuranceData.length > 0 ? (
						<CRTable.Root style={{ tableLayout: 'auto', overflow: 'scroll' }}>
							<CRTable.MultiHead heads={INSURANCE_TABLE_HEADER_CONFIG} />
							<CRTable.Body>
								{employeeInsuranceData.map((item) => (
									<CRTable.Row
										key={item.insuranceRequestId}
										style={{ borderLeft: '1rem solid black', borderRight: '1rem solid black' }}
										item={item}
										customRender={{
											insuranceRequestState: renderInsuranceRequestState,
											insuranceRequestType: renderInsuranceRequestType,
											pensionInsuranceRequestAmt: renderPriceNumber('pensionInsuranceApproveYn'),
											pensionInsuranceRequestDate: renderDate('pensionInsuranceApproveYn'),
											healthInsuranceRequestAmt: renderPriceNumber('healthInsuranceApproveYn'),
											healthInsuranceRequestDate: renderDate('healthInsuranceApproveYn'),
											employInsuranceRequestAmt: renderPriceNumber('employInsuranceApproveYn'),
											employInsuranceRequestDate: renderDate('employInsuranceApproveYn'),
											accidentInsuranceRequestAmt: renderPriceNumber('accidentInsuranceApproveYn'),
											accidentInsuranceRequestDate: renderDate('accidentInsuranceApproveYn'),
											requestDate: renderFullDate,
										}}
										renderKeys={[
											'insuranceRequestState',
											'insuranceRequestType',
											'pensionInsuranceRequestAmt',
											'pensionInsuranceRequestDate',
											'healthInsuranceRequestAmt',
											'healthInsuranceRequestDate',
											'employInsuranceRequestAmt',
											'employInsuranceRequestDate',
											'accidentInsuranceRequestAmt',
											'accidentInsuranceRequestDate',
											'requestDate',
										]}
										customStyle={{
											insuranceRequestState: {
												borderLeft: `0.1rem solid ${Colors.gray90}`,
											},
											requestDate: {
												borderRight: `0.1rem solid ${Colors.gray90}`,
											},
										}}
									/>
								))}
							</CRTable.Body>
						</CRTable.Root>
					) : (
						<S.EmptyContainer>
							<CRText typography='label' color='gray60' text='등록된 요청이 없습니다.' />
						</S.EmptyContainer>
					)}
				</S.InsuranceTableContainer>
				<CRButton.IconButton
					size='default'
					type='outlined'
					palette='gray'
					style={{ width: '13.2rem' }}
					iconLeft={Assets.icon.add}
					onClick={handleClickAddEmployeeInsurance}>
					4대보험 신고
				</CRButton.IconButton>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default EmployeeInsuranceInfo;
