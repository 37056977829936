import { css, styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 1.6rem 0;
	position: relative;
`;

export const SectionContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	margin-bottom: 0.8rem;
`;

export const SectionTitleContainer = styled.div`
	flex: 1;
`;

export const Form = styled.form`
	display: flex;
	flex-direction: column;
	flex-direction: column;
	gap: 0.8rem;
`;

export const Label = styled.div`
	${(props) => props.theme.typography.labelB};
	color: ${(props) => props.theme.colors.gray00};
	display: flex;
	justify-content: space-between;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
`;

export const Table = styled.table`
	width: 100%;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const TableRow = styled.tr<{ $type?: string }>`
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};

	&:last-child {
		border-bottom: 0;
	}

	${(props) =>
		props.$type === 'DELETE' &&
		css`
			background: ${props.theme.colors.redLighten};
		`}

	${(props) =>
		props.$type === 'UPDATE' &&
		css`
			background: ${props.theme.colors.yellowLighten};
		`}
`;

export const TableLabelColumn = styled.td`
	${(props) => props.theme.typography.label};
	color: ${(props) => props.theme.colors.gray60};
	height: 3.4rem;
	padding: 0.4rem 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	background: ${(props) => props.theme.colors.gray99};
	vertical-align: middle;
	word-break: keep-all;
`;

export const TableValueColumnBase = styled.td<{ $textAlign?: 'left' | 'right' }>`
	${(props) => props.theme.typography.label};
	text-align: ${(p) => p.$textAlign || 'right'};
	position: relative;
	box-sizing: border-box;
	height: 3.4rem;
	padding: 0.4rem 0.8rem;
	box-sizing: border-box;
	border-right: 0.1rem solid ${(props) => props.theme.colors.gray90};
	vertical-align: middle;
`;

export const TableValueColumn = styled(TableValueColumnBase)<{ $textAlign?: 'left' | 'right' }>`
	* {
		${(props) => props.theme.typography.label};
	}
`;

export const RightButtonContainer = styled.div`
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
`;

export const OptionContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;
