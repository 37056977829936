import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;

export const YearMonthPickerContainer = styled.div`
	margin-bottom: 2rem;
`;

export const TaskCategorySelectorContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;
	margin: 2.4rem 2.4rem 0;
`;

export const Divider = styled.div`
	width: 0.1rem;
	height: 1.6rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const EmptyContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	flex: 1;
	height: 100%;
`;

export const EmptyTitle = styled.h3`
	${(props) => props.theme.typography.h3};
	color: ${(props) => props.theme.colors.gray60};
`;

export const EmptyDescription = styled.p`
	${(props) => props.theme.typography.body};
	text-align: center;
	color: ${(props) => props.theme.colors.gray60};
`;

export const SelectionContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;
