import useCRMutation from 'lib/hook/react-query/base/useCRMutation';
import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import { endpoint } from 'lib/service/Api/endpoint';

export const useMonthlyBurdenCharges = useCRQuery(
	endpoint.getMonthlyBurdenCharges.key,
	'getMonthlyBurdenCharges',
	{
		enabledKey: ['centerId'],
	},
);

export const useMonthlyBurdenChargesMutation = useCRMutation(
	endpoint.getMonthlyBurdenCharges.key,
	'getMonthlyBurdenCharges',
);

export const useMonthlyBurdenChargeDetail = useCRQuery(
	endpoint.getMonthlyBurdenChargeDetail.key,
	'getMonthlyBurdenChargeDetail',
	{
		enabledKey: ['burdenAmtChargeId'],
	},
);

export const useMonthlyBurdenChargeDetailMutation = useCRMutation(
	endpoint.getMonthlyBurdenChargeDetail.key,
	'getMonthlyBurdenChargeDetail',
);

export const useMonthlyBurdenChargeSummary = useCRQuery(
	endpoint.getMonthlyBurdenChargesSummary.key,
	'getMonthlyBurdenChargesSummary',
	{
		enabledKey: ['centerId'],
	},
);

export const useBurdenPayHistories = useCRQuery(
	endpoint.getBurdenPayHistories.key,
	'getBurdenPayHistories',
	{
		enabledKey: ['centerId'],
	},
);

export const useBurdenPayHistoryDetail = useCRQuery(
	endpoint.getBurdenPayHistoryDetail.key,
	'getBurdenPayHistoryDetail',
	{
		enabledKey: ['centerId', 'recipientId', 'targetYear'],
	},
);

export const useBurdenChargesCorrections = useCRQuery(
	endpoint.getBurdenChargesCorrections.key,
	'getBurdenChargesCorrections',
	{
		enabledKey: ['centerId', 'recipientId'],
	},
);

export const useBurdenChargesDetail = useCRQuery(
	endpoint.getBurdenChargesDetail.key,
	'getBurdenChargesDetail',
	{
		enabledKey: ['centerId', 'recipientId', 'burdenAmtChargeYm', 'serviceTypeCd'],
	},
);
