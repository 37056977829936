import { CheckOption } from 'components/base/Selections/type';

export interface RecipientContractReviewTaskType {
	value: string;
	label: string;
	filterKey: string[];
	sideModalActiveKey: string;
	statusSelectionItem?: Array<CheckOption<CheckOption[]>>;
	tableRenderKeys: RecipientContractManagemetTableRenderKey[];
}

export const CONTRACT_REVIEW_RECIPIENT_TASK_CONFIG: Array<RecipientContractReviewTaskType> = [
	{
		value: 'all',
		label: '전체',
		filterKey: [],
		sideModalActiveKey: '급여계약 검수',
		tableRenderKeys: [
			'recipientContractCompletionStatus',
			'integratedAssessmentCompletionStatus',
			'employeeChangeConsultationWritingStatus',
			'admissionApplicationFormCompletionStatus',
			'registeredLongtermCareBenefitScheduleToCorporation',
			'appliedIndividualLongtermCarePlan',
			'tagCompletionStatus',
		],
	},
	{
		value: 'salary_contract_review',
		label: '급여계약 검수',
		sideModalActiveKey: '급여계약 검수',
		filterKey: [
			'recipientContractCompletionStatusFilter',
			'contractCompletionStatusFilter',
			'salaryPlanCompletionStatusFilter',
			'integratedAssessmentCompletionStatusFilter',
		],
		statusSelectionItem: [
			{
				label: '급여제공계획서 종합의견 검수',
				value: 'salaryPlanCompleteCd',
				data: [
					{ label: '감수대기', value: 'CMN211.10' },
					{ label: '수정필요', value: 'CMN211.20' },
					{ label: '검수완료', value: 'CMN211.30' },
				],
			},
			{
				label: '급여제공계획서 공단 통보',
				value: 'notiToPcorpYn',
				data: [
					{ label: '완료', value: true },
					{ label: '미통보', value: false },
				],
			},
			{
				label: '급여제공변경사유서 작성 여부',
				value: 'salaryChangeStatCd',
				data: [
					{ label: '확인필요', value: 'CMN214.10' },
					{ label: '해당없음', value: 'CMN214.20' },
					{ label: '작성필요', value: 'CMN214.30' },
					{ label: '작성완료', value: 'CMN214.40' },
				],
			},
		],
		tableRenderKeys: [
			'recipientContractCompletionStatus',
			'recipientContractSendStatus',
			'recipientContractDocumentVerification',
			// 급여제공 계획서
			'longtermCareBenefitPlanCompletionStatus',
			'longtermCareBenefitPlanDocumentVerification',
			'longtermCareBenefitPlanDocumnetSendStatus',
			'longtermCareBenefitPlanOverallOpinionReview',
			'longtermCareBenefitPlanNotificationToCorporation',
			'longtermCareBenefitPlanReasonForChange',
			// 통합사정
			'integratedAssessmentCompletionStatus',
			'integratedAssessmentWritingStatus',
			'fallRiskAssessmentCompletionStatus',
			'pressureInjuryRiskAssessmentCompletionStatus',
			'cognitiveFunctionAssessmentCompletionStatus',
			'needsAssessmentCompletionStatus',
		],
	},
	{
		value: 'employee_change_review',
		label: '직원 변경 검수',
		sideModalActiveKey: '직원 변경 검수',
		filterKey: ['employeeReplacementConsultationStatusFilter'],
		statusSelectionItem: [
			{
				label: '직원교체상담일지 작성 여부',
				value: 'employeeReplacementCounselingCd',
				data: [
					{ label: '확인필요', value: 'CMN214.10' },
					{ label: '해당없음', value: 'CMN214.20' },
					{ label: '작성필요', value: 'CMN214.30' },
					{ label: '작성완료', value: 'CMN214.40' },
				],
			},
		],
		tableRenderKeys: ['employeeChangeConsultationWritingStatus'],
	},
	{
		value: 'admission_application',
		label: '입소이용신청',
		sideModalActiveKey: '입소이용신청',
		filterKey: ['admissionApplicationStatusFilter', 'admissionApplicationTargetFilter'],
		statusSelectionItem: [
			{
				label: '입소이용신청서 지자체 발송/승인',
				value: 'admissionDispatchCd',
				data: [
					{ label: '미발송', value: 'CMN212.10' },
					{ label: '발송완료', value: 'CMN212.20' },
					{ label: '승인거부', value: 'CMN212.30' },
					{ label: '승인', value: 'CMN212.40' },
				],
			},
		],
		tableRenderKeys: [
			'admissionApplicationFormCompletionStatus',
			'isTargetOfadmissionApplication',
			'isCompletedAdmissionApplicationForm',
			'admissionApplicationFormSendStatus',
			'admissionApplicationSendApprovalStatus',
		],
	},
	{
		value: 'schedule_registration',
		label: '일정 등록',
		sideModalActiveKey: '일정 등록',
		filterKey: ['publicScheduleRegistrationStatusFilter'],
		tableRenderKeys: ['registeredLongtermCareBenefitScheduleToCorporation'],
	},
	{
		value: 'corporation_registration',
		label: '공단 등록',
		sideModalActiveKey: '공단 등록',
		filterKey: ['individualCarePlanCompletionStatusFilter', 'tagCompletionStatusFilter'],
		statusSelectionItem: [
			{
				label: '개인별이용계획서 열람신청',
				value: 'longtermPlanRequestYn',
				data: [
					{ label: '미신청', value: false },
					{ label: '신청완료', value: true },
				],
			},
			{
				label: '태그 신청/부착',
				value: 'tagRequestTaskCd',
				data: [
					{ label: '미신청', value: 'CMN215.10' },
					{ label: '신청완료', value: 'CMN215.20' },
					{ label: '수령가능', value: 'CMN215.30' },
					{ label: '부착완료', value: 'CMN215.40' },
				],
			},
		],
		tableRenderKeys: [
			'appliedIndividualLongtermCarePlan',
			'tagCompletionStatus',
			'tagSocialWorkerRequest',
			'tagAttachmentClassification',
			'tagAttachmentStatus',
		],
	},
];

export interface EmployeeContractReviewTaskType {
	value: string;
	label: string;
	filterKey: string[];
	sideModalDefaultActiveKey: string;
	statusSelectionItem?: Array<CheckOption<CheckOption[]>>;
	tableRenderKeys: EmployeeContractManagemetTableRenderKey[];
}

export const EMPLOYEE_CONTRACT_REVIEW_TASK_CONFIG: Array<EmployeeContractReviewTaskType> = [
	{
		value: 'all',
		label: '전체',
		sideModalDefaultActiveKey: '범죄경력조회',
		filterKey: [],
		tableRenderKeys: [
			'isCrimeCheckCompleted',
			'employeeContractCompletionStatus',
			'employeeReportState',
			'isInsuranceEnrollmentReportCompleted',
			'isLiabilityInsuranceRegistrationCompleted',
			'deviceRegistrationRegistrationStatus',
		],
	},
	{
		value: 'criminal_record_check',
		label: '범죄경력조회',
		sideModalDefaultActiveKey: '범죄경력조회',
		filterKey: ['criminalRecordFilter'],
		statusSelectionItem: [
			{
				label: '범죄경력조회 등록 유형',
				value: 'criminalRecordRegistrationYn',
				data: [
					{ label: '시스템', value: false },
					{ label: '수기', value: true },
				],
			},
		],
		tableRenderKeys: [
			'isCrimeCheckCompleted',
			'hasCrimeReportFile',
			'hasElderAbuseReportFile',
			'hasDisabilityAbuseReportFile',
			'isManuallyRegistered',
		],
	},
	{
		value: 'employment_contract',
		label: '근로계약서',
		sideModalDefaultActiveKey: '근로계약서',
		filterKey: ['employeeContractCompleteFilter'],
		statusSelectionItem: [
			{
				label: '근로계약서 검수',
				value: 'employeeContractCompleteCd',
				data: [
					{ label: '미완료', value: 'CMN218.10' },
					{ label: '수정필요', value: 'CMN218.20' },
					{ label: '완료', value: 'CMN218.30' },
				],
			},
		],
		tableRenderKeys: [
			'employeeContractCompletionStatus',
			'employeeContractSendingStatus',
			'isEmployeeContractValid',
			'employeeContractHourlyWage',
			'employeeContractMonthlyWorkHours',
			'isContractWorkHoursAlignedWithPolicy',
		],
	},
	{
		value: 'personnel_report',
		label: '인력신고',
		sideModalDefaultActiveKey: '인력신고',
		filterKey: ['employeeReportFilter'],
		tableRenderKeys: ['employeeReportState'],
	},
	{
		value: 'insurance',
		label: '4대보험',
		sideModalDefaultActiveKey: '4대보험',
		filterKey: ['insuranceFilter'],
		tableRenderKeys: [
			'employeeContractCompletionStatus',
			'employeeContractHourlyWage',
			'employeeContractMonthlyWorkHours',
			'isInsuranceEnrollmentReportCompleted',
			'weeklyContractedHours',
			'reportedInsuranceWage',
			'insuranceEnrollmentDate',
			'requiresNationalPensionService',
			'requiresNationalHealthInsurance',
			'requiresEmploymentInsurance',
			'requiresEmployeeCompensationInsurance',
		],
	},
	{
		value: 'corporation_registration',
		label: '공단 등록',
		sideModalDefaultActiveKey: '공단 등록',
		filterKey: ['liabilityInsuranceFilter', 'pcorpTagRegistrationFilter'],
		statusSelectionItem: [
			{
				label: '배상책임보험 가입 여부',
				value: 'liabilityInsuranceSubscriptionYn',
				data: [
					{ label: '미가입', value: false },
					{ label: '가입 완료', value: true },
				],
			},
			{
				label: '단말기 등록 여부',
				value: 'pcorpTagRegistrationCd',
				data: [
					{ label: '미완료', value: 'CMN217.10' },
					{ label: '타명의 등록완료', value: 'CMN217.20' },
					{ label: '등록완료', value: 'CMN217.30' },
				],
			},
		],
		tableRenderKeys: [
			'isLiabilityInsuranceRegistrationCompleted',
			'isLiabilityInsuranceRequired',
			'isLiabilityInsuranceEnrolled',
			'deviceRegistrationRegistrationStatus',
		],
	},
];

export type EmployeeContractManagemetTableRenderKey =
	| 'checked'
	// 기본 정보
	| 'totalWorkCompletionStatus'
	| 'center'
	| 'socialWorker'
	| 'employeeName'
	| 'employeeBirthDate'
	| 'isNewEmployee'
	| 'recipientContractDate'
	| 'recipientName'
	| 'longTermCareNumber'
	| 'isFamily'
	// 근로자 급여별 정보
	// 급여 계약 정보
	| 'longtermCareBenefitType'
	| 'longtermCareStartDate'
	| 'longtermCareEndDate'
	| 'employeeContractDate'
	// 근로 계약서
	| 'employeeContractSendingStatus'
	| 'isEmployeeContractValid'
	| 'employeeContractHourlyWage'
	| 'employeeContractMonthlyWorkHours'
	| 'isContractWorkHoursAlignedWithPolicy'
	// 범죄경력조회
	| 'isCrimeCheckCompleted'
	| 'hasCrimeReportFile'
	| 'hasElderAbuseReportFile'
	| 'hasDisabilityAbuseReportFile'
	| 'isManuallyRegistered'
	// 근로 계약서 업무
	| 'employeeContractCompletionStatus'
	// 인력신고
	| 'employeeReportState'
	// 4대보험
	| 'isInsuranceEnrollmentReportCompleted'
	| 'weeklyContractedHours'
	| 'reportedInsuranceWage'
	| 'insuranceEnrollmentDate'
	| 'requiresNationalPensionService'
	| 'requiresNationalHealthInsurance'
	| 'requiresEmploymentInsurance'
	| 'requiresEmployeeCompensationInsurance'
	// 배상책임보험
	| 'isLiabilityInsuranceRegistrationCompleted'
	| 'isLiabilityInsuranceRequired'
	| 'isLiabilityInsuranceEnrolled'
	// 단말기 등록
	| 'deviceRegistrationRegistrationStatus'
	// 메모
	| 'referenceContractNotes'
	| 'adminMemo';

export type RecipientContractManagemetTableRenderKey =
	| 'checked'
	| 'totalWorkCompletionStatus'
	// 기본정보
	| 'center'
	| 'socialWorker'
	| 'recipientName'
	| 'longTermCareNumber'
	| 'contractType'
	| 'contractDate'
	// 급여별 정보
	| 'longtermCareBenefitType'
	| 'longtermCareStartDate'
	| 'longtermCareEndDate'
	| 'employeeName'
	| 'longtermCareBenefitPlanDocumentVerification'
	| 'longtermCareBenefitPlanDocumnetSendStatus'
	// 급여별 통합사정
	| 'integratedAssessmentWritingStatus'
	| 'fallRiskAssessmentCompletionStatus'
	| 'pressureInjuryRiskAssessmentCompletionStatus'
	| 'cognitiveFunctionAssessmentCompletionStatus'
	| 'needsAssessmentCompletionStatus'
	// 수급자 계약
	| 'recipientContractCompletionStatus'
	| 'recipientContractSendStatus'
	| 'recipientContractDocumentVerification'
	// 급여제공계획서
	| 'longtermCareBenefitPlanCompletionStatus'
	| 'longtermCareBenefitPlanOverallOpinionReview'
	| 'longtermCareBenefitPlanNotificationToCorporation'
	// 급여 변경 사유서
	| 'longtermCareBenefitPlanReasonForChange'
	// 통합사정
	| 'integratedAssessmentCompletionStatus'
	// 요양보호사 교체 상담일지
	| 'employeeChangeConsultationWritingStatus'
	// 입소 이용 신청서
	| 'admissionApplicationFormCompletionStatus'
	| 'isTargetOfadmissionApplication'
	| 'isCompletedAdmissionApplicationForm'
	| 'admissionApplicationFormSendStatus'
	| 'admissionApplicationSendApprovalStatus'
	// 공단 일정 등록
	| 'registeredLongtermCareBenefitScheduleToCorporation'
	// 개인별 장기요양이용계획서 열람신청
	| 'appliedIndividualLongtermCarePlan'
	// 태그
	| 'tagCompletionStatus'
	| 'tagSocialWorkerRequest'
	| 'tagAttachmentClassification'
	| 'tagAttachmentStatus'
	// 메모
	| 'referenceContractNotes'
	| 'adminMemo';
