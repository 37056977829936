import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { RecipientContractReviewFormFields } from 'components/domain/sideModal/RecipientContractReviewSideModal/types';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';

import { COMPLETION_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: RecepientContractReviewListViewType;
}

function RecipientEmployeeChangeInfo({ data }: Props): React.ReactElement {
	const { control } = useFormContext<RecipientContractReviewFormFields>();
	const { data: commonCodes = { CMN214: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN214'] },
		commonCodeAdapter,
	);

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						직원 교체상담일지
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.tagCompletionStatus ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<CRInputLabel label='작성 여부' type='left' isRequired>
					<Controller
						control={control}
						name='employeeReplacementCounselingOption'
						render={({ field: { value, onChange } }) => (
							<CRInput.Selector
								currentValue={value}
								onChangeValue={onChange}
								items={commonCodes.CMN214}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default RecipientEmployeeChangeInfo;
