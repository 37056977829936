import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { v4 } from 'uuid';

import Assets from 'assets';
import CRBanner from 'components/base/CRBanner';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRSpinner from 'components/base/CRSpinner';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import { CenterAccountRecordFormData } from 'components/domain/form/CenterAccountRecordDetailForm';
import CenterAccountRecordDetailFormNew from 'components/domain/form/CenterAccountRecordDetailFormNew';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import { displayComma, displayCommaToNumber, removeFalsyValues } from 'lib';
import { useMyAccountInfo } from 'lib/hook/react-query';
import { useUpdateCenterAccountRecordDetail } from 'lib/hook/react-query/mutation/centerAccount';
import { useCenterAccountRecordTargets } from 'lib/hook/react-query/query/centerAccount';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { ResponseCode } from 'types/api';
import {
	CenterAccountRecordData,
	CenterAccountRecordDetail,
	GetCenterAccountRecordTargetData,
} from 'types/api/centerAccount';
import { PageResponse } from 'types/api/common';

import * as S from './styles';

interface Props {
	accountDetail: CenterAccountRecordData;
	onClose: () => void;
}

const defaultRecordDetail = {
	centerAccountRecordDetailId: undefined,
	centerAccountRecordId: undefined,
	centerId: undefined,
	recordTargetDivCd: undefined,
	recordTargetId: undefined,
	recordTargetNm: undefined,
	recordTypeValue: undefined,
	recordTypeCd: undefined,
	recordTypeNm: undefined,
	recordDetailCd: undefined,
	recordDetailNm: undefined,
	recordDesc: undefined,
	depositAmt: undefined,
	withdrawAmt: undefined,
	centerAccountAliasNm: undefined,
	recordDt: undefined,
	recordTime: undefined,
	recordKindNm: undefined,
	balanceAmt: undefined,
	record: undefined,
	rowType: undefined,
	recordDate: undefined,
};

const getDefaultCenterAccountRecordDetails = (accountDetail: CenterAccountRecordData) =>
	accountDetail.centerAccountRecordDetails?.length
		? accountDetail.centerAccountRecordDetails.map((val) => ({
				...val,
				...(val?.recordTypeNm && val?.recordTypeCd
					? {
							recordTypeValue: {
								label: val.recordTypeNm,
								value: val.recordTypeCd,
							},
						}
					: {}),
				...(val?.recordDetailNm && val?.recordDetailCd
					? {
							recordDetailValue: {
								label: val.recordDetailNm,
								value: val.recordDetailCd,
							},
						}
					: {}),
				...(val?.recordTargetNm && val?.recordTargetId
					? {
							recordTargetDivValue: {
								label: val.recordTargetNm,
								name: val.recordTargetNm,
								value: { id: val.recordTargetId, cd: val.recordTargetDivCd },
							},
						}
					: {}),
				depositAmt: displayComma(val?.depositAmt),
				withdrawAmt: displayComma(val.withdrawAmt),
				tempId: v4(),
			}))
		: [
				{
					...defaultRecordDetail,
					tempId: v4(),
					centerAccountRecordId: accountDetail.centerAccountRecordId,
					centerId: accountDetail.centerId,
				},
			];

function CenterAccountDetailDialogNew({ accountDetail, onClose }: Props): React.ReactElement {
	const [selectedRecommendedRecipient, setSelectedRecommendedRecipient] = useState<
		GetCenterAccountRecordTargetData | undefined
	>(undefined);
	const formMethods = useForm<CenterAccountRecordFormData>({
		mode: 'onChange',
		defaultValues: {
			...accountDetail,
			centerAccountRecordDetails: getDefaultCenterAccountRecordDetails(accountDetail),
		},
	});

	const { data: accountInfo } = useMyAccountInfo();

	const { data: centerAccountRecordTargets, isLoading } = useCenterAccountRecordTargets({
		centerId: accountInfo?.centerId,
		recordTargetDivCds: 'CMN118.20',
	});

	const recommendedRecipient = centerAccountRecordTargets?.filter(
		(val) =>
			val.recordTargetNm.includes(accountDetail.record) ||
			val.recordTargetGuardianNm.includes(accountDetail.record),
	);

	const hasUpdateBankAccountDetailFunc = useHasFunc(['center:update_bank_account_detail']);

	const { getValues, control, handleSubmit } = formMethods;
	const updateCenterRecodeDetail = useUpdateCenterAccountRecordDetail();
	const client = useQueryClient();

	const fieldArray = useFieldArray({
		control,
		name: 'centerAccountRecordDetails',
	});
	const centerAccountRecordDetails = formMethods.watch('centerAccountRecordDetails');

	const isDeposit = accountDetail.recordKindNm === '입금';

	const detailInfoItem: InformationTableItemType[][] = [
		[
			{
				labelStyle: { width: '43rem' },
				label: '금액',
				value: displayComma(isDeposit ? getValues('depositAmt') : getValues('withdrawAmt')),
			},
		],
		[
			{
				label: '입금처/출금처',
				value: getValues('record'),
			},
		],
		[
			{
				label: '계좌명',
				value: getValues('centerAccountAliasNm'),
			},
		],
		[
			{
				label: '이체 일시',
				value: dayjs(getValues('recordDate')).format('YYYY.MM.DD HH:mm'),
			},
		],
	];

	const addRecordDetail = () => {
		fieldArray.append({
			...defaultRecordDetail,
			tempId: v4(),
			centerAccountRecordId: accountDetail.centerAccountRecordId,
			centerId: accountDetail.centerId,
		});
	};

	const isRecordDataValid = (formData: CenterAccountRecordFormData) =>
		formData.centerAccountRecordDetails.every((detail, idx) => {
			if (!detail?.recordTypeValue?.value) return false;
			const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010', 'CMN035.2150', 'CMN035.2160'];
			const isOnlyNeedRecordType = !renderAbleRecordType.includes(detail.recordTypeValue?.value);

			if (isOnlyNeedRecordType) return true;
			// return !!(detail?.recordDetailValue?.value && detail?.recordTargetDivValue?.value);
			return !!detail?.recordDetailValue?.value;
		});

	const isAmtValid = (formData: CenterAccountRecordFormData) =>
		formData.centerAccountRecordDetails.every(
			(detail, idx) =>
				Number(detail?.depositAmt?.replace(/,/g, '') || 0) +
					Number(detail?.withdrawAmt?.replace(/,/g, '') || 0) >
				0,
		);

	const onClickUpdateRecordDetail = async (formData: CenterAccountRecordFormData) => {
		if (updateCenterRecodeDetail.isLoading) return;
		if (!isRecordDataValid(formData)) {
			Toast.error('유형의 내용을 모두 입력해주세요.');
			return;
		}
		if (!isAmtValid(formData)) {
			Toast.error('상세 금액을 확인해주세요.');
			return;
		}
		const targetYmRecordType = ['CMN035.1000', 'CMN035.2010'];

		const formattedData = formData.centerAccountRecordDetails.map((detail) =>
			removeFalsyValues({
				...detail,
				centerAccountAliasNm: formData.centerAccountAliasNm,
				centerAccountRecordDetails: undefined,
				verifyStateValue: undefined,
				tempId: undefined,
				recordTypeValue: undefined,
				recordTypeNm: detail?.recordTypeValue?.label,
				recordTypeCd: detail?.recordTypeValue?.value,
				recordDetailValue: undefined,
				recordDetailNm: detail?.recordDetailValue?.label,
				recordDetailCd: detail?.recordDetailValue?.value,
				recordTargetDivValue: undefined,
				recordTargetNm: detail?.recordTargetDivValue?.label,
				recordTargetId: detail?.recordTargetDivValue?.value.id,
				recordTargetDivCd: detail?.recordTargetDivValue?.value?.cd,
				depositAmt: Number(detail?.depositAmt?.replace(/,/g, '') || 0),
				withdrawAmt: Number(detail?.withdrawAmt?.replace(/,/g, '') || 0),
				recordTargetYm:
					targetYmRecordType.includes(detail.recordTypeValue?.value) && detail.recordTargetYm
						? dayjs(detail.recordTargetYm).format('YYYYMM')
						: '',
			}),
		);
		const res = await updateCenterRecodeDetail.mutateAsync(
			formattedData as CenterAccountRecordDetail[],
		);
		if (res?.code !== ResponseCode.SUCCESS) {
			Toast.success('저장을 실패했습니다.');
			return;
		}
		Toast.success('정상적으로 내용이 저장 되었습니다.');
		client.setQueriesData(
			['getCenterAccountRecords'],
			(data?: PageResponse<CenterAccountRecordData[]>) => {
				if (!data?.content?.map) return data;
				const test = {
					...data,
					content: data?.content?.map((content) => {
						if (content.centerAccountRecordId !== res.data?.centerAccountRecordId) return content;
						return res.data;
					}),
				};
				return test;
			},
		);
		onClose();
	};

	const totalAmt = centerAccountRecordDetails?.reduce((acc, cur) => {
		if (cur.rowType === 'DELETE') return acc;
		return acc + (displayCommaToNumber(cur.depositAmt) + displayCommaToNumber(cur.withdrawAmt));
	}, 0);

	const targetAmt = isDeposit ? getValues('depositAmt') : getValues('withdrawAmt');

	const isSubmittable = useMemo(() => {
		const hasValue = totalAmt === targetAmt || totalAmt === 0;
		const renderAbleRecordType = ['CMN035.1000', 'CMN035.2010', 'CMN035.2150', 'CMN035.2160'];
		const renderDateRecordType = ['CMN035.1000', 'CMN035.2010'];

		const hasDivValue = getValues('centerAccountRecordDetails').every((item) => {
			if (!renderAbleRecordType.includes(item.recordTypeValue?.value)) return true;
			const validRecordTargetYm = renderDateRecordType.includes(item.recordTypeValue?.value)
				? item.recordTargetYm
				: true;

			return item.recordDetailValue?.value && validRecordTargetYm;
		});

		return hasValue && hasDivValue && hasUpdateBankAccountDetailFunc;
	}, [
		totalAmt,
		targetAmt,
		isDeposit,
		getValues('centerAccountRecordDetails'),
		getValues('depositAmt'),
		getValues('withdrawAmt'),
		hasUpdateBankAccountDetailFunc,
	]);

	const recipientOptions = useMemo(
		() =>
			recommendedRecipient?.map((val) => ({
				label: val.recordTargetNm,
				value: val.recordTargetId,
				data: val,
			})),
		[recommendedRecipient],
	);

	const renderRecommendedRecipient = (item: CheckOption) => {
		const data = recipientOptions?.find((val) => val.value === item.value)?.data;
		return (
			<CRText
				text={`${data?.recordTargetNm}(${data?.recordTargetBirthDt})・${data?.recordTargetGuardianNm}`}
			/>
		);
	};

	useEffect(() => {
		if (recommendedRecipient?.length === 1) {
			setSelectedRecommendedRecipient(recommendedRecipient[0]);
		}
	}, [recommendedRecipient]);

	return (
		<CRDialog
			type='L'
			title='계좌 내역 상세'
			onClickClose={onClose}
			body={
				<FormProvider {...formMethods}>
					<S.Container>
						<S.ContentContainer>
							<CRInputLabel label='거래정보'>
								<InformationTable items={detailInfoItem} />
							</CRInputLabel>
							<CRInputLabel label='추천 수급자'>
								{isLoading && (
									<CRBanner
										type='error'
										content={<CRSpinner floatMode={false} />}
										showTypeIcon={false}
									/>
								)}
								{!isLoading &&
									(recommendedRecipient?.length ? (
										<CRInput.Selector
											currentValue={
												selectedRecommendedRecipient
													? { label: '', value: selectedRecommendedRecipient.recordTargetId }
													: undefined
											}
											renderItem={renderRecommendedRecipient}
											renderOptionItem={renderRecommendedRecipient}
											onChangeValue={(e) => {
												setSelectedRecommendedRecipient(e.data as GetCenterAccountRecordTargetData);
											}}
											placeholder='수급자를 선택해주세요.'
											items={recipientOptions}
										/>
									) : (
										<CRBanner type='error' title='추천 수급자가 없습니다.' />
									))}
							</CRInputLabel>
						</S.ContentContainer>
						<S.ScrollContainer>
							<CenterAccountRecordDetailFormNew
								fieldArray={fieldArray}
								recipients={centerAccountRecordTargets ?? []}
								selectedRecommendedRecipient={selectedRecommendedRecipient}
								lastAmt={Number(targetAmt - totalAmt)}
							/>
						</S.ScrollContainer>
					</S.Container>
					<S.TotalPriceContainer>
						<CRText text='현재 금액' />
						<FlexContainer gap='0.4rem'>
							<CRText typography='bodyB' text={`${displayComma(totalAmt)}`} />
							<CRText text='원' color='gray60' />
						</FlexContainer>
						<CRText text='차액' />
						<FlexContainer gap='0.4rem'>
							<CRText typography='bodyB' text={`${displayComma(targetAmt - totalAmt)}`} />
							<CRText text='원' color='gray60' />
						</FlexContainer>
						<CRText text='총 금액' />
						<FlexContainer gap='0.4rem'>
							<CRText typography='bodyB' text={`${displayComma(targetAmt)}`} />
							<CRText text='원' color='gray60' />
						</FlexContainer>
					</S.TotalPriceContainer>
				</FormProvider>
			}
			footer={
				<FlexContainer justify='space-between' width='100%'>
					<CRButton.IconButton
						iconLeft={Assets.icon.add}
						disabled={!hasUpdateBankAccountDetailFunc}
						palette='gray'
						type='outlined'
						onClick={addRecordDetail}>
						추가
					</CRButton.IconButton>
					<FlexContainer gap='0.8rem'>
						<CRButton.Default type='text' palette='gray' onClick={onClose}>
							취소
						</CRButton.Default>
						<CRButton.Default
							disabled={!isSubmittable}
							onClick={handleSubmit(onClickUpdateRecordDetail)}>
							저장
						</CRButton.Default>
					</FlexContainer>
				</FlexContainer>
			}
		/>
	);
}

export default CenterAccountDetailDialogNew;
