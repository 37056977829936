import React, { useEffect, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CheckOption } from 'components/base/Selections/type';
import InformationSheet from 'components/ui/InformationSheet';
import { useEDocIssueInfoBefore } from 'lib/hook/react-query/query/edoc';
import useDialog from 'lib/hook/util/useDialog';
import { EDocResignDTO } from 'types/api/eDoc';
import { EmployeeDTO } from 'types/api/employee';
import { ResignFormTypes } from 'types/view/eDoc';

import * as S from './styles';

interface Props {
	templateCode: string;
	currentEmployee?: EmployeeDTO;
	formContext: UseFormReturn<ResignFormTypes, any, undefined>;
}

// 사직서 + 퇴직급여지급연장동의서
export default function EDocResignAndSeverancePaymentAgreementForm({
	templateCode,
	currentEmployee,
	formContext,
}: Props): React.ReactElement {
	const { showDialog } = useDialog();
	const { data: eDocBeforeIssueInfo } = useEDocIssueInfoBefore({
		employeeId: currentEmployee?.employeeId,
		centerId: currentEmployee?.centerId,
		templateCode,
	});
	const resignType = formContext.watch('resignType');

	const exitOptions = useMemo(
		() =>
			((eDocBeforeIssueInfo as unknown as EDocResignDTO)?.data.exitCds ?? []).map(
				(item) =>
					({
						label: item.korComCdNm,
						value: item.comCdId,
					}) as CheckOption,
			),
		[eDocBeforeIssueInfo],
	);

	const exitDetailOptions = useMemo(
		() =>
			((eDocBeforeIssueInfo as unknown as EDocResignDTO)?.data.exitDetailCds ?? [])
				.filter((cds) => {
					if (!resignType) return false;
					return cds.etcDesc1 === resignType.value;
				})
				.map(
					(item) =>
						({
							label: item.korComCdNm,
							value: item.comCdId,
						}) as CheckOption,
				),
		[eDocBeforeIssueInfo, resignType],
	);

	const workStartDate = formContext.watch('workStartDate');
	const resignDate = formContext.watch('resignDate');

	const workStartEndDate = workStartDate
		? `${dayjs(workStartDate).format('YYYY-MM-DD')} ~ ${dayjs(
				formContext.watch('resignDate'),
			).format('YYYY-MM-DD')}`
		: '-';
	const totalWorkDate: string = useMemo(() => {
		const diffTotalMonth = dayjs(resignDate)?.diff(dayjs(workStartDate), 'month');

		if (diffTotalMonth > 11) {
			return `총 ${Math.floor(diffTotalMonth / 12)}년 ${Math.floor(diffTotalMonth % 12)}개월 근무`;
		}
		return `총 ${Math.floor(diffTotalMonth % 12)}개월 근무`;
	}, [workStartDate, resignDate]);

	const handleClickResignDate = async (): Promise<boolean> =>
		new Promise((resolve) => {
			showDialog(({ hideDialog }) => (
				<CRDialog
					title='퇴사 희망일 작성 기준'
					body={
						<S.DialogBodyContainer>
							{`1. '퇴직 의사를 밝힌 날', '마지막 근무일 다음날' 중 더 나중 일자를 입력해주세요. (장기 보류 후 퇴사하는 근로자의 경우, 반드시 '퇴직 의사를 밝힌 날'을 입력해주세요!)\n2. 조건부 계약종료(수급자 사망, 수급자 계약종료 등)의 경우, 사유 발생일 다음날을 입력해주세요.`}
						</S.DialogBodyContainer>
					}
					onClickClose={() => {
						resolve(true);
						hideDialog();
					}}
					footer={
						<S.ButtonContainer>
							<CRButton.Default
								palette='primary'
								onClick={() => {
									resolve(true);
									hideDialog();
								}}>
								확인
							</CRButton.Default>
						</S.ButtonContainer>
					}
				/>
			));
		});

	useEffect(() => {
		if (eDocBeforeIssueInfo) {
			formContext.setValue('data', eDocBeforeIssueInfo);
			const accidentDate = (eDocBeforeIssueInfo as unknown as EDocResignDTO).data.employee
				.accidentInsuranceAcqDate;
			if (accidentDate) {
				formContext.setValue('workStartDate', dayjs(accidentDate).toDate());
			}
		}
	}, [eDocBeforeIssueInfo]);

	useEffect(() => {
		formContext.setValue('resignDate', new Date());
	}, []);

	useEffect(() => {
		if (formContext.watch('resignType')?.value !== 'CMN109.99') {
			formContext.setValue('resignTypeEtc', '');
		}
	}, [formContext.watch('resignType')]);

	return (
		<S.Container>
			<S.SubFormContainer>
				<CRInputLabel label='신청인' isRequired>
					<CRInput.Selector
						autoComplete
						disabled
						currentValue={{
							label: currentEmployee?.korMemberNm || '',
							value: currentEmployee?.employeeId,
						}}
						items={[
							{
								label: currentEmployee?.korMemberNm || '',
								value: currentEmployee?.employeeId,
							},
						]}
					/>
				</CRInputLabel>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='입사일' isRequired>
							<CRInput.DatePicker
								value={value}
								onChangeValue={onChange}
								placeholder='입사일 선택'
							/>
						</CRInputLabel>
					)}
					name='workStartDate'
					control={formContext.control}
				/>
				<Controller
					render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
						<CRInputLabel label='퇴사일' isRequired>
							<S.ResignDateContainer>
								<CRInput.DatePicker
									value={value}
									placeholder='퇴사일 선택'
									onChangeValue={onChange}
									onClick={handleClickResignDate}
								/>
								<InformationSheet
									items={[
										[
											{
												label: '근무기간',
												value: workStartEndDate,
												labelWidth: '12rem',
											},
										],
										[
											{
												label: '근무 개월수',
												value: totalWorkDate,
												labelWidth: '12rem',
											},
										],
									]}
									type='gray'
								/>
							</S.ResignDateContainer>
						</CRInputLabel>
					)}
					name='resignDate'
					control={formContext.control}
				/>

				<CRInputLabel label='종료 유형' isRequired>
					<FlexContainer gap='0.8rem' direction='column'>
						<Controller
							render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
								<CRInput.Selector
									currentValue={value}
									items={exitOptions}
									onChangeValue={(e) => {
										onChange(e);
										formContext.resetField('resignDetailType');
									}}
									placeholder='종료 유형 선택'
								/>
							)}
							name='resignType'
							control={formContext.control}
						/>
						{exitDetailOptions.length > 0 && (
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInput.Selector
										currentValue={value}
										items={exitDetailOptions}
										onChangeValue={onChange}
										renderOptionItem={(val) => <S.SelectorItem>{val.label}</S.SelectorItem>}
										placeholder='상세 유형 선택'
									/>
								)}
								name='resignDetailType'
								control={formContext.control}
							/>
						)}
					</FlexContainer>
				</CRInputLabel>
				<CRInputLabel label='종료 사유' isRequired>
					<Controller
						render={({ field: { onChange, value }, formState: { errors } }) => (
							<CRInput.TextArea onChange={onChange} value={value} placeholder='종료 사유 입력' />
						)}
						name='reason'
						control={formContext.control}
					/>
				</CRInputLabel>
			</S.SubFormContainer>
		</S.Container>
	);
}
