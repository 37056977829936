import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Assets from 'assets';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRTableHeader from 'components/base/CRTableHeader';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import ContractWithdrawalDialog from 'components/domain/dialog/ContractWithdrawalDialog';
import RecipientContractAddDialog from 'components/domain/dialog/RecipientContractAddDialog';
import RecipientContractRenewDialog from 'components/domain/dialog/RecipientContractRenewDialog';
import RecipientEmployeeChangeDialog from 'components/domain/dialog/RecipientEmployeeChangeDialog';
import RecipientServiceTable from 'components/domain/table/RecipientServiceTable';
import { commonCodeAdapter } from 'lib/adapter/common';
import { recipientServicesAdapter } from 'lib/adapter/recipient';
import {
	useCommonCodes,
	useCreateChangeEmployeeContract,
	useCreateRenewalRecipientContract,
	useGetServiceEmployees,
	useMyAccountInfo,
	useRecipientContracts,
} from 'lib/hook/react-query';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { Filter } from 'types/view/filter';
import { RecipientService } from 'types/view/recipient';

import * as S from './styles';

function RecipientServiceTab(): React.ReactElement {
	const navigate = useNavigate();
	const params = useParams<{ id: string }>();
	const { showDialog, hideDialog } = useGlobalLayout();
	const { currentRecipient, currentRecipientInfoSummary } = useRecipientPage();
	const { data: myAccountInfo } = useMyAccountInfo();
	const createRenewalRecipientContract = useCreateRenewalRecipientContract();
	const createChangeEmployeeContract = useCreateChangeEmployeeContract();
	const getServiceEmployees = useGetServiceEmployees();

	const [currentFilter, setCurrentFilter] = useState<{
		statusFilter: CheckOption[];
		serviceFilter: CheckOption[];
	}>({
		statusFilter: [],
		serviceFilter: [],
	});

	const {
		data: commonCodes = {
			CMN079: [],
			CMN136: [],
			CMN006: [],
		},
	} = useCommonCodes({ comCdGroupNms: ['CMN079', 'CMN136', 'CMN006'] }, commonCodeAdapter);

	const { data = [], refetch } = useRecipientContracts(
		{
			recipientId: currentRecipient?.recipientId,
			centerIds: currentRecipient?.centerId,
			serviceStateCds: currentFilter.serviceFilter?.map((item) => item.value) || [],
			contractStateCds: ['CMN082.90'],
		},
		recipientServicesAdapter,
	);

	const filters = useMemo(
		() =>
			[
				{
					key: 'statusFilter',
					type: 'multi',
					placeholder: '상태',
					options: commonCodes.CMN079,
				},
				{
					key: 'serviceFilter',
					type: 'multi',
					placeholder: '급여',
					options: commonCodes.CMN006.filter((item) => item.data?.etcDesc1 === 'Y'),
				},
			] as Filter<{
				label: string;
				value: any;
			}>[],
		[commonCodes],
	);

	const filteredData = useMemo(
		() =>
			data
				.filter(
					(item) =>
						!currentFilter.statusFilter?.length ||
						currentFilter.statusFilter.some((filter) => filter.value === item.status),
				)
				.filter(
					(item) =>
						!currentFilter.serviceFilter?.length ||
						currentFilter.serviceFilter.some((filter) => filter.label === item.service),
				),
		[data, currentFilter],
	);

	const handleSubmitContractAdd = (contractId: number) => {
		navigate(
			`/${RouterPath.recipient().tab.uniqueKey}/${params.id}/${
				RouterPath.recipient().service.uniqueKey
			}/${RouterPath.myTaskOperate().contract.uniqueKey}/${contractId}/${
				RouterPath.myTaskOperate().recipientBasic.uniqueKey
			}`,
		);
	};

	const handleCreateRecipientRenewal = async () => {
		if (!myAccountInfo) return;
		if (!currentRecipient) return;
		if (createRenewalRecipientContract.isLoading) return;
		try {
			const result = await createRenewalRecipientContract.mutateAsync({
				centerId: myAccountInfo?.centerId,
				recipientId: Number(currentRecipient?.recipientId),
			});

			if (!result) return;

			navigate(
				`/${RouterPath.recipient().tab.uniqueKey}/${params.id}/${
					RouterPath.recipient().service.uniqueKey
				}/${RouterPath.myTaskOperate().contractRenewal.uniqueKey}/${
					result.contractBaseInfo.serviceContractGroupUuid
				}/${RouterPath.myTaskOperate().renewalRecipientBasic.uniqueKey}`,
				{
					state: result,
				},
			);
		} catch (e: any) {
			Toast.error(e?.message);
		}
		hideDialog();
	};

	const handleClickAddContract = () => {
		if (!currentRecipient) return;
		showDialog(({ hideDialog }) => (
			<RecipientContractAddDialog
				currentRecipient={currentRecipient}
				currentRecipientInfoSummary={currentRecipientInfoSummary}
				onCancel={hideDialog}
				onClose={hideDialog}
				onSubmit={handleSubmitContractAdd}
			/>
		));
	};

	const handleClickRenewContract = () => {
		showDialog(({ hideDialog }) => (
			<RecipientContractRenewDialog
				onCancel={hideDialog}
				onClose={hideDialog}
				onSubmit={handleCreateRecipientRenewal}
			/>
		));
	};

	const handleRefetch = () => {
		refetch();
	};

	const handleSubmitChangeEmployee = (serviceContractDetailId: number) => async (ids: number[]) => {
		if (createChangeEmployeeContract.isLoading) return;
		const createChangeEmployeeContractResult = await createChangeEmployeeContract.mutateAsync({
			serviceContractDetailId,
			employeeIds: ids,
		});

		navigate(
			`/${RouterPath.recipient().tab.uniqueKey}/${params.id}/${
				RouterPath.recipient().service.uniqueKey
			}/${RouterPath.myTaskOperate().contractChangeEmployee.uniqueKey}/${
				createChangeEmployeeContractResult?.service.serviceContractId
			}/${RouterPath.myTaskOperate().changeEmployeeRecipientBasic.uniqueKey}`,
			{
				state: createChangeEmployeeContractResult,
			},
		);
		hideDialog();
	};

	const handleClickChangeEmployee = async (item: RecipientService) => {
		const result =
			(await getServiceEmployees.mutateAsync({
				serviceContractDetailId: Number(item.id),
			})) ?? [];

		if (result.length > 1) {
			showDialog(({ hideDialog }) => (
				<RecipientEmployeeChangeDialog
					onCancel={hideDialog}
					onClose={hideDialog}
					items={result}
					onSubmit={handleSubmitChangeEmployee(Number(item.id))}
				/>
			));
		} else {
			handleSubmitChangeEmployee(Number(item.id))((result ?? []).map((item) => item.employeeId));
		}
	};

	const handleClickWithdrawal = (item: RecipientService) => {
		showDialog(() => <ContractWithdrawalDialog detailId={item.id} onRefetch={handleRefetch} />);
	};

	useEffect(() => {
		setCurrentFilter({
			statusFilter: [],
			serviceFilter: [],
		});
	}, [currentRecipient]);

	return (
		<S.Container>
			<CRTableHeader
				currentFilter={currentFilter}
				setCurrentFilter={setCurrentFilter}
				hideSearchButton
				renderRightButton={
					<>
						<CRButton.IconButton
							iconLeft={Assets.icon.refresh}
							size='xSmall'
							type='outlined'
							palette='gray'
							onClick={handleClickRenewContract}>
							갱신
						</CRButton.IconButton>
						<CRButton.IconButton
							iconLeft={Assets.icon.add}
							size='xSmall'
							onClick={handleClickAddContract}>
							계약 추가
						</CRButton.IconButton>
					</>
				}
				filters={filters}
			/>
			<S.TableContainer>
				<RecipientServiceTable
					items={filteredData}
					onClickChangeEmployee={handleClickChangeEmployee}
					onClickWithdrawal={handleClickWithdrawal}
				/>
			</S.TableContainer>
		</S.Container>
	);
}

export default RecipientServiceTab;
