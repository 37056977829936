import React, { useEffect, useMemo, useRef, useState } from 'react';

import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRSyncMenu from 'components/base/CRSyncMenu';
import CRTab from 'components/base/CRTab';
import { Toast } from 'components/base/CRToast';
import SalarySyncHistoryDialog from 'components/domain/dialog/SalarySyncHistoryDialog';
import { SyncDialog } from 'components/domain/dialog/SyncDialog';
import { displayDateToYYYYMM } from 'lib';
import {
	useAutomationLastSync,
	useMyAccountInfo,
	useSalaryAutomationUploadInfo,
	useSalarysAutoBilling,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { ResponseCode } from 'types/api/base';
import { UploadTypeCd } from 'types/api/common';

import SalaryTab from './SalaryTab';
import * as S from './styles';

function SalaryPage(): React.ReactElement {
	const pollingIdRef = useRef<number | null>(null);
	const hasSyncChargeFunc = useHasFunc(['salary:sync_charge']);
	const hasCalculateSalaryFunc = useHasFunc(['salary:calculate_salary']);
	const salaryTab = useMemo(() => RouterPath.salaryTab(), []);
	const dialog = useDialog();
	const [isSyncing, setIsSyncing] = useState(false);

	const { data: myAccountInfo } = useMyAccountInfo();
	const centerId = myAccountInfo?.centerId;

	const { mutateAsync: autoBillSalary, isLoading: isAutoBillingLoading } = useSalarysAutoBilling();
	const { isLoading: isUpdateLogLoading } = useSalaryAutomationUploadInfo({
		centerId,
		uploadTypeCds: [UploadTypeCd.임금계산, UploadTypeCd['청구내역/청구내역상세']],
	});
	const { data: lastSyncData, refetch: refetchLastSyncData } = useAutomationLastSync({
		centerId,
		uploadTypeCds: [UploadTypeCd.임금계산, UploadTypeCd['청구내역/청구내역상세']],
	});

	const handleScheduleSync = async (data: { yearMonth: Date }) => {
		if (!centerId) return;
		if (isAutoBillingLoading) return;

		const YYYYMM = displayDateToYYYYMM(data.yearMonth);

		try {
			const res = await autoBillSalary({ centerId: `${centerId}`, yyyymm: YYYYMM });
			if (res?.code !== ResponseCode.SUCCESS) {
				throw new Error(res?.message);
			}
			setIsSyncing(true);
			Toast.success(
				'청구 내역 동기화 요청을 하였습니다. 잠시 뒤 자동화 업데이트 후 반영될 예정입니다.',
			);
		} catch {
			Toast.error('청구 내역 동기화 요청에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
		}
		dialog.hideDialog();
	};

	const handleClickSyncButton = () => {
		dialog.showDialog(() => <SyncDialog title='청구 내역' onSync={handleScheduleSync} />);
	};

	const handleClickSyncHistory = () => {
		dialog.showDialog(() => <SalarySyncHistoryDialog />);
	};

	useEffect(() => {
		if (!isSyncing && pollingIdRef.current) {
			window.clearInterval(pollingIdRef.current);
			pollingIdRef.current = null;
			return;
		}

		if (isSyncing) {
			pollingIdRef.current = window.setInterval(() => {
				refetchLastSyncData();
			}, 30000);
		}
	}, [isSyncing]);

	useEffect(() => {
		if (!lastSyncData) return;
		const isProgress = ['CMN108.REQUEST', 'CMN108.PROGRESS'].includes(lastSyncData.uploadStateCd);

		// 아직 동기화가 완료되지 않았다면 동기화 중으로 설정하고 탈출
		if (isProgress) {
			setIsSyncing(true);
		} else {
			setIsSyncing(false);
		}
	}, [lastSyncData]);

	return (
		<CRTab.Default
			defaultActiveKey={salaryTab.Salary.key}
			renderRightButton={
				<S.SyncButtonContainer>
					<CRSyncMenu
						syncDisabled={!hasSyncChargeFunc || isUpdateLogLoading}
						syncTooltip={
							<div style={{ whiteSpace: 'pre-line' }}>
								공단 청구내역 다운로드 후 임금을 재계산 및 저장합니다. (확정 내역 제외)
							</div>
						}
						title={isSyncing ? '전체 청구 내역 동기화 중' : '전체 청구 내역 동기화'}
						isProgress={isSyncing}
						onClickSync={handleClickSyncButton}
						onClickSyncHistory={handleClickSyncHistory}
					/>
				</S.SyncButtonContainer>
			}
			items={[
				{
					label: salaryTab.Salary.label,
					key: salaryTab.Salary.key,
					children: (
						<SalaryTab isAutomaticProgress={isSyncing} setIsAutomaticProgress={setIsSyncing} />
					),
				},
			]}
			breadCrumb='임금관리'
		/>
	);
}

export default SalaryPage;
