import React, {
	ChangeEvent,
	Dispatch,
	DragEvent,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import { Toast } from 'components/base/CRToast';
import DeleteDialog from 'components/domain/dialog/DeleteDialog';
import { useCreateUploadUrl, useDeleteFileDetail, useUploadFile } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { useDownload } from 'lib/hook/util/useDownload';
import useGlobalLayout from 'lib/hook/util/useGlobalLayout';
import { getFileExtension } from 'lib/util/file';
import { ResponseCode } from 'types/api/base';
import { FileDetailDTO } from 'types/dto';

import * as S from './styles';

const FILE_PATH_NAME = dayjs().format('YYYYMMDD');
interface Props {
	uploadPath?: string;
	disabled?: boolean;
	title?: string;
	readOnly?: boolean;
	placeholder?: string;
	status?: 'default' | 'error';
	type?: 'single' | 'multiple';
	files?: FileDetailDTO[];
	showSize?: boolean;
	setIsLoading?: Dispatch<React.SetStateAction<boolean>>;
	customObjectKey?: string;
	allowedExtension?: string[];
	isAutoCertification?: boolean;
	onChange?: (files: FileDetailDTO[]) => void;
	onClickPreview?: () => void;
	onDelete?: () => void;
}

function CRFileUploader({
	uploadPath = FILE_PATH_NAME,
	title = '',
	disabled = false,
	readOnly = false,
	placeholder = '파일을 끌어오거나 업로드',
	type = 'single',
	files = [],
	showSize = false,
	customObjectKey,
	allowedExtension,
	isAutoCertification = false,
	onChange,
	onClickPreview,
	setIsLoading,
	onDelete,
	status = 'default',
}: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const inputRef = useRef<HTMLInputElement>(null);
	const [isDragOver, setIsDragOver] = useState(false);
	const { mutateAsync: createUploadUrl, isLoading: createUploadUrlLoading } = useCreateUploadUrl();
	const { mutateAsync: uploadFile, isLoading: uploadFileLoading } = useUploadFile();
	const { mutateAsync: deleteFile, isLoading: deleteFileLoading } = useDeleteFileDetail();

	const unDeletedItem = useMemo(() => files.filter((file) => !file.fileDeleteYn), [files]);

	const { openFile } = useDownload();

	const formatBytes = (bytes: number, decimals = 2) => {
		if (!+bytes) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
	};

	const handleDeleteFile = async (targetIndex: number, fileDetailId?: number) => {
		let newFiles: FileDetailDTO[] = files.filter((file) => !file.fileDeleteYn);

		if (fileDetailId) {
			await deleteFile({
				fileDetailId,
			});
			newFiles = newFiles.map((item) =>
				item.fileDetailId === fileDetailId
					? {
							...item,
							fileDeleteYn: true,
						}
					: item,
			);
		} else {
			newFiles = newFiles.filter((file, fileIndex) => fileIndex !== targetIndex);
		}

		onChange?.(newFiles);
		onDelete?.();
		Toast.success('파일을 삭제했습니다.');
		hideDialog();
	};

	const onDeleteFile = async (targetIndex: number, fileDetailId?: number) => {
		showDialog(({ hideDialog }) => (
			<DeleteDialog
				title='파일 삭제'
				content={`${title} 파일을 삭제하겠습니다.`}
				hideDialog={hideDialog}
				cancelOption={{
					text: '취소',
				}}
				successOption={{
					text: '삭제',
					successCallback: () => handleDeleteFile(targetIndex, fileDetailId),
				}}
			/>
		));
	};

	const handleNullFileDragOver = (event: DragEvent<HTMLDivElement>) => {
		if (disabled) return;
		event.preventDefault();
		setIsDragOver(true);
	};

	const handleDragLeave = () => {
		if (disabled) return;
		setIsDragOver(false);
	};

	const splitPathAndFilename = (path: string) => {
		const lastSlashIndex = path.lastIndexOf('/');
		const transFileNm = path.substring(lastSlashIndex + 1);
		const filePathNm = path.substring(0, lastSlashIndex + 1);

		return {
			filePathNm,
			transFileNm,
		};
	};

	const fileToS3 = async (e: File) => {
		const hasExtension = e.name.split('.').length > 1;

		if (!hasExtension) {
			Toast.error('확장자가 없는 파일은 업로드할 수 없습니다');

			return null;
		}
		const {
			data: createResponse,
			code,
			message,
		} = await createUploadUrl({
			size: e.size,
			objectKey: `${uploadPath}/${
				customObjectKey ? `${customObjectKey}.${getFileExtension(e.name)}` : e.name
			}`,
			autoCertificationYn: isAutoCertification,
		});

		if (code === ResponseCode.SUCCESS) {
			if (createResponse?.objectKey) {
				await uploadFile({
					presignedUrl: createResponse?.url,
					type: e.type,
					file: e,
				});
				const { filePathNm, transFileNm } = splitPathAndFilename(createResponse.objectKey);
				const fileInfo: Pick<
					FileDetailDTO,
					'originFileNm' | 'fileDeleteYn' | 'fileSize' | 'filePathNm' | 'transFileNm'
				> = {
					originFileNm: e.name,
					fileDeleteYn: false,
					fileSize: e.size,
					filePathNm: `${filePathNm}`,
					transFileNm,
				};
				return fileInfo;
			}
			return null;
		}
		Toast.error(message);

		return null;
	};

	const checkAllowedExtension = (files: FileList) => {
		if (allowedExtension?.length) {
			const isValid = Array.from(files).every((file) =>
				allowedExtension.map((item) => item.slice(1)).includes(getFileExtension(file.name)),
			);
			if (!isValid) {
				Toast.error('허용되지 않은 확장자 입니다.');
				return false;
			}
		}

		const isAllFileHasExtension = Array.from(files).every(
			(file) => file.name.split('.').length > 1,
		);
		if (!isAllFileHasExtension) {
			Toast.error('확장자가 없는 파일은 업로드할 수 없습니다.');
			return false;
		}

		return true;
	};

	const handleNullFileDrop = async (event: DragEvent<HTMLDivElement>) => {
		if (disabled) return;
		event.preventDefault();
		setIsDragOver(false);
		const isAllowed = checkAllowedExtension(event.dataTransfer.files);

		if (isAllowed) {
			const s3UploadResult = await Promise.all(
				Array.from(event.dataTransfer.files).map((file) => fileToS3(file)),
			);
			const newFiles = s3UploadResult.filter(Boolean) as FileDetailDTO[];
			const uploadResult =
				type === 'single' && isAutoCertification ? newFiles : files.concat(newFiles);
			onChange?.(uploadResult);
		}
	};

	const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files || disabled) return;
		const isAllowed = checkAllowedExtension(event.target.files);

		if (isAllowed) {
			const s3UploadResult = await Promise.all(
				Array.from(event.target.files).map((file) => fileToS3(file)),
			);
			const newFiles = s3UploadResult.filter(Boolean) as FileDetailDTO[];
			const uploadResult =
				type === 'single' && isAutoCertification ? newFiles : files.concat(newFiles);
			onChange?.(uploadResult);
		}
	};

	const onClickInput = () => {
		inputRef.current?.click();
	};

	const renderFileItemInfo = (file: FileDetailDTO, index: number) => {
		const { fileSize, originFileNm } = file;
		return (
			<S.FileListItem key={file.fileDetailId ?? index}>
				<S.FileInfo>
					<S.FileName $disabled={disabled} onClick={() => openFile(file)}>
						{originFileNm}
					</S.FileName>
					{showSize && <S.FileSize>{formatBytes(fileSize, 2)}</S.FileSize>}
				</S.FileInfo>
				{!disabled && (
					<S.DeleteIcon
						src={Assets.icon.close}
						onClick={() => onDeleteFile(index, file.fileDetailId)}
					/>
				)}
			</S.FileListItem>
		);
	};

	if (readOnly && files?.length) {
		return (
			<S.Container>
				<S.InputContainer
					$disabled={disabled}
					$isSingleUploaded={false}
					$isDragOver={false}
					$error={status === 'error'}>
					<S.Icon src={Assets.icon.uploadFile} />
					<S.CustomInputArea $isSingleUploaded={type === 'single'} $disabled={disabled}>
						{files[0].originFileNm}
					</S.CustomInputArea>
					<CRButton.Default type='outlined' palette='gray' size='xSmall' onClick={onClickPreview}>
						미리보기
					</CRButton.Default>
				</S.InputContainer>
			</S.Container>
		);
	}

	useEffect(() => {
		setIsLoading?.(uploadFileLoading || deleteFileLoading || createUploadUrlLoading);
	}, [uploadFileLoading, deleteFileLoading, createUploadUrlLoading]);

	return (
		<S.Container>
			<S.InputContainer
				$error={status === 'error'}
				$disabled={disabled}
				onDragOver={handleNullFileDragOver}
				onDrop={handleNullFileDrop}
				onDragLeave={handleDragLeave}
				$isSingleUploaded={type === 'single' && unDeletedItem.length > 0}
				$isDragOver={isDragOver}>
				<S.Icon
					src={type === 'single' ? Assets.icon.description : Assets.icon.uploadFile}
					$isSingleUploaded={type === 'single'}
				/>
				<input
					ref={inputRef}
					hidden
					type='file'
					onChange={handleChangeFile}
					multiple={type === 'multiple'}
					accept={allowedExtension && allowedExtension.join(',')}
				/>

				<S.CustomInputArea
					$isSingleUploaded={type === 'single' && unDeletedItem.length > 0}
					$disabled={disabled}>
					{type === 'single' && unDeletedItem.length > 0
						? unDeletedItem[0].originFileNm
						: `${placeholder} `}
				</S.CustomInputArea>
				{isAutoCertification ? (
					!disabled && (
						<CRButton.Default type='outlined' palette='gray' size='xSmall' onClick={onClickInput}>
							파일 선택
						</CRButton.Default>
					)
				) : type === 'single' && unDeletedItem.length > 0 && !disabled ? (
					<S.DeleteIcon
						src={Assets.icon.close}
						onClick={() => onDeleteFile(0, unDeletedItem?.[0]?.fileDetailId)}
					/>
				) : (
					!disabled && (
						<CRButton.Default type='outlined' palette='gray' size='xSmall' onClick={onClickInput}>
							파일 선택
						</CRButton.Default>
					)
				)}
			</S.InputContainer>
			{files?.length > 0 && type === 'multiple' && files.some((file) => !file.fileDeleteYn) && (
				<S.FileList>
					{files.filter((file) => !file.fileDeleteYn)?.map(renderFileItemInfo)}
				</S.FileList>
			)}
		</S.Container>
	);
}

export default CRFileUploader;
