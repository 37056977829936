import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import CRInput from 'components/base/CRInput';
import CRTextArea from 'components/base/CRInput/CRTextArea';
import CRInputLabel from 'components/base/CRInputLabel';
import CRInputMessage from 'components/base/CRInputMessage';
import { Toast } from 'components/base/CRToast';
import { UpsertBurdenChargeCorrectionValidator, displayCommaToNumber } from 'lib';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useBurdenChargesDetail,
	useCommonCodes,
	useUpsertBurdenChargesCorrections,
} from 'lib/hook/react-query';
import { ResponseCode } from 'types/api';
import { BurdenChargeCorrectionDTO } from 'types/dto';
import { OwnExpenseCorrectionForm } from 'types/view/ownExpense';

import * as S from './styles';

interface Props {
	recipientId: number;
	centerId: number;
	item?: BurdenChargeCorrectionDTO;
	hideDialog: () => void;
	refetch?: () => Promise<void>;
}

function AddOwnExpenseCorrectionDialog({
	recipientId,
	centerId,
	item,
	hideDialog,
	refetch,
}: Props): React.ReactElement {
	const {
		watch,
		setValue,
		control,
		handleSubmit,
		trigger,
		formState: { isValid },
	} = useForm<OwnExpenseCorrectionForm>({
		resolver: yupResolver(UpsertBurdenChargeCorrectionValidator),
		mode: 'onChange',
		defaultValues: item,
	});

	const { data: burdenChargesDetail } = useBurdenChargesDetail({
		centerId,
		recipientId,
		burdenAmtChargeYm: dayjs(watch('burdenAmtChargeYm')).format('YYYYMM'),
		serviceTypeCd: watch('serviceTypeCd'),
	});

	const { mutateAsync: upsertBurdenChargesCorrectionsMutateAsync } =
		useUpsertBurdenChargesCorrections(async (client, data) => {
			hideDialog();
			await refetch?.();

			if (data?.code === ResponseCode.SUCCESS) {
				Toast.success('본인부담금 보정 저장을 완료하였습니다.');
			} else {
				Toast.error('본인부담금 보정이 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
		});

	const { data: commonCodes } = useCommonCodes(
		{ comCdGroupNms: ['CMN006', 'CMN210'] },
		commonCodeAdapter,
	);

	const serviceTypeOptions = useMemo(() => commonCodes?.CMN006 ?? [], [commonCodes]);
	const correctionTypeOptions = useMemo(
		() => (commonCodes?.CMN210 ?? []).filter((option) => option.data?.etcDesc1 === 'Y'),
		[commonCodes],
	);

	const onSubmit = async (data: OwnExpenseCorrectionForm) => {
		upsertBurdenChargesCorrectionsMutateAsync({ ...data, recipientId, centerId });
	};

	const submitForm = () => {
		trigger().then((isValid) => {
			if (isValid) {
				handleSubmit(onSubmit)();
			}
		});
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			showDivider
			bottomContainerStyle={{ borderTop: `1px solid ${Colors.gray90}` }}
			type='S'
			title='본인부담금 보정'
			body={
				<S.Form onSubmit={handleSubmit(onSubmit)}>
					<S.ContentContainer>
						<S.Content>
							<Controller
								render={({ field: { onChange, onBlur, value }, formState: { errors } }) => (
									<CRInputLabel isRequired label='연월'>
										<CRInput.YearMonthPicker
											onChangeValue={(date) =>
												onChange(date ? dayjs(date).format('YYYYMM') : undefined)
											}
											currentValue={value ? dayjs(value).toDate() : undefined}
											placeholder='연월 선택'
										/>
									</CRInputLabel>
								)}
								name='burdenAmtChargeYm'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInputLabel isRequired label='급여 유형'>
										<CRInput.Selector
											items={serviceTypeOptions}
											onChangeValue={(option) => onChange(option.value)}
											currentValue={value ? { value, label: '' } : undefined}
											placeholder='급여 유형 선택'
										/>
									</CRInputLabel>
								)}
								name='serviceTypeCd'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInputLabel isRequired label='보정 유형'>
										<CRInput.Selector
											items={correctionTypeOptions}
											onChangeValue={(option) => onChange(option.value)}
											currentValue={value ? { value, label: '' } : undefined}
											placeholder='보정 유형 선택'
										/>
									</CRInputLabel>
								)}
								name='burdenAmtChargeTypeCd'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, value }, formState: { errors } }) => (
									<CRInputLabel
										label='본인부담금 보정'
										renderRightAddon={
											<S.MessageContainer>
												<CRInputMessage type='default'>{`보정 후: ${((burdenChargesDetail?.burdenAmt ?? 0) + (Number(value ?? '0') ?? 0)).toLocaleString()}원`}</CRInputMessage>
											</S.MessageContainer>
										}>
										<CRInput.Default
											type='comma'
											onChange={(value) => {
												const newValue = ![undefined, ''].includes(value)
													? displayCommaToNumber(value)
													: '';
												onChange(newValue);

												setValue(
													'feeSumAmt',
													Number(watch('pcorpChargeAmt') ?? '0') + Number(newValue ?? '0'),
												);
											}}
											min={-999999999}
											max={999999999}
											value={value}
											placeholder='본인부담금 보정 금액 입력'
										/>
									</CRInputLabel>
								)}
								name='burdenAmt'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel
										label='공단청구금 보정'
										renderRightAddon={
											<S.MessageContainer>
												<CRInputMessage type='default'>{`보정 후: ${((burdenChargesDetail?.pcorpChargeAmt ?? 0) + (Number(value ?? '0') ?? 0)).toLocaleString()}원`}</CRInputMessage>
											</S.MessageContainer>
										}>
										<CRInput.Default
											type='comma'
											ref={ref}
											onBlur={onBlur}
											onChange={(value) => {
												const newValue = value ? displayCommaToNumber(value) : '';
												onChange(newValue);

												setValue(
													'feeSumAmt',
													Number(watch('burdenAmt') ?? '0') + Number(newValue ?? '0'),
												);
											}}
											min={-999999999}
											max={999999999}
											value={value}
											placeholder='공단청구금 보정 금액 입력'
										/>
									</CRInputLabel>
								)}
								name='pcorpChargeAmt'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel
										label='수가 총액 보정'
										renderRightAddon={
											<S.MessageContainer>
												<CRInputMessage type='default'>{`보정 후: ${((burdenChargesDetail?.feeSumAmt ?? 0) + (Number(value ?? '0') ?? 0)).toLocaleString()}원`}</CRInputMessage>
											</S.MessageContainer>
										}>
										<CRInput.Default
											type='comma'
											ref={ref}
											onBlur={onBlur}
											onChange={(value) => {
												const newValue = value ? displayCommaToNumber(value) : undefined;
												onChange(newValue);
											}}
											min={-999999999}
											max={999999999}
											value={value}
											placeholder='수가 총액 보정 금액 입력'
											disabled
										/>
									</CRInputLabel>
								)}
								name='feeSumAmt'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel
										label='가산금 보정'
										renderRightAddon={
											<S.MessageContainer>
												<CRInputMessage type='default'>{`보정 후: ${((burdenChargesDetail?.additionAmt ?? 0) + (Number(value ?? '0') ?? 0)).toLocaleString()}원`}</CRInputMessage>
											</S.MessageContainer>
										}>
										<CRInput.Default
											type='comma'
											ref={ref}
											onBlur={onBlur}
											onChange={(value) => {
												const newValue = value ? displayCommaToNumber(value) : undefined;
												onChange(newValue);
											}}
											min={-999999999}
											max={999999999}
											value={value}
											placeholder='가산금 보정 금액 입력'
										/>
									</CRInputLabel>
								)}
								name='additionAmt'
								control={control}
							/>
							<Controller
								render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
									<CRInputLabel label='메모'>
										<CRTextArea
											ref={ref}
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											placeholder='메모 입력'
										/>
									</CRInputLabel>
								)}
								name='correctionSpecialDesc'
								control={control}
							/>
						</S.Content>
					</S.ContentContainer>
				</S.Form>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default
						disabled={!isValid}
						palette='primary'
						size='default'
						onClick={submitForm}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default AddOwnExpenseCorrectionDialog;
