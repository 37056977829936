import React, { useMemo } from 'react';

import { centerMangersOptionsAdapter } from 'lib/adapter/common';
import { recipientScheduleAutomationInfoAdapter } from 'lib/adapter/recipient';
import { useCenterManagers, useEmployees, useRecipientBase } from 'lib/hook/react-query';

import ContractReviewRecipientScheduleAutomationForm from './ContractReviewRecipientScheduleAutomationForm';
import ContractReviewRecipientScheduleAutomationView from './ContractReviewRecipientScheduleAutomationView';
import * as S from './styles';

interface Props {
	centerId: number;
	recipientId: number;
}

function ContractReviewRecipientScheduleAutomationFormView({
	centerId,
	recipientId,
}: Props): React.ReactElement {
	const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

	const { data: employees } = useEmployees({
		centerIds: [centerId],
	});
	const { data: centerManagers } = useCenterManagers({ centerId }, centerMangersOptionsAdapter);
	const { data: recipientAutomationInfoData } = useRecipientBase(
		{ recipientId },
		recipientScheduleAutomationInfoAdapter,
	);

	const currentRecipientScheduleAutomationData = useMemo(
		() =>
			recipientAutomationInfoData?.map((data) => {
				const firstEmployee = employees?.find(
					(item) => item.employeeId === data?.employeeId1?.value.id,
				);
				const secondEmployee = employees?.find(
					(item) => item.employeeId === data?.employeeId2?.value.id,
				);
				return {
					...data,
					employeeId1: firstEmployee
						? {
								label: firstEmployee.korMemberNm,
								value: {
									id: firstEmployee.employeeId,
									name: firstEmployee.korMemberNm,
									duty: firstEmployee.dutyNms,
									birth: firstEmployee.birthDt,
								},
							}
						: undefined,
					employeeId2: secondEmployee
						? {
								label: secondEmployee.korMemberNm,
								value: {
									id: secondEmployee.employeeId,
									name: secondEmployee.korMemberNm,
									duty: secondEmployee.dutyNms,
									birth: secondEmployee.birthDt,
								},
							}
						: undefined,
				};
			}),
		[employees, recipientAutomationInfoData],
	);

	const onStartChangeSection = () => {
		setIsEditMode(true);
	};

	const onCancelChangeSection = () => {
		setIsEditMode(false);
	};

	return (
		<S.Container>
			{isEditMode ? (
				<ContractReviewRecipientScheduleAutomationForm
					employees={employees || []}
					items={currentRecipientScheduleAutomationData}
					centerManagers={centerManagers}
					onStartChangeSection={onStartChangeSection}
					onCancelChangeSection={onCancelChangeSection}
				/>
			) : (
				<ContractReviewRecipientScheduleAutomationView
					employees={employees || []}
					centerManagers={centerManagers}
					items={currentRecipientScheduleAutomationData}
					onChangeMode={onStartChangeSection}
				/>
			)}
		</S.Container>
	);
}

export default ContractReviewRecipientScheduleAutomationFormView;
