import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Assets from 'assets';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRIcon from 'components/base/CRIcon';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { CRText } from 'components/base/CRText';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { EmployeeContractReviewFormFields } from 'components/domain/sideModal/EmployeeContractReviewSideModal/types';
import InformationTable from 'components/ui/InformationTable';
import { InformationLabelType, InformationValueType } from 'components/ui/InformationTable/type';
import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';
import { EmployeeContractReviewListViewType } from 'types/view/contractReview';

import { CHECK_CRIME_REPORT_STATUS_CONFIG, COMPLETION_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: EmployeeContractReviewListViewType;
}

function EmployeeContractDetailCrimeHistory({ data }: Props): React.ReactElement {
	const { control } = useFormContext<EmployeeContractReviewFormFields>();

	const renderCheckCrimpeReportStatus = (value: boolean) => (
		<CRStatus options={CHECK_CRIME_REPORT_STATUS_CONFIG}>{value ? '등록완료' : '미등록'}</CRStatus>
	);
	const crimeHistoryTableItem = [
		[
			{ type: 'label', label: '등록 여부', labelStyle: { width: '9.6rem' } },
			{ type: 'label', label: '서류 유형', labelStyle: { width: '24rem' } },
			{ type: 'label', label: '첨부 파일', labelStyle: { width: '24rem' } },
			{ type: 'label', label: '' },
		] as InformationLabelType[],
		[
			{ type: 'value', value: renderCheckCrimpeReportStatus(data.hasCrimeReportFile) },
			{ type: 'value', value: '범죄경력회보서' },
			{ type: 'value', value: '첨부 파일' },
			{ type: 'value', value: '' },
		] as InformationValueType[],
		[
			{ type: 'value', value: renderCheckCrimpeReportStatus(data.hasElderAbuseReportFile) },
			{ type: 'value', value: '노인학대관련범죄전력조회회신서' },
			{ type: 'value', value: '첨부 파일' },
			{ type: 'value', value: '' },
		] as InformationValueType[],
		[
			{ type: 'value', value: renderCheckCrimpeReportStatus(data.hasDisabilityAbuseReportFile) },
			{ type: 'value', value: '장애인학대관련범죄등경력조회회신서' },
			{ type: 'value', value: '첨부 파일' },
			{ type: 'value', value: '' },
		] as InformationValueType[],
	];

	const tooltipContent = `위 [등록 여부]와 관계없이\n업무 완료 처리합니다.`;

	const crimeOption = [
		{
			label: '시스템 자동 등록',
			value: false,
		},
		{
			label: (
				<CRText style={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
					수기 등록 완료
					<RDTooltip content={tooltipContent} side='top'>
						<CRIcon
							src={Assets.icon.help}
							width='1.6rem'
							height='1.6rem'
							style={{ marginBottom: '0.2rem' }}
						/>
					</RDTooltip>
				</CRText>
			),
			value: true,
		},
	];

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						범죄경력조회
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.isCrimeCheckCompleted ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<InformationTable items={crimeHistoryTableItem} />
				<CRInputLabel label='등록 유형' type='left'>
					<Controller
						control={control}
						name='criminalRecordRegistrationYn'
						render={({ field: { ...rest } }) => (
							<CRCheckBoxGroup
								value={[crimeOption.find((item) => item.value === rest.value) as CheckOption]}
								onChange={(value: CheckOption[]) => rest.onChange(Boolean(value[0]?.value))}
								checkType='single'
								appearanceType='button'
								type='radio'
								gap={0.8}
								options={crimeOption}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default EmployeeContractDetailCrimeHistory;
