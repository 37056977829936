import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import dayjs from 'dayjs';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import useContractTask from 'lib/hook/view/contract/useContractTask';

import { useCreateEmployee, useMyAccountInfo } from '../../../../lib/hook/react-query';
import { useApi } from '../../../../lib/provider/service/Api';
import { EmployeeCheckDuplicateDTO } from '../../../../types/api';
import { ContractDetailEmployeeDutyDTO } from '../../../../types/dto';
import { Toast } from '../../../base/CRToast';
import EmployeeDuplicationCheckForm from '../EmployeeDuplicationCheckForm';
import * as S from './styles';

function VisitCareContractForm(): React.ReactElement {
	const api = useApi();
	const { form, saveTempContract, disabled } = useContractTask();
	const { data: myAccountInfo } = useMyAccountInfo();
	const createEmployee = useCreateEmployee();

	const { append } = useFieldArray({
		control: form.control,
		name: 'employees',
	});

	const index =
		form?.getValues('services')?.findIndex((item) => item.serviceTypeCd === 'CMN006.10') ?? 0;

	const getEmployeeData = async (targetIndex: number, employeeId: number, memberId: number) => {
		if (!myAccountInfo) return;
		const result = await api.getEmployeeDetailInfo({
			centerId: myAccountInfo.centerId,
			employeeId,
			memberId,
		});

		form.setValue(
			`services.${index}.associates.contractEmployees.${targetIndex}.centerId`,
			myAccountInfo.centerId,
		);
		form.setValue(
			`services.${index}.associates.contractEmployees.${targetIndex}.employeeId`,
			employeeId ?? 0,
		);

		if (!form.getValues('employees')?.some((item) => item.base.employeeId === employeeId)) {
			append({
				base: {
					employeeId: employeeId ?? 0,
					centerId: myAccountInfo.centerId,
					employeeNm: result?.korMemberNm,
					employeeRsdnNo: result?.rsdnNo,
					tagMobilePhoneNo: result?.mobilePhoneNo,
					employeeSpecialDesc: result?.employeeSpecialDesc,
					employeeBirthDt: result?.birthDt,
					employeeBasAddr: result?.basAddr ?? '',
					employeeDetailAddr: result?.detailAddr ?? '',
					roadNm: result?.roadNm ?? '',
					regionNm1: result?.regionNm1 ?? '',
					regionNm2: result?.regionNm2 ?? '',
					regionNm3: result?.regionNm3 ?? '',
					disabilityGradeCd: result?.disabilityGradeCd,
					dementiaEduCompleteYn: result?.dementiaEduCompleteYn,
					dutyCds: (result?.dutyCds.length
						? result.dutyCds
						: [
								{
									dutyCd: 'CMN070.10',
								} as ContractDetailEmployeeDutyDTO,
							]
					).map(
						(item) =>
							({
								dutyCd: item.dutyCd,
								employeeDutyId: item.employeeDutyId
									? Number(item.employeeDutyId ?? '0')
									: undefined,
							}) as ContractDetailEmployeeDutyDTO,
					),
					tagCallRequestYn: result?.twoPhoneYn,
					twoPhoneYn: result?.twoPhoneYn,
					subMobilePhoneNo: result?.subMobilePhoneNo,
					joinDate: result?.joinDate,
					docReceiveMethodCd: result?.docReceiveMethodCd,
				},
				salary: {
					salaryPayTypeCd: result?.salaryPayTypeCd,
					salaryAccountBankCd: result?.salaryAccountBankCd,
					salaryAccountDepositorNm: result?.salaryAccountDepositorNm,
					salaryAccountNo: result?.salaryAccountNo,
					salaryAccountValidYn: result?.salaryAccountValidYn,
				},
			});

			saveTempContract?.({ disableToast: true });
		}
	};

	const handleClickDuplication =
		(targetIndex: number) =>
		async (data: { name: string; rsdn: string; result: EmployeeCheckDuplicateDTO }) => {
			if (!myAccountInfo) return;
			if (data.result?.isCenterDuplicated && data.result.isEmployeeDuplicated) {
				if (!data.result.employeeId) return;
				if (!data.result.memberId) return;
				await getEmployeeData(targetIndex, data.result.employeeId, data.result.memberId);
				Toast.success('계약이 저장되었습니다.');
				return;
			}
			const result = await createEmployee.mutateAsync({
				centerId: myAccountInfo.centerId,
				name: data.name,
				rsdnNo: data.rsdn,
			});
			if (!result?.data?.employeeId) return;
			if (!result?.data?.memberId) return;

			await getEmployeeData(targetIndex, result.data.employeeId, result.data.memberId);
			Toast.success('직원 생성을 위해 계약이 저장되었습니다.');
			return;
		};

	return (
		<S.Container>
			<S.SectionContainer>
				<S.SectionTitle>수급자</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.recipientContractAssignDate`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => {
						const recipientContractAssignDate = form?.watch(
							`services.${index}.associates.recipientContractAssignDate`,
						);
						return (
							<CRInputLabel type='left' label='계약일' isRequired showOverflow>
								<CRInput.DatePicker
									placeholder='계약일'
									value={
										recipientContractAssignDate
											? dayjs(recipientContractAssignDate).toDate()
											: undefined
									}
									maxDate={
										form.watch(`services.${index}.use.sameDayContractYn`)
											? dayjs(form.watch(`services.${index}.use.serviceStartDate`)).toDate()
											: dayjs(form.watch(`services.${index}.use.serviceStartDate`))
													.subtract(1, 'day')
													.toDate()
									}
									onChangeValue={(date) => {
										onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'));
										form.setValue(
											'services',
											form.getValues('services').map((item) => ({
												...item,
												associate: {
													...item.associates,
													recipientContractAssignDate: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
												},
											})),
										);
									}}
									disabled={disabled}
								/>
							</CRInputLabel>
						);
					}}
				/>
			</S.SectionContainer>
			<S.SectionContainer>
				<S.SectionTitle>직원</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.contractEmployees.0`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => {
						const employeeContractAssignDate = form?.watch(
							`services.${index}.associates.contractEmployees.0.employeeContractAssignDate`,
						);
						return (
							<>
								<CRInputLabel type='left' label='계약일' isRequired showOverflow>
									<CRInput.DatePicker
										placeholder='계약일'
										value={
											employeeContractAssignDate
												? dayjs(employeeContractAssignDate).toDate()
												: undefined
										}
										maxDate={
											form.watch(`services.${index}.use.sameDayContractYn`)
												? dayjs(form.watch(`services.${index}.use.serviceStartDate`)).toDate()
												: dayjs(form.watch(`services.${index}.use.serviceStartDate`))
														.subtract(1, 'day')
														.toDate()
										}
										onChangeValue={(date) =>
											onChange({
												...value,
												employeeContractAssignDate: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
											})
										}
										disabled={disabled}
									/>
								</CRInputLabel>
								<CRInputLabel type='left' label='직원 정보' isRequired showOverflow>
									<CRCardFormLayout label='직원' isRequired>
										<EmployeeDuplicationCheckForm
											data={{ rsdn: value?.employeeRsdnNo, name: value?.employeeNm }}
											onChange={(data) =>
												onChange({ ...value, employeeNm: data.name, employeeRsdnNo: data.rsdn })
											}
											disabled={form
												.watch('employees')
												?.some(
													(item) =>
														item?.base.employeeId &&
														item?.base.employeeId ===
															form.watch(
																`services.${index}.associates.contractEmployees.0.employeeId`,
															),
												)}
											onConfirm={handleClickDuplication(0)}
										/>
									</CRCardFormLayout>
								</CRInputLabel>
							</>
						);
					}}
				/>
			</S.SectionContainer>
		</S.Container>
	);
}

export default VisitCareContractForm;
