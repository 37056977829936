import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { CRText } from 'components/base/CRText';
import EmployReportEnrollDialog from 'components/domain/dialog/EmployReportEnrollDialog';
import { EmployeeContractReviewFormFields } from 'components/domain/sideModal/EmployeeContractReviewSideModal/types';
import { REPORT_COMPLETION_STATUS_CONFIG } from 'components/domain/table/EmployeeContractManagementTable/constant';
import InformationTable from 'components/ui/InformationTable';
import { InformationLabelType, InformationValueType } from 'components/ui/InformationTable/type';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes, useGetEmployReportData } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { EmployReportDataDTO } from 'types/dto/employReport';
import { CRStatusConfig } from 'types/view/base';
import { EmployeeContractReviewListViewType } from 'types/view/contractReview';

import { TAG_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: EmployeeContractReviewListViewType;
}

function EmployeeReportInfo({ data }: Props): React.ReactElement {
	const { showDialog } = useDialog();

	const handleClickAddEmployeeReport = () => {
		const longtermCareBenefitTypeList = data.lognTermCareBenefitInfo.map((item) => ({
			serviceTypeNm: item.longtermCareBenefitType,
			serviceTypeCd: item.longtermCareBenefitTypeCd,
			workStartDate: item.longtermCareStartDate,
			joinDt: item.longtermCareStartDate,
		}))[0];
		console.log(longtermCareBenefitTypeList);
		showDialog(() => (
			<EmployReportEnrollDialog
				data={{
					autoSsisYn: true,
					requestType: '입사',
					serviceTypeCd: longtermCareBenefitTypeList.serviceTypeCd,
					serviceTypeNm: longtermCareBenefitTypeList.serviceTypeNm,
					workStartDt: longtermCareBenefitTypeList.workStartDate,
					joinDt: longtermCareBenefitTypeList.joinDt,
					employeeNo: dayjs().format('YYYYMMDD-HHss'),
				}}
				employeeId={data.employeeId}
				centerId={data.centerId}
			/>
		));
	};

	const { control } = useFormContext<EmployeeContractReviewFormFields>();
	const { data: commonCodes = { CMN216: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN216'] },
		commonCodeAdapter,
	);
	const { data: employReportData } = useGetEmployReportData({
		page: 1,
		size: 50,
		requestTypes: ['입사', '급여추가'],
		keyword: data.employeeName,
	});

	const renderConfigStatus = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || item.value === value);

		return (
			<CRStatus type='pill' options={config}>
				{currentValue?.label}
			</CRStatus>
		);
	}, []);

	const renderReportStatus = useCallback((value?: string, item?: EmployReportDataDTO) => {
		const renderText = (value?: string) => {
			switch (value) {
				case 'CMN153.10':
					return '대기';
				case 'CMN153.20':
					return '진행중';
				case 'CMN153.30':
					return '완료';
				case 'CMN153.40':
					return '실패';
				default:
					return '대기';
			}
		};

		return <CRStatus options={TAG_STATUS_CONFIG}>{renderText(item?.statusCd)}</CRStatus>;
	}, []);

	const renderRequestStatus = useCallback(
		(value?: boolean) => (
			<CRText typography='label' color={value ? 'gray00' : 'gray60'}>
				{value ? '요청' : '미요청'}
			</CRText>
		),
		[],
	);

	const employeeReportTableItem = (employReportData: EmployReportDataDTO[]) => [
		[
			{ type: 'label', label: '결과', labelStyle: { width: '8rem' } },
			{ type: 'label', label: '요청 상태', labelStyle: { width: '8rem' } },
			{ type: 'label', label: '결과로그', labelStyle: { width: '36rem' } },
			{ type: 'label', label: '급여유형', labelStyle: { width: '8rem' } },
			{ type: 'label', label: '입사일', labelStyle: { width: '10.4rem' } },
		] as InformationLabelType[],
		...employReportData.map(
			(item) =>
				[
					{ type: 'value', value: renderReportStatus(item.statusCd) },
					{ type: 'value', value: renderRequestStatus(item.autoSsisYn) },
					{ type: 'value', value: item.msg || '-' },
					{ type: 'value', value: item.serviceTypeNm },
					{ type: 'value', value: dayjs(item.joinDt).format('YYYY.MM.DD') },
				] as InformationValueType[],
		),
	];

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						인력신고
						{renderConfigStatus(REPORT_COMPLETION_STATUS_CONFIG, data.employeeReportState)}
					</S.CardLabelContainer>
				}>
				<CRInputLabel label='입사 신고' type='left'>
					<Controller
						control={control}
						name='employeeReportStatCd'
						render={({ field: { ...rest } }) => (
							<CRInput.Selector
								items={commonCodes.CMN216}
								currentValue={commonCodes.CMN216.find((item) => item.value === rest.value)}
								onChangeValue={(value) => rest.onChange(value.value)}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
			<CRCardFormLayout label='인력신고 자동화 이력'>
				{employReportData?.content && employReportData?.content.length > 0 ? (
					<InformationTable items={employeeReportTableItem(employReportData.content)} />
				) : (
					<S.EmptyContainer>
						<CRText typography='label' color='gray60' text='등록된 요청이 없습니다.' />
					</S.EmptyContainer>
				)}
				<CRButton.IconButton
					size='default'
					type='outlined'
					palette='gray'
					style={{ width: '13.2rem' }}
					iconLeft={Assets.icon.add}
					onClick={handleClickAddEmployeeReport}>
					요청 추가
				</CRButton.IconButton>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default EmployeeReportInfo;
