import React, { ChangeEvent, useRef, useState } from 'react';

import CRButton from 'components/base/CRButton';
import CRToolTip from 'components/base/CRToolTip';

import Assets from '../../../assets';
import CRSpinner from '../../../components/base/CRSpinner';
import * as S from './styles';

interface Props {
	onChange?: (files: File) => Promise<void> | void;
	disabled?: boolean;
}

function OnwExpenseFileUploadButton({ onChange, disabled = false }: Props): React.ReactElement {
	const [loading, setLoading] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		setLoading(true);
		await onChange?.(event.target.files[0]);
		setLoading(false);
	};

	const onClickInput = () => {
		inputRef.current?.click();
	};

	return (
		<S.UploadButtonContainer>
			<CRToolTip
				title='원청구의 청구 명세서를 업로드해주세요, 보완청구/추가청구는 본인부담금 보정 기능을 이용해주세요'
				placement='right'>
				<CRButton.Default disabled={disabled} palette='gray' type='outlined' onClick={onClickInput}>
					<S.Button>
						{loading ? <CRSpinner floatMode={false} /> : <S.Icon src={Assets.icon.description} />}
						원청구 명세서 업로드
					</S.Button>
				</CRButton.Default>
			</CRToolTip>
			<input ref={inputRef} hidden type='file' onChange={handleChangeFile} />
		</S.UploadButtonContainer>
	);
}

export default OnwExpenseFileUploadButton;
