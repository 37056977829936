import React from 'react';
import { UseFieldArrayReturn, useFieldArray, useFormContext } from 'react-hook-form';

import CRTable from 'components/base/CRTable';
import { CheckOption } from 'components/base/Selections/type';
import CenterAccountRecordDetailFormRow from 'components/domain/form/CenterAccountRecordDetailFormNew/CenterAccountRecordDetailFormRow';
import { GetCenterAccountRecordTargetData } from 'types/api/centerAccount';

export interface CenterAccountRecordFormDetail {
	id?: string;
	tempId?: string;
	centerAccountRecordDetailId?: number;
	centerAccountRecordId?: number;
	centerId?: number;
	recordTargetDivValue?: CheckOption<any>;
	recordTargetDivCd?: string;
	recordTargetId?: number;
	recordTargetNm?: string;
	recordTypeValue?: CheckOption<any>;
	recordTypeCd?: string;
	recordTypeNm?: string;
	recordDetailValue?: CheckOption<any>;
	recordDetailCd?: string;
	recordDetailNm?: string;
	recordDesc?: string;
	depositAmt?: string;
	withdrawAmt?: string;
	centerAccountAliasNm?: string;
	recordDt?: string;
	recordTime?: string;
	recordKindNm?: string;
	balanceAmt?: number;
	record?: string;
	rowType?: string;
	recordDate?: string;
	recordTargetYm?: string;
}

export interface CenterAccountRecordFormData {
	centerAccountRecordId: number;
	centerId: number;
	centerNm: string;
	centerAccountId: number;
	centerAccountBankCd: string;
	centerAccountBankNm: string;
	centerAccountNo: string;
	centerAccountBizNo: string;
	centerAccountDepositorNm: string;
	verifyStateValue: string;
	recordKindNm: string;
	record: string;
	depositAmt: number;
	withdrawAmt: number;
	balanceAmt: number;
	centerAccountAliasNm: string;
	recordDt: string;
	recordTime: string;
	recordDate: string;
	recordTypeNm: string;
	recordDesc: string;
	centerAccountRecordDetails: CenterAccountRecordFormDetail[];
}
interface Props {
	fieldArray: UseFieldArrayReturn<CenterAccountRecordFormData, 'centerAccountRecordDetails'>;
	recipients?: GetCenterAccountRecordTargetData[];
	selectedRecommendedRecipient?: GetCenterAccountRecordTargetData;
	disabledInputs?: ['금액' | '유형' | '메모'];
	lastAmt?: number;
}

function CenterAccountRecordDetailFormNew({
	fieldArray,
	recipients,
	selectedRecommendedRecipient,
	disabledInputs,
	lastAmt,
}: Props): React.ReactElement {
	const formReturn = useFormContext<CenterAccountRecordFormData>();

	return (
		<CRTable.Root>
			<CRTable.Head
				heads={[
					{ label: '유형', width: '16rem' },
					{ label: '수급자', width: '12rem' },
					{ label: '해당월', width: '12rem' },
					{ label: '급여', width: '12rem' },
					{ label: '현금영수증 여부', width: '12rem' },
					{ label: '금액', width: '20rem' },
					{ label: '메모', width: '32rem' },
					{ label: '' },
				]}
			/>
			{fieldArray.fields.map((detail, index) => {
				if (detail.rowType === 'DELETE') return null;
				return (
					<CenterAccountRecordDetailFormRow
						key={detail.tempId}
						formReturn={formReturn}
						fieldArray={fieldArray}
						item={detail}
						index={index}
						recipients={recipients}
						selectedRecommendedRecipient={selectedRecommendedRecipient}
						disabledInputs={disabledInputs}
						lastAmt={lastAmt}
					/>
				);
			})}
		</CRTable.Root>
	);
}

export default CenterAccountRecordDetailFormNew;
