import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { CRText } from 'components/base/CRText';
import EDocNoDialog from 'components/domain/dialog/EdocNoDialog';
import { RecipientContractReviewFormFields } from 'components/domain/sideModal/RecipientContractReviewSideModal/types';
import { ADMISSION_APPLICATION_SEND_STATUS_CONFIG } from 'components/domain/table/RecipientContractReviewTable/constant';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { CRStatusConfig } from 'types/view/base';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';

import { COMPLETION_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: RecepientContractReviewListViewType;
}

function RecipientApplicationInfo({ data }: Props): React.ReactElement {
	const { showDialog } = useDialog();

	const { control } = useFormContext<RecipientContractReviewFormFields>();

	const { data: commonCodes = { CMN212: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN212'] },
		commonCodeAdapter,
	);

	const renderConfigStatus = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || value === item.value);

		return (
			<CRStatus type='pill' options={config}>
				{currentValue?.label}
			</CRStatus>
		);
	}, []);

	const handleClickPreview = async (edocNo: string) => {
		showDialog(() => <EDocNoDialog edocNo={edocNo} centerId={Number(data.data.centerId)} />);
	};

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						입소이용신청
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.tagCompletionStatus ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<S.LabelValueContainer>
					<S.LabelContainer>
						<CRText typography='body' text='대상' />
					</S.LabelContainer>
					<CRText color={data.isTargetOfadmissionApplication ? 'primary60' : 'gray00'}>
						{data.isTargetOfadmissionApplication ? '신청필요' : '해당없음'}
					</CRText>
				</S.LabelValueContainer>
				<S.LabelValueContainer>
					<S.LabelContainer>
						<CRText typography='body' text='서류 작성' />
					</S.LabelContainer>
					<CRText color={data.isCompletedAdmissionApplicationForm ? 'gray00' : 'primary60'}>
						{data.isCompletedAdmissionApplicationForm ? '완료' : '미완료'}
					</CRText>
				</S.LabelValueContainer>
				<S.LabelValueContainer>
					<S.LabelContainer>
						<CRText typography='body' text='발송상태' />
					</S.LabelContainer>
					<S.TableCellContianer>
						{renderConfigStatus(
							ADMISSION_APPLICATION_SEND_STATUS_CONFIG,
							data.admissionApplicationFormSendStatus,
						)}
						{data.data?.recipientAdmissionEdocNo && (
							<CRButton.Default
								type='outlined'
								palette='gray'
								size='xSmall'
								onClick={() => handleClickPreview(data.data?.recipientAdmissionEdocNo || '')}>
								미리보기
							</CRButton.Default>
						)}
					</S.TableCellContianer>
				</S.LabelValueContainer>
				<CRInputLabel label='지자체 발송/승인' type='left' isRequired>
					<Controller
						control={control}
						name='admissionDispatchOption'
						render={({ field: { value, onChange } }) => (
							<CRInput.Selector
								currentValue={value}
								onChangeValue={onChange}
								items={commonCodes.CMN212}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default RecipientApplicationInfo;
