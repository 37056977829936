import React from 'react';
import { Control, Controller } from 'react-hook-form';

import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { CheckOption } from 'components/base/Selections/type';
import { CorporationDTO, ExternalCenterDTO } from 'types/dto';
import { CreateEmployReportForm } from 'types/view/employReport';

import { requstTypeOptionItems } from './cosntant';

interface Props {
	control: Control<CreateEmployReportForm>;
	centerOptions: CheckOption<ExternalCenterDTO>[];
	corporationOptions: CheckOption<CorporationDTO>[];
	handleAdditionalInfo: (value: CheckOption, onChange: (...event: CheckOption[]) => void) => void;
	handleCorpInfo: (value: CheckOption, onChange: (...event: CheckOption[]) => void) => void;
}

function EmployeeReportDefaultInfoForm({
	control,
	centerOptions,
	corporationOptions,
	handleAdditionalInfo,
	handleCorpInfo,
}: Props): React.ReactElement {
	const renderErrorMessage = (error: boolean, message: string) =>
		error ? (
			<CRText color='primary60' typography='label'>
				{message}
			</CRText>
		) : null;

	return (
		<>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel label='요청 여부'>
						<CRCheckBox
							checkBoxType='checkbox'
							appearanceType='button'
							checked={value}
							onChange={onChange}>
							<CRText>자동화 요청</CRText>
						</CRCheckBox>
					</CRInputLabel>
				)}
				name='autoSsisYn'
				control={control}
			/>

			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						label='요청 유형'
						isRequired
						message={renderErrorMessage(!!errors.requestType, errors.requestType?.message ?? '')}>
						<CRInput.Selector
							placeholder='요청유형 입력'
							items={requstTypeOptionItems}
							currentValue={value}
							onChangeValue={(value) => handleAdditionalInfo(value, onChange)}
							status={errors.requestType ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='requestType'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
					<CRInputLabel
						label='소속센터'
						isRequired
						message={renderErrorMessage(!!errors.center, errors.center?.message ?? '')}>
						<CRInput.Selector
							items={centerOptions}
							autoComplete
							currentValue={value}
							onChangeValue={(value) => handleCorpInfo(value, onChange)}
							placeholder='센터 선택'
							status={errors.center ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='center'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, onBlur, value, ref }, formState: { errors } }) => (
					<CRInputLabel
						label='법인'
						isRequired
						message={renderErrorMessage(!!errors.corporation, errors.corporation?.message ?? '')}>
						<CRInput.Selector
							items={corporationOptions}
							autoComplete
							currentValue={value}
							onChangeValue={onChange}
							placeholder='법인 선택'
							status={errors.corporation ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='corporation'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						label='직원'
						isRequired
						message={renderErrorMessage(!!errors.employeeNm, errors.employeeNm?.message ?? '')}>
						<CRInput.Default
							placeholder='이름 입력'
							value={value}
							onChange={onChange}
							status={errors.employeeNm ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='employeeNm'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						label='직원 주민등록번호'
						isRequired
						message={renderErrorMessage(
							!!errors.employeeRsdnNo,
							errors.employeeRsdnNo?.message ?? '',
						)}>
						<CRInput.Default
							placeholder='주민번호 입력'
							onChange={onChange}
							value={value}
							status={errors.employeeRsdnNo ? 'error' : 'default'}
							maxLength={13}
						/>
					</CRInputLabel>
				)}
				name='employeeRsdnNo'
				control={control}
			/>
		</>
	);
}

export default EmployeeReportDefaultInfoForm;
