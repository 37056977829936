import * as yup from 'yup';

// 4대보험 취득 시 Validator
export const insuranceAcquirementEnrollValidator = yup.object().shape({
	weekPerWorkHourCnt: yup.number().required(), // 주 소정근로시간
	accidentInsuranceApproveYn: yup.boolean().optional(), // 산재보험
	accidentInsuranceRequestAmt: yup
		.string()
		.when('accidentInsuranceApproveYn', (value, schema) =>
			value ? schema.required() : schema.optional(),
		), // 산재보험
	accidentInsuranceRequestDate: yup
		.string()
		.when('accidentInsuranceApproveYn', (value, schema) =>
			value ? schema.required() : schema.optional(),
		),
	pensionInsuranceApproveYn: yup.boolean().optional(), // 국민연금
	pensionInsuranceRequestAmt: yup
		.string()
		.when('pensionInsuranceApproveYn', (value, schema) =>
			value ? schema.required() : schema.optional(),
		), // 국민연금
	pensionInsuranceRequestDate: yup
		.string()
		.when('pensionInsuranceApproveYn', (value, schema) =>
			value ? schema.required() : schema.optional(),
		),
	employInsuranceApproveYn: yup.boolean().optional(), // 고용보험
	employInsuranceRequestAmt: yup
		.string()
		.when('employInsuranceApproveYn', (value, schema) =>
			value ? schema.required() : schema.optional(),
		), // 고용보험
	employInsuranceRequestDate: yup
		.string()
		.when('employInsuranceApproveYn', (value, schema) =>
			value ? schema.required() : schema.optional(),
		),
	healthInsuranceApproveYn: yup.boolean().optional(), // 건강보험
	healthInsuranceRequestAmt: yup
		.string()
		.when('healthInsuranceApproveYn', (value, schema) =>
			value ? schema.required() : schema.optional(),
		), // 건강보험
	healthInsuranceRequestDate: yup
		.string()
		.when('healthInsuranceApproveYn', (value, schema) =>
			value ? schema.required() : schema.optional(),
		),
	attatchFileDetails: yup.object().optional(),
});
