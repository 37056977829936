import React, { useEffect } from 'react';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRTab from 'components/base/CRTab';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import RecipientApplicationInfo from 'components/domain/table/RecipientContractDetailTable/RecipientApplicationInfo';
import RecipientContractDetailBasicInfo from 'components/domain/table/RecipientContractDetailTable/RecipientContractDetailBasicInfo';
import RecipientContractDetailInfo from 'components/domain/table/RecipientContractDetailTable/RecipientContractDetailInfo';
import RecipientEmployeeChangeInfo from 'components/domain/table/RecipientContractDetailTable/RecipientEmployeeChangeInfo';
import RecipientLongtermInfo from 'components/domain/table/RecipientContractDetailTable/RecipientLongtermInfo';
import RecipientScheduleInfo from 'components/domain/table/RecipientContractDetailTable/RecipientScheduleInfo';
import useCRForm from 'lib/hook/form/useCRForm';
import { useSaveContractReview } from 'lib/hook/react-query';
import useSideModal from 'lib/hook/util/useSideModal';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { SaveContractReviewDTO } from 'types/dto/contractReview';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';

import {
	APPLICATION_STATUS_OPTION,
	CHANGE_OPTION,
	OVERALL_OPINION_REVIEW_OPTION,
	SCHEDULE_REGISTRATION_OPTION,
	TAG_STATUS_OPTION,
} from './constant';
import * as S from './styles';
import { RecipientContractReviewFormFields } from './types';
import { recipientContractReviewValidator } from './validator';

interface Props {
	data: RecepientContractReviewListViewType;
	defaultActiveKey?: string;
}

function RecipientContractReviewSideModal({ data, defaultActiveKey }: Props): React.ReactElement {
	const { hideSideModal } = useSideModal();

	const { mutate: saveRecipientContractReview } = useSaveContractReview(
		(client, returnData, variables) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success(`계약 검수 저장에 성공하였습니다.`);
				client.invalidateQueries([endpoint.getEmployeeContractReviewList.key]);
			} else {
				Toast.error(`계약 검수 저장에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.`);
			}
		},
	);

	// TODO: 기본값 설정
	const handleDefaultValues = (
		data?: RecepientContractReviewListViewType,
	): RecipientContractReviewFormFields => {
		if (!data) return {} as RecipientContractReviewFormFields;

		return {
			serviceContractId: data.serviceContractId,
			centerId: data.data.centerId,
			recipientId: data.data?.recipientId,
			employeeId: undefined,
			salaryPlanCompleteOption: OVERALL_OPINION_REVIEW_OPTION.find(
				(item) => item.value === data.longtermCareBenefitPlanOverallOpinionReview,
			) as CheckOption,
			notiToPcorpYn: data.longtermCareBenefitPlanNotificationToCorporation,
			integratedEvaluationCompleteYn: data.integratedAssessmentCompletionStatus,
			admissionDispatchOption: APPLICATION_STATUS_OPTION.find(
				(item) => item.value === data.admissionApplicationSendApprovalStatus,
			) as CheckOption,
			longtermPlanRequestYn: data.appliedIndividualLongtermCarePlan,
			registeredLongtermCareBenefitScheduleToCorporationOption: SCHEDULE_REGISTRATION_OPTION.find(
				(item) => item.value === data.registeredLongtermCareBenefitScheduleToCorporation,
			) as CheckOption,
			salaryChangeStatOption: CHANGE_OPTION.find(
				(item) => item.value === data.longtermCareBenefitPlanReasonForChange,
			) as CheckOption,
			employeeReplacementCounselingOption: CHANGE_OPTION.find(
				(item) => item.value === data.employeeChangeConsultationWritingStatus,
			) as CheckOption,
			tagRequestTaskOption: TAG_STATUS_OPTION.find(
				(item) => item.value === data.tagAttachmentStatus,
			) as CheckOption,

			pcorpTagRegistrationCd: data.tagAttachmentStatus,
			remark: data.adminMemo,
			contractMngtId: data.contractMngtId,
		};
	};

	const { setValue, watch, CRHandleSubmit, CRFormProvider, reset } =
		useCRForm<RecipientContractReviewFormFields>({
			yupValidator: recipientContractReviewValidator,
			defaultValues: handleDefaultValues(data),
		});

	const onSubmit = async (inputData: RecipientContractReviewFormFields) => {
		const saveRecipientContractReviewData: SaveContractReviewDTO = {
			serviceContractId: inputData.serviceContractId,
			centerId: inputData.centerId,
			employeeId: undefined,
			recipientId: inputData.recipientId,
			contractMngtId: inputData.contractMngtId,

			salaryPlanCompleteCd: inputData.salaryPlanCompleteOption.value,
			notiToPcorpYn: inputData.notiToPcorpYn,
			integratedEvaluationCompleteYn: inputData.integratedEvaluationCompleteYn,
			admissionDispatchCd: inputData.admissionDispatchOption.value,
			longtermPlanRequestYn: inputData.longtermPlanRequestYn,
			pcorpScheduleCd: inputData.registeredLongtermCareBenefitScheduleToCorporationOption.value,
			salaryChangeStatCd: inputData.salaryChangeStatOption.value,
			employeeReplacementCounselingCd: inputData.employeeReplacementCounselingOption.value,
			tagRequestTaskCd: inputData.tagRequestTaskOption.value,
			remark: inputData.remark,
		};
		await saveRecipientContractReview([saveRecipientContractReviewData]);
	};
	useEffect(() => {
		reset(handleDefaultValues(data));
	}, [data]);

	return (
		<S.Container>
			<S.Header>
				<CRText typography='h4' text='수급자 계약 정보' color='gray00' />
				<S.CloseIcon src={Assets.icon.close} alt='닫기' onClick={hideSideModal} />
			</S.Header>
			<S.Content>
				<CRFormProvider>
					<RecipientContractDetailBasicInfo data={data} />
					<CRTab.Sub
						menuContainerStyle={{ padding: '0 2.4rem' }}
						contentContainerStyle={{ overflow: 'scroll', scrollbarWidth: 'none' }}
						containerStyle={{ overflow: 'visible' }}
						defaultActiveKey={defaultActiveKey || '급여계약 검수'}
						items={[
							{
								label: '급여계약 검수',
								key: '급여계약 검수',
								children: <RecipientContractDetailInfo data={data} />,
							},
							{
								label: '직원 변경 검수',
								key: '직원 변경 검수',
								children: <RecipientEmployeeChangeInfo data={data} />,
							},
							{
								label: '입소이용신청',
								key: '입소이용신청',
								children: <RecipientApplicationInfo data={data} />,
							},
							{
								label: '일정 등록',
								key: '일정 등록',
								children: <RecipientScheduleInfo data={data} />,
							},
							{
								label: '공단 등록',
								key: '공단 등록',
								children: <RecipientLongtermInfo data={data} />,
							},
						]}
					/>
				</CRFormProvider>
			</S.Content>
			<S.ButtonContainer>
				<CRButton.Default type='text' palette='gray' onClick={() => hideSideModal()}>
					취소
				</CRButton.Default>
				<CRButton.Default buttonType='submit' onClick={CRHandleSubmit(onSubmit)}>
					저장
				</CRButton.Default>
			</S.ButtonContainer>
		</S.Container>
	);
}

export default RecipientContractReviewSideModal;
