import React from 'react';
import { Controller } from 'react-hook-form';

import dayjs from 'dayjs';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';

import { CheckOption } from '../../../base/Selections/type';
import EmployeeDuplicationCheckForm from '../EmployeeDuplicationCheckForm';
import * as S from './styles';

interface Props {
	currentService: CheckOption<any>;
}

function RenewalVisitCareContractForm({ currentService }: Props): React.ReactElement {
	const { form, disabled } = useContractRenewalTask();

	const index =
		form?.getValues('services').findIndex((item) => {
			const [serviceContractId, serviceTypeCd] = currentService.value.split('-');

			return (
				item.serviceContractId === Number(serviceContractId) && item.serviceTypeCd === serviceTypeCd
			);
		}) ?? 0;

	return (
		<S.Container key={currentService.value}>
			<S.SectionContainer>
				<S.SectionTitle>수급자</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.recipientContractAssignDate`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => {
						const recipientContractAssignDate = form?.watch(
							`services.${index}.associates.recipientContractAssignDate`,
						);
						return (
							<CRInputLabel type='left' label='계약일' isRequired showOverflow>
								<CRInput.DatePicker
									placeholder='계약일'
									maxDate={
										form.watch(`services.${index}.use.sameDayContractYn`)
											? dayjs(form.watch(`services.${index}.use.serviceStartDate`)).toDate()
											: dayjs(form.watch(`services.${index}.use.serviceStartDate`))
													.subtract(1, 'day')
													.toDate()
									}
									value={
										recipientContractAssignDate
											? dayjs(recipientContractAssignDate).toDate()
											: undefined
									}
									onChangeValue={(date) => {
										onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'));
										form.setValue(
											'services',
											form.getValues('services').map((item) => ({
												...item,
												associates: {
													...item.associates,
													recipientContractAssignDate: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
												},
											})),
										);
									}}
									disabled={disabled}
								/>
							</CRInputLabel>
						);
					}}
				/>
			</S.SectionContainer>
			<S.SectionContainer>
				<S.SectionTitle>직원</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.contractEmployees.0`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => {
						const employeeContractAssignDate = form?.watch(
							`services.${index}.associates.contractEmployees.0.employeeContractAssignDate`,
						);
						return (
							<>
								<CRInputLabel type='left' label='계약일' isRequired showOverflow>
									<CRInput.DatePicker
										placeholder='계약일'
										value={
											employeeContractAssignDate
												? dayjs(employeeContractAssignDate).toDate()
												: undefined
										}
										maxDate={
											form.watch(`services.${index}.use.sameDayContractYn`)
												? dayjs(form.watch(`services.${index}.use.serviceStartDate`)).toDate()
												: dayjs(form.watch(`services.${index}.use.serviceStartDate`))
														.subtract(1, 'day')
														.toDate()
										}
										onChangeValue={(date) =>
											onChange({
												...value,
												employeeContractAssignDate: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
											})
										}
										disabled
									/>
								</CRInputLabel>
								<CRInputLabel type='left' label='직원 정보' isRequired showOverflow>
									<CRCardFormLayout label='직원' isRequired>
										<EmployeeDuplicationCheckForm
											data={{ rsdn: value?.employeeRsdnNo, name: value?.employeeNm }}
											onChange={(data) =>
												onChange({ ...value, employeeNm: data.name, employeeRsdnNo: data.rsdn })
											}
											disabled
										/>
									</CRCardFormLayout>
								</CRInputLabel>
							</>
						);
					}}
				/>
			</S.SectionContainer>
		</S.Container>
	);
}

export default RenewalVisitCareContractForm;
