import styled, { css } from 'styled-components';

export const Container = styled.div<{
	$isDisabled?: boolean;
}>`
	width: 100%;
	position: relative;
	cursor: ${(props) => (props.$isDisabled ? 'no-drop' : 'pointer')};
`;

export const ContentContainer = styled.div<{
	$hasValue?: boolean;
	$hasLabel?: boolean;
	$hasError: boolean;
	$activeColor?: boolean;
	$type?: 'default' | 'small' | 'tableHeader';
	$isDisabled?: boolean;
}>`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: left;
	height: 5.6rem;
	padding: 0 1.8rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: ${(props) => props.theme.radius.radius01};
	background: ${(props) => props.theme.colors.gray100};
	margin: ${(props) => (props.$hasLabel ? '0.4rem 0' : '0rem')};

	${(props) =>
		props.$type === 'small' &&
		css`
			${(props) => props.theme.typography.label};
			padding: 0 0.8rem;
			height: 3.2rem;
			border-radius: 0.4rem;
		`};
	${(props) =>
		props.$type === 'tableHeader' &&
		css`
			padding: 0 0.8rem;
			height: 3.2rem;
			border-radius: 0.4rem;
		`};

	${(props) =>
		props.$hasValue
			? css`
					color: ${props.theme.colors.gray10};
				`
			: css`
					color: ${props.theme.colors.gray60};
				`}

	${(props) =>
		props.$activeColor &&
		css`
			background: ${(props) => props.theme.colors.primary95};
			border: 0.1rem solid ${(props) => props.theme.colors.primary90};
		`}

	${(props) =>
		props.$hasError &&
		css`
			border: 0.2rem solid ${(props) => props.theme.colors.primary60};
			background: ${(props) => props.theme.colors.primary99};
		`};

	${(props) =>
		props.$isDisabled &&
		css`
			background: ${(props) => props.theme.colors.gray95};
			border: none;
			cursor: no-drop;
		`}
`;

export const Content = styled.div`
	${(props) => props.theme.typography.label}
	width:100%;
	height: 5.6rem;
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const TextBox = styled.div<{ $disabled?: boolean }>`
	${(props) => props.theme.typography.body};

	color: ${(props) => props.theme.colors.gray10};
	${(props) =>
		props.$disabled &&
		css`
			color: ${(props) => props.theme.colors.gray60};
		`};
`;

export const PlaceHolder = styled.div<{ $type: 'default' | 'small' | 'tableHeader' }>`
	/* ${(props) => props.theme.typography.label}; */
	color: ${(props) => props.theme.colors.gray60};

	${(props) =>
		props.$type === 'small' &&
		css`
			${(props) => props.theme.typography.label};
		`};
`;

export const UnfoldMoreIcon = styled.img`
	width: 2rem;
	height: 2rem;
	filter: invert(63%) sepia(4%) saturate(196%) hue-rotate(149deg) brightness(91%) contrast(87%);
`;

export const Icon = styled.img<{ $disabled?: boolean }>`
	width: 2rem;
	height: 2rem;

	${(props) => props.$disabled && props.theme.filters.gray60};
`;

export const Item = styled.div<{ $disabled: boolean; $type: 'default' | 'small' | 'tableHeader' }>`
	flex: 1;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	${(props) =>
		props.$disabled &&
		css`
			color: ${(props) => props.theme.colors.gray60};
		`};

	${(props) =>
		props.$type === 'small' &&
		css`
			font-size: 1.4rem;
		`}
`;

export const TextInput = styled.input<{
	focused?: boolean;
	$disabled?: boolean;
	$type?: 'default' | 'small' | 'tableHeader';
}>`
	${(props) => props.theme.typography.body};
	cursor: text;
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: ${(props) => props.theme.colors.gray10};
	padding: 0;
	margin: 0;
	border: none;
	vertical-align: middle;
	background: transparent;
	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
	}

	&:disabled {
		color: ${(props) => props.theme.colors.gray60};
	}

	&:focus {
		outline: none;
	}

	${(props) =>
		props.$disabled &&
		css`
			color: ${(props) => props.theme.colors.gray60};
		`}

	${(props) =>
		props.$type === 'small' &&
		css`
			font-size: 1.4rem;
		`};
`;

export const ItemListContainer = styled.ul<{
	$type?: 'default' | 'small' | 'tableHeader';
	$isEmpty: boolean;
}>`
	display: flex;
	position: absolute;
	z-index: 100;
	flex-direction: column;
	width: 100%;
	min-width: 10rem;
	max-height: 26rem;
	overflow-y: overlay;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: ${(props) => props.theme.radius.radius01};
	background: ${(props) => props.theme.colors.gray100};
	${(props) =>
		props.$type === 'tableHeader' &&
		css`
			width: 16rem;
		`};
	${(props) =>
		props.$type === 'small' &&
		css`
			max-height: 20rem;
		`};
	${(props) =>
		props.$isEmpty &&
		css`
			border: none;
		`};
`;

export const ItemContainer = styled.li<{
	$isActive?: boolean;
	$type: 'default' | 'small' | 'tableHeader';
}>`
	${(props) => props.theme.typography.label}
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 5.6rem;
	width: 100%;
	padding: 0 1.8rem;
	box-sizing: border-box;
	white-space: nowrap;

	overflow: hidden;
	/* max-width: 30rem; */

	&:hover {
		background: ${(props) => props.theme.colors.gray99};
	}

	${(props) =>
		props.$type === 'tableHeader' &&
		css`
			font-size: 1.6rem;
		`};

	${(props) =>
		props.$type === 'small' &&
		css`
			white-space: break-spaces;
			text-align: left;
		`};

	${(props) =>
		props.$isActive &&
		css`
			background: ${props.theme.colors.gray99};
		`}
`;

export const HelperText = styled.div<{ status: 'default' | 'error' }>`
	${(props) => props.theme.typography.label};
	color: ${(props) =>
		props.status === 'default' ? props.theme.colors.gray60 : props.theme.colors.primary60};
`;
