import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { EmployeeContractReviewFormFields } from 'components/domain/sideModal/EmployeeContractReviewSideModal/types';
import { DEVICE_REGISTRATION_STATUS_CONFIG } from 'components/domain/table/EmployeeContractManagementTable/constant';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { CRStatusConfig } from 'types/view/base';
import { EmployeeContractReviewListViewType } from 'types/view/contractReview';

import { COMPLETION_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: EmployeeContractReviewListViewType;
}

function EmployeeLongtermInfo({ data }: Props): React.ReactElement {
	const { control } = useFormContext<EmployeeContractReviewFormFields>();
	const { data: commonCodes = { CMN217: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN217'] },
		commonCodeAdapter,
	);

	const renderConfigStatus = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || item.value === value);

		return (
			<CRStatus type='pill' options={config}>
				{currentValue?.label}
			</CRStatus>
		);
	}, []);

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						배상책임보험
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.isLiabilityInsuranceRegistrationCompleted ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<S.LabelValueContainer>
					<S.LabelContainer>
						<CRText typography='body' text='대상' />
					</S.LabelContainer>
					<S.ValueContainer>
						<CRText
							color={data.isLiabilityInsuranceRequired ? 'primary60' : 'gray00'}
							text={data.isLiabilityInsuranceRequired ? '가입필요' : '해당 없음'}
						/>
					</S.ValueContainer>
				</S.LabelValueContainer>
				<CRInputLabel label={<CRText typography='body' text='가입여부' />} type='left'>
					<Controller
						control={control}
						name='liabilityInsuranceSubscriptionYn'
						render={({ field: { ...rest } }) => (
							<CRCheckBox
								checkBoxType='checkbox'
								appearanceType='button'
								checked={rest.value}
								onChange={rest.onChange}>
								<CRText>공단 가입 완료</CRText>
							</CRCheckBox>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>

			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						단말기 번호 등록
						{renderConfigStatus(
							DEVICE_REGISTRATION_STATUS_CONFIG,
							data.deviceRegistrationRegistrationStatus,
						)}
					</S.CardLabelContainer>
				}>
				<S.LabelValueContainer>
					<S.LabelContainer>
						<CRText typography='body' text='직원 전화번호' />
					</S.LabelContainer>
					<S.ValueContainer>
						<CRText>
							{data.data.tagMobilePhoneNo
								? data.data.tagMobilePhoneNo.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
								: '-'}
						</CRText>
					</S.ValueContainer>
				</S.LabelValueContainer>
				<CRInputLabel label={<CRText typography='body' text='등록 여부' />} type='left'>
					<Controller
						control={control}
						name='pcorpTagRegistrationCd'
						render={({ field: { ...rest } }) => (
							<CRInput.Selector
								items={commonCodes.CMN217}
								currentValue={commonCodes.CMN217.find((item) => item.value === rest.value)}
								onChangeValue={(value) => rest.onChange(value.value)}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default EmployeeLongtermInfo;
