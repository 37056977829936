import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	height: calc(100vh - 21.7rem);
	padding: 0 2.4rem 2.4rem;
	overflow-y: scroll;
	box-sizing: border-box;
	padding: 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-bottom: 0.1rem solid ${(props) => props.theme.colors.gray90};
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem;
	box-sizing: border-box;
`;

export const LisenseContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	padding: 0 2.4rem 2.4rem;
	overflow-y: scroll;
	box-sizing: border-box;
`;
