import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import RouterPath from 'common/router';
import CRButton from 'components/base/CRButton';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import { MonitoringCompleteStatusDialog } from 'components/domain/dialog/MonitoringCompleteStatusDialog';
import MonitoringTable from 'components/domain/table/MonitoringTable';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import { useCenterManagers, useCommonCodes, useMyAccountInfo } from 'lib/hook/react-query';
import { useMonitoringSchedules } from 'lib/hook/react-query/query/monitoring';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { useHasPermission } from 'lib/hook/util/useHasPermission';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { useScroll } from 'lib/hook/util/useScroll';
import { MonitoringDTO, UpdateMonitoringCompleteStatusRequest } from 'types/api';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

import * as S from './styles';

interface Props {
	date: Date;
	allDataSyncDate?: string;
	onChangeDate: (date: Date) => void;
}

export default function MonitoringTab({ date, allDataSyncDate, onChangeDate }: Props) {
	const hasConfirmMonitoringFunc = useHasFunc(['monitoring:confirm_monitoring']);
	const { elementRef, saveScrollPosition } = useScroll();
	const [isOpen, setOpen] = useState(false);
	const [keyword, setKeyword] = useState('');
	const { filters, setFilters, pageInfo, setPageInfo } = usePageFilter({});
	const [searchValue, setSearchValue] = useState('');
	const { data: commonCodes } = useCommonCodes({ comCdGroupNms: ['CMN083', 'CMN171', 'CMN006'] });
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});
	const navigate = useNavigate();
	const hasPermission = useHasPermission('센터장');
	const { showDialog } = useDialog();

	const {
		data: monitoringSchedules,
		isLoading: monitoringSchedulesLoading,
		refetch,
	} = useMonitoringSchedules({
		centerId: myAccountInfo?.centerId,
		serviceYm: dayjs(date).startOf('M').format('YYYYMM'),
		state: filters?.statusFilter?.length === 1 ? filters?.statusFilter?.[0].value : null,
		completeYn:
			filters?.completeYnFilter?.length === 1 ? filters?.completeYnFilter?.[0].value : null,
		confirmYn:
			filters?.workConfirmYnFilter?.length === 1 ? filters?.workConfirmYnFilter?.[0].value : null,
		serviceTypeCds: filters?.serviceTypeFilter?.map((item) => item.value),
		serviceKindCds: filters?.serviceKindFilter?.map((item) => item.value),
		managerIds: filters?.managerFilter?.map((item) => item?.value),
		workConfirmYn:
			filters?.workConfirmYnFilter?.length === 1 ? filters?.workConfirmYnFilter?.[0].value : null,
		workDuplConfirmYn:
			filters?.workDuplConfirmYn?.length === 1 ? filters?.workDuplConfirmYn?.[0].value : null,
		dataCreationYn:
			filters?.dataCreationYnFilter?.length === 1 ? filters?.dataCreationYnFilter?.[0].value : null,
		page: pageInfo.page,
		size: pageInfo.size,
		keyword,
	});

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleChangeDate = (date: Date) => {
		onChangeDate(date);
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
	};

	const serviceKindFilter = useMemo(
		() =>
			({
				key: 'serviceKindFilter',
				type: 'multi',
				placeholder: '급여 유형 상세',
				options: (commonCodes || [])
					?.filter((item) => item.comCdGroupNm === 'CMN083')
					?.map((code) => ({
						label: code.korComCdAliasNm,
						value: code.comCdId,
					})),
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const serviceTypeFilter = useMemo(
		() =>
			({
				key: 'serviceTypeFilter',
				type: 'multi',
				placeholder: '급여 유형',
				options: (commonCodes || [])
					?.filter((item) => item.comCdGroupNm === 'CMN006' && item.etcDesc1 === 'Y')
					?.map((code) => ({
						label: code.korComCdAliasNm,
						value: code.comCdId,
					})),
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const statusFilter = useMemo(
		() =>
			({
				key: 'statusFilter',
				type: 'multi',
				placeholder: '상태',
				options: [
					{
						label: '미완료',
						value: false,
					},
					{
						label: '완료',
						value: true,
					},
				],
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const workConfirmYnFilter = useMemo(
		() =>
			({
				key: 'workConfirmYnFilter',
				type: 'multi',
				options: [
					{
						label: '미확인',
						value: false,
					},
					{
						label: '확인 완료',
						value: true,
					},
				],
				placeholder: '근무 확인',
			}) as Filter<CheckOption>,
		[],
	);

	const workDuplConfirmYn = useMemo(
		() =>
			({
				key: 'workDuplConfirmYn',
				type: 'multi',
				options: [
					{
						label: '미확인',
						value: false,
					},
					{
						label: '확인 완료',
						value: true,
					},
				],
				placeholder: '일정 중복 확인',
			}) as Filter<CheckOption>,
		[],
	);

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options:
					centerManagers?.map((item) => ({
						label: item.userNm,
						value: item.memberAccountId,
					})) ?? [],
				placeholder: '담당자',
			}) as Filter<CheckOption>,
		[centerManagers],
	);

	// 확정 상태(여부)
	const completeYnFilter = useMemo(
		() =>
			({
				key: 'completeYnFilter',
				type: 'multi',
				placeholder: '확정 상태',
				options: [
					{
						label: '대기',
						value: false,
					},
					{
						label: '완료',
						value: true,
					},
				],
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	// 자료생성 여부
	const dataCreationYnFilter = useMemo(
		() =>
			({
				key: 'dataCreationYnFilter',
				type: 'multi',
				placeholder: '자료생성 여부',
				options: [
					{
						label: '미완료',
						value: false,
					},
					{
						label: '완료',
						value: true,
					},
				],
			}) as Filter<CheckOption>,
		[commonCodes],
	);

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>일정 내역이 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					해당 월 데이터가 아직 갱신되지 않았습니다.
					<br />
					공단 데이터 불러오기 후, 데이터 갱신 버튼을 눌러주세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	const handleSearchValue = (value: string) => setSearchValue(value);

	const monitoringScheedulesData = useMemo(
		() => monitoringSchedules?.content ?? [],
		[monitoringSchedules],
	);

	const handleRefresh = () => {
		refetch();
	};

	const handleClickRow = (item: MonitoringDTO) => {
		saveScrollPosition();
		navigate(
			`${RouterPath.scheduleOperate().monitoring.key}/detail?centerId=${item.centerId}&serviceYm=${
				item.serviceYm
			}&recipientId=${item.recipientId}&employeeId1=${item.employeeId1}&serviceTypeCd=${
				item.serviceTypeCd
			}${item.employeeId2 ? `&employeeId2=${item.employeeId2}` : ''}`,
		);
	};

	const onClickConfirm = (completeYn: boolean) => {
		if (!myAccountInfo?.centerId) return;

		const title = `확정 ${completeYn ? '완료' : '대기'}로 변경`;
		const content = `${dayjs(date).format('YYYY년 M월')} 일정 전체를 확정${
			!completeYn ? ' 취소' : ''
		}합니다`;

		const param: UpdateMonitoringCompleteStatusRequest = {
			centerId: myAccountInfo.centerId,
			serviceYm: dayjs(date).format('YYYYMM'),
			completeYn,
		};

		setOpen(false);
		showDialog(() => (
			<MonitoringCompleteStatusDialog title={title} content={content} param={param} />
		));
	};

	useEffect(() => {
		if (monitoringSchedules?.totalPages !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: monitoringSchedules?.totalPages || 0,
			}));
		}
	}, [monitoringSchedules?.totalPages]);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setFilters((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo]);

	return (
		<CRTable.BackBlind>
			<S.HeaderContainer>
				<CRTableMonthSelector value={date} onChange={handleChangeDate} />
				<S.TitleRightContainer>
					<S.SyncTime>
						{allDataSyncDate
							? `${dayjs(allDataSyncDate).format('YYYY.MM.DD HH:mm')} 전체 데이터 갱신 완료`
							: ''}
					</S.SyncTime>
					{hasPermission && (
						<RdPopover
							showDivider={false}
							side='right'
							align='start'
							borderShape='small'
							sideOffset={-130}
							alignOffset={100}
							isOpen={isOpen}
							setOpen={setOpen}
							content={
								<>
									<S.DropdownItem onClick={() => onClickConfirm(true)}>
										<S.Circle $color={Colors.green} /> 완료로 변경
									</S.DropdownItem>
									<S.DropdownItem onClick={() => onClickConfirm(false)}>
										<S.Circle $color={Colors.gray90} />
										대기로 변경
									</S.DropdownItem>
								</>
							}>
							<CRButton.Default
								disabled={!hasConfirmMonitoringFunc}
								style={{
									display: 'flex',
									gap: '0.8rem',
									padding: '0.6rem 1.6rem 0.6rem 2.4rem',
								}}>
								<CRText typography='label' color='gray100' text='전체 확정' />
								<S.ArrowIcon
									src={isOpen ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom}
								/>
							</CRButton.Default>
						</RdPopover>
					)}
				</S.TitleRightContainer>
			</S.HeaderContainer>
			<S.TableContainer>
				<CRTableHeader
					onChangePageInfo={handleChangePageInfo}
					pageInfo={pageInfo}
					currentFilter={filters}
					stickyMode
					filters={[
						statusFilter,
						completeYnFilter,
						serviceTypeFilter,
						serviceKindFilter,
						managerFilter,
						workConfirmYnFilter,
						workDuplConfirmYn,
						dataCreationYnFilter,
					]}
					searchValue={searchValue}
					placeholder='수급자명, 직원명으로 검색'
					onChangeSearchValue={handleSearchValue}
					setCurrentFilter={setFilters}
					onSearch={handleSearch}
					onRefresh={handleRefresh}
					showRefresh
					showViewCount
				/>
				<S.TableScrollContainer ref={elementRef}>
					{monitoringSchedulesLoading ? (
						<CRSpinner />
					) : monitoringScheedulesData && monitoringScheedulesData?.length > 0 ? (
						<MonitoringTable items={monitoringScheedulesData} onClickRow={handleClickRow} />
					) : (
						renderEmpty
					)}
				</S.TableScrollContainer>
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}
