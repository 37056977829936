type DefaultAdditionalInfoKey = keyof typeof defaultAdditionalInfo;
export const defaultAdditionalInfo = {
	licenseType: '요양보호사1급', // (입사) 자격증 유형 기본값
	jobCategory: '요양보호사 1급', // (입사) 직종 기본값
	salaryType: undefined, // (입사) 급여유형 기본값
	dementiaEduYn: '아니오', // (입사) 치매교육여부 기본값
	workType: '시간제', // (입사) 근무형태 기본값
	employmentType: '정규직', // (입사) 고용구분 기본값
	deptNm: '기타', // (입사) 부서명 기본값
	regionJobCategory: '요양보호사', // (입사) 시군구 직종 기본값
	position: '요양보호사', // (입사) 직급 기본값
	recruitmentType: '공채', // (입사) 채용구분 기본값
	employeeStatus: '정상', // (입사) 직원상태 기본값 -> (퇴사)는 '퇴직'으로 변경
	updateContent: '근로자 입사 신고', // (입사) 변경내용 기본값 -> (퇴사)는 '근로자 퇴사 신고'로 변경)
	updateReason: '퇴사보고', // (퇴사) 인력변경사유 기본값
	updateReasonDetail: '퇴사보고', // (퇴사) 인력변경사유 상세 기본값
	autoSsisYn: true,
};

// 입사(급여추가) 및 퇴사 기본 정보
export const defaultAdditionalInfoArray: {
	type: string;
	info: { key: DefaultAdditionalInfoKey; value: string | undefined }[];
}[] = [
	{
		type: '급여추가',
		info: [
			{ key: 'licenseType', value: '요양보호사1급' },
			{ key: 'jobCategory', value: '요양보호사 1급' },
			{ key: 'salaryType', value: undefined },
			{ key: 'dementiaEduYn', value: '아니오' },
			{ key: 'workType', value: '시간제' },
			{ key: 'position', value: '요양보호사' },
			{ key: 'employmentType', value: '정규직' },
			{ key: 'deptNm', value: '기타' },
			{ key: 'updateContent', value: '근로자 입사 신고' },
			{ key: 'updateReason', value: undefined },
			{ key: 'updateReasonDetail', value: undefined },
			{ key: 'employeeStatus', value: '정상' },
			{ key: 'regionJobCategory', value: '요양보호사' },
			{ key: 'recruitmentType', value: '공채' },
		],
	},
	{
		type: '입사',
		info: [
			{ key: 'licenseType', value: '요양보호사1급' },
			{ key: 'jobCategory', value: '요양보호사 1급' },
			{ key: 'salaryType', value: undefined },
			{ key: 'dementiaEduYn', value: '아니오' },
			{ key: 'workType', value: '시간제' },
			{ key: 'position', value: '요양보호사' },
			{ key: 'employmentType', value: '정규직' },
			{ key: 'deptNm', value: '기타' },
			{ key: 'updateContent', value: '근로자 입사 신고' },
			{ key: 'updateReason', value: undefined },
			{ key: 'updateReasonDetail', value: undefined },
			{ key: 'employeeStatus', value: '정상' },
			{ key: 'regionJobCategory', value: '요양보호사' },
			{ key: 'recruitmentType', value: '공채' },
		],
	},
	{
		type: '퇴사',
		info: [
			{ key: 'licenseType', value: undefined },
			{ key: 'jobCategory', value: undefined },
			{ key: 'salaryType', value: undefined },
			{ key: 'dementiaEduYn', value: undefined },
			{ key: 'workType', value: undefined },
			{ key: 'position', value: undefined },
			{ key: 'employmentType', value: undefined },
			{ key: 'deptNm', value: undefined },
			{ key: 'updateContent', value: '근로자 퇴사 신고' },
			{ key: 'updateReason', value: '퇴사보고' },
			{ key: 'updateReasonDetail', value: '퇴사보고' },
			{ key: 'employeeStatus', value: '퇴직' },
			{ key: 'regionJobCategory', value: undefined },
			{ key: 'recruitmentType', value: undefined },
		],
	},
];
