import React from 'react';
import { Control, Controller } from 'react-hook-form';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { CreateEmployReportForm } from 'types/view/employReport';

interface Props {
	control: Control<CreateEmployReportForm>;
}

function EmploymentReportAdditionalInfoForm({ control }: Props): React.ReactElement {
	const renderErrorMessage = (error: boolean, message: string) =>
		error ? (
			<CRText color='primary60' typography='label'>
				{message}
			</CRText>
		) : null;

	return (
		<CRCardFormLayout label='기타 정보'>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='채용구분'
						message={renderErrorMessage(
							!!errors.recruitmentType,
							errors.recruitmentType?.message ?? '',
						)}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='채용구분 선택'
							status={errors.recruitmentType ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='recruitmentType'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='직원상태'
						message={renderErrorMessage(
							!!errors.employeeStatus,
							errors.employeeStatus?.message ?? '',
						)}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='직원상태 선택'
							status={errors.employeeStatus ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='employeeStatus'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='시군구직종'
						message={renderErrorMessage(
							!!errors.regionJobCategory,
							errors.regionJobCategory?.message ?? '',
						)}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='지역직종 선택'
							status={errors.regionJobCategory ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='regionJobCategory'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='직종'
						message={renderErrorMessage(!!errors.jobCategory, errors.jobCategory?.message ?? '')}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='직종 선택'
							status={errors.jobCategory ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='jobCategory'
				control={control}
			/>
			{/* <Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='급여유형'
						message={renderErrorMessage(!!errors.salaryType, errors.salaryType?.message ?? '')}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='급여유형 선택'
							status={errors.salaryType ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='salaryType'
				control={control}
			/> */}
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='치매교육여부'
						message={renderErrorMessage(
							!!errors.dementiaEduYn,
							errors.dementiaEduYn?.message ?? '',
						)}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='치매교육여부 선택'
							status={errors.dementiaEduYn ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='dementiaEduYn'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='근무형태'
						message={renderErrorMessage(!!errors.workType, errors.workType?.message ?? '')}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='근무형태 선택'
							status={errors.workType ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='workType'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='직위'
						message={renderErrorMessage(!!errors.position, errors.position?.message ?? '')}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='직급 선택'
							status={errors.position ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='position'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='고용형태'
						message={renderErrorMessage(
							!!errors.employmentType,
							errors.employmentType?.message ?? '',
						)}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='고용형태 선택'
							status={errors.employmentType ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='employmentType'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='부서명'
						message={renderErrorMessage(!!errors.deptNm, errors.deptNm?.message ?? '')}>
						<CRInput.Default
							value={value}
							onChange={onChange}
							placeholder='부서명 선택'
							status={errors.deptNm ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='deptNm'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='변경내용'
						message={renderErrorMessage(
							!!errors.updateContent,
							errors.updateContent?.message ?? '',
						)}>
						<CRInput.TextArea
							value={value}
							onChange={onChange}
							placeholder='변경내용 입력'
							numberOfLines={1}
							status={errors.updateContent ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='updateContent'
				control={control}
			/>
		</CRCardFormLayout>
	);
}

export default EmploymentReportAdditionalInfoForm;
