import useCRQuery from 'lib/hook/react-query/base/useCRQuery';
import { endpoint } from 'lib/service/Api/endpoint';

export const useEmployeeContractList = useCRQuery(
	endpoint.getEmployeeContractReviewList.key,
	'getEmployeeContractReviewList',
);

export const useRecipientContractList = useCRQuery(
	endpoint.getRecipientContractReviewList.key,
	'getRecipientContractReviewList',
);
