import React, {
	ChangeEvent,
	FocusEvent,
	FocusEventHandler,
	ForwardedRef,
	HTMLInputTypeAttribute,
	KeyboardEvent,
	KeyboardEventHandler,
	forwardRef,
	useState,
} from 'react';

import { displayComma } from 'lib';

import * as S from './styles';

interface IProps {
	type?: HTMLInputTypeAttribute | 'comma';
	value?: string | number;
	id?: string;
	min?: number | string;
	max?: number | string;
	addOnBottom?: string;
	status?: 'default' | 'error';
	disabled?: boolean;
	maxLength?: number;
	placeholder?: string;
	onChange?: (...event: any[]) => void;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
	formatter?: (e: ChangeEvent<HTMLInputElement>) => string;
	prefix?: string;
	suffix?: React.ReactNode;
	inputMode?: 'numeric' | 'decimal' | 'text' | 'tel' | 'search' | 'email' | 'url';
}

function CRInputDefault(
	{
		type = 'text',
		value,
		id,
		min = 0,
		max = 999999999,
		status = 'default',
		maxLength = 524288,
		addOnBottom = '',
		disabled = false,
		placeholder = '',
		onChange,
		onBlur,
		onPressEnter,
		formatter,
		prefix,
		suffix,
		inputMode,
	}: IProps,
	ref: ForwardedRef<HTMLInputElement>,
) {
	const [isFocused, setIsFocused] = useState(false);
	const onFocus = () => setIsFocused(true);
	const handleBlur = (e: FocusEvent<HTMLInputElement, Element>) => {
		setIsFocused(false);
		onBlur?.(e);
	};

	const formatComma = (value: string | number | undefined): string | undefined => {
		if (value === undefined) return undefined;
		if (typeof value === 'number') return formatComma(value.toString());
		if (['', '-'].includes(value)) return undefined;

		const isMinus = value.replace(/[^-]/g, '').length === 1;
		const number = value.replace(/[^\d]/g, '');
		const commaString = isMinus ? `-${displayComma(number)}` : displayComma(number);

		return commaString;
	};

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > maxLength) return;
		if (type === 'comma') {
			const { value } = e.target;

			if (['-'].includes(value)) {
				onChange?.(undefined);
				return;
			}
			if ([''].includes(value)) {
				onChange?.('');
				return;
			}

			const isMinus = value.replace(/[^-]/g, '').length === 1;
			const number = isMinus
				? -Number(value.replace(/[^\d]/g, ''))
				: Number(value.replace(/[^\d]/g, ''));

			if (min !== undefined && number < Number(min)) {
				onChange?.(formatComma(min));
				return;
			}
			if (max !== undefined && number > Number(max)) {
				onChange?.(formatComma(max));
				return;
			}

			onChange?.(formatComma(e.target.value));
			return;
		}

		onChange?.(formatter ? formatter(e) : e.target.value);
	};

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onPressEnter?.(e);
		}
	};

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if ((e.ctrlKey || e.metaKey) && ['v', 'c'].includes(e.key)) {
			return;
		}
		if (type === 'number' && ['e', 'E', '+', '-', 'ArrowUp', 'ArrowDown', '.'].includes(e.key)) {
			e.preventDefault();
		}
		if (type === 'comma') {
			if (
				![
					'0',
					'1',
					'2',
					'3',
					'4',
					'5',
					'6',
					'7',
					'8',
					'9',
					'Backspace',
					'ArrowLeft',
					'ArrowRight',
					'Tab',
					'-',
				].includes(e.key)
			) {
				e.preventDefault();
			}
		}
	};

	const numberInputOnWheelPreventChange = (e) => {
		// Prevent the input value change
		e.target.blur();
		// Prevent the page/container scrolling
		e.stopPropagation();
	};

	const handleChangeStartTime = (event: ChangeEvent<HTMLInputElement>) => {
		const numericOnly = event.target.value.replace(/\D/g, '');
		let formattedTime = numericOnly;
		if (numericOnly.length > 2) {
			formattedTime = `${numericOnly.substring(0, 2)}:${numericOnly.substring(2)}`;
		}
		// 시간 제한: 24시 이하, 분 제한: 60분 이하
		if (parseInt(numericOnly.substring(0, 2), 10) > 23) {
			formattedTime = '00:';
		}
		if (parseInt(numericOnly.substring(2), 10) > 59) {
			formattedTime = `${numericOnly.substring(0, 2)}:00`;
		}
		onChange?.(formattedTime);
	};

	const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		if (type === 'number') {
			e.preventDefault();
			const clipboardString = e.clipboardData.getData('text');
			const numericValue = clipboardString.replace(/\D/g, '');
			handleChange({
				target: {
					value: numericValue,
				},
			} as any);
		}
	};

	const inputType = type === 'time' || type === 'comma' ? 'text' : type;

	const wrappedValue = type === 'comma' ? formatComma(value as string) : value;

	return (
		<S.Container $isDisabled={disabled}>
			<S.InputContainer $isFocused={isFocused} $error={status === 'error'} disabled={disabled}>
				{prefix && <S.PrefixText>{prefix}</S.PrefixText>}
				<input
					style={{ textAlign: prefix ? 'right' : 'left' }}
					type={inputType}
					inputMode={inputMode}
					min={min}
					ref={ref}
					id={id}
					value={wrappedValue}
					disabled={disabled}
					maxLength={maxLength}
					placeholder={placeholder}
					onBlur={handleBlur}
					onFocus={onFocus}
					onChange={type === 'time' ? handleChangeStartTime : handleChange}
					onKeyDown={onKeyDown}
					onKeyUp={onKeyUp}
					onWheel={numberInputOnWheelPreventChange}
					onPaste={handlePaste}
				/>
				{suffix && <S.SuffixText>{suffix}</S.SuffixText>}
			</S.InputContainer>
			<S.HelperText status={status}>{addOnBottom}</S.HelperText>
		</S.Container>
	);
}

export default forwardRef(CRInputDefault);
