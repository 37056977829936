import styled, { RuleSet, css } from 'styled-components';

import { InputStatus } from '../type';

type InputAttrObj = {
	[key in InputStatus]: RuleSet<object>;
};

const inputAttr: InputAttrObj = {
	default: css`
		box-shadow: 0 0 0 0.1rem ${(props) => props.theme.colors.gray90} inset;
		background: ${(props) => props.theme.colors.gray100};
	`,
	focus: css`
		/* box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.gray10} inset; */
		background: ${(props) => props.theme.colors.gray99};
	`,
	error: css`
		box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colors.primary60} inset;
		background: ${(props) => props.theme.colors.primary99};
	`,
	disabled: css`
		background: ${(props) => props.theme.colors.gray95};
	`,
};

export const Container = styled.div<{ $isDisabled?: boolean }>`
	width: 100%;
	position: relative;
	cursor: ${(props) => (props.$isDisabled ? 'no-drop' : 'pointer')};
	pointer-events: ${(props) => (props.$isDisabled ? 'none' : 'auto')};
`;

export const DisplayInput = styled.div`
	width: 100%;
	height: 3.3rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.4rem;
	/* padding: 0.4rem 0.8rem; */
`;

export const InputContainer = styled.div<{
	$isFocused?: boolean;
	$error?: boolean;
	disabled?: boolean;
}>`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0.4rem 0.9rem;
	height: 3.3rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray100};
	border-radius: 0.4rem;
	margin: 0.4rem 0;
	gap: 0.8rem;
	justify-content: space-between;

	& > input {
		${(props) => props.theme.typography.label};
		color: ${(props) => (!props.$error ? props.theme.colors.gray10 : props.theme.colors.gray10)};
		padding: 0;
		width: 100%;
		min-height: 2.8rem;
		background: transparent;
		border: none;
		outline: none;
		cursor: inherit;

		&::placeholder {
			${(props) => props.theme.typography.body};
			color: ${(props) => props.theme.colors.gray60};
		}
	}

	${inputAttr.default};
	${(props) => props.$isFocused && inputAttr.focus};
	${(props) => props.$error && inputAttr.error};
	${(props) => props.disabled && inputAttr.disabled};

	& > img {
		filter: ${(props) => props.theme.filters.gray60};
	}
`;

export const DropdownContainer = styled.div`
	position: absolute;
	width: 100%;
	top: 3.3rem;
	right: 0;
	min-width: 28rem;
	z-index: 10;
`;
export const UnfoldMoreIcon = styled.img`
	width: 2rem;
	height: 2rem;
	filter: invert(63%) sepia(4%) saturate(196%) hue-rotate(149deg) brightness(91%) contrast(87%);
`;

export const Icon = styled.img`
	width: 2rem;
	height: 2rem;
	${(props) => props.theme.filters.gray60};
`;

export const SearchDropDownContainer = styled.div<{ $tableInput: boolean }>`
	position: absolute;
	left: 0;
	top: 5.3rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
	z-index: 99;

	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0 0 0.8rem 0.8rem;
	overflow: hidden;

	${(props) =>
		props.$tableInput &&
		css`
			top: 3.3rem;
		`};
`;

export const SearchContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0rem 1.6rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray99};
	border-bottom: 1px solid ${(props) => props.theme.colors.gray90};
`;

export const SearchIcon = styled.img`
	${(props) => props.theme.filters.gray60}
	width: 2.4rem;
	height: 2.4rem;
	margin-right: 0.8rem;
`;

export const SearchInput = styled.input`
	${(props) => props.theme.typography.body};
	flex: 1;
	padding: 2.4rem 0rem;
	box-sizing: border-box;
	border: none;
	outline: none;
	background: none;
	color: ${(props) => props.theme.colors.gray10};

	&::placeholder {
		color: ${(props) => props.theme.colors.gray60};
	}
`;

export const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.8rem 1.6rem;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray100};
	gap: 0.8rem;
`;

export const SearchResultContainer = styled.ul<{ $tableInput: boolean }>`
	padding: 0.8rem 0;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.colors.gray100};

	max-height: 25rem;
	min-height: 5rem;

	box-sizing: border-box;
	overflow: auto;
	${(props) =>
		props.$tableInput &&
		css`
			padding: 0.3rem 0;
		`};
`;

export const SearchResultTitle = styled.div<{ $tableInput: boolean }>`
	${(props) => props.theme.label}
	color: ${(props) => props.theme.colors.gray60};
	padding: 0.5rem 1.6rem 0;

	box-sizing: border-box;
	${(props) =>
		props.$tableInput &&
		css`
			font-size: 1.4rem;
		`};
`;

export const SearchResultEmptyListContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	box-sizing: border-box;
`;

export const SearchResultEmptyListDescription = styled.div<{ $tableInput: boolean }>`
	/* ${(props) => props.theme.body} */
	color: ${(props) => props.theme.colors.gray60};

	${(props) => (props.$tableInput ? props.theme.typography.label : props.theme.typography.body)}
`;

export const SearchResultListContainer = styled.ul`
	display: flex;
	flex-direction: column;
`;

export const SearchResultItemContainer = styled.li<{ $tableInput: boolean }>`
	height: 4.8rem;
	display: flex;
	align-items: center;
	gap: 0.4rem;
	padding: 0 1.6rem;

	&:hover {
		background: ${(props) => props.theme.colors.gray99};
	}

	${(props) =>
		props.$tableInput &&
		css`
			height: 3.3rem;
		`}
`;

export const Line = styled.div`
	width: 0.1rem;
	height: 2.8rem;
	margin-right: 1.2rem;
	background-color: ${(props) => props.theme.colors.gray90};
`;

export const SearchResultItemChildContainer = styled.li`
	height: 4rem;
	display: flex;
	align-items: center;
	gap: 0.4rem;
	padding-left: 1.6rem;

	&:hover {
		background: ${(props) => props.theme.colors.gray99};
	}
`;

export const SearchResultItemOuterContainer = styled.li`
	display: flex;
	flex-direction: column;
`;

export const SearchResultItemMainContainer = styled.span<{ $tableInput: boolean }>`
	${(props) => props.theme.typography.body}
	color: ${(props) => props.theme.colors.gray10};

	${(props) =>
		props.$tableInput &&
		css`
			font-size: 1.4rem;
		`};
`;

export const SearchResultItemSubContainer = styled.span`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray60};
`;
