export default class RouterPath {
	static root() {
		return '/';
	}

	static viewPage() {
		return {
			contractSendInfo: {
				label: '수급자 계약 발송',
				key: '/contract/:contractId/send-info',
				uniqueKey: 'contract-send-info',
			},
			renewContractSendInfo: {
				label: '수급자 갱신계약 발송',
				key: '/contract-renewal/:contractId/send-info',
				uniqueKey: 'renew-contract-send-info',
			},
		};
	}

	static personalLongTermCarePlanUploaderDialogTab() {
		return {
			license: {
				key: '/personal-long-term-care-plan-uploader-dialog/license',
				label: '인정정보',
			},
			salary: {
				key: '/personal-long-term-care-plan-uploader-dialog/salary',
				label: '급여정보',
			},
			welfareEquipment: {
				key: '/personal-long-term-care-plan-uploader-dialog/welfare-equipment',
				label: '복지용구',
			},
		};
	}

	static home() {
		return '/';
	}

	static myCenter() {
		return '/my-center';
	}

	static myCenterOperate() {
		return {
			user: {
				label: '계정',
				key: '/my-center/user',
				uniqueKey: 'user',
			},
			information: {
				label: '센터 정보',
				key: '/my-center/information',
				uniqueKey: 'information',
			},
			accountManagement: {
				label: '계좌 관리',
				key: '/my-center/account-management',
				uniqueKey: 'account-management',
			},
			financeInformation: {
				label: '재무 정보',
				key: '/my-center/finance-information',
				uniqueKey: 'finance-information',
			},
			addFinanceInformation: {
				label: '재무 정보 추가',
				key: '/my-center/finance-information/new',
				uniqueKey: 'new',
			},
			updateFinanceInformation: {
				label: '재무 정보 상세',
				key: '/my-center/finance-information/detail',
				uniqueKey: 'detail',
			},
			document: {
				label: '문서',
				key: '/my-center/document',
				uniqueKey: 'document',
			},
		};
	}

	static search() {
		return '/search';
	}

	static myTask() {
		return '/my-task';
	}

	static myTaskTab() {
		return {
			progress: {
				key: '/my-task/progress',
				label: '진행중',
			},
			pending: {
				key: '/my-task/pending',
				label: '대기중',
			},
			complete: {
				key: '/my-task/complete',
				label: '완료',
			},
		};
	}

	static contractTask() {
		return '/my-task/contract-task';
	}

	static myTaskOperate() {
		return {
			contract: {
				label: '계약',
				key: 'contract/:contractId',
				uniqueKey: 'contract',
			},
			contractRenewal: {
				label: '갱신 계약',
				key: 'contract-renewal/:contractId',
				uniqueKey: 'contract-renewal',
			},
			contractChangeEmployee: {
				label: '직원 변경',
				key: 'contract-change-employee/:contractId',
				uniqueKey: 'contract-change-employee',
			},
			recipientBasic: {
				label: '수급자 정보',
				key: 'recipient-basic',
				uniqueKey: 'recipient-basic',
			},
			contractService: {
				label: '급여 정보',
				key: 'contract-service',
				uniqueKey: 'contract-service',
			},
			manager: {
				label: '담당 직원 정보',
				key: 'manager',
				uniqueKey: 'manager',
			},
			contractInspection: {
				label: '계약 점검',
				key: 'contract-inspection',
				uniqueKey: 'contract-inspection',
			},
			renewalRecipientBasic: {
				label: '수급자 정보',
				key: 'recipient-basic',
				uniqueKey: 'recipient-basic',
			},
			renewalContractService: {
				label: '급여 정보',
				key: 'contract-service',
				uniqueKey: 'contract-service',
			},
			renewalContractInspection: {
				label: '계약 점검',
				key: 'contract-inspection',
				uniqueKey: 'contract-inspection',
			},
			changeEmployeeRecipientBasic: {
				label: '수급자 정보',
				key: 'recipient-basic',
				uniqueKey: 'recipient-basic',
			},
			changeEmployeeContractService: {
				label: '급여 정보',
				key: 'contract-service',
				uniqueKey: 'contract-service',
			},
			changeEmployeeManager: {
				label: '담당 직원 정보',
				key: 'manager',
				uniqueKey: 'manager',
			},
			changeEmployeeContractInspection: {
				label: '계약 점검',
				key: 'contract-inspection',
				uniqueKey: 'contract-inspection',
			},
			schedule: {
				label: '일정등록',
				key: 'my-task/schedule/*',
			},
			registration: {
				label: '일정등록',
				key: 'my-task/schedule/registration',
				uniqueKey: 'registration',
			},
			automation: {
				label: '자동화 공단 등록',
				key: 'my-task/schedule/automation',
				uniqueKey: 'automation',
			},
			errorEdit: {
				label: '일정 오류 수정',
				key: 'my-task/schedule/error-edit',
				uniqueKey: 'error-edit',
			},
			monitoring: {
				label: '일정 모니터링',
				key: 'my-task/monitoring',
				uniqueKey: 'monitoring',
			},
			monitoringDetail: {
				label: '일정 모니터링 상세',
				key: `my-task/monitoring/:detail`,
				uniqueKey: ':detailId',
			},
			salary: {
				label: '일정 모니터링 상세',
				key: `my-task/salary`,
			},
			ownExpense: {
				label: '본인부담금',
				key: `my-task/own-expense`,
			},
			billing: {
				label: '급여 확인',
				key: `my-task/billing`,
			},
			workExecuteLogDetail: {
				label: '업무수행일지',
				key: `work-execute-log/:detail`,
				uniqueKey: 'work-execute-log-detail',
			},
			workExecuteLogNew: {
				label: '업무수행일지',
				key: `:id/work-execute-log`,
				uniqueKey: 'work-execute-log-detail',
			},
		};
	}

	static workExecuteLog() {
		return {
			tab: {
				key: ':id/work-execute-log',
				label: '업무수행일지',
				uniqueKey: 'work-execute-log',
			},
			newWorkExecuteLog: {
				label: '계약',
				key: 'new-work-execute-log',
				uniqueKey: 'new-work-execute-log',
			},
		};
	}

	static approval() {
		return '/approval';
	}

	static approvalOperate() {
		return {
			new: {
				label: '신규 문서 작성',
				key: '/approval/document/new',
				uniqueKey: 'new',
			},
			approval: {
				label: '전자결재',
				key: '/approval',
			},
			document: {
				label: '문서 작성',
				key: '/approval/document',
				uniqueKey: 'document',
			},
			detail: {
				label: '신규 문서 작성',
				key: '/approval/document/:id',
				uniqueKey: ':detailId',
			},
		};
	}

	static approvalTab() {
		return {
			progress: {
				key: '/my-task/progress',
				label: '진행중',
			},
			complete: {
				key: '/my-task/complete',
				label: '완료',
			},
		};
	}

	static counsel() {
		return '/counsel';
	}

	static contract() {
		return '/contract';
	}

	static contractTab() {
		return {
			contract: {
				key: '/contract',
				label: '계약',
			},
			employReportAutomation: {
				key: '/contract/auto',
				label: '인력신고 자동화',
			},
			newContractManagement: {
				key: '/contract/new-contract-management',
				label: '신규계약 관리',
			},
		};
	}

	static employReportAutomationToTab() {
		return {
			employment: {
				key: '/contract/auto/employment',
				label: '입사(급여추가)',
			},
			resign: {
				key: '/contract/auto/resign',
				label: '퇴사',
			},
		};
	}

	static newContractManagementToTab() {
		return {
			recipient: {
				key: '/contract/new-contract-management/recipient',
				label: '수급자',
			},
			employee: {
				key: '/contract/new-contract-management/employee',
				label: '직원',
			},
		};
	}

	static schedule() {
		return '/schedule';
	}

	static scheduleOperate() {
		return {
			monthSchedule: {
				label: '월별 일정 관리',
				key: '/schedule/month-schedule',
				uniqueKey: 'month-schedule',
			},
			tagError: {
				label: '태그 관리',
				key: '/schedule/tag-error',
				uniqueKey: 'tag-error',
			},
			recordingSheet: {
				label: '기록지',
				key: '/schedule/record',
				uniqueKey: 'record',
			},
			tagAutomation: {
				label: '태그 수정 자동화 결과',
				key: '/schedule/tag-automation',
			},
			clientMobileRecipient: {
				label: '내 일정 확인',
				key: `/schedule/client-mobile/recipient/:id`,
			},
			clientMobileEmployee: {
				label: '내 일정 확인',
				key: `/schedule/client-mobile/employee/:id`,
			},
			monitoring: {
				label: '일정 모니터링',
				key: '/schedule/monitoring',
			},
			monitoringDetail: {
				label: '일정 모니터링 상세',
				key: '/schedule/monitoring/detail',
			},
		};
	}

	static monthScheduleTab() {
		return {
			recipient: {
				key: '/schedule/month-schedule/recipient/:id',
				label: '수급자',
			},
			employee: {
				key: '/schedule/month-schedule/employee/:id',
				label: '직원',
			},
			gasan: {
				key: '/schedule/month-schedule/gasan/:id',
				label: '방문관리',
			},
		};
	}

	static tagErrorTab() {
		return {
			recipient: {
				key: '/schedule/tag-error/recipient',
				label: '수급자',
			},
		};
	}

	static ownExpense() {
		return '/own-expense';
	}

	static ownExpenseTab() {
		return {
			ownExpenseMonthly: {
				key: '/burden/charge',
				label: '월별 본인부담금',
			},
			ownExpensePayHistory: {
				key: '/burden/pay',
				label: '본인부담금 납부 이력',
			},
		};
	}

	static alarm() {
		return '/alarm';
	}

	static salary() {
		return '/salary';
	}

	static salaryTab() {
		return {
			Salary: {
				key: '/salary',
				label: '급여내역',
			},
			majorInsurance: {
				key: '/salary/major-insurance',
				label: '4대보험',
			},
			severancePay: {
				key: '/salary/severance-pay',
				label: '퇴직금관리',
			},
		};
	}

	static billing() {
		return '/billing';
	}

	static billingTab() {
		return {
			billing: {
				key: '/billing',
				label: '청구관리',
			},
		};
	}

	static document() {
		return '/document';
	}

	static recipientTab() {
		return {
			recipient: {
				key: '/recipient',
				label: '수급자',
			},
		};
	}

	static recipient() {
		return {
			tab: {
				label: '수급자',
				key: '/recipient',
				uniqueKey: 'recipient',
			},
			service: {
				label: '급여 관리',
				key: ':id/service',
				uniqueKey: 'service',
			},
			basicInformation: {
				label: '기본 정보',
				key: ':id/basic',
				uniqueKey: 'basic',
			},
			integratedAssessment: {
				label: '통합사정',
				key: ':id/integrated-assessment',
				uniqueKey: 'integrated-assessment',
			},
			integratedAssessmentFallInfo: {
				label: '낙상위험도',
				key: 'fall-info/:recipientIaId',
				uniqueKey: 'fall-info',
			},
			integratedAssessmentBedsoreInfo: {
				label: '욕창위험도',
				key: 'bedsore-info/:recipientIaId',
				uniqueKey: 'bedsore-info',
			},
			integratedAssessmentKmmseInfo: {
				label: '인지기능',
				key: 'kmmse-info/:recipientIaId',
				uniqueKey: 'kmmse-info',
			},
			integratedAssessmentDesireInfo: {
				label: '욕구사정',
				key: 'desire-info/:recipientIaId',
				uniqueKey: 'desire-info',
			},
			integratedAssessmentSalaryOfferPlanInfo: {
				label: '급여제공변경계획서',
				key: 'salary-offer-plan-info/:salaryOfferPlanId',
				uniqueKey: 'salary-offer-plan-info',
			},
			schedule: {
				label: '월별 일정',
				key: ':id/schedule',
				uniqueKey: 'schedule',
			},
			documents: {
				label: '서류관리',
				key: ':id/documents',
				uniqueKey: 'documents',
			},
			ownExpense: {
				label: '본인부담금',
				key: ':id/own-expense',
				uniqueKey: 'own-expense',
			},
			consulting: {
				label: '상담기록',
				key: ':id/consulting',
				uniqueKey: 'consulting',
			},
			consultingDetail: {
				label: '상담상세',
				key: 'detail/:adviceId',
				uniqueKey: 'detail',
			},
			consultingNew: {
				label: '신규 상담',
				key: 'new',
				uniqueKey: 'new',
			},
			consultingEdit: {
				label: '상담수정',
				key: 'edit/:adviceId',
				uniqueKey: 'edit',
			},
			message: {
				label: '발송내역',
				key: ':id/message',
				uniqueKey: 'message',
			},
			workExecuteLogInspectionDetail: {
				label: '업무수행일지',
				key: 'work-execute-inspection-log/:sendUuid',
				uniqueKey: 'work-execute-inspection-log',
			},
			resign: {
				label: '퇴소',
				key: ':id/resign',
				uniqueKey: 'resign',
			},
			workExecuteLogTab: {
				key: ':id/work-execute',
				label: '업무수행일지',
				uniqueKey: 'work-execute',
			},
			workExecuteLogDetail: {
				key: 'detail/:detailId',
				label: '업무수행일지',
				uniqueKey: 'detail',
			},
			workExecuteLogNew: {
				key: 'new',
				label: '업무수행일지',
				uniqueKey: 'work-execute-log-detail',
			},
			workExecuteLog: {
				key: 'work-execute-log',
				label: '업무수행일지',
				uniqueKey: 'work-execute-log',
			},
		};
	}

	static centerEmployeeTab() {
		return {
			employee: {
				key: '/employee',
				label: '요양요원',
			},
		};
	}

	static employee() {
		return {
			tab: {
				label: '비상근직 직원',
				key: '/employee',
				uniqueKey: 'employee',
			},
			basic: {
				key: ':id/basic',
				label: '기초 정보',
				uniqueKey: 'basic',
			},
			career: {
				key: ':id/career',
				label: '근무 경력',
				uniqueKey: 'career',
			},
			license: {
				key: ':id/license',
				label: '자격증',
				uniqueKey: 'license',
			},
			education: {
				key: ':id/education',
				label: '교육',
				uniqueKey: 'education',
			},
			contract: {
				key: ':id/contract',
				label: '계약',
				uniqueKey: 'contract',
			},
			service: {
				label: '급여 관리',
				key: ':id/service',
				uniqueKey: 'service',
			},
			basicInformation: {
				label: '기본 정보',
				key: ':id/basic',
				uniqueKey: 'basic',
			},
			integratedAssessment: {
				label: '통합사정',
				key: ':id/integrated-assessment',
				uniqueKey: 'integrated-assessment',
			},
			integratedAssessmentFallInfo: {
				label: '낙상위험도',
				key: 'fall-info/:recipientIaId',
				uniqueKey: 'fall-info',
			},
			schedule: {
				key: ':id/schedule',
				label: '일정 관리',
				uniqueKey: 'schedule',
			},
			wage: { label: '임금 내역', key: ':id/salary', uniqueKey: 'salary' },
			consulting: {
				label: '상담기록',
				key: ':id/consulting',
				uniqueKey: 'consulting',
			},
			consultingDetail: {
				label: '상담상세',
				key: 'detail/:adviceId',
				uniqueKey: 'detail',
			},
			consultingNew: {
				label: '신규 상담',
				key: 'new',
				uniqueKey: 'new',
			},
			consultingEdit: {
				label: '상담수정',
				key: 'edit/:adviceId',
				uniqueKey: 'edit',
			},
			message: { label: '발송 내역', key: ':id/message', uniqueKey: 'message' },
			documents: { label: '서류 관리', key: ':id/documents', uniqueKey: 'documents' },
			resign: {
				label: '퇴사',
				key: ':id/resign',
				uniqueKey: 'resign',
			},
		};
	}

	static fullTimeEmployee() {
		return {
			tab: {
				label: '상근직 직원',
				key: '/ftime-employee',
				uniqueKey: 'ftime-employee',
			},
			basic: {
				key: ':id/basic',
				label: '기초 정보',
				uniqueKey: 'basic',
			},
			license: {
				key: ':id/license',
				label: '자격증',
				uniqueKey: 'license',
			},
			education: {
				key: ':id/education',
				label: '교육',
				uniqueKey: 'education',
			},
			detailReport: {
				key: ':id/detail-report',
				label: '종사자 근무 상세 신고',
				uniqueKey: 'detail-report',
			},
			schedule: {
				key: ':id/schedule',
				label: '근무 일정',
				uniqueKey: 'schedule',
			},
			resign: {
				label: '퇴사',
				key: ':id/resign',
				uniqueKey: 'resign',
			},
		};
	}

	static admin() {
		return '/admin';
	}

	static signIn() {
		return '/sign-in';
	}

	static signUp() {
		return '/sign-up';
	}

	static signOut() {
		return '/sign-out';
	}

	static newPassword() {
		return '/new-password';
	}

	// EmployeeResignPage

	static send() {
		return {
			tab: {
				label: '발송',
				key: '/send',
				uniqueKey: 'send',
			},
			do: {
				label: '발송하기',
				key: '/send/do',
				uniqueKey: 'do',
			},
			history: {
				label: '발송이력',
				key: '/send/history',
				uniqueKey: 'history',
			},
			workExecuteLogInspectionDetail: {
				key: '/send/history/esign/work-execute-inspection-log/:sendUuid',
				label: '업무수행일지',
				uniqueKey: 'work-execute-inspection-log',
			},
			sendDetail: {
				label: '메시지 예약 발송 상세',
				key: '/send/history/:historyId/detail',
				uniqueKey: 'detail',
			},
		};
	}

	static sendTab() {
		return {
			message: {
				key: '/send/message',
				label: '발송하기',
			},
			history: {
				key: '/send/history',
				label: '발송내역',
			},
		};
	}

	static sendHistoryTab() {
		return {
			message: {
				key: '/send/history/message',
				label: '메시지 발송 이력',
			},
			esign: {
				key: '/send/history/esign',
				label: '전자서명 발송 이력',
			},
			reserve: {
				key: '/send/history/reserve',
				label: '메세지 예약 발송 목록',
			},
		};
	}

	static insurance() {
		return {
			tab: {
				label: '4대보험',
				key: '/insurance',
				uniqueKey: 'insurance',
			},
			detail: {
				label: '상세',
				key: '/insurance/:id/detail/:detailId',
				uniqueKey: 'detail',
			},
			historyDetail: {
				label: '상세',
				key: '/insurance/:id/history/detail/:detailId',
				uniqueKey: 'detail',
			},
			request: {
				label: '신고',
				key: '/insurance/:id/history/request',
				uniqueKey: 'request',
			},
		};
	}

	static centerAccount() {
		return {
			tab: {
				label: '4대보험',
				key: '/center-account',
				uniqueKey: 'center-account',
			},
		};
	}

	static eDoc() {
		return '/edoc';
	}

	static eDocInfo() {
		return {
			info: {
				key: '/edoc/:id',
				label: '케어링 전자문서시스템',
			},
			notFound: {
				key: '/edoc/*',
				label: '존재하지 않는 페이지',
			},
		};
	}

	static eSign() {
		return '/esign';
	}

	static eSignInfo() {
		return {
			info: {
				key: '/esign/:id',
				label: '케어링 전자서명시스템',
			},
			notFound: {
				key: '/esign/*',
				label: '존재하지 않는 페이지',
			},
		};
	}

	static workSchedule() {
		return '/work';
	}

	static workScheduleTab() {
		return {
			management: {
				key: '/work/schedule',
				label: '근무 및 출퇴근 관리',
			},
			annualManagement: {
				key: '/work/annual',
				label: '연차, 유급휴일 대장',
			},
		};
	}

	static annualToTab() {
		return {
			annualManage: {
				key: '/work/annual/manage',
				label: '연차관리대장',
			},
			annualUsage: {
				key: '/work/annual/usage',
				label: '연차사용대장',
			},
		};
	}

	static eDocDownload() {
		return '/edoc/download/:key';
	}

	static roundingManagement() {
		return '/visit';
	}

	static roundingManagementTab() {
		return {
			roundingHistory: {
				key: '/visit/hist',
				label: '방문내역 관리',
			},
			roundingRate: {
				key: '/visit/rate',
				label: '방문비율',
			},
		};
	}

	static roundingHistoryToTab() {
		return {
			visitCareRoundingHistory: {
				key: '/visit/hist/visit-care',
				label: '방문요양',
			},
			familyCareRoundingHistory: {
				key: '/visit/hist/family',
				label: '가족요양',
			},
			visitBathRoundingHistory: {
				key: '/visit/hist/visit-bath',
				label: '방문목욕',
			},
		};
	}
}
