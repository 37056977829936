import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
	padding: 2.4rem;
	box-sizing: border-box;
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-radius: 1.6rem;
	background: ${(props) => props.theme.colors.white};
	padding: 2.4rem;
	box-sizing: border-box;
`;

export const CalendarHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.6rem;
`;

export const SummaryContainer = styled.div`
	display: flex;
	gap: 1rem;
	margin-bottom: 1.6rem;
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const MonthDate = styled.h4`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	${(props) => props.theme.typography.h4};
	color: ${(props) => props.theme.colors.gray00};
`;

export const HeaderButtonContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	gap: 0.8rem;

	& > div {
		display: flex;
		gap: 0.8rem;
	}
`;

export const LastUpdatedDateText = styled.div`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.theme.colors.gray50}
`;

export const OwnExpenseTableContainer = styled.div`
	flex: 1;
	width: 100%;
	height: 100%;
	overflow-x: overlay;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 1rem;
	padding: 1.2rem 0rem;
	align-items: center;
`;

export const Button = styled.div`
	${(props) => props.theme.typography.label}
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.8rem;
`;

export const UploadButtonContainer = styled.div``;
