import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	background: ${(props) => props.theme.colors.gray100};
`;

export const HeaderButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.8rem;
	background-color: white;
	width: 100%;
	margin-bottom: 1.6rem;
`;

export const MonthDate = styled.h4`
	display: flex;
	align-items: center;
	gap: 0.8rem;
	${(props) => props.theme.typography.h4};
	color: ${(props) => props.theme.colors.gray00};
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const DropdownIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
	${(props) => props.theme.filters.gray100}
`;

export const TableContainer = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	background: ${(props) => props.theme.colors.gray100};
`;

export const InnerContainer = styled.div`
	width: 100%;
	height: calc(100% - 11.2rem);
	overflow: auto;
	background: ${(props) => props.theme.colors.gray100};
`;

export const SyncButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const DropdownItem = styled.div`
	${(props) => props.theme.typography.body};
	display: flex;
	gap: 0.8rem;
	align-items: center;
	width: 13.1rem;
	min-height: 4.8rem;
	height: fit-content;
	user-select: none;
	padding: 0 1.6rem 0;
	box-sizing: border-box;
	cursor: pointer;

	&:hover {
		background: ${(props) => props.theme.colors.gray95};
	}
`;

export const Circle = styled.div<{ $color: string }>`
	width: 1.2rem;
	height: 1.2rem;
	border-radius: 50%;
	background: ${(props) => props.$color};
`;
