import useCRMutation from 'lib/hook/react-query/base/useCRMutation';
import { endpoint } from 'lib/service/Api/endpoint';

export const useUploadMonthlyBurdenCharges = useCRMutation(
	endpoint.uploadMonthlyBurdenCharges.key,
	'uploadMonthlyBurdenCharges',
);

export const useCancelCashReceipt = useCRMutation(
	endpoint.cancelCashReceipt.key,
	'cancelCashReceipt',
);

export const useIssueCashReceipt = useCRMutation(endpoint.issueCashReceipt.key, 'issueCashReceipt');

export const useSendBurdenCharge = useCRMutation(endpoint.sendBurdenCharge.key, 'sendBurdenCharge');

export const useUpsertBurdenChargesCorrections = useCRMutation(
	endpoint.upsertBurdenChargesCorrections.key,
	'upsertBurdenChargesCorrections',
);

export const useDeleteBurdenChargesCorrections = useCRMutation(
	endpoint.deleteBurdenChargesCorrections.key,
	'deleteBurdenChargesCorrections',
);
