import { CRStatusConfig, CRTableHeadConfig } from 'types/view/base';

export const OWN_EXPENSE_PAY_HISTORY_HEADER_CONFIG: CRTableHeadConfig[] = [
	{
		width: '14%',
		maxWidth: '20rem',
		label: '수급자명',
		sortKey: '수급자명',
	},
	{
		width: '10%',
		maxWidth: '16rem',
		label: '생년월일',
		sortKey: '생년월일',
	},
	{
		width: '10%',
		maxWidth: '26rem',
		label: '본인부담금 총액',
		sortKey: '본인부담금 총액',
		textAlign: 'right',
	},
	{
		width: '10%',
		maxWidth: '26rem',
		label: '납부 총액',
		sortKey: '납부 총액',
		textAlign: 'right',
	},
	{
		width: '10%',
		maxWidth: '26rem',
		label: '미/과납금',
		sortKey: '미/과납금',
		textAlign: 'right',
	},
	{
		width: '10%',
		maxWidth: '16rem',
		label: '납부방식',
		sortKey: '납부방식',
	},
	{
		width: '10%',
		maxWidth: '16rem',
		label: '납부 상태',
		sortKey: '납부 상태',
	},
	{
		width: '10%',
		maxWidth: '16rem',
		label: '현금영수증',
		sortKey: '현금영수증',
	},
	{
		width: '14%',
		maxWidth: '20rem',
		label: '사회복지사',
		sortKey: '사회복지사',
	},
];

export const OWN_EXPENSE_PAY_HISTORY_STATUS_CONFIG: CRStatusConfig[] = [
	{
		key: 'CMN135.10',
		label: '미납',
		color: 'red',
	},
	{
		key: 'CMN135.30',
		label: '완납',
		color: 'green',
	},
	{
		key: 'CMN135.40',
		label: '과납',
		color: 'blue',
	},
];
