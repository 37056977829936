import React from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { Toast } from 'components/base/CRToast';
import {
	useMyAccountInfo,
	useUpdateContractState,
	useUpdateRenewalContractState,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';

import { ContractRecord } from '../../../../types/view/contract';
import * as S from './styles';

interface Props {
	hideDialog: () => void;
	contract?: ContractRecord;
}

function ContractRollbackDialog({ contract, hideDialog }: Props): React.ReactElement {
	const { data: myAccountInfo } = useMyAccountInfo();
	const updateContractStateMutation = useUpdateContractState((client) => {
		client.invalidateQueries([endpoint.getContracts.key]);
		Toast.success('계약이 진행중으로 변경되었습니다.');
	});
	const updateRenewalContractStateMutation = useUpdateRenewalContractState((client) => {
		client.invalidateQueries([endpoint.getContracts.key]);
		Toast.success('계약이 진행중으로 변경되었습니다.');
	});

	const handleSubmit = async () => {
		if (!myAccountInfo) return;
		if (!contract) return;

		if (contract.contractType === 'CMN136.30') {
			await updateRenewalContractStateMutation.mutateAsync({
				serviceContractUuId: contract.serviceContractGroupUuid,
			});
		} else {
			await updateContractStateMutation.mutateAsync({ serviceContractId: contract.id });
		}

		hideDialog();
	};

	return (
		<CRDialog
			onClickClose={hideDialog}
			title='완료 취소'
			type='S'
			body={
				<S.Container>
					<S.SubTitle>{`${contract?.recipient} 님의 계약을 진행중 상태로 변경합니다.`}</S.SubTitle>
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' size='default' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default palette='primary' size='default' onClick={handleSubmit}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default ContractRollbackDialog;
