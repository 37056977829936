import React, {
	ChangeEvent,
	KeyboardEvent,
	KeyboardEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import * as Pop from '@radix-ui/react-popover';

import Assets from 'assets';
import { CRText } from 'components/base/CRText';
import { CheckOption } from 'components/base/Selections/type';
import Highlighter from 'components/ui/Highlighter';
import RdPopover from 'components/ui/radix/popover/RdPopover';

import * as S from './styles';

interface IProps {
	id?: string;
	currentValue?: CheckOption;
	items?: CheckOption[];
	maxLength?: number;
	placeholder?: string;
	searchKey?: string[];
	onChange?: (...event: any[]) => void;
	onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
	disabled?: boolean;
	topOffset?: number;
}

function CRTableSearchSelector({
	id,
	items,
	placeholder = '',
	maxLength = 50,
	currentValue,
	onChange,
	onPressEnter,
	searchKey = [],
	disabled = false,
	topOffset,
}: IProps): React.ReactElement {
	const containerRef = useRef<HTMLDivElement>(null);
	const itemListRef = useRef<HTMLUListElement>(null);
	const itemRef = useRef<HTMLButtonElement>(null);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const innerItemRef = useRef<HTMLDivElement>(null);
	const [position, setPosition] = useState({
		top: 0,
		left: 0,
		height: 0,
		width: 0,
	});
	const [searchText, setSearchText] = useState(currentValue?.label);
	const [keyboardCursorIndex, setKeyboardCursorIndex] = useState(-1);
	const [isFocused, setIsFocused] = useState(false);

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > maxLength) return;
		setSearchText(e.target.value);
	};

	const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			onPressEnter?.(e);
		}
	};

	const handleClickSearchItem = (item: CheckOption) => {
		onChange?.(item);
		itemListRef.current?.scrollTo({ top: 0 });
		setSearchText(item.label);
		setKeyboardCursorIndex(-1);
		setIsFocused(false);
	};

	const searchItems = !searchText
		? []
		: (items?.filter(
				(item) =>
					item.label.toLowerCase().includes(searchText.toLowerCase()) ||
					item?.children?.find((child) =>
						child.label.toLowerCase().includes(searchText.toLowerCase()),
					) ||
					searchKey?.find((key) =>
						item.value[key].toLowerCase().includes(searchText.toLowerCase()),
					),
			) ?? []);

	const handleResizeEvent = useCallback(() => {
		const boundingClientRect = containerRef.current?.getBoundingClientRect();
		if (!boundingClientRect) return;

		const { height, width } = boundingClientRect;
		setPosition({ top: height - 1, left: 0, height, width });
	}, []);

	const renderSearchItem = (searchItem: CheckOption) => {
		const { id, birth, duty, guardian } = searchItem.value;
		if (['수급자', '요양보호사', '간호사', '간호조무사'].includes(duty)) {
			return [duty, birth].filter((val) => val).join('・');
		}
		return [duty, birth, id, guardian].filter((val) => val).join('・');
	};

	useEffect(() => {
		handleResizeEvent();
		document.addEventListener('resize', handleResizeEvent);

		return () => {
			document.removeEventListener('resize', handleResizeEvent);
		};
	}, [handleResizeEvent]);

	useEffect(() => {
		if (wrapperRef.current && innerItemRef.current && currentValue) {
			const element = innerItemRef.current;
			// 텍스트가 overflow 되어 ellipsis가 적용된 상태인지 확인 , 5는 마진
			if (element.scrollWidth > 330 && element.scrollWidth > element.clientWidth + 5) {
				wrapperRef.current.title = currentValue?.label || ''; // title 속성에 전체 텍스트 추가
			} else {
				wrapperRef.current.title = ''; // ellipsis가 적용되지 않았다면 title 제거
			}
		}
	}, [currentValue]);

	const handleChangeAutoKeyDown = useCallback(
		(event: KeyboardEvent) => {
			switch (event.key) {
				case 'ArrowDown':
					event.preventDefault();
					if (keyboardCursorIndex === searchItems.length - 1) {
						itemListRef.current?.scrollTo({ top: 0 });
						setKeyboardCursorIndex(-1);
						return;
					}
					itemListRef.current?.scrollTo({
						top: (keyboardCursorIndex + 1) * position.height,
						behavior: 'smooth',
					});
					setKeyboardCursorIndex(keyboardCursorIndex + 1);
					return;
				case 'ArrowUp':
					event.preventDefault();
					if (keyboardCursorIndex === -1) {
						itemListRef.current?.scrollTo({ top: (searchItems.length - 2) * position.height });
						setKeyboardCursorIndex(searchItems.length - 1);
						return;
					}

					itemListRef.current?.scrollTo({ top: (keyboardCursorIndex - 1) * position.height });
					setKeyboardCursorIndex(keyboardCursorIndex - 1);
					return;
				case 'Enter':
					event.preventDefault();
					if (keyboardCursorIndex !== -1) handleClickSearchItem(searchItems[keyboardCursorIndex]);
					itemRef.current?.click();
					break;
				default:
					break;
			}
		},
		[keyboardCursorIndex, searchItems, position],
	);

	useEffect(() => {
		if (isFocused) {
			document.addEventListener('keydown', handleChangeAutoKeyDown);
		}

		return () => {
			document.removeEventListener('keydown', handleChangeAutoKeyDown);
		};
	}, [isFocused, handleChangeAutoKeyDown]);

	return (
		<div>
			<RdPopover
				openCallback={() => !disabled && setIsFocused(true)}
				closeCallback={() => !disabled && setIsFocused(false)}
				sideOffset={0}
				showDivider={false}
				borderShape='none'
				content={
					isFocused && (
						<S.DropdownContainer
							style={{
								width: wrapperRef.current?.clientWidth,
								top:
									topOffset ||
									(wrapperRef.current?.clientHeight
										? wrapperRef.current.clientHeight / 2
										: position.top),
								left: position.left,
							}}>
							<S.InputContainer $isFocused={isFocused} $error={false} disabled={false}>
								<input
									id={id}
									// eslint-disable-next-line
									autoFocus
									value={searchText}
									disabled={false}
									maxLength={maxLength}
									onChange={handleChange}
									onKeyUp={onKeyUp}
								/>
							</S.InputContainer>

							<S.SearchDropDownContainer $tableInput>
								<S.SearchResultTitle $tableInput>검색 결과</S.SearchResultTitle>
								<S.SearchResultContainer ref={itemListRef} $tableInput>
									{searchItems.length > 0 ? (
										searchItems?.map((searchItem) => (
											<Pop.Close
												ref={itemRef}
												style={{
													background: 'transparent',
													border: 0,
													padding: 0,
													margin: 0,
													cursor: 'pointer',
													outline: 'none',
												}}>
												<S.SearchResultItemOuterContainer>
													<S.SearchResultItemContainer
														$tableInput
														key={searchItem.value?.id}
														onClick={(e) => {
															e.stopPropagation();
															handleClickSearchItem?.(searchItem);
														}}>
														<S.SearchResultItemMainContainer $tableInput>
															<Highlighter
																text={`${searchItem.value.name}`}
																targetText={searchText ?? ''}
																highlightStyle={{ color: 'red' }}
															/>
														</S.SearchResultItemMainContainer>
														<S.SearchResultItemSubContainer>
															<Highlighter
																text={renderSearchItem(searchItem)}
																targetText={searchText ?? ''}
																highlightStyle={{ color: 'red' }}
															/>
														</S.SearchResultItemSubContainer>
													</S.SearchResultItemContainer>
													{searchItem.children?.map((chidren) => (
														<S.SearchResultItemChildContainer
															key={chidren.value?.id}
															onClick={(e) => {
																e.stopPropagation();
																handleClickSearchItem?.(chidren);
															}}>
															<S.Line />
															<S.SearchResultItemMainContainer $tableInput>
																<Highlighter
																	text={`${chidren.value.name}`}
																	targetText={searchText ?? ''}
																	highlightStyle={{ color: 'red' }}
																/>
															</S.SearchResultItemMainContainer>
															<S.SearchResultItemSubContainer>
																<Highlighter
																	text={renderSearchItem(chidren)}
																	targetText={searchText ?? ''}
																	highlightStyle={{ color: 'red' }}
																/>
															</S.SearchResultItemSubContainer>
														</S.SearchResultItemChildContainer>
													))}
												</S.SearchResultItemOuterContainer>
											</Pop.Close>
										))
									) : (
										<S.SearchResultEmptyListContainer>
											<S.SearchResultEmptyListDescription $tableInput>
												{searchText ? '검색결과가 없습니다.' : '검색어를 입력해주세요.'}
											</S.SearchResultEmptyListDescription>
										</S.SearchResultEmptyListContainer>
									)}
								</S.SearchResultContainer>
							</S.SearchDropDownContainer>
						</S.DropdownContainer>
					)
				}>
				<S.Container $isDisabled={disabled} ref={wrapperRef}>
					<S.InputContainer
						disabled={disabled}
						ref={containerRef}
						onClick={(e) => {
							if (disabled) return;
							setIsFocused(true);
						}}>
						<CRText
							text={currentValue?.label || placeholder}
							typography='label'
							color={currentValue?.label ? 'gray10' : 'gray60'}
						/>
						<S.Icon src={Assets.icon.unfoldMore} />
					</S.InputContainer>
				</S.Container>
			</RdPopover>
		</div>
	);
}

export default CRTableSearchSelector;
