import React, { useMemo } from 'react';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { CRText } from 'components/base/CRText';
import CRCheckBoxGroup from 'components/base/Selections/CRCheckBoxGroup';
import { CheckOption } from 'components/base/Selections/type';
import { useEmployeePageLicenseInfo } from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { EmployeeLicenseInfoDTO } from 'types/api';

import * as S from './styles';

interface Props {
	uploadLicenseFile: (licenseInfo: EmployeeLicenseInfoDTO) => void;
	employeeId: number;
}

function EmployeeLicenseUploadDialog({ uploadLicenseFile, employeeId }: Props): React.ReactElement {
	const { hideDialog } = useDialog();
	const [licenseInfo, setLisenseInfo] = React.useState<CheckOption<EmployeeLicenseInfoDTO>[]>([]);

	const { data: employeeLicenseInfo, refetch } = useEmployeePageLicenseInfo({
		employeeId,
	});
	const employeeLisenceOptions: CheckOption[] = useMemo(
		() =>
			employeeLicenseInfo?.map((item) => ({
				label: `요양보호사 1급 (${item.licenseNo})`,
				value: item.employeeLicenseId,
				data: item,
			})) || [],
		[employeeLicenseInfo],
	);

	return (
		<CRDialog
			title={employeeLisenceOptions.length > 0 ? '자격증 불러오기' : '자격증 정보 없음'}
			onClickClose={hideDialog}
			type='S'
			body={
				<S.LisenseContainer>
					{employeeLisenceOptions.length > 0 ? (
						<CRText typography='body' text='불러올 자격증을 선택해 주십시오.' />
					) : (
						<CRText typography='body' text='불러올 수 있는 자격증 정보가 없습니다.' />
					)}

					{employeeLisenceOptions.length > 0 && (
						<CRCheckBoxGroup
							checkType='single'
							appearanceType='button'
							type='radio'
							direction='col'
							options={employeeLisenceOptions}
							value={licenseInfo}
							gap={0.8}
							onChange={(value: CheckOption[]) => setLisenseInfo(value)}
						/>
					)}
				</S.LisenseContainer>
			}
			footer={
				employeeLisenceOptions.length > 0 ? (
					<S.ButtonContainer>
						<CRButton.Default type='text' palette='gray' onClick={hideDialog}>
							취소
						</CRButton.Default>
						<CRButton.Default
							buttonType='submit'
							onClick={() =>
								uploadLicenseFile(licenseInfo[0]?.data || ({} as EmployeeLicenseInfoDTO))
							}>
							불러오기
						</CRButton.Default>
					</S.ButtonContainer>
				) : (
					<S.ButtonContainer>
						<CRButton.Default buttonType='submit' onClick={hideDialog}>
							확인
						</CRButton.Default>
					</S.ButtonContainer>
				)
			}
		/>
	);
}

export default EmployeeLicenseUploadDialog;
