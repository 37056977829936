import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { CreateEmployReportForm } from 'types/view/employReport';

interface Props {
	control: Control<CreateEmployReportForm, any>;
}

function EmploymentReportResignInfoForm({ control }: Props): React.ReactElement {
	const renderErrorMessage = (error: boolean, message: string) =>
		error ? (
			<CRText color='primary60' typography='label'>
				{message}
			</CRText>
		) : null;

	return (
		<CRCardFormLayout label='퇴사 정보'>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='퇴사일'
						message={renderErrorMessage(!!errors.resignDate, errors.resignDate?.message ?? '')}>
						<CRInput.DatePicker
							value={value}
							onChangeValue={onChange}
							placeholder='퇴사일 선택'
							status={errors.resignDate ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='resignDate'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='근무종료일'
						message={renderErrorMessage(!!errors.workEndDate, errors.workEndDate?.message ?? '')}>
						<CRInput.DatePicker
							value={value}
							onChangeValue={onChange}
							placeholder='근무종료일 선택'
							status={errors.workEndDate ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='workEndDate'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='퇴사서류 파일'
						message={renderErrorMessage(!!errors.resignFile, errors.resignFile?.message ?? '')}>
						<CRInput.FileUploader
							onChange={(files) => onChange({ ...value, fileDetails: files })}
							files={value?.fileDetails}
							placeholder='파일을 끌어오거나 업로드'
							status={errors.resignFile ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='resignFile'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='퇴사 사유'
						message={renderErrorMessage(!!errors.resignReason, errors.resignReason?.message ?? '')}>
						<CRInput.TextArea
							value={value}
							onChange={onChange}
							placeholder='퇴사 사유 입력'
							numberOfLines={1}
							status={errors.resignReason ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='resignReason'
				control={control}
			/>
		</CRCardFormLayout>
	);
}

export default EmploymentReportResignInfoForm;
