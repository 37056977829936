import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import CRToolTip from 'components/base/CRToolTip';
import { CheckOption } from 'components/base/Selections/type';
import DefaultDialog from 'components/domain/dialog/DefaultDialog';
import SalaryTable from 'components/domain/table/SalaryTable';
import SalaryEmpty from 'components/ui/Salary/SalaryEmpty';
import RdPopover from 'components/ui/radix/popover/RdPopover';
import { displayDateToYYYYMM, unique } from 'lib';
import {
	useCenterManagers,
	useConfirmAllEmployeeSalary,
	useDownloadEmployeeSalarysPayroll,
	useDownloadEmployeeSalarysTransfer,
	useMyAccountInfo,
	useRecalculateAllEmployeeSalary,
	useSalaryAutomationUploadInfo,
} from 'lib/hook/react-query';
import { useSalarysEmployeeSalaryList } from 'lib/hook/react-query/query/salary';
import useDialog from 'lib/hook/util/useDialog';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import usePageFilter from 'lib/hook/util/usePageFilter';
import { endpoint } from 'lib/service/Api/endpoint';
import { exportExcelSalaryPayroll } from 'lib/util/xlsx/employeeSalaryPayroll';
import { exportExcelSalaryTransfer } from 'lib/util/xlsx/employeeSalaryTransfer';
import { ResponseCode } from 'types/api';
import { PageInfo } from 'types/view/base';
import { Filter } from 'types/view/filter';

import * as S from './styles';

interface SalaryTabProps {
	isAutomaticProgress: boolean;
	setIsAutomaticProgress: (isAutomaticProgress: boolean) => void;
}

function SalaryTab({
	isAutomaticProgress,
	setIsAutomaticProgress,
}: SalaryTabProps): React.ReactElement {
	const { showDialog } = useDialog();
	const [isOpen, setOpen] = useState(false);
	const hasConfirmSalaryFunc = useHasFunc(['salary:confirm_salary']);
	const hasCalculateSalaryFunc = useHasFunc(['salary:calculate_salary']);
	const hasDownloadPayrollFunc = useHasFunc(['salary:download_payroll']);
	const hasDownloadBulkTransferHistoryFunc = useHasFunc(['salary:download_bulk_transfer_history']);
	const { data: myAccountInfo } = useMyAccountInfo();
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const [date, setDate] = useState(dayjs().subtract(1, 'month').toDate());
	const { filters: currentFilter, setFilters: setCurrentFilter } = usePageFilter({});

	const [searchValue, setSearchValue] = useState<string>('');
	const [keyword, setKeyword] = useState<string>('');

	const { data: centerManagers } = useCenterManagers({
		centerId: myAccountInfo?.centerId,
	});

	const {
		data: employeeSalarysData,
		isLoading: isEmployeeSalarysLoading,
		refetch: refetchEmployeeSalarysData,
	} = useSalarysEmployeeSalaryList({
		salaryYm: displayDateToYYYYMM(date),
		centerId: myAccountInfo?.centerId,
		managerIds: currentFilter?.managerFilter?.map((item) => item.value) || [],
		salaryCompleteYns: currentFilter?.salaryCompleteFilter?.map((item) => item.value) || [],
		page: pageInfo.page,
		size: pageInfo.size,
		keyword,
	});

	const {
		mutateAsync: downloadEmployeeSalaryPayroll,
		isLoading: isEmployeeSalaryPayrollDownloading,
	} = useDownloadEmployeeSalarysPayroll();

	const {
		mutateAsync: downloadEmployeeSalaryTransfer,
		isLoading: isEmployeeSalaryTransferLoading,
	} = useDownloadEmployeeSalarysTransfer();

	const { data: salaryAutoUploadInfo } = useSalaryAutomationUploadInfo({
		centerId: myAccountInfo?.centerId,
		uploadTypeCds: ['CMN107.CHARGE', 'CMN107.SALARY'],
	});

	const { mutateAsync: confirmAllEmployeeSalary } = useConfirmAllEmployeeSalary((client, data) => {
		refetchEmployeeSalarysData();
		client.invalidateQueries([endpoint.getSalarysEmployeeSalaryDetail.key]);
		if (data?.code === ResponseCode.SUCCESS) {
			Toast.success('확정이 완료되었습니다.');
		} else {
			Toast.error('확정에 실패했습니다. 관리자에게 문의해주세요.');
		}
	});

	const { mutateAsync: recalculateAllEmployeeSalary } = useRecalculateAllEmployeeSalary(
		(client, data) => {
			refetchEmployeeSalarysData();
			client.invalidateQueries([endpoint.getSalarysEmployeeSalaryDetail.key]);
			if (data?.code === ResponseCode.SUCCESS) {
				Toast.success('전체 재계산이 요청되었습니다.');
				setIsAutomaticProgress(true);
			} else {
				Toast.error('전체 재계산 요청이 실패했습니다. 관리자에게 문의해주세요.');
			}
		},
	);

	const lastSyncedDate = useMemo(() => {
		if (!salaryAutoUploadInfo?.length) return '';

		const lastSynced = salaryAutoUploadInfo?.find(
			(info) =>
				info?.uploadStateCd === 'CMN108.COMPLETE' &&
				info?.uploadTypeCd === 'CMN107.SALARY' &&
				info.uploadTargetYm === displayDateToYYYYMM(date),
		)?.uploadEndDate;
		if (!lastSynced) return '';
		return dayjs(lastSynced).format('YYYY.MM.DD HH:mm');
	}, [salaryAutoUploadInfo, date]);

	useEffect(() => {
		if (employeeSalarysData?.totalPages !== undefined) {
			setPageInfo((prev) => ({
				...prev,
				totalPages: employeeSalarysData?.totalPages || 0,
			}));
		}
	}, [employeeSalarysData?.totalPages]);

	const downloadEmployeeSalaryPayrollHandler = async () => {
		if (isEmployeeSalaryPayrollDownloading) return;
		if (!myAccountInfo) {
			Toast.error('새로고침 후 다시 시도해주세요.');
			return;
		}
		const res = await downloadEmployeeSalaryPayroll({
			centerId: myAccountInfo.centerId,
			salaryYm: displayDateToYYYYMM(date),
		});
		if (!res || !res.length) {
			Toast.error('다운로드 할 자료가 존재하지 않습니다. 관리자에게 문의해주세요.');
			return;
		}

		const fileName = `${dayjs(date).format('YYYY년_MM월')}_${
			myAccountInfo.centerNm
		}_임금대장`.replace(/\s/g, '_');

		try {
			exportExcelSalaryPayroll(fileName, res);
			Toast.success(`[${fileName}] 다운로드에 성공했습니다.`);
		} catch (e) {
			Toast.error(`[${fileName}] 다운로드에 실패했습니다.`);
		}
	};

	const downloadEmployeeSalaryTransferHandler = async () => {
		if (isEmployeeSalaryTransferLoading) return;
		if (!myAccountInfo) {
			Toast.error('새로고침 후 다시 시도해주세요.');
			return;
		}
		const res = await downloadEmployeeSalaryTransfer({
			centerId: myAccountInfo.centerId,
			salaryYm: displayDateToYYYYMM(date),
		});
		if (!res || !res.length) {
			Toast.error('다운로드 할 자료가 존재하지 않습니다. 관리자에게 문의해주세요.');
			return;
		}

		const fileName = `${dayjs(date).format('YYYY-MM')}_급여이체_${
			myAccountInfo.centerNm
		}_요양보호사`.replace(/\s/g, '_');

		try {
			exportExcelSalaryTransfer(fileName, res);
			Toast.success(`[${fileName}] 다운로드에 성공했습니다.`);
		} catch {
			Toast.error(`[${fileName}] 다운로드에 실패했습니다.`);
		}
	};

	const handleClickConfirm = (salaryCompleteYn: boolean) => async () => {
		if (!myAccountInfo?.centerId) return;
		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title={salaryCompleteYn ? '확정 완료로 변경' : '확정 대기로 변경'}
				content={
					salaryCompleteYn
						? `${dayjs(date).format('YYYY년 MM월')} 임금 전체를 확정합니다.`
						: `${dayjs(date).format('YYYY년 MM월')} 임금 전체를 확정 취소합니다.`
				}
				successOption={{
					text: salaryCompleteYn ? '확정' : '확정 취소',
					successCallback: async () => {
						await confirmAllEmployeeSalary({
							centerId: myAccountInfo?.centerId,
							salaryYm: displayDateToYYYYMM(date),
							salaryCompleteYn,
						});
						hideDialog();
					},
				}}
				cancelOption={{
					text: '취소',
					callback: async () => {
						hideDialog();
					},
				}}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleClickCaculate = async () => {
		if (!myAccountInfo?.centerId) return;
		await recalculateAllEmployeeSalary({
			centerId: myAccountInfo?.centerId,
			salaryYm: displayDateToYYYYMM(date),
		});
	};

	const handleChangeSearchValue = (value: string) => {
		setSearchValue(value);
	};

	const handleSearch = () => {
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
		setKeyword(searchValue);
	};

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleChangeDate = (date: Date) => {
		setDate(date);
		setPageInfo((prev) => ({
			...prev,
			page: 1,
		}));
	};

	const managerFilter = useMemo(
		() =>
			({
				key: 'managerFilter',
				type: 'multi',
				options: unique(centerManagers ?? [], (item) => item.memberAccountId)?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})),
				placeholder: '사회복지사',
			}) as Filter<CheckOption>,
		[centerManagers],
	);

	const salaryCompleteFilter = useMemo(
		() =>
			({
				key: 'salaryCompleteFilter',
				type: 'multi',
				options: [
					{
						label: '대기',
						value: false,
					},
					{
						label: '완료',
						value: true,
					},
				],
				placeholder: '검수상태',
			}) as Filter<CheckOption>,
		[],
	);

	// 사회복지사 기본필터를 자신으로 설정
	useLayoutEffect(() => {
		if (myAccountInfo && myAccountInfo?.positionCd === 'CMN103.10') {
			setCurrentFilter((prev) => {
				const alreadyManagerExist = Boolean(
					prev?.managerFilter?.find((manager) => manager.value === myAccountInfo.memberAccountId),
				);
				if (alreadyManagerExist) {
					return prev;
				}
				return {
					...prev,
					managerFilter: [
						...(prev?.managerFilter || []),
						{ value: myAccountInfo.memberAccountId, label: myAccountInfo.userNm },
					],
				};
			});
		}
	}, [myAccountInfo]);

	const isLoading = isEmployeeSalarysLoading;
	const isEmpty = employeeSalarysData?.content?.length === 0;

	return (
		<CRTable.BackBlind>
			<S.HeaderButtonContainer>
				<CRTableMonthSelector onChange={handleChangeDate} value={date} />
				<FlexContainer align='center' gap='0.8rem' height='4rem'>
					<CRText
						typography='label'
						color='gray50'
						margin='0 1rem 0 0'
						text={lastSyncedDate ? `${lastSyncedDate} 동기화` : ''}
					/>
					<CRButton.IconButton
						disabled={!hasDownloadBulkTransferHistoryFunc}
						palette='gray'
						type='outlined'
						iconLeft={Assets.icon.fileDownload}
						onClick={downloadEmployeeSalaryTransferHandler}>
						대량이체 다운로드
					</CRButton.IconButton>
					<CRButton.IconButton
						disabled={!hasDownloadPayrollFunc}
						palette='gray'
						type='outlined'
						iconLeft={Assets.icon.fileDownload}
						onClick={downloadEmployeeSalaryPayrollHandler}>
						임금대장 다운로드
					</CRButton.IconButton>
					<CRToolTip title='임금을 재계산 및 저장합니다. (확정 내역 제외)' placement='right'>
						<CRButton.Default
							type='outlined'
							palette='gray'
							disabled={!hasCalculateSalaryFunc || isAutomaticProgress}
							onClick={handleClickCaculate}>
							전체 저장(재계산)
						</CRButton.Default>
					</CRToolTip>
					<RdPopover
						showDivider={false}
						side='right'
						align='start'
						borderShape='small'
						sideOffset={-130}
						alignOffset={100}
						isOpen={isOpen}
						setOpen={setOpen}
						content={
							<>
								<S.DropdownItem onClick={handleClickConfirm(true)}>
									<S.Circle $color={Colors.green} /> 완료로 변경
								</S.DropdownItem>
								<S.DropdownItem onClick={handleClickConfirm(false)}>
									<S.Circle $color={Colors.gray90} />
									대기로 변경
								</S.DropdownItem>
							</>
						}>
						<CRButton.Default
							disabled={!hasConfirmSalaryFunc || isAutomaticProgress}
							style={{
								display: 'flex',
								gap: '0.8rem',
								padding: '0.6rem 1.6rem 0.6rem 2.4rem',
							}}>
							<CRText typography='label' color='gray100' text='전체 확정' />
							<S.DropdownIcon
								src={isOpen ? Assets.icon.keyboardArrowTop : Assets.icon.keyboardArrowBottom}
							/>
						</CRButton.Default>
					</RdPopover>
				</FlexContainer>
			</S.HeaderButtonContainer>
			<S.TableContainer>
				<CRTableHeader
					searchValue={searchValue}
					onChangeSearchValue={handleChangeSearchValue}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
					filters={[salaryCompleteFilter, managerFilter]}
					onSearch={handleSearch}
					onRefresh={refetchEmployeeSalarysData}
					onChangePageInfo={handleChangePageInfo}
					placeholder='요양보호사로 검색'
					pageInfo={pageInfo}
					showViewCount
					stickyMode
					showRefresh
				/>
				<S.InnerContainer>
					{!isEmpty && (
						<>
							{/* eslint-disable-next-line react/jsx-no-useless-fragment */}
							{isLoading ? (
								<CRSpinner />
							) : (
								<SalaryTable
									items={employeeSalarysData?.content}
									isAutomaticProgress={isAutomaticProgress}
									refetch={refetchEmployeeSalarysData}
								/>
							)}
						</>
					)}
					{isEmpty && <SalaryEmpty />}
				</S.InnerContainer>
			</S.TableContainer>
		</CRTable.BackBlind>
	);
}

export default SalaryTab;
