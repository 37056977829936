import React, { useCallback } from 'react';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import { FlexContainer } from 'components/base/CRFlexLayout/styles';
import CRStatus from 'components/base/CRStatus';
import CRTable from 'components/base/CRTable';
import SalaryDetailDialog from 'components/domain/dialog/SalaryDetailDialog';
import { displayComma } from 'lib';
import useDialog from 'lib/hook/util/useDialog';
import { SalarysEmployeeSalarysContent } from 'types/api/salary';

import {
	EMPLOYEE_SALARY_COMPLETE_STATUS_CONFIG,
	EMPLOYEE_SALARY_SEND_STATUS_CONFIG,
	EMPLOYEE_SALARY_TABLE_HEADER_CONFIG,
} from './constant';
import * as S from './styles';

interface Props {
	items?: SalarysEmployeeSalarysContent[] | null;
	refetch: () => void;
	isAutomaticProgress?: boolean;
}

function SalaryTable({ items = [], isAutomaticProgress, refetch }: Props): React.ReactElement {
	const { showDialog } = useDialog();

	const onClickRow = (item: SalarysEmployeeSalarysContent) => {
		showDialog(({ hideDialog }) => (
			<SalaryDetailDialog
				employeeSalaryId={item.employeeSalaryId}
				onClose={hideDialog}
				refetchList={refetch}
				isAutomaticProgress={isAutomaticProgress}
			/>
		));
	};

	const renderSendStatus = useCallback(
		(status: string) => <CRStatus options={EMPLOYEE_SALARY_SEND_STATUS_CONFIG}>{status}</CRStatus>,
		[],
	);

	const renderCompleteStatus = useCallback(
		(status: boolean) => (
			<CRStatus options={EMPLOYEE_SALARY_COMPLETE_STATUS_CONFIG}>
				{status ? '완료' : '대기'}
			</CRStatus>
		),
		[],
	);

	const renderServiceChangeNm = useCallback(
		(serviceChangeNm: string) =>
			serviceChangeNm?.split(',').length > 0 ? (
				<S.ServiceTypeBadgeContainer>
					{serviceChangeNm
						?.split(',')
						.map((item) => <S.ServiceTypeBadge>{item}</S.ServiceTypeBadge>)}
				</S.ServiceTypeBadgeContainer>
			) : (
				'-'
			),
		[],
	);

	const renderTotalWorDayCount = useCallback(
		(totalWorkDayCnt: number) => (
			<S.TextBox style={{ textAlign: 'left' }}>
				{totalWorkDayCnt > 0 ? `${totalWorkDayCnt.toLocaleString()}일` : '-'}
			</S.TextBox>
		),
		[],
	);

	const renderTotalWorkHourCount = useCallback(
		(salaryServiceConcatSummary: string, row?: SalarysEmployeeSalarysContent) => (
			<FlexContainer gap='0.8rem'>
				{salaryServiceConcatSummary?.split(',').map((item, index) => {
					if (index === 0) {
						return <S.TextBox>{item}</S.TextBox>;
					}
					return <S.ServiceTypeBadge>{item}</S.ServiceTypeBadge>;
				})}
			</FlexContainer>
		),
		[],
	);

	const renderMoney = useCallback(
		(value: number) => <S.TextBox>{displayComma(value)}</S.TextBox>,
		[],
	);

	const renderBirthDt = useCallback((value: string) => dayjs(value).format('YYYY.MM.DD'), []);

	const renderSalaryYm = useCallback((value: string) => dayjs(value).format('YYYY.MM'), []);

	return (
		<S.Container>
			<CRTable.Root
				style={{
					borderLeft: `0.1rem solid ${Colors.gray90}`,
					borderRight: `0.1rem solid ${Colors.gray90}`,
				}}>
				<CRTable.Head offset={1} heads={EMPLOYEE_SALARY_TABLE_HEADER_CONFIG} />
				<CRTable.Body>
					{items?.map((item) => (
						<CRTable.Row
							key={item.employeeSalaryId}
							item={item}
							onClick={onClickRow}
							customRender={{
								salaryYm: renderSalaryYm,
								totalWorkDayCnt: renderTotalWorDayCount,
								salaryServiceConcatSummary: renderTotalWorkHourCount,
								birthDt: renderBirthDt,
								serviceChangeNm: renderServiceChangeNm,
								salarySendStateCd: renderSendStatus,
								salaryCompleteYn: renderCompleteStatus,
								paySumAmt: renderMoney,
								salaryItemAmt3010: renderMoney,
								salaryItemAmt3080: renderMoney,
								deductSumAmt: renderMoney,
								realPayAmt: renderMoney,
							}}
							renderKeys={[
								'salaryYm',
								'employeeNm',
								'birthDt',
								'recipientNm',
								'serviceChangeNm',
								'totalWorkDayCnt',
								'salaryServiceConcatSummary',
								'paySumAmt',
								'deductSumAmt',
								'salaryItemAmt3010',
								'salaryItemAmt3080',
								'realPayAmt',
								'salaryCompleteYn',
								'salarySendStateCd',
								'managerNm',
							]}
							customStyle={{
								totalWorkDayCnt: {
									textAlign: 'right',
								},
								totalWorkHourCnt: {
									textAlign: 'right',
								},
								paySumAmt: {
									textAlign: 'right',
								},
								salaryItemAmt3010: {
									textAlign: 'right',
								},
								salaryItemAmt3080: {
									textAlign: 'right',
								},
								deductSumAmt: {
									textAlign: 'right',
								},
								realPayAmt: {
									textAlign: 'right',
								},
							}}
						/>
					))}
				</CRTable.Body>
			</CRTable.Root>
		</S.Container>
	);
}

export default SalaryTable;
