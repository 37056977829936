import styled, { css, keyframes } from 'styled-components';

const rotate = keyframes`
   from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1080deg); /* 3바퀴 회전을 위해 1080도 설정 */
  }
`;

export const Container = styled.div<{ $stickyMode: boolean; $bottomPadding: boolean }>`
	position: relative;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: ${(props) => props.theme.colors.gray100};
	width: 100%;
	padding-bottom: ${(props) => (props.$bottomPadding ? '1.6rem' : 0)};
	box-sizing: border-box;
	${(props) =>
		props.$stickyMode &&
		css`
			position: sticky;
			z-index: 6;
		`};
`;

export const LeftSideMenu = styled.div`
	display: flex;
	gap: 0.8rem;
	flex: 1;
`;

export const PageInfo = styled.div`
	${(props) => props.theme.typography.body}
	display: flex;
	align-items: center;
	gap: 0.8rem;
	color: ${(props) => props.theme.colors.gray60};
	white-space: nowrap;
`;

export const CurrentPageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: auto;
	min-width: 3.2rem;
	min-height: 3.2rem;
	border: 0.1rem solid ${(props) => props.theme.colors.gray90};
	border-radius: 0.4rem;
	box-sizing: border-box;

	& > input {
		display: flex;
		justify-content: center;
		${(props) => props.theme.typography.body}
		padding: 0;
		margin: 0;
		outline: none;
		border: none;
		width: 100%;
		max-width: 10rem;
		padding: 0 0.8rem;
		box-sizing: content-box;
	}
`;

export const TotalPageText = styled.div`
	${(props) => props.theme.typography.body};
	color: ${(props) => props.theme.colors.gray60};
	& > span {
		${(props) => props.theme.typography.label};
		color: ${(props) => props.theme.colors.gray60};
	}
`;

export const Paging = styled.div`
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const ViewCount = styled.div`
	${(props) => props.theme.typography.body};
	display: flex;
	align-items: center;
	gap: 0.8rem;
`;

export const Divider = styled.div`
	width: 0.1rem;
	height: 2.4rem;
	background: ${(props) => props.theme.colors.gray90};
`;

export const RefreshIcon = styled.img<{ $isRotating: boolean }>`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;

	${({ $isRotating }) =>
		$isRotating &&
		css`
			animation: ${rotate} 1s linear;
		`}
`;

export const Icon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const RightSideMenu = styled.div`
	display: flex;
	align-items: center;
	gap: 1.6rem;
	justify-content: flex-start;
	padding-left: 1.6rem;
`;

export const TableButton = styled.button`
	display: flex;
	align-items: center;
	gap: 0.4rem;
	padding: 0.2rem 1.2rem 0.2rem 0.4rem;
	border-radius: 0.4rem;
	min-height: 3.2rem;
	border: none;
	background-color: ${(props) => props.theme.colors.primary60};
	cursor: pointer;

	& > img {
		filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
	}

	& > span {
		color: ${(props) => props.theme.colors.primary100};
		${(props) => props.theme.typography.bodyB};
		white-space: nowrap;
	}
`;

export const RefreshContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1.6rem;
`;
