import { CheckOption } from 'components/base/Selections/type';

export const OVERALL_OPINION_REVIEW_OPTION: CheckOption[] = [
	{
		label: '검수대기',
		value: 'CMN211.10',
	},
	{
		label: '수정필요',
		value: 'CMN211.20',
	},
	{
		label: '검수완료',
		value: 'CMN211.30',
	},
];

export const CHANGE_OPTION: CheckOption[] = [
	{
		label: '확인필요',
		value: 'CMN214.10',
	},
	{
		label: '해당없음',
		value: 'CMN214.20',
	},
	{
		label: '작성필요',
		value: 'CMN214.30',
	},
	{
		label: '작성완료',
		value: 'CMN214.40',
	},
];

export const SCHEDULE_REGISTRATION_OPTION: CheckOption[] = [
	{
		label: '미등록',
		value: 'CMN213.10',
	},
	{
		label: '미흡',
		value: 'CMN213.20',
	},
	{
		label: '등록완료',
		value: 'CMN213.30',
	},
];

export const TAG_STATUS_OPTION: CheckOption[] = [
	{
		label: '미신청',
		value: 'CMN215.10',
	},
	{
		label: '신청완료',
		value: 'CMN215.20',
	},
	{
		label: '수령가능',
		value: 'CMN215.30',
	},
	{
		label: '부착완료',
		value: 'CMN215.40',
	},
];

export const APPLICATION_STATUS_OPTION: CheckOption[] = [
	{
		label: '미발송',
		value: 'CMN212.10',
	},
	{
		label: '발송완료',
		value: 'CMN212.20',
	},
	{
		label: '승인거부',
		value: 'CMN212.30',
	},
	{
		label: '승인',
		value: 'CMN212.40',
	},
];
