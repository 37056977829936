// 임금관리 목록조회
import { BaseResponse, SortOrder } from './base';
import { PageResponse } from './common';

export interface Sort2 {
	empty: boolean;
	sorted: boolean;
	unsorted: boolean;
}

export interface Sort {
	empty: boolean;
	sorted: boolean;
	unsorted: boolean;
}

export interface Pageable {
	sort: Sort;
	offset: number;
	pageNumber: number;
	pageSize: number;
	paged: boolean;
	unpaged: boolean;
}

export interface SalaryServiceSummary {
	employeeSalaryId: number;
	totalServiceHourCnt: number;
	totalServiceWorkCnt: number;
	totalServiceSalaryAmt: number;
	visitCareServiceHourCnt: number;
	visitCareServiceWorkCnt: number;
	visitCareServiceSalaryAmt: number;
	visitBathServiceHourCnt: number;
	visitBathServiceWorkCnt: number;
	visitBathServiceSalaryAmt: number;
	visitNursingServiceHourCnt: number;
	visitNursingServiceWorkCnt: number;
	visitNursingServiceSalaryAmt: number;
}

export interface SalarySendHist {
	centerId: number;
	receiveTargetDivCd: string;
	receiveTargetDivNm: string;
	receiverId: number;
	sendDetailId: number;
	sendId: number;
	sendRequestEntityNm: string;
	sendRequestEntityIdNm: string;
	sendRequestEntityIdValue: number;
	edocSendId: number;
	sendDivCd: string;
	sendDivNm: string;
	burdenAmtPayStateCd: string;
	sendTemplateTypeNm: string;
	sendTitle: string;
	sendStateCd: string;
	sendStateNm: string;
	sendRequestDate: string;
	targetYm: string;
	sendUuid: string;
	edocNo: string;
}

export interface CashSendHist {
	centerId: number;
	receiveTargetDivCd: string;
	receiveTargetDivNm: string;
	receiverId: number;
	sendDetailId: number;
	sendId: number;
	sendRequestEntityNm: string;
	sendRequestEntityIdNm: string;
	sendRequestEntityIdValue: number;
	edocSendId: number;
	sendDivCd: string;
	sendDivNm: string;
	burdenAmtPayStateCd: string;
	sendTemplateTypeNm: string;
	sendTitle: string;
	sendStateCd: string;
	sendStateNm: string;
	sendRequestDate: string;
	targetYm: string;
	sendUuid: string;
	edocNo: string;
}

export interface SalaryServiceScheduleSummary {
	employeeSalaryId: number;
	serviceNm: string;
	recipientId?: number;
	recipientNm: string;
	totalWorkHours: number;
	totalWorkCount: number;
	totalSalaryAmt: number;
	div: number;
	totalWorkHourInfo: string;
}

export interface SalaryServiceSchedule {
	salaryServiceScheduleId: number;
	employeeSalaryId: number;
	salaryYm: string;
	employeeId: number;
	employeeNm: string;
	employeeBirthDt: string;
	providerNo: string;
	providerDivNm: string;
	centerId: number;
	recipientId: number;
	recipientNm: string;
	longTermNo: string;
	serviceTypeCd: string;
	serviceTypeNm: string;
	serviceKindCd: string;
	serviceKindNm: string;
	serviceKindDetailNm: string;
	serviceYm: string;
	serviceStartDt: string;
	serviceStartTime: string;
	serviceEndDt: string;
	serviceEndTime: string;
	serviceOfferHourCnt: number;
	familyYn: boolean;
	familyRelNm: string;
	serviceFeeNm: string;
	serviceFeeAmt: number;
	automationDivNm: string;
	serviceChangeNm: string;
	serviceNm: string;
	serviceFeeCd: string;
	longTermGradeCd: string;
	longTermGradeNm: string;
	workHourCnt: number;
	restHourCnt: number;
	nightWorkHourCnt: number;
	holidayWorkHourCnt: number;
	fixNightPay: number;
	fixHolidayPay: number;
	salaryAmt: number;
	basicSalaryAmt: number;
	serviceHourCnt: number;
	mealPay: number;
	mealEtcPay: number;
	nightPay: number;
	weekRestPay: number;
	annualPay: number;
	basicWeekRestAnnualPay: number;
	severePay: number;
	alldayVisitCareSalaryAmt: number;
	ordinaryWage: number;
	holidayPay: number;
	labordayPay: number;
	labordayWorkHourCnt: number;
}

export interface UpdateSalaryServiceScheduleForm extends SalaryServiceSchedule {
	transactionType?: 'UPDATE' | 'DELETE';
	pcorpServiceFeeCd?: string;
	original?: SalaryServiceSchedule;
}

export interface SalaryItem {
	salaryItemId: number;
	employeeSalaryId: number;
	salaryYm: string;
	centerId: number;
	employeeId: number;
	salaryItemCd: string;
	salaryItemAmt: number;
	salaryItemNm: string;
	salaryCertiItemNm: string;
	payDeductDivCd: string;
	payDeductDivNm: string;
	payDeductGroupNm: string;
	orderSeq: number;
	taxFreeYn: boolean;
	taxFreeLimitAmt: number;
	amtInputPosbYn: boolean;
	calculationFormulaDesc?: string;
	remark?: string;
}

export interface EmployeeSalaryFeeStandard {
	employeeSalaryFeeStandardId: number;
	employeeSalaryId: number;
	salaryYm: string;
	centerId: number;
	employeeId: number;
	salaryFeeCd: string;
	salaryFeeNm: string;
	salaryFeeTypeCd: string;
	salaryFeeTypeNm: string;
	orderSeq: number;
	screenDisplayYn: boolean;
	salaryFeeValue: string;
	salaryFeeStandardStartDate: string;
	salaryFeeStandardEndDate: string;
	serviceTypeCd?: string;
	serviceTypeNm?: string;
	serviceTypeDetailCd?: string;
	serviceTypeDetailNm?: string;
}

export interface BurdenAmtDeduct {
	burdenAmtDeductId: number;
	employeeSalaryId: number;
	salaryYm: string;
	centerId: number;
	employeeId: number;
	recipientId: number;
	recipientNm: string;
	visitCareBurdenAmt: string | number;
	visitNursingBurdenAmt: string | number;
	visitBathBurdenAmt: string | number;
	burdenAmt: number;
	burdenAmtDeductYn: boolean;
	burdenAmtDeductDt: string;
	burdenAmtDeductDesc: string;
}

export enum SalaryPayType {
	계좌이체 = 'CMN133.10',
	현금수령 = 'CMN133.20',
}

type SalaryPayTypeCd = (typeof SalaryPayType)[keyof typeof SalaryPayType];

export interface SalarysEmployeeSalarysContent {
	employeeSalaryId: number;
	salaryYm: string;
	centerId: number;
	centerNm: string;
	centerMark: string;
	centerAddr: string;
	centerZipCode: string;
	bizNo: string;
	representativeNm: string;
	employeeId: number;
	employeeNm: string;
	dutyCd: string;
	dutyNm: string;
	salaryCalculationTypeCd: string;
	salaryCalculationTypeNm: string;
	salaryPayTypeCd: SalaryPayTypeCd;
	salaryItemAmt3010: number; // 본인부담금
	salaryItemAmt3080: number; // 고용보험
	salaryPayTypeNm: string;
	birthDt: string;
	mobilePhoneNo: string;
	mobilePhoneNo4: string;
	serviceChangeNm: string;
	recipientNm: string;
	salaryAccountBankCd: string;
	salaryAccountBankNm: string;
	salaryAccountNo: string;
	salaryAccountDepositorNm?: string;
	salaryAccountValidYn: boolean;
	dementiaEduCompleteYn: boolean;
	paySumAmt: number;
	deductSumAmt: number;
	realPayAmt: number;
	companyLaborCost: number;
	employInsuranceRate: number;
	taxSalarySumAmt: number;
	taxFreeSalarySumAmt: number;
	weeklyAvgWorkHourCnt: number;
	extendWorkHourCnt: number;
	extendWorkPay: number;
	holidayWorkHourCnt: number;
	holidayWorkPay: number;
	nightWorkHourCnt: number;
	nightWorkPay: number;
	sundayWorkHourCnt: number;
	sundayWorkPay: number;
	totalWorkHourCnt: number;
	totalWorkDayCnt: number;
	accidentInsuranceAcqStateCd?: string;
	accidentInsuranceAcqStateNm?: string;
	accidentInsuranceAcqDate?: string;
	accidentInsuranceAmt: number;
	employInsuranceAcqStateCd?: string;
	employInsuranceAcqStateNm?: string;
	employInsuranceAcqDate?: string;
	employInsuranceAmt: number;
	healthInsuranceAcqStateCd?: string;
	healthInsuranceAcqStateNm?: string;
	healthInsuranceAcqDate?: string;
	healthInsuranceAmt: number;
	pensionInsuranceAcqStateCd?: string;
	pensionInsuranceAcqStateNm?: string;
	pensionInsuranceAcqDate?: string;
	pensionInsuranceAmt: number;
	managerId: number;
	managerNm?: string;
	disabilityCertiSubmitYn: boolean;
	salarySendStateCd: string;
	salarySendStateNm: string;
	cashSendStateCd: string;
	cashSendStateNm: string;
	insuranceSummary?: string;
	salarySendHists: SalarySendHist[];
	cashSendHists: CashSendHist[];
	salaryServiceScheduleSummarys: SalaryServiceScheduleSummary[];
	salaryServiceSchedules: SalaryServiceSchedule[];
	salaryItems: SalaryItem[];
	employeeSalaryFeeStandards: EmployeeSalaryFeeStandard[];
	burdenAmtDeducts: BurdenAmtDeduct[];
	salaryServiceSummary: SalaryServiceSummary;
	salaryServiceConcatSummary: string;
	centerStampFileObjectKey: string;
	entityNm: string;
	entityIdNm: string;
	entityIdValue: number;
	salaryCompleteYn: boolean;
	accidentInsuranceLossDate?: string;
	employInsuranceLossDate?: string;
	salaryMemo?: string;
	salarySendDate?: string;
	cashSendDate?: string;
	visitCareOffHourCnt: number;
	visitBathOffHourCnt: number;
	visitNursingOffHourCnt: number;
	labordayOffWorkPay: number;
}

export interface SalarysEmployeeSalarysData {
	content: SalarysEmployeeSalarysContent[];
	pageable: Pageable;
	totalPages: number;
	totalElements: number;
	last: boolean;
	size: number;
	number: number;
	sort: Sort2;
	numberOfElements: number;
	first: boolean;
	empty: boolean;
}

export type SalarysEmployeeSalaryListResponse = BaseResponse<SalarysEmployeeSalarysData>;
export interface SalarysEmployeeSalaryListRequestDTO {
	salaryYm: string;
	centerId?: number;
	managerIds?: number[];
	salaryCompleteYns?: boolean[];
	page: number;
	size: number;
	sortColumn?: string;
	sortOrder?: SortOrder;
	keyword?: string;
}

export type SalarysEmployeeSalaryDetailResponse = BaseResponse<SalarysEmployeeSalarysContent>;
export interface SalarysEmployeeSalaryDetailRequestDTO {
	employeeSalaryId: number;
}

/// //////////////////////////////////

// 청구엑셀 다운로드 자동화 요청
/// /////////////////////////////////////////////

export interface SalarysAutoBillingData {
	id: string;
	task: string;
	center_id: string;
	request: {
		centerId: string;
		yyyymm: string;
	};
	created_id: string;
	created_at: string;
	updated_id: string;
	updated_at: string;
}

export interface SalarysAutoBillingRequestDTO {
	centerId: string;
	yyyymm: string;
}

export type SalarysAutoBillingResponse = BaseResponse<SalarysAutoBillingData>;

/// //////////////////////////////////////////////////////

// 임금대장 다운로드 요청
/// /////////////////////////////////////////////////

export interface EmployeeSalarysPayrollData {
	employeeId: number;
	employeeNm: string;
	birthDt: string;
	rsdnNo: string;
	accidentInsuranceAcqDate?: string;
	dutyNm: string;
	totalWorkHourCnt: number;
	totalWorkDayCnt: number;
	salaryItemAmt2010: number;
	salaryItemAmt2015: number;
	salaryItemAmt2050: number;
	salaryItemAmt2020: number;
	salaryItemAmt2030: number;
	salaryItemAmt2052: number;
	salaryItemAmt2051: number;
	salaryItemAmt2060: number;
	salaryItemAmt2070: number;
	salaryItemAmt2080: number;
	salaryItemAmt2090: number;
	salaryItemAmt2110: number;
	salaryItemAmt2100: number;
	salaryItemAmt2120: number;
	salaryItemAmt2130: number;
	salaryItemAmt2140: number;
	salaryItemAmt2150: number;
	salaryItemAmt2160: number;
	salaryItemAmt2170: number;
	salaryItemAmt2180: number;
	salaryItemAmt2190: number;
	paySumAmt: number;
	salaryItemAmt3050: number;
	salaryItemAmt3060: number;
	salaryItemAmt3070: number;
	salaryItemAmt3080: number;
	salaryItemAmt3030: number;
	salaryItemAmt3040: number;
	salaryItemAmt3010: number;
	salaryItemAmt3090: number;
	salaryItemAmt3020: number;
	salaryItemAmt3025: number;
	salaryItemAmt3051: number;
	salaryItemAmt3061: number;
	salaryItemAmt3071: number;
	salaryItemAmt3081: number;
	deductSumAmt: number;
	realPayAmt: number;
	salaryItemAmt5010: number;
	salaryItemAmt5020: number;
	salaryItemAmt5030: number;
	salaryItemAmt5040: number;
	salaryItemAmt5050: number;
	salaryItemAmt5060: number;
	salaryItemAmt5070: number;
	visitCareSalaryAmt: number;
	visitBathSalaryAmt: number;
	visitNursingSalaryAmt: number;
	totalWorkConvertTime: number;
	visitCareConvertTime: number;
	visitBathConvertTime: number;
	visitNursingConvertTime: number;
	salaryAccountBankNm: string;
	salaryAccountNo: string;
	managerNm: string;
	rowNumber: number;
	visitCareBurdenAmt: number;
	visitBathBurdenAmt: number;
	visitNursingBurdenAmt: number;
}

export interface EmployeeSalarysPayrollDTO {
	centerId: number;
	salaryYm: string;
}

export type EmployeeSalarysPayrollResponse = BaseResponse<EmployeeSalarysPayrollData[]>;

/// //////////////////////////////////////////

// 대량이체 다운로드 요청
/// /////////////////////////////////////////////////

export interface EmployeeSalarysTransferData {
	employeeId: number;
	employeeNm: string;
	salaryAccountBankCd: string;
	salaryAccountBankNm: string;
	salaryAccountNo: string;
	salaryAccountDepositorNm: string;
	realPayAmt: number;
}

export interface EmployeeSalarysTransferDTO {
	centerId: number;
	salaryYm: string;
}

export type EmployeeSalarysTransferResponse = BaseResponse<EmployeeSalarysTransferData[]>;

/**
 * 임금 수정 저장
 */
export interface UpdateSalarysDetailRequestDTO {
	employeeSalaryId: number;
}
export type UpdateSalarysDetailResponse = BaseResponse<SalarysEmployeeSalarysData>;

/**
 * 임금 개별 재계산 처리
 *
 */
export interface UpdateSalarysCalculateRequestDTO {
	employeeSalaryId: number;
	inputValueKeepYn?: boolean;
}
export type UpdateSalarysCalculateResponse = BaseResponse<SalarysEmployeeSalarysData>;

/**
 * 임금 현금 수령증 발송
 */
export interface SendCashReceiveRequestDTO {
	employeeSalaryId: number;
}
export type SendCashReceiveResponse = BaseResponse<SalarysEmployeeSalarysData>;

/**
 * 임금 명세서 발송
 */
export interface SendEmployeeSalaryRequestDTO {
	employeeSalaryId: number;
}
export type SendEmployeeSalaryResponse = BaseResponse<SalarysEmployeeSalarysData>;

/**
 * 임금 개별 확정
 */
export interface CompleteEmployeeSalaryRequestDTO {
	employeeSalaryId: number;
	salaryCompleteYn: boolean;
}
export type CompleteEmployeeSalaryResponse = BaseResponse<SalarysEmployeeSalarysData>;

export interface DeleteEmployeeSalaryRequest {
	employeeSalaryId: number;
}

export type DeleteEmployeeSalaryResponse = BaseResponse<string>;

export interface DeleteEmployeeSalaryScheduleRequest {
	employeeSalaryId: number;
	scheduleId: number;
}

export type DeleteEmployeeSalaryScheduleResponse = BaseResponse<string>;

export type UpdateSalaryServiceScheduleDTO = {
	transactionType: 'UPDATE' | 'DELETE';
	salaryServiceScheduleId: number;
	serviceKindCd: string;
	serviceStartDate: string;
	serviceEndDate: string;
	familyYn: boolean;
};

export interface UpdateSalaryWorkSchedulesRequest {
	employeeSalaryId: number;
	scheduels: UpdateSalaryServiceScheduleDTO[];
}

export type UpdateSalaryWorkSchedulesResponse = BaseResponse<string>;

export interface UpdateEmployeeSalaryAfterSyncRequest {
	employeeSalaryId: number;
	inputValueKeepYn?: boolean;
	syncYn?: boolean;
	employeeSalarySaveRequest: SalarysEmployeeSalarysContent;
	salaryServiceScheduleWorkSaveRequests?: UpdateSalaryServiceScheduleDTO[];
}

export type UpdateEmployeeSalaryAfterSyncResponse = BaseResponse<string>;

export type ConfirmAllEmployeeSalaryRequest = {
	centerId: number;
	salaryYm: string;
	salaryCompleteYn: boolean;
};

export type ConfirmAllEmployeeSalaryData = string;

export type ConfirmAllEmployeeSalaryResponse = BaseResponse<ConfirmAllEmployeeSalaryData>;

export type RecalculateAllEmployeeSalaryRequest = {
	centerId: number;
	salaryYm: string;
};

export type RecalculateAllEmployeeSalaryData = string;

export type RecalculateAllEmployeeSalaryResponse = BaseResponse<RecalculateAllEmployeeSalaryData>;
