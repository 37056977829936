import React, { useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import dayjs from 'dayjs';

import RouterPath from 'common/router';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import {
	WorkExecuteLogCode,
	insuranceTypes,
} from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogBasicInfoForm';
import { VisitStatusCode } from 'components/domain/form/WorkExecuteLogForm/WorkExecuteLogVisitForm/constant';
import { ServiceTypeCd } from 'components/domain/table/RecordingSheetTable/serviceEdocParam';
import { commonCodeAdapter } from 'lib/adapter/common';
import {
	useCenterList,
	useCenterManagers,
	useCommonCodes,
	useEmployees,
	useMyAccountInfo,
} from 'lib/hook/react-query';
import { useUpdateRecipientWorkExecuteLog } from 'lib/hook/react-query/mutation/workExecuteLog';
import { useCenterChiefs } from 'lib/hook/react-query/query/centerInfo';
import { useRecipientConsultClient } from 'lib/hook/react-query/query/workExecuteLog';
import useRecipientPage from 'lib/hook/recipient/useRecipientPage';
import { endpoint } from 'lib/service/Api/endpoint';
import { displayPadTime } from 'lib/util';
import { ConsultClientGuardianInfo, ResponseCode } from 'types/api';
import { CommonCodeDTO } from 'types/api/common';
import {
	GetRecipientWorkExecuteLogNewDTO,
	UpdateRecipientWorkExecuteLogRequest,
} from 'types/api/workExecuteLog';
import { LastESignStateCd } from 'types/view/eDoc';
import { WorkExecuteLogData, WorkExecuteLogFormType } from 'types/view/workExecuteLog';

interface WorkExecuteLogContextType {
	form: UseFormReturn<WorkExecuteLogFormType>;
	commonCodes?: {
		[key: string]: CheckOption<CommonCodeDTO>[];
	};
	disabled: boolean;
	workExecuteLogNewData?: GetRecipientWorkExecuteLogNewDTO;
	advisorOptions?: ConsultClientGuardianInfo[];
	saveWorkExecuteLog?: () => void;
	validate?: (config?: { ignoreCheck?: boolean; isCreateDocument?: boolean }) => void;
	isLoaded?: boolean;
	setBaseData: React.Dispatch<React.SetStateAction<WorkExecuteLogData | undefined>>;
}

export const WorkExecuteLogContext = React.createContext<WorkExecuteLogContextType | null>(null);

function WorkExecuteLogProvider({ children }: { children: React.ReactNode }) {
	const { currentRecipient } = useRecipientPage();
	const [isInitForm, setIsInitForm] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const [isLoaded] = useState(false);
	const [baseData, setBaseData] = useState<WorkExecuteLogData>();
	const params = useParams<{ id?: string; detailId?: string }>();
	const { data: myAccountInfo } = useMyAccountInfo();
	const { data: centerManager } = useCenterManagers({ centerId: myAccountInfo?.centerId });
	const { data: centers } = useCenterList();
	const { data: consultClient } = useRecipientConsultClient({
		centerId: myAccountInfo?.centerId,
		recipientId: params.id,
	});
	const { data: centerChiefs } = useCenterChiefs();
	const { data: employeesData } = useEmployees({
		centerIds: myAccountInfo?.centerId ? [myAccountInfo.centerId] : [],
	});

	const disabled = baseData
		? baseData?.data?.lastEsignStateCd
			? ![LastESignStateCd.서명취소, LastESignStateCd.완료].includes(
					baseData.data.lastEsignStateCd as LastESignStateCd,
				)
			: false
		: true;

	const employeesOptions = useMemo(
		() =>
			(employeesData ?? []).map(
				(item) =>
					({
						label: item.korMemberNm,
						value: {
							id: item.employeeId,
							name: item.korMemberNm,
							duty: item.dutyNms,
							birth: item.birthDt,
						},
					}) as CheckOption,
			),
		[employeesData],
	);

	const centerChiefOptions = useMemo(
		() =>
			centerChiefs
				?.filter((item) => item?.centerId === myAccountInfo?.centerId)
				?.map((item) => ({
					label: item.userNm,
					value: item.memberAccountId,
				})) || [],
		[centerChiefs, myAccountInfo],
	);

	const advisorOptions =
		consultClient?.[0] && consultClient?.[0].guardians?.length
			? [
					...consultClient[0].guardians.filter((item) => !item.selfGuardianYn),
					{
						clientDivCd: consultClient?.[0]?.clientDivCd,
						clientId: consultClient?.[0]?.clientId,
						clientNm: consultClient?.[0]?.clientNm,
						clientBirth: consultClient?.[0]?.clientBirth,
					},
				]
			: [];

	const isNew = useMemo(() => {
		if (params.detailId && typeof !Number.isNaN(Number(params.detailId))) {
			return false;
		}
		return true;
	}, [params.detailId]);

	const centerOption = (centers ?? []).map(
		(item) => ({ label: item.centerChiefNm, value: item.centerChiefId }) as CheckOption,
	);

	const centerManagerOption = (centerManager ?? []).map(
		(item) => ({ label: item.userNm, value: item.memberAccountId }) as CheckOption,
	);

	const saveWorkExecuteLogMutation = useUpdateRecipientWorkExecuteLog((client) => {
		client.invalidateQueries([endpoint.getRecipientWorkExecuteLogDetail.key]);
		client.invalidateQueries([endpoint.getWorkExecuteLog.key]);
	});

	const form = useForm<WorkExecuteLogFormType>({
		defaultValues: {
			workExecuteLogDivCd: [],
			workExecuteLogStateCd: undefined,
			visitDt: undefined,
			visitor:
				centerManagerOption.find((item) => item.value === myAccountInfo?.memberAccountId) ||
				undefined,
			visitTime: undefined,
			facilityManager: undefined,
			visitSchedule: undefined,
			visitPlaceNm: '수급자 가정',
			salaryOfferVisitYn: undefined,
			consultDesc: '',
		},
	});

	const { data: commonCodes } = useCommonCodes(
		{
			comCdGroupNms: ['CMN006', 'CMN183', 'CMN184'],
		},
		commonCodeAdapter,
	);

	const serviceTypeOption =
		commonCodes?.CMN006?.filter((item) => item.data?.etcDesc1 === 'Y') || [];

	const validate = () => {
		const initHash = (target: string) => {
			navigate(target);
			setTimeout(() => {
				navigate(location.pathname, {
					replace: true,
				});
			});
		};

		if (!form.getValues('workExecuteLogDivCd')?.length) {
			Toast.error('기본정보 - 구분을 입력해주세요.');
			initHash('#1');
			return false;
		}
		if (!form.getValues('workExecuteLogStateCd')) {
			Toast.error('방문정보 - 상태를 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('visitDt')) {
			Toast.error('방문정보 - 방문(상담) 일시 - 날짜를 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (form.formState.errors?.visitTime?.message) {
			Toast.error('방문정보 - 방문(상담) 시간을 확인해주세요');
			initHash('#2');
			return false;
		}

		if (!form.getValues('visitTime')?.startTime) {
			Toast.error('방문 정보 - 방문(상담) 시작 시간을 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (form.getValues('visitTime')?.startTime?.length !== 5) {
			Toast.error('방문 정보 - 방문(상담) 시작 시간을 확인해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('visitTime')?.endTime) {
			Toast.error('방문 정보 - 방문(상담) 종료 시간을 입력해주세요.');
			initHash('#2');
			return false;
		}

		if (form.getValues('visitTime')?.endTime?.length !== 5) {
			Toast.error('방문 정보 - 방문(상담) 종료 시간을 확인해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('serviceTypeCd')?.length) {
			Toast.error('방문정보 - 급여 종류를 선택해주세요.');
			initHash('#2');
			return false;
		}

		if (!form.getValues('visitor')) {
			Toast.error('방문정보 - 방문자를 입력해주세요');
			initHash('#2');
			return false;
		}

		if (!form.getValues('facilityManager')) {
			Toast.error('방문정보 - 시설장(관리책임자)을 입력해주세요');
			initHash('#2');
			return false;
		}

		if (!form.getValues('visitPlaceNm')) {
			Toast.error('방문정보 - 방문장소를 입력해주세요');
			initHash('#2');
			return false;
		}

		if (
			form.getValues('employee1') &&
			form.getValues('employee2') &&
			form.getValues('employee1')?.value?.id === form.getValues('employee2')?.value?.id
		) {
			Toast.error('급여제공자1 과 급여제공자2가 같을 수 없습니다.');
			initHash('#5');
			return false;
		}

		return true;
	};

	const goBackPage = () => {
		navigate(
			`${RouterPath.recipient().tab.key}/${params.id}/${
				RouterPath.recipient().workExecuteLogTab.uniqueKey
			}`,
			{
				replace: true,
			},
		);
	};

	const isSameDate = (startTime: string, endTime: string) => {
		// 시간을 숫자로 변환
		const parseTime = (time: string) => {
			const hours = parseInt(time.slice(0, -2), 10); // 앞 두자리 (시간)
			const minutes = parseInt(time.slice(-2), 10); // 뒤 두자리 (분)
			return hours * 60 + minutes; // 분 단위로 변환
		};

		const start = parseTime(startTime);
		const end = parseTime(endTime);

		return start <= end;
	};

	const saveWorkExecuteLog = async () => {
		if (saveWorkExecuteLogMutation.isLoading) return;

		if (!validate() || !myAccountInfo?.centerId || !baseData) return;

		const param: UpdateRecipientWorkExecuteLogRequest = {
			workExecuteLogDivCd: form.getValues('workExecuteLogDivCd')?.[0]?.value,
			workExecuteLogStateCd: form.getValues('workExecuteLogStateCd')?.value,
			centerId: myAccountInfo.centerId,
			recipientId: baseData?.recipient.recipientId,
			recipientNm: baseData.recipient.recipientNm,
			recipientBirthDt: baseData.recipient.birthDt,
			recipientGenderCd: baseData.recipient.genderCd,
			longTermNo: baseData.recipient.longTermNo || '',
			longTermMajorChangeNo: baseData.recipient.longTermMajorChangeNo || '',
			longTermMinorChangeNo: baseData.recipient.longTermMinorChangeNo || '',
			longTermGradeCd: baseData.recipient.longTermGradeCd || '',
			visitorId: form.getValues('visitor')?.value,
			facilityManagerNm: form.getValues('facilityManager')?.label || '',
			facilityManagerId: form.getValues('facilityManager')?.value,
			visitDt: dayjs(form.getValues('visitDt')).format('YYYYMMDD'),
			startTime: form.getValues('visitTime.startTime')?.replace(':', ''),
			endTime: form.getValues('visitTime.endTime')?.replace(':', ''),
			serviceStartDt: form.getValues('serviceDt') || undefined,
			serviceStartTime: form.getValues('serviceTime.startTime')?.replace(':', '') || undefined,
			serviceEndDt:
				form.getValues('serviceTime.startTime') && form.getValues('serviceTime.endTime')
					? isSameDate(
							form.getValues('serviceTime.startTime'),
							form.getValues('serviceTime.endTime'),
						)
						? form.getValues('serviceDt')
						: dayjs(form.getValues('serviceDt')).add(1, 'day').format('YYYYMMDD')
					: form.getValues('serviceDt') || undefined,
			serviceEndTime: form.getValues('serviceTime.endTime')?.replace(':', '') || undefined,
			visitPlaceNm: form.getValues('visitPlaceNm') || '',
			salaryOfferVisitYn: form.getValues('salaryOfferVisitYn')?.[0]?.value,
			serviceTypeCd: form.getValues('serviceTypeCd')?.[0]?.value,
			clientDivCd: form.getValues('client')?.[0]?.data?.clientDivCd,
			clientId: form.getValues('client')?.[0]?.data?.clientId,
			clientPhoneNo: form.getValues('client')?.[0]?.data?.telNum,
		};

		if (form.getValues('employee1')?.value?.id) {
			param.employeeId1 = form.getValues('employee1')?.value?.id;
			if (
				form.getValues('serviceTypeCd')?.[0]?.value === ServiceTypeCd.방문목욕 &&
				form.getValues('employee2')?.value?.id
			) {
				param.employeeId2 = form.getValues('employee2')?.value?.id;
			}
		}

		if (baseData?.data?.workExecuteLogId) {
			param.workExecuteLogId = baseData.data.workExecuteLogId;
		}

		if (form.getValues('visitDenyDesc')) {
			param.visitDenyDesc = form.getValues('visitDenyDesc');
		}

		if (form.getValues('client')?.[0] && form.getValues('client')?.[0]?.data?.mainGuardianYn) {
			param.clientMainGuardianYn = true;
		}

		if (form.getValues('consultDesc')) {
			param.consultDesc = form.getValues('consultDesc');
		}

		if (form.getValues('workExecuteLogDivCd')?.[0].value === WorkExecuteLogCode.프로그램관리자) {
			if (form.getValues('recogStimulationNeedDesc')) {
				param.recogStimulationNeedDesc = form.getValues('recogStimulationNeedDesc');
			}
			if (form.getValues('recogStimulationProvisionMethodDesc')) {
				param.recogStimulationProvisionMethodDesc = form.getValues(
					'recogStimulationProvisionMethodDesc',
				);
			}
			if (form.getValues('physicalAbilityNeedDesc')) {
				param.physicalAbilityNeedDesc = form.getValues('physicalAbilityNeedDesc');
			}
			if (form.getValues('physicalAbilityProvisionMethodDesc')) {
				param.physicalAbilityProvisionMethodDesc = form.getValues(
					'physicalAbilityProvisionMethodDesc',
				);
			}
		}
		if (form.getValues('salaryrecogActpgOfferConfirmDesc')) {
			param.salaryrecogActpgOfferConfirmDesc = form.getValues('salaryrecogActpgOfferConfirmDesc');
		}
		if (form.getValues('salaryrecogActpgOfferActionDesc')) {
			param.salaryrecogActpgOfferActionDesc = form.getValues('salaryrecogActpgOfferActionDesc');
		}

		if (form.getValues('physicalConditionDesc')) {
			param.physicalConditionDesc = form.getValues('physicalConditionDesc');
		}

		if (form.getValues('diseaseDesc')) {
			param.diseaseDesc = form.getValues('diseaseDesc');
		}

		if (form.getValues('recogStateDesc')) {
			param.recogStateDesc = form.getValues('recogStateDesc');
		}

		if (form.getValues('communicationDesc')) {
			param.communicationDesc = form.getValues('communicationDesc');
		}

		if (form.getValues('nutritionalStateDesc')) {
			param.nutritionalStateDesc = form.getValues('nutritionalStateDesc');
		}

		if (form.getValues('familyEnvironmentDesc')) {
			param.familyEnvironmentDesc = form.getValues('familyEnvironmentDesc');
		}

		if (form.getValues('totalOpinionDesc')) {
			param.totalOpinionDesc = form.getValues('totalOpinionDesc');
		}

		if (form.getValues('salaryGoalDesc')) {
			param.salaryGoalDesc = form.getValues('salaryGoalDesc');
		}

		if (form.getValues('needSalaryDesc')) {
			param.needSalaryDesc = form.getValues('needSalaryDesc');
		}

		if (form.getValues('provisionMethodDesc')) {
			param.provisionMethodDesc = form.getValues('provisionMethodDesc');
		}

		const result = await saveWorkExecuteLogMutation.mutateAsync(param);
		if (result?.code === ResponseCode.SUCCESS) {
			setIsInitForm(false);
			form.reset();
			Toast.success('업무수행일지를 저장했습니다.');
			goBackPage();
		}
	};

	useEffect(() => {
		if (
			baseData &&
			!isInitForm &&
			commonCodes &&
			centerOption?.length &&
			advisorOptions?.length &&
			centerChiefOptions?.length &&
			serviceTypeOption?.length &&
			employeesOptions?.length
		) {
			const defaultForm: WorkExecuteLogFormType = {
				physicalConditionDesc: `${isNew ? '[도움 필요 항목]\n' : ''}${
					baseData?.recipientIa?.physicalConditionDesc || ''
				}`,
				client: [],
				diseaseDesc: baseData?.recipientIa?.diseaseDesc || '',
				recogStateDesc: baseData?.recipientIa?.recogStateDesc || '',
				communicationDesc: baseData?.recipientIa?.communicationDesc || '',
				nutritionalStateDesc: baseData?.recipientIa?.nutritionalStateDesc || '',
				familyEnvironmentDesc: baseData?.recipientIa?.familyEnvironmentDesc || '',
				totalOpinionDesc: baseData?.recipientIa?.totalOpinionDesc || '',
				salaryGoalDesc: baseData?.salaryOfferPlan?.salaryGoalDesc || '',
				needSalaryDesc: baseData?.salaryOfferPlan?.needSalaryDesc || '',
				provisionMethodDesc: baseData?.salaryOfferPlan?.provisionMethodDesc || '',
			};
			if (baseData.data) {
				const workExecuteLogDivCd = commonCodes.CMN183.find(
					(item) => item.value === baseData.data?.workExecuteLogDivCd,
				);
				const workExecuteLogStateCd = commonCodes.CMN184.find(
					(item) => item.value === baseData.data?.workExecuteLogStateCd,
				);
				const visitDt = dayjs(baseData.data.visitDt).toDate();
				const time = {
					startTime: displayPadTime(baseData.data.startTime),
					endTime: displayPadTime(baseData.data.endTime),
				};
				const facilityManager = centerChiefOptions.find(
					(item) => item.value === baseData.data?.facilityManagerId,
				);
				const employeeId = [baseData.data?.employeeId1, baseData.data?.employeeId2]
					.filter(Boolean)
					.join(',');
				const employee1 = employeesOptions?.find(
					(item) => item.value?.id === baseData.data?.employeeId1,
				);
				const employee2 = employeesOptions?.find(
					(item) => item.value?.id === baseData.data?.employeeId2,
				);

				const serviceTypeCd = serviceTypeOption?.find(
					(item) => item.value === baseData.data?.serviceTypeCd,
				);

				const visitSchedule =
					baseData.data.serviceStartDt &&
					baseData.data.serviceTypeCd &&
					baseData.data.serviceStartTime &&
					baseData.data.serviceEndTime
						? {
								label: '',
								value:
									employeeId +
									baseData.data.serviceStartDt +
									baseData.data.serviceTypeCd +
									baseData.data.serviceStartTime +
									baseData.data.serviceEndTime,
								data: {
									employeeId,
									serviceStartDt: baseData.data.serviceStartDt,
									serviceTypeCd: baseData.data.serviceTypeCd,
									serviceStartTime: baseData.data.serviceStartTime,
									serviceEndTime: baseData.data.serviceEndTime,
								},
							}
						: undefined;

				const client = [
					{
						label: '',
						value: baseData.data.clientId,
						data: {
							clientDivCd: baseData.data.clientDivCd,
							clientId: baseData.data.clientId,
							telNum: baseData.data.clientPhoneNo,
						},
					},
				];

				const salaryOfferVisitYn =
					baseData.data?.salaryOfferVisitYn === false
						? [
								{
									label: '',
									value: false,
								},
							]
						: baseData.data?.salaryOfferVisitYn === true
							? [
									{
										label: '',
										value: true,
									},
								]
							: undefined;

				const serviceTime = {
					startTime: baseData.data.serviceStartTime
						? displayPadTime(baseData.data.serviceStartTime)
						: '',
					endTime: baseData.data.serviceEndTime ? displayPadTime(baseData.data.serviceEndTime) : '',
				};

				defaultForm.workExecuteLogDivCd = workExecuteLogDivCd ? [workExecuteLogDivCd] : [];
				defaultForm.workExecuteLogStateCd = workExecuteLogStateCd;
				defaultForm.visitDt = visitDt;
				defaultForm.visitor =
					centerManagerOption.find((item) => item.value === baseData.data?.visitorId) || undefined;
				defaultForm.visitTime = time;
				defaultForm.facilityManager = facilityManager;
				defaultForm.visitSchedule = visitSchedule ? [visitSchedule] : [];
				defaultForm.visitPlaceNm = baseData.data?.visitPlaceNm || '';
				defaultForm.salaryOfferVisitYn = salaryOfferVisitYn;
				defaultForm.visitDenyDesc = baseData.data.visitDenyDesc;
				defaultForm.client = baseData.data.clientId ? client : [];
				defaultForm.consultDesc = baseData.data.consultDesc;

				defaultForm.salaryrecogActpgOfferConfirmDesc =
					baseData.data.salaryrecogActpgOfferConfirmDesc || '';
				defaultForm.salaryrecogActpgOfferActionDesc =
					baseData.data.salaryrecogActpgOfferActionDesc || '';

				defaultForm.physicalConditionDesc = baseData.data.physicalConditionDesc;
				defaultForm.diseaseDesc = baseData.data.diseaseDesc;
				defaultForm.recogStateDesc = baseData.data.recogStateDesc;
				defaultForm.communicationDesc = baseData.data.communicationDesc;
				defaultForm.nutritionalStateDesc = baseData.data.nutritionalStateDesc;
				defaultForm.familyEnvironmentDesc = baseData.data.familyEnvironmentDesc;
				defaultForm.totalOpinionDesc = baseData.data.totalOpinionDesc;
				defaultForm.salaryGoalDesc = baseData.data.salaryGoalDesc;
				defaultForm.needSalaryDesc = baseData.data.needSalaryDesc;
				defaultForm.provisionMethodDesc = baseData.data.provisionMethodDesc;
				defaultForm.recogStimulationNeedDesc = baseData.data.recogStimulationNeedDesc;
				defaultForm.recogStimulationProvisionMethodDesc =
					baseData.data.recogStimulationProvisionMethodDesc;
				defaultForm.physicalAbilityNeedDesc = baseData.data.physicalAbilityNeedDesc;
				defaultForm.physicalAbilityProvisionMethodDesc =
					baseData.data.physicalAbilityProvisionMethodDesc;
				defaultForm.employee1 = employee1;
				defaultForm.employee2 = employee2;
				defaultForm.serviceTypeCd = serviceTypeCd ? [serviceTypeCd] : undefined;
				defaultForm.serviceDt = baseData.data.serviceStartDt || '';
				defaultForm.serviceTime = serviceTime;
			} else {
				defaultForm.workExecuteLogDivCd =
					baseData.recipient.longTermGradeCd !== 'CMN042.5'
						? [insuranceTypes[0]]
						: [insuranceTypes[1]];

				defaultForm.facilityManager = centerOption?.[0];
				defaultForm.workExecuteLogStateCd = commonCodes?.CMN184?.find(
					(item) => item.value === VisitStatusCode.방문예정, // 초기 방문예정으로 설정
				);
			}

			form.reset(defaultForm);
			setIsInitForm(true);
		}
	}, [
		baseData,
		commonCodes,
		isInitForm,
		centerOption,
		isNew,
		advisorOptions,
		centerChiefOptions,
		employeesOptions,
	]);

	useEffect(() => {
		if (currentRecipient && !currentRecipient.mainGuardianNm) {
			goBackPage();
		}
	}, [currentRecipient]);

	const value = useMemo(
		() => ({
			form,
			commonCodes: {
				...commonCodes,
				centerManagerOption,
				centerOption,
				centerChiefOptions,
				serviceTypeOption,
				employeesOptions,
			},
			saveWorkExecuteLog,
			workExecuteLogNewData: baseData,
			validate,
			isLoaded,
			setBaseData,
			advisorOptions: advisorOptions || undefined,
			disabled,
		}),
		[
			form,
			commonCodes,
			centerManagerOption,
			saveWorkExecuteLog,
			isLoaded,
			baseData,
			setBaseData,
			advisorOptions,
			serviceTypeOption,
			disabled,
		],
	);

	return <WorkExecuteLogContext.Provider value={value}>{children}</WorkExecuteLogContext.Provider>;
}
export default React.memo(WorkExecuteLogProvider);
