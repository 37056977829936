import React, { useState } from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import InformationTable from 'components/ui/InformationTable';
import { InformationTableItemType } from 'components/ui/InformationTable/type';
import {
	useBurdenPayHistoryDetail,
	useCancelCashReceipt,
	useIssueCashReceipt,
	useSendBurdenCharge,
} from 'lib/hook/react-query';
import { useHasFunc } from 'lib/hook/util/useHasFunc';
import { CashReceiptForm, OwnExpensePayHistory } from 'types/view/ownExpense';

import { displayBirthDay, displayShortDate } from '../../../../lib';
import useDialog from '../../../../lib/hook/util/useDialog';
import { endpoint } from '../../../../lib/service/Api/endpoint';
import { ResponseCode } from '../../../../types/api';
import { BurdenChargeDTO } from '../../../../types/dto';
import CRStatus from '../../../base/CRStatus';
import { Toast } from '../../../base/CRToast';
import CashReceiptDialog from '../CashReceiptDialog';
import DefaultDialog from '../DefaultDialog';
import EDocNoDialog from '../EdocNoDialog';
import * as S from './styles';

interface Props {
	item: OwnExpensePayHistory;
	onClickClose?: () => void;
}

function OwnExpensePayDetailDialog({ item, onClickClose }: Props): React.ReactElement {
	const hasCreateCashReceiptFunc = useHasFunc(['own_expense:create_cash_receipt']);
	const hasDeleteCashReceiptFunc = useHasFunc(['own_expense:delete_cash_receipt']);
	const { showDialog } = useDialog();
	const [monthDate, setMonthDate] = useState(dayjs());
	const { data, isLoading } = useBurdenPayHistoryDetail({
		centerId: item.centerId,
		recipientId: item.recipientId,
		targetYear: Number(monthDate.format('YYYY')),
	});
	const { mutateAsync: sendBurdenCharge } = useSendBurdenCharge((client) => {
		client.invalidateQueries([
			endpoint.getBurdenPayHistoryDetail.key,
			{
				centerId: item.centerId,
				recipientId: item.recipientId,
				targetYear: Number(monthDate.format('YYYY')),
			},
		]);
	});

	const issueCashReceipt = useIssueCashReceipt((client) => {
		client.invalidateQueries([
			endpoint.getBurdenPayHistoryDetail.key,
			{
				centerId: item.centerId,
				recipientId: item.recipientId,
				targetYear: Number(monthDate.format('YYYY')),
			},
		]);
	});

	const cancelCashReceipt = useCancelCashReceipt((client) => {
		client.invalidateQueries([
			endpoint.getBurdenPayHistoryDetail.key,
			{
				centerId: item.centerId,
				recipientId: item.recipientId,
				targetYear: Number(monthDate.format('YYYY')),
			},
		]);
	});

	const handleClickPrevMonth = () => {
		setMonthDate(monthDate.subtract(1, 'year'));
	};

	const handleClickNextMonth = () => {
		setMonthDate(monthDate.add(1, 'year'));
	};

	const handleClickPreview = (data: BurdenChargeDTO) => {
		showDialog(() => <EDocNoDialog dialogType='M' edocNo={data.edocNo} />);
	};

	const handleConfirmIssueCashReceipt =
		(data: BurdenChargeDTO) => async (form: CashReceiptForm) => {
			await issueCashReceipt.mutateAsync({
				centerId: item.centerId,
				bizNo: data.bizNo,
				targetDivCd: 'CMN118.20',
				targetId: data.recipientId,
				targetYm: data.burdenAmtChargeYm,
				cashReceiptRequestEntityIdValue: data.burdenAmtChargeId,
				cashReceiptIssuerNm: form.name,
				cashReceiptIssueMethodCd: form.issuerNo.type.value,
				cashReceiptIssuerNo: form.issuerNo.number,
				cashReceiptTargetDt: data.burdenAmtChargeDt,
				cashReceiptRequestAmt: Number(data.burdenAmt),
				cashReceiptRequestKindCd: 'CMN166.10',
				remark: `${data.burdenAmtChargeYm.substring(0, 4)}-${data.burdenAmtChargeYm.substring(
					4,
					6,
				)} ${data.centerNm} ${data.recipientNm} ${(data.burdenAmt ?? 0).toLocaleString()}`,
			});
		};

	const handleClickIssueCashReceipt = async (data: BurdenChargeDTO) => {
		showDialog(({ hideDialog }) => (
			<CashReceiptDialog
				title={`${data.burdenAmtChargeYm.substring(0, 4)}년 ${data.burdenAmtChargeYm.substring(
					4,
					6,
				)}월 현금영수증 발행`}
				item={data}
				onSubmit={handleConfirmIssueCashReceipt(data)}
				hideDialog={hideDialog}
			/>
		));
	};

	const handleConfirmCancelCashReceipt = async (data: BurdenChargeDTO) => {
		await cancelCashReceipt.mutateAsync({
			cashReceiptId: data.cashReceipt.cashReceiptId,
			bizNo: data.bizNo,
			targetYm: data.cashReceipt.targetYm,
		});
	};

	const handleClickCancelCashReceipt = async (data: BurdenChargeDTO) => {
		showDialog(({ hideDialog }) => (
			<CashReceiptDialog
				title={`${data.burdenAmtChargeYm.substring(0, 4)}년 ${data.burdenAmtChargeYm.substring(
					4,
					6,
				)}월 현금영수증 발행`}
				item={data}
				hideDialog={hideDialog}
				onSubmit={() => handleConfirmCancelCashReceipt(data)}
				isCancel
			/>
		));
	};

	const handleClickSendBurdenCharge = (data: BurdenChargeDTO) => {
		const successCallback = async (hideDialog: () => void) => {
			const res = await sendBurdenCharge({
				burdenAmtChargeId: data.burdenAmtChargeId,
			});
			if (res.code === ResponseCode.SUCCESS) {
				Toast.success('정상적으로 발송 요청을 하였습니다.');
			} else {
				Toast.error('발송 요청을 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.');
			}
			hideDialog();
		};

		const date = dayjs(data.burdenAmtChargeYm).format('YYYY년 M월');
		const content = `${data.recipientNm}(${data.mobilePhoneNo})님에게 ${date} 본인부담금 납부 고지서를 발송할 수 있도록 요청합니다.`;

		showDialog(({ hideDialog }) => (
			<DefaultDialog
				title='납부 고지서를 발송 요청 하시겠습니까?'
				content={content}
				hideDialog={hideDialog}
				successOption={{
					text: '발송 요청',
					successCallback: () => successCallback(hideDialog),
				}}
				cancelOption={{
					text: '취소',
				}}
			/>
		));
	};

	if (isLoading) {
		return <div />;
	}

	if (!data) {
		onClickClose?.();
		return <div />;
	}

	return (
		<CRDialog
			type='M'
			title='본인부담금 납부 상세'
			body={
				<S.Container>
					<S.SectionContainer>
						<S.SectionTitle>수급자 기본 정보</S.SectionTitle>
						<InformationTable
							items={[
								[
									{
										label: '이름',
										value: data.recipientNm,
									},
									{
										label: '생년월일',
										value: displayBirthDay(data.birthDt),
									},
									{
										label: '현금영수증',
										value: data.cashReceiptIssueYn ? '대상자' : '비대상자',
									},
								],
								[
									{
										label: '본인부담금 총액',
										value: (data.burdenAmtChargeSumAmt ?? 0).toLocaleString(),
									},
									{
										label: '납부 총액',
										value: (data.burdenAmtPaySumAmt ?? 0).toLocaleString(),
									},
									{
										label: '납부 상태',
										value: (
											<CRStatus
												options={[
													{
														key: 'CMN135.30',
														label: '완납',
														color: 'green',
													},
													{
														key: 'CMN135.40',
														label: '과납',
														color: 'blue',
													},
													{
														key: 'CMN135.10',
														label: '미납',
														color: 'red',
													},
												]}>
												{data.burdenAmtPayStateCd}
											</CRStatus>
										),
									},
								],
								[
									{
										label: '납부 방식',
										value: data.burdenAmtPayMethodNm,
									},
									{
										label: '사회복지사',
										value: data.managerNm,
									},
									{
										type: 'labelValueNull',
									},
								],
							]}
						/>
					</S.SectionContainer>
					<S.SectionContainer>
						<S.SectionTitle>청구/입출금 이력</S.SectionTitle>
						<S.HeaderButtonContainer>
							<S.Icon
								onClick={handleClickPrevMonth}
								src={Assets.icon.keyboardArrowLeft}
								alt='keyboardArrowLeft'
							/>
							<S.MonthDate>
								{dayjs(monthDate).format('YYYY년')}
								<S.Icon src={Assets.icon.calendarToday} />{' '}
							</S.MonthDate>
							<S.Icon
								onClick={handleClickNextMonth}
								src={Assets.icon.keyboardArrowRight}
								alt='keyboardArrowRight'
							/>
						</S.HeaderButtonContainer>
						<S.HorizontalScrollContainer>
							<S.HorizontalScrollContentContainer>
								<InformationTable
									items={[
										[
											{
												type: 'label',
												label: '청구 일자',
												labelStyle: {
													width: '10rem',
												},
											},
											{
												type: 'label',
												label: '연월',
												labelStyle: {
													width: '20rem',
												},
											},
											{
												type: 'label',
												label: '본인부담금',
												labelStyle: {
													width: '10rem',
													textAlign: 'right',
												},
											},
											{
												type: 'label',
												label: '발송 요청 일시',
												labelStyle: {
													width: '15rem',
												},
											},
											{
												type: 'label',
												label: '현금영수증',
												labelStyle: {
													width: '22rem',
												},
											},
											{
												type: 'label',
												label: '',
												labelStyle: {
													width: '25rem',
												},
											},
										],
										...data.burdenAmtCharges.map(
											(charges) =>
												[
													{
														type: 'value',
														value: displayShortDate(charges.burdenAmtChargeDt),
													},
													{
														type: 'value',
														value: dayjs(displayShortDate(charges.burdenAmtChargeYm)).format(
															'YYYY년 MM월',
														),
													},
													{
														type: 'value',
														value: (charges.burdenAmt ?? 0).toLocaleString(),
														valueStyle: {
															textAlign: 'right',
														},
													},
													{
														type: 'value',
														value: charges.sendDate
															? dayjs(charges.sendDate).format('YYYY.MM.DD HH:mm')
															: '',
													},
													{
														type: 'value',
														value: (
															<CRStatus
																options={[
																	{ key: 'CMN167.10', label: '대기', color: 'yellow' },
																	{ key: 'CMN167.20', label: '발행중', color: 'yellow' },
																	{ key: 'CMN167.90', label: '완료', color: 'green' },
																	{ key: 'CMN167.99', label: '취소완료', color: 'red' },
																	{ key: 'CMN167.30', label: '취소중', color: 'red' },
																]}>
																{charges.cashReceiptIssueStateCd}
															</CRStatus>
														),
													},
													{
														type: 'value',
														value: charges.sendDate ? (
															<S.ButtonContainer>
																<CRButton.Default
																	size='xSmall'
																	type='outlined'
																	palette='gray'
																	onClick={() => handleClickPreview(charges)}>
																	미리보기
																</CRButton.Default>
																<CRButton.Default
																	size='xSmall'
																	type='outlined'
																	palette='gray'
																	onClick={() => handleClickSendBurdenCharge(charges)}>
																	명세서 발송
																</CRButton.Default>
																{['CMN167.30', 'CMN167.90'].includes(
																	charges.cashReceiptIssueStateCd,
																) ? (
																	<CRButton.Default
																		size='xSmall'
																		type='outlined'
																		disabled={
																			charges.cashReceiptIssueStateCd === 'CMN167.30' ||
																			!hasDeleteCashReceiptFunc
																		}
																		onClick={() => handleClickCancelCashReceipt(charges)}>
																		영수증 취소
																	</CRButton.Default>
																) : (
																	<CRButton.Default
																		size='xSmall'
																		type='outlined'
																		palette='gray'
																		disabled={
																			charges.cashReceiptIssueStateCd === 'CMN167.20' ||
																			!hasCreateCashReceiptFunc
																		}
																		onClick={() => handleClickIssueCashReceipt(charges)}>
																		영수증 발행
																	</CRButton.Default>
																)}
															</S.ButtonContainer>
														) : (
															<div />
														),
													},
												] as InformationTableItemType[],
										),
										[
											{
												type: 'value',
												value: '총액',
												colSpan: 2,
												valueStyle: {
													borderRight: 'none',
												},
											},
											{
												type: 'value',
												value: data.burdenAmtCharges
													.reduce((sum, item) => sum + item.burdenAmt, 0)
													.toLocaleString(),
												valueStyle: {
													fontWeight: '700',
													textAlign: 'right',
												},
											},
											{ type: 'labelValueNull', colSpan: 3 },
										],
									]}
								/>
							</S.HorizontalScrollContentContainer>
						</S.HorizontalScrollContainer>
						<S.HorizontalScrollContainer>
							<InformationTable
								items={[
									[
										{
											type: 'label',
											label: '납부 일자',
											labelStyle: {
												width: '20%',
											},
										},
										{
											type: 'label',
											label: '금액',
											labelStyle: {
												width: '20%',
												textAlign: 'right',
											},
										},
										{
											type: 'label',
											label: '납부 방식',
										},
										{
											type: 'label',
											label: '메모',
										},
									],
									...data.burdenAmtPayDetails.map(
										(detail) =>
											[
												{
													type: 'value',
													value: dayjs(detail.burdenAmtDt).format('YYYY.MM.DD'),
												},
												{
													type: 'value',
													value: (detail.burdenAmt ?? 0).toLocaleString(),
													valueStyle: {
														textAlign: 'right',
													},
												},
												{
													type: 'value',
													value: detail.burdenAmtPayMethodNm,
												},
												{
													type: 'value',
													value: detail.burdenAmtDesc,
												},
											] as InformationTableItemType[],
									),
									[
										{
											type: 'value',
											value: '총액',
											valueStyle: {
												borderRight: 'none',
											},
										},
										{
											type: 'value',
											value: data.burdenAmtPayDetails
												.reduce((sum, item) => sum + item.burdenAmt, 0)
												.toLocaleString(),
											valueStyle: {
												fontWeight: '700',
												textAlign: 'right',
											},
										},
										{ type: 'labelValueNull' },
									],
								]}
							/>
						</S.HorizontalScrollContainer>
					</S.SectionContainer>
				</S.Container>
			}
			onClickClose={onClickClose}
		/>
	);
}

export default OwnExpensePayDetailDialog;
