import React from 'react';
import { Control, Controller } from 'react-hook-form';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { CreateEmployReportForm } from 'types/view/employReport';

interface Props {
	control: Control<CreateEmployReportForm, any>;
	renderRightButton?: React.ReactElement;
}

function EmploymentReportLicenseInfoForm({
	control,
	renderRightButton,
}: Props): React.ReactElement {
	const renderErrorMessage = (error: boolean, message: string) =>
		error ? (
			<CRText color='primary60' typography='label'>
				{message}
			</CRText>
		) : null;

	return (
		<CRCardFormLayout label='자격증' renderRightButton={renderRightButton}>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						label='자격증 종류'
						isRequired
						message={renderErrorMessage(!!errors.licenseType, errors.licenseType?.message ?? '')}>
						<CRInput.Default
							placeholder='자격증 종류 입력'
							value={value}
							onChange={onChange}
							status={errors.licenseType ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='licenseType'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						label='자격면허번호'
						isRequired
						message={renderErrorMessage(!!errors.licenseNo, errors.licenseNo?.message ?? '')}>
						<CRInput.Default
							placeholder='예: 2020-1234567'
							onChange={onChange}
							value={value}
							status={errors.licenseNo ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='licenseNo'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='자격취득일자'
						message={renderErrorMessage(
							!!errors.licenseAcqDate,
							errors.licenseAcqDate?.message ?? '',
						)}>
						<CRInput.DatePicker
							value={value}
							onChangeValue={onChange}
							placeholder='자격취득일자 선택'
							status={errors.licenseAcqDate ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='licenseAcqDate'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						label='자격증 파일'
						isRequired
						message={renderErrorMessage(!!errors.licenseFile, errors.licenseFile?.message ?? '')}>
						<CRInput.FileUploader
							onChange={(files) => onChange({ ...value, fileDetails: files })}
							files={value?.fileDetails}
							placeholder='파일을 끌어오거나 업로드'
							status={errors.licenseFile ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='licenseFile'
				control={control}
			/>
		</CRCardFormLayout>
	);
}

export default EmploymentReportLicenseInfoForm;
