import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';

import Colors from 'common/colors';
import CRButton from 'components/base/CRButton';
import CRChips from 'components/base/CRChips';
import CRSpinner from 'components/base/CRSpinner';
import CRTable from 'components/base/CRTable';
import CRTableHeader from 'components/base/CRTableHeader';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import CRTableMonthSelector from 'components/base/CRTableMonthSelector';
import { CRText } from 'components/base/CRText';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import RecipientContractReviewTable from 'components/domain/table/RecipientContractReviewTable';
import { centerListAdapter, commonCodeAdapter } from 'lib/adapter/common';
import { recipientContractReviewAdapter } from 'lib/adapter/contractReview';
import {
	useCommonCenters,
	useCommonCodes,
	useRecipientContractList,
	useSaveContractReview,
} from 'lib/hook/react-query';
import { endpoint } from 'lib/service/Api/endpoint';
import { ResponseCode } from 'types/api';
import { SaveContractReviewRequest } from 'types/api/contractReview';
import { PageInfo } from 'types/view/base';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';
import { Filter } from 'types/view/filter';

import { CONTRACT_REVIEW_RECIPIENT_TASK_CONFIG, RecipientContractReviewTaskType } from './constant';
import * as S from './styles';

function RecipientNewContractTab(): React.ReactElement {
	const [checkedList, setCheckedList] = useState<RecepientContractReviewListViewType[]>([]);

	const [date, setDate] = useState(dayjs().toDate());
	const [pageInfo, setPageInfo] = useState<PageInfo>(defaultPageInfo);
	const [keyword, setKeyword] = useState('');
	const [searchValue, setSearchValue] = useState('');
	const [currentStatus, setCurrentStatus] = useState<CheckOption>();
	const [currentStatusValue, setCurrentStatusValue] = useState<CheckOption>();
	const [currentTask, setCurrentTask] = useState<RecipientContractReviewTaskType>(
		CONTRACT_REVIEW_RECIPIENT_TASK_CONFIG[0],
	);

	const [currentFilter, setCurrentFilter] = useState<{
		sameDayContractFilter?: { label: string; value: boolean }[];
		totalWorkStatusFilter?: { label: string; value: boolean }[];
		contractTypeFilter?: { label: string; value: string }[];
		centerFilter?: { label: string; value: number }[];
		salaryPlanCompletionStatusFilter?: { label: string; value: boolean }[];

		serviceStartDateFilter?: { label: string; value: string }[];
		contractCompletionStatusFilter?: { label: string; value: boolean }[];
		integratedAssessmentCompletionStatusFilter?: { label: string; value: boolean }[];
		admissionApplicationStatusFilter?: { label: string; value: boolean }[];
		individualCarePlanCompletionStatusFilter?: { label: string; value: boolean }[];
		publicScheduleRegistrationStatusFilter?: { label: string; value: string }[];
		salaryChangeReasonCompletionStatusFilter?: { label: string; value: string }[];
		employeeReplacementConsultationStatusFilter?: { label: string; value: string }[];
		admissionApplicationTargetFilter?: { label: string; value: boolean }[];
		tagCompletionStatusFilter?: { label: string; value: boolean }[];
	}>({
		contractTypeFilter: [{ label: '신규계약', value: 'CMN136.10' }],
	});

	const { data: centerOptions } = useCommonCenters(centerListAdapter);
	const { data: commonCodes = { CMN153: [], CMN136: [], CMN213: [], CMN214: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN153', 'CMN136', 'CMN213', 'CMN214'] },
		commonCodeAdapter,
	);

	const {
		data: recipientContractData,
		isLoading,
		refetch,
	} = useRecipientContractList(
		{
			contractTypeCd: currentFilter?.contractTypeFilter?.[0]?.value || undefined,
			yearMonth: dayjs(date).format('YYYYMM'),
			centerIds: currentFilter?.centerFilter?.map((item) => item.value) || undefined,
			// contractStartDate: dayjs(date).format('YYYY-MM-DD'),
			sameDayContractYn: currentFilter?.sameDayContractFilter?.[0]?.value ?? undefined,
			totalCompleteYn: currentFilter.totalWorkStatusFilter?.[0].value || undefined,
			// 계약서 완료 여부 필터 추가 필요
			salaryPlanComplateYn: currentFilter.salaryPlanCompletionStatusFilter?.[0].value || undefined,
			integratedEvaluationCompleteYn:
				currentFilter.integratedAssessmentCompletionStatusFilter?.[0].value || undefined,
			employeeReplacementCounselingCd:
				currentFilter.employeeReplacementConsultationStatusFilter?.[0].value || undefined,
			recipientAdmissionCompleteYn:
				currentFilter.admissionApplicationStatusFilter?.[0].value || undefined,
			// 입소이용신청서 대상 여부 필터 추가 필요
			longtermPlanRequestYn:
				currentFilter.individualCarePlanCompletionStatusFilter?.[0].value || undefined,
			pcorpScheduleCd: currentFilter.publicScheduleRegistrationStatusFilter?.[0].value || undefined,
			salaryChangeStatCd:
				currentFilter.salaryChangeReasonCompletionStatusFilter?.[0].value || undefined,
			tagCompleteYn: currentFilter.tagCompletionStatusFilter?.[0].value || undefined,
			page: pageInfo.page,
			size: pageInfo.size,
			keyword,
		},
		recipientContractReviewAdapter,
	);

	const { mutate: saveRecipientContractReview } = useSaveContractReview(
		(client, returnData, variables) => {
			if (returnData?.code === ResponseCode.SUCCESS) {
				Toast.success(
					`총 ${checkedList.length}건 ${currentStatusValue?.label}상태로 변경하였습니다.`,
				);
				client.invalidateQueries([endpoint.getEmployeeContractReviewList.key]);
				setCurrentStatus(undefined);
				setCurrentStatusValue(undefined);
				setCheckedList([]);
			} else {
				Toast.error(
					`총 ${checkedList.length}건 변경에 실패했습니다. 잠시 후 다시 시도해 주시기 바랍니다.`,
				);
			}
		},
	);

	const filters = useMemo(
		() =>
			[
				{
					key: 'sameDayContractFilter',
					type: 'toggle',
					placeholder: '당일 계약',
					options: [{ label: '당일 계약', value: true }],
				},
				{
					key: 'contractTypeFilter',
					type: 'single',
					placeholder: '계약 유형',
					options: commonCodes.CMN136,
				},
				{
					key: 'totalWorkStatusFilter',
					type: 'single',
					placeholder: '상태',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
				{
					key: 'centerFilter',
					type: 'multi',
					placeholder: '소속센터',
					options: centerOptions,
				},
				{
					key: 'serviceStartDateFilter',
					type: 'day_calendar',
					placeholder: '급여 시작일',
					value: [
						{
							label: 'rangeFilter',
							value: {
								start: dayjs(date).startOf('M').toDate(),
								end: dayjs(date).endOf('M').toDate(),
							},
						},
					],
				},
				// 급여계약 검수
				{
					key: 'contractCompletionStatusFilter',
					type: 'multi',
					placeholder: '수급자 계약서',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
				{
					key: 'salaryPlanCompletionStatusFilter',
					type: 'multi',
					placeholder: '급여제공계획서',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
				{
					key: 'integratedAssessmentCompletionStatusFilter',
					type: 'single',
					placeholder: '통합사정',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
				{
					key: 'salaryChangeReasonCompletionStatusFilter',
					type: 'multi',
					placeholder: '급여제공 변경사유서',
					options: commonCodes.CMN214,
				},
				// 직원 변경
				{
					key: 'employeeReplacementConsultationStatusFilter',
					type: 'multi',
					placeholder: '직원교체상담일지',
					options: commonCodes.CMN214,
				},
				// 입소이용신청
				{
					key: 'admissionApplicationStatusFilter',
					type: 'multi',
					placeholder: '입소이용신청',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
				{
					key: 'admissionApplicationTargetFilter',
					type: 'multi',
					placeholder: '입소이용신청 대상',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
				// 개인별 이용계획서
				{
					key: 'individualCarePlanCompletionStatusFilter',
					type: 'single',
					placeholder: '개인별이용계획서',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
				// 공단 등록
				{
					key: 'publicScheduleRegistrationStatusFilter',
					type: 'single',
					placeholder: '공단일정등록',
					options: commonCodes.CMN213,
				},
				{
					key: 'tagCompletionStatusFilter',
					type: 'single',
					placeholder: '태그 완료 여부',
					options: [
						{ label: '완료', value: true },
						{ label: '미완료', value: false },
					],
				},
			] as Filter<CheckOption>[],
		[commonCodes, centerOptions],
	);

	const filterHandler = useCallback(() => {
		const currentFilter: Filter<CheckOption>[] = currentTask.filterKey
			.map((item) => filters.find((filter) => filter.key === item))
			.filter((filter): filter is Filter<CheckOption> => filter !== undefined);
		return [...filters.slice(0, 4), ...currentFilter];
	}, [currentTask, filters]);

	const handleSearch = () => {
		setKeyword(searchValue);
	};

	const handleChangeDate = (newDate: Date) => {
		setDate(newDate);
	};

	const handleSearchValue = (value: string) => setSearchValue(value);

	const handleChangePageInfo = (pageInfo: PageInfo) => {
		setPageInfo(pageInfo);
	};

	const handleSaveEmployeeContractReviewStatus = async () => {
		if (!currentStatusValue || !currentStatus) return;
		const currentData: SaveContractReviewRequest = checkedList
			.filter((item) => item.recipientId && item.serviceContractId && item.centerId)
			.map((item) => ({
				serviceContractId: item.serviceContractId,
				centerId: item.centerId,
				employeeId: undefined,
				recipientId: item.recipientId,
				contractMngtId: item.contractMngtId,

				salaryPlanCompleteCd: item.longtermCareBenefitPlanOverallOpinionReview,
				notiToPcorpYn: item.longtermCareBenefitPlanNotificationToCorporation,
				integratedEvaluationCompleteYn: item.integratedAssessmentCompletionStatus,
				admissionDispatchCd: item.admissionApplicationSendApprovalStatus,
				longtermPlanRequestYn: item.appliedIndividualLongtermCarePlan,
				pcorpScheduleCd: item.registeredLongtermCareBenefitScheduleToCorporation,
				salaryChangeStatCd: item.longtermCareBenefitPlanReasonForChange,
				employeeReplacementCounselingCd: item.employeeChangeConsultationWritingStatus,
				tagRequestTaskCd: item.tagAttachmentStatus,
				remark: item.adminMemo,

				[currentStatus.value]: currentStatusValue.value,
			}));

		await saveRecipientContractReview(currentData);
	};

	const renderEmpty = useMemo(
		() => (
			<S.EmptyContainer>
				<S.EmptyTitle>신규계약 내역이 없습니다.</S.EmptyTitle>
				<S.EmptyDescription>
					해당 월에 추가된 신규계약이 없습니다.
					<br />
					연월 또는 필터를 다시 설정해 주세요.
				</S.EmptyDescription>
			</S.EmptyContainer>
		),
		[],
	);

	const renderCustomFilter = useMemo(
		() =>
			checkedList.length > 0 && currentTask.statusSelectionItem ? (
				<S.SelectionContainer>
					{currentTask.statusSelectionItem && (
						<CRChips.Selection
							containerStyle={{
								width: '16rem',
								backgroundColor: currentStatus ? Colors.gray100 : undefined,
								borderColor: Colors.gray90,
								overflow: 'hidden',
								whiteSpace: 'nowrap',
								textOverflow: 'ellipsis',
							}}
							hasArrowIcon
							options={currentTask.statusSelectionItem}
							placeholder='업무 선택'
							filterKey='single'
							currentValue={currentStatus ? [currentStatus] : undefined}
							labelColor={currentStatus ? 'gray00' : 'gray60'}
							onChangeValue={(value) => {
								setCurrentStatus(value);
								setCurrentStatusValue(undefined);
							}}
						/>
					)}
					{currentTask.statusSelectionItem && (
						<CRChips.Selection
							containerStyle={{
								width: '16rem',
								backgroundColor: currentStatus ? Colors.gray100 : undefined,
								borderColor: Colors.gray90,
							}}
							hasArrowIcon
							disabled={!currentStatus}
							options={currentStatus?.data}
							placeholder='값 선택'
							filterKey='single'
							currentValue={currentStatusValue ? [currentStatusValue] : undefined}
							labelColor={currentStatusValue ? 'gray00' : 'gray60'}
							onChangeValue={setCurrentStatusValue}
						/>
					)}
					<CRButton.Default
						palette='gray'
						size='small'
						style={{ padding: '0 1.2rem', borderRadius: '0.8rem' }}
						disabled={!(currentStatusValue && currentStatus)}
						onClick={handleSaveEmployeeContractReviewStatus}>
						변경
					</CRButton.Default>
					<CRText text={`(${checkedList.length}개 선택)`} color='gray60' />
					<S.Divider />
				</S.SelectionContainer>
			) : undefined,
		[checkedList, currentTask, currentStatus, currentStatusValue],
	);

	useEffect(() => {
		// TODO: 표 목록 데이터 불러올 때 pageInfo state 변경
	}, []);

	useEffect(() => {
		if (checkedList.length === 0) {
			setCurrentStatus(undefined);
			setCurrentStatusValue(undefined);
		}
	}, [checkedList]);

	return (
		<S.Container>
			{/* 컴포넌트화 필요 */}
			<S.TaskCategorySelectorContainer>
				{CONTRACT_REVIEW_RECIPIENT_TASK_CONFIG.map((item, index) => (
					<React.Fragment key={item.value}>
						<CRButton.Default
							type={item.value === currentTask.value ? 'filled' : 'outlined'}
							palette='gray'
							size='default'
							style={{ padding: '0.6rem 1.6rem' }}
							onClick={() => setCurrentTask(item)}>
							<CRText
								typography='body'
								color={item.value === currentTask.value ? 'white' : 'gray00'}>
								{item.label}
							</CRText>
						</CRButton.Default>
						{index === 0 && <S.Divider />}
					</React.Fragment>
				))}
			</S.TaskCategorySelectorContainer>
			<CRTable.BackBlind>
				<S.YearMonthPickerContainer>
					<CRTableMonthSelector value={date} onChange={handleChangeDate} />
				</S.YearMonthPickerContainer>
				<CRTableHeader
					pageInfo={pageInfo}
					onChangePageInfo={handleChangePageInfo}
					currentFilter={currentFilter}
					setCurrentFilter={setCurrentFilter}
					stickyMode
					filters={filterHandler()}
					searchValue={searchValue}
					onChangeSearchValue={handleSearchValue}
					onSearch={handleSearch}
					onRefresh={refetch}
					showRefresh
					placeholder='수급자명 검색'
					showViewCount
					renderCustomFilter={renderCustomFilter}
				/>
				{isLoading ? (
					<CRSpinner />
				) : recipientContractData && recipientContractData.recipientContractList.length > 0 ? (
					<RecipientContractReviewTable
						checkedList={checkedList}
						setCheckedList={setCheckedList}
						recipientContractData={recipientContractData.recipientContractList}
						tableRenderKeys={currentTask.tableRenderKeys}
						sideModalDefaultActiveKey={currentTask.sideModalActiveKey}
					/>
				) : (
					renderEmpty
				)}
			</CRTable.BackBlind>
		</S.Container>
	);
}

export default RecipientNewContractTab;
