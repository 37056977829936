import React from 'react';
import { Control, Controller } from 'react-hook-form';

import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import { CRText } from 'components/base/CRText';
import { CreateEmployReportForm } from 'types/view/employReport';

interface Props {
	control: Control<CreateEmployReportForm>;
}

function ResignReportAdditionalInfoForm({ control }: Props): React.ReactElement {
	const renderErrorMessage = (error: boolean, message: string) =>
		error ? (
			<CRText color='primary60' typography='label'>
				{message}
			</CRText>
		) : null;

	return (
		<CRCardFormLayout label='기타 정보'>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='변경내용'
						message={renderErrorMessage(
							!!errors.updateContent,
							errors.updateContent?.message ?? '',
						)}>
						<CRInput.TextArea
							value={value}
							onChange={onChange}
							placeholder='변경내용 입력'
							numberOfLines={1}
							status={errors.updateContent ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='updateContent'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='인력변경사유'
						message={renderErrorMessage(!!errors.updateReason, errors.updateReason?.message ?? '')}>
						<CRInput.TextArea
							value={value}
							onChange={onChange}
							placeholder='인력변경사유 입력'
							numberOfLines={1}
							status={errors.updateReason ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='updateReason'
				control={control}
			/>
			<Controller
				render={({ field: { onChange, value }, formState: { errors } }) => (
					<CRInputLabel
						isRequired
						label='인력변경사유 상세'
						message={renderErrorMessage(
							!!errors.updateReasonDetail,
							errors.updateReasonDetail?.message ?? '',
						)}>
						<CRInput.TextArea
							value={value}
							onChange={onChange}
							placeholder='인력변경사유 상세 입력'
							numberOfLines={1}
							status={errors.updateReasonDetail ? 'error' : 'default'}
						/>
					</CRInputLabel>
				)}
				name='updateReasonDetail'
				control={control}
			/>
		</CRCardFormLayout>
	);
}

export default ResignReportAdditionalInfoForm;
