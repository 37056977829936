import { Dayjs } from 'dayjs';

import { CheckOption } from 'components/base/Selections/type';
import {
	GetCenterWorkScheduleStatusData,
	GetEmployeeWorkHistoryResponseData,
	GetEmployeeWorkScheduleData,
} from 'types/api';
import {
	CenterDefaultWorkTimeDTO,
	CenterEmployeeWorkScheduleStatusDTO,
	FtimeEmployeeAnnualStateResponseDTO,
	GetEmployeeDefaultWorkTimeDTO,
	WorkLeaveInfoDTO,
	WorkScheduleInfoDTO,
} from 'types/dto';
import {
	EmployeeAnnualStateDTO,
	EmployeeWorkLeavesInfoDTO,
	FtimeEmployeeInfoDTO,
	HolidayDTO,
} from 'types/dto/employee';

export interface DefaultWorkTimeViewType {
	days: string;
	workHour: string;
	data: GetEmployeeDefaultWorkTimeDTO;
}

export interface CenterDefaultWorkTimeViewType {
	days: string;
	workHour: string;
	data: CenterDefaultWorkTimeDTO;
}

export type EmployeeBasicWorkInfoViewType = {
	korMemberNm: string;
	dutyNm: string;
	mobilePhoneNo: string;
	workExecuteActingEmployeeNm: string;
	joinDate: string;
	employState: string;
	data: FtimeEmployeeInfoDTO;
};

export enum ActualWorkLabelType {
	WorkActual = 'WorkActual',
	WorkError = 'WorkError',
}

export type EmployeeActualWorkDataViewType = {
	workDt: string;
	label: string;
	type: ActualWorkLabelType;
	overworkTimeCnt: number;
	data: EmployeeWorkLeavesInfoDTO;
};

export type WorkScheduleListViewType = {
	employeeBasicInfoData: EmployeeBasicWorkInfoViewType;
	actualWorkData: EmployeeActualWorkDataViewType[];
	holidayData: HolidayDTO[];
	annualStateData: EmployeeAnnualStateDTO[];
	data: GetEmployeeWorkScheduleData;
};

export type CenterWorkScheduleListViewType = {
	centerNm: string;
	searchDuration: string;
	dataByName: {
		korMemberNm: string;
		dutyNm: string;
		workData: {
			date: string;
			workType: string;
			employeeWorkData: CenterEmployeeWorkScheduleStatusDTO;
		}[];
	}[];
	originalData: GetCenterWorkScheduleStatusData;
};

export type EmployeeVacationInfoViewType = {
	vacationDate: string;
	categoryOfVacation?: CheckOption;
	tagText: string;
	data: WorkScheduleInfoDTO;
};

export type EmployeeActualWorkViewType = {
	workDt?: Dayjs;
	workStartTime?: Dayjs;
	workStartPlace?: CheckOption;
	workStartRemark?: string;
	leaveDt?: Dayjs;
	workEndTime?: Dayjs;
	workEndPlace?: CheckOption;
	workEndRemark?: string;
	data: WorkLeaveInfoDTO;
};
export type EmployeeWorkHistoryViewType = {
	labelText: string;
	tagText: string;
	startTime: Dayjs;
	endTime: Dayjs;
	workHourCnt: number;
	overWorkTimeCnt: number;
	data: WorkScheduleInfoDTO;
};

export type EmployeeDailyWorkHistoryViewType = {
	date: Dayjs;
	workHistoryInfo: EmployeeWorkHistoryViewType;
	actualWorkInfo: EmployeeActualWorkViewType;
	vacationInfo: EmployeeVacationInfoViewType[];
	holidayInfo: { data: HolidayDTO }[];
	confirmed: boolean;
	shouldRenderNoHistoryLabel: boolean;
	totalOverWorkTimeOfMonth: number;
	totalWorkTimeCntOfTargetWeek: number;
	maxRoundingEndTime?: Dayjs;
	minRoundingStartTime?: Dayjs;
	initialEmployeeActualWorkHourCnt: number;
	managerTagInfo?: {
		roundingDt: Dayjs;
		startTime: string;
		endTime: string;
	}[];
	workExecuteLogInfo?: {
		roundingDt: Dayjs;
		startTime: string;
		endTime: string;
		recipientName: string;
		longtermNumber: string;
	}[];
};

export type EmployeeWorkHistoryDataViewType = {
	employeeBasicInfo: EmployeeBasicWorkInfoViewType;
	dailyWorkHistory: EmployeeDailyWorkHistoryViewType[];
	annualStateInfo: FtimeEmployeeAnnualStateResponseDTO;
	data: GetEmployeeWorkHistoryResponseData;
};
