import React, {
	ChangeEvent,
	KeyboardEvent,
	ReactElement,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react';

import dayjs from 'dayjs';

import Assets from 'assets';
import CRChips from 'components/base/CRChips';
import CRSearch from 'components/base/CRSearch';
import { defaultPageInfo } from 'components/base/CRTableHeader/constant';
import { CRText } from 'components/base/CRText';
import { match } from 'lib';
import { PageInfo } from 'types/view/base';
import { Filter, FilterType } from 'types/view/filter';

import CRInput from '../CRInput';
import { CheckOption } from '../Selections/type';
import * as S from './styles';

interface IProps<T extends { label: string; value: any }> {
	filters?: Filter<T>[];
	searchValue?: string;
	placeholder?: string;
	pageInfo?: PageInfo;
	stickyMode?: boolean;
	offset?: number;
	renderRightButton?: ReactElement;
	renderCustomFilter?: ReactElement;
	showViewCount?: boolean;
	currentFilter: {
		[key: string]: T[];
	};
	showRefresh?: boolean;
	setCurrentFilter?: any;
	onChangeSearchValue?: (searchValue: string) => void;
	onChangePageInfo?: (pageInfo: PageInfo) => void;
	onSearch?: () => void;
	onRefresh?: () => void;
	hideSearchButton?: boolean;
	bottomPadding?: boolean;
}

function CRTableHeader<T extends { label: string; value: any }>({
	filters = [],
	searchValue,
	pageInfo = defaultPageInfo,
	placeholder,
	stickyMode = false,
	offset = 0,
	renderRightButton,
	renderCustomFilter,
	showViewCount = false,
	currentFilter,
	showRefresh = false,
	setCurrentFilter,
	onChangeSearchValue,
	onChangePageInfo,
	onSearch,
	onRefresh,
	hideSearchButton = false,
	bottomPadding = true,
}: IProps<T>): React.ReactElement {
	const [isRotating, setIsRotating] = useState(false);
	const [top, setTop] = useState(0);
	const ref = useRef<HTMLDivElement>(null);
	const totalPage = useMemo(() => pageInfo?.totalPages ?? 0, [pageInfo?.totalPages]);

	const currentPageNumber = useMemo(() => {
		if (!Number.isNaN(pageInfo?.page)) return Number(pageInfo?.page);
		return 1;
	}, [pageInfo?.page]);

	const onChangePageIndexInput = (event: ChangeEvent<HTMLInputElement>) => {
		onChangePageInfo?.({
			size: pageInfo.size,
			totalPages: pageInfo.totalPages,
			page: Number(event.target.value),
		});
	};

	const onChangePageSize = (item: CheckOption) => {
		onChangePageInfo?.({
			...pageInfo,
			page: 1,
			size: item.value,
		});
	};

	const handleBlurCurrentPage = () => {
		if (currentPageNumber === 0) {
			onChangePageInfo?.({
				...pageInfo,
				page: currentPageNumber + 1,
			});
		}
	};

	const onClickChangePage = (diff: number) => {
		if (pageInfo.page + diff < 1 || pageInfo.page + diff > totalPage) return;
		onChangePageInfo?.({
			...pageInfo,
			page: pageInfo.page + diff,
		});
	};

	const calculateInputWidth = (pageCount: number) => {
		if (pageCount < 10) {
			return '1.2rem';
		}
		if (pageCount < 100) {
			return '2.4rem';
		}
		if (pageCount < 1000) {
			return '3.4rem';
		}
		if (pageCount < 10000) {
			return '4.6rem';
		}
		if (pageCount < 100000) {
			return '5.8rem';
		}
		return '7rem';
	};

	const onChangeFilter = (item: T, key: string, filterType: FilterType) => {
		const prevFilter = Object.keys(currentFilter).length === 0 ? {} : { ...currentFilter };

		if (filterType === 'single') {
			const newItems = { ...prevFilter };
			const exist = prevFilter[key]?.find((selectedItem) => selectedItem.value === item.value);
			if (exist) {
				delete newItems[key];
			} else {
				newItems[key] = [item];
			}
			setCurrentFilter({
				...newItems,
			});
		} else if (['multi', 'toggle'].includes(filterType)) {
			if (!prevFilter[key]) {
				setCurrentFilter({
					...prevFilter,
					[key]: [item],
				});
			} else if (item.value === undefined) {
				// 해당필터 초기화의 경우
				delete prevFilter.key;
				const removedKeyFilter: { [key: string]: T[] } = {};
				Object.entries(prevFilter).forEach(([label, value]) => {
					if (label !== key) {
						removedKeyFilter[label] = value;
					} else {
						removedKeyFilter[label] = [];
					}
				});
				setCurrentFilter(removedKeyFilter);
			} else {
				const newItems = { ...prevFilter };
				const exist = prevFilter[key].find((selectedItem) => selectedItem.value === item.value);
				if (exist) {
					newItems[key] = prevFilter[key]?.filter((prevItem) => prevItem.value !== item.value);
				} else {
					newItems[key] = [...prevFilter[key], item];
				}
				setCurrentFilter(newItems);
			}
		} else if (['calendar', 'month_calendar', 'day_calendar'].includes(filterType)) {
			setCurrentFilter({
				...prevFilter,
				[key]: [item],
			});
		}
	};

	const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (['e', 'E', '+', '-', 'ArrowUp', 'ArrowDown'].includes(e.key)) {
			e.preventDefault();
		}
	};

	const onClickRefresh = () => {
		setIsRotating(true);
		setTimeout(() => {
			setIsRotating(false);
		}, 1000); // 1초 후 애니메이션 종료
		onRefresh?.();
	};

	// TODO: 자동으로 top 위치 가져오도록 기능 수정 예정, 해당 코드에 대한 주석 필요함
	useLayoutEffect(() => {
		const newTop =
			(ref.current?.getBoundingClientRect().top ?? 0) -
			(ref.current?.parentElement?.getBoundingClientRect().top ?? 0);
		setTop(newTop + offset);
	}, [offset]);

	return (
		<S.Container
			ref={ref}
			$stickyMode={stickyMode}
			style={stickyMode ? { top } : undefined}
			$bottomPadding={bottomPadding}>
			<S.LeftSideMenu>
				{renderCustomFilter && renderCustomFilter}
				{filters?.length > 0 &&
					filters?.map((filter) =>
						match(filter.type)
							.on(
								(type) => ['single', 'multi'].includes(type),
								() => (
									<CRChips.Selection
										key={filter.key}
										filterKey={filter.key}
										onChangeValue={onChangeFilter}
										currentValue={currentFilter?.[filter?.key]}
										options={filter.options}
										placeholder={filter.placeholder}
										filterType={filter.type}
									/>
								),
							)
							.on(
								(type) => type === 'calendar',
								() => (
									<CRChips.Calendar
										disabled={filter.disabled}
										key={filter.key}
										filterKey={filter.key}
										onChangeValue={onChangeFilter}
										placeholder={filter.placeholder}
										currentValue={currentFilter?.[filter?.key] || filter.value}
										selected={dayjs().subtract(12, 'month').toDate()}
									/>
								),
							)
							.on(
								(type) => type === 'single_range_calendar',
								() => (
									<CRChips.Calendar
										disabled={filter.disabled}
										key={filter.key}
										filterKey={filter.key}
										onChangeValue={onChangeFilter}
										placeholder={filter.placeholder}
										currentValue={currentFilter?.[filter?.key] || filter.value}
										singleMonth
									/>
								),
							)
							.on(
								(type) => type === 'month_calendar',
								() => (
									<CRChips.MonthCalendar
										key={filter.key}
										filterKey={filter.key}
										onChangeValue={onChangeFilter}
										placeholder={filter.placeholder}
										currentValue={currentFilter?.[filter?.key]}
									/>
								),
							)
							.on(
								(type) => type === 'day_calendar',
								() => (
									<CRChips.DayCalendar
										key={filter.key}
										filterKey={filter.key}
										onChangeValue={onChangeFilter}
										placeholder={filter.placeholder}
										currentValue={currentFilter?.[filter?.key]}
										minDate={filter?.props?.minDate}
										maxDate={filter?.props?.maxDate}
									/>
								),
							)
							.on(
								(type) => type === 'search',
								() => (
									<CRChips.Search
										key={filter.key}
										filterKey={filter.key}
										onChangeValue={onChangeFilter}
										placeholder={filter.placeholder}
										currentValue={currentFilter?.[filter?.key]}
									/>
								),
							)
							.otherwise(() => (
								<CRChips.Default
									key={filter.key}
									options={filter.options}
									filterKey={filter.key}
									onChangeValue={onChangeFilter}
									currentValue={currentFilter?.[filter?.key]}
								/>
							)),
					)}
			</S.LeftSideMenu>
			<S.RightSideMenu>
				{showRefresh && (
					<S.RefreshContainer>
						<S.RefreshIcon
							src={Assets.icon.refresh}
							$isRotating={isRotating}
							onClick={onClickRefresh}
						/>
					</S.RefreshContainer>
				)}
				{showViewCount && (
					<>
						{showRefresh && <S.Divider />}
						<S.PageInfo>
							<S.CurrentPageContainer>
								<input
									min={1}
									style={{
										width: calculateInputWidth(pageInfo?.page),
									}}
									onBlur={handleBlurCurrentPage}
									onChange={onChangePageIndexInput}
									onKeyDown={onKeyDown}
									type='number'
									value={String(pageInfo?.page)}
								/>
							</S.CurrentPageContainer>
							<S.TotalPageText>
								<span>/</span> {totalPage}
							</S.TotalPageText>
						</S.PageInfo>
						<S.Paging>
							<S.Icon src={Assets.icon.keyboardArrowLeft} onClick={() => onClickChangePage(-1)} />
							<S.Icon src={Assets.icon.keyboardArrowRight} onClick={() => onClickChangePage(+1)} />
						</S.Paging>
						<S.ViewCount>
							<CRInput.Selector
								type='tableHeader'
								currentValue={{ label: `${pageInfo.size}개`, value: pageInfo.size } as T}
								onChangeValue={onChangePageSize}
								items={
									[
										{
											label: '50개',
											value: 50,
										},
										{
											label: '100개',
											value: 100,
										},
										{
											label: '150개',
											value: 150,
										},
										{
											label: '200개',
											value: 200,
										},
									] as T[]
								}
							/>
							<CRText typography='body' color='gray00'>
								씩 보기
							</CRText>
						</S.ViewCount>
					</>
				)}
				{((!hideSearchButton && showViewCount) || showViewCount) && <S.Divider />}

				{!hideSearchButton && (
					<CRSearch
						placeholder={placeholder}
						value={searchValue}
						onChange={onChangeSearchValue}
						onSearch={onSearch}
					/>
				)}
				{renderRightButton && renderRightButton}
			</S.RightSideMenu>
		</S.Container>
	);
}

export default CRTableHeader;
