import { styled } from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 80rem;
	height: 100%;
	overflow: hidden;
	background: ${(props) => props.theme.colors.gray100};
	box-sizing: border-box;
`;

export const Header = styled.div`
	display: flex;
	height: 8rem;
	justify-content: space-between;
	box-sizing: border-box;
	align-items: center;
	padding: 2.4rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.6rem;
	height: calc(100vh - 21.7rem);
	overflow-y: scroll;
	box-sizing: border-box;
	overflow: auto;
`;

export const CloseIcon = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	cursor: pointer;
`;

export const ButtonContainer = styled.div`
	display: flex;
	gap: 0.8rem;
	justify-content: flex-end;
	padding: 1.6rem 2.4rem;
	border-top: 0.1rem solid ${(props) => props.theme.colors.gray90};
	box-sizing: border-box;
`;
