import { styled } from 'styled-components';

export const DescriptionContainer = styled.div`
	padding: 0 2.4rem 2.4rem 2.4rem;
`;

export const FooterRightSideContainer = styled.div`
	flex: 1;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 160rem;
	height: 100%;
	overflow: hidden;
	background: ${(props) => props.theme.colors.gray99};
`;

export const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 0.4rem;
`;

export const AmountColumn = styled.div<{ color?: string }>`
	${(props) => props.theme.typography.label}
	color: ${(props) => props.color};
`;

export const DeleteDialogContainer = styled.div`
	display: flex;
	padding: 0 2.4rem;
`;
