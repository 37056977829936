import React, { ReactElement } from 'react';

import RDTooltip from 'components/ui/radix/hoverCard/RdTooltip';

import CRAvatar from '../CRAvatar';
import { AvatarSize } from '../CRAvatar/type';
import * as S from './styles';

interface IProps {
	maxCount?: number;
	size?: AvatarSize;
	children: any;
}

/* eslint-disable */
function CRAvatarGroup({ maxCount = 3, size = 'default', children }: IProps) {
	const visibleArray = React.Children.toArray(children)?.slice(0, maxCount);
	const remainArray = React.Children.toArray(children)?.slice(visibleArray?.length);

	const renderAvatar = () => {
		const visible = visibleArray?.map((e) => React.cloneElement(e as ReactElement, { size }));
		const remain = remainArray?.map((e) => React.cloneElement(e as ReactElement, { size }));

		return (
			<>
				{visible}
				{remain.length > 0 && (
					<RDTooltip
						content={
							<S.AvatarContainer>
								{remain.map((e) => (
									<CRAvatar {...e.props} key={e.key} />
								))}
							</S.AvatarContainer>
						}>
						<CRAvatar size={size} style={{ marginLeft: '-1rem' }}>
							<S.RemainText size={size}>{'•••'}</S.RemainText>
						</CRAvatar>
					</RDTooltip>
				)}
			</>
		);
	};

	return <S.Container>{renderAvatar()}</S.Container>;
}

export default CRAvatarGroup;
