import dayjs from 'dayjs';

import {
	GetContractReviewDTO,
	GetEmployeeContractReviewDTO,
	IntegratedAssessmentEvaluationDTO,
} from 'types/dto/contractReview';
import {
	EmployeeContractReviewListViewType,
	RecepientContractReviewListViewType,
} from 'types/view/contractReview';

export const recipientContractReviewAdapter = (
	data: GetContractReviewDTO | null,
): { totalCnt: number; recipientContractList: RecepientContractReviewListViewType[] } => {
	const recipientContractList = data?.adminRecipientContracts;

	const handleIntergratedAssessmentInfo = (
		integratedEvaluations?: IntegratedAssessmentEvaluationDTO[],
	) => {
		const currentTargetYear = integratedEvaluations?.[0]?.targetYear;
		const currentTargetSeq = integratedEvaluations?.[0]?.targetSeq;

		const filteredEvaluations = integratedEvaluations?.filter(
			(evaluation) =>
				evaluation.targetYear === currentTargetYear && evaluation.targetSeq === currentTargetSeq,
		);

		const fallRiskData = filteredEvaluations?.find(
			(evaluation) => evaluation.paperTypeCd === 'CMN119.0005',
		);

		const pressureInjuryRiskData = filteredEvaluations?.find(
			(evaluation) => evaluation.paperTypeCd === 'CMN119.0006',
		);

		const cognitiveFunctionData = filteredEvaluations?.find(
			(evaluation) => evaluation.paperTypeCd === 'CMN119.0008',
		);

		const needsAssessmentData = filteredEvaluations?.find(
			(evaluation) => evaluation.paperTypeCd === 'CMN119.0004',
		);

		const isRecipientAgeValid = (recipientAge?: number, ageScrore?: number) =>
			recipientAge && ageScrore
				? (recipientAge >= 60 && recipientAge <= 69 && ageScrore === 1) ||
					(recipientAge >= 70 && recipientAge <= 79 && ageScrore === 2) ||
					(recipientAge >= 80 && ageScrore === 3)
				: false;

		// 낙상위험도
		const fallRiskInfo = {
			fallRiskAssessmentCompletionStatus:
				Boolean(fallRiskData?.cmn17210Josa) &&
				fallRiskData?.writeYn &&
				isRecipientAgeValid(fallRiskData?.recipientAge, fallRiskData?.cmn1190005Age),
			fallRiskAssessmentReason: Boolean(fallRiskData?.cmn17210Josa),
			fallRiskAssessmentDate: fallRiskData?.writeYn,
			fallRiskAssessmentAgeScoreValid: isRecipientAgeValid(
				fallRiskData?.recipientAge,
				fallRiskData?.cmn1190005Age,
			),
		};

		// 욕창위험도
		const pressureInjuryRiskInfo = {
			pressureInjuryRiskAssessmentCompletionStatus:
				Boolean(pressureInjuryRiskData?.cmn17210Josa) &&
				pressureInjuryRiskData?.writeYn &&
				pressureInjuryRiskData?.cmn1190006Cnt === 6,
			pressureInjuryRiskAssessmentReason: Boolean(pressureInjuryRiskData?.cmn17210Josa),
			pressureInjuryRiskAssessmentDate: pressureInjuryRiskData?.writeYn,
			pressureInjuryRiskAssessmentItemValidity: pressureInjuryRiskData?.cmn1190006Cnt === 6,
		};

		// 인지기능
		const cognitiveFunctionInfo = {
			cognitiveFunctionAssessmentCompletionStatus:
				Boolean(cognitiveFunctionData?.cmn17210Josa) &&
				cognitiveFunctionData?.writeYn &&
				cognitiveFunctionData?.cmn1190008Inji === 16 &&
				cognitiveFunctionData?.eduYearCntYn,
			cognitiveFunctionAssessmentReason: Boolean(cognitiveFunctionData?.cmn17210Josa),
			cognitiveFunctionAssessmentDate: cognitiveFunctionData?.writeYn,
			cognitiveFunctionAssessmentItemValidity: cognitiveFunctionData?.cmn1190008Inji === 16,
			cognitiveFunctionAssessmentEducationYearsValidity:
				cognitiveFunctionData?.eduYearCntYn || false,
		};

		// 욕구평가
		const needsAssessmentInfo = {
			needsAssessmentCompletionStatus:
				Boolean(needsAssessmentData?.cmn17210Josa) &&
				needsAssessmentData?.writeYn &&
				needsAssessmentData?.cmn1190004TdCnt === 11 &&
				needsAssessmentData?.totDescCnt === 1,
			needsAssessmentReason: Boolean(needsAssessmentData?.cmn17210Josa),
			needsAssessmentDate: needsAssessmentData?.writeYn,
			needsAssessmentJudgmentBasisValidity: needsAssessmentData?.cmn1190004TdCnt === 11,
			needsAssessmentOverallOpinionValidity: needsAssessmentData?.totDescCnt === 1,
		};

		const responseData = {
			targetYear: currentTargetYear,
			targetSeq: currentTargetSeq,
			salLastEsignStateNm: filteredEvaluations?.[0]?.salLastEsignStateNm,
			// 급여제공계획서
			longtermCareBenefitPlanDocumentVerification: false, // 급여제공계획서 유효성 검사
			longtermCareBenefitPlanDocumnetSendStatus:
				filteredEvaluations?.[0]?.salLastEsignStateNm || '',
			longtermCareBenefitPlanOverallOpinion: filteredEvaluations?.[0]?.totalOpinionDesc || '',
			longtermCareBenefitPlanDocumentNumber: filteredEvaluations?.[0]?.edocNo,
			longtermCareBenefitPlanWriteDate: filteredEvaluations?.[0]?.salPlanWriteDt,
			longtermCareBenefitPlanStartDate: dayjs('20250203').format('YYYY.MM.DD'),
			longtermCareBenefitPlanEndDate: dayjs('20250203').format('YYYY.MM.DD'),
			longtermCareBenefitPlanContractDate: dayjs('20250203').format('YYYY.MM.DD'),

			// 통합사정
			integratedAssessmentWritingStatus:
				fallRiskInfo.fallRiskAssessmentCompletionStatus &&
				pressureInjuryRiskInfo.pressureInjuryRiskAssessmentCompletionStatus &&
				cognitiveFunctionInfo.cognitiveFunctionAssessmentCompletionStatus &&
				needsAssessmentInfo.needsAssessmentCompletionStatus,
			// 낙상위험도
			...fallRiskInfo,
			// 욕창위험도
			...pressureInjuryRiskInfo,
			// 인지기능
			...cognitiveFunctionInfo,
			// 욕구평가
			...needsAssessmentInfo,
		};

		return responseData;
	};

	const items = (recipientContractList ?? []).map(
		(item) =>
			({
				contractMngtId: item.contractMngtId,
				serviceContractId: item.serviceContractId,
				// 기본 정보 (수급자)
				totalWorkCompletionStatus: item.totCompleteYn,
				center: item.centerNm,
				centerId: item.centerId,
				socialWorker: item.contractManagerNm,
				recipientName: item.recipientNm,
				recipientId: item.recipientId,
				longTermCareNumber: item.longTermNo ?? '',
				contractType: item.contractTypeNm,
				contractDate: item.contractDt,
				// 수급자 기준 급여별 정보
				lognTermCareBenefitInfo: item.serviceTypes.map((item) => ({
					longtermCareBenefitType: item.serviceTypeNm ?? '',
					longtermCareStartDate: item.serviceStartDate ?? '',
					longtermCareEndDate: item.serviceEndDate ?? '',
					employeeName: (item.employees ?? []).map((employee) => employee.employeeNm).join(', '),
					// 급여제공계획서 통합사정
					// TODO : 급제계, 통합사정 유효성 검사 로직 추가 필요
					...handleIntergratedAssessmentInfo(item?.integratedEvaluations),
				})),

				// 수급자 계약
				isSameDayContract: Boolean(item.sameDayContractYn),
				recipientContractCompletionStatus: Boolean(item.contractCompleteYn),
				recipientContractSendStatus: item.contractLastEsignStateNm,
				recipientContractDocumentVerification: item.contractYn,

				// 급여제공계획서
				longtermCareBenefitPlanCompletionStatus: Boolean(item.salaryPlanCompleteYn),
				longtermCareBenefitPlanOverallOpinionReview: item.salaryPlanCompleteCd,
				longtermCareBenefitPlanNotificationToCorporation: Boolean(item.notiToPcorpYn),

				// 급여제공계획 변경 사유서 작성 여부
				longtermCareBenefitPlanReasonForChange: item.salaryChangeStatCd ?? '',
				// 통합사정
				integratedAssessmentCompletionStatus: Boolean(item.integratedEvaluationCompleteYn),
				// 요양보호사 교체 상담일지
				employeeChangeConsultationWritingStatus: item.employeeReplacementCounselingCd ?? '',
				// 입소 이용 신청서
				admissionApplicationFormCompletionStatus: Boolean(
					item.recipientAdmissionCompleteYn === '1',
				),
				isTargetOfadmissionApplication: Boolean(item.recipientAdmissionYn === '1'),
				isCompletedAdmissionApplicationForm: Boolean(item.recipientAdmissionEdocNo),
				admissionApplicationFormSendStatus: item.recipientAdmissionLastEsignStateNm ?? '',
				admissionApplicationSendApprovalStatus: item.admissionDispatchCd || '',
				// 공단 일정 등록
				registeredLongtermCareBenefitScheduleToCorporation: item.pcorpScheduleCd || '',
				// 개인별 장기요양이용계획서 열람신청
				appliedIndividualLongtermCarePlan: Boolean(item.longtermPlanRequestYn),
				// 태그
				tagCompletionStatus: Boolean(item.tagCompleteYn),
				tagSocialWorkerRequest: item.contractTagRequestTaskCd || '',
				tagAttachmentClassification: item.contractTagRequestTaskCd || '',
				tagAttachmentStatus: item.tagRequestTaskCd || '',
				// 메모
				referenceContractNotes: item.contractInspectionDesc ?? '',
				adminMemo: item.recipientRemark ?? '',
				data: item,
			}) as RecepientContractReviewListViewType,
	);

	return {
		recipientContractList: items,
		totalCnt: data?.totalCnt ?? 0,
	};
};

export const employeeContractReviewAdapter = (
	data: GetEmployeeContractReviewDTO | null,
): { totalCnt: number; employeeContractReviewList: EmployeeContractReviewListViewType[] } => {
	const employeeContractList = data?.adminEmployeeContracts;
	const formatNumbers = (input: string): string => input.replace(/,(\d)/g, '\n$1');
	const formatDate = (dateString: string): string => dayjs(dateString).format('YYYY.MM.DD');

	const items = (employeeContractList ?? []).map(
		(item) =>
			({
				serviceContractId: item.serviceContractId,
				centerId: item.centerId,
				recipientId: item.recipientId,
				employeeId: item.employeeId,
				contractMngtId: item?.contractMngtId,
				// 기본 정보
				totalWorkCompletionStatus: Boolean(item.totCompleteYn),
				center: item.centerNm,
				socialWorker: item.contractManagerNm,
				employeeName: item.employeeNm,
				employeeBirthDate: item.employeeBirthDt,
				isNewEmployee: item.contractEmployeeNewYn,
				contractType: item.contractTypeNm,
				recipientContractDate: item.contractDt,
				recipientName: item.recipientNm,
				isFamily: Boolean(item.familyYn),
				isSameDayContract: Boolean(item.sameDayContractYn),
				// 근로자 기준 급여별 정보
				lognTermCareBenefitInfo: item.employeeContractDetails.map((detail) => ({
					// 급여 계약 정보
					longtermCareBenefitType: detail.serviceTypeNm ?? '',
					longtermCareBenefitTypeCd: detail.serviceTypeCd ?? '',
					longtermCareStartDate: detail.serviceStartDt ?? '',
					longtermCareEndDate: detail.contractEndDt ?? '',
					employeeContractDate: detail.contractDt ? formatDate(detail.contractDt) : '',
					// 근로 계약서
					employeeContractSendingStatus: detail.lastEsignStateNm || '',
					isEmployeeContractValid: Boolean(detail.contractMatchYn),
					employeeContractHourlyWage: detail.serviceFeeAmt ?? 0,
					employeeContractMonthlyWorkHours: detail.monthPerWorkHourCnt ?? 0,
					isContractWorkHoursAlignedWithPolicy: Boolean(detail.timeCntMatchYn),
					contractEdocNumber: detail.edocNo ?? '',
					longtermCareBenefitUseDay: formatNumbers(detail.serviceDay || ''),
				})),
				// 범죄경력조회
				isCrimeCheckCompleted: item.criminalCompleteYn,
				hasCrimeReportFile: item.criminalRecordYn,
				hasElderAbuseReportFile: item.eacrReportYn,
				hasDisabilityAbuseReportFile: item.dacrInquiry,
				isManuallyRegistered: item.criminalRecordRegistrationYn,
				// 근로 계약서 업무
				employeeContractCompletionStatus: item.employeeContractCompleteCd || '',
				// 인력신고
				employeeReportState: item.employeeReportStatCd ?? '',
				// 4대보험
				isInsuranceEnrollmentReportCompleted: Boolean(item.insuranceRequestYn),
				weeklyContractedHours: item.weekPerWorkHourCnt ?? 0,
				reportedInsuranceWage: item.serviceMonthAmt ?? 0,
				insuranceEnrollmentDate: item.serviceStartDt,
				requiresNationalPensionService: Boolean(item.nationalPensionYn),
				requiresNationalHealthInsurance: Boolean(item.healthInsuranceYn),
				requiresEmploymentInsurance: Boolean(item.employmentInsuranceYn),
				requiresEmployeeCompensationInsurance: Boolean(item.accidentInsuranceYn),
				// 배상책임보험
				isLiabilityInsuranceRegistrationCompleted: Boolean(item.liabilityInsuranceCompleteYn),
				isLiabilityInsuranceRequired: item.liabilityInsuranceYn,
				isLiabilityInsuranceEnrolled: Boolean(item.liabilityInsuranceSubscriptionYn),
				// 단말기 등록
				deviceRegistrationRegistrationStatus: item.pcorpTagRegistrationCd ?? '',
				// 메모
				referenceContractNotes: item.contractInspectionDesc ?? '',
				adminMemo: item.employeeRemark ?? '',
				data: item,
			}) as EmployeeContractReviewListViewType,
	);

	return {
		employeeContractReviewList: items,
		totalCnt: data?.totalCnt ?? 0,
	};
};
