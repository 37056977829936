import React from 'react';
import { Controller } from 'react-hook-form';

import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import useContractRenewalTask from 'lib/hook/view/contract/useContractRenewalTask';

import { CheckOption } from '../../../base/Selections/type';
import * as S from './styles';

interface Props {
	currentService: CheckOption<any>;
}

function RenewalVisitBathContractForm({ currentService }: Props): React.ReactElement {
	const { form, disabled } = useContractRenewalTask();

	const index =
		form?.getValues('services').findIndex((item) => {
			const [serviceContractId, serviceTypeCd] = currentService.value.split('-');

			return (
				item.serviceContractId === Number(serviceContractId) && item.serviceTypeCd === serviceTypeCd
			);
		}) ?? 0;

	return (
		<S.Container key={currentService.value}>
			<S.SectionContainer>
				<S.SectionTitle>수급자</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.recipientContractAssignDate`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => {
						const recipientContractAssignDate = form?.watch(
							`services.${index}.associates.recipientContractAssignDate`,
						);
						return (
							<CRInputLabel type='left' label='계약일' isRequired showOverflow>
								<CRInput.DatePicker
									maxDate={
										form.watch(`services.${index}.use.sameDayContractYn`)
											? dayjs(form.watch(`services.${index}.use.serviceStartDate`)).toDate()
											: dayjs(form.watch(`services.${index}.use.serviceStartDate`))
													.subtract(1, 'day')
													.toDate()
									}
									placeholder='계약일'
									value={
										recipientContractAssignDate
											? dayjs(recipientContractAssignDate).toDate()
											: undefined
									}
									onChangeValue={(date) => {
										onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'));
										form.setValue(
											'services',
											form.getValues('services').map((item) => ({
												...item,
												associates: {
													...item.associates,
													recipientContractAssignDate: dayjs(date).format('YYYY-MM-DD hh:mm:ss'),
												},
											})),
										);
									}}
									disabled={disabled}
								/>
							</CRInputLabel>
						);
					}}
				/>
			</S.SectionContainer>
			<S.SectionContainer>
				<S.SectionTitle>직원 01</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.contractEmployees.0.employeeContractAssignDate`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => {
						const employeeContractAssignDate = form?.watch(
							`services.${index}.associates.contractEmployees.0.employeeContractAssignDate`,
						);
						return (
							<CRInputLabel type='left' label='계약일' isRequired showOverflow>
								<CRInput.DatePicker
									maxDate={
										form.watch(`services.${index}.use.sameDayContractYn`)
											? dayjs(form.watch(`services.${index}.use.serviceStartDate`)).toDate()
											: dayjs(form.watch(`services.${index}.use.serviceStartDate`))
													.subtract(1, 'day')
													.toDate()
									}
									placeholder='계약일'
									value={
										employeeContractAssignDate
											? dayjs(employeeContractAssignDate).toDate()
											: undefined
									}
									onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
									disabled
								/>
							</CRInputLabel>
						);
					}}
				/>
				<CRInputLabel type='left' label='직원 정보' isRequired showOverflow>
					<CRCardFormLayout label='직원' isRequired>
						<Controller
							name={`services.${index}.associates.contractEmployees.0.employeeNm`}
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel type='left-sub' label='이름'>
									<CRInput.Default
										placeholder='이름 입력'
										value={value}
										onChange={onChange}
										disabled
									/>
								</CRInputLabel>
							)}
						/>
						<Controller
							name={`services.${index}.associates.contractEmployees.0.employeeRsdnNo`}
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel type='left-sub' label='주민번호'>
									<CRInput.Default
										placeholder='주민번호 입력'
										onChange={onChange}
										value={value}
										disabled
										suffix={
											<CRButton.Default
												size='xSmall'
												palette='gray'
												type='outlined'
												disabled
												buttonType='button'>
												중복 조회
											</CRButton.Default>
										}
									/>
								</CRInputLabel>
							)}
						/>
					</CRCardFormLayout>
				</CRInputLabel>
			</S.SectionContainer>
			<S.SectionContainer>
				<S.SectionTitle>직원 02</S.SectionTitle>
				<Controller
					name={`services.${index}.associates.contractEmployees.1.employeeContractAssignDate`}
					control={form?.control}
					render={({ field: { onChange, value }, formState: { errors } }) => {
						const employeeContractAssignDate = form?.watch(
							`services.${index}.associates.contractEmployees.1.employeeContractAssignDate`,
						);

						return (
							<CRInputLabel type='left' label='계약일' isRequired showOverflow>
								<CRInput.DatePicker
									maxDate={
										form.watch(`services.${index}.use.sameDayContractYn`)
											? dayjs(form.watch(`services.${index}.use.serviceStartDate`)).toDate()
											: dayjs(form.watch(`services.${index}.use.serviceStartDate`))
													.subtract(1, 'day')
													.toDate()
									}
									placeholder='계약일'
									value={
										employeeContractAssignDate
											? dayjs(employeeContractAssignDate).toDate()
											: undefined
									}
									onChangeValue={(date) => onChange(dayjs(date).format('YYYY-MM-DD hh:mm:ss'))}
									disabled
								/>
							</CRInputLabel>
						);
					}}
				/>
				<CRInputLabel type='left' label='직원 정보' isRequired showOverflow>
					<CRCardFormLayout label='직원' isRequired>
						<Controller
							name={`services.${index}.associates.contractEmployees.1.employeeNm`}
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel type='left-sub' label='이름'>
									<CRInput.Default
										placeholder='이름 입력'
										value={value}
										onChange={onChange}
										disabled
									/>
								</CRInputLabel>
							)}
						/>
						<Controller
							name={`services.${index}.associates.contractEmployees.1.employeeRsdnNo`}
							control={form?.control}
							render={({ field: { onChange, value }, formState: { errors } }) => (
								<CRInputLabel type='left-sub' label='주민번호'>
									<CRInput.Default
										placeholder='주민번호 입력'
										onChange={onChange}
										value={value}
										disabled
										suffix={
											<CRButton.Default
												size='xSmall'
												palette='gray'
												type='outlined'
												buttonType='button'
												disabled>
												중복 조회
											</CRButton.Default>
										}
									/>
								</CRInputLabel>
							)}
						/>
					</CRCardFormLayout>
				</CRInputLabel>
			</S.SectionContainer>
		</S.Container>
	);
}

export default RenewalVisitBathContractForm;
