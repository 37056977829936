import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Colors from 'common/colors';
import CRCardFormLayout from 'components/base/CRCardFormLayout';
import CRInput from 'components/base/CRInput';
import CRInputLabel from 'components/base/CRInputLabel';
import CRStatus from 'components/base/CRStatus';
import { CRText } from 'components/base/CRText';
import CRCheckBox from 'components/base/Selections/CRCheckBox';
import { RecipientContractReviewFormFields } from 'components/domain/sideModal/RecipientContractReviewSideModal/types';
import {
	TAG_APPLICATION_REQUEST_CONFIG,
	TAG_ATTACHMENT_CLASSIFICATION_CONFIG,
} from 'components/domain/table/RecipientContractReviewTable/constant';
import { commonCodeAdapter } from 'lib/adapter/common';
import { useCommonCodes } from 'lib/hook/react-query';
import { CRStatusConfig } from 'types/view/base';
import { RecepientContractReviewListViewType } from 'types/view/contractReview';

import { COMPLETION_STATUS_CONFIG } from './constant';
import * as S from './styles';

interface Props {
	data: RecepientContractReviewListViewType;
}

function RecipientLongtermInfo({ data }: Props): React.ReactElement {
	const { control } = useFormContext<RecipientContractReviewFormFields>();
	const { data: commonCodes = { CMN215: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN215'] },
		commonCodeAdapter,
	);

	const renderConfigText = useCallback((config: CRStatusConfig[], value: string) => {
		const currentValue = config.find((item) => item.label === value || value === item.value);
		const currentColor =
			currentValue?.color === 'green'
				? 'gray00'
				: currentValue?.color === 'yellow'
					? 'red'
					: (currentValue?.color as keyof typeof Colors);

		return (
			<CRText typography='label' color={currentColor}>
				{currentValue?.label ?? '-'}
			</CRText>
		);
	}, []);

	return (
		<S.Container>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						개인별장기요양이용계획서
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.tagCompletionStatus ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<CRInputLabel label='열람신청' type='left'>
					<Controller
						control={control}
						name='longtermPlanRequestYn'
						render={({ field: { value, onChange } }) => (
							<CRCheckBox
								checkBoxType='checkbox'
								appearanceType='button'
								checked={value}
								onChange={onChange}>
								<CRText>신청완료</CRText>
							</CRCheckBox>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
			<CRCardFormLayout
				label={
					<S.CardLabelContainer>
						태그
						<CRStatus type='pill' options={COMPLETION_STATUS_CONFIG}>
							{data.tagCompletionStatus ? '완료' : '미완료'}
						</CRStatus>
					</S.CardLabelContainer>
				}>
				<S.LabelValueContainer>
					<S.LabelContainer>
						<CRText typography='body' text='사회복지사 요청' />
					</S.LabelContainer>
					{renderConfigText(TAG_APPLICATION_REQUEST_CONFIG, data.tagSocialWorkerRequest)}
				</S.LabelValueContainer>
				<S.LabelValueContainer>
					<S.LabelContainer>
						<CRText typography='body' text='부착 구분' />
					</S.LabelContainer>
					{renderConfigText(TAG_ATTACHMENT_CLASSIFICATION_CONFIG, data.tagSocialWorkerRequest)}
				</S.LabelValueContainer>
				<CRInputLabel label='신청/부착' type='left' isRequired>
					<Controller
						control={control}
						name='tagRequestTaskOption'
						render={({ field: { value, onChange } }) => (
							<CRInput.Selector
								currentValue={value}
								onChangeValue={onChange}
								items={commonCodes.CMN215}
							/>
						)}
					/>
				</CRInputLabel>
			</CRCardFormLayout>
		</S.Container>
	);
}

export default RecipientLongtermInfo;
