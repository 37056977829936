import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import CRButton from 'components/base/CRButton';
import CRDialog from 'components/base/CRDialog';
import { Toast } from 'components/base/CRToast';
import { CheckOption } from 'components/base/Selections/type';
import EmployeeLicenseUploadDialog from 'components/domain/dialog/EmployeeLicenseUploadDialog';
import EmployeeReportDefaultInfoForm from 'components/domain/form/EmployeeReportDefaultInfoForm';
import EmploymentReportAdditionalInfoForm from 'components/domain/form/EmploymentReportAdditionalInfoForm';
import EmploymentReportJoinInfoForm from 'components/domain/form/EmploymentReportJoinInfoForm';
import EmploymentReportLicenseInfoForm from 'components/domain/form/EmploymentReportLicenseInfoForm';
import EmploymentReportResignInfoForm from 'components/domain/form/EmploymentReportResignInfoForm';
import ResignReportAdditionalInfoForm from 'components/domain/form/ResignReportAdditionalInfoForm';
import {
	commonCodeAdapter,
	corporationListAdapter,
	externalCenterListAdapter,
} from 'lib/adapter/common';
import {
	useCommonCodes,
	useCommonCorporations,
	useEmployeePageBaseInfo,
	useExternalCenters,
	useSaveEmployReportData,
} from 'lib/hook/react-query';
import useDialog from 'lib/hook/util/useDialog';
import { endpoint } from 'lib/service/Api/endpoint';
import { EmployeeLicenseInfoDTO, ResponseCode } from 'types/api';
import { EmployReportRequest } from 'types/api/employReport';
import { EmployReportDataDTO } from 'types/dto/employReport';
import { CreateEmployReportForm } from 'types/view/employReport';

import { defaultAdditionalInfoArray } from './constant';
import { EmploymentAutomationDataValidator } from './resolver';
import * as S from './styles';

interface Props {
	employeeId: number;
	centerId: number;
	data: EmployReportDataDTO;
}

function EmployReportEnrollDialog({ data, employeeId, centerId }: Props): React.ReactElement {
	const { showDialog, hideDialog } = useDialog();
	const { data: corporationOptions } = useCommonCorporations(corporationListAdapter);
	const { data: centerOptions } = useExternalCenters(externalCenterListAdapter);
	const { data: commonCodes = { CMN006: [] } } = useCommonCodes(
		{ comCdGroupNms: ['CMN006'] },
		commonCodeAdapter,
	);
	const serviceOptions = useMemo(
		() => (commonCodes.CMN006 || [])?.filter((item) => item.data?.etcDesc1 === 'Y'),
		[commonCodes],
	);
	const { data: employeeInfo } = useEmployeePageBaseInfo({ employeeId, centerId });

	const { mutate: saveEmployReportData } = useSaveEmployReportData((client, returnData) => {
		if (returnData?.code === ResponseCode.SUCCESS) {
			Toast.success(`정상적으로 자동화 요청 수정을 완료하였습니다.`);
			client.invalidateQueries([endpoint.getEmployReportData.key]);
		} else {
			Toast.error(`자동화 요청 수정에 실패하였습니다. 잠시 후 다시 시도해 주시길 바랍니다.`);
		}
	});

	const handleDefaultValues = (data: EmployReportDataDTO) => {
		const currentDefaultAdditionalInfo = defaultAdditionalInfoArray.find(
			(item) => item.type === data.requestType,
		);
		const defaultValue = currentDefaultAdditionalInfo?.info.reduce(
			(acc, item) => ({ ...acc, [item.key]: item.value }),
			{},
		);
		const currentCenter = centerOptions?.find((item) => item.value === centerId);
		const currentCorporation = corporationOptions?.find(
			(item) => item.data?.corpId === currentCenter?.data?.corpId,
		);

		return {
			id: data.id,
			reqDt: data.reqDt,
			requestType: data.requestType
				? { label: data.requestType, value: data.requestType }
				: undefined,
			center: currentCenter,
			corporation: currentCorporation,
			serviceType: serviceOptions.find((item) => item.value === data.serviceTypeCd) || undefined,
			employeeNm: employeeInfo?.korMemberNm,
			employeeRsdnNo: employeeInfo?.rsdnNo,
			employeeNo: data.employeeNo,
			// 입사정보
			joinDate: employeeInfo?.joinDate
				? new Date(dayjs(employeeInfo.joinDate).format('YYYY.MM.DD'))
				: new Date(dayjs(data.joinDt).format('YYYY.MM.DD')),
			workStartDate: data.workStartDt
				? new Date(dayjs(data.workStartDt).format('YYYY.MM.DD'))
				: undefined,
			joinFile: data.joinFile ?? undefined,
			// 자격정보
			licenseType: data.licenseType,
			licenseNo: data.licenseNo,
			licenseAcqDate: data.licenseDt
				? new Date(dayjs(data.licenseDt).format('YYYY.MM.DD'))
				: undefined,
			licenseFile: data.licenseFile ?? undefined,
			// 퇴사정보
			resignDate: data.leaveDt ? new Date(dayjs(data.leaveDt).format('YYYY.MM.DD')) : undefined,
			workEndDate: data.workEndDt
				? new Date(dayjs(data.workEndDt).format('YYYY.MM.DD'))
				: undefined,
			resignFile: data.leaveFile ?? undefined,
			resignReason: data.leaveReason,
			// 기타
			recruitmentType: data.recruitmentType,
			employeeStatus: data.employeeStatus,
			regionJobCategory: data.regionJobCategory,
			jobCategory: data.jobCategory,
			salaryType: data.salaryType,
			dementiaEduYn: data.dementiaEduYn,
			workType: data.workType,
			position: data.position,
			employmentType: data.employmentType,
			deptNm: data.deptNm,
			updateContent: data.updateContent,
			updateReason: data.updateReason,
			updateReasonDetail: data.updateReasonDetail,
			autoSsisYn: data.autoSsisYn,
			...defaultValue,
		};
	};

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		formState: { errors },
		resetField,
		reset,
	} = useForm<CreateEmployReportForm>({
		mode: 'onSubmit',
		resolver: yupResolver(EmploymentAutomationDataValidator),
		defaultValues: handleDefaultValues(data),
	});

	const uploadLicenseFile = (licenseInfo: EmployeeLicenseInfoDTO) => {
		setValue('licenseNo', licenseInfo?.licenseNo);
		setValue('licenseAcqDate', dayjs(licenseInfo?.licenseIssueDate).toDate());
		setValue('licenseFile', licenseInfo.licenseFile);
		Toast.success('자격증 파일을 불러왔습니다.');
		hideDialog();
	};

	const showLicenseDialog = useCallback(() => {
		showDialog(() => (
			<EmployeeLicenseUploadDialog uploadLicenseFile={uploadLicenseFile} employeeId={employeeId} />
		));
	}, []);

	const renderRightButton = useCallback(() => {
		if (employeeId) {
			return (
				<CRButton.Default size='xSmall' type='outlined' palette='gray' onClick={showLicenseDialog}>
					불러오기
				</CRButton.Default>
			);
		}
		return undefined;
	}, [employeeId]);

	const handleInputSectionView = useCallback(() => {
		const requestType = watch('requestType')?.value ?? '';
		if (requestType === '퇴사') {
			return (
				<>
					<EmploymentReportResignInfoForm control={control} />
					<EmploymentReportLicenseInfoForm control={control} />
					<ResignReportAdditionalInfoForm control={control} />
				</>
			);
		}
		return (
			<>
				<EmploymentReportJoinInfoForm control={control} />
				<EmploymentReportLicenseInfoForm
					control={control}
					renderRightButton={renderRightButton()}
				/>
				<EmploymentReportAdditionalInfoForm control={control} />
			</>
		);
	}, [watch()]);

	const handleAdditionalInfo = (
		value: CheckOption,
		onChange?: (...event: CheckOption[]) => void,
	) => {
		onChange?.(value);

		const currentDefaultAdditionalInfo = defaultAdditionalInfoArray.find(
			(item) => item.type === value.value,
		);
		if (currentDefaultAdditionalInfo) {
			currentDefaultAdditionalInfo.info.forEach((item) => {
				if (item.value) setValue(item.key, item.value);
				else resetField(item.key);
			});
		}
	};

	const handleCorpInfo = (value: CheckOption, onChange: (...event: CheckOption[]) => void) => {
		onChange(value);
		setValue(
			'corporation',
			corporationOptions?.find((item) => item.data?.corpId === value.data?.corpId) ||
				({} as CheckOption),
		);
	};

	const onSubmit = async (inputData: CreateEmployReportForm) => {
		if (!inputData.center.data || !corporationOptions) return;
		const saveEmployReportParam: EmployReportRequest = {
			...data,
			requestType: inputData.requestType.value,
			centerId: inputData.center.value,
			corpId: inputData.center.data.corpId,
			corpNm: corporationOptions.find((item) => item.value === inputData.center.data?.corpId)?.data
				?.corpNm,
			ssisCenterNm: inputData.center.data.ssisCenterNm,
			serviceTypeCd: inputData.serviceType?.value ?? null,
			serviceTypeNm: inputData.serviceType?.label ?? null,
			salaryType: inputData.serviceType?.label ?? null,
			employeeNo: inputData.employeeNo,
			employeeRsdnNo: inputData.employeeRsdnNo,
			employeeNm: inputData.employeeNm,

			// 입사 정보
			joinDt: dayjs(inputData.joinDate).format('YYYYMMDD'),
			workStartDt: dayjs(inputData.workStartDate).format('YYYYMMDD'),
			joinFile: inputData.joinFile,
			// 자격증 정보
			licenseType: inputData.licenseType,
			licenseNo: inputData.licenseNo,
			licenseDt: dayjs(inputData.licenseAcqDate).format('YYYYMMDD'),
			licenseFile: inputData.licenseFile,
			// 퇴사 정보
			leaveFile: inputData.resignFile,
			leaveDt: dayjs(inputData.resignDate).format('YYYYMMDD'),
			workEndDt: dayjs(inputData.workEndDate).format('YYYYMMDD'),
			leaveReason: inputData.resignReason,
			// 기타 정보
			jobCategory: inputData.jobCategory,
			dementiaEduYn: inputData.dementiaEduYn,
			workType: inputData.workType,
			position: inputData.position,
			employmentType: inputData.employmentType,
			deptNm: inputData.deptNm,
			updateContent: inputData.updateContent,
			updateReason: inputData.updateReason,
			updateReasonDetail: inputData.updateReasonDetail,
			employeeStatus: inputData.employeeStatus,
			regionJobCategory: inputData.regionJobCategory,
			recruitmentType: inputData.recruitmentType,
			autoSsisYn: inputData.autoSsisYn,
		};

		saveEmployReportData(saveEmployReportParam);
	};

	useEffect(() => {
		reset(handleDefaultValues(data));
	}, [data, centerOptions, corporationOptions, commonCodes, employeeInfo]);

	return (
		<CRDialog
			title='자동화 요청 등록'
			onClickClose={hideDialog}
			body={
				<S.Container>
					<EmployeeReportDefaultInfoForm
						control={control}
						centerOptions={centerOptions ?? []}
						corporationOptions={corporationOptions ?? []}
						handleAdditionalInfo={handleAdditionalInfo}
						handleCorpInfo={handleCorpInfo}
					/>
					{handleInputSectionView()}
				</S.Container>
			}
			footer={
				<S.ButtonContainer>
					<CRButton.Default type='text' palette='gray' onClick={hideDialog}>
						취소
					</CRButton.Default>
					<CRButton.Default buttonType='submit' onClick={handleSubmit(onSubmit)}>
						저장
					</CRButton.Default>
				</S.ButtonContainer>
			}
		/>
	);
}

export default EmployReportEnrollDialog;
