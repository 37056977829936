import React from 'react';

import * as S from './styles';
import { InformationTableItemType } from './type';

interface Props {
	items: InformationTableItemType[][];
	roundBorder?: boolean;
	disabledFullWidth?: boolean;
	tableStyle?: React.CSSProperties;
	rowStyle?: React.CSSProperties;
}

function InformationTable({
	items,
	roundBorder = false,
	disabledFullWidth = false,
	tableStyle,
	rowStyle,
}: Props): React.ReactElement {
	const renderItem = (item: InformationTableItemType, isLastItem: boolean) => {
		if (item.type === 'labelValueNull') {
			return (
				<S.TableLabelColumn
					rowSpan={item.rowSpan}
					style={item.style}
					colSpan={item.colSpan ?? 2}
					viewBorder={!isLastItem}
				/>
			);
		}
		if (item.type === 'value') {
			return (
				<S.TableValueColumn
					title={typeof item.value === 'string' ? item.value : undefined}
					rowSpan={item.rowSpan}
					colSpan={item.colSpan}
					style={{ ...item.valueStyle }}
					viewBorder={!isLastItem}
					onClick={item.onClick}
					$selected={item.selected}>
					{item.value}
				</S.TableValueColumn>
			);
		}
		if (item.type === 'label') {
			return (
				<S.TableLabelColumn
					rowSpan={item.rowSpan}
					viewBorder={!isLastItem}
					colSpan={item.colSpan}
					style={{ ...item.labelStyle }}>
					{item.label}
				</S.TableLabelColumn>
			);
		}

		return (
			<>
				<S.TableLabelColumn
					rowSpan={item.labelRowSpan}
					colSpan={item.labelColSpan}
					style={{ maxWidth: '12rem', ...item.labelStyle }}
					viewBorder>
					{item.label}
				</S.TableLabelColumn>
				<S.TableValueColumn
					title={typeof item.value === 'string' ? item.value : undefined}
					rowSpan={item.valueRowSpan}
					colSpan={item.valueColSpan}
					viewBorder={!isLastItem}
					style={{ ...item.valueStyle }}
					$selected={item.selected}>
					{item.value}
				</S.TableValueColumn>
			</>
		);
	};

	const rowKeyExtractor = (rowIndex: number) => `row-${rowIndex}`;
	const colKeyExtractor = (rowIndex: number, colIndex: number) => `row-${rowIndex}-col-${colIndex}`;

	const renderTable = (component: JSX.Element) =>
		roundBorder ? <S.BorderContainer>{component}</S.BorderContainer> : component;

	return renderTable(
		<S.Table
			$disabledFullWidth={disabledFullWidth}
			$roundBorder={roundBorder}
			border={0}
			cellPadding={1}
			cellSpacing={1}
			style={tableStyle}>
			<tbody>
				{items.map((cols, rowIndex) => (
					<S.TableRow
						key={rowKeyExtractor(rowIndex)}
						viewBorder={rowIndex !== items.length - 1}
						style={rowStyle}>
						{cols.map((col, colIndex) => (
							<React.Fragment key={colKeyExtractor(rowIndex, colIndex)}>
								{renderItem(col, colIndex === cols.length - 1)}
							</React.Fragment>
						))}
					</S.TableRow>
				))}
			</tbody>
		</S.Table>,
	);
}

export default React.memo(InformationTable);
